import React, { Fragment } from 'react';

export interface ValidationProps
{
    message: string,
    type: ValidationType,
    active: boolean,
}

export enum ValidationType
{
    Required,
    Format,
    System
}

export interface RadioButtonProps
{
    validation?: ValidationProps,
    group: string,
    value: string,
    label: string,
    name?: string,
    onActionClick?: any,
    onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void,
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void,
    properties?: { [key: string]: string },
    checked?: boolean,
    disabled?: boolean
}

export const RadioButton: React.FC<RadioButtonProps> = ({
    validation,
    group,
    value,
    label,
    name,
    onActionClick,
    onKeyUp,
    onFocus,
    properties,
    checked,
    disabled
}) => 
{
    return (
        <Fragment>
            <div className="form-check">
                <input className="form-check-input" type="radio" radioGroup={group} id={name} name={name} value={value} onClick={onActionClick} onKeyUp={onKeyUp} onFocus={onFocus} {...properties} checked={checked} disabled={disabled} />
                <label className="form-check-label"><b>{label}</b></label>
            </div><br/>
        </Fragment>
    );
}