import * as React from 'react';
import { Button, ButtonState } from '../../../Button';
import { TablePaginationActionsProps } from './types';
const TablePaginationActions = (
  props: React.PropsWithChildren<TablePaginationActionsProps>
) => {
  const { page, rowsPerPage, count, onChangePage, classes } = props;
  const pageStart = page * rowsPerPage + 1;
  let pageEnd = rowsPerPage * (page + 1);
  if (pageEnd > count) {
    pageEnd = count;
  }
  const style = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minWidth: '210px',
  };
  const finalStyle = { ...style, ...props.style };
  let finalClasses = 'HuiTablePaginationActions';
  if (classes) finalClasses += ` ${classes}`;
  return (
    <div className={finalClasses} style={finalStyle}>
      <div>
        {pageStart} - {pageEnd} of {count}
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          minWidth: '120px',
        }}
      >
        <Button
          customAriaLabel="Paginate Table Left"
          title="HuiLeftTablePaginateButton"
          id="HuiLeftTablePaginateButton"
          text=" "
          state={page > 0 ? ButtonState.Enabled : ButtonState.Disabled}
          icon="arrow-left"
          onClick={(e: any) => {
            onChangePage(e, page - 1 >= 0 ? page - 1 : page);
          }}
        />
        <Button
          title="HuiRightTablePaginateButton"
          customAriaLabel="Paginate Table Right"
          id="HuiRightTablePaginateButton"
          text=" "
          state={
            (page + 1) * rowsPerPage < count
              ? ButtonState.Enabled
              : ButtonState.Disabled
          }
          icon="arrow-right"
          onClick={(e: any) => {
            onChangePage(e, page + rowsPerPage < count ? page + 1 : page);
          }}
        />
      </div>
    </div>
  );
};

export default TablePaginationActions;
