import React, { Fragment } from "react";
import NavItem, { NavItemProps, NavItemState } from "./NavItem";
import { ButtonBar, ButtonBarProps } from "../Button";
import $ from "jquery";
import { Modal } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";

//@ts-ignore
import MediaQuery from "react-responsive";

export interface NavBarProps {
	type: NavBarTypes;
	tabs: NavItemProps[];
	utilNav?: ButtonBarProps;
	itemAlignment?: ItemAlignment;
	responsiveType?: ResponsiveTypes;
	mainMenu?: boolean;
	overrideState?: any;
	maxWidth?: number;
	minWidth?: number;
}

export enum ResponsiveTypes {
	None, // Will never collapse (and most propably break)
	CollapseAll, //Collapses all the items into a hamburger menu
	CollapseSome, // Collapses item 6 and onwards into a hamburger menu
	CollapseFit, // Collapses all the items that do not fit into a row
}

export enum NavBarTypes {
	Text,
	IconText,
	IconOnly,
	Tabs,
}

export enum ItemAlignment {
	Left,
	Center,
	Right,
}

interface NavBarState {
	index: number;
	showMenu: boolean;
	tabs: NavItemProps[];
	show: boolean;
}

export default class NavBar extends React.Component<NavBarProps, NavBarState> {
	static defaultProps = {
		responsiveType: ResponsiveTypes.None,
		maxWidth: 576,
		minWidth: 577,
	};

	private NavId: string = "nav" + uuidv4();

	constructor(props: NavBarProps) {
		super(props);

		const activeIndex = this.props.tabs.findIndex((itm) => itm.state === NavItemState.Active);

		this.state = {
			index: activeIndex < 0 ? 0 : activeIndex,
			showMenu: false,
			tabs: this.props.tabs,
			show: false,
		};

		if (this.props.responsiveType === ResponsiveTypes.CollapseFit) window.addEventListener("resize", this.doAdapt);
	}

	doAdapt = () => {
		const container = document.getElementById(this.NavId);

		if (container) {
			//Calculate if tabs will fit
			let parentWidth = container?.offsetWidth;

			let children = container?.querySelectorAll("div > li:not(#btnMore)");

			if (children) {
				const moreBtn = document.getElementById("btnMore");

				if (moreBtn) {
					children?.forEach((item: any) => {
						item.classList.remove("d-none");
					});

					const style = getComputedStyle(moreBtn);
					const margin = parseInt(style.marginLeft) + parseInt(style.marginRight);
					let stopWidth = moreBtn?.offsetWidth + margin;

					children?.forEach((item: any, i: number) => {
						const style = getComputedStyle(item);
						const margin = parseInt(style.marginLeft) + parseInt(style.marginRight);

						//@ts-ignore
						if (parentWidth >= stopWidth + item?.offsetWidth + margin) {
							stopWidth += item?.offsetWidth + margin;
						} else {
							item.classList.add("d-none");
							moreBtn.classList.remove("d-none");
						}
					});
				}
			}
		}
	};

	componentDidMount() {
		//add click event on mainbar for responsiveness
		$(".mainbar").bind("click", () => {
			this.setState({ showMenu: false });
			$(".atlas-theme").removeClass("showMenu");
		});

		this.doAdapt();
		this.doAdapt();
	}

	getIcon(): string {
		var classes: string[] = [];

		if (this.props.type === NavBarTypes.Text) classes.push("nav-text");

		if (this.props.type === NavBarTypes.IconText) classes.push("nav-icons");

		if (this.props.type === NavBarTypes.IconOnly) classes.push("nav-icons-only");

		if (this.props.type === NavBarTypes.Tabs) classes.push("nav-tabs");

		return classes.join(" ");
	}

	getClasses(): string {
		var classes: string[] = [];

		if (this.props.itemAlignment === ItemAlignment.Left) classes.push("nav-left");
		if (this.props.itemAlignment === ItemAlignment.Center) classes.push("nav-center");
		if (this.props.itemAlignment === ItemAlignment.Right) classes.push("nav-right");
		if (this.props.tabs.length > 5) classes.push("many");

		return classes.join(" ");
	}

	onClick(navItem: NavItemProps, index: number) {
		if (this.props.tabs[index].state !== NavItemState.Disabled) {
			this.setState({
				index: index,
			});
		}

		if (navItem.onClick) {
			navItem.onClick();
		}
	}

	getState(navItem: NavItemProps, number: number) {
		if (!navItem.to) return navItem.state ? navItem.state : NavItemState.None;

		if (navItem.overrideState) {
			return navItem.overrideState();
		}

		return this.state.index === number ? NavItemState.Active : navItem.state === NavItemState.Active ? NavItemState.None : navItem.state;
	}

	//Render mobile utility navigation
	renderMobile(): React.ReactNode {
		if (this.props.responsiveType === ResponsiveTypes.CollapseAll) {
			//Add collapse
			return (
				this.props.tabs.length > 0 && (
					<ul className={`nav ${this.getIcon()} ${this.getClasses()}`}>
						<NavItem
							icon={"bars"}
							iconOnly={false}
							text={"More"}
							onClick={() => {
								this.setState({ show: true });
								setTimeout(() => {
									document.getElementsByClassName("modal-open")[0].classList.remove("modal-open");
								}, 100);
							}}
						/>
						<Modal className={"containerless"} show={this.state.show} onHide={() => this.setState({ show: false })}>
							<Modal.Header closeButton></Modal.Header>
							<Modal.Body>
								<div className={`nav nav-icons mobile-nav-list`}>
									{
										//Add menu items in modal
										this.props.tabs.map((navItem: NavItemProps, number: number) => {
											if (navItem.state === NavItemState.Invisible) return false;

											return (
												<NavItem
													{...navItem}
													state={navItem.state ? navItem.state : NavItemState.None}
													key={number}
													onClick={() => {
														navItem.onClick && navItem.onClick();
														this.setState({ show: false });
													}}
												/>
											);
										})
									}
								</div>
							</Modal.Body>
						</Modal>
					</ul>
				)
			);
		}

		if (this.props.responsiveType === ResponsiveTypes.CollapseSome) {
			if (this.state.showMenu) return this.renderDesktop();

			return (
				<ul className={`nav ${this.getIcon()} ${this.getClasses()}`}>
					{this.props.tabs.slice(0, 4).map((navItem: NavItemProps, number: number) => {
						if (navItem.state === NavItemState.Invisible) return false;

						return (
							<NavItem
								key={number}
								{...navItem}
								iconOnly={this.props.type === NavBarTypes.IconOnly ? true : false}
								state={this.getState(navItem, number)}
								onClick={() => this.onClick(navItem, number)}
							/>
						);
					})}

					{this.props.tabs.length > 5 && (
						<NavItem
							icon={"chevron-up"}
							iconOnly={false}
							text={"More"}
							onClick={() => {
								var element = document.getElementsByClassName("atlas-theme");
								element[0].classList.add("showMenu");

								var rows = Math.ceil(this.props.tabs.length / 5);
								element[0].classList.add("rows-" + rows.toString());

								this.setState({ showMenu: true });
							}}
						/>
					)}
				</ul>
			);
		}

		if (this.props.responsiveType === ResponsiveTypes.CollapseFit)
			return (
				<Fragment>
					<Modal className={"containerless"} show={this.state.show} onHide={() => this.setState({ show: false })}>
						<Modal.Header closeButton></Modal.Header>
						<Modal.Body>
							<div className={`nav nav-icons mobile-nav-list`}>
								{
									//Add menu items in modal
									this.props.tabs.map((navItem: NavItemProps) => {
										if (navItem.state === NavItemState.Invisible) return false;

										return (
											<NavItem
												{...navItem}
												state={navItem.state ? navItem.state : NavItemState.None}
												onClick={() => {
													navItem.onClick && navItem.onClick();
													this.setState({ show: false });
												}}
											/>
										);
									})
								}
							</div>
						</Modal.Body>
					</Modal>
					<ul id={this.NavId} className={`nav ${this.getIcon()} ${this.getClasses()}`}>
						{this.props.tabs.map((navItem: NavItemProps, number: number) => {
							if (navItem.state === NavItemState.Invisible) return false;

							return (
								<NavItem
									key={number}
									{...navItem}
									iconOnly={this.props.type === NavBarTypes.IconOnly ? true : false}
									state={this.getState(navItem, number)}
									onClick={() => {
										this.onClick(navItem, number);
										this.setState({ showMenu: false });
										$(".atlas-theme").removeClass("showMenu");
									}}
								/>
							);
						})}
						<li className="nav-item d-none" id="btnMore">
							<a
								onClick={() => {
									this.setState({ show: true });
									setTimeout(() => {
										document.getElementsByClassName("modal-open")[0].classList.remove("modal-open");
									}, 100);
								}}
								href="#"
								className="nav-link">
								<i className="fas fa-ellipsis-h always-show"></i>
							</a>
						</li>
					</ul>
				</Fragment>
			);

		return this.renderDesktop();
	}

	//Render desktop utility navigation
	renderDesktop(): React.ReactNode {
		return (
			<Fragment>
				<ul className={`nav ${this.getIcon()} ${this.getClasses()}`}>
					{this.props.tabs.map((navItem: NavItemProps, number: number) => {
						if (navItem.state === NavItemState.Invisible) return false;

						return (
							<NavItem
								key={number}
								{...navItem}
								iconOnly={this.props.type === NavBarTypes.IconOnly ? true : false}
								state={this.getState(navItem, number)}
								onClick={() => {
									this.onClick(navItem, number);
									this.setState({ showMenu: false });
									$(".atlas-theme").removeClass("showMenu");
								}}
							/>
						);
					})}
				</ul>
			</Fragment>
		);
	}

	render(): React.ReactNode {
		return (
			<React.Fragment>
				{this.props.utilNav && (
					<div className={`utilNav`}>
						<ButtonBar {...this.props.utilNav} />
					</div>
				)}
				{this.props.responsiveType ? (
					<Fragment>
						<MediaQuery maxWidth={this.props.maxWidth ? this.props.maxWidth : 576}>{this.renderMobile()}</MediaQuery>
						<MediaQuery minWidth={this.props.maxWidth ? this.props.minWidth : 577}>{this.renderDesktop()}</MediaQuery>
					</Fragment>
				) : (
					this.renderDesktop()
				)}
			</React.Fragment>
		);
	}
}
