import {
  GREGORIAN_MONTHS,
  GREGORIAN_WEEK_DAYS,
} from './Constants';

const localeLanguages = {
  en: {
    months: GREGORIAN_MONTHS,
    weekDays: GREGORIAN_WEEK_DAYS,
    weekStartingIndex: 0,
    getToday(gregorainTodayObject: any) {
      return gregorainTodayObject;
    },
    toNativeDate(date: any) {
      return new Date(date.year, date.month - 1, date.day);
    },
    getMonthLength(date: any) {
      return new Date(date.year, date.month, 0).getDate();
    },
    transformDigit(digit: any) {
      return digit;
    },
    nextMonth: 'Next Month',
    previousMonth: 'Previous Month',
    openMonthSelector: 'Open Month Selector',
    openYearSelector: 'Open Year Selector',
    closeMonthSelector: 'Close Month Selector',
    closeYearSelector: 'Close Year Selector',
    from: 'from',
    to: 'to',
    defaultPlaceholder: 'Select...',
    digitSeparator: ',',
    yearLetterSkip: 0,
    isRtl: false,
  }
};

const getLocaleDetails = () => {
  return localeLanguages.en;
};

export { localeLanguages };
export default getLocaleDetails;