import React from "react";
import Icon from './Icon';

export interface ErrorProps {
  message: string;
  type: ErrorType;
  context: ErrorContext;
  retry?: boolean;
}
export enum ErrorType {
  Generic,
  Connection,
  AccessDenied,
  NoData,
  FirstAction
}

export enum ErrorContext {
  Component,
  Page,
}

export default class Error extends React.Component<ErrorProps> {


    getIcon(): string
    {
        var classes: string[] = [];

        if (this.props.type === ErrorType.AccessDenied)
            classes.push('ban');

        if (this.props.type === ErrorType.Connection)
            classes.push('wifi-slash');

        if (this.props.type === ErrorType.Generic)
            classes.push('exclamation-triangle');

        if (this.props.type === ErrorType.NoData)
            classes.push('folder-open');

        if (this.props.type === ErrorType.FirstAction)
            classes.push('mouse-pointer');
        return classes.join(' ');
    }


  render(): React.ReactNode {
    if (this.props.context === ErrorContext.Component) {
      return (
        <div className="error card p-3 m-3">
          <h1 className="display-3"><Icon icon={this.getIcon()} type={"light"} /></h1>
          <p>{this.props.message}</p>
        </div>
      );
    } else {
      return <div className="error-page">
        <div className="error card p-3">
          <h1 className="display-3"><Icon icon={this.getIcon()} type={"light"} /></h1>
          <p>{this.props.message}</p>
        </div>
          
          </div>;
    }
  }
}
