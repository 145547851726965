import * as React from 'react'
import {inject, observer} from "mobx-react";
import {FC, useEffect, useState} from "react";
import {SearchUserInterface} from "../../model/SearchUser";
import medicalApi, {VaccineTypes, VaccineCompany} from "../../api/MedicalApi";
import {displayDate, formatPhoneNumber, usDateTime, toDate} from "../../common/Formatters";
import { AdvancedTable, IAdvancedTableColumn } from '@mit/hui';
import { RolesInterface } from '../../model/Roles';

interface PeopleListProps {
  searchUser?: SearchUserInterface;
  mitId?: string;
  roles?: RolesInterface;
}

const executeSortDesc = (a: any, b: any) => {
  const fieldName = 'start_datetime'
  let dateTimeA: moment.Moment = toDate(a[fieldName]!)
  let dateTimeB: moment.Moment = toDate(b[fieldName]!)
  return dateTimeB.diff(dateTimeA)
}

const PersonVaccineProofsComponent: FC<PeopleListProps> = ({searchUser, mitId, roles}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState<any>([]);
  const [vaccineType, setVaccineType] = useState<VaccineTypes[]>([]);
	const [vaccineCompany, setVaccineCompany] = useState<VaccineCompany[]>([]);


  useEffect(() => {
		initialiseData();
	}, []);


/*   useEffect(() => {
    if(mitId || searchUser && searchUser.person) {
      initialiseData()
    }
  }, [searchUser!.person, mitId]); */

  const initialiseData = () => {
    setIsLoading(true)
    Promise.all([
			medicalApi.fetchVaccineCompanies(),	
			medicalApi.fetchVaccineTypes(),	
			medicalApi.fetchPersonVaccineProofs(mitId ? mitId : searchUser!.person!.mitId)
		]).then(([company, vaccineType, vaccineProof])=>{
      setVaccineType(vaccineType)
      setVaccineCompany(company)
      remapProofs(vaccineProof.items || [], company);
      setIsLoading(false)
    })
  }

  const remapProofs = (vaccineProofs:any, vaccineProof: any) => {
    let remapped = vaccineProofs.map((proof: any) => {
      const companyName = vaccineProof.find((company: VaccineCompany) => company.medical_vaccine_company_guid === proof.company);

      return {
        ...proof,
        vaccine_status: vaccineStatus(proof.status)?.status,
        vaccine_type: proof.shots.length >= 2 ? `${companyName.company_name} (Dose ${proof.shots[0].shot_number} & Dose ${proof.shots[1].shot_number})` : `${companyName.company_name} (Dose ${proof.shots[0].shot_number})`,
        vaccine_date: displayDate(proof.shots[0].received_on)
      }

    })
    setData(remapped)
  }

  const vaccineStatus = (status: string) => {
		let vaccineStatus: any = {
			P : {status: "Pending", color: "status-pending" },
			A : {status: "Approved", color: "status-approved" },
			R : {status: "Rejected", color: "status-rejected" }
		}
		return vaccineStatus[status];
	}

  const columns: Array<IAdvancedTableColumn> = [
      {
        id: "vaccine_type",
        name: "Vaccine Type",
        sortable: true,
        options: {
            demandPopin: true,
            minWidth: 1000,
            popinText: "Vaccine Type"
        },
        filterable: false
      },
      {
        id: "vaccine_date",
        name: "Recieved On",
        sortable: true,
        options: {
            demandPopin: true,
            minWidth: 1000,
            popinText: "Recieved On"
        },
        filterable: false
      },
      {
          id: "vaccine_status",
          name: "Status",
          sortable: true,
          formatter: (item:any) => (
            <div className={` ${vaccineStatus(item.status)?.color}`}>
              <span>{item.vaccine_status}</span>
            </div>
          ),
          options: {
              demandPopin: true,
              minWidth: 1000,
              popinText: "Vaccine Status"
          },
          filterable: false
      },
    ];

  return <>
    <h4 className="covid-19-vaccine-proofs profile-table-headers">Vaccine Proof(s) Uploaded.</h4>
    <div className={'person-detail-container'}>
      <AdvancedTable 
        columns={columns}
        maxRows={1000}
        items={data}
        isLoading={isLoading}
        sortStateOptions={{
          enabled:true,
          key: 'covid-medical-person-vaccine-proofs'
        }}
      />
    </div>
  </>
}

export const PersonVaccineProofs = inject('searchUser', 'roles')(observer(PersonVaccineProofsComponent))