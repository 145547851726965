import Amplify, {Auth} from 'aws-amplify';
import {FederatedSignInOptionsCustom} from "@aws-amplify/auth/lib/types";

Amplify.configure({
    region: process.env.AMP_REGION,
    userPoolId: process.env.AMP_USER_POOL_ID,
    userPoolWebClientId: process.env.AMP_USER_POOL_WEB_CLIENT_ID,
    oauth: {
        domain: process.env.OATH_DOMAIN,
        scope: process.env.REACT_APP_OATH_SCOPE ? process.env.REACT_APP_OATH_SCOPE.split(' ') : [],
        redirectSignIn: process.env.OATH_REDIRECT_SIGN_ON,
        redirectSignOut: process.env.OATH_REDIRECT_SIGN_OUT,
        responseType: process.env.OATH_RESPONSE_TYPE,
        options: {
            AdvancedSecurityDataCollectionFlag: false
        }
    }
});

class AuthProvider {
    getToken = async (): Promise<any> => {
        return new Promise((resolve, reject) => {
            Auth.currentAuthenticatedUser().then((res: any) => {
                resolve(res.getSignInUserSession().accessToken.jwtToken)
            }).catch((err: any) => {
                console.log("not logged in", err);
                Auth.federatedSignIn({customProvider: process.env.CUSTOM_PROVIDER} as FederatedSignInOptionsCustom).then((res1: any) => {
                    console.log("login success", res1);
                }).catch((err1: any) => {
                    console.log("login err", err1);
                });
            });
        });
    }

    logout = async (): Promise<any> => {
        console.debug("calling Auth.signOut()");
        await Auth.signOut();
    }
}

const authProvider = new AuthProvider()
export default authProvider;
