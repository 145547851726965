import React from 'react';
// This is a module to print out nicely all the variables that are of importance

export interface BrandingProps
{
    theme: string,
}

export default class Branding extends React.Component<BrandingProps>
{ 
    render(): React.ReactNode 
    {
        return (
            <section>
                <h1>Colors</h1>
                <p>Ofcourse we have more than your first crayon set here. We are professionals.</p>
                <div className="bg-primary p-1 text-white">Primary Color</div>
                <div className="bg-secondary p-1 text-white">Secondary Color</div>
                <p></p>
                <div className="bg-success p-1 text-white">Success Color</div>
                <div className="bg-warning p-1 text-white">Warning Color</div>
                <div className="bg-danger p-1 text-white">Danger Color</div>
                <p></p>
                <div className="bg-info p-1 text-white">Info Color</div>
                <div className="bg-light p-1">Light Color</div>
                <div className="bg-dark text-white p-1">Dark Color</div>
                <br /><br />
                <h1>Gradients</h1>
                <p>Yes, the creators of the ATLAS brand and pretty much the whole design community like gradients. Deal with it.</p>
                <br /><br />
                <h1>Animations</h1>
                <p>We are all grown-ups here but humans, young and old, understand better through animation.</p>
                <br /><br />
                <h1>Interactive states</h1>
                <p>If you are a developer, you probably don't know what we are talking about. If we explain, you'll think "hover effect right"? Thank goodness we have this document for you to read and educate yourself. Swagger doesn't happen by itself.</p>
                <br /><br />
                <h1>Typography</h1>
                <p>It's simple, use these as part of the components and you will be fine. For curiosity, here are the list of fonts to be used:</p>
                <p><ul><li>Graphika</li></ul></p>
                <div><h1>Heading 1</h1></div>
                <div><h2>Heading 2</h2></div>
                <div><h3>Heading 3</h3></div>
                <div><h4>Heading 4</h4></div>
                <div><p>A paragraph of text</p></div>
                <br /><br />
                <h1>Spacing</h1>
                <p>Please don't try to figure out your own spacing that you believe works. We have 1034 variables that keeps this eco-system alive and well on all devices. Please, just don't.</p>
                <br /><br />
                <h1>Tone of Voice</h1>
                <p>If his unpleasant wounding has in some way enlightened the rest of you as to the grim finish beneath the glossy veneer of criminal life and inspired you to change your ways, then his injuries carry with it an inherent nobility, and a supreme glory. We should all be so fortunate. You say poor Toby? I say poor us.</p>
                <br /><br />
                <h1>Microcopy</h1>
                <p>Less is more, always.</p>
                <br /><br />
                <h1>Iconography</h1>
                <p>We simply use <a href="https://www.font-awesome.com" target="_blank" rel="noopener noreferrer">Font Awesome</a>, because they bring out new icons often, easy to work with, and they have <b>bold</b>, regular and light icons.</p>

            </section>
        );
    }
}