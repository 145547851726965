import BaseController from "./BaseController";

export interface Address {
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    postal_code?: string;
}
export interface ExtendedPerson {
    mit_id: string;
    krb_name: string;
    first_name: string;
    last_name: string;
    email?: string;
    phone?: string;
    gender?: string;
    date_of_birth?: string;
    type?: string;
    medical_schedule_irregular: boolean;
    address: Address;
    job_title?:string;
    student_year?:number;
    department?: string;
    resident: string;
    resident_food_delivery: string;
    vaccine_status: string;
    vaccine_hold_status: string
}

export interface FetchResponseCovidPerson {
    item?: ExtendedPerson;
    error?: any;
}

class CovidPersonApi extends BaseController {

    readonly url_person: string

    constructor() {
        super()
        this.url_person = `${this.apiHost}/${this.apiPathCovid}/person`;
    }

    fetchPerson = async (mit_id: string | number): Promise<FetchResponseCovidPerson> => {

        let response = await fetch(`${this.url_person}/${mit_id}`, await this.requestHeaderGet());

        if (response.status == 200) {
            let data = await response.json();
            return {item: data}
        } else {
            return {
                error: true
            }
        }
    }
}

const covidPersonApi = new CovidPersonApi();
export default covidPersonApi;
