import * as React from 'react';
import { TableVirtualizedProps } from './types';
import { AutoSizer, List, InfiniteLoader, ListRowRenderer, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import { MobileTableCard } from '../MobileTable/MobileTableCard'
import MobileTableFilter from '../MobileTableFilter'
const cache = new CellMeasurerCache({
  defaultHeight: 300,
  fixedWidth: true
});
class TableVirtualizedMobile extends React.PureComponent<TableVirtualizedProps> {
  static defaultProps = {
    headerHeight: 0,
    rowHeight: 279,
  };



  rowRenderer: ListRowRenderer = ({ index, isScrolling, key, parent, style }) => {
    const { columns, data, rowHeight, onRowClick } = this.props;
    if (data[index]) {
      return (
        <CellMeasurer
          cache={cache}
          columnIndex={0}
          key={key}
          parent={parent}
          rowIndex={index}
        >
          {({ measure, registerChild }: any) => (
            // eslint-disable-next-line
            <div
              ref={registerChild}
              key={key}
              style={{ ...style, height: '300px', paddingTop: index === 0 ? '0.25em' : '0em', paddingLeft: '0.2em', paddingRight: '0.2em' }}
            >
              <MobileTableCard measure={measure} row={data[index]} columnConfig={columns} />
            </div>
          )}
        </CellMeasurer>
      )
    }
    return <></>

  };


  render() {
    console.log("Here")
    const { filterConfig, data, onSort, columns, rowHeight, headerHeight, selectable, sort, onRowClick, lazyLoading, isRowLoaded, loadMoreRows, remoteRowCount, ...tableProps } = this.props;
    if (lazyLoading !== undefined && lazyLoading) {
      return (
        <div className="HuiTableVirtualizedMobile">

          {
            filterConfig !== undefined && <div style={{ marginBottom: '0.25em' }}><MobileTableFilter filterConfig={filterConfig} /></div>
          }
          <InfiniteLoader
            isRowLoaded={isRowLoaded}
            loadMoreRows={loadMoreRows}
            rowCount={remoteRowCount}
          >
            {({ onRowsRendered, registerChild }) => (
              <AutoSizer>
                {({ height, width }) => (
                  <List
                    deferredMeasurementCache={cache}

                    ref={registerChild}
                    onRowsRendered={onRowsRendered}
                    rowCount={remoteRowCount}
                    rowHeight={cache.rowHeight}

                    height={height}
                    rowRenderer={this.rowRenderer}
                    width={width}
                    data={data}
                  />
                )}
              </AutoSizer>
            )}

          </InfiniteLoader>
        </div>

      )
    }
    return (
      <div className="HuiTableVirtualizedMobile">
        <AutoSizer>
          {({ height, width }) => (
            <List
              ref="List"
              rowCount={remoteRowCount}
              rowHeight={
                279
              }
              height={height}
              rowRenderer={this.rowRenderer}
              width={width}
            />
          )}
        </AutoSizer>
      </div>
    );
  }
}

export default TableVirtualizedMobile;
