import React, { useEffect, useState } from 'react';
import { Textbox } from '../Textbox';

export interface InputBoxOption {
  title: string;
  helperText?: string;
  value?: any;
  selected?: boolean;
  name: string;
  required: boolean;
  showInput?: boolean;
  textboxProperties?: { [key: string]: any };
}

export interface CheckboxInputGroupProps {
  groupOptions: Array<InputBoxOption>;
  indeterminate?: boolean;
  group: string;
  label: string;
  inputMask?: string;
  name?: string;
  value?: any;
  onActionClick?: any;
  onChange?: (value: any) => void;
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  placeholderText?: string;
  properties?: { [key: string]: string };
  unselected?: boolean;
  register?: any; // https://react-hook-form.com/api#register
  errors?: any; // https://react-hook-form.com/api#errors
  setValue?: any; // https://react-hook-form.com/api#setValue
}

export const CheckboxInputGroup: React.FC<CheckboxInputGroupProps> = ({
  groupOptions,
  group,
  inputMask,
  name,
  onActionClick,
  onChange,
  onKeyUp,
  onFocus,
  placeholderText,
  properties,
  unselected,
  register,
  errors,
  setValue,
}) => {
  let [localGroupOptions, setLocalGroupOptions]: any = useState();
  let [selectedValue, setSelectedValue]: any = useState('');

  useEffect(() => {
    console.log('localOptions', localGroupOptions);
    // if (groupOptions !== undefined) {
    setLocalGroupOptions(groupOptions);
    //set defualt items for react-hook-form
    if (localGroupOptions === undefined) {
      onChange && onChange(groupOptions);
    }
    // }
  }, [groupOptions]);

  const onChangeCheckbox = (e: any, item: InputBoxOption) => {
    let newOptions = [];
    let checked = e.target.checked;
    if (localGroupOptions) {
      newOptions = localGroupOptions.map((it: InputBoxOption) => {
        if (it.name === item.name) {
          if (
            localGroupOptions.filter(
              (option: InputBoxOption) =>
                option.selected && option.name === item.name
            ).length ===
            localGroupOptions.filter(
              (option: InputBoxOption) => option.selected
            ).length
          ) {
            if (unselected) {
              it.selected = checked;
            } else {
              //never unselect if it is the last one
              it.selected = true;
            }
          } else {
            it.selected = checked;
          }
        } else if (!it.selected) {
          it.selected = false;
          it.value = null;
        }
        return it;
      });
    }
    setLocalGroupOptions(newOptions);
    onChange && onChange(newOptions);
    if (onActionClick) onActionClick(e);
  };

  const onChangeInput = (e: any, item: InputBoxOption) => {
    let value = e.target.value;
    console.log(item.name, value);
    if (localGroupOptions) {
      let newOptions = localGroupOptions.map((it: InputBoxOption) => {
        if (it.name === item.name) {
          it.value = value;
        }
        return it;
      });
      setLocalGroupOptions(newOptions);
      onChange && onChange(newOptions);
    }
    setSelectedValue(value);
  };

  return (
    <div className="radio-group">
      {localGroupOptions?.map((item: InputBoxOption, index: number) => {
        // const id = uuidv4();
        return (
          <div className="form-check" key={item.name}>
            <input
              key={item.name + item.selected}
              className="form-check-input"
              type="checkbox"
              id={item.name}
              name={`${name}.${item.name}-selected`}
              defaultChecked={item.selected || false}
              radioGroup={group}
              onClick={(e) => {
                onChangeCheckbox(e, item);
              }}
              checked={item.selected || false}
              {...properties}
            />
            <label className="form-check-label">
              <b>{item.title}</b>{' '}
              <span className="font-italic">{item.helperText}</span>
            </label>
            <br />
            {(item.showInput === undefined || item.showInput) && item.selected && (
              <div
                style={{
                  display: !item.selected ? 'none' : 'inherit',
                }}
              >
                <Textbox
                  inputMask={inputMask}
                  onKeyUp={onKeyUp}
                  required={item.required}
                  name={`${name}.${item.name}`}
                  placeholderText={placeholderText || 'Please specify a value'}
                  value={item.value}
                  register={register}
                  errors={errors}
                  setValue={setValue}
                  onFocus={onFocus}
                  onChange={(e: any) => onChangeInput(e, item)}
                  {...item.textboxProperties}
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
