import * as React from 'react';
import { defaults, TableRowProps } from './types';

const TableRow: React.FC<React.PropsWithChildren<TableRowProps>> = (props: React.PropsWithChildren<TableRowProps>) => {
  const finalProps = { ...defaults, ...props };
  const { component, hover, selected, style, classes, onClick } = finalProps;
  let finalClasses = 'HuiTableRow';
  finalClasses += selected ? ' HuiTableRowSelected' : '';
  finalClasses += hover ? ' HuiTableRowHover' : '';
  const CustomTag = `${component}` as keyof JSX.IntrinsicElements;
  if (classes) finalClasses += ` ${classes}`;
  return (
    <CustomTag onClick={onClick} className={finalClasses} style={style}>
      {props.children}
    </CustomTag>
  );
};

export default TableRow;
