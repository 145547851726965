import React from 'react';

export interface NavLogoProps
{
    logoUrl: string,
    appName: string
}
 
//this needs to become proper logo info
export default class NavLogo extends React.Component<NavLogoProps>
{
    render(): React.ReactNode
    {
        return (

            <a className={`navbar-brand`} href="/#">
                <img alt="ATLAS" src={this.props.logoUrl} role="none" />
                {this.props.appName}
            </a >

        );
    }
}