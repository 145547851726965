import * as React from "react";
import { Fragment, useEffect } from "react";
import "./scss/main.scss";
import { BrowserRouter } from "react-router-dom";
import ErrorBoundary from "./components/common/ErrorBoundary";
import ProfileView from "./components/ProfileView";
import { peopleStore } from "./model/PeopleStore";
import { Loader, AtlasPage, Error, ErrorType, ErrorContext, NavBar, NavBarTypes, NavItemState, NavItemProps, ResponsiveTypes } from "@mit/hui";
import { inject, observer } from "mobx-react";
import { CONTACT_SUPPORT_LINK } from "./common/Defaults";
import UserProfileStore from "./model/UserProfileStore";
import userController from "./api/UserController";
import { PageError } from "./types/PageErrors";
import { covidNav } from "./model/CovidNav";
import { useState } from "react";
import holds from "./model/Holds";
import vaccineHolds from "./model/VaccineHolds";
import medicalApi, { FetchFollowUpsResponse, FetchHoldsResponse, FluShotDetailResponse, TextItem } from "./api/MedicalApi";
import Home from "./Home";
import ReleaseNotesComponent from "./components/release-notes/ReleaseNotes";
import contactTracingApi, { FetchCasesResponse } from "./api/ContactTracingApi";
import roles from "./model/Roles";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import featureController from "./api/FeatureController";
import dataController from "./api/DataController";

const App = () => {
	const [userProfileStoreState, setUserProfileStoreState] = useState<UserProfileStore | undefined>(undefined);
	const [pageError, setPageError] = useState<PageError>({ error: false, errorText: "" });
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [title, setTitle] = useState<string>("Follow-up Required");
	const [showReleaseNotes, setShowReleaseNotes] = useState(false);

	useEffect(() => {}, [covidNav!.activePage]);

	const navData: any[] = [
		{
			icon: "phone-laptop",
			text: "Search",
			iconOnly: false,
			to: "search",
			onClick: () => {
				setTitle("Search");
				covidNav!.activePage = "search";
			},
			overrideState: (item: any) => {
				return covidNav!.activePage === "search" && window.location.pathname.includes("/search") ? NavItemState.Active : NavItemState.None;
			},
		},
		{
			icon: "syringe",
			text: "Flu Shots",
			iconOnly: false,
			to: "flushots",
			onClick: () => {
				setTitle("Flu Shots");
				covidNav!.activePage = "flushots";
			},
			overrideState: (item: any) => {
				return covidNav!.activePage === "flushots" && window.location.pathname === "/flushots" ? NavItemState.Active : NavItemState.None;
			},
		},
	];

	covidNav &&
		covidNav!.features &&
		covidNav!.features["covid19.medical.vaccine.visible"] &&
		navData.push({
			icon: "shield-check",
			text: "Covid Vaccine",
			iconOnly: false,
			to: "vaccine",
			onClick: () => {
				setTitle("Covid Vaccine");
				covidNav!.activePage = "vaccine";
			},
			overrideState: (item: any) => {
				return covidNav!.activePage === "vaccine" && window.location.pathname === "/vaccine" ? NavItemState.Active : NavItemState.None;
			},
		});

	useEffect(() => {
		document.documentElement.lang = "eng";

		// Prevent form submission on enter key press inside inputs
		const listener = (e: any) => {
			if (e.keyIdentifier === "U+000A" || e.keyIdentifier === "Enter" || e.keyCode === 13) {
				if (e.target.nodeName === "INPUT") {
					e.preventDefault();
					return false;
				}
			}
		};
		document.addEventListener("keydown", listener, true);

		const featurePromise = userController.fetchFeatures();
		const profilePromise = userController.fetchProfile();
		const statusesPromise = medicalApi.fetchHoldStatuses();
		const vacineHoldStatusesPromise = medicalApi.fetchVaccineHoldStatuses();
		const textsPromise = medicalApi.fetchTexts();
		const contactTracingStatusPromise = contactTracingApi.fetchStatus();
		const rolesPromise = contactTracingApi.fetchRoles();
		const exemptionTypesPromise = medicalApi.fetchExemptionTypes();
    const featuresPromise = featureController.fetchFeatures();
    const textPromise = dataController.fetchArray('texts')

		Promise.all([featurePromise, profilePromise, statusesPromise, textsPromise, rolesPromise, vacineHoldStatusesPromise, contactTracingStatusPromise,exemptionTypesPromise, featuresPromise, textPromise])
			.then(([featureResponse, profileResponse, statusesResponse, textsResponse, rolesResponse, vacineStatusesResponse, contactTracingStatusResponse, exemptionTypesResponse, featuresResponse, textResponse]) => {
				if (contactTracingStatusResponse.items) {
					covidNav.contactTracingStatus = contactTracingStatusResponse.items;
				}

				if (textsResponse.items) {
					let texts = textsResponse.items.reduce((all: any, current: TextItem) => {
						return {
							...all,
							[current.id]: current.text,
						};
					}, {});

					covidNav.texts = texts;
				}

				let permissions = featureResponse.reduce((obj: any, curr: any) => {
					return { ...obj, [curr.id]: curr.value };
				}, {});

				//@ts-ignore
				vaccineHolds.statuses = vacineStatusesResponse.items;
				holds.exemptionTypes = exemptionTypesResponse;
				//@ts-ignore
				holds.statuses = statusesResponse.items;
				roles.roles = rolesResponse.items;

				holds.features = featuresResponse.data?.features
				holds.appText = textResponse.items

				covidNav.features = permissions;
				if (rolesResponse.items.length > 0) {
					if (profileResponse.person) {
						setUserProfileStoreState(
							new UserProfileStore({
								kerberosId: profileResponse.person.kerberosId,
								firstName: profileResponse.person.firstName,
								lastName: profileResponse.person.lastName,
								displayName: profileResponse.person.displayName,
							})
						);
					}
				} else {
					setPageError({ error: true, errorText: `You are not authorized for this application. For more information, please contact ${CONTACT_SUPPORT_LINK}.` });
				}
        // setFeatures(featuresResponse)
				setIsLoading(false);
			})
			.catch((error) => {
				setPageError({ error: true, errorText: `An error occurred and the application could not be loaded. If the problem persists, please contact ${CONTACT_SUPPORT_LINK}.` });
				setIsLoading(false);
			});
	}, []);

	const refreshPage = () => {
		if (covidNav!.activePage === "follow-up") {
			reloadDataFollowUp();
		}
		if (covidNav!.activePage === "vaccine-hold") {
			reloadDataVaccineHold();
		}
		if (covidNav!.activePage === "completed-tests") {
			reloadDataCompleted();
		}
		if (covidNav!.activePage === "flushots") {
			reloadDataFluShots();
		}
		if (covidNav!.activePage === "vaccine") {
			reloadDataVaccine();
		}
		if (covidNav!.activePage === "search") {
			window.location.reload();
		}
		if (covidNav!.activePage === "contact-tracing") {
			reloadDataContactTracing();
		}
	};

	const reloadDataFollowUp = async () => {
		covidNav!.globalTableIsLoading = true;
		const followUpResponse: FetchHoldsResponse = await medicalApi.fetchAllHolds();
		if (followUpResponse.error) {
			//todo: handle error
		} else {
			if (followUpResponse.items) {
				peopleStore.setFollowUpList(followUpResponse.items);
				covidNav!.globalTableIsLoading = false;
			}
		}
	};

	const reloadDataVaccineHold = async () => {
		covidNav!.globalTableIsLoading = true;
		const followUpResponse: FetchHoldsResponse = await medicalApi.fetchAllVaccineHolds();
		if (followUpResponse.error) {
			//todo: handle error
		} else {
			if (followUpResponse.items) {
				peopleStore.setVaccineHoldList(followUpResponse.items);
				covidNav!.globalTableIsLoading = false;
			}
		}
	};

	const reloadDataCompleted = async () => {
		covidNav!.globalTableIsLoading = true;
		const resultsResponse: FetchFollowUpsResponse = await medicalApi.fetchPersonResults(covidNav.currentDateString);
		if (resultsResponse.error) {
			//todo: handle error
		} else {
			if (resultsResponse.items) {
				peopleStore.setCompletedList(resultsResponse.items);
				covidNav!.globalTableIsLoading = false;
			}
		}
	};

	const reloadDataFluShots = async () => {
		covidNav!.globalTableIsLoading = true;
		const fluShotDetailResponse: FluShotDetailResponse = await medicalApi.fetchFluShotResults(covidNav.currentDateString);
		if (fluShotDetailResponse.error) {
			//todo: handle error
		} else {
			if (fluShotDetailResponse.items) {
				peopleStore.setFluShotList(fluShotDetailResponse.items);
				covidNav!.globalTableIsLoading = false;
			}
		}
	};

	const reloadDataVaccine = async () => {
		covidNav!.globalTableIsLoading = true;
		covidNav!.currentDateString = "";
		const vaccineDetailResponse: FluShotDetailResponse = await medicalApi.fetchVaccineResults(covidNav.currentDateString);
		if (vaccineDetailResponse.error) {
			//todo: handle error
		} else {
			if (vaccineDetailResponse.items) {
				peopleStore.setVaccineList(vaccineDetailResponse.items);
				covidNav!.globalTableIsLoading = false;
			}
		}
	};

	const reloadDataContactTracing = async () => {
		covidNav!.globalTableIsLoading = true;
		peopleStore.setContactTracingList([]);
	};

	const handleShowReleaseNotes = () => {
		setShowReleaseNotes(true);
	};

	const handleReleaseNotesClose = () => {
		setShowReleaseNotes(false);
	};

	const utilityNavData: NavItemProps[] = [
		{
			icon: "question-square",
			text: "Help",
			iconOnly: false,
			onClick: () => {
				window.open("mailto:covidapps-help@mit.edu");
			},
		},
		{
			icon: "calendar",
			text: "Time Management",
			overrideState: (item: any) => {
				return NavItemState.None;
			},
			onClick: () => {
				setTitle("Time Management");
			},
			to: "time-management",
			state: roles?.roles?.includes("medical") ? NavItemState.None : NavItemState.Invisible,
			iconOnly: false,
		},
		{ icon: "bell", text: "What's new", onClick: handleShowReleaseNotes, iconOnly: false },
		{ icon: "sync", text: `Reload ${title}`, iconOnly: false, onClick: refreshPage },
	];

	const getEnv = () => {
		if (process.env.ENV === "prod") return "production";

		if (process.env.ENV === "test" || process.env.ENV === "release") return "staging";

		if (process.env.ENV === "dev" || process.env.ENV === "develop") return "dev";

		if (process.env.ENV === "local") return "dev";

		return "production";
	};

	return (
		<>
			<ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
			{pageError.error && <Error context={ErrorContext.Page} type={ErrorType.AccessDenied} message={pageError.errorText} />}
			{(isLoading || pageError.error) && <Loader theme="medical" contactEmail="nist-physec@mit.edu" hasAccess={!pageError.error} exit={!isLoading} name={"Covid Medical"} />}
			{!isLoading && !pageError.error && (
				<Fragment>
					<ErrorBoundary errorComponent={<Error message={"Something went wrong."} type={ErrorType.Generic} context={ErrorContext.Page} />}>
						{/* <ToastProvider
                        autoDismiss
                        autoDismissTimeout={5000}
                        placement="bottom-center"
                    > */}
						<BrowserRouter>
							<AtlasPage
								name="COVID Medical"
								contentAriaLabel="COVID Medical Content Container."
								environment={getEnv()}
								utilityNavigation={utilityNavData}
								subtitle="Testing and Admin Console"
								theme="medical"
								utilMaxWidth={1440}
								utilMinWidth={1441}
								gaId="G-XG7JEHXLVC"
								profile={<ProfileView userProfileStore={userProfileStoreState} />}
								navigation={<NavBar responsiveType={ResponsiveTypes.CollapseSome} type={NavBarTypes.IconText} tabs={navData} />}
								content={<Home />}
							/>
						</BrowserRouter>
						{/* </ToastProvider> */}
					</ErrorBoundary>

					<ReleaseNotesComponent show={showReleaseNotes} onClose={handleReleaseNotesClose} />
				</Fragment>
			)}
		</>
	);
};

const AppWrapper = inject("peopleStore", "holds", "covidNav")(observer(App));

export default AppWrapper;
