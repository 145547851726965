import React from 'react';

export interface SpacerProps
{
    size?: string,//maybe this should be enum or a number
}

export default class Spacer extends React.Component<SpacerProps>
{
    public static defaultProps =
        {
            size: 1,
        };
 
    render(): React.ReactNode 
    {
        return (
            <div className={`p-${(this.props.size)}`} ></div>
        );
    }
}