import * as React from 'react';
import { Button, ButtonType } from '../../Button';
import {
    getAttributeCols,
    getPinnedCols,
    getPinnedColsHeaders,
    getRemainingCols,
} from './utils';
export const MobileTableCard: React.FC<{
    measure?: any,
    row: any,
    columnConfig: any
}> = ({ measure, row, columnConfig }) => {
    const [showExtra, setShowExtra] = React.useState(false)
    const pinnedColsHeaders = getPinnedColsHeaders(row, columnConfig);
    const pinnedCols = getPinnedCols(row, columnConfig);
    const attributeCols = getAttributeCols(row, columnConfig);
    const unpinnedCols = getRemainingCols(row, columnConfig);
    React.useEffect(() => {
        if (measure !== undefined) {
            measure()

        }
    }, [/*Here can enter some value to call again the content inside useEffect*/])
    return (
        <div className={'HuiCard'}>
            <div className="HuiPinnedColsContainer">
                <div className="HuiMobilePinnedColsHeaders">
                    {pinnedColsHeaders.map((col: any, key: number) => {
                        return (
                            <div
                                key={key}
                                style={{
                                    width: `calc(100%/${pinnedColsHeaders.length})`,
                                    // border: '1px solid #e4e4e4',
                                    padding: '1em',
                                }}
                            >
                                {col}
                            </div>
                        );
                    })}
                </div>
                <div className="HuiMobilePinnedCols">
                    {pinnedCols.map((col: any, key: number) => {
                        return (
                            <div
                                key={key}
                                style={{
                                    width: `calc(100%/${pinnedColsHeaders.length})`,
                                    // border: '1px solid #e4e4e4',
                                    padding: '1em',
                                }}
                            >
                                {col}
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="HuiAttributeColsContainer">
                <div className="HuiAttributeCols">
                    {attributeCols.map((col: any, key: number) => {
                        return <div key={key}>{col}</div>;
                    })}
                </div>
            </div>
            {showExtra && (
                <div className="HuiUnpinnedColsContainer">
                    <div className="HuiMobileUnpinnedCols">
                        {unpinnedCols.map((col: any) => {
                            return <div>{col}</div>;
                        })}
                    </div>
                </div>
            )}
            <div className="HuiCardButton">
                <Button
                    type={ButtonType.Icon}
                    block={true}
                    icon={!showExtra ? 'chevron-down' : 'chevron-up'}
                    onClick={() => {
                        setShowExtra(!showExtra);
                        if (measure !== undefined) {
                            setTimeout(() => {
                                measure()
                            }, 100)
                        }


                    }}
                />
            </div>
        </div>
    )

}