import React from 'react';

import NavItem, { NavItemProps } from './NavItem';
import NavLogo, { NavLogoProps } from './NavLogo';

export interface MenuBarProps
{
    type: string,
    tabs: NavItemProps[],
    utilityMenu: NavItemProps[],
    navLogo: NavLogoProps,
    contained: boolean
}
 
//this should not be "tabs"
export default class MenuBar extends React.Component<MenuBarProps>
{
    // need to add the href for the log
    // need to make this be A LOT better for mobile
    render(): React.ReactNode
    {

        if (this.props.contained)
        {
            return (
                <nav className={`navbar navbar-expand-sm ${this.props.type}`}>
                    <div className={`container`}>
                        {/*Need to make sure the logo hovers correctly*/}
                        <NavLogo {...this.props.navLogo} />
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="fas fa-bars"></i>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav">
                                {
                                    this.props.tabs.map((navItem: NavItemProps, number: number) => (
                                        <NavItem {...navItem} key={number} />
                                    ))
                                }
                            </ul>
                        </div>
                        <div className="form-inline">
                            <ul className="navbar-nav">
                                {
                                    this.props.utilityMenu.map((navItem: NavItemProps, number: number) => (
                                        <NavItem {...navItem} key={number} />
                                    ))
                                }
                            </ul>
                        </div>

                    </div>

                </nav>
            );
        }
        else
        {
            return (
                <nav className={`navbar navbar-expand-sm ${this.props.type}`}>

                    {/*Need to make sure the logo hovers correctly*/}
                    <NavLogo {...this.props.navLogo} />
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <i className="fas fa-bars"></i>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav">
                            {
                                this.props.tabs.map((navItem: NavItemProps, number: number) => (
                                    <NavItem {...navItem} key={number} />
                                ))
                            }
                        </ul>
                    </div>
                    <div className="form-inline">
                        <ul className="navbar-nav">
                            {
                                this.props.utilityMenu.map((navItem: NavItemProps, number: number) => (
                                    <NavItem {...navItem} key={number} />
                                ))
                            }
                        </ul>
                    </div>



                </nav>
            );
        }

    }

}