import React, {useEffect, useState} from 'react';
import moment, {Moment} from 'moment'
import {CalendarInput, ICalendarInputProps} from "../Calendar/CalendarInput";
import {AvailabilityControl, Button} from "../../index";
import {DefaultSelectedType, SelectionType} from "./AvailabilityControl";

interface Props {
  calendarProps?: ICalendarInputProps;
  startTime?: string;
  endTime?: string;
  handleSelection?: (selection: SelectionType[]) => void;
  handleInspect?: (selection: SelectionType) => void;
  containerClass?: string
  hideCalendar?: boolean
  openDate?: string // YYYY-MM-DD
  closeDate?: string // YYYY-MM-DD
  selections?: DefaultSelectedType[]
  readonly?: boolean
  hourSlots?: boolean
}

interface DateSelectType {
  from: {
    day: number
    month: number
    year: number
  }
  to: {
    day: number
    month: number
    year: number
  }
}

interface RenderDateType {
  day: string
  date: string
  fullDate: string
}

export const AvailabilityRange: React.FC<Props> = (
  {
    calendarProps,
    startTime, endTime,
    handleSelection,
    containerClass,
    hideCalendar,
    openDate,
    closeDate,
    selections,
    readonly,
    handleInspect,
    hourSlots
  }) => {
  const [selectedPage, setSelectedPage] = useState<number>(0)
  const [maxPage, setMaxPage] = useState<number>(0)
  const [dateRange, setDateRange] = useState<number>(0)
  const [firstDate, setFirstDate] = useState<string>()
  const [lastDate, setLastDate] = useState<string>()

  useEffect(() => {
    if (!openDate || !closeDate) {
      return
    }
    const startMoment = moment(openDate);
    const endMoment = moment(closeDate)
    setDates(startMoment, endMoment)
  }, [openDate, closeDate])

  const getRange = (value: DateSelectType) => {
    if(!value.from || !value.to) {
      return
    }
    const {from, to} = value;
    const startDate = moment(`${from.month}-${from.day}-${from.year}`)
    const endDate = moment(`${to.month}-${to.day}-${to.year}`)
    setDates(startDate, endDate)
  }

  const setDates = (startDate: Moment, endDate: Moment) => {
    const numDays = endDate.diff(startDate, 'days') + 1
    const numPages = Math.floor(numDays/7) + (numDays % 7 === 0 ? 0 : 1)
    setMaxPage(numPages - 1)
    setDateRange(numDays)
    setFirstDate(startDate.format())
    setLastDate(endDate.format())
  }

  const getClass = (index: number) => {
    if (index < selectedPage) {
      return 'availability-range-grid availability-range-left'
    } else if (index === selectedPage) {
      return 'availability-range-grid availability-range-active'
    } else {
      return 'availability-range-grid availability-range-right'
    }
  }

  const renderGrid = () => {
    const datesArray: RenderDateType[] = []
    for (let i = 0; i < dateRange; i++) {
      const workingDay = moment(firstDate).add(i, 'days')
      datesArray.push({
        day: workingDay.format('dddd'),
        date: workingDay.format('MMM Do'),
        fullDate: workingDay.format('MM-DD-YYYY')
      })

    }
    const renderArray = datesArray.reduce((result:RenderDateType[][], item, index) => {
      const chunkIndex = Math.floor(index/7);
      if(!result[chunkIndex]) {
        result[chunkIndex] = []
      }
      result[chunkIndex].push(item)
      return result
    }, [])

    if (!renderArray.length) {
      return (
        <AvailabilityControl
          type={'range'}
          slotsPerHour={2}
          startTime={startTime || '08:00'}
          endTime={endTime || '17:00'}
          containerClass={getClass(0)}
          readonly={true}
        />
      )
    }

    const lastWeek = renderArray[renderArray.length -1];
    const length = 7 - lastWeek.length
    for (let i = 0; i < length; i++) {
      lastWeek.push({
        day: '',
        date: '',
        fullDate: ''
      })
    }

    return renderArray.map((item, index) => {
      if (index < selectedPage - 1 || index > selectedPage + 1) {
        return null
      }
      return (
        <AvailabilityControl
          type={'range'}
          slotsPerHour={2}
          startTime={startTime || '08:00'}
          endTime={endTime || '17:00'}
          containerClass={getClass(index)}
          customDays={item}
          handleInspect={handleInspect}
          handleSelection={handleSelection}
          readonly={readonly}
          selections={selections}
          hourSlots={hourSlots}
        />
      )
    })
  }

  return (
    <div className={`${containerClass || '' } availability-range-container`}>
      {!hideCalendar &&
        <CalendarInput
          id={calendarProps?.id || 'availability-range-calendar'}
          onChange={(v: DateSelectType) => getRange(v)}
          isRangeSelected={true}
        />
      }
      <div className="availability-range-header">
        {selectedPage > 0 ? <Button type={4} icon="chevron-left" onClick={() => setSelectedPage(selectedPage-1)} text="Previous"/> : <div />}
        <p>{firstDate ? `${moment(firstDate).format('MMMM DD')} - ${moment(lastDate).format('MMMM DD')}` : `Week ${selectedPage + 1}`}</p>
        {selectedPage < maxPage ? <Button type={4} icon="chevron-right" onClick={() => setSelectedPage(selectedPage+1)} textBefore="Next"/> : <div />}
      </div>
      <div className="availability-range-grid-container" >
        {renderGrid()}
      </div>
    </div>
  )
}
