import React from 'react'
import { Button, ButtonProps } from '../Button';
import { getNestedObjectValue } from './';

export interface IAdvancedTableExportCsvColumn {
    id: string;
    name: string;
    formatter?: (item: any) => string;
}

export interface IAdvancedTableExportCsvProps {
    button: ButtonProps;
    columns: IAdvancedTableExportCsvColumn[];
    fileName: string;
    items: any[];
    customHeader?: string; //add above default header    
}

export const AdvancedTableExportCsv: React.FC<IAdvancedTableExportCsvProps> = ({ button, columns, fileName, items, customHeader }) => {
    const newLineChar = '\n';

    const downloadFile = () => {
        let csvContent = createFile();
        let pom = document.createElement('a');
        let blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        let url = URL.createObjectURL(blob);
        pom.href = url;
        pom.setAttribute('download', `${fileName}.csv`);
        // pom.setAttribute('download', `ELO ${dateString}.csv`);
        pom.click();
    }

    const createFile = () => {
        let content = '';
        let row = [];

        if (customHeader) {
            content += customHeader;
        }
        for (let col of columns) {
            row.push(col.name);
        }
        
        content += row.map((name: any) => (`"${name}"`)).join(',') + newLineChar;

        for (let item of items) {
            row = [];
            for (let col of columns) {
                if (col.formatter) {
                    row.push(col.formatter(item))
                } else {
                    row.push(getNestedObjectValue(item, col.id));
                }
            }

            content += row.map((name: any) => (`"${name}"`)).join(',') + newLineChar;
        }
        return content;
    }

    return (
        <Button  {...button} onClick={() => downloadFile()} />
    )
}