import * as React from "react";

interface IGridProps {
    showGutters?: boolean;
    children: React.ReactNode;
}
export class GridContainer extends React.Component<IGridProps> {
    public render() {
      return (
        <div className={`row ${(!this.props.showGutters) && 'no-gutters'}`}>
            {this.props.children}
        </div>
      );
    }
}