import authProvider from "./AuthProvider";
import BaseController from "./BaseController";
import { ApiPersonDetail } from "./MedicalApi";

export interface PersonAws {
    mitid: string;
    kerberos_id: string;
    first_name: string;
    last_name: string;
    display_name: string;
}

export interface PersonAutocomplete {
    //id: number;
    label: string;
    mitId: string;
    kerberosId: string;
    firstName: string;
    lastName: string;
    displayName: string;
}

export const peopleResponseConverterAws = (people: PersonAws[]): PersonAutocomplete[] => {

    return people.map((awsPerson: PersonAws, index:number) => ({
        id: index + 1,
        label: `${awsPerson.display_name} (${awsPerson.kerberos_id})`,
        kerberosId: awsPerson.kerberos_id,
        mitId: awsPerson.mitid,
        firstName: awsPerson.first_name,
        lastName: awsPerson.last_name,
        displayName: awsPerson.display_name,
        
    }))
}

export interface FetchPeopleResponseAws {
    people?: PersonAutocomplete[];
    error?: any;
}

export interface FetchPeopleDetailResponse {
    detail?: ApiPersonDetail;
    error?: any;
}

class PeopleController extends BaseController {
    readonly url_people: string;
    readonly url_people_detail: string;

    constructor() {
        super()
        this.url_people = `${this.apiHost}/${this.apiPathDigitalId}/search?q=`
        this.url_people_detail = `${this.apiHost}/${this.apiPathCovid}/person/`
    }

    fetchPeopleAws = async (query: string): Promise<FetchPeopleResponseAws> => {
        let response = await fetch(this.url_people + query, await this.requestHeaderGet())
        if (response.status == 200) {
            let people = await response.json()
            if (people) {
                let peopleResults: PersonAutocomplete[] = peopleResponseConverterAws(people)
                return {
                    people: peopleResults
                }
            } else {
                return {
                    error: true
                }
            }
        } else {
            return {
                error: true
            }
        }
    }

    async putResident(id: string, food_delivery: boolean): Promise<any> {

        let body = {
            food_delivery: food_delivery
        }

        let token = await authProvider.getToken();
        const putOptions: RequestInit = {
            method: "PUT",
            headers: new Headers({
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify(body)
        };

        let response = await fetch(this.url_people_detail + `${id}/resident`, putOptions);

        if (response.status === 200) {
            let data = await response.json()
            return {items: data}
        } else {
            return { error: true };
        }
    }

    fetchPersonAws = async(kerbId: string) : Promise<FetchPeopleDetailResponse> => {
        let response = await fetch(this.url_people_detail + kerbId, await this.requestHeaderGet())
        if (response.status === 200) {
            let detail: ApiPersonDetail = await response.json();
            if (detail) {
                return { detail: detail };
            } else {
                return { error: true }
            }
        } else {
            return { error: true }
        }
    }
}

const peopleController = new PeopleController()
export default peopleController