import React, {FC} from 'react';
import Icon from './Icon'

export interface LabelProps
{
    //required
    text: string,
   
    //optional
    target?: string
    icon?: string,
}

const Label: FC<LabelProps> = ({target, text, icon}) => {
    return (
        <>
            {icon && <Icon type={"light"} icon={icon} /> }
            <label htmlFor={`${target}`} >{text}</label>
        </>
    );
}

export default Label;