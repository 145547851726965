import * as React from "react";
import { render } from "react-dom";
import App from "./App";
import { peopleStore } from "./model/PeopleStore";
import holds from "./model/Holds";
import { Provider } from "mobx-react";
import * as serviceWorker from "./serviceWorker";
import { covidNav } from "./model/CovidNav";
import roles from "./model/Roles";

render(
	<React.StrictMode>
		<Provider covidNav={covidNav} holds={holds} roles={roles} peopleStore={peopleStore}>
			<link
				rel="stylesheet"
				href="https://pro.fontawesome.com/releases/v5.15.4/css/all.css"
				integrity="sha384-rqn26AG5Pj86AF4SO72RK5fyefcQ/x32DNQfChxWvbXIyXFePlEktwD18fEz+kQU"
				crossOrigin="anonymous"></link>
			<App />
		</Provider>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
