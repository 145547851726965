import React from 'react';

export interface SectionProps
{
    contents: any,
    verticalHeight?: number,
    alignment: string,
    backgroundColor: string,
    backgroundImage: string
}
 
export default class TemplateSection extends React.Component<SectionProps>
{
    render(): React.ReactNode
    {
        return (
            // This item will have to be a style element. It's not wrong, it's a flexibility we afford
            <section className={`text-${this.props.alignment} ${this.props.backgroundColor}`} style={{ height: `${this.props.verticalHeight}vh`, backgroundImage: `url(${this.props.backgroundImage})` }}>
                {
                    this.props.contents
                }
            </section>
        );
    }
}