import React, { Fragment } from "react";
import { AutoCompleteFilterOn } from "./AutoComplete";
import { Dropdown } from "./Dropdown";
import { DropDownProps } from "./Dropdown/Dropdown";

import ListItem, { ListItemProps, ListItemState } from "./ListItem";
import { Textbox, TextboxProps, TextboxType } from "./Textbox";

export interface FilteredListProps {
	name: string;
	searchOptions?: TextboxProps;
	type?: "default" | "categorize";
	items: ListItemProps[];
	loadingText?: string;
	compact?: boolean;
	selectable?: boolean;
	height: number; //this is to be represented as vertical height in the CSS
	isLoading?: boolean;
	isBusy?: boolean;
	isEmpty?: boolean;
	onSearch?: (keyword: string) => void;
	dropDownProps?: DropDownProps;
	additionalFilterOn?: AutoCompleteFilterOn;
}

interface FilteredListState {
	keyword: string;
	data: ListItemProps[];
	selectedIndex: number;
}

export default class FilteredList extends React.Component<FilteredListProps, FilteredListState> {
	public static defaultProps = {
		compact: false,
		selectable: true,
		isEmpty: false,
	};

	constructor(props: FilteredListProps) {
		super(props);

		let activeItem = -1;

		this.props.items.map((itm: ListItemProps, index: number) => {
			if (itm.state === ListItemState.Active) activeItem = index;

			itm.itemIndex = index;
		});

		this.state = {
			keyword: "",
			data: this.filterData(""),
			selectedIndex: activeItem,
		};
	}

	componentDidUpdate(prevProps: FilteredListProps) {
		if (this.props.items !== prevProps.items) {
			this.setState({
				data: this.props.onSearch ? this.props.items : this.filterData(this.state.keyword),
				selectedIndex: this.getActiveIndex(),
			});
		}
	}

	getActiveIndex() {
		let activeIndex = -1;
		if (this.props.items) {
			this.props.items.map((itm: ListItemProps, index: number) => {
				if (itm.state === ListItemState.Active) {
					activeIndex = index;
				}
			});
		}
		return activeIndex;
	}

	onKeyUp(element: HTMLInputElement & EventTarget): void {
		var keyword = element.value;

		if (this.props.onSearch) {
			this.props.onSearch(keyword);
			this.setState({
				keyword: keyword,
			});
		} else {
			this.setState({
				keyword: keyword,
				data: this.filterData(keyword),
			});
		}
	}

	getState(item: ListItemProps): ListItemState | undefined {
		if (!this.props.selectable) return ListItemState.None;

		if (this.state.keyword) return ListItemState.None;

		if (item.state === ListItemState.Disabled) return ListItemState.Disabled;

		return this.state.selectedIndex === item.itemIndex ? ListItemState.Active : ListItemState.None;
	}

	onClick(item: ListItemProps): boolean {
		if (this.props.selectable) {
			this.setState({
				keyword: "",
				data: this.state.data,
				selectedIndex: item.itemIndex ? item.itemIndex : 0,
			});
		}

		return true;
	}

	onTextboxEndIconClick(): void {
		this.props.onSearch && this.props.onSearch("");

		this.setState({
			keyword: "",
			data: this.filterData(""),
		});
	}

	filterData(keyword: string): ListItemProps[] {
		let data = this.props.items.filter((item: ListItemProps) => {
			if (keyword.length > 0) {
				if (this.props.additionalFilterOn) {
					if (
						(item.text && item.text.toLowerCase().indexOf(keyword.toLowerCase()) > -1) ||
						(item[this.props.additionalFilterOn] && item[this.props.additionalFilterOn].toLowerCase().indexOf(keyword.toLowerCase()) > -1)
					)
						return true;
				} else if (item.template) {
					return true;
				} else if (item.text.toLowerCase().indexOf(keyword.toLowerCase()) > -1) {
					return true;
				}
			} else {
				return true;
			}

			return false;
		});

		return data;
	}

	getHeight(): any {
		if (this.props.height > 0) return { height: `${this.props.height}vh` };
		else return null;
	}

	render(): React.ReactNode {
		return (
			//Specified count
			<Fragment>
				<div aria-busy={this.props.isLoading ?? false} className={this.props.isLoading ? "filtered-list loading" : "filtered-list"}>
					{this.props.type === "categorize" && this.props.dropDownProps && (
						<div className="px-4 pt-4 categories">
							<Dropdown {...this.props.dropDownProps} onChange={() => this.onTextboxEndIconClick()} />
						</div>
					)}
					<div className="px-4 pt-4">
						<div className="load-indicator">
							<div className="pulsate-loading"></div>
							{this.props.onSearch && this.state.keyword ? (
								<div className="load-text"></div>
							) : (
								<div className="load-text">{this.props.loadingText ? this.props.loadingText : "Loading list"}</div>
							)}
						</div>
						<Textbox
							name={this.props.name}
							compact={false}
							onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => this.onKeyUp(e.currentTarget)}
							onClear={() => this.onTextboxEndIconClick()}
							value={this.state.keyword}
							{...this.props.searchOptions}
							properties={{ "aria-label": "Search", autoComplete: "off" }}
							type={TextboxType.IconStart | TextboxType.IconEnd}
							clearable
						/>
					</div>

					<div role="list" className={`list-group px-4 ${this.props.compact && "list-group-compact"} ${this.props.isEmpty && "d-none"} text`} style={this.getHeight()}>
						<li className="list-group-item skeleton">&nbsp;</li>
						<li className="list-group-item skeleton">&nbsp;</li>
						<li className="list-group-item skeleton">&nbsp;</li>
						{this.state.data.map((item: ListItemProps, number: number) => {
							return (
								<ListItem
									{...item}
									state={this.getState(item)}
									filter={this.state.keyword}
									key={number}
									onClick={(event: React.MouseEvent) => this.onClick(item) && item.onClick && item.onClick(event, item)}
								/>
							);
						})}
					</div>
					<div className={this.props.isEmpty ? "empty-list" : "d-none"}>
						<i className="fal fa-folder-open"></i>
						No Items found
					</div>
				</div>
			</Fragment>
		);
	}
}
