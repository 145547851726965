import * as React from 'react';
import { defaults, TableFilterProps, TableFieldFilter } from '../TableFilter/types';
import { Textbox } from '../../Textbox';
import { Button, ButtonType } from '../../Button';
import Text, { TextType } from '../../Text'
import {
  Modal,
  TemplateModalSize,
  TemplateModalAlignment,

} from "../../Template";
import Checkbox from '../../Checkbox';

function capitalize(str: string) {
  const lower = str.toLowerCase();
  return str.charAt(0).toUpperCase() + lower.slice(1)
}
const AllFilters: React.FC<{ filters: TableFieldFilter[], setFieldsToFilter: any }> = ({ filters, setFieldsToFilter }) => {
  return (
    <div style={{ textAlign: 'left' }}>
      {
        filters.map((filter: TableFieldFilter) => {
          const Normal = () => <Checkbox checked={filter.mobilePinned} label={capitalize(filter.fieldName.toString())} name={filter.fieldName} onClick={(checked: boolean) => { filter.mobilePinned = checked; setFieldsToFilter(filters) }} properties={{ "aria-label": filter.fieldName }} />;

          return (
            <Normal />
          )
        })
      }
    </div>
  )
}

const TableFilter = (props: React.PropsWithChildren<TableFilterProps>) => {
  const finalProps = { ...defaults, ...props };
  const { component, children, classes, filterConfig } = finalProps;
  const { filters, onMobileSearch, onClearSearch } = filterConfig;
  const [fieldsToFilter, setFieldsToFilter] = React.useState(filters)
  const CustomTag = `${component}` as keyof JSX.IntrinsicElements;
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState("")

  const style = {
    left: 0,
    top: 0,
  };

  const finalStyles = { ...style, ...props.style };
  let finalClasses = 'HuiMobileTableFilter';
  if (classes) finalClasses += ` ${classes}`;
  if (fieldsToFilter === undefined) return <></>
  const Large = () => (
    <Modal
      show={true}
      imageUrl=""
      name="exampleModal"
      size={TemplateModalSize.Large}
      bodyAlignment={TemplateModalAlignment.Right}
      header={<Text content="Configure Visible Filters" type={TextType.Heading4} icon="" />}
      body={
        filters !== undefined && <AllFilters filters={filters} setFieldsToFilter={setFieldsToFilter} />
      }
      footer={
        <>
          <Button
            icon="cancel"
            text="Close"
            onClick={() => {
              setOpen(false)
            }}
            type={ButtonType.Primary | ButtonType.Small}
          />
        </>
      }
    />
  );

  const handleSearch = (e: any) => {
    fieldsToFilter.forEach((field: TableFieldFilter) => {
      const { onChangeField } = field;
      try {
        onChangeField(e)
      }
      catch (e) { }

    })
    setSearch(e.target.value)

  }

  // const mainProps = React.useContext(TableFilterContext);
  return (
    <CustomTag style={finalStyles} className={finalClasses}>
      {
        open === true && <Large />
      }
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          height: '100%',
          width: '100%'
        }}
      >

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginBottom: '0.25em' }}>
          <Textbox
            name={"Search"}
            placeholderText={`Filter Table`}
            onChange={handleSearch}
            value={search}
            buttonProps={fieldsToFilter.filter((filter: TableFieldFilter) => {
              return filter.value !== null && filter.value.length > 0
            }).length > 0 ? {
              onClick: () => {
                setSearch("")
                onClearSearch();
                document.getElementsByClassName("ReactVirtualized__Grid ReactVirtualized__List")[0].scrollTop = 0;

              },
              type: ButtonType.TextNaked,
              icon: "times-circle",
              text: ""
            } : {
              type: ButtonType.TextNaked
            }}
          />
          <div style={{ marginLeft: '0.25em', display: 'flex' }}>


            <div style={{ marginLeft: '0.25em', width: '100%', display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
              <Button
                onClick={() => {
                  onMobileSearch();
                  document.getElementsByClassName("ReactVirtualized__Grid ReactVirtualized__List")[0].scrollTop = 0;
                }}
                outlined={true}
                padded={true}
                icon="search"
                text=""
              />
            </div>

          </div>

        </div>
      </div>
    </CustomTag>
  );
};


export default TableFilter;
