import { SortDirection } from "../TableCell"
export interface TableSortLabelProps {
  classes?: string;
  active?: boolean;
  direction?: SortDirection;
  hideSortIcon?: boolean;
  IconComponent?: string;
  onClick?: React.MouseEventHandler<any>;
  style?: any;
}

export const defaults: TableSortLabelProps = {
  classes: '',
  active: false,
  direction: 'asc',
  hideSortIcon: false,
  IconComponent: '',
};
