import * as React from 'react';
import { Context, createContext } from 'react';
import { TableProps } from './types';

export const defaults: TableProps = {
  component: 'table',
  classes: '',
  padding: 'default',
  size: 'full',
  stickyHeader: false,
  tableData: [],
  columnConfig: [],
};
const TableContext: Context<TableProps> = createContext(defaults);

const TableContextProvider = (props: React.PropsWithChildren<TableProps>) => {
  const finalProps = { ...defaults, ...props };
  const { children } = finalProps;
  return (
    <TableContext.Provider value={finalProps}>{children}</TableContext.Provider>
  );
};

export { TableContext, TableContextProvider };
