import moment = require("moment");
import React from "react";

export const simpleToday = (): string => {
	let displayDate = moment().format("MMM D");
	return `${displayDate}`;
};

export const shortToday = (): string => {
	let displayDate = moment().format("MMM D, YYYY");
	return `${displayDate}`;
};

export const mitFormatToday = (): string => {
	return moment().format("MM/DD/YYYY");
};

export const simpleDate = (d: string): string => {
	return "May 21";
	//return moment(d, 'MM/DD/YYYY').format('MMM D')
};

export const toDate = (dateTime: string): moment.Moment => {
	return moment(dateTime, "YYYY-MM-DD H:mm:ss");
};

export const displayDateTime = (date: moment.Moment | string | undefined): string => {
	if (!date) {
		return "-";
	}

	if (typeof date === "string") {
		date = moment(date);
	}

	let displayDate = moment(date).format("LLL");
	return `${displayDate}`;
};
export const usDateTime = (date: moment.Moment | string | undefined): string => {
	if (!date) {
		return "-";
	}
	let displayDate = moment.utc(date).local();
	return `${displayDate.format("MM/DD/YYYY hh:mm A")}`;
};

export const displayDateTimeLong = (date: moment.Moment | string | undefined): string => {
	if (!date) {
		return "-";
	}
	let displayDate = moment(date, "YYYY-MM-DDTHH:mm:ssZ");
	return `${displayDate.format("MM/DD/YYYY hh:mm A")}`;
};

export const displayTime = (date: moment.Moment | string | undefined): string => {
	if (!date) {
		return "-";
	}
	let displayDate = moment(date, "YYYY-MM-DDTHH:mm:ssZ");
	return `${displayDate.format("hh:mm A")}`;
};

export const displayDate = (date: moment.Moment | string): string => {
	if (typeof date === "string") {
		date = moment(date);
	}

	let displayDate = moment(date).format("MM/DD/YYYY");
	return `${displayDate}`;
};
export const displayDateTimeShort = (date: moment.Moment | string): string => {
	if (!date) {
		return "-";
	}

	if (typeof date === "string") {
		date = moment(date);
	}

	let displayDate = moment(date).format("MMM D, YYYY H:mm A");
	return `${displayDate}`;
};

export const displayDateShort = (date: moment.Moment | string): string => {
	if (!date) {
		return "-";
	}

	if (typeof date === "string") {
		date = moment(date);
	}

	let displayDate = moment(date).format("MMM D, YYYY");
	return `${displayDate}`;
};

export const toLocalTimeFromUct = (utcTime: string): string => {
	var date = moment.utc(utcTime).format();
	let local: moment.Moment = moment.utc(date).local();
	return local.format();
};

export const toUtc = (localTime: string): string => {
	return moment.utc(moment(localTime)).format();
};

export const toUtcDate = (localTime: moment.Moment): string => {
	return moment.utc(localTime).format();
};

export const formatPhoneNumber = (number: string): string => {
	if (number) {
		let cleaned = ("" + number).replace(/\D/g, "");
		let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
		if (match) {
			let intlCode = match[1] ? "+1 " : "";
			return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
		}
	}
	return number;
};

export const getResultText = (abbr: "P" | "N" | "I" | "FLU_SHOT" | "VACCINE_SHOT" | "VACCINE_COURSE_COMPLETED" | null, extraInfo?: any) => {
	switch (abbr) {
		case "P":
			return "Positive";
		case "N":
			return "Negative";
		case "I":
			return "Invalid";
		case "FLU_SHOT":
			return "Flu Shot";
		case "VACCINE_SHOT":
			return `${extraInfo?.test_company ?? "Pfizer"} shot 1`;
		case "VACCINE_COURSE_COMPLETED":
			return `${extraInfo?.test_company ?? "Pfizer"} shot 2`;
	}
	return "Pending";
};

export const firstLetterUpper = (theString: string) => {
	var newString = theString.toLowerCase().replace(/(^\s*\w|[\.\!\?]\s*\w)/g,function(c){return c.toUpperCase()});
  return newString;
}

export const sentenceCase = (str: string) => { return str.replace(/[a-z]/i, function (letter) { return letter.toUpperCase(); }).replace(/_/g, " ").trim(); }