import React, { Fragment } from 'react';
import { DropdownItem, DropdownItemProps } from './Dropdown';

//@ts-ignore
import MediaQuery from 'react-responsive';
import { NavItem } from './Nav';
import { Modal } from 'react-bootstrap';
import { Tooltip } from '..';

export interface ProfileProps {
  name: string;
  subtitle: string;
  imageUrl?: string;
  kerbId?: string;
  role?: UserRoles;
  roleTooltipText?: string;
  impersonateImageUrl?: string;
  impersonateName?: string;
  impersonateSubtitle?: string;
  impersonateRole?: UserRoles;
  impersonateActive?: boolean;
  onExitImpersonation?: () => void;
  darkmode?: boolean;
  submenu?: DropdownItemProps[];
}

export type UserRoles = 'default' | 'admin' | 'superadmin' | 'helpdesk';

export interface ProfileState {
  expanded: boolean;
  darkMode: boolean;
  show: boolean;
}

export default class Profile extends React.Component<ProfileProps, ProfileState> {
  public static defaultProps = {
    imageUrl: '',
    name: '',
    subtitle: '',
    submenu: [],
    impersonateActive: false,
    impersonateName: 'No Name',
    impersonateSubtitle: 'No department',
  };

  constructor(props: ProfileProps) {
    super(props);

    this.state = {
      expanded: false,
      darkMode: false,
      show: false,
    };
  }

  getRole(): string {
    var classes: string[] = [];

    if (this.props.role) {
      if (this.props.role === 'superadmin') classes.push('fas fa-crown');
      if (this.props.role === 'admin') classes.push('far fa-crown');
      if (this.props.role === 'helpdesk') classes.push('fas fa-headset');
    }

    return classes.join(' ');
  }

  getImpersonateRole(): string {
    var classes: string[] = [];

    if (this.props.impersonateRole === 'superadmin') classes.push('fas fa-crown');
    if (this.props.impersonateRole === 'admin') classes.push('far fa-crown');
    if (this.props.impersonateRole === 'helpdesk') classes.push('fas fa-headset');

    return classes.join(' ');
  }

  switchMode = (): void => {
    const updatedState = { darkMode: !this.state.darkMode };

    this.setState(updatedState);

    if (updatedState.darkMode) {
      document.body.classList.add('dark');
      localStorage.setItem('hui.darkMode', 'true');
    } else {
      document.body.classList.remove('dark');
      localStorage.setItem('hui.darkMode', 'false');
    }
  };

  renderMobile(): React.ReactNode {
    return (
      <Fragment>
        {((this.props.submenu && this.props.submenu.length) || this.props.darkmode) && (
          <Modal className={'containerless profile-modal'} show={this.state.show} onHide={() => this.setState({ show: false })}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <div className='profile-details'>
                <div className='name'>{this.props.name}</div>
                <div className="subcontent">
                  <div className='subtitle'>{this.props.subtitle}</div>
                  <div className="kerb-id">{this.props.kerbId}</div>
                </div>
              </div>
              <div className='dropdown-divider'></div>
              <ul className={`nav nav-icons mobile-nav-list`}>
                {this.props.submenu &&
                  this.props.submenu.length > 0 &&
                  this.props.submenu.map((dropdownItem: DropdownItemProps, number: number) => (
                    <NavItem
                      key={number}
                      icon={dropdownItem.icon}
                      text={dropdownItem.text}
                      iconOnly={false}
                      onClick={() => {
                        dropdownItem.onClick && dropdownItem.onClick();
                        this.setState({ show: false });
                      }}
                    />
                  ))}
                {this.props.darkmode && (
                  <Fragment>
                    {this.props.submenu && this.props.submenu.length > 0 && this.props.darkmode && <div className='dropdown-divider'></div>}
                    {!this.state.darkMode ? (
                      <NavItem
                        icon={'moon'}
                        text={'Go Dark'}
                        iconOnly={false}
                        onClick={() => {
                          this.switchMode();
                          this.setState({ show: false });
                        }}
                      />
                    ) : (
                      <NavItem
                        icon={'sun'}
                        text={'Go Light'}
                        iconOnly={false}
                        onClick={() => {
                          this.switchMode();
                          this.setState({ show: false });
                        }}
                      />
                    )}
                  </Fragment>
                )}
              </ul>
            </Modal.Body>
          </Modal>
        )}
        <div role='navigation' aria-label='Profile.'>
          <span className={this.getRole()}></span>
          <a
            role="button"
            className='profile'
            aria-haspopup={true}
            onClick={() => {
              this.setState({ show: true });
              setTimeout(() => {
                document.getElementsByClassName('modal-open')[0].classList.remove('modal-open');
              }, 100);
            }}
          >
            <img
              src={this.props.imageUrl}
              alt={`${this.props.name} Profile Picture`}
              style={{ visibility: !!this.props.imageUrl ? 'visible' : 'hidden' }}
            />
            <div className='profile-details'>
              <div className='name'>{this.props.name}</div>
              <div className='subtitle'>{this.props.subtitle}</div>
            </div>
          </a>
        </div>
        {this.props.impersonateActive && (
          <div className='impersonation'>
            <span className={this.getImpersonateRole()}></span>
            <a className='profile' aria-haspopup={true} data-toggle='modal' data-target='#profileModal'>
              <img
                src={this.props.impersonateImageUrl}
                alt={`${this.props.name} Profile Picture`}
                style={{ visibility: !!this.props.impersonateImageUrl ? 'visible' : 'hidden' }}
              />
              <div className='profile-details' aria-label={`${this.props.name} Profile Information`}>
                <div className='name'>{this.props.name}</div>
                <div className='subtitle'>{this.props.subtitle}</div>
              </div>
            </a>
          </div>
        )}
      </Fragment>
    );
  }

  renderDesktop(): React.ReactNode {
    let tagItems = {};

    if ((this.props.submenu && this.props.submenu.length > 0) || this.props.darkmode)
      tagItems = {
        href: '/#',
        id: 'profileOptionsNav',
        'data-toggle': 'dropdown',
        'aria-haspopup': true,
        'aria-expanded': this.state.expanded,
        role:"button",
        onClick: () => this.setState({ expanded: !this.state.expanded }),
      };

    return (
      <React.Fragment>
        <div
          className={`profile-wrapper ${this.props.impersonateActive && 'disable-base-profile'}`}
          role='navigation'
          aria-label='Profile.'
        >
          <span className={this.getRole()}></span>
          <a className='profile base' role="button" {...tagItems}>
            <Tooltip text={this.props.roleTooltipText ? this.props.roleTooltipText : ''}>
              <img
                src={this.props.imageUrl}
                alt={`${this.props.name} Profile Picture`}
                style={{ visibility: !!this.props.imageUrl ? 'visible' : 'hidden' }}
              />
            </Tooltip>

          </a>

          {this.props.impersonateActive && (
            <div className='impersonation'>
              <span className={this.getImpersonateRole()}></span>
              <a className='profile' aria-haspopup={true} data-toggle='modal' data-target='#impersonateProfileModal'>
                <img
                  src={this.props.impersonateImageUrl}
                  alt={`${this.props.impersonateName} Profile Picture`}
                  style={{ visibility: !!this.props.impersonateImageUrl ? 'visible' : 'hidden' }}
                />
                <div className='profile-details'>
                  <div className='name'>{this.props.impersonateName}</div>
                  <div className='subtitle'>{this.props.impersonateSubtitle}</div>
                </div>
              </a>
              <div className='impersonation-exit-container'>
                <a className='exit' onClick={() => this.props.onExitImpersonation && this.props.onExitImpersonation()}>
                  <i className='fas fa-chevron-left'></i> Exit Impersonation
                </a>
              </div>
            </div>
          )}
          <div className='dropdown-menu dropdown-menu-right' aria-labelledby='profileOptionsNav' tabIndex={-1}>
            <div className='profile-details'>
              <div className='name'>{this.props.name}</div>
              <div className="subcontent">
                <div className='subtitle'>{this.props.subtitle}</div>
                <div className="kerb-id">{this.props.kerbId}</div>
              </div>
            </div>
            {((this.props.submenu && this.props.submenu.length > 0) || this.props.darkmode) && (
              this.props.submenu && this.props.submenu.length > 0 &&
              this.props.submenu.map((dropdownItem: DropdownItemProps, number: number) => {
                return <DropdownItem {...dropdownItem} key={number} tabindex={number} />
              })
            )

            }
          </div>
          {/* {((this.props.submenu && this.props.submenu.length > 0) || this.props.darkmode) && (
            <div className='dropdown-menu' aria-labelledby='profileOptionsNav' tabIndex={-1}>
              {this.props.submenu &&
                this.props.submenu.length > 0 &&
                this.props.submenu.map((dropdownItem: DropdownItemProps, number: number) => (
                  <DropdownItem {...dropdownItem} key={number} tabindex={number} />
                ))}
              {
                // this.props.darkmode
                // &&
                // <Fragment>
                //     {
                //         this.props.submenu && this.props.submenu.length > 0 && this.props.darkmode
                //         &&
                //         <div className="dropdown-divider"></div>
                //     }
                //     {
                //         !this.state.darkMode
                //             ?
                //             <DropdownItem onClick={this.switchMode} icon="moon" text="Go Dark" />
                //             :
                //             <DropdownItem onClick={this.switchMode} icon="sun" text="Go Light" />
                //     }
                // </Fragment>
              }
            </div>
          )} */}
        </div>
      </React.Fragment>
    );
  }

  render(): React.ReactNode {
    return (
      <React.Fragment>
        <MediaQuery maxWidth={576}>{this.renderMobile()}</MediaQuery>
        <MediaQuery minWidth={577}>{this.renderDesktop()}</MediaQuery>
      </React.Fragment>
    );
  }
}
