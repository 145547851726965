import { observable } from "mobx";

export interface RolesInterface {
	roles: string[];
	statusDisabled: () => boolean;
}

class Roles {
	@observable roles: string[];

	getRoles(): string[] {
		return this.roles;
	}

	statusDisabled(): boolean {
		if (this.roles.includes("medical")) return false;

		if (this.roles.includes("emergency_management")) return true;

		if (this.roles.includes("student_support")) return true;

		if (this.roles.includes("dining")) return true;

		return false;
	}
}

const roles = new Roles();
export default roles;
