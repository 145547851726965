import * as React from "react";
import { FC, useState, useEffect } from "react";
import { ActionList, Error, ErrorContext, ErrorType, Icon, TextArea } from "@mit/hui";
import contactTracingApi, { AuditResponse } from "../../api/ContactTracingApi";
import { displayDateTimeShort } from "../../common/Formatters";

interface ContactAuditComponentProps {
	mitId: string;
}

const ContactAudit: FC<ContactAuditComponentProps> = ({ mitId }) => {
	const [isLoading, setLoading] = useState(false);
	const [unAuth, setUnAuth] = useState(false);
	const [isError, setError] = useState(false);
	const [audits, setAudits] = useState<AuditResponse[]>([]);

	useEffect(() => {
		fetchComments();
	}, []);

	const fetchComments = () => {
		setLoading(true);
		contactTracingApi.fetchAudit(mitId).then((response) => {
			if (response.error) {
			} else if (response.unauth) {
				setUnAuth(true);
			} else {
				if (response.items) {
					setLoading(false);
					setAudits(response.items);
				}
			}
		});
	};

	const getAuditTypeIcon = (audit_type: string): any => {
		if (audit_type === "comment") return <Icon type={"regular"} icon={"comments"} />;
		if (audit_type === "tested_positive") return <Icon type={"regular"} icon={"microscope"} />;
		if (audit_type === "tested_negative") return <Icon type={"regular"} icon={"microscope"} />;
		if (audit_type === "medical_symptoms") return <Icon type={"regular"} icon={"briefcase-medical"} />;
		if (audit_type === "hold") return <Icon type={"regular"} icon={"briefcase-medical"} />;
		if (audit_type === "flu_shot") return <Icon type={"regular"} icon={"syringe"} />;
		if (audit_type === "attestation_all_cleared") return <Icon type={"regular"} icon={"clipboard-list-check"} />;
		if (audit_type === "attestation_has_symptoms") return <Icon type={"regular"} icon={"clipboard-list-check"} />;
	};

	if (isError) return <Error context={ErrorContext.Component} message="Oops.. something went wrong" type={ErrorType.Generic} />;

	if (unAuth) return <Error context={ErrorContext.Component} message="Not Authorized" type={ErrorType.AccessDenied} />;

	return (
		<React.Fragment>
			{isLoading && <ActionList isLoading items={[]}/>}
			{!isLoading && (
				<React.Fragment>
					{audits.map((itm, i) => {
						return (
							<div key={`audit_item_${i}`}>
								<div className={`row ${itm.audit_type}`}>
									{/* Date & Time */}
									<div className={"col-3"}>{displayDateTimeShort(itm.datetime)}</div>
									{/* Icon */}
									<div className={"col-1"}>{getAuditTypeIcon(itm.audit_type)}</div>
									{/* info */}
									<div className={"col-8"}>
										<div>{itm.title}</div>
										<div>{itm.description_line1}</div>
										<div>{itm.description_line2}</div>
									</div>
								</div>
								<hr />
							</div>
						);
					})}
				</React.Fragment>
			)}
		</React.Fragment>
	);
};

export default ContactAudit;
