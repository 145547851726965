import React, { FC, useState, useEffect } from "react";
import { ValidationProps } from "./Form";
import { useForm } from "react-hook-form";
import { ValidationContainer } from "./Form/ValidationContainer";

export interface TextAreaProps {
    name: string;
    rows: number;
    required?: boolean; //A Boolean which, if true, indicates that the input must have a value before the form can be submitted.
    maxLength?: number; //The maximum length of the value to accept for this input.
    minLength?: number; //The minimum length of the value to accept for this input.
    max?: number; //The maximum value to accept for this input.
    min?: number; //The minimum value to accept for this input.
    placeholderText?: string;
    validation?: ValidationProps;
    isLoading?: boolean;
    onActionClick?: any;
    onChange?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
    properties?: { [key: string]: string };
    value?: string;
    disabled?: boolean;
    readOnly?: boolean;
    pattern?: any; //The regex pattern for the input.
    register?: any; // https://react-hook-form.com/api#register
    errors?: any; // https://react-hook-form.com/api#errors
    setValue?: any; // https://react-hook-form.com/api#setValue
}

export const TextArea: FC<TextAreaProps> = (props) => {
    let { register, errors, setValue } = useForm({
        mode: "onBlur",
    });

    if (props.register) register = props.register;

    if (props.errors) errors = props.errors;

    if (props.setValue) setValue = props.setValue;

    useEffect(() => {
        setValue(props.name, props.value);
    }, [props.value, props.name, setValue]);

    const [keyword, setKeyword] = useState(props.value ? props.value : "");

    const getClasses = (): string => {
        var classes: string[] = [];

        return classes.join(" ");
    };

    const onKeyUp = (element: HTMLInputElement & EventTarget): void => {
        var keyword = element.value;
        setKeyword(keyword);
    };

    if (props.isLoading) {
        // text compact icon-start icon-end icon-end busy-indicator
		return (
			<div aria-busy={props.isLoading ?? false} className={`textarea loading busy-indicator`}>
                <div className="loader" style={{right: '20px'}}>
					<div className="pulsate-loading"></div>
				</div>
                <textarea
                    name={props.name}
                    id={props.name}
                    className={`form-control ${getClasses()}`}
                    placeholder={`Loading data...`}
                    rows={props.rows}
                    disabled={true}
                    readOnly={props.readOnly}
                />
			</div>
		);
	}

    return (
        <div aria-busy={props.isLoading ?? false} className="text">
            
            {!keyword && props.required && !props.isLoading && <span className="text-required-dot"></span>}

            <ValidationContainer
                name={props.name}
                errors={errors}
                value={props.value}
                keyword={keyword}
                validation={props.validation}
            />

            <textarea
                name={props.name}
                ref={register({
                    required: props.required,
                    maxLength: props.maxLength,
                    minLength: props.minLength,
                    min: props.min,
                    max: props.max,
                    pattern: props.pattern,
                })}
                id={props.name}
                //onFocus={(event: React.FocusEvent<HTMLInputElement>) => this.props.onFocus && this.props.onFocus(event)}
                className={`form-control ${getClasses()}`}
                placeholder={`${props.placeholderText}`}
                {...props.properties}
                rows={props.rows}
                disabled={props.disabled}
                readOnly={props.readOnly}
                onChange={(event: any) => {
                    props.onChange && props.onChange(event);
                    props.onKeyUp && props.onKeyUp(event);
                    onKeyUp(event.currentTarget);
                }}
            />
        </div>
    );
};
