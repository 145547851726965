import React, { useState } from "react";
import { Drawer, DrawerSize, DrawerType, FormContainer, FormField, DropdownItemProps } from "../../";
import { getNestedObjectValue, IAdvancedTableColumn } from "./";
import Text, { TextType } from "../../components/Text";
import { AtlasTheme } from "../../components/Template";

export interface IAdvancedTableFilterOptions {
	type: "textbox" | "multilinetextbox" | "dropdown" | "calendar" | "checkbox" | "radio" | "radioGroup" | "autocomplete" | "toggle";
	onFilter: (item: any, value: any) => boolean;
	options?: DropdownItemProps[];
	createOptionsOffItems?: boolean;
	properties?: { [key: string]: any };
}

export interface IAdvancedTableFilterProps {
	show: boolean;
	onClose: () => void;
	columns: IAdvancedTableColumn[];
	items: any[];
	onFilter: (items: any[], onClear?: boolean) => void;
	theme: AtlasTheme;
}

export const AdvancedTableFilter: React.FC<IAdvancedTableFilterProps> = ({ show, onClose, columns, items, onFilter, theme }) => {
	const [filterValues, setFilterValues]: any = useState({});

	const getFilterableColumns = () => {
		return columns.filter((col: IAdvancedTableColumn) => col.filterable && col.filterOptions);
	};

	const getFilterItems = (col: IAdvancedTableColumn) => {
		let options: any = [];
		for (let item of items) {
			let value: any = getNestedObjectValue(item, col.id);
			if (Array.isArray(value)) {
				for (let option of value) {
					options.push({
						id: option.id,
						text: option.name,
						icon: "",
					});
				}
			} else {
				options.push({
					id: "1",
					text: value,
					icon: "",
				});
			}
		}
		let unique: any = [...new Set(...[options.map((option: any) => option.text)])].map((text: any) => options.find((obj: any) => obj.text === text));
		options = unique;

		//SORT ASC
		options = options.sort((a: any, b: any) => {
			if (a.text < b.text) {
				return -1;
			}
			if (a.text > b.text) {
				return 1;
			}
			return 0;
		});

		return options;
	};

	const getFilterValue = (id: string) => {
		if (filterValues.hasOwnProperty(id)) {
			return filterValues[id];
		}

		return null;
	};

	const setColFilterValue = (id: string, e: any) => {
		let value: string = e.target?.value !== null ? e.target.value : e?.text || e;
		let filters: any = {};
		filters[id] = value;

		setFilterValues({ ...filterValues, ...filters });
	};

	const onClearFilters = () => {
		setFilterValues({});
		onFilter(items, true);
		onClose();
	};

	const getFilters = () => {
		let filterableColumns = getFilterableColumns();
		let filters = filterableColumns.map((col: IAdvancedTableColumn) => {
			return (
				<FormField
					id={col.id}
					label={col.name}
					value={getFilterValue(col.id)}
					editor={col.filterOptions?.type || "textbox"}
					options={col.filterOptions?.createOptionsOffItems ? getFilterItems(col) : col.filterOptions?.options}
					items={col.filterOptions?.createOptionsOffItems ? getFilterItems(col) : col.filterOptions?.options}
					labelDisplay={"ontop"}
					required={false}
					properties={col.filterOptions?.properties}
					isRangeSelected={col.filterOptions?.type === "calendar"}
					onChange={(e: any) => {
						setColFilterValue(col.id, e);
					}}
				/>
			);
		});

		return (
			<FormContainer onFormSubmit={onFilterItems} formValidationTrigger={"onSubmit"} id="filter-form" submitText="Filter" secondaryActionText="Clear Filters" action={onFilterItems} secondaryAction={onClearFilters}>
				{filters}
			</FormContainer>
		);
	};

	const onFilterItems = (filters: any) => {
		let filteredItems: any = [];
		let filterableColumns: IAdvancedTableColumn[] = getFilterableColumns();

		for (let item of items) {
			let valid = true;
			for (let col of filterableColumns) {
				valid = col.filterOptions?.onFilter(item, filters[col.id]) || false;
				if (!valid) break;
			}
			if (valid) {
				filteredItems.push(item);
			}
		}
		onFilter(filteredItems);
		onClose();
	};

	return (
		<>
			{show && (
				<Drawer
					show={show}
					type={DrawerType.Left}
					size={DrawerSize.Small}
					onClose={onClose}
					themedColor={theme}
					header={<Text content="Filter" type={TextType.Heading4} icon="" />}
					contents={getFilters()}
					footer={[]}
				/>
			)}
		</>
	);
};
