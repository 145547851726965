import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Text, {TextType} from './Text'



export interface ReleaseNoteFeatureProps
{
    heading: string,
    description:string,
    image?:any,
}




export default class ReleaseNoteFeature extends React.Component<ReleaseNoteFeatureProps>
{
    private id = uuidv4();
    public static defaultProps = {

    };


    render(): React.ReactNode
    {
        
            return (
                <div className="col-lg-3 col-md-4 col-sm-6 mb-5">
                    <Text type={TextType.Heading5} content={this.props.heading}/>
                    {this.props.description}
                </div>

            );
        
    }
}