import * as React from 'react'
import {inject, observer} from "mobx-react";
import {PeopleStoreInterface} from "../../model/PeopleStore";
import {FC, useState} from "react";
import {displayDate, formatPhoneNumber, usDateTime} from "../../common/Formatters";
import {CovidNav} from "../../model/CovidNav";
import {FluShotDetail} from "../../api/MedicalApi";
import { AdvancedTable } from '@mit/hui';
import { IAdvancedTableColumn } from '@mit/hui/build/components/AdvancedTable';
import ViewPerson from '../common/ViewPerson';
import PersonDetailsDrawerComponent from '../common/PersonDetailsDrawer'

interface PeopleListProps {
    covidNav?: CovidNav;
    peopleStore?: PeopleStoreInterface;
    isLoading?: boolean;
}


const FluShotsAdministeredComponent: FC<PeopleListProps> = ({peopleStore, covidNav, isLoading}) => {
    const [showPerson, setShowPerson]  = useState(false);
    const [person, setPerson]  = useState<FluShotDetail>();

    const viewPersonClose = () => {
        setPerson(undefined);
        setShowPerson(false);
    }

    const showPersonInfo = (item: any) => {
        setShowPerson(true);
        setPerson(item);
    }

    const toTitleCase = (str: string) => {
        return str.replace(
          /\w\S*/g,
          function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          }
        );
    }

    const columns: Array<IAdvancedTableColumn> = [
        {
            id: "full_name",
            name: "Name",
            sortable: true,
            formatter: (item:any) => { return (<div className="clickable" onClick={() => { showPersonInfo(item)}}><span>{item.full_name}</span></div>) },
            options: {
                demandPopin: true,
                minWidth: 600,
                popinText:  "Name"
            },
            filterable: true,
            filterOptions: {
				type: "textbox",
				onFilter: (item: any, value: any) => {
					if (item.display_name.toLowerCase().indexOf(value.toLowerCase()) != -1)
						return true;

					return false;
				},
			}
        },
        {
            id: "mit_id",
            name: "MIT ID",
            sortable: false,
            options: {
                demandPopin: true,
                minWidth: 1000,
                popinText:  "MIT ID"
            },
            filterable: false
        },
        {
            id: "gender",
            name: "Gender",
            formatter: (item: any) => { return toTitleCase(item.gender) },
            sortable: false,
            options: {
                demandPopin: true,
                minWidth: 1000,
                popinText:  "Gender"
            },
            filterable: false
        },
        {
            id: "phone_number",
            name: "Phone",
            sortable: false,
            formatter: (item: any) => {return ( <a href={`tel:${item.phone_number}`}>{formatPhoneNumber(item.phone_number)}</a> ) },
            options: {
                demandPopin: true,
                minWidth: 600,
                popinText: "Phone"
            },
            filterable: false
        },
        {
            id: "email",
            name: "Email",
            sortable: false,
            formatter: (item: any) => { return ( <a className="table-email" href={`mailto:${item.email}`}>{item.email}</a> ) },
            options: {
                demandPopin: true,
                minWidth: 600,
                popinText: "Email"
            },
            filterable: false
        },
        {
            id: "date_of_birth",
            name: "Date of Birth",
            sortable: false,
            options: {
                demandPopin: true,
                minWidth: 1000,
                popinText: "Date of Birth"
            },
            filterable: false
        },
        {
            id: "flu_shot_date",
            name: "Flu Shot Date",
            sortable: true,
            formatter: (item: any) => { return item.flu_shot_date;},
            options: {
                demandPopin: true,
                minWidth: 1000,
                popinText: "Flu Shot Date"
            }
        },
        {
            id: "body_region_name",
            name: "Body Region",
            sortable: true,
            options: {
                demandPopin: true,
                minWidth: 1000,
                popinText: "Body Region"
            },
            filterable: false
        },
        {
            id: "lot_number",
            name: "Lot Number",
            sortable: true,
            options: {
                demandPopin: true,
                minWidth: 1000,
                popinText: "Lot Number"
            },
            filterable: false
        },
        {
            id: "location_name",
            name: "Location",
            sortable: true,
            options: {
                demandPopin: true,
                minWidth: 1000,
                popinText: "Location"
            }
        },
        {
            id: "test_by_full_name",
            name: "Administered By",
            sortable: true,
            options: {
                demandPopin: true,
                minWidth: 1000,
                popinText: "Administered By"
            }
        },
    ];

    const data = peopleStore!.fluShotsList?.map((person: FluShotDetail, index: number) => {
        return {
            ...person,
            gender: person.gender ? person.gender : '-',
            phone_number: person.phone_number ? formatPhoneNumber(person.phone_number) : '-',
            email: person.email ? person.email : '-',
            date_of_birth: person.date_of_birth ? displayDate(person.date_of_birth) : '-',
            flu_shot_date: person.flu_shot_date ? displayDate(person.flu_shot_date): '-'
        }
    })

    return <>
        <AdvancedTable 
            columns={columns} 
            items={data ? data : []} 
            showExportButton={false} 
            showFilterButton={true} 
            isLoading={isLoading || covidNav!.globalTableIsLoading} 
            noDataText="No results found"
            sortStateOptions={{
                enabled:true,
                key: 'covid-medical-flu-shot-administered'
            }}
            theme={"medical"} />
        {/* <ViewPerson show={showPerson} person={person} onClose={viewPersonClose} /> */}
        <PersonDetailsDrawerComponent id={person?.mit_id} show={showPerson} onClose={viewPersonClose} />
    </>
}

export const FluShotsAdministered = inject('peopleStore', 'covidNav')(observer(FluShotsAdministeredComponent))
