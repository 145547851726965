import React from 'react';

export interface ThreeColumnsProps
{
    leftArea: any,
    leftAlignment: string,
    rightArea: any,
    rightAlignment: string,
    centerArea: any,
    centerAlignment: string,
    showDividers: boolean,
    showGutters: boolean,
    padded: boolean
}
 
export default class TemplateThreeColumns extends React.Component<ThreeColumnsProps>
{
    render(): React.ReactNode
    {
        return (
            <React.Fragment>

                <div className={`row ${(!this.props.showGutters) && 'no-gutters'}`}>
                    <div className={`col-lg-4 text-${this.props.leftAlignment} ${(this.props.showDividers) && 'divider'} ${(this.props.padded) && 'p-3'}`}>
                        {
                            this.props.leftArea
                        }
                    </div>
                    <div className={`col-lg-4 text-${this.props.centerAlignment} ${(this.props.showDividers) && 'divider'} ${(this.props.padded) && 'p-3'}`}>
                        {
                            this.props.centerArea
                        }
                    </div>
                    <div className={`col-lg-4 text-${this.props.rightAlignment} ${(this.props.padded) && 'p-3'}`}>
                        {
                            this.props.rightArea
                        }
                    </div>
                </div>

            </React.Fragment>
        );
    }
}