import * as React from 'react';
import { defaults, TableContainerProps } from './types';

const TableContainer: React.FC<React.PropsWithChildren<TableContainerProps>> = (
  props: React.PropsWithChildren<TableContainerProps>
) => {
  const finalProps = { ...defaults, ...props };
  const { component, children, classes } = finalProps;
  const CustomTag = `${component}` as keyof JSX.IntrinsicElements;
  let finalStyles = 'HuiTableContainer';
  if (classes) finalStyles += ` ${classes}`;
  return (
    <CustomTag style={props.style} className={finalStyles}>
      {children}
    </CustomTag>
  );
};

export default TableContainer;
