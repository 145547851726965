import { elementType } from '../Table/types';

export interface TableContainerProps {
  classes?: string;
  component?: elementType;
  style?: any;
}

export const defaults: TableContainerProps = {
  classes: '',
  component: 'div',
};
