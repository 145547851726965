import * as React from "react";
import { FC, useState, useEffect } from "react";
import {
	Error,
	Text,
	ErrorType,
	Icon,
	Button,
	Drawer,
	DrawerLayout,
	NavBar,
	NavItemProps,
	NavItemState,
	NavBarTypes,
	DrawerType,
	DrawerSize,
	ActionList,
	FormContainer,
	TextType,
	FormField,
	SkeletonLoader,
} from "@mit/hui";
import peopleController from "../../api/PeopleController";
import medicalApi, { ApiPersonDetail } from "../../api/MedicalApi";
import PersonProfilePicture from "./PersonProfilePicture";
import { displayDateShort, formatPhoneNumber } from "../../common/Formatters";
import { inject, observer, Provider } from "mobx-react";
import { PersonTests } from "../search/PersonTests";
import { PersonHolds } from "../holds/PersonHolds";
import { HoldsModelInterface } from "../../model/Holds";
import { peopleStore } from "../../model/PeopleStore";
import { RolesInterface } from "../../model/Roles";
import { SearchUserInterface } from "../../model/SearchUser";
import { covidNav } from "../../model/CovidNav";
import ContactAudit from "../contactTracing/ContactAudit";
import authProvider from "../../api/AuthProvider";
import VaccineExemption from "../vaccineExemptions/VaccineExemption";

interface PersonDetailsDrawerProps {
	id: string | any;
	show: boolean;
	onClose: () => void;
	holds?: HoldsModelInterface;
	searchUser?: SearchUserInterface;
	roles?: RolesInterface;
}

const PersonDetailsDrawerComponent: FC<PersonDetailsDrawerProps> = ({ id, show, onClose, holds, roles, searchUser }) => {
	const [isLoading, setLoading] = useState(false);
	const [personDetails, setPersonDetails] = useState<ApiPersonDetail | null>(null);
	const [personTab, setPersonTab] = useState<"profile" | "tests" | "appointments" | "holds" | "exempt" | "comments" | "audit" | "questions">("profile");
	const [exemptReason, setExemptReason] = useState("");
	const [busy, setBusy] = useState(false);

	useEffect(() => {
		setPersonDetails(null)
		if (show) {
			toPersonalDetails();
			// temporary - create and move this to a new controller
			const fetchPersonExemption = async () => {
				setBusy(true);
				medicalApi
					.fetchVaccineExemption(id)
					.then((data) => {
						if (data.items && data.items.length > 0) {
							setExemptReason(data.items[0]?.reason ?? "");
						} else {
							setExemptReason("");
						}
						setBusy(false);
					})
					.catch((err) => {
						setBusy(false);
					});
			};
			fetchPersonExemption();
		}
	}, [id]);

	const getVaccineIcon = (status?: string) => {
		if (status && status.toLowerCase() === "none") return <></>;

		if (status && status.toLowerCase() === "eligible") return <></>;

		if (status && status.toLowerCase() === "in_progress")
			return (
				<>
					<Icon type={"regular"} icon="shield" color="green" />
				</>
			);

		if (status && (status.toLowerCase() === "completed" || status.toLowerCase() === "full"))
			return (
				<>
					<Icon type={"solid"} icon="shield-check" color="green" />
				</>
			);

		return <></>;
	};

	const handleClose = () => {
		onClose();
	};

	const toPersonalDetails = () => {
		setLoading(true);
		setPersonTab("profile");

		if (id) {
			peopleController.fetchPersonAws(id).then((response) => {
				if (response.detail) {
					setPersonDetails(response.detail);
					setLoading(false);
				} else {
					setLoading(false);
				}
			});
		} else {
			setLoading(false);
		}
	};

	const renderAddress = () => {
		if (personDetails && personDetails.address && personDetails.address.address1) {
			return `${personDetails.address.address1 ? personDetails.address.address1 : ""} 
			${personDetails.address.address2 ? personDetails.address.address2 : ""}  
			${personDetails.address.city ? personDetails.address.city + "," : ""} 
			${personDetails.address.state ? personDetails.address.state + "," : ""}
			${personDetails.address.postal_code ? personDetails.address.postal_code : ""}`;
		}
		return "N/A";
	};

	let TabData: NavItemProps[] = [
		{
			icon: "",
			text: "Profile",
			state: personTab === "profile" ? NavItemState.Active : NavItemState.None,
			onClick: () => toPersonalDetails(),
			iconOnly: false,
		},
		{
			icon: "",
			text: "Covid-19 Tests",
			state: personTab === "tests" ? NavItemState.Active : NavItemState.None,
			iconOnly: false,
			onClick: () => setPersonTab("tests"),
		},
		{
			icon: "",
			text: "Medical Holds",
			state: personTab === "holds" ? NavItemState.Active : NavItemState.None,
			iconOnly: false,
			onClick: () => setPersonTab("holds"),
		},
		{
			icon: "",
			text: "Vaccine Status",
			state: personTab === "exempt" ? NavItemState.Active : NavItemState.None,
			iconOnly: false,
			onClick: () => setPersonTab("exempt"),
		},
		// { icon: " ", text: "Comments", state: personTab === "comments" ? NavItemState.Active : NavItemState.None, iconOnly: false, onClick: () => setPersonTab("comments") },
		{
			icon: " ",
			text: "Audit",
			state: personTab === "audit" ? NavItemState.Active : NavItemState.None,
			iconOnly: false,
			onClick: () => setPersonTab("audit"),
		},
		// { icon: " ", text: "Qusetions", state: personTab === "questions" ? NavItemState.Active : NavItemState.None, iconOnly: false, onClick: () => setPersonTab("questions") },
	];

	const getVaccineStatusText = () => {
		switch (personDetails?.vaccine_status) {
			case "full":
				return "Fully Vaccinated";
			case "partial":
				return "Partially Vaccinated";
			case "exempt":
				return "Exempt";
			case "none":
				return "Not Vaccinated";
			case "No Status":
				return "Unknown Vaccination Status";
		}
	};
	const getInfo = (info?: string) => {
		if (!info || !info.length) {
			return "N/A";
		}
		return info;
	};

	return (
		<React.Fragment>
			{show && (
				<Drawer
					contentAriaLabel="Personal Details"
					show={show}
					themedColor="medical"
					onClose={() => {
						handleClose();
					}}
					layout={DrawerLayout.Hero}
					headerNavBar={<NavBar type={NavBarTypes.Tabs} tabs={TabData} />}
					contents={
						<div>
							{personTab === "profile" && (
								<div className="tight-layout">
									<Text content="Personal information" type={TextType.Heading4} />
									{isLoading && <ActionList isLoading items={[]} />}
									{!isLoading && (
										<FormContainer id={"profileForm"} formValidationTrigger={"onSubmit"}>
											<div className="row">
												<div className="col-4-lg">
													<div>
														<PersonProfilePicture id={id} type={"large"} highResolution person={personDetails?.first_name || ""} />
													</div>
												</div>
												<div className="col-8-lg">
													<FormField id="" editor="textbox" readonly={true} value={getInfo(personDetails?.mit_id)} label="MIT ID" labelDisplay="nextto" />
													<FormField
														id=""
														editor="textbox"
														readonly={true}
														value={getInfo(`${personDetails?.first_name} ${personDetails?.last_name}`)}
														label="Display Name"
														labelDisplay="nextto"
													/>
													<FormField id="" editor="textbox" readonly={true} value={getInfo(personDetails?.date_of_birth)} label="Date of birth" labelDisplay="nextto" />
													<FormField id="" editor="textbox" readonly={true} value={getInfo(personDetails?.gender)} label="Gender" labelDisplay="nextto" />
													<FormField id="" editor="textbox" readonly={true} label="Address" value={renderAddress()} labelDisplay="nextto" />
													<FormField
														id=""
														editor="textbox"
														properties={{ width: 50 }}
														readonly={true}
														value={getInfo(personDetails?.email)}
														label="Email Address"
														labelDisplay="nextto"
													/>
													<FormField
														id="contactNumber"
														editor="textbox"
														properties={{ width: 50 }}
														readonly={true}
														value={getInfo(formatPhoneNumber(personDetails?.phone ?? ""))}
														label="Contact Number"
														labelDisplay="nextto"
													/>
													<FormField id="" editor="textbox" readonly={true} value={getInfo(personDetails?.job_title)} label="Title" labelDisplay="nextto" />
													<FormField id="" editor="textbox" readonly={true} value={getInfo(personDetails?.department)} label="Department" labelDisplay="nextto" />
													<FormField id="" editor="textbox" readonly={true} value={personDetails?.vaccine_status} label="Vaccine status" labelDisplay="nextto" />
												</div>
											</div>
										</FormContainer>
									)}
								</div>
							)}
							{personTab === "tests" && (
								<div>
									<Provider searchUser={searchUser} roles={roles} peopleStore={peopleStore}>
										<PersonTests mitId={id} />
									</Provider>
								</div>
							)}
							{personTab === "holds" && (
								<div>
									<Provider searchUser={searchUser} roles={roles} peopleStore={peopleStore} covidNav={covidNav} holds={holds}>
										<PersonHolds mitId={id} />
									</Provider>
								</div>
							)}
							{personTab === "exempt" && (
                <Provider vacc searchUser={searchUser} roles={roles} peopleStore={peopleStore} covidNav={covidNav} holds={holds}>
                  <VaccineExemption mitId={id} vaccine_hold_status={personDetails?.vaccine_hold_status} vaccine_status={personDetails?.vaccine_status} />
                </Provider>
							)}
							{/* {personTab === "comments" && (
                            <div>
                                <Text content="Comments" type={TextType.Heading4} />
                                {selectedContact?.contact_guid ? (
                                    <ContactComments contactGuid={selectedContact?.contact_guid} caseGuid={caseData.case_guid} person={caseData.person} />
                                ) : (
                                        <ContactComments caseGuid={caseData.case_guid} person={caseData.person} />
                                    )}
                            </div>
                        )} */}
							{personTab === "audit" && (
								<div>
									<Text content="Audit" type={TextType.Heading4} />
									<ContactAudit mitId={id} />
								</div>
							)}
							{/* {personTab === "contacts" && (
                            <div>
                                <Text content="Contacts" type={TextType.Heading4} />
                                <Provider roles={roles} searchUser={searchUser} peopleStore={peopleStore} covidNav={covidNav} holds={holds}>
                                    <ContactsView caseData={caseData} />
                                </Provider>
                            </div>
                        )} */}
							{/*  {personTab === "questions" && (
                            <div>
                                <Text content="Contact Tracing Questions" type={TextType.Heading4} />
                                <QuestionsView mitId={selectedContact?.mit_id} caseData={caseData} />
                            </div>
                            )} */}
						</div>
					}
					footer={[]}
					type={DrawerType.Right}
					size={DrawerSize.Medium}
					header={
						<div className="contact-case-card mt-2">
							<div className="py-2 pb-4 d-flex">
								<PersonProfilePicture id={id ?? ""} type={"round-large"} person={personDetails?.first_name} />
								{personDetails?.mit_id ? (
									<div className="px-2 d-flex flex-column justify-content-center">
										<h2>
											{personDetails?.first_name} {personDetails?.last_name} {getVaccineIcon(personDetails?.vaccine_status)}
										</h2>
										<div className="d-flex justify-content-start align-items-center w-100">
											<p className="sub-title">
												<span className="left">{personDetails?.mit_id}</span>
												<span className="right">{personDetails?.krb_name}</span>
											</p>
										</div>
									</div>
								) : (
									<div className="px-2 d-flex flex-column justify-content-center">
										<Text content="please wait" type="small" />
									</div>
								)}
							</div>
						</div>
					}
				/>
			)}
		</React.Fragment>
	);
};

const PersonDetailsDrawer = inject("covidNav", "holds", "roles", "searchUser")(observer(PersonDetailsDrawerComponent));
export default PersonDetailsDrawer;
