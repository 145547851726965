import * as React from 'react';
import { TableLvl2ContextProvider } from '../Table/TableLvl2ContextProvider';
import { TableLvl2Props } from '../Table/types';
import { defaults, TableBodyProps } from './types';

const TableBody: React.FC<React.PropsWithChildren<TableBodyProps>> = (props: React.PropsWithChildren<TableBodyProps>) => {
  const finalProps = { ...defaults, ...props };
  const { component, children, classes } = finalProps;
  const CustomTag = `${component}` as keyof JSX.IntrinsicElements;

  const propsWithVariant: TableLvl2Props = { variant: 'body' };
  // const mainProps = React.useContext(TableBodyContext);
  let finalClasses = 'HuiTableBody';
  if (classes) finalClasses += ` ${classes}`;
  return (
    <CustomTag style={props.style} className={finalClasses}>
      <TableLvl2ContextProvider {...propsWithVariant}>
        {children}
      </TableLvl2ContextProvider>
    </CustomTag>
  );
};

export default TableBody;
