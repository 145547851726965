const updateInfo = function() {
    let context = "%cHUI UPDATE :: INFO |";
    let style = 'background-color: blue; color: #ffffff; padding: 2px 10px; border-radius: 5px 0 0 5px; font-weight:500; font-family:Arial;'
    return Function.prototype.bind.call(console.info, console, context, style);
}();

const updateWarning = function() {
    let context = "%cHUI UPDATE :: WARNING |";
    let style = 'background-color: orange; color: #ffffff; padding: 2px 10px; border-radius: 5px 0 0 5px; font-weight:500; font-family:Arial;'
    return Function.prototype.bind.call(console.warn, console, context, style);
}();

const updateError = function() {
    let context = "%cHUI UPDATE :: ERROR |";
    let style = 'background-color: red; color: #ffffff; padding: 2px 10px; border-radius: 5px 0 0 5px; font-weight:500; font-family:Arial;'
    return Function.prototype.bind.call(console.error, console, context, style);
}();

let huiLog = {updateWarning, updateError, updateInfo}
export {huiLog}