import React from 'react';

interface CircularProgressProps {
  maxAmount: number
  fillAmount: number
  maxText: string
  fillText: string
  progressColor?: ProgressColorTypes
  emptyCircle?: boolean
  wrapperClass?: string
  children?: React.ReactNode,
  isLoading?: boolean
}

export type ProgressColorTypes = "default" | "red" | "green" | "medical" | "travel"

export const CircularProgress: React.FC<CircularProgressProps> = ({ maxAmount, fillAmount, maxText, fillText, progressColor, emptyCircle, wrapperClass, children, isLoading }) => {


  const getDegrees = () => {
    const percentage = fillAmount / maxAmount
    return Math.floor(360 - (360 * percentage))
  }

  const getProgressColor = (color: ProgressColorTypes) => {
    switch (color) {
      case "default":
        return "#007BC2";
      case "green":
        return "#029600";
      case "red":
        return "#E3124B";
      case "medical":
        return "#175c5f"
      case "travel":
        return "#4B97F2"
      default:
        return "#007BC2";
    }
  };

  return (
    isLoading ? (
        <div className="circular-progress-outer skeleton" />
    ) : (
      <div
        className={`circular-progress-outer ${progressColor} ${wrapperClass}`}
        style={{
          background: `conic-gradient(transparent ${getDegrees()}deg, ${getProgressColor(progressColor || "default")} ${getDegrees()}.5deg 100%)`
        }}
      >
        <div
          className="circular-progress-inner"
          style={{
            background: `conic-gradient(grey ${getDegrees()}deg, transparent ${getDegrees()}.5deg 100%)`
          }}
        >
          <div
            className="circular-progress-body"
          >
            {children ? (
              children
            ) : (
              !emptyCircle && (
                <>
                  <h1>{maxAmount}</h1>
                  <h5>{maxText}</h5>
                  <span>{fillAmount} {fillText}</span>
                </>
              )
            )}

          </div>
        </div>
      </div>
    )
  )
}

CircularProgress.defaultProps = {
  progressColor: "default"
}