import React, { useState, useEffect } from "react";
import "./picker.css";
import { DatePicker, Day, DayRange } from "./CalendarDisplay";
import { useForm } from "react-hook-form";
import Cleave from "cleave.js/react";
import moment from "moment";
import { ValidationContainer } from "../Form/ValidationContainer";

export type Theme = "Blue" | "Pink" | "Green"; // we will need a universal colours that are part of the atlas theme, this component should not determine it's own colours
export interface ValidationProps {
	message: string;
	type: ValidationType;
	active: boolean;
}
export enum ValidationType {
	Required,
}

export interface ICalendarInputProps {
	id: string;
	value?: any;
	onChange: any;
	shouldHighlightWeekends?: boolean;
	format?: string;
	maxDate?: Day;
	minDate?: Day;
	className?: string;
	placeholder?: string;
	yearTollerance?: "" | "";
	isRangeSelected?: boolean;
	theme?: Theme;
	isMultiSelect?: boolean;
	disabledDays?: any;
	required?: boolean;
	clearable?: boolean;
	yearRequired?: boolean;
	readonly?: boolean;
	validation?: ValidationProps;
	properties?: { [key: string]: string };
	register?: any; // https://react-hook-form.com/api#register
	errors?: any; // https://react-hook-form.com/api#errors
	setValue?: any; // https://react-hook-form.com/api#setValue
}

export const CalendarInput: React.FC<ICalendarInputProps> = (props) => {
	let { register, errors, setValue } = useForm({
		mode: "onChange",
	});

	if (register) register = register;

	if (props.errors) errors = props.errors;

	if (props.setValue) setValue = props.setValue;

	const [defaultDate, setDefaultDate]: any = useState(null);
	const [dateDisplay, setDateDisplay]: any = useState("");
	const [dateRange, setDateRange]: any = useState({
		fromDate: "",
		toDate: "",
	});

	useEffect(() => {
		if (props.value) {
			let startupValue = getDefaultDate(props.value, props.isRangeSelected, props.isMultiSelect);
			setDefaultDate(startupValue);
			let displayDate = updateDisplay(startupValue);
			setDateDisplay(displayDate);
		}
		else {
			clearDate()
		}
	}, [props.value]);

	const getDefaultDate = (value: any, isRangeSelected?: boolean, isMultiSelect?: boolean) => {
		if (value === undefined || value === null) {
			if (isRangeSelected) {
				let defaultDate: DayRange = {
					from: null,
					to: null,
				};
				setDefaultDate(defaultDate);
				return defaultDate;
			}
			if (isMultiSelect) {
				let defaultDate: Array<Day> = [];
				setDefaultDate(defaultDate);
				return defaultDate;
			}
			let defaultDate = null;
			setDefaultDate(defaultDate);
			return defaultDate;
		}

		if (value instanceof Date) {
			value = {
				day: value.getDate(),
				month: value.getMonth() + 1,
				year: value.getFullYear(),
			};
		}

		if (value.from !== undefined && value.to !== undefined) {
			setDefaultDate(value);
			return value;
		}

		if (value !== undefined && new Date(value) instanceof Date) {
			let year: number = 0;
			let month: number = 0;
			let day: number = 0;

			if (value.indexOf("-") > -1) {
				year = value.split("-")[0];
				month = value.split("-")[1];
				day = value.split("-")[2];
			}

			value = new Date(year, month - 1, day, 0, 0, 0);

			value = {
				day: value.getDate(),
				month: value.getMonth() + 1,
				year: value.getFullYear(),
			};
		}

		if (isRangeSelected && value.type !== "DayRange") {
			let toDate = new Date(value.year, value.month - 1, value.day, 0, 0, 0);
			toDate.setDate(toDate.getDate() + 3);
			let toValue = {
				day: toDate.getDate(),
				month: toDate.getMonth() + 1,
				year: toDate.getFullYear(),
			};
			let defaultDate = {
				from: value,
				to: toValue,
			};

			setDefaultDate(defaultDate);
			return defaultDate;
		}

		if (isMultiSelect && value.type !== "Day[]") {
			let defaultDate = [];
			defaultDate.push(value);
			setDefaultDate(defaultDate);
			return defaultDate;
		}

		setDefaultDate(value);
		return value;
	};

	const handleChange = (date: any) => {
		setDefaultDate(date);
		let displayDate = updateDisplay(date);
		setDateDisplay(displayDate);
		if (!props.isRangeSelected && !props.isMultiSelect) {
			if (props.yearRequired === undefined || props.yearRequired) {
				let newDate = moment(`${date.year}-${date.month}-${date.day}T00:00:00`, "YYYY-MM-DDTHH:mm:ss").format("YYYY-MM-DD");
				props.onChange(newDate);
			} else {
				props.onChange(`${date.month}-${date.day}`);
			}
		} else {
			props.onChange(date);
		}
	};

	const isValidDate = (dateString: string, yearRequired: boolean) => {
		// First check for the pattern
		if (yearRequired && !/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) return false;
		else if (!yearRequired && !/^\d{1,2}\/\d{1,2}$/.test(dateString)) return false;
		// Parse the date parts to integers
		var parts = dateString.split("/");
		var day = parseInt(parts[1], 10);
		var month = parseInt(parts[0], 10);
		var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
		if (yearRequired) {
			let yearValue = parts[2].length === 2 ? `20${parts[2]}` : parts[2];
			var year = parseInt(yearValue, 10);

			// Check the ranges of month and year
			if (year < 1000 || year > 3000 || month === 0 || month > 12) return false;
			// Adjust for leap years
			if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) monthLength[1] = 29;
		} else {
			monthLength[1] = 29;
		}
		// Check the range of the day
		return day > 0 && day <= monthLength[month - 1];
	};

	const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (props.isRangeSelected && !props.isMultiSelect) {
			const values = e.currentTarget.value.split(" ");
			const fromDate = values[1];
			const toDate = values.length > 4 ? values[3] : "";
			setDateRange({ fromDate, toDate });
		}
		if (props.isMultiSelect && props.isRangeSelected) {
			console.log("Manual entry not supported for multiselect or date ranges");
		}
		let val = e.currentTarget.value;
		setDateDisplay(val);

		evalDate(val);
	};

	const evalDate = (val: string, e?: any) => {
		if (isValidDate(val, props.yearRequired === undefined ? true : props.yearRequired)) {
			var parts = val.split("/");
			var day = parseInt(parts[1], 10);
			var month = parseInt(parts[0], 10);
			let yearValue = parts[2];
			var year = parseInt(yearValue, 10);

			let date = moment(`${year}-${month}-${day}T00:00:00`, "YYYY-MM-DDTHH:mm:ss").format("YYYY-MM-DD");
			let dateValue = getDefaultDate(date, props.isRangeSelected, props.isMultiSelect);
			props.onChange(date);
			setDefaultDate(dateValue);
			if (e) {
				e.currentTarget.value = val;
			}
		}
	};

	const getPrimaryColor = (theme: Theme) => {
		switch (theme) {
			case "Blue":
				return "#20447C";
			case "Green":
				return "#0B393C";
			case "Pink":
			default:
				return "#E3124C";
		}
	};

	const getLighterColor = (theme: Theme) => {
		switch (theme) {
			case "Blue":
				return "rgba(32, 68, 124, 0.4)";
			case "Green":
				return "rgba(11, 57, 60, 0.4)";
			case "Pink":
			default:
				return "rgba(227, 18, 76, 0.4)";
		}
	};

	useEffect(() => {
		if (defaultDate === null) {
			let startupValue = getDefaultDate(props.value, props.isRangeSelected, props.isMultiSelect);
			setDefaultDate(startupValue);
			let displayDate = updateDisplay(startupValue);
			setDateDisplay(displayDate);
		}

		let element: any = document.getElementById(props.id);
	});

	const updateDisplay = (selectedDate: any) => {
		if (selectedDate === null || selectedDate === "" || selectedDate === undefined) {
			return undefined;
		}
		if (selectedDate.from !== undefined || selectedDate.to !== undefined) {
			let returnString = "";
			if (selectedDate.from !== null && selectedDate.from !== undefined && selectedDate.from !== "") {
				returnString += `from ${selectedDate.from.month < 10 ? "0" + selectedDate.from.month : selectedDate.from.month}/${
					selectedDate.from.day < 10 ? "0" + selectedDate.from.day : selectedDate.from.day
				}/${selectedDate.from.year} `;
			}
			if (selectedDate.to !== null && selectedDate.to !== undefined && selectedDate.to !== "") {
				returnString += `to ${selectedDate.to.month < 10 ? "0" + selectedDate.to.month : selectedDate.to.month}/${selectedDate.to.day < 10 ? "0" + selectedDate.to.day : selectedDate.to.day}/${
					selectedDate.to.year
				}`;
			}
			return returnString;
		} else if (selectedDate.length > 0) {
			let returnString = [];
			for (var i = 0; i < selectedDate.length; i++) {
				if (selectedDate[i] !== null && selectedDate[i] !== "") {
					returnString.push(
						`${selectedDate[i].month < 10 ? "0" + selectedDate[i].month : selectedDate[i].month}/${selectedDate[i].day < 10 ? "0" + selectedDate[i].day : selectedDate[i].day}/${
							selectedDate[i].year
						}`
					);
				}
			}
			return returnString.join(", ");
		} else if (props.yearRequired === undefined || props.yearRequired) {
			return selectedDate && selectedDate !== "" && selectedDate !== undefined
				? `${selectedDate.month < 10 ? "0" + selectedDate.month : selectedDate.month}/${selectedDate.day < 10 ? "0" + selectedDate.day : selectedDate.day}/${selectedDate.year}`
				: undefined;
		} else {
			return selectedDate ? `${selectedDate.month < 10 ? "0" + selectedDate.month : selectedDate.month}/${selectedDate.day < 10 ? "0" + selectedDate.day : selectedDate.day}` : undefined;
		}
	};

	const getMaskPattern = () => {
		if (props.isRangeSelected && !props.isMultiSelect) {
			return {
				delimiters: ["from ", "/", "/", " to ", "/", "/"],
				blocks: [0, 2, 2, 4, 2, 2, 4],
				// numeral: true
			};
		}
		if (props.yearRequired === undefined || props.yearRequired) {
			return { date: true, delimiter: "/", datePattern: ["m", "d", "Y"] };
		} else {
			return { date: true, delimiter: "/", datePattern: ["m", "d"] };
		}
	};

	const clearDate = () => {
		setDefaultDate("");
		setDateDisplay("");
		props.onChange && props.onChange(null);
	};

	const handleBlur = (e: any) => {
		if (e) {
			if (!props.isMultiSelect && props.isRangeSelected) {
				console.log("VALUE", e.currentTarget.value);
				return;
			}
			const dateVal = e.currentTarget.value;

			if (dateVal) {
				let year = e.currentTarget.value.split("/")[2].split(" ")[0];
				if (!year || year.length > 2) {
					return;
				}
				if (parseInt(year) > 60) {
					year = "19" + year;
				} else {
					year = "20" + year;
				}
				const fullVal = `${dateVal.split("/")[0]}/${dateVal.split("/")[1]}/${year}`;

				evalDate(fullVal, e);
			}
		}
	};

	const renderCustomInput = ({ ref }: any) => {
		return (
			<div className={`text calendar-input icon-end compact ${props.required && !dateDisplay && "required"}`}>
				<div>
					{dateDisplay && props.clearable && (
						<button className={`icon-end`} aria-label={"clear"} onClick={clearDate}>
							<i className={`icon-end fas fa-times`} />
						</button>
					)}

					<span>
						<i className={`icon-end far fa-calendar`} />
					</span>
				</div>

				{/* {
                    //Default Required
                    errors[props.id]?.type === "required" && <Validation active={true} message={"Required"} type={ValidationType.Required} />
                } */}
				<ValidationContainer errors={errors} name={props.id} value={dateDisplay} />
				{!props.isRangeSelected && !props.isMultiSelect ? (
					<Cleave
						options={getMaskPattern()}
						onChange={(e: any) => onKeyUp(e)}
						onBlur={(e: any) => handleBlur(e)}
						name={props.id}
						id={props.id}
						className={`form-control`}
						htmlRef={props.required ? register({ required: props.required }) : undefined}
						placeholder={props.placeholder || "Select a day"}
						value={dateDisplay}
						required={props.required}
						{...props.properties}
					/>
				) : props.isRangeSelected ? (
					<Cleave
						options={getMaskPattern()}
						onChange={(e: any) => onKeyUp(e)}
						name={props.id}
						id={props.id}
						className={`form-control`}
						htmlRef={props.required ? register({ required: props.required }) : undefined}
						placeholder={props.placeholder || "Select a date range"}
						value={dateDisplay}
						required={props.required}
						{...props.properties}
					/>
				) : (
					<input
						onChange={(e: any) => onKeyUp(e)}
						name={props.id}
						id={props.id}
						required={props.required}
						className={`form-control`}
						ref={props.required ? register({ required: props.required }) : undefined}
						placeholder={props.placeholder || "Select a day"}
						value={dateDisplay}
						{...props.properties}
					/>
				)}
			</div>
		);
	};

	return (
		<div>
			{props.readonly && <input id={props.id} name={props.id} className={`form-control read-only`} readOnly={props.readonly} value={dateDisplay} ref={register} />}
			{!props.readonly && (
				<DatePicker
					value={defaultDate}
					onChange={handleChange}
					shouldHighlightWeekends={props.shouldHighlightWeekends || true}
					maximumDate={props.maxDate}
					minimumDate={props.minDate}
					colorPrimary={getPrimaryColor(props.theme || "Blue")}
					colorPrimaryLight={getLighterColor(props.theme || "Blue")}
					disabledDays={props.disabledDays}
					renderInput={renderCustomInput}
					calendarPopperPosition="bottom"
					yearRequired={props.yearRequired === undefined ? true : props.yearRequired}
					isRangedSelected={props.isRangeSelected}
				/>
			)}
		</div>
	);
};
