import * as React from 'react';
import { TableLvl2Context } from '../Table/TableLvl2ContextProvider';
import { TableCellProps } from './types';

const TableCell: React.FC<React.PropsWithChildren<TableCellProps>> = (props: React.PropsWithChildren<TableCellProps>) => {
  const mainProps = React.useContext(TableLvl2Context);
  let { variant } = mainProps;
  const { classes, variant: localVariant, align } = props;
  if (localVariant !== null) {
    variant = localVariant
  }
  const CustomTag = `${variant === 'head' ? 'th' : 'td'
    }` as keyof JSX.IntrinsicElements;

  let finalClasses = 'HuiTableCell';
  if (classes) finalClasses += ` ${classes}`;
  if (align) finalClasses += ` align${align.charAt(0).toUpperCase() + align.slice(1)}`
  return (
    <CustomTag colSpan={props.colSpan} style={props.style} className={finalClasses}>
      {props.children}
    </CustomTag>
  );
};

export default TableCell;
