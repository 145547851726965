import React from 'react';

import { MetricCard, MetricCardProps } from './';

interface MetricGroupProps
{
    items: MetricCardProps[]
}
 
export default class MetricGroup extends React.Component<MetricGroupProps>
{
    render(): React.ReactNode
    {
        return (
            <div className="metric-cards card-deck" >
                {
                    this.props.items.map((item: MetricCardProps, number: number) => (
                        <MetricCard key={number} {...item} />
                    ))
                }
            </div>
        );
    }
}