import React from 'react';

import Text, { TextType } from './Text';

export interface VideoItemProps
{
    url:string,
    label:string,
    youtube?:boolean,

}

export default class VideoItem extends React.Component<VideoItemProps>
{
    public static defaultProps =
        {
           youtube:true
        };
 
    render(): React.ReactNode 
    {
        if (this.props.youtube){
                return (
                <div className="video-container col-lg-8 col-md-10 col-sm-12 mx-auto mb-3">
                <div className="video-item">
                    <iframe title={`${this.props.label} iframe`} width="100%" height="360" src={this.props.url} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" ></iframe>
                </div>
                <Text content={this.props.label} type={TextType.Body} padded={true}/> 
                </div>
                );
        }

    }
}