import { elementType, TableFilterConfig } from '../Table/types';

export interface TableFilterProps {
  classes?: string;
  component?: elementType;
  style?: any;
  filterConfig: TableFilterConfig
}

export const defaults: TableFilterProps = {
  classes: '',
  component: 'div',
  filterConfig: {
    filters: [],
    onClearSearch: () => { },
    onSearch: () => { },
    onMobileSearch: () => { }
  },
};

export interface TableFieldFilter {
  type: 'text' | 'number' | 'select';
  value: any;
  fieldName: string;
  onChangeField?: any;
  filterPinned: any;
  mobilePinned: any;
}