import React from "react";
import Text, { TextType } from "../components/Text";
import {huiLog} from '../common/huiConsoleLogs';

//https://www.digitalocean.com/community/tutorials/how-to-build-a-custom-toggle-switch-with-react#step-4-%E2%80%94-building-the-sample-app

interface ToggleState {
	choice: ToggleChoice;
}

interface ToggleProps {
	choice: ToggleChoice;
	name?: string;
	onClick?: any;
	static?: boolean;
	onStateChanged?: any;
	showBothOptions?: boolean;
	inverse?: boolean; //swap positive and negative colors
	inverseLabels?: boolean;
	positiveLabel?: string; // only for options that show labels
	negativeLabel?: string; // only for options that show labels
	neutralLabel?: string;
	disabled?: boolean;
	ariaLabel?:string;
	altAriaLabel?: string;
	ariaLabelledby?: string;
}

export type ToggleChoice = "NotSelected" | "Positive" | "Negative" | "Neutral";

export default class Toggle extends React.Component<ToggleProps, ToggleState> {
	constructor(props: ToggleProps) {
		super(props);

		if (!this.props.showBothOptions) {
			this.state = {
				choice: this.props.choice === "NotSelected" ? "Negative" : this.props.choice,
			};
		} else {
			this.state = {
				choice: this.props.choice,
			};
		}
	}

	componentDidUpdate(prevProps: ToggleProps) {
		if (this.props.choice !== prevProps.choice) {
			this.setState({
				choice: this.props.choice === "NotSelected" ? "Negative" : this.props.choice,
			});
		}
	}

	toggleSwitch = (evt: any, state: ToggleChoice) => {
		if (this.props.static || this.props.disabled) return;

		evt.persist();
		evt.preventDefault();

		const { onClick, onStateChanged } = this.props;

		this.setState({ choice: state }, () => {
			const state = this.state;

			// Augument the event object with SWITCH_STATE
			const switchEvent = Object.assign(evt, { SWITCH_STATE: state });

			// Execute the callback functions
			onClick && onClick(switchEvent);
			onStateChanged && onStateChanged(state);
		});
	};

	getPositiveToggleIcon = (choice: boolean) => {
		if (this.props.inverseLabels) {
			choice = !choice;
		}

		if (choice) {
			return this.props.positiveLabel;
		} else {
			return this.props.negativeLabel;
		}
	};

	getPositiveToggleAriaLabel = (choice: boolean) => {
		if (this.props.inverseLabels) {
			choice = !choice;
		}

		if (choice) {
			return this.props.positiveLabel;
		} else {
			return this.props.negativeLabel;
		}
	};

	toggleSwitchEnter = (evt: any, state: ToggleChoice) => {
		if (this.props.static || this.props.disabled) return;
		let key = evt.key || evt.keyCode
		if (key === 13) {
			evt.persist();
			evt.preventDefault();

			const { onClick, onStateChanged } = this.props;

			this.setState({ choice: state }, () => {
				const state = this.state;

				// Augument the event object with SWITCH_STATE
				const switchEvent = Object.assign(evt, { SWITCH_STATE: state });

				// Execute the callback functions
				onClick && onClick(switchEvent);
				onStateChanged && onStateChanged(state);
			});
		}
	};

	render() {

		if(!this.props.ariaLabel){
			huiLog.updateWarning(
				`\nA UNIQUE [ariaLabel] attributes will soon become required for all [Toggle] components. 
				\nPlease update all [Toggle] components with this atrribute ASAP.`
			);
		}
		const { choice } = this.state;

		// Isolate special props and store the remaining as restProps
		const { choice: _choice, onClick, onStateChanged, ...restProps } = this.props;

		const getClasses = (): string => {
			var classes: string[] = [];

			classes.push("switch-toggle");

			if (this.props.inverse) classes.push("inverse");

			if (this.props.disabled) classes.push("disabled");

			classes.push(`switch-toggle--${choice === "Positive" ? "on" : "off"}`);

			return classes.join(" ");
		};

		if (!this.props.showBothOptions)
			return (
				<div className={`switch switch--default ${getClasses()}`} onClick={(e: any) => this.toggleSwitch(e, this.state.choice === "Positive" ? "Negative" : "Positive")}>
					<input type="hidden" id={this.props.name} name={this.props.name} value={this.state.choice.toString()} />
					<div
						onKeyDown={(e: any) => this.toggleSwitchEnter(e, this.state.choice === "Positive" ? "Negative" : "Positive")}
						tabIndex={0}
						role="switch"
						aria-label={
							this.props.ariaLabel? 
							`${this.props.ariaLabel} toggle switch currently set to: ${this.state.choice === "Positive" ? this.props.positiveLabel : this.props.negativeLabel} ${this.props.disabled? 'read-only' : `Press enter to set to: ${this.state.choice === "Positive" ? this.props.negativeLabel : this.props.positiveLabel}` }` 
							:
							`toggle switch currently set to: ${this.state.choice === "Positive" ? this.props.positiveLabel : this.props.negativeLabel}.; ${this.props.disabled? 'read-only.' : `Press enter to set to: ${this.state.choice === "Positive" ? this.props.negativeLabel : this.props.positiveLabel}` }`
						}
						aria-labelledby={this.props.ariaLabelledby}
						aria-checked={this.state.choice === "Positive"}
						className={getClasses()}/>
					{this.state.choice === "Negative" && this.props.negativeLabel && <Text color="red" type={TextType.Body} content={this.props.negativeLabel} />}
					{this.state.choice === "Positive" && this.props.positiveLabel && <Text color="green" type={TextType.Body} content={this.props.positiveLabel} />}
				</div>
			);

		if (this.props.showBothOptions)
			return (
				<div className={`switch-options ${getClasses()}`} aria-label={this.props.ariaLabel? `${this.props.ariaLabel} toggle switch` : `toggle switch` }>
					<input type="hidden" id={this.props.name} name={this.props.name} value={this.state.choice.toString()} aria-hidden={ true }/>
					{choice === "Positive" ? (
						<a
							className={`positive`}
							tabIndex={0} role="button"
							aria-pressed={true}
							aria-label={ `${this.props.altAriaLabel ? this.props.altAriaLabel : ''} ${this.props.disabled? `read-only ${this.getPositiveToggleIcon(true)} is selected.` : `${this.getPositiveToggleIcon(true)} is selected`}`}
							>
							{this.getPositiveToggleIcon(true)}
						</a>
					) : (
						<a
							className={``}
							tabIndex={0}
							role="button"
							aria-pressed={false}
							aria-label={`${this.props.altAriaLabel ? this.props.altAriaLabel : ''} ${this.props.disabled? `${this.getPositiveToggleIcon(true)} is read-only.` : `Press enter to select ${this.getPositiveToggleIcon(true)}.`}` }
							onKeyDown={(e: any) => {
								this.toggleSwitchEnter(e, "Positive");
							}}
							onClick={(e) => this.toggleSwitch(e, "Positive")}>
							{this.getPositiveToggleIcon(true)}
						</a>
					)}
					{this.props.neutralLabel && (
						<>
							{choice === "Neutral" ? (
								<a
									className={`positive`}
									tabIndex={0} role="button"
									aria-pressed={true}
									aria-label={ `${this.props.altAriaLabel ? this.props.altAriaLabel : ''} ${this.props.disabled? `read-only ${this.props.neutralLabel} is selected` : `${this.props.neutralLabel} is selected`}`}
									>
									{this.props.neutralLabel}
								</a>
							) : (
								<a
									className={``}
									role="button"
									aria-pressed={false}
									tabIndex={0}
									aria-label={`${this.props.altAriaLabel ? this.props.altAriaLabel : ''} ${this.props.disabled? `${this.props.neutralLabel} is read-only.` : `Press enter to select ${this.props.neutralLabel}.`}`}
									onKeyDown={(e: any) => {
										this.toggleSwitchEnter(e, "Neutral");
									}}
									onClick={(e) => this.toggleSwitch(e, "Neutral")}>
									{this.props.neutralLabel}
								</a>
							)}
						</>
					)}
					{choice === "Negative" ? (
						<a tabIndex={0} className={`negative`} role="button" aria-pressed={true} aria-label={ `${this.props.disabled? `read-only ${this.getPositiveToggleIcon(false)} is selected.` : `${this.getPositiveToggleIcon(false)} is selected`}`} >
							{this.getPositiveToggleIcon(false)}
						</a>
					) : (
						<a
							tabIndex={0}
							className={``}
							role="button"
							aria-pressed={false}
							aria-label={`${this.props.altAriaLabel ? this.props.altAriaLabel : ''}  ${this.props.disabled? 	`${this.getPositiveToggleIcon(false)} is read-only.` : `Press enter to select ${this.getPositiveToggleIcon(false)}`}`}
							onKeyDown={(e: any) => {
								this.toggleSwitchEnter(e, "Negative");
							}}
							onClick={(e) => this.toggleSwitch(e, "Negative")}>
							{this.getPositiveToggleIcon(false)}
						</a>
					)}
				</div>
			);
	}
}
