import * as React from "react";

export interface IStepProps {
    name: string,
    disabled?: boolean,
    completed?: boolean,
    stepNumber: string,
    isCurrentStep?: boolean,
    displayLine?: boolean
}

export const getStepClass = (completed?: boolean, isCurrentStep?: boolean, disabled?: boolean) => {
    let resultingClass:Array<string> = ["btn", "btn-circle"];
    if (completed) {
        resultingClass.push("step-completed");
    } 
    if (isCurrentStep) {
        resultingClass.push("step-current")
    }
    if (disabled) {
        resultingClass.push("step-disabled")
    }
    return resultingClass.join(" ");
}

export const getLineClass = (isCurrentStep: boolean) => {
	let resultingClass:Array<string> = ["step-line"];
	if (isCurrentStep) {
        resultingClass.push("step-completed");
    } 
    return resultingClass.join(" ");
}

export const Step: React.FC<IStepProps> = 
({
    name,
    disabled,
    completed,
    stepNumber,
    isCurrentStep,
	displayLine
}) => {
	return (
        <div className='stepwizard-step col-xs-3'> 
            { displayLine && (
            	<div className={getLineClass(isCurrentStep || false)}></div>
        	)}
            <a type="button" className={getStepClass(completed, isCurrentStep, disabled)}>{stepNumber}</a>
            <p className="step-text"><small>{name}</small></p>
        </div>
    )
}