import * as React from "react";
import { FC, useState } from "react";
import { Switch, Route } from "react-router-dom";
import { withRouter } from "react-router";
import MedicalLocationTimes from "./components/location/MedicalLocationTimes";
import FluShots from "./components/fluShots/FluShots";
import { CovidNavInterface } from "./model/CovidNav";
import { PeopleStoreInterface } from "./model/PeopleStore";
import { AutoCompleteProps } from "@mit/hui/build/components/AutoComplete";
import FollowUp from "./components/followUp/FollowUp";
//import VaccineHold from "./components/vaccineHold/VaccineHold";
import CompletedTests from "./components/completedTests/CompletedTests";
import Logout from "./components/Logout";
import PersonProfile from "./components/search/PersonProfile";
import searchUser from "./model/SearchUser";
import userController from "./api/UserController";
import { inject, observer, Provider } from "mobx-react";
import { HoldsModelInterface } from "./model/Holds";
import { BasicLayout, NavBar, NavBarTypes, ResponsiveTypes, TextboxType } from "@mit/hui";
import ContactTracing from "./components/contactTracing/ContactTracing";
import Vaccine from "./components/vaccine/Vaccine";
import cloudSearchController from "./api/CloudSearchController";
import TimeManagement from "./components/timeManagement/TimeManagement";
import peopleController, { FetchPeopleResponseAws } from "./api/PeopleController";

export interface IHomeProps {
	holds?: HoldsModelInterface;
	history?: any;
	peopleStore?: PeopleStoreInterface;
	covidNav?: CovidNavInterface;
	title?: string;
}

const Home: FC<IHomeProps> = ({ holds, history, peopleStore, covidNav }) => {
	const [isSearchLoading, setSearchLoading] = useState(false);
	const [searchData, setSearchData]: any = useState([]);

	const [timeout, setSearchTimeout]: any = useState(0);

	const handleSearch = (query: string, element: React.KeyboardEvent<HTMLInputElement>) => {
		if (timeout) {
			clearTimeout(timeout);
		}

		if (query.length <= 0) return;
    if (element.key === 'Tab') {
      return
    }
		if (element.keyCode === 8 || element.keyCode === 46) return;

		setSearchLoading(true);
		setSearchTimeout(
			setTimeout(() => {
				//CLOUD SEARCH (JUST UNCOMMENT)

				// cloudSearchController.search(query).then((results) => {
				// 	if (results.items) {
				// 		setSearchLoading(false);
				// 		let newSearchData = results.items.hits.hit.map((itm) => {
				// 			return {
				// 				icon: "user",
				// 				text: `${itm.fields.suggestion}`,
				// 				secondaryText: "",
				// 				tertiaryText: "",
				// 				id: itm.fields.krb_name,
				// 			};
				// 		});
				// 		setSearchData(newSearchData);
				// 		setSearchLoading(false);
				// 	}
				// });

				peopleController.fetchPeopleAws(query).then((peopleResultsResponse: FetchPeopleResponseAws) => {
					if (peopleResultsResponse.people) {
						setSearchLoading(false);
						let newSearchData = peopleResultsResponse.people.map((itm: any) => {
							return {
								icon: "user",
								text: `${itm.displayName} (${itm.kerberosId})`,
								secondaryText: "",
								tertiaryText: "",
								id: itm.kerberosId,
							};
						});
						setSearchData(newSearchData);
						setSearchLoading(false);
					}
				});
			}, 500)
		);
	};

	const personSelected = async (person: any) => {
		if (person.id) {
			let profileResp = await userController.fetchProfile(person.id);
			if (profileResp.person) {
				holds!.personHolds = undefined;
				searchUser!.person = { label: person, ...profileResp.person };
				history.push(`/search?krb_name=${searchUser!.person.kerberosId}`);

				covidNav!.activePage = "search";
			}
		}
	};

	const autoCompleteData: AutoCompleteProps = {
		name: "searchbox",
		onChange: personSelected,
		isBusy: isSearchLoading,
		items: searchData,
		type: "default",
		searchOptions: {
			startIcon: "search",
			startIconAccessibilityText: "search",
			placeholderText: "Search by name or kerberos id",
			type: TextboxType.IconStart,
			name: "search",
		},
    showItemsOnFocus: false,
    onBlur: () => setSearchLoading(false),
		onSearch: handleSearch,
	};

	const getTitle = () => {
		let url = window.location.pathname;
		switch (url) {
			case "/":
			case "/folow-up":
			default:
				return "Follow-up Required";
			// case "/vaccine-hold":
			// 	return "Vaccine Holds";
			case "/search":
				return "Search";
			case "/completed-tests":
				return "Completed Tests";
			case "/time-management":
				return "Time Management";
			case "/flushots":
				return "Flushots";
			case "/vaccine":
				return "Vaccine";
			case "/contact-tracing":
				return "Covid Contact Tracing";
		}
	};

	return (
		<BasicLayout
			startState={true}
			autoComplete={autoCompleteData}
			fullWidth={true}
			content={
				<Switch>
					{/* <Route exact path={"/contact-tracing"}>
						<Provider searchUser={searchUser} peopleStore={peopleStore}>
							<ContactTracing />
						</Provider>
					</Route> */}
					<Route exact path={"/search"}>
						<Provider searchUser={searchUser}>
							<PersonProfile />
						</Provider>
					</Route>
					<Route exact path={"/logout"}>
						<Logout />
					</Route>
					{/* <Route exact path={"/follow-up"}>
						<Provider searchUser={searchUser} peopleStore={peopleStore}>
							<FollowUp peopleStore={peopleStore} history={history} />
						</Provider>
					</Route> */}
					{/* <Route exact path={"/vaccine-hold"}>
						<Provider searchUser={searchUser} peopleStore={peopleStore}>
							<VaccineHold peopleStore={peopleStore} history={history} />
						</Provider>	
					</Route> */}
					<Route exact path={"/completed-tests"}>
						<Provider searchUser={searchUser} peopleStore={peopleStore}>
							<CompletedTests />
						</Provider>
					</Route>
					<Route exact path={"/flushots"}>
						<Provider searchUser={searchUser} peopleStore={peopleStore}>
							<FluShots />
						</Provider>
					</Route>
					<Route exact path={"/vaccine"}>
						<Provider searchUser={searchUser} peopleStore={peopleStore} holds={holds}>
							<Vaccine />
						</Provider>
					</Route>
					{/* <Route exact path={"/time-management"}>
						<TimeManagement />
					</Route> */}
					<Route exact path={"/"}>
						<Provider searchUser={searchUser} peopleStore={peopleStore}>
							<FollowUp />
						</Provider>
					</Route>
				</Switch>
			}
			contentTitle={getTitle()}
		/>
	);
};

const HomeWrapper = withRouter(inject("peopleStore", "holds", "covidNav")(observer(Home)));

export default HomeWrapper;
