import {observable} from "mobx";
import {PersonAutocomplete} from "../api/PeopleController";
import {PersonProfileInterface} from "../api/UserController";

export interface SearchUserInterface {
    person?: PersonProfileInterface;
}

class SearchUser {
    @observable person: PersonAutocomplete
}
const searchUser = new SearchUser()
export default searchUser