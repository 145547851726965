import * as React from "react";
import { inject, observer } from "mobx-react";
import { PeopleStoreInterface } from "../../model/PeopleStore";
import { FC, useState, useEffect } from "react";
import { formatPhoneNumber, usDateTime } from "../../common/Formatters";
import { CovidNav } from "../../model/CovidNav";
import { HoldsModelInterface } from "../../model/Holds";
import { AdvancedTable, ButtonType, Error, ErrorContext, ErrorType, Icon } from "@mit/hui";
import { IAdvancedTableColumn } from "@mit/hui/build/components/AdvancedTable";
import ViewPerson from "../common/ViewPerson";
import ChangeHoldStatus from "../holds/ChangeHoldStatus";
import { withRouter } from "react-router-dom";
import medicalApi, { FetchHoldsResponse } from "../../api/MedicalApi";
import PersonDetailsDrawerComponent from '../common/PersonDetailsDrawer'

interface PeopleListProps {
	covidNav?: CovidNav;
	peopleStore?: PeopleStoreInterface;
	holds?: HoldsModelInterface;
	history?: any;
}

const FollowUpListComponent: FC<PeopleListProps> = ({ peopleStore, holds, covidNav, history }) => {
	const [showPerson, setShowPerson] = useState(false);
	const [showEdit, setShowEdit] = useState(false);
	const [person, setPerson] = useState<any>();
	const [editRecord, setEditRecord] = useState();
	const [isLoading, setLoading] = useState(false);
	const [isUnauth, setUnauth] = useState(false);

	useEffect(() => {
		if (peopleStore!.followUpList === undefined) {
			setLoading(true);
			reloadDataFollowUp();
		}
	});

	const reloadDataFollowUp = async () => {
        covidNav!.globalTableIsLoading = true;
        const followUpResponse: FetchHoldsResponse = await medicalApi.fetchAllHolds()
        if (followUpResponse.error) {
            //todo: handle error
		} else if (followUpResponse.unauth) {
			//setLoading(false);
			setUnauth(true);
        } else {
            if (followUpResponse.items) {
				setLoading(false);
                peopleStore!.setFollowUpList(followUpResponse.items)
                covidNav!.globalTableIsLoading = false;
            }
        }
    }

	const viewPersonClose = () => {
		setPerson(undefined);
		setShowPerson(false);
	};

	const editHoldStatusClose = () => {
		setEditRecord(undefined);
		setShowEdit(false);
		setLoading(true)
		reloadDataFollowUp();
	};

	const handlePersonClick = (item: any) => {
		covidNav!.activePage = "search";
		history.push(`/search?krb_name=${item.kerberos}`);
	};

	const showPersonInfo = (item: any) => {
		setShowPerson(true);
		setPerson(item);
	};

	const getClassName = (status: string) => {
    if (status.toLowerCase() === 'open') {
      return 'open-status'
    } else if (status.toLowerCase() === 'new') {
      return 'new-status'
    }
	  return ''
  }

	const columns: Array<IAdvancedTableColumn> = [
		{
			id: "display_name",
			name: "Name",
			sortable: true,
			formatter: (item: any) => {
				return (
					<div
						className="clickable"
						onClick={() => {
							showPersonInfo(item);
						}}
						tabIndex={0}
					>
						<span>{item.display_name}</span>
					</div>
				);
			},
			options: {
				demandPopin: true,
				minWidth: 600,
				popinText: "Name",
			},
			filterable: true,
			filterOptions: {
				type: "textbox",
				onFilter: (item: any, value: any) => {
					if (item.display_name.toLowerCase().indexOf(value.toLowerCase()) != -1)
						return true;

					return false;
				},
			}
		},
		{
			id: "vaccination_status",
			name: "Vaccination Status",
			sortable: false,
			formatter: (item: any) => {
				let iconsOptions = [
					{
						status: "none",
						color: "red",
						icon: "shield",
						display: false
					},
					{
						status: "partial",
						color: "orange",
						icon: "shield-alt",
						display: false
					},
					{
						status: "full",
						color: "green",
						icon: "shield-check",
						display: true
					},
					{
						status: "exempt",
						color: "grey",
						icon: "comment-check",
						display: false
					}
				]
				
				let iconCfg = iconsOptions.find(o=> o.status === item.vaccination_status);

				return (
					<span style={{fontSize:"1.5em"}}>
					{iconCfg && iconCfg.display &&
						<Icon 
							type={"solid"}
							color={iconCfg? iconCfg.color : 'grey'}
							icon={iconCfg? iconCfg.icon : "question-square"}
							padded={false}
						/>
					}
					</span>
				);
			},
			options: {
				demandPopin: false,
				popinText: "Vaccination Status",
			},
			filterable: true,
			filterOptions: {
				type: "dropdown",
				createOptionsOffItems: true,
				onFilter: (item: any, value: any) => {
					if (value) return item.vaccination_status === value;
					return true;
				},
			},
		},
		{
			id: "phone_number",
			name: "Phone Number",
			sortable: false,
			formatter: (item: any) => {
				return item.phone_number && <a href={`tel:${item.phone_number}`}>{formatPhoneNumber(item.phone_number)}</a>;
			},
			options: {
				demandPopin: true,
				minWidth: 600,
				popinText: "Phone Number",
			},
			filterable: false,
		},
		{
			id: "email",
			name: "Email",
			sortable: false,
			formatter: (item: any) => {
				return (
					<a className="table-email" href={`mailto:${item.email}`}>
						{item.email}
					</a>
				);
			},
			options: {
				demandPopin: true,
				minWidth: 600,
				popinText: "Email",
			},
			filterable: false,
		},
		{
			id: "created_on",
			name: "Last Updated",
			sortable: true,
			formatter: (item: any) => {
				return usDateTime(item.created_on);
			},
			options: {
				demandPopin: true,
				minWidth: 1000,
				popinText: "Last Updated",
			},
			filterable: true,
			filterOptions: {
				type: "calendar",
				onFilter: (item: any, value: any) => {
					if (value !== null && value !== undefined && value !== "") {
						let fromDate = new Date(value.substr(value.indexOf(" ") + 1, value.indexOf("to ") - 6));
						let toDate = new Date(value.substr(value.indexOf("to ") + 3));
						toDate.setHours(23);
						toDate.setMinutes(59);
						toDate.setSeconds(59);
						if (fromDate && toDate) {
							var compareDate = new Date(item.created_on);
							return compareDate.getTime() >= fromDate.getTime() && compareDate.getTime() <= toDate.getTime();
						}
						return true;
					}
					return true;
				},
			},
		},
		{
			id: "updated_by",
			name: "Updated by",
			sortable: true,
			formatter: (item: any) => {
				return item.updated_by ? item.updated_by : item.created_by;
			},
			options: {
				demandPopin: true,
				minWidth: 1000,
				popinText: "Updated by",
			},
			filterable: false,
		},
		{
			id: "reason",
			name: "Reason",
			sortable: true,
			options: {
				demandPopin: true,
				minWidth: 1000,
				popinText: "Reason",
			},
			filterable: true,
			filterOptions: {
				type: "dropdown",
				createOptionsOffItems: true,
				onFilter: (item: any, value: any) => {
					if (value) return item.reason === value;
					return true;
				},
			},
		},
		{
			id: "comments",
			name: "Comments",
			sortable: false,
			options: {
				demandPopin: true,
				minWidth: 1000,
				popinText: "Comments",
			},
			filterable: false,
		},
		{
			id: "status",
			name: "Status",
			sortable: true,
      formatter: (item: any) => (
        <div className={getClassName(item.status)}>
					{item.status}
				</div>
			),
			options: {
				demandPopin: true,
				minWidth: 1000,
				popinText: "Status",
			},
			filterable: true,
			filterOptions: {
				type: "dropdown",
				createOptionsOffItems: true,
				onFilter: (item: any, value: any) => {
					if (value) return item.status === value;
					return true;
				}
			}
		}
	];

	const actionButtons = [
		{
			icon: "edit",
			type: ButtonType.Ghost,
			onClick: (e: any) => {
				setEditRecord(e);
				setShowEdit(true);
			},
			text: "",
			state: 0,
			padded: false,
		},
	];
	
	const data = peopleStore!.followUpList?.map(i => i) || []
	//const data = peopleStore!.followUpList?.map(i => i).filter(o=> o.reason !== 'Contact') || []
	//console.log('DATA', data, peopleStore!.followUpList);
	
	if (isUnauth) return <Error context={ErrorContext.Component} message="Not Authorized" type={ErrorType.AccessDenied} />;

	return (
		<>
			<AdvancedTable
				columns={columns}
				items={data ? data : []}
				showExportButton={false}
				showFilterButton={true}
				isLoading={isLoading || covidNav!.globalTableIsLoading}
				actionButtons={actionButtons}
				theme={"medical"}
				sortStateOptions={{
					enabled:true,
					key: 'covid-medical-follow-up-list'
				}}
			/>
			{/* <ViewPerson show={showPerson} person={person} onClose={viewPersonClose} /> */}
			<PersonDetailsDrawerComponent id={person?.mit_id} show={showPerson} onClose={viewPersonClose} />
			<ChangeHoldStatus show={showEdit} person={editRecord} onClose={editHoldStatusClose} />
		</>
	);
};

export const FollowUpList = withRouter(inject("peopleStore", "holds", "covidNav")(observer(FollowUpListComponent)));
