import * as React from 'react';
import { defaults, TableFilterProps, TableFieldFilter } from './types';
import { Textbox } from '../../../components/Textbox';
import { Button, ButtonType } from '../../../components/Button';
import Text, { TextType } from '../../../components/Text'
import {
  Modal,
  TemplateModalSize,
  TemplateModalAlignment,

} from "../../../components/Template";
import Checkbox from '../../../components/Checkbox';

function capitalize(str: string) {
  const lower = str.toLowerCase();
  return str.charAt(0).toUpperCase() + lower.slice(1)
}
const AllFilters: React.FC<{ filters: TableFieldFilter[], setFieldsToFilter: any }> = ({ filters, setFieldsToFilter }) => {
  return (
    <div style={{ textAlign: 'left' }}>
      {
        filters.map((filter: TableFieldFilter) => {
          const Normal = () => <Checkbox checked={filter.filterPinned} label={capitalize(filter.fieldName.toString())} name={filter.fieldName} onClick={(checked: boolean) => { filter.filterPinned = checked; setFieldsToFilter(filters) }} properties={{ "aria-label": filter.fieldName }} />;

          return (
            <Normal />
          )
        })
      }
    </div>
  )
}

const TableFilter = (props: React.PropsWithChildren<TableFilterProps>) => {
  const finalProps = { ...defaults, ...props };
  const { component, children, classes, filterConfig } = finalProps;
  const { filters, onSearch, onClearSearch } = filterConfig;
  const [fieldsToFilter, setFieldsToFilter] = React.useState(filters)
  const CustomTag = `${component}` as keyof JSX.IntrinsicElements;
  const [open, setOpen] = React.useState(false);


  const style = {
    display: 'flex',
    left: 0,
    top: 0,
  };

  const finalStyles = { ...style, ...props.style };
  let finalClasses = 'HuiTableFilter';
  if (classes) finalClasses += ` ${classes}`;
  if (fieldsToFilter === undefined) return <></>
  const showSearch = () => {
    const pinned = fieldsToFilter.find(fields => {return fields.filterPinned})
    if (pinned) return true
    else return false
  }
  const Large = () => (
    <Modal
      show={true}
      imageUrl=""
      name="exampleModal"
      size={TemplateModalSize.Large}
      bodyAlignment={TemplateModalAlignment.Right}
      header={<Text content="Configure Visible Filters" type={TextType.Heading4} icon="" />}
      body={
        filters !== undefined && <AllFilters filters={filters} setFieldsToFilter={setFieldsToFilter} />
      }
      footer={
        <>
          <Button
            icon="cancel"
            text="Close"
            onClick={() => {
              setOpen(false)
            }}
            type={ButtonType.Primary | ButtonType.Small}
          />
        </>
      }
    />
  );

  // const mainProps = React.useContext(TableFilterContext);
  return (
    <CustomTag style={finalStyles} className={finalClasses}>
      {
        open === true && <Large />
      }
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          height: '100%',
          width: '100%'
        }}
      >

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', width: '100%', marginBottom: '0.5em' }}>
          <Button
            onClick={() => {
              setOpen(!open);
            }}
            type={ButtonType.TextNaked}
            padded={true}
            icon="cog"
            text=""
          />
          {fieldsToFilter.map((fieldFilter: TableFieldFilter | undefined, index: any) => {
            if (fieldFilter) {
              const { type, onChangeField, fieldName, value, filterPinned: pinned } = fieldFilter;
              if (pinned === true) {
                if (type === 'text') {
                  return (
                    <div style={{ width: '100%', marginLeft: index !== 0 ? '0.5em' : '0', }}>

                      <div style={{ marginLeft: '0.25em', fontWeight: 400, fontSize: '13px', textTransform: 'uppercase' }}>
                        {
                          value !== null && value !== undefined && value !== "" ? fieldName : <br />
                        }
                      </div>
                      <Textbox
                        name={fieldName}
                        placeholderText={`Filter ${fieldName}`}
                        onChange={onChangeField}
                        value={value}
                        compact={false}
                      />
                    </div>
                  );
                } else if (type === 'number') {
                  return (
                    <div style={{ width: '100%', marginLeft: index !== 0 ? '0.5em' : '0' }}>
                      <div style={{ marginBottom: '0.5em', marginLeft: '0.25em', fontWeight: 400, fontSize: '13px', textTransform: 'uppercase' }}>
                        {
                          value !== null && value !== undefined && value !== "" ? fieldName : <br />
                        }
                      </div>
                      <Textbox
                        numericOnly
                        name={fieldName}
                        placeholderText={`Filter ${fieldName}`}
                        onChange={onChangeField}
                        value={value}
                      />
                    </div>
                  );
                }
              }
            }

          })}
          <div style={{ paddingTop: '2em', display: 'flex' }}>

            {
              fieldsToFilter.filter((filter: TableFieldFilter) => {
                return filter.value !== null && filter.value.length > 0
              }).length > 0 ? <div style={{ width: '100%', minWidth: '150px', display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                <br />
                <Button
                  onClick={() => {
                    onClearSearch();
                    setTimeout(() => {
                      document.getElementsByClassName("ReactVirtualized__Table__Grid")[0].scrollTop = 0;
                    })

                  }}
                  type={ButtonType.TextNaked}
                  padded={true}
                  icon="times-circle"
                  text="Clear Search"
                />
              </div> : <div style={{ marginLeft: '0.25em' }}></div>
            }

            {showSearch() && (
              <div style={{ marginLeft: '0.5em', width: '100%', minWidth: '105px', display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                <br />
                <Button
                  onClick={() => {
                    onSearch();
                    setTimeout(() => {
                      document.getElementsByClassName("ReactVirtualized__Table__Grid")[0].scrollTop = 0;
                    })
                  }}
                  type={ButtonType.Primary}
                  padded={true}
                  icon="search"
                  text="Search"
                />
              </div>
            )}

          </div>

        </div>
      </div>
    </CustomTag>
  );
};


export default TableFilter;
