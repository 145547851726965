import React, { useEffect, useState, Fragment } from "react";
import { Table, TableGrowingMode } from "@ui5/webcomponents-react";
import { ButtonType, ButtonState, ButtonProps, Button } from "../Button";
import { ActionList, Tooltip } from "../../";
import {
	AdvancedTableCell,
	AdvancedTableColumn,
	AdvancedTableRow,
	AdvancedTableFilter,
	IAdvancedTableColumnProps,
	IAdvancedTableFilterOptions,
	AdvancedTableExportCsv,
	IAdvancedTableExportCsvProps,
} from "./";
//@ts-ignore
import InfiniteScroll from "react-infinite-scroll-component";
import { AtlasTheme } from "../../components/Template";

export {TableGrowingMode} from "@ui5/webcomponents-react";


export interface IAdvancedTableColumn {
	id: string;
	displayId?: string;
	name: string;
	formatter?: (item: any) => any;
	headerFormatter?: (item: any) => any;
	options?: IAdvancedTableColumnProps;
	sortable?: boolean;
	onSort?: (data: any) => void;
	filterable?: boolean;
	filterOptions?: IAdvancedTableFilterOptions;
	className?: any;
	// matchCodeOrder?: 'MIT_ID' | 'SSN_EXACT' | 'SSN_NEAR' | 'LAST_NAME' | 'FIRST_NAME' | 'REVERSED_NAME' | 'MIDDLE_NAME' | 'DOB';
	matchCodeOrder?: 'MIT_ID' | 'LAST_NAME' | 'FIRST_NAME' | 'MIDDLE_NAME' | 'DOB';
}

export interface IAdvancedTableSortStoreProps {
	enabled: boolean
	key: string
}

export interface IAdvancedTableProps {
	columns: Array<IAdvancedTableColumn>;
	items: Array<any>;
	actionButtons?: ButtonProps[] | ((item: any) => any);
	id?: string;
	sortStateOptions?:IAdvancedTableSortStoreProps,
	noDataText?: string;
	showNoData?: boolean;
	stickyColumnHeader?: boolean;
	isLoading?: boolean;
	showFilterButton?: boolean;
	showExportButton?: boolean;
	exportOptions?: IAdvancedTableExportCsvProps;
	onClickFilterButton?: () => void;
	maxRows?: number;
	numberRowsOnScroll?: number;
	sort?: IColumnSortOptions;
	theme?: AtlasTheme;
	growing?: TableGrowingMode;
	onLoadMore?: (e:any)=> void;
	onRowClick?: (e:any)=> void;
	moreText?: string

}

export type SortDirection = "asc" | "desc";

export interface IColumnSortOptions {
	sortedBy: string;
	sortDirection: SortDirection;
	onSort?: any;
}

export const getNestedObjectValue = (value: any, key: string) => {
	let keys = key.split("/");
	keys.forEach((key: string) => {
		value = value[key];
	});
	// console.log(value)
	return value;
};

export const getSortIcon = (sortedBy: string, columName: string, sortDirection: SortDirection) => {
	if (sortedBy === columName) {
		return sortDirection === "asc" ? "sort-down" : "sort-up";
	}
	return "sort";
};

const AdvancedTable: React.FC<IAdvancedTableProps> = (props) => {

	const sortStore = {
		save(value: object) {
			if(props.sortStateOptions?.enabled) localStorage.setItem(props.sortStateOptions?.key, JSON.stringify(value));
		},
		delete() {
			if(props.sortStateOptions?.enabled)  localStorage.removeItem(props.sortStateOptions?.key);
		},
		get() {
			if(props.sortStateOptions?.enabled) {
				let data: any = localStorage.getItem(props.sortStateOptions?.key);
		
				if (data) {
					try {
						return JSON.parse(data);
					}
					catch (e) {
						return data;
					}
				}
				return props.sort;
			}else{
				return props.sort;
			}
		}
	}
	
	const {
		actionButtons = [],
		columns,
		items,
		isLoading,
		stickyColumnHeader,
		showNoData,
		noDataText,
		showFilterButton,
		showExportButton,
		exportOptions,
		onClickFilterButton,
		maxRows = 500,
		numberRowsOnScroll = 20,
		theme = "default",
		growing = TableGrowingMode.None,
		onLoadMore,
		onRowClick,
		moreText = 'More'
	} = props;

	const [showFilter, setShowFilter] = useState<boolean>(false);
	const [tableItems, setTableItems] = useState<any[]>([]);
	const [sortOptions, setSortOptions] = useState<IColumnSortOptions | undefined>(sortStore.get);
	const [filteredItems, setFilteredItems] = useState<any[] | null>(null);

	const matchCode = (header: any, item: any ) => {
		const { matchCodeOrder } = header;
		const { matchCode } = item;

		// Match code is in the following format: MIT_ID|SSN_EXACT|SSN_NEAR|LAST_NAME|FIRST_NAME|REVERSED_NAME|MIDDLE_NAME|DOB
		// Match code is in the following format:   0-1 |   1-2   |  2-3   |   3-4   |    4-5   |      5-6    |    6-7    |7-8
		// Values are: X = Not available.
		//             E = Exact match.
		//             C = Close match.
		//             1 = Only 1st character matches.
		//             Y = Yes, match.
		//             N = No match.
		//             M = (DOB only) MMDD match, if full date provided and it matches the full date in the DB, Y will be returned.
		//             B = Bad (SSN)
		//             T = Transposed
		//             I = Only initial provided and stored and matched
		//             P = Alternative name (matched without a suffix like Sr, Jr, I, II, etc.)
		//
		// MIT_ID can be Y/N/X
		// SSN_EXACT can be Y,N,B,X
		// SSN_NEAR can be 7,T,8,9,X
		// LAST_NAME can be E,N,C,P
		// FIRST_NAME can be E,I,C,1,N,X
		// REVERSED_NAME can be E (last/first), e (possible last/first), M (middle/last), m (middle/possible last), N
		// MIDDLE_NAME can be E,I,1,N,X
		// DOB can be Y,N,M,X

		switch (matchCodeOrder){
			default:
				return 'none'
			case 'MIT_ID': {
				const matchSymbol = matchCode.substring(0,1)
				if (['Y'].indexOf(matchSymbol) !== -1)
					return 'exact'
				return 'none'
			}
			case 'FIRST_NAME': {
				const matchSymbol = matchCode.substring(4,5)
				if (['E'].indexOf(matchSymbol) !== -1)
					return 'exact'
				else if (['C','I',].indexOf(matchSymbol) !== -1)
					return 'possible'

				const revMatchSymbol = matchCode.substring(5,6)
				if (['E','e','M','m'].indexOf(revMatchSymbol) !== -1)
					return 'possible'
				return 'none'
			}
			case 'MIDDLE_NAME': {
				const matchSymbol = matchCode.substring(6,7)
				if (['E'].indexOf(matchSymbol) !== -1)
					return 'exact'
				else if (['I','1',].indexOf(matchSymbol) !== -1)
					return 'possible'
				return 'none'
			}
			case 'LAST_NAME': {
				const matchSymbol = matchCode.substring(3,4)
				if (['E'].indexOf(matchSymbol) !== -1)
					return 'exact'
				else if (['C','P'].indexOf(matchSymbol) !== -1)
					return 'possible'
				const revMatchSymbol = matchCode.substring(5,6)
				if (['E','e','M','m'].indexOf(revMatchSymbol) !== -1)
					return 'possible'
				return 'none'
			}
			case 'DOB': {
				const matchSymbol = matchCode.substring(7,8)
				if (['Y'].indexOf(matchSymbol) !== -1)
					return 'exact'
				else if (['M'].indexOf(matchSymbol) !== -1)
					return 'possible'
				return 'none'
			}
		}
	}

	const getCellValue = (header: IAdvancedTableColumn, item: any) => {
		let value;

		if (header.formatter) {
			value = header.formatter(item);
		} else {
			value = getNestedObjectValue(item, header.displayId ? header.displayId : header.id);
		}
		// console.log('getCellValue ', value)
		return value;
	};

	const getHeaderValue = (header: IAdvancedTableColumn) => {
		let value;

		if (header.headerFormatter) {
			value = header.headerFormatter(header.name);
		} else {
			value = header.name;
		}
		return value;
	};

	const getCellClass = (header: IAdvancedTableColumn, item: any, index: number) => {
		let className = index === 0 ? "first-in-row " : "";
		if (index === columns.length) {
			className += "last-in-row ";
		}

		if (header.className) {
			className += header.className(getNestedObjectValue(item, header.id));
		}
		return className;
	};

	const loadMoreRows = () => {
		let newRows = items.slice(tableItems.length, tableItems.length + numberRowsOnScroll);

		setTableItems((prev: any) => [...prev, ...newRows]);
	};

	const getActionHeaderColumn = () => {
		if (actionButtons) return <AdvancedTableColumn className="action-column" />;
	};

	const sortData = (headerId: string) => {
		let newSortOptions: IColumnSortOptions = {
			sortedBy: headerId,
			sortDirection: "asc",
			onSort: sortOptions?.onSort,
		};

		if (headerId === sortOptions?.sortedBy) {
			if (sortOptions.sortDirection === "asc") newSortOptions.sortDirection = "desc";
			else newSortOptions.sortDirection = "asc"; 
		} else {
			newSortOptions.sortDirection = "asc";
		}

		setSortOptions(newSortOptions);
		sortStore.save(newSortOptions);

		if (newSortOptions?.onSort) {
			let newData = newSortOptions.onSort(newSortOptions);
			setTableItems(newData);
			return newData
		} else {
			let newData = tableItems;
			if (newSortOptions.sortDirection === "asc") {
				newData = tableItems.sort((itemA, itemB) => {
					if (headerId.includes("/")) {
						var valueA = headerId.split("/").reduce(function (p, prop) {
							return p[prop];
						}, itemA);
						var valueB = headerId.split("/").reduce(function (p, prop) {
							return p[prop];
						}, itemB);
						return valueA.toLowerCase() < valueB.toLowerCase() ? 1 : -1;
					} else {
						return itemA[headerId].toLowerCase() < itemB[headerId].toLowerCase() ? 1 : -1;
					}
				});
			} else {
				newData = tableItems.sort((itemA, itemB) => {
					if (headerId.includes("/")) {
						var valueA = headerId.split("/").reduce(function (p, prop) {
							return p[prop];
						}, itemA);
						var valueB = headerId.split("/").reduce(function (p, prop) {
							return p[prop];
						}, itemB);
						return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
					} else {
						return itemA[headerId].toLowerCase() > itemB[headerId].toLowerCase() ? 1 : -1;
					}
				});
			}
			setTableItems(newData);
			return newData;
		}
	};
	
	useEffect(() => {
		let tableItems = filteredItems ? filteredItems : items;

		if (maxRows) {
			tableItems = tableItems.slice(0, maxRows);
		}

		setTableItems(tableItems);
	}, [items, filteredItems, maxRows]);

	useEffect(() => {
		if (sortOptions === undefined) {
			setSortOptions( props.sort );
		}
	}, [props.sort, sortOptions]);

	if (isLoading) {
		return (
			<div aria-busy={props.isLoading ?? false}>
				<div className={`rtable loading rtable--4cols rtable--collapse}`}>
					<div style={{ order: 0 }} className="rtable-cell skeleton">
						<div></div>
					</div>
				</div>
				<div className={`rtable loading rtable--4cols rtable--collapse}`}>
					<div style={{ order: 0 }} className="rtable-cell skeleton">
						<div></div>
					</div>
					<div style={{ order: 0 }} className="rtable-cell skeleton">
						<div></div>
					</div>
					<div style={{ order: 0 }} className="rtable-cell skeleton">
						<div></div>
					</div>
					<div style={{ order: 0 }} className="rtable-cell skeleton">
						<div></div>
					</div>

					<div style={{ order: 0 }} className="rtable-cell skeleton">
						<div></div>
					</div>
					<div style={{ order: 0 }} className="rtable-cell skeleton">
						<div></div>
					</div>
					<div style={{ order: 0 }} className="rtable-cell skeleton">
						<div></div>
					</div>
					<div style={{ order: 0 }} className="rtable-cell skeleton">
						<div></div>
					</div>

					<div style={{ order: 0 }} className="rtable-cell skeleton">
						<div></div>
					</div>
					<div style={{ order: 0 }} className="rtable-cell skeleton">
						<div></div>
					</div>
					<div style={{ order: 0 }} className="rtable-cell skeleton">
						<div></div>
					</div>
					<div style={{ order: 0 }} className="rtable-cell skeleton">
						<div></div>
					</div>

					<div style={{ order: 0 }} className="rtable-cell skeleton">
						<div></div>
					</div>
					<div style={{ order: 0 }} className="rtable-cell skeleton">
						<div></div>
					</div>
					<div style={{ order: 0 }} className="rtable-cell skeleton">
						<div></div>
					</div>
					<div style={{ order: 0 }} className="rtable-cell skeleton">
						<div></div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<Fragment>
			<div aria-busy={props.isLoading ?? false} id="scrollableDiv" className="advanced-table-scroll-container">
				{showFilterButton && (
					<AdvancedTableFilter
						show={showFilter}
						onClose={() => setShowFilter(false)}
						onFilter={(data: any, onclear) => {
							if(onclear) return setFilteredItems(null);
							setFilteredItems(data ? [...data] : null);
						}}
						columns={columns}
						items={items}
						theme={theme}
					/>
				)}

				<div className="d-flex justify-content-between export-right table-controls">
					{showFilterButton ? (
						<Tooltip text="Filter">
							<Button
								type={ButtonType.Small}
								text="Filter"
								icon="filter"
								onClick={() => {
									onClickFilterButton && onClickFilterButton();
									setShowFilter(true);
								}}
							/>
						</Tooltip>
					) : (
						<span />
					)}
					{showExportButton && (
						<AdvancedTableExportCsv
							button={{
								type: exportOptions?.button.type || ButtonType.Small,
								icon: exportOptions?.button.icon || "download",
								onClick: () => alert("Download Csv"),
								text: exportOptions?.button.text || "Export",
								state: ButtonState.Enabled,
							}}
							columns={exportOptions?.columns || []}
							fileName={exportOptions?.fileName || "Exported"}
							items={filteredItems ? filteredItems : items}
						/>
					)}
				</div>
				<InfiniteScroll
					dataLength={tableItems.length}
					next={loadMoreRows}
					hasMore={maxRows ? (filteredItems && filteredItems.length ? filteredItems.length !== tableItems.length : items.length !== tableItems.length) : false}
					loader={<ActionList isLoading={isLoading} items={[]} selectable={false} />}
					scrollableTarget="scrollableDiv">
					<Table
						className="advanced-table"
						stickyColumnHeader={stickyColumnHeader}
						showNoData={showNoData}
						noDataText={noDataText}
						growing={growing}
						onLoadMore={(e:any) => onLoadMore && onLoadMore(e) }
						onRowClick={(e:any) => onRowClick && onRowClick(e)}
						moreText={moreText}
						columns={
							<>
								{columns.map((header: IAdvancedTableColumn) => (
									<AdvancedTableColumn {...header.options} key={header.id}>
										<Fragment>
											{getHeaderValue(header)}
											{header.sortable && (
												<span className={"sort-icon"}>
													<Button
														icon={getSortIcon(sortOptions?.sortedBy || "", header.id, sortOptions?.sortDirection || "asc")}
														type={ButtonType.IconNaked}
														onClick={() => {														
															let newData = sortData(header.id);
															header.onSort && header.onSort(newData);
														}}
													/>
												</span>
											)}
										</Fragment>
									</AdvancedTableColumn>
								))}
								{getActionHeaderColumn()}
							</>
						}>
						{tableItems.map((item: any, index: number) => (
							<AdvancedTableRow key={index}>
								{columns.map((header: IAdvancedTableColumn, columnIndex: number) => {
									let properties: any = {};
									if (columnIndex === 0) {
										properties["first-in-row"] = true;
									}
									if (columnIndex === columns.length) {
										properties["last-in-row"] = true;
									}
									return (
										<AdvancedTableCell key={header.id} className={`${matchCode(header, item)} ${getCellClass(header, item, columnIndex)}`} properties={properties}>
											<span>{getCellValue(header, item)}</span>
										</AdvancedTableCell>
									);
								})}
								{actionButtons && (
									<AdvancedTableCell>
										<div className={"action-buttons-container"}>
											{Array.isArray(actionButtons)
												? actionButtons.map((button: ButtonProps, index: number) => {
														if (button.shouldDisplay === undefined || button.shouldDisplay(item))
															return <Button {...button} onClick={() => button.onClick(item)} key={index} />;
												  })
												: actionButtons(item)}
										</div>
									</AdvancedTableCell>
								)}
							</AdvancedTableRow>
						))}
					</Table>
				</InfiniteScroll>
			</div>
		</Fragment>
	);
};

export default AdvancedTable;