import * as React from 'react'
import {FunctionComponent, useEffect, useState} from "react";
import {PeopleStoreInterface} from "../../model/PeopleStore";
import medicalApi, {FetchHoldsResponse, PostStatusResponse} from "../../api/MedicalApi";
import { inject, observer } from "mobx-react";
import * as Loader from 'react-loader'
import {mediumLoaderOptions} from "../../common/Defaults";
import {HoldsModelInterface} from "../../model/Holds";
import {CovidNavInterface} from "../../model/CovidNav";
import { Drawer, DrawerSize, DrawerType, TextType, Text, ButtonType, ButtonState, DropdownItemProps, Dropdown, CalendarInput, TimePicker } from '@mit/hui';
import { toJS } from 'mobx';
import { RolesInterface } from '../../model/Roles';
import { toast } from 'react-toastify';
import * as moment from 'moment';

export interface ChangeHoldStatus {
    kerberosId: string;
    mitId: string;
    displayName: string;
    status?: string;
    hold_guid?: string;
    mit_id?: string;
}

interface ChangeHoldStatusProps {
    person?: any;
    peopleStore?: PeopleStoreInterface;
    holds?: HoldsModelInterface;
    covidNav?: CovidNavInterface;
    show?: boolean;
    onClose: any;
    roles?: RolesInterface;
}

const ChangeHoldStatusComponent: FunctionComponent<ChangeHoldStatusProps> = ({person, show, onClose, peopleStore, holds, covidNav, roles}) => {

    const isClearedStatus = (): boolean => {
        if(holds && holds.newStatus && holds.newStatus!.text === 'Cleared') {
            return true
        } else {
            return false
        }
    }

    const [isLoading, setIsLoading] = useState(false)
    const [isDisabled, setIsDisabled] = useState<boolean>(false)
    const [holdTypeSelected, setHoldTypeSelected] = useState<string>("New")
    const [agreedToCleared, setAgreedToCleared] = useState<boolean>(false)
    const [clearDate, setClearDate] = useState<string | undefined>()
    const [clearTime, setClearTime] = useState<string | undefined>()
    const [exemptFromTesting, setExemptFromTesting] = useState<boolean>(false)

    const getNewStatus = (value: any) => {
        if (holds && holds.statuses && value) {
            let newStatus = holds.statuses.find((x) => { return x.text === (value && value.text ? value.text : value)});
            return newStatus;
        }
    }

    const setNewStatus = (value: any) => {
        holds!.newStatus = getNewStatus(value);
    }

    const setDateTime = () => {
        let now = (person && person!.status === 'Scheduled To Clear') ? new Date(person.scheduled_time) : new Date();
        
        var dateNow = now.getFullYear()+'-'+(now.getMonth()+1)+'-'+now.getDate();
        let timeNow = now.getHours() + ":" + now.getMinutes();
        setClearDate(dateNow)
        setClearTime(timeNow)
    }

    //component is already created, so you need to check whenever holds.newStatus!.text changes to see if isDisabled is true
    useEffect(() => {
        setIsDisabled(isClearedStatus() && !agreedToCleared)
        setHoldTypeSelected(holds && holds.newStatus ? holds.newStatus.text : person?.status)
    }, [holds!.newStatus, agreedToCleared])

    useEffect(()=>{
        if(show){
            setIsDisabled(isClearedStatus() && !agreedToCleared)
            setDateTime();
            setHoldTypeSelected(holds && holds.newStatus ? holds.newStatus.text : person?.status)
            setNewStatus(holds && holds.newStatus ? holds.newStatus.text : person?.status)
        }   
    },[show])

    const handleClose = () => {
        holds!.showUpdateStatusModal = false
        holds!.newStatus = undefined

        setIsLoading(false)
        setIsDisabled(false)
        setAgreedToCleared(false)
        setExemptFromTesting(false);
        setClearDate(undefined)
        setClearTime(undefined)

        onClose();
    }

    const submitAndClose = () => {
        setIsLoading(true)
        let d = new Date(`${clearDate} ${clearTime}`);
        let date = moment(d).toISOString(true);
        
        holds!.showUpdateStatusModal = false;
        medicalApi.patchStatus(person!.hold_guid!, holds!.newStatus!.id, exemptFromTesting, date, true).then((response: PostStatusResponse) => {
            setIsLoading(false)
            if (person) {
                medicalApi.fetchPersonMedicalHolds(person!.mit_id!).then((resp) => {
                    holds!.personHolds = resp.items ? resp.items : []
                })
            }

            if (peopleStore) {
                medicalApi.fetchAllHolds().then((response: FetchHoldsResponse) => {
                    if (response.items) {
                        peopleStore.setFollowUpList(response.items)
                    }
                })
            }

            if (response.error) {
                toast.error('Could not update status', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }

            handleClose()
        })
    }

    const replaceAll = (base: string, search: string, replaceWith: string) => {
        return base.split(search).join(replaceWith)
    }

    const getName = (name: string) => {

        if (!name)
            return "";

        const nameArr = name.split(',')
        return nameArr.length === 2 ? `${nameArr[1]} ${nameArr[0]}` : nameArr.length === 1 ? nameArr[0] : name
    }

    const renderClearedConfirmationText = (confText: string, name: string) => {
        return replaceAll(confText, '[name]', `${getName(name)}`)
    }

    const renderExemptText = (confText: string) => {
        return (confText) ? confText : "Exempt from testing for 90 days";
    }

    let mainActionButtonEnabled = (
        (holds && holds.newStatus && holds.newStatus!.text === 'Cleared') || (holds && holds.newStatus && holds.newStatus!.text === 'Scheduled To Clear')) 
        ? agreedToCleared : true

    const defaultButtonData: any[] = [
        { icon: '', text: 'Submit', type: ButtonType.Primary, onClick: () => { submitAndClose() }, state: roles?.statusDisabled() ? ButtonState.Disabled : mainActionButtonEnabled ? ButtonState.Enabled : ButtonState.Disabled },
        { icon: '', text: 'Cancel', type: ButtonType.Primary | ButtonType.Ghost, onClick: () => { handleClose() }, state: ButtonState.Enabled },
    ];

    const getDropDownItems = () => {
        let dropdownData: DropdownItemProps[] = [];

        if (holds && holds.statuses) {
            // console.log("holds.statuses", holds.statuses)
            holds.statuses.map((status: any) => {
                // console.log("holds.status", status)
                dropdownData.push({text: status.text, icon: "", id: status.id});
            })
            // console.log("holds.statuses dropdownData", dropdownData)
        }

        return dropdownData;
    }

    const dislay_name = person && person.display_name ? `${person.display_name.split(', ')[1]} ${person.display_name.split(', ')[0]}` : '';    
    const cleared_text = covidNav!.texts['medical.clear.attestation'].replaceAll('[name]', dislay_name)

    return ( <Drawer
            contentAriaLabel={`Change Hold Status`} 
            header={
                <>
                    <Text content="Change Hold Status" type={TextType.Heading3} />
                </>
            }
            type={DrawerType.Right}
            themedColor={"medical"}
            footer={defaultButtonData}
            contents={
                <>
                     <Loader loaded={!isLoading} options={{...mediumLoaderOptions, color: '#000000'}}>
                        <p>You will be changing the hold status to</p> 
                        <Dropdown 
                            id="change_hold_status" 
                            disabled={roles?.statusDisabled()} 
                            text="" dropdownItems={getDropDownItems()} 
                            name="dropdownStatus" 
                            value={person?.status} 
                            onChange={(value: any) => setNewStatus(value)}
                        />
                    
                        {(holdTypeSelected === 'Cleared' || holdTypeSelected === 'Scheduled To Clear') &&
                            <div>
                                {holdTypeSelected === 'Scheduled To Clear' && 
                                    <div className='flex mt-3'>
                                        <div className={'row'}>
                                            <div className={'col-6'} >
                                                <CalendarInput
                                                    clearable={false}
                                                    id="schedule clear"
                                                    onChange={(val:any) => {console.log(val); setClearDate(val)}}
                                                    placeholder="Please select a date"
                                                    value={clearDate}
                                                />
                                            </div>
                                            <div className={'col-6'} >
                                                <TimePicker value={clearTime} onChange={(val: any) => {console.log(val); setClearTime(val)}}/>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className='flex ml-4 mt-3'>
                                    <input id='cleared-checkbox' className={'form-check-input'} type={'checkbox'} checked={agreedToCleared} onChange={() => {
                                        setAgreedToCleared(!agreedToCleared)
                                        }
                                    }/>
                                    <label htmlFor={'cleared-checkbox'} className="form-check-label">{renderClearedConfirmationText(cleared_text, holds!.personName!)}</label>
                                </div>
                                {person?.reason === 'Positive Medical Test' ? 
                                <div className='flex ml-4 mt-3'>
                                    <input id='exempt-checkbox' className={'form-check-input'} type={'checkbox'} checked={exemptFromTesting} onChange={() => {
                                        setExemptFromTesting(!exemptFromTesting)
                                    }
                                    }/>
                                    <label htmlFor={'exempt-checkbox'} className="form-check-label">{renderExemptText(covidNav!.texts['medical.clear.exempt'])}</label>

                                </div> : 
                                null}
                            </div>
                        }
                    </Loader>
                </>
            }
            show={show || false}
            onClose={handleClose}
            size={DrawerSize.Small}
        />
    )
}

const ChangeHoldStatus = inject('peopleStore', 'holds', 'roles', 'covidNav')(observer(ChangeHoldStatusComponent))
export default ChangeHoldStatus
