import * as React from 'react';
import { TableLvl2ContextProvider } from '../Table/TableLvl2ContextProvider';
import { TableLvl2Props } from '../Table/types';
import { defaults, TableHeadProps } from './types';

const TableHead: React.FC<React.PropsWithChildren<TableHeadProps>> = (props: React.PropsWithChildren<TableHeadProps>) => {
  const finalProps = { ...defaults, ...props };
  const { component, children, classes } = finalProps;
  const CustomTag = `${component}` as keyof JSX.IntrinsicElements;

  const propsWithVariant: TableLvl2Props = { variant: 'head' };
  let finalClasses = 'HuiTableHead';
  if (classes) finalClasses += ` ${classes}`;
  // const mainProps = React.useContext(TableHeadContext);
  return (
    <CustomTag style={props.style} className={finalClasses}>
      <TableLvl2ContextProvider {...propsWithVariant}>
        {children}
      </TableLvl2ContextProvider>
    </CustomTag>
  );
};

export default TableHead;
