import * as React from "react";
import { FC, useState, useEffect } from "react";
import { peopleStore } from "../../model/PeopleStore";
import medicalApi, { FetchFollowUpsResponse } from "../../api/MedicalApi";
import { inject, observer, Provider } from "mobx-react";
import { CovidNav } from "../../model/CovidNav";
import { PeopleListCompleted } from "./PeopleListCompleted";
import { Button, ButtonType, CalendarInput, Error, ErrorContext, ErrorType } from "@mit/hui";
import UploadModalTests from "./UploadModalTests";
import DownloadBetweenDates from "../manifest/DownloadBetweenDates";
import { RolesInterface } from "../../model/Roles";

interface CompletedTestsComponentProps {
	covidNav?: CovidNav;
	roles?: RolesInterface;
}

const CompletedTestsComponent: FC<CompletedTestsComponentProps> = ({ covidNav, roles }) => {
	const [isLoading, setLoading] = useState(false);
	const [unAuth, setUnAuth] = useState(false);
	const [show, setShow] = useState(false);
	const [showDownload, setShowDownload] = useState(false);

	const reloadDataCompleted = async (date?: string) => {
		covidNav!.currentDateString = date!;
		setLoading(true);
		const resultsResponse: FetchFollowUpsResponse = await medicalApi.fetchPersonResults(date!);
		if (resultsResponse.error) {
			setLoading(false);
			//todo: handle error
		} else if (resultsResponse.unauth) {
			setUnAuth(true);
		} else {
			if (resultsResponse.items) {
				setLoading(false);
				peopleStore.setCompletedList(resultsResponse.items);
			}
		}
	};

	useEffect(() => {
		if (peopleStore?.completedList === undefined) {
			let searchDate = new Date();
			let dateString = `${searchDate.getFullYear()}-${searchDate.getMonth() + 1 < 10 ? "0" + (searchDate.getMonth() + 1) : searchDate.getMonth() + 1}-${
				searchDate.getDate() < 10 ? "0" + searchDate.getDate() : searchDate.getDate()
			}`;
			setLoading(true);
			covidNav!.currentDateString = dateString;
			medicalApi.fetchPersonResults(dateString).then((resultsResponse: FetchFollowUpsResponse) => {
				if (resultsResponse.error) {
					setLoading(false);

					//todo: handle error
				} else if (resultsResponse.unauth) {
					setUnAuth(true);
				} else {
					if (resultsResponse.items) {
						setLoading(false);
						peopleStore.setCompletedList(resultsResponse.items);
					}
				}
			});
		}
	});

	const getInitialCalendarValue = () => {
		let searchDate = new Date();
		let dateString = `${searchDate.getFullYear()}-${searchDate.getMonth() + 1 < 10 ? "0" + (searchDate.getMonth() + 1) : searchDate.getMonth() + 1}-${
			searchDate.getDate() < 10 ? "0" + searchDate.getDate() : searchDate.getDate()
		}`;
		return dateString;
	};

	const handleShowUpload = () => {
		setShow(true);
	};

	const handleCloseUpload = () => {
		setShow(false);
	};

	const handleShowDownload = () => {
		setShowDownload(true);
	};

	const handleCloseDownload = () => {
		setShowDownload(false);
	};

	if (unAuth) return <Error context={ErrorContext.Component} message="Not Authorized" type={ErrorType.AccessDenied} />;

	return (
		<React.Fragment>
			<div className="calendar-picker">
				<div className="row">
					<div className="col-xl-4"></div>
					<div className="col-xl-4">
						<CalendarInput onChange={(e: string) => reloadDataCompleted(e)} value={getInitialCalendarValue()} id="completedTestsDateSelector" />
					</div>

					<div className="col-xl-4">
						<div className="controls">
							{
								roles?.roles.includes("medical") && <Button type={ButtonType.Primary} text="Import Results" onClick={() => handleShowUpload()} />
							}							
							<Button type={ButtonType.Primary} text="Download Results" onClick={() => handleShowDownload()} />
						</div>
					</div>
				</div>
			</div>
			<Provider peopleStore={peopleStore}>
				<PeopleListCompleted isLoading={isLoading} />
			</Provider>
			<UploadModalTests show={show} onClose={handleCloseUpload} />
			<DownloadBetweenDates show={showDownload} onClose={handleCloseDownload} />
		</React.Fragment>
	);
};

const CompletedTests = inject("covidNav", "roles")(observer(CompletedTestsComponent));
export default CompletedTests;
