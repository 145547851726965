import * as React from "react";
import { TextArea } from "../TextArea";
import Checkbox from "../Checkbox";
import DatePicker from "../DateTimePicker";
import { MaskedType, Textbox, TextboxProps } from "../Textbox";
import { RadioButton } from "../RadioButton";
import { RadioGroup, RadioGroupOption } from "./RadioGroup";
import { IValidationPattern } from "./FormValidation";
import { Toggle } from "../..";
import { ListItemProps } from "../ListItem";
import { DropdownItemProps } from "../Dropdown";
import { ToggleChoice } from "../Toggle";
import { AutoComplete } from "../AutoComplete";
import Dropdown from "../Dropdown/Dropdown";
import { CalendarInput, Theme } from "../Calendar/CalendarInput";
import { Controller } from "react-hook-form";
import {TimePicker} from "../TimePicker";
import { CheckboxInputGroup, InputBoxOption } from "./CheckboxInputGroup";
import { LocationSearch } from "../Search";
/* The available editors for the field */
type Editor = "textbox" | "multilinetextbox" | "dropdown" | "datepicker" | "timepicker" | "checkbox" | "radio" | "radioGroup" | "autocomplete" | "toggle" | "calendar" | "checkboxinputgroup" | "locationsearch";
type LabelDisplay = "ontop" | "nextto";

const LabelExceptions: Array<string> = ["radio", "checkbox"];

export interface IFieldProps {
	id: string;
	label?: string;
	editor: Editor;
	maskType?: MaskedType;
	inputMask?: any;
	options?: Array<DropdownItemProps>;
	checkboxGroupOptions?: InputBoxOption[];
	properties?: { [key: string]: any };
	placeholder?: string;
	value?: any;
	labelDisplay?: LabelDisplay;
	readonly?: boolean;
	required?: boolean;
	isBusy?: boolean;
	isLoading?: boolean;
	group?: string;
	textAreaRows?: number;
	pattern?: IValidationPattern;
	validation?: any;
	groupOptions?: Array<RadioGroupOption>;
	onChange?: any;
	onClick?: any;
	onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
	choice?: ToggleChoice;
	items?: ListItemProps[];
	searchOptions?: TextboxProps;
	width?: number;
	register?: any; // https://react-hook-form.com/api#register
	errors?: any; // https://react-hook-form.com/api#errors
	setValue?: any; // https://react-hook-form.com/api#setValue,
	control?: any;
	registerObject?: IFormHookRef;
	isRangeSelected?: boolean;
	isMultiSelect?: boolean;
	shouldHighlightWeekends?: boolean;
	format?: string;
	maxDate?: any;
	minDate?: any;
	className?: string;
	mainClassName?: string;
	theme?: Theme;
	disabledDays?: any;
	yearRequired?: boolean;
	ariaLabel?: string;
	accessToken?: string;
	types?: String;
	disabled?: boolean;
}

export interface IFormHookRef {
	validation?: any;
	required?: any;
	pattern?: IValidationPattern;
	maxLength?: number;
	minLength?: number;
	min?: number;
	max?: number;
	numericOnly?: boolean;
}

const getClasses = (labelDisplay?: LabelDisplay) => {
	var classes: string[] = [];

	if (labelDisplay === "ontop") classes.push("cluster");

	if (labelDisplay === "nextto") classes.push("row");

	return classes.join(" ");
};

const getLabelClasses = (labelDisplay?: LabelDisplay) => {
	var classes: string[] = [];

	if (labelDisplay === "nextto") classes.push("col-sm-4 text-right");

	return classes.join(" ");
};

const getInputClasses = (labelDisplay?: LabelDisplay) => {
	var classes: string[] = [];

	if (labelDisplay === "nextto") classes.push("col-sm-8");

	return classes.join(" ");
};

export const FormField: React.FC<IFieldProps> = ({
	id,
	label,
	editor,
	maskType,
	inputMask,
	labelDisplay,
	options,
	placeholder,
	value,
	readonly,
	required,
	isBusy,
	isLoading,
	group,
	textAreaRows,
	validation,
	pattern,
	groupOptions,
	register,
	errors,
	setValue,
	control,
	properties,
	onChange,
	onBlur,
	onClick,
	searchOptions,
	items,
	choice,
	registerObject,
	isRangeSelected,
	isMultiSelect,
	shouldHighlightWeekends,
	format,
	maxDate,
	minDate,
	className,
	mainClassName,
	theme,
	disabledDays,
	accessToken,
	width,
	yearRequired,
	checkboxGroupOptions,
	ariaLabel,
	types,
	disabled
}) => {
	return (
		<div className={`form-group ${getClasses(labelDisplay)} ${mainClassName}`}>
			{label && LabelExceptions.indexOf(editor.toLowerCase()) === -1 && (
				<label className={`${getLabelClasses(labelDisplay)} col-form-label`} htmlFor={id}>
					{label}
				</label>
			)}
			{label && editor.toLowerCase() === "checkbox" && <div className={`${getLabelClasses(labelDisplay)} col-form-label`}></div>}
			<div className={`${getInputClasses(labelDisplay)}`}>
				{editor.toLowerCase() === "textbox" && readonly !== true && (
					<Controller
						name={id}
						control={control}
						render={(renderProps) => (
							<Textbox
								onBlur={(e: any) => onBlur && onBlur(e)}
								masked={maskType}
								inputMask={inputMask}
								disabled={disabled}
								onChange={(e) => {
									renderProps.onChange(e);
									onChange && onChange(e);
								}}
								pattern={pattern || registerObject?.pattern}
								register={register}
								setValue={setValue}
								errors={errors}
								validation={validation || registerObject?.validation}
								name={id}
								required={required || registerObject?.required}
								maxLength={registerObject?.maxLength}
								minLength={registerObject?.minLength}
								min={registerObject?.min}
								max={registerObject?.max}
								properties={{ readOnly: readonly ? "readonly" : "" }}
								placeholderText={placeholder || ""}
								value={value}
								width={width}
								isBusy={isBusy}
								isLoading={isLoading}
								ariaLabel={ariaLabel}
								{...properties}
							/>
						)}
						defaultValue={value}
						rules={{
							required: required || registerObject?.required,
							pattern: pattern || registerObject?.pattern,
							maxLength: registerObject?.maxLength,
							max: registerObject?.max,
							min: registerObject?.min,
							minLength: registerObject?.minLength,
						}}
						error={errors}
					/>
				)}

				{editor.toLowerCase() === "textbox" && readonly === true && (
					<label className="col-sm-8 col-form-label" htmlFor={id}>
						{value}
					</label>
				)}

				{editor.toLowerCase() === "locationsearch" && (
					<>
					<LocationSearch
						name={id}
						onChange={(e) => onChange && onChange(e)}
						accessToken={accessToken ?? "pk.eyJ1Ijoic3BldHNpbmciLCJhIjoiY2tpMXlwOHdpMHEzdjJybXN6YmNzZ205eCJ9.ndS2CNk-eJa4C7kQrK-40g"}
						required={required}
						types={types}
						placeholderText={placeholder}
						register={register}
						errors={errors}
						setValue={setValue}
						key={id}
						{...properties}
					/>
					</>
				)}

				{editor.toLowerCase() === "multilinetextbox" && (
					<TextArea
						onChange={(e: any) => onChange && onChange(e)}
						register={register}
						setValue={setValue}
						errors={errors}
						key={id}
						required={required}
						name={id}
						disabled={disabled}
						rows={textAreaRows || 3}
						placeholderText={placeholder || ""}
						value={value}
						isLoading={isLoading}
						{...properties}
					/>
				)}

				{editor!.toLowerCase() === "dropdown" && (
					<Dropdown
						value={value}
						readOnly={readonly}
						name={id}
						id={`${id ? id : "form"}-dropdownMenuLink`}
						text="Select Item"
						dropdownItems={options || []}
						disabled={disabled}
						onChange={onChange}
						register={register}
						required={required}
						setValue={setValue}
						errors={errors}
						{...properties}
					/>
				)}

				{editor!.toLowerCase() === "datepicker" && <DatePicker {...properties} onChange={(e: any) => onChange && onChange(e)} key={id} name={id} />}

				{editor!.toLowerCase() === "timepicker" && <TimePicker value={value} {...properties} onChange={(e: any) => onChange && onChange(e)} key={id} name={id} />}

				{editor!.toLowerCase() === "calendar" && (
					<Controller
						name={id}
						control={control}
						render={(renderProps) => (
							<CalendarInput
								{...properties}
								onChange={(e: any) => {
									renderProps.onChange(e);
									onChange && onChange(e);
								}}
								isRangeSelected={isRangeSelected}
								isMultiSelect={isMultiSelect}
								shouldHighlightWeekends={shouldHighlightWeekends}
								format={format}
								maxDate={maxDate}
								minDate={minDate}
								readonly={readonly}
								yearRequired={yearRequired}
								value={value}
								required={required}
								className={className}
								theme={theme}
								disabledDays={disabledDays}
								key={id}
								id={id}
								register={register}
								setValue={setValue}
								errors={errors}
							/>
						)}
						defaultValue={value}
						rules={{ required: required }}
						error={errors}
					/>
				)}

				{editor!.toLowerCase() === "checkbox" && <Checkbox {...properties} label={label || ""} onClick={(e: any) => onClick && onClick(e)} name={id} register={register} />}

				{editor!.toLowerCase() === "radio" && <RadioButton {...properties} key={id} label={label || ""} group={group || id} value={value || ""} name={id} />}
				{editor!.toLowerCase() === "radiogroup" && (
					<Controller
						as={<RadioGroup {...properties} key={id} label={label || ""} group={group || id} groupOptions={groupOptions || []} name={id} />}
						name={id}
						control={control}
						rules={
							//@ts-ignore
							{ type: "radioGroup", groupOptions: groupOptions || [] }
						}
					/>
				)}
				{editor!.toLowerCase() === "toggle" && (
					<Toggle altAriaLabel={ariaLabel} {...properties} onClick={(e: any) => onClick && onClick(e)} name={id} choice={choice ? choice : "NotSelected"} />
				)}
				{editor!.toLowerCase() === "autocomplete" && (
					<AutoComplete
						register={registerObject !== undefined ? register(registerObject) : register}
						setValue={setValue}
						errors={errors}
						required={required}
						disabled={disabled}
						isLoading={isLoading}
						isBusy={isBusy}
						value={value}
						onChange={(e: any) => onChange && onChange(e)}
						name={id}
						searchOptions={searchOptions ? searchOptions : { name: "" }}
						items={items ? items : []}
						{...properties}
					/>
				)}
				{editor!.toLocaleLowerCase() === "checkboxinputgroup" && (
					<Controller
						control={control}
						name={id}
						as={
							<CheckboxInputGroup
								group={group || id}
								name={group || id}
								inputMask={inputMask}
								groupOptions={checkboxGroupOptions || []}
								label={label || ""}
								placeholderText={placeholder}
								value={
									checkboxGroupOptions?.find((x) => {
										return x.selected;
									})?.value
								}
								onActionClick={onClick}
								properties={properties}
							/>
						}
						rules={{ required: required }}
						register={register}
						setValue={setValue}
						errors={errors}
					/>
				)}
			</div>
			{/* TODO - display validation error */}
		</div>
	);
};
FormField.defaultProps = {
	editor: "textbox",
};
