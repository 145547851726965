/* Copyright 2012 Mozilla Foundation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* no-babel-preset */
/* eslint-disable no-var */

import { getArrayLookupTableFactory } from "./core_utils.js";

var getGlyphsUnicode = getArrayLookupTableFactory(function () {
  // prettier-ignore
  return [
    "A", 0x0041,
    "AE", 0x00c6,
    "AEacute", 0x01fc,
    "AEmacron", 0x01e2,
    "AEsmall", 0xf7e6,
    "Aacute", 0x00c1,
    "Aacutesmall", 0xf7e1,
    "Abreve", 0x0102,
    "Abreveacute", 0x1eae,
    "Abrevecyrillic", 0x04d0,
    "Abrevedotbelow", 0x1eb6,
    "Abrevegrave", 0x1eb0,
    "Abrevehookabove", 0x1eb2,
    "Abrevetilde", 0x1eb4,
    "Acaron", 0x01cd,
    "Acircle", 0x24b6,
    "Acircumflex", 0x00c2,
    "Acircumflexacute", 0x1ea4,
    "Acircumflexdotbelow", 0x1eac,
    "Acircumflexgrave", 0x1ea6,
    "Acircumflexhookabove", 0x1ea8,
    "Acircumflexsmall", 0xf7e2,
    "Acircumflextilde", 0x1eaa,
    "Acute", 0xf6c9,
    "Acutesmall", 0xf7b4,
    "Acyrillic", 0x0410,
    "Adblgrave", 0x0200,
    "Adieresis", 0x00c4,
    "Adieresiscyrillic", 0x04d2,
    "Adieresismacron", 0x01de,
    "Adieresissmall", 0xf7e4,
    "Adotbelow", 0x1ea0,
    "Adotmacron", 0x01e0,
    "Agrave", 0x00c0,
    "Agravesmall", 0xf7e0,
    "Ahookabove", 0x1ea2,
    "Aiecyrillic", 0x04d4,
    "Ainvertedbreve", 0x0202,
    "Alpha", 0x0391,
    "Alphatonos", 0x0386,
    "Amacron", 0x0100,
    "Amonospace", 0xff21,
    "Aogonek", 0x0104,
    "Aring", 0x00c5,
    "Aringacute", 0x01fa,
    "Aringbelow", 0x1e00,
    "Aringsmall", 0xf7e5,
    "Asmall", 0xf761,
    "Atilde", 0x00c3,
    "Atildesmall", 0xf7e3,
    "Aybarmenian", 0x0531,
    "B", 0x0042,
    "Bcircle", 0x24b7,
    "Bdotaccent", 0x1e02,
    "Bdotbelow", 0x1e04,
    "Becyrillic", 0x0411,
    "Benarmenian", 0x0532,
    "Beta", 0x0392,
    "Bhook", 0x0181,
    "Blinebelow", 0x1e06,
    "Bmonospace", 0xff22,
    "Brevesmall", 0xf6f4,
    "Bsmall", 0xf762,
    "Btopbar", 0x0182,
    "C", 0x0043,
    "Caarmenian", 0x053e,
    "Cacute", 0x0106,
    "Caron", 0xf6ca,
    "Caronsmall", 0xf6f5,
    "Ccaron", 0x010c,
    "Ccedilla", 0x00c7,
    "Ccedillaacute", 0x1e08,
    "Ccedillasmall", 0xf7e7,
    "Ccircle", 0x24b8,
    "Ccircumflex", 0x0108,
    "Cdot", 0x010a,
    "Cdotaccent", 0x010a,
    "Cedillasmall", 0xf7b8,
    "Chaarmenian", 0x0549,
    "Cheabkhasiancyrillic", 0x04bc,
    "Checyrillic", 0x0427,
    "Chedescenderabkhasiancyrillic", 0x04be,
    "Chedescendercyrillic", 0x04b6,
    "Chedieresiscyrillic", 0x04f4,
    "Cheharmenian", 0x0543,
    "Chekhakassiancyrillic", 0x04cb,
    "Cheverticalstrokecyrillic", 0x04b8,
    "Chi", 0x03a7,
    "Chook", 0x0187,
    "Circumflexsmall", 0xf6f6,
    "Cmonospace", 0xff23,
    "Coarmenian", 0x0551,
    "Csmall", 0xf763,
    "D", 0x0044,
    "DZ", 0x01f1,
    "DZcaron", 0x01c4,
    "Daarmenian", 0x0534,
    "Dafrican", 0x0189,
    "Dcaron", 0x010e,
    "Dcedilla", 0x1e10,
    "Dcircle", 0x24b9,
    "Dcircumflexbelow", 0x1e12,
    "Dcroat", 0x0110,
    "Ddotaccent", 0x1e0a,
    "Ddotbelow", 0x1e0c,
    "Decyrillic", 0x0414,
    "Deicoptic", 0x03ee,
    "Delta", 0x2206,
    "Deltagreek", 0x0394,
    "Dhook", 0x018a,
    "Dieresis", 0xf6cb,
    "DieresisAcute", 0xf6cc,
    "DieresisGrave", 0xf6cd,
    "Dieresissmall", 0xf7a8,
    "Digammagreek", 0x03dc,
    "Djecyrillic", 0x0402,
    "Dlinebelow", 0x1e0e,
    "Dmonospace", 0xff24,
    "Dotaccentsmall", 0xf6f7,
    "Dslash", 0x0110,
    "Dsmall", 0xf764,
    "Dtopbar", 0x018b,
    "Dz", 0x01f2,
    "Dzcaron", 0x01c5,
    "Dzeabkhasiancyrillic", 0x04e0,
    "Dzecyrillic", 0x0405,
    "Dzhecyrillic", 0x040f,
    "E", 0x0045,
    "Eacute", 0x00c9,
    "Eacutesmall", 0xf7e9,
    "Ebreve", 0x0114,
    "Ecaron", 0x011a,
    "Ecedillabreve", 0x1e1c,
    "Echarmenian", 0x0535,
    "Ecircle", 0x24ba,
    "Ecircumflex", 0x00ca,
    "Ecircumflexacute", 0x1ebe,
    "Ecircumflexbelow", 0x1e18,
    "Ecircumflexdotbelow", 0x1ec6,
    "Ecircumflexgrave", 0x1ec0,
    "Ecircumflexhookabove", 0x1ec2,
    "Ecircumflexsmall", 0xf7ea,
    "Ecircumflextilde", 0x1ec4,
    "Ecyrillic", 0x0404,
    "Edblgrave", 0x0204,
    "Edieresis", 0x00cb,
    "Edieresissmall", 0xf7eb,
    "Edot", 0x0116,
    "Edotaccent", 0x0116,
    "Edotbelow", 0x1eb8,
    "Efcyrillic", 0x0424,
    "Egrave", 0x00c8,
    "Egravesmall", 0xf7e8,
    "Eharmenian", 0x0537,
    "Ehookabove", 0x1eba,
    "Eightroman", 0x2167,
    "Einvertedbreve", 0x0206,
    "Eiotifiedcyrillic", 0x0464,
    "Elcyrillic", 0x041b,
    "Elevenroman", 0x216a,
    "Emacron", 0x0112,
    "Emacronacute", 0x1e16,
    "Emacrongrave", 0x1e14,
    "Emcyrillic", 0x041c,
    "Emonospace", 0xff25,
    "Encyrillic", 0x041d,
    "Endescendercyrillic", 0x04a2,
    "Eng", 0x014a,
    "Enghecyrillic", 0x04a4,
    "Enhookcyrillic", 0x04c7,
    "Eogonek", 0x0118,
    "Eopen", 0x0190,
    "Epsilon", 0x0395,
    "Epsilontonos", 0x0388,
    "Ercyrillic", 0x0420,
    "Ereversed", 0x018e,
    "Ereversedcyrillic", 0x042d,
    "Escyrillic", 0x0421,
    "Esdescendercyrillic", 0x04aa,
    "Esh", 0x01a9,
    "Esmall", 0xf765,
    "Eta", 0x0397,
    "Etarmenian", 0x0538,
    "Etatonos", 0x0389,
    "Eth", 0x00d0,
    "Ethsmall", 0xf7f0,
    "Etilde", 0x1ebc,
    "Etildebelow", 0x1e1a,
    "Euro", 0x20ac,
    "Ezh", 0x01b7,
    "Ezhcaron", 0x01ee,
    "Ezhreversed", 0x01b8,
    "F", 0x0046,
    "Fcircle", 0x24bb,
    "Fdotaccent", 0x1e1e,
    "Feharmenian", 0x0556,
    "Feicoptic", 0x03e4,
    "Fhook", 0x0191,
    "Fitacyrillic", 0x0472,
    "Fiveroman", 0x2164,
    "Fmonospace", 0xff26,
    "Fourroman", 0x2163,
    "Fsmall", 0xf766,
    "G", 0x0047,
    "GBsquare", 0x3387,
    "Gacute", 0x01f4,
    "Gamma", 0x0393,
    "Gammaafrican", 0x0194,
    "Gangiacoptic", 0x03ea,
    "Gbreve", 0x011e,
    "Gcaron", 0x01e6,
    "Gcedilla", 0x0122,
    "Gcircle", 0x24bc,
    "Gcircumflex", 0x011c,
    "Gcommaaccent", 0x0122,
    "Gdot", 0x0120,
    "Gdotaccent", 0x0120,
    "Gecyrillic", 0x0413,
    "Ghadarmenian", 0x0542,
    "Ghemiddlehookcyrillic", 0x0494,
    "Ghestrokecyrillic", 0x0492,
    "Gheupturncyrillic", 0x0490,
    "Ghook", 0x0193,
    "Gimarmenian", 0x0533,
    "Gjecyrillic", 0x0403,
    "Gmacron", 0x1e20,
    "Gmonospace", 0xff27,
    "Grave", 0xf6ce,
    "Gravesmall", 0xf760,
    "Gsmall", 0xf767,
    "Gsmallhook", 0x029b,
    "Gstroke", 0x01e4,
    "H", 0x0048,
    "H18533", 0x25cf,
    "H18543", 0x25aa,
    "H18551", 0x25ab,
    "H22073", 0x25a1,
    "HPsquare", 0x33cb,
    "Haabkhasiancyrillic", 0x04a8,
    "Hadescendercyrillic", 0x04b2,
    "Hardsigncyrillic", 0x042a,
    "Hbar", 0x0126,
    "Hbrevebelow", 0x1e2a,
    "Hcedilla", 0x1e28,
    "Hcircle", 0x24bd,
    "Hcircumflex", 0x0124,
    "Hdieresis", 0x1e26,
    "Hdotaccent", 0x1e22,
    "Hdotbelow", 0x1e24,
    "Hmonospace", 0xff28,
    "Hoarmenian", 0x0540,
    "Horicoptic", 0x03e8,
    "Hsmall", 0xf768,
    "Hungarumlaut", 0xf6cf,
    "Hungarumlautsmall", 0xf6f8,
    "Hzsquare", 0x3390,
    "I", 0x0049,
    "IAcyrillic", 0x042f,
    "IJ", 0x0132,
    "IUcyrillic", 0x042e,
    "Iacute", 0x00cd,
    "Iacutesmall", 0xf7ed,
    "Ibreve", 0x012c,
    "Icaron", 0x01cf,
    "Icircle", 0x24be,
    "Icircumflex", 0x00ce,
    "Icircumflexsmall", 0xf7ee,
    "Icyrillic", 0x0406,
    "Idblgrave", 0x0208,
    "Idieresis", 0x00cf,
    "Idieresisacute", 0x1e2e,
    "Idieresiscyrillic", 0x04e4,
    "Idieresissmall", 0xf7ef,
    "Idot", 0x0130,
    "Idotaccent", 0x0130,
    "Idotbelow", 0x1eca,
    "Iebrevecyrillic", 0x04d6,
    "Iecyrillic", 0x0415,
    "Ifraktur", 0x2111,
    "Igrave", 0x00cc,
    "Igravesmall", 0xf7ec,
    "Ihookabove", 0x1ec8,
    "Iicyrillic", 0x0418,
    "Iinvertedbreve", 0x020a,
    "Iishortcyrillic", 0x0419,
    "Imacron", 0x012a,
    "Imacroncyrillic", 0x04e2,
    "Imonospace", 0xff29,
    "Iniarmenian", 0x053b,
    "Iocyrillic", 0x0401,
    "Iogonek", 0x012e,
    "Iota", 0x0399,
    "Iotaafrican", 0x0196,
    "Iotadieresis", 0x03aa,
    "Iotatonos", 0x038a,
    "Ismall", 0xf769,
    "Istroke", 0x0197,
    "Itilde", 0x0128,
    "Itildebelow", 0x1e2c,
    "Izhitsacyrillic", 0x0474,
    "Izhitsadblgravecyrillic", 0x0476,
    "J", 0x004a,
    "Jaarmenian", 0x0541,
    "Jcircle", 0x24bf,
    "Jcircumflex", 0x0134,
    "Jecyrillic", 0x0408,
    "Jheharmenian", 0x054b,
    "Jmonospace", 0xff2a,
    "Jsmall", 0xf76a,
    "K", 0x004b,
    "KBsquare", 0x3385,
    "KKsquare", 0x33cd,
    "Kabashkircyrillic", 0x04a0,
    "Kacute", 0x1e30,
    "Kacyrillic", 0x041a,
    "Kadescendercyrillic", 0x049a,
    "Kahookcyrillic", 0x04c3,
    "Kappa", 0x039a,
    "Kastrokecyrillic", 0x049e,
    "Kaverticalstrokecyrillic", 0x049c,
    "Kcaron", 0x01e8,
    "Kcedilla", 0x0136,
    "Kcircle", 0x24c0,
    "Kcommaaccent", 0x0136,
    "Kdotbelow", 0x1e32,
    "Keharmenian", 0x0554,
    "Kenarmenian", 0x053f,
    "Khacyrillic", 0x0425,
    "Kheicoptic", 0x03e6,
    "Khook", 0x0198,
    "Kjecyrillic", 0x040c,
    "Klinebelow", 0x1e34,
    "Kmonospace", 0xff2b,
    "Koppacyrillic", 0x0480,
    "Koppagreek", 0x03de,
    "Ksicyrillic", 0x046e,
    "Ksmall", 0xf76b,
    "L", 0x004c,
    "LJ", 0x01c7,
    "LL", 0xf6bf,
    "Lacute", 0x0139,
    "Lambda", 0x039b,
    "Lcaron", 0x013d,
    "Lcedilla", 0x013b,
    "Lcircle", 0x24c1,
    "Lcircumflexbelow", 0x1e3c,
    "Lcommaaccent", 0x013b,
    "Ldot", 0x013f,
    "Ldotaccent", 0x013f,
    "Ldotbelow", 0x1e36,
    "Ldotbelowmacron", 0x1e38,
    "Liwnarmenian", 0x053c,
    "Lj", 0x01c8,
    "Ljecyrillic", 0x0409,
    "Llinebelow", 0x1e3a,
    "Lmonospace", 0xff2c,
    "Lslash", 0x0141,
    "Lslashsmall", 0xf6f9,
    "Lsmall", 0xf76c,
    "M", 0x004d,
    "MBsquare", 0x3386,
    "Macron", 0xf6d0,
    "Macronsmall", 0xf7af,
    "Macute", 0x1e3e,
    "Mcircle", 0x24c2,
    "Mdotaccent", 0x1e40,
    "Mdotbelow", 0x1e42,
    "Menarmenian", 0x0544,
    "Mmonospace", 0xff2d,
    "Msmall", 0xf76d,
    "Mturned", 0x019c,
    "Mu", 0x039c,
    "N", 0x004e,
    "NJ", 0x01ca,
    "Nacute", 0x0143,
    "Ncaron", 0x0147,
    "Ncedilla", 0x0145,
    "Ncircle", 0x24c3,
    "Ncircumflexbelow", 0x1e4a,
    "Ncommaaccent", 0x0145,
    "Ndotaccent", 0x1e44,
    "Ndotbelow", 0x1e46,
    "Nhookleft", 0x019d,
    "Nineroman", 0x2168,
    "Nj", 0x01cb,
    "Njecyrillic", 0x040a,
    "Nlinebelow", 0x1e48,
    "Nmonospace", 0xff2e,
    "Nowarmenian", 0x0546,
    "Nsmall", 0xf76e,
    "Ntilde", 0x00d1,
    "Ntildesmall", 0xf7f1,
    "Nu", 0x039d,
    "O", 0x004f,
    "OE", 0x0152,
    "OEsmall", 0xf6fa,
    "Oacute", 0x00d3,
    "Oacutesmall", 0xf7f3,
    "Obarredcyrillic", 0x04e8,
    "Obarreddieresiscyrillic", 0x04ea,
    "Obreve", 0x014e,
    "Ocaron", 0x01d1,
    "Ocenteredtilde", 0x019f,
    "Ocircle", 0x24c4,
    "Ocircumflex", 0x00d4,
    "Ocircumflexacute", 0x1ed0,
    "Ocircumflexdotbelow", 0x1ed8,
    "Ocircumflexgrave", 0x1ed2,
    "Ocircumflexhookabove", 0x1ed4,
    "Ocircumflexsmall", 0xf7f4,
    "Ocircumflextilde", 0x1ed6,
    "Ocyrillic", 0x041e,
    "Odblacute", 0x0150,
    "Odblgrave", 0x020c,
    "Odieresis", 0x00d6,
    "Odieresiscyrillic", 0x04e6,
    "Odieresissmall", 0xf7f6,
    "Odotbelow", 0x1ecc,
    "Ogoneksmall", 0xf6fb,
    "Ograve", 0x00d2,
    "Ogravesmall", 0xf7f2,
    "Oharmenian", 0x0555,
    "Ohm", 0x2126,
    "Ohookabove", 0x1ece,
    "Ohorn", 0x01a0,
    "Ohornacute", 0x1eda,
    "Ohorndotbelow", 0x1ee2,
    "Ohorngrave", 0x1edc,
    "Ohornhookabove", 0x1ede,
    "Ohorntilde", 0x1ee0,
    "Ohungarumlaut", 0x0150,
    "Oi", 0x01a2,
    "Oinvertedbreve", 0x020e,
    "Omacron", 0x014c,
    "Omacronacute", 0x1e52,
    "Omacrongrave", 0x1e50,
    "Omega", 0x2126,
    "Omegacyrillic", 0x0460,
    "Omegagreek", 0x03a9,
    "Omegaroundcyrillic", 0x047a,
    "Omegatitlocyrillic", 0x047c,
    "Omegatonos", 0x038f,
    "Omicron", 0x039f,
    "Omicrontonos", 0x038c,
    "Omonospace", 0xff2f,
    "Oneroman", 0x2160,
    "Oogonek", 0x01ea,
    "Oogonekmacron", 0x01ec,
    "Oopen", 0x0186,
    "Oslash", 0x00d8,
    "Oslashacute", 0x01fe,
    "Oslashsmall", 0xf7f8,
    "Osmall", 0xf76f,
    "Ostrokeacute", 0x01fe,
    "Otcyrillic", 0x047e,
    "Otilde", 0x00d5,
    "Otildeacute", 0x1e4c,
    "Otildedieresis", 0x1e4e,
    "Otildesmall", 0xf7f5,
    "P", 0x0050,
    "Pacute", 0x1e54,
    "Pcircle", 0x24c5,
    "Pdotaccent", 0x1e56,
    "Pecyrillic", 0x041f,
    "Peharmenian", 0x054a,
    "Pemiddlehookcyrillic", 0x04a6,
    "Phi", 0x03a6,
    "Phook", 0x01a4,
    "Pi", 0x03a0,
    "Piwrarmenian", 0x0553,
    "Pmonospace", 0xff30,
    "Psi", 0x03a8,
    "Psicyrillic", 0x0470,
    "Psmall", 0xf770,
    "Q", 0x0051,
    "Qcircle", 0x24c6,
    "Qmonospace", 0xff31,
    "Qsmall", 0xf771,
    "R", 0x0052,
    "Raarmenian", 0x054c,
    "Racute", 0x0154,
    "Rcaron", 0x0158,
    "Rcedilla", 0x0156,
    "Rcircle", 0x24c7,
    "Rcommaaccent", 0x0156,
    "Rdblgrave", 0x0210,
    "Rdotaccent", 0x1e58,
    "Rdotbelow", 0x1e5a,
    "Rdotbelowmacron", 0x1e5c,
    "Reharmenian", 0x0550,
    "Rfraktur", 0x211c,
    "Rho", 0x03a1,
    "Ringsmall", 0xf6fc,
    "Rinvertedbreve", 0x0212,
    "Rlinebelow", 0x1e5e,
    "Rmonospace", 0xff32,
    "Rsmall", 0xf772,
    "Rsmallinverted", 0x0281,
    "Rsmallinvertedsuperior", 0x02b6,
    "S", 0x0053,
    "SF010000", 0x250c,
    "SF020000", 0x2514,
    "SF030000", 0x2510,
    "SF040000", 0x2518,
    "SF050000", 0x253c,
    "SF060000", 0x252c,
    "SF070000", 0x2534,
    "SF080000", 0x251c,
    "SF090000", 0x2524,
    "SF100000", 0x2500,
    "SF110000", 0x2502,
    "SF190000", 0x2561,
    "SF200000", 0x2562,
    "SF210000", 0x2556,
    "SF220000", 0x2555,
    "SF230000", 0x2563,
    "SF240000", 0x2551,
    "SF250000", 0x2557,
    "SF260000", 0x255d,
    "SF270000", 0x255c,
    "SF280000", 0x255b,
    "SF360000", 0x255e,
    "SF370000", 0x255f,
    "SF380000", 0x255a,
    "SF390000", 0x2554,
    "SF400000", 0x2569,
    "SF410000", 0x2566,
    "SF420000", 0x2560,
    "SF430000", 0x2550,
    "SF440000", 0x256c,
    "SF450000", 0x2567,
    "SF460000", 0x2568,
    "SF470000", 0x2564,
    "SF480000", 0x2565,
    "SF490000", 0x2559,
    "SF500000", 0x2558,
    "SF510000", 0x2552,
    "SF520000", 0x2553,
    "SF530000", 0x256b,
    "SF540000", 0x256a,
    "Sacute", 0x015a,
    "Sacutedotaccent", 0x1e64,
    "Sampigreek", 0x03e0,
    "Scaron", 0x0160,
    "Scarondotaccent", 0x1e66,
    "Scaronsmall", 0xf6fd,
    "Scedilla", 0x015e,
    "Schwa", 0x018f,
    "Schwacyrillic", 0x04d8,
    "Schwadieresiscyrillic", 0x04da,
    "Scircle", 0x24c8,
    "Scircumflex", 0x015c,
    "Scommaaccent", 0x0218,
    "Sdotaccent", 0x1e60,
    "Sdotbelow", 0x1e62,
    "Sdotbelowdotaccent", 0x1e68,
    "Seharmenian", 0x054d,
    "Sevenroman", 0x2166,
    "Shaarmenian", 0x0547,
    "Shacyrillic", 0x0428,
    "Shchacyrillic", 0x0429,
    "Sheicoptic", 0x03e2,
    "Shhacyrillic", 0x04ba,
    "Shimacoptic", 0x03ec,
    "Sigma", 0x03a3,
    "Sixroman", 0x2165,
    "Smonospace", 0xff33,
    "Softsigncyrillic", 0x042c,
    "Ssmall", 0xf773,
    "Stigmagreek", 0x03da,
    "T", 0x0054,
    "Tau", 0x03a4,
    "Tbar", 0x0166,
    "Tcaron", 0x0164,
    "Tcedilla", 0x0162,
    "Tcircle", 0x24c9,
    "Tcircumflexbelow", 0x1e70,
    "Tcommaaccent", 0x0162,
    "Tdotaccent", 0x1e6a,
    "Tdotbelow", 0x1e6c,
    "Tecyrillic", 0x0422,
    "Tedescendercyrillic", 0x04ac,
    "Tenroman", 0x2169,
    "Tetsecyrillic", 0x04b4,
    "Theta", 0x0398,
    "Thook", 0x01ac,
    "Thorn", 0x00de,
    "Thornsmall", 0xf7fe,
    "Threeroman", 0x2162,
    "Tildesmall", 0xf6fe,
    "Tiwnarmenian", 0x054f,
    "Tlinebelow", 0x1e6e,
    "Tmonospace", 0xff34,
    "Toarmenian", 0x0539,
    "Tonefive", 0x01bc,
    "Tonesix", 0x0184,
    "Tonetwo", 0x01a7,
    "Tretroflexhook", 0x01ae,
    "Tsecyrillic", 0x0426,
    "Tshecyrillic", 0x040b,
    "Tsmall", 0xf774,
    "Twelveroman", 0x216b,
    "Tworoman", 0x2161,
    "U", 0x0055,
    "Uacute", 0x00da,
    "Uacutesmall", 0xf7fa,
    "Ubreve", 0x016c,
    "Ucaron", 0x01d3,
    "Ucircle", 0x24ca,
    "Ucircumflex", 0x00db,
    "Ucircumflexbelow", 0x1e76,
    "Ucircumflexsmall", 0xf7fb,
    "Ucyrillic", 0x0423,
    "Udblacute", 0x0170,
    "Udblgrave", 0x0214,
    "Udieresis", 0x00dc,
    "Udieresisacute", 0x01d7,
    "Udieresisbelow", 0x1e72,
    "Udieresiscaron", 0x01d9,
    "Udieresiscyrillic", 0x04f0,
    "Udieresisgrave", 0x01db,
    "Udieresismacron", 0x01d5,
    "Udieresissmall", 0xf7fc,
    "Udotbelow", 0x1ee4,
    "Ugrave", 0x00d9,
    "Ugravesmall", 0xf7f9,
    "Uhookabove", 0x1ee6,
    "Uhorn", 0x01af,
    "Uhornacute", 0x1ee8,
    "Uhorndotbelow", 0x1ef0,
    "Uhorngrave", 0x1eea,
    "Uhornhookabove", 0x1eec,
    "Uhorntilde", 0x1eee,
    "Uhungarumlaut", 0x0170,
    "Uhungarumlautcyrillic", 0x04f2,
    "Uinvertedbreve", 0x0216,
    "Ukcyrillic", 0x0478,
    "Umacron", 0x016a,
    "Umacroncyrillic", 0x04ee,
    "Umacrondieresis", 0x1e7a,
    "Umonospace", 0xff35,
    "Uogonek", 0x0172,
    "Upsilon", 0x03a5,
    "Upsilon1", 0x03d2,
    "Upsilonacutehooksymbolgreek", 0x03d3,
    "Upsilonafrican", 0x01b1,
    "Upsilondieresis", 0x03ab,
    "Upsilondieresishooksymbolgreek", 0x03d4,
    "Upsilonhooksymbol", 0x03d2,
    "Upsilontonos", 0x038e,
    "Uring", 0x016e,
    "Ushortcyrillic", 0x040e,
    "Usmall", 0xf775,
    "Ustraightcyrillic", 0x04ae,
    "Ustraightstrokecyrillic", 0x04b0,
    "Utilde", 0x0168,
    "Utildeacute", 0x1e78,
    "Utildebelow", 0x1e74,
    "V", 0x0056,
    "Vcircle", 0x24cb,
    "Vdotbelow", 0x1e7e,
    "Vecyrillic", 0x0412,
    "Vewarmenian", 0x054e,
    "Vhook", 0x01b2,
    "Vmonospace", 0xff36,
    "Voarmenian", 0x0548,
    "Vsmall", 0xf776,
    "Vtilde", 0x1e7c,
    "W", 0x0057,
    "Wacute", 0x1e82,
    "Wcircle", 0x24cc,
    "Wcircumflex", 0x0174,
    "Wdieresis", 0x1e84,
    "Wdotaccent", 0x1e86,
    "Wdotbelow", 0x1e88,
    "Wgrave", 0x1e80,
    "Wmonospace", 0xff37,
    "Wsmall", 0xf777,
    "X", 0x0058,
    "Xcircle", 0x24cd,
    "Xdieresis", 0x1e8c,
    "Xdotaccent", 0x1e8a,
    "Xeharmenian", 0x053d,
    "Xi", 0x039e,
    "Xmonospace", 0xff38,
    "Xsmall", 0xf778,
    "Y", 0x0059,
    "Yacute", 0x00dd,
    "Yacutesmall", 0xf7fd,
    "Yatcyrillic", 0x0462,
    "Ycircle", 0x24ce,
    "Ycircumflex", 0x0176,
    "Ydieresis", 0x0178,
    "Ydieresissmall", 0xf7ff,
    "Ydotaccent", 0x1e8e,
    "Ydotbelow", 0x1ef4,
    "Yericyrillic", 0x042b,
    "Yerudieresiscyrillic", 0x04f8,
    "Ygrave", 0x1ef2,
    "Yhook", 0x01b3,
    "Yhookabove", 0x1ef6,
    "Yiarmenian", 0x0545,
    "Yicyrillic", 0x0407,
    "Yiwnarmenian", 0x0552,
    "Ymonospace", 0xff39,
    "Ysmall", 0xf779,
    "Ytilde", 0x1ef8,
    "Yusbigcyrillic", 0x046a,
    "Yusbigiotifiedcyrillic", 0x046c,
    "Yuslittlecyrillic", 0x0466,
    "Yuslittleiotifiedcyrillic", 0x0468,
    "Z", 0x005a,
    "Zaarmenian", 0x0536,
    "Zacute", 0x0179,
    "Zcaron", 0x017d,
    "Zcaronsmall", 0xf6ff,
    "Zcircle", 0x24cf,
    "Zcircumflex", 0x1e90,
    "Zdot", 0x017b,
    "Zdotaccent", 0x017b,
    "Zdotbelow", 0x1e92,
    "Zecyrillic", 0x0417,
    "Zedescendercyrillic", 0x0498,
    "Zedieresiscyrillic", 0x04de,
    "Zeta", 0x0396,
    "Zhearmenian", 0x053a,
    "Zhebrevecyrillic", 0x04c1,
    "Zhecyrillic", 0x0416,
    "Zhedescendercyrillic", 0x0496,
    "Zhedieresiscyrillic", 0x04dc,
    "Zlinebelow", 0x1e94,
    "Zmonospace", 0xff3a,
    "Zsmall", 0xf77a,
    "Zstroke", 0x01b5,
    "a", 0x0061,
    "aabengali", 0x0986,
    "aacute", 0x00e1,
    "aadeva", 0x0906,
    "aagujarati", 0x0a86,
    "aagurmukhi", 0x0a06,
    "aamatragurmukhi", 0x0a3e,
    "aarusquare", 0x3303,
    "aavowelsignbengali", 0x09be,
    "aavowelsigndeva", 0x093e,
    "aavowelsigngujarati", 0x0abe,
    "abbreviationmarkarmenian", 0x055f,
    "abbreviationsigndeva", 0x0970,
    "abengali", 0x0985,
    "abopomofo", 0x311a,
    "abreve", 0x0103,
    "abreveacute", 0x1eaf,
    "abrevecyrillic", 0x04d1,
    "abrevedotbelow", 0x1eb7,
    "abrevegrave", 0x1eb1,
    "abrevehookabove", 0x1eb3,
    "abrevetilde", 0x1eb5,
    "acaron", 0x01ce,
    "acircle", 0x24d0,
    "acircumflex", 0x00e2,
    "acircumflexacute", 0x1ea5,
    "acircumflexdotbelow", 0x1ead,
    "acircumflexgrave", 0x1ea7,
    "acircumflexhookabove", 0x1ea9,
    "acircumflextilde", 0x1eab,
    "acute", 0x00b4,
    "acutebelowcmb", 0x0317,
    "acutecmb", 0x0301,
    "acutecomb", 0x0301,
    "acutedeva", 0x0954,
    "acutelowmod", 0x02cf,
    "acutetonecmb", 0x0341,
    "acyrillic", 0x0430,
    "adblgrave", 0x0201,
    "addakgurmukhi", 0x0a71,
    "adeva", 0x0905,
    "adieresis", 0x00e4,
    "adieresiscyrillic", 0x04d3,
    "adieresismacron", 0x01df,
    "adotbelow", 0x1ea1,
    "adotmacron", 0x01e1,
    "ae", 0x00e6,
    "aeacute", 0x01fd,
    "aekorean", 0x3150,
    "aemacron", 0x01e3,
    "afii00208", 0x2015,
    "afii08941", 0x20a4,
    "afii10017", 0x0410,
    "afii10018", 0x0411,
    "afii10019", 0x0412,
    "afii10020", 0x0413,
    "afii10021", 0x0414,
    "afii10022", 0x0415,
    "afii10023", 0x0401,
    "afii10024", 0x0416,
    "afii10025", 0x0417,
    "afii10026", 0x0418,
    "afii10027", 0x0419,
    "afii10028", 0x041a,
    "afii10029", 0x041b,
    "afii10030", 0x041c,
    "afii10031", 0x041d,
    "afii10032", 0x041e,
    "afii10033", 0x041f,
    "afii10034", 0x0420,
    "afii10035", 0x0421,
    "afii10036", 0x0422,
    "afii10037", 0x0423,
    "afii10038", 0x0424,
    "afii10039", 0x0425,
    "afii10040", 0x0426,
    "afii10041", 0x0427,
    "afii10042", 0x0428,
    "afii10043", 0x0429,
    "afii10044", 0x042a,
    "afii10045", 0x042b,
    "afii10046", 0x042c,
    "afii10047", 0x042d,
    "afii10048", 0x042e,
    "afii10049", 0x042f,
    "afii10050", 0x0490,
    "afii10051", 0x0402,
    "afii10052", 0x0403,
    "afii10053", 0x0404,
    "afii10054", 0x0405,
    "afii10055", 0x0406,
    "afii10056", 0x0407,
    "afii10057", 0x0408,
    "afii10058", 0x0409,
    "afii10059", 0x040a,
    "afii10060", 0x040b,
    "afii10061", 0x040c,
    "afii10062", 0x040e,
    "afii10063", 0xf6c4,
    "afii10064", 0xf6c5,
    "afii10065", 0x0430,
    "afii10066", 0x0431,
    "afii10067", 0x0432,
    "afii10068", 0x0433,
    "afii10069", 0x0434,
    "afii10070", 0x0435,
    "afii10071", 0x0451,
    "afii10072", 0x0436,
    "afii10073", 0x0437,
    "afii10074", 0x0438,
    "afii10075", 0x0439,
    "afii10076", 0x043a,
    "afii10077", 0x043b,
    "afii10078", 0x043c,
    "afii10079", 0x043d,
    "afii10080", 0x043e,
    "afii10081", 0x043f,
    "afii10082", 0x0440,
    "afii10083", 0x0441,
    "afii10084", 0x0442,
    "afii10085", 0x0443,
    "afii10086", 0x0444,
    "afii10087", 0x0445,
    "afii10088", 0x0446,
    "afii10089", 0x0447,
    "afii10090", 0x0448,
    "afii10091", 0x0449,
    "afii10092", 0x044a,
    "afii10093", 0x044b,
    "afii10094", 0x044c,
    "afii10095", 0x044d,
    "afii10096", 0x044e,
    "afii10097", 0x044f,
    "afii10098", 0x0491,
    "afii10099", 0x0452,
    "afii10100", 0x0453,
    "afii10101", 0x0454,
    "afii10102", 0x0455,
    "afii10103", 0x0456,
    "afii10104", 0x0457,
    "afii10105", 0x0458,
    "afii10106", 0x0459,
    "afii10107", 0x045a,
    "afii10108", 0x045b,
    "afii10109", 0x045c,
    "afii10110", 0x045e,
    "afii10145", 0x040f,
    "afii10146", 0x0462,
    "afii10147", 0x0472,
    "afii10148", 0x0474,
    "afii10192", 0xf6c6,
    "afii10193", 0x045f,
    "afii10194", 0x0463,
    "afii10195", 0x0473,
    "afii10196", 0x0475,
    "afii10831", 0xf6c7,
    "afii10832", 0xf6c8,
    "afii10846", 0x04d9,
    "afii299", 0x200e,
    "afii300", 0x200f,
    "afii301", 0x200d,
    "afii57381", 0x066a,
    "afii57388", 0x060c,
    "afii57392", 0x0660,
    "afii57393", 0x0661,
    "afii57394", 0x0662,
    "afii57395", 0x0663,
    "afii57396", 0x0664,
    "afii57397", 0x0665,
    "afii57398", 0x0666,
    "afii57399", 0x0667,
    "afii57400", 0x0668,
    "afii57401", 0x0669,
    "afii57403", 0x061b,
    "afii57407", 0x061f,
    "afii57409", 0x0621,
    "afii57410", 0x0622,
    "afii57411", 0x0623,
    "afii57412", 0x0624,
    "afii57413", 0x0625,
    "afii57414", 0x0626,
    "afii57415", 0x0627,
    "afii57416", 0x0628,
    "afii57417", 0x0629,
    "afii57418", 0x062a,
    "afii57419", 0x062b,
    "afii57420", 0x062c,
    "afii57421", 0x062d,
    "afii57422", 0x062e,
    "afii57423", 0x062f,
    "afii57424", 0x0630,
    "afii57425", 0x0631,
    "afii57426", 0x0632,
    "afii57427", 0x0633,
    "afii57428", 0x0634,
    "afii57429", 0x0635,
    "afii57430", 0x0636,
    "afii57431", 0x0637,
    "afii57432", 0x0638,
    "afii57433", 0x0639,
    "afii57434", 0x063a,
    "afii57440", 0x0640,
    "afii57441", 0x0641,
    "afii57442", 0x0642,
    "afii57443", 0x0643,
    "afii57444", 0x0644,
    "afii57445", 0x0645,
    "afii57446", 0x0646,
    "afii57448", 0x0648,
    "afii57449", 0x0649,
    "afii57450", 0x064a,
    "afii57451", 0x064b,
    "afii57452", 0x064c,
    "afii57453", 0x064d,
    "afii57454", 0x064e,
    "afii57455", 0x064f,
    "afii57456", 0x0650,
    "afii57457", 0x0651,
    "afii57458", 0x0652,
    "afii57470", 0x0647,
    "afii57505", 0x06a4,
    "afii57506", 0x067e,
    "afii57507", 0x0686,
    "afii57508", 0x0698,
    "afii57509", 0x06af,
    "afii57511", 0x0679,
    "afii57512", 0x0688,
    "afii57513", 0x0691,
    "afii57514", 0x06ba,
    "afii57519", 0x06d2,
    "afii57534", 0x06d5,
    "afii57636", 0x20aa,
    "afii57645", 0x05be,
    "afii57658", 0x05c3,
    "afii57664", 0x05d0,
    "afii57665", 0x05d1,
    "afii57666", 0x05d2,
    "afii57667", 0x05d3,
    "afii57668", 0x05d4,
    "afii57669", 0x05d5,
    "afii57670", 0x05d6,
    "afii57671", 0x05d7,
    "afii57672", 0x05d8,
    "afii57673", 0x05d9,
    "afii57674", 0x05da,
    "afii57675", 0x05db,
    "afii57676", 0x05dc,
    "afii57677", 0x05dd,
    "afii57678", 0x05de,
    "afii57679", 0x05df,
    "afii57680", 0x05e0,
    "afii57681", 0x05e1,
    "afii57682", 0x05e2,
    "afii57683", 0x05e3,
    "afii57684", 0x05e4,
    "afii57685", 0x05e5,
    "afii57686", 0x05e6,
    "afii57687", 0x05e7,
    "afii57688", 0x05e8,
    "afii57689", 0x05e9,
    "afii57690", 0x05ea,
    "afii57694", 0xfb2a,
    "afii57695", 0xfb2b,
    "afii57700", 0xfb4b,
    "afii57705", 0xfb1f,
    "afii57716", 0x05f0,
    "afii57717", 0x05f1,
    "afii57718", 0x05f2,
    "afii57723", 0xfb35,
    "afii57793", 0x05b4,
    "afii57794", 0x05b5,
    "afii57795", 0x05b6,
    "afii57796", 0x05bb,
    "afii57797", 0x05b8,
    "afii57798", 0x05b7,
    "afii57799", 0x05b0,
    "afii57800", 0x05b2,
    "afii57801", 0x05b1,
    "afii57802", 0x05b3,
    "afii57803", 0x05c2,
    "afii57804", 0x05c1,
    "afii57806", 0x05b9,
    "afii57807", 0x05bc,
    "afii57839", 0x05bd,
    "afii57841", 0x05bf,
    "afii57842", 0x05c0,
    "afii57929", 0x02bc,
    "afii61248", 0x2105,
    "afii61289", 0x2113,
    "afii61352", 0x2116,
    "afii61573", 0x202c,
    "afii61574", 0x202d,
    "afii61575", 0x202e,
    "afii61664", 0x200c,
    "afii63167", 0x066d,
    "afii64937", 0x02bd,
    "agrave", 0x00e0,
    "agujarati", 0x0a85,
    "agurmukhi", 0x0a05,
    "ahiragana", 0x3042,
    "ahookabove", 0x1ea3,
    "aibengali", 0x0990,
    "aibopomofo", 0x311e,
    "aideva", 0x0910,
    "aiecyrillic", 0x04d5,
    "aigujarati", 0x0a90,
    "aigurmukhi", 0x0a10,
    "aimatragurmukhi", 0x0a48,
    "ainarabic", 0x0639,
    "ainfinalarabic", 0xfeca,
    "aininitialarabic", 0xfecb,
    "ainmedialarabic", 0xfecc,
    "ainvertedbreve", 0x0203,
    "aivowelsignbengali", 0x09c8,
    "aivowelsigndeva", 0x0948,
    "aivowelsigngujarati", 0x0ac8,
    "akatakana", 0x30a2,
    "akatakanahalfwidth", 0xff71,
    "akorean", 0x314f,
    "alef", 0x05d0,
    "alefarabic", 0x0627,
    "alefdageshhebrew", 0xfb30,
    "aleffinalarabic", 0xfe8e,
    "alefhamzaabovearabic", 0x0623,
    "alefhamzaabovefinalarabic", 0xfe84,
    "alefhamzabelowarabic", 0x0625,
    "alefhamzabelowfinalarabic", 0xfe88,
    "alefhebrew", 0x05d0,
    "aleflamedhebrew", 0xfb4f,
    "alefmaddaabovearabic", 0x0622,
    "alefmaddaabovefinalarabic", 0xfe82,
    "alefmaksuraarabic", 0x0649,
    "alefmaksurafinalarabic", 0xfef0,
    "alefmaksurainitialarabic", 0xfef3,
    "alefmaksuramedialarabic", 0xfef4,
    "alefpatahhebrew", 0xfb2e,
    "alefqamatshebrew", 0xfb2f,
    "aleph", 0x2135,
    "allequal", 0x224c,
    "alpha", 0x03b1,
    "alphatonos", 0x03ac,
    "amacron", 0x0101,
    "amonospace", 0xff41,
    "ampersand", 0x0026,
    "ampersandmonospace", 0xff06,
    "ampersandsmall", 0xf726,
    "amsquare", 0x33c2,
    "anbopomofo", 0x3122,
    "angbopomofo", 0x3124,
    "angbracketleft", 0x3008, // Glyph is missing from Adobe's original list.
    "angbracketright", 0x3009, // Glyph is missing from Adobe's original list.
    "angkhankhuthai", 0x0e5a,
    "angle", 0x2220,
    "anglebracketleft", 0x3008,
    "anglebracketleftvertical", 0xfe3f,
    "anglebracketright", 0x3009,
    "anglebracketrightvertical", 0xfe40,
    "angleleft", 0x2329,
    "angleright", 0x232a,
    "angstrom", 0x212b,
    "anoteleia", 0x0387,
    "anudattadeva", 0x0952,
    "anusvarabengali", 0x0982,
    "anusvaradeva", 0x0902,
    "anusvaragujarati", 0x0a82,
    "aogonek", 0x0105,
    "apaatosquare", 0x3300,
    "aparen", 0x249c,
    "apostrophearmenian", 0x055a,
    "apostrophemod", 0x02bc,
    "apple", 0xf8ff,
    "approaches", 0x2250,
    "approxequal", 0x2248,
    "approxequalorimage", 0x2252,
    "approximatelyequal", 0x2245,
    "araeaekorean", 0x318e,
    "araeakorean", 0x318d,
    "arc", 0x2312,
    "arighthalfring", 0x1e9a,
    "aring", 0x00e5,
    "aringacute", 0x01fb,
    "aringbelow", 0x1e01,
    "arrowboth", 0x2194,
    "arrowdashdown", 0x21e3,
    "arrowdashleft", 0x21e0,
    "arrowdashright", 0x21e2,
    "arrowdashup", 0x21e1,
    "arrowdblboth", 0x21d4,
    "arrowdbldown", 0x21d3,
    "arrowdblleft", 0x21d0,
    "arrowdblright", 0x21d2,
    "arrowdblup", 0x21d1,
    "arrowdown", 0x2193,
    "arrowdownleft", 0x2199,
    "arrowdownright", 0x2198,
    "arrowdownwhite", 0x21e9,
    "arrowheaddownmod", 0x02c5,
    "arrowheadleftmod", 0x02c2,
    "arrowheadrightmod", 0x02c3,
    "arrowheadupmod", 0x02c4,
    "arrowhorizex", 0xf8e7,
    "arrowleft", 0x2190,
    "arrowleftdbl", 0x21d0,
    "arrowleftdblstroke", 0x21cd,
    "arrowleftoverright", 0x21c6,
    "arrowleftwhite", 0x21e6,
    "arrowright", 0x2192,
    "arrowrightdblstroke", 0x21cf,
    "arrowrightheavy", 0x279e,
    "arrowrightoverleft", 0x21c4,
    "arrowrightwhite", 0x21e8,
    "arrowtableft", 0x21e4,
    "arrowtabright", 0x21e5,
    "arrowup", 0x2191,
    "arrowupdn", 0x2195,
    "arrowupdnbse", 0x21a8,
    "arrowupdownbase", 0x21a8,
    "arrowupleft", 0x2196,
    "arrowupleftofdown", 0x21c5,
    "arrowupright", 0x2197,
    "arrowupwhite", 0x21e7,
    "arrowvertex", 0xf8e6,
    "asciicircum", 0x005e,
    "asciicircummonospace", 0xff3e,
    "asciitilde", 0x007e,
    "asciitildemonospace", 0xff5e,
    "ascript", 0x0251,
    "ascriptturned", 0x0252,
    "asmallhiragana", 0x3041,
    "asmallkatakana", 0x30a1,
    "asmallkatakanahalfwidth", 0xff67,
    "asterisk", 0x002a,
    "asteriskaltonearabic", 0x066d,
    "asteriskarabic", 0x066d,
    "asteriskmath", 0x2217,
    "asteriskmonospace", 0xff0a,
    "asterisksmall", 0xfe61,
    "asterism", 0x2042,
    "asuperior", 0xf6e9,
    "asymptoticallyequal", 0x2243,
    "at", 0x0040,
    "atilde", 0x00e3,
    "atmonospace", 0xff20,
    "atsmall", 0xfe6b,
    "aturned", 0x0250,
    "aubengali", 0x0994,
    "aubopomofo", 0x3120,
    "audeva", 0x0914,
    "augujarati", 0x0a94,
    "augurmukhi", 0x0a14,
    "aulengthmarkbengali", 0x09d7,
    "aumatragurmukhi", 0x0a4c,
    "auvowelsignbengali", 0x09cc,
    "auvowelsigndeva", 0x094c,
    "auvowelsigngujarati", 0x0acc,
    "avagrahadeva", 0x093d,
    "aybarmenian", 0x0561,
    "ayin", 0x05e2,
    "ayinaltonehebrew", 0xfb20,
    "ayinhebrew", 0x05e2,
    "b", 0x0062,
    "babengali", 0x09ac,
    "backslash", 0x005c,
    "backslashmonospace", 0xff3c,
    "badeva", 0x092c,
    "bagujarati", 0x0aac,
    "bagurmukhi", 0x0a2c,
    "bahiragana", 0x3070,
    "bahtthai", 0x0e3f,
    "bakatakana", 0x30d0,
    "bar", 0x007c,
    "barmonospace", 0xff5c,
    "bbopomofo", 0x3105,
    "bcircle", 0x24d1,
    "bdotaccent", 0x1e03,
    "bdotbelow", 0x1e05,
    "beamedsixteenthnotes", 0x266c,
    "because", 0x2235,
    "becyrillic", 0x0431,
    "beharabic", 0x0628,
    "behfinalarabic", 0xfe90,
    "behinitialarabic", 0xfe91,
    "behiragana", 0x3079,
    "behmedialarabic", 0xfe92,
    "behmeeminitialarabic", 0xfc9f,
    "behmeemisolatedarabic", 0xfc08,
    "behnoonfinalarabic", 0xfc6d,
    "bekatakana", 0x30d9,
    "benarmenian", 0x0562,
    "bet", 0x05d1,
    "beta", 0x03b2,
    "betasymbolgreek", 0x03d0,
    "betdagesh", 0xfb31,
    "betdageshhebrew", 0xfb31,
    "bethebrew", 0x05d1,
    "betrafehebrew", 0xfb4c,
    "bhabengali", 0x09ad,
    "bhadeva", 0x092d,
    "bhagujarati", 0x0aad,
    "bhagurmukhi", 0x0a2d,
    "bhook", 0x0253,
    "bihiragana", 0x3073,
    "bikatakana", 0x30d3,
    "bilabialclick", 0x0298,
    "bindigurmukhi", 0x0a02,
    "birusquare", 0x3331,
    "blackcircle", 0x25cf,
    "blackdiamond", 0x25c6,
    "blackdownpointingtriangle", 0x25bc,
    "blackleftpointingpointer", 0x25c4,
    "blackleftpointingtriangle", 0x25c0,
    "blacklenticularbracketleft", 0x3010,
    "blacklenticularbracketleftvertical", 0xfe3b,
    "blacklenticularbracketright", 0x3011,
    "blacklenticularbracketrightvertical", 0xfe3c,
    "blacklowerlefttriangle", 0x25e3,
    "blacklowerrighttriangle", 0x25e2,
    "blackrectangle", 0x25ac,
    "blackrightpointingpointer", 0x25ba,
    "blackrightpointingtriangle", 0x25b6,
    "blacksmallsquare", 0x25aa,
    "blacksmilingface", 0x263b,
    "blacksquare", 0x25a0,
    "blackstar", 0x2605,
    "blackupperlefttriangle", 0x25e4,
    "blackupperrighttriangle", 0x25e5,
    "blackuppointingsmalltriangle", 0x25b4,
    "blackuppointingtriangle", 0x25b2,
    "blank", 0x2423,
    "blinebelow", 0x1e07,
    "block", 0x2588,
    "bmonospace", 0xff42,
    "bobaimaithai", 0x0e1a,
    "bohiragana", 0x307c,
    "bokatakana", 0x30dc,
    "bparen", 0x249d,
    "bqsquare", 0x33c3,
    "braceex", 0xf8f4,
    "braceleft", 0x007b,
    "braceleftbt", 0xf8f3,
    "braceleftmid", 0xf8f2,
    "braceleftmonospace", 0xff5b,
    "braceleftsmall", 0xfe5b,
    "bracelefttp", 0xf8f1,
    "braceleftvertical", 0xfe37,
    "braceright", 0x007d,
    "bracerightbt", 0xf8fe,
    "bracerightmid", 0xf8fd,
    "bracerightmonospace", 0xff5d,
    "bracerightsmall", 0xfe5c,
    "bracerighttp", 0xf8fc,
    "bracerightvertical", 0xfe38,
    "bracketleft", 0x005b,
    "bracketleftbt", 0xf8f0,
    "bracketleftex", 0xf8ef,
    "bracketleftmonospace", 0xff3b,
    "bracketlefttp", 0xf8ee,
    "bracketright", 0x005d,
    "bracketrightbt", 0xf8fb,
    "bracketrightex", 0xf8fa,
    "bracketrightmonospace", 0xff3d,
    "bracketrighttp", 0xf8f9,
    "breve", 0x02d8,
    "brevebelowcmb", 0x032e,
    "brevecmb", 0x0306,
    "breveinvertedbelowcmb", 0x032f,
    "breveinvertedcmb", 0x0311,
    "breveinverteddoublecmb", 0x0361,
    "bridgebelowcmb", 0x032a,
    "bridgeinvertedbelowcmb", 0x033a,
    "brokenbar", 0x00a6,
    "bstroke", 0x0180,
    "bsuperior", 0xf6ea,
    "btopbar", 0x0183,
    "buhiragana", 0x3076,
    "bukatakana", 0x30d6,
    "bullet", 0x2022,
    "bulletinverse", 0x25d8,
    "bulletoperator", 0x2219,
    "bullseye", 0x25ce,
    "c", 0x0063,
    "caarmenian", 0x056e,
    "cabengali", 0x099a,
    "cacute", 0x0107,
    "cadeva", 0x091a,
    "cagujarati", 0x0a9a,
    "cagurmukhi", 0x0a1a,
    "calsquare", 0x3388,
    "candrabindubengali", 0x0981,
    "candrabinducmb", 0x0310,
    "candrabindudeva", 0x0901,
    "candrabindugujarati", 0x0a81,
    "capslock", 0x21ea,
    "careof", 0x2105,
    "caron", 0x02c7,
    "caronbelowcmb", 0x032c,
    "caroncmb", 0x030c,
    "carriagereturn", 0x21b5,
    "cbopomofo", 0x3118,
    "ccaron", 0x010d,
    "ccedilla", 0x00e7,
    "ccedillaacute", 0x1e09,
    "ccircle", 0x24d2,
    "ccircumflex", 0x0109,
    "ccurl", 0x0255,
    "cdot", 0x010b,
    "cdotaccent", 0x010b,
    "cdsquare", 0x33c5,
    "cedilla", 0x00b8,
    "cedillacmb", 0x0327,
    "cent", 0x00a2,
    "centigrade", 0x2103,
    "centinferior", 0xf6df,
    "centmonospace", 0xffe0,
    "centoldstyle", 0xf7a2,
    "centsuperior", 0xf6e0,
    "chaarmenian", 0x0579,
    "chabengali", 0x099b,
    "chadeva", 0x091b,
    "chagujarati", 0x0a9b,
    "chagurmukhi", 0x0a1b,
    "chbopomofo", 0x3114,
    "cheabkhasiancyrillic", 0x04bd,
    "checkmark", 0x2713,
    "checyrillic", 0x0447,
    "chedescenderabkhasiancyrillic", 0x04bf,
    "chedescendercyrillic", 0x04b7,
    "chedieresiscyrillic", 0x04f5,
    "cheharmenian", 0x0573,
    "chekhakassiancyrillic", 0x04cc,
    "cheverticalstrokecyrillic", 0x04b9,
    "chi", 0x03c7,
    "chieuchacirclekorean", 0x3277,
    "chieuchaparenkorean", 0x3217,
    "chieuchcirclekorean", 0x3269,
    "chieuchkorean", 0x314a,
    "chieuchparenkorean", 0x3209,
    "chochangthai", 0x0e0a,
    "chochanthai", 0x0e08,
    "chochingthai", 0x0e09,
    "chochoethai", 0x0e0c,
    "chook", 0x0188,
    "cieucacirclekorean", 0x3276,
    "cieucaparenkorean", 0x3216,
    "cieuccirclekorean", 0x3268,
    "cieuckorean", 0x3148,
    "cieucparenkorean", 0x3208,
    "cieucuparenkorean", 0x321c,
    "circle", 0x25cb,
    "circlecopyrt", 0x00a9, // Glyph is missing from Adobe's original list.
    "circlemultiply", 0x2297,
    "circleot", 0x2299,
    "circleplus", 0x2295,
    "circlepostalmark", 0x3036,
    "circlewithlefthalfblack", 0x25d0,
    "circlewithrighthalfblack", 0x25d1,
    "circumflex", 0x02c6,
    "circumflexbelowcmb", 0x032d,
    "circumflexcmb", 0x0302,
    "clear", 0x2327,
    "clickalveolar", 0x01c2,
    "clickdental", 0x01c0,
    "clicklateral", 0x01c1,
    "clickretroflex", 0x01c3,
    "club", 0x2663,
    "clubsuitblack", 0x2663,
    "clubsuitwhite", 0x2667,
    "cmcubedsquare", 0x33a4,
    "cmonospace", 0xff43,
    "cmsquaredsquare", 0x33a0,
    "coarmenian", 0x0581,
    "colon", 0x003a,
    "colonmonetary", 0x20a1,
    "colonmonospace", 0xff1a,
    "colonsign", 0x20a1,
    "colonsmall", 0xfe55,
    "colontriangularhalfmod", 0x02d1,
    "colontriangularmod", 0x02d0,
    "comma", 0x002c,
    "commaabovecmb", 0x0313,
    "commaaboverightcmb", 0x0315,
    "commaaccent", 0xf6c3,
    "commaarabic", 0x060c,
    "commaarmenian", 0x055d,
    "commainferior", 0xf6e1,
    "commamonospace", 0xff0c,
    "commareversedabovecmb", 0x0314,
    "commareversedmod", 0x02bd,
    "commasmall", 0xfe50,
    "commasuperior", 0xf6e2,
    "commaturnedabovecmb", 0x0312,
    "commaturnedmod", 0x02bb,
    "compass", 0x263c,
    "congruent", 0x2245,
    "contourintegral", 0x222e,
    "control", 0x2303,
    "controlACK", 0x0006,
    "controlBEL", 0x0007,
    "controlBS", 0x0008,
    "controlCAN", 0x0018,
    "controlCR", 0x000d,
    "controlDC1", 0x0011,
    "controlDC2", 0x0012,
    "controlDC3", 0x0013,
    "controlDC4", 0x0014,
    "controlDEL", 0x007f,
    "controlDLE", 0x0010,
    "controlEM", 0x0019,
    "controlENQ", 0x0005,
    "controlEOT", 0x0004,
    "controlESC", 0x001b,
    "controlETB", 0x0017,
    "controlETX", 0x0003,
    "controlFF", 0x000c,
    "controlFS", 0x001c,
    "controlGS", 0x001d,
    "controlHT", 0x0009,
    "controlLF", 0x000a,
    "controlNAK", 0x0015,
    "controlNULL", 0x0000, // Glyph is missing from Adobe's original list.
    "controlRS", 0x001e,
    "controlSI", 0x000f,
    "controlSO", 0x000e,
    "controlSOT", 0x0002,
    "controlSTX", 0x0001,
    "controlSUB", 0x001a,
    "controlSYN", 0x0016,
    "controlUS", 0x001f,
    "controlVT", 0x000b,
    "copyright", 0x00a9,
    "copyrightsans", 0xf8e9,
    "copyrightserif", 0xf6d9,
    "cornerbracketleft", 0x300c,
    "cornerbracketlefthalfwidth", 0xff62,
    "cornerbracketleftvertical", 0xfe41,
    "cornerbracketright", 0x300d,
    "cornerbracketrighthalfwidth", 0xff63,
    "cornerbracketrightvertical", 0xfe42,
    "corporationsquare", 0x337f,
    "cosquare", 0x33c7,
    "coverkgsquare", 0x33c6,
    "cparen", 0x249e,
    "cruzeiro", 0x20a2,
    "cstretched", 0x0297,
    "curlyand", 0x22cf,
    "curlyor", 0x22ce,
    "currency", 0x00a4,
    "cyrBreve", 0xf6d1,
    "cyrFlex", 0xf6d2,
    "cyrbreve", 0xf6d4,
    "cyrflex", 0xf6d5,
    "d", 0x0064,
    "daarmenian", 0x0564,
    "dabengali", 0x09a6,
    "dadarabic", 0x0636,
    "dadeva", 0x0926,
    "dadfinalarabic", 0xfebe,
    "dadinitialarabic", 0xfebf,
    "dadmedialarabic", 0xfec0,
    "dagesh", 0x05bc,
    "dageshhebrew", 0x05bc,
    "dagger", 0x2020,
    "daggerdbl", 0x2021,
    "dagujarati", 0x0aa6,
    "dagurmukhi", 0x0a26,
    "dahiragana", 0x3060,
    "dakatakana", 0x30c0,
    "dalarabic", 0x062f,
    "dalet", 0x05d3,
    "daletdagesh", 0xfb33,
    "daletdageshhebrew", 0xfb33,
    "dalethebrew", 0x05d3,
    "dalfinalarabic", 0xfeaa,
    "dammaarabic", 0x064f,
    "dammalowarabic", 0x064f,
    "dammatanaltonearabic", 0x064c,
    "dammatanarabic", 0x064c,
    "danda", 0x0964,
    "dargahebrew", 0x05a7,
    "dargalefthebrew", 0x05a7,
    "dasiapneumatacyrilliccmb", 0x0485,
    "dblGrave", 0xf6d3,
    "dblanglebracketleft", 0x300a,
    "dblanglebracketleftvertical", 0xfe3d,
    "dblanglebracketright", 0x300b,
    "dblanglebracketrightvertical", 0xfe3e,
    "dblarchinvertedbelowcmb", 0x032b,
    "dblarrowleft", 0x21d4,
    "dblarrowright", 0x21d2,
    "dbldanda", 0x0965,
    "dblgrave", 0xf6d6,
    "dblgravecmb", 0x030f,
    "dblintegral", 0x222c,
    "dbllowline", 0x2017,
    "dbllowlinecmb", 0x0333,
    "dbloverlinecmb", 0x033f,
    "dblprimemod", 0x02ba,
    "dblverticalbar", 0x2016,
    "dblverticallineabovecmb", 0x030e,
    "dbopomofo", 0x3109,
    "dbsquare", 0x33c8,
    "dcaron", 0x010f,
    "dcedilla", 0x1e11,
    "dcircle", 0x24d3,
    "dcircumflexbelow", 0x1e13,
    "dcroat", 0x0111,
    "ddabengali", 0x09a1,
    "ddadeva", 0x0921,
    "ddagujarati", 0x0aa1,
    "ddagurmukhi", 0x0a21,
    "ddalarabic", 0x0688,
    "ddalfinalarabic", 0xfb89,
    "dddhadeva", 0x095c,
    "ddhabengali", 0x09a2,
    "ddhadeva", 0x0922,
    "ddhagujarati", 0x0aa2,
    "ddhagurmukhi", 0x0a22,
    "ddotaccent", 0x1e0b,
    "ddotbelow", 0x1e0d,
    "decimalseparatorarabic", 0x066b,
    "decimalseparatorpersian", 0x066b,
    "decyrillic", 0x0434,
    "degree", 0x00b0,
    "dehihebrew", 0x05ad,
    "dehiragana", 0x3067,
    "deicoptic", 0x03ef,
    "dekatakana", 0x30c7,
    "deleteleft", 0x232b,
    "deleteright", 0x2326,
    "delta", 0x03b4,
    "deltaturned", 0x018d,
    "denominatorminusonenumeratorbengali", 0x09f8,
    "dezh", 0x02a4,
    "dhabengali", 0x09a7,
    "dhadeva", 0x0927,
    "dhagujarati", 0x0aa7,
    "dhagurmukhi", 0x0a27,
    "dhook", 0x0257,
    "dialytikatonos", 0x0385,
    "dialytikatonoscmb", 0x0344,
    "diamond", 0x2666,
    "diamondsuitwhite", 0x2662,
    "dieresis", 0x00a8,
    "dieresisacute", 0xf6d7,
    "dieresisbelowcmb", 0x0324,
    "dieresiscmb", 0x0308,
    "dieresisgrave", 0xf6d8,
    "dieresistonos", 0x0385,
    "dihiragana", 0x3062,
    "dikatakana", 0x30c2,
    "dittomark", 0x3003,
    "divide", 0x00f7,
    "divides", 0x2223,
    "divisionslash", 0x2215,
    "djecyrillic", 0x0452,
    "dkshade", 0x2593,
    "dlinebelow", 0x1e0f,
    "dlsquare", 0x3397,
    "dmacron", 0x0111,
    "dmonospace", 0xff44,
    "dnblock", 0x2584,
    "dochadathai", 0x0e0e,
    "dodekthai", 0x0e14,
    "dohiragana", 0x3069,
    "dokatakana", 0x30c9,
    "dollar", 0x0024,
    "dollarinferior", 0xf6e3,
    "dollarmonospace", 0xff04,
    "dollaroldstyle", 0xf724,
    "dollarsmall", 0xfe69,
    "dollarsuperior", 0xf6e4,
    "dong", 0x20ab,
    "dorusquare", 0x3326,
    "dotaccent", 0x02d9,
    "dotaccentcmb", 0x0307,
    "dotbelowcmb", 0x0323,
    "dotbelowcomb", 0x0323,
    "dotkatakana", 0x30fb,
    "dotlessi", 0x0131,
    "dotlessj", 0xf6be,
    "dotlessjstrokehook", 0x0284,
    "dotmath", 0x22c5,
    "dottedcircle", 0x25cc,
    "doubleyodpatah", 0xfb1f,
    "doubleyodpatahhebrew", 0xfb1f,
    "downtackbelowcmb", 0x031e,
    "downtackmod", 0x02d5,
    "dparen", 0x249f,
    "dsuperior", 0xf6eb,
    "dtail", 0x0256,
    "dtopbar", 0x018c,
    "duhiragana", 0x3065,
    "dukatakana", 0x30c5,
    "dz", 0x01f3,
    "dzaltone", 0x02a3,
    "dzcaron", 0x01c6,
    "dzcurl", 0x02a5,
    "dzeabkhasiancyrillic", 0x04e1,
    "dzecyrillic", 0x0455,
    "dzhecyrillic", 0x045f,
    "e", 0x0065,
    "eacute", 0x00e9,
    "earth", 0x2641,
    "ebengali", 0x098f,
    "ebopomofo", 0x311c,
    "ebreve", 0x0115,
    "ecandradeva", 0x090d,
    "ecandragujarati", 0x0a8d,
    "ecandravowelsigndeva", 0x0945,
    "ecandravowelsigngujarati", 0x0ac5,
    "ecaron", 0x011b,
    "ecedillabreve", 0x1e1d,
    "echarmenian", 0x0565,
    "echyiwnarmenian", 0x0587,
    "ecircle", 0x24d4,
    "ecircumflex", 0x00ea,
    "ecircumflexacute", 0x1ebf,
    "ecircumflexbelow", 0x1e19,
    "ecircumflexdotbelow", 0x1ec7,
    "ecircumflexgrave", 0x1ec1,
    "ecircumflexhookabove", 0x1ec3,
    "ecircumflextilde", 0x1ec5,
    "ecyrillic", 0x0454,
    "edblgrave", 0x0205,
    "edeva", 0x090f,
    "edieresis", 0x00eb,
    "edot", 0x0117,
    "edotaccent", 0x0117,
    "edotbelow", 0x1eb9,
    "eegurmukhi", 0x0a0f,
    "eematragurmukhi", 0x0a47,
    "efcyrillic", 0x0444,
    "egrave", 0x00e8,
    "egujarati", 0x0a8f,
    "eharmenian", 0x0567,
    "ehbopomofo", 0x311d,
    "ehiragana", 0x3048,
    "ehookabove", 0x1ebb,
    "eibopomofo", 0x311f,
    "eight", 0x0038,
    "eightarabic", 0x0668,
    "eightbengali", 0x09ee,
    "eightcircle", 0x2467,
    "eightcircleinversesansserif", 0x2791,
    "eightdeva", 0x096e,
    "eighteencircle", 0x2471,
    "eighteenparen", 0x2485,
    "eighteenperiod", 0x2499,
    "eightgujarati", 0x0aee,
    "eightgurmukhi", 0x0a6e,
    "eighthackarabic", 0x0668,
    "eighthangzhou", 0x3028,
    "eighthnotebeamed", 0x266b,
    "eightideographicparen", 0x3227,
    "eightinferior", 0x2088,
    "eightmonospace", 0xff18,
    "eightoldstyle", 0xf738,
    "eightparen", 0x247b,
    "eightperiod", 0x248f,
    "eightpersian", 0x06f8,
    "eightroman", 0x2177,
    "eightsuperior", 0x2078,
    "eightthai", 0x0e58,
    "einvertedbreve", 0x0207,
    "eiotifiedcyrillic", 0x0465,
    "ekatakana", 0x30a8,
    "ekatakanahalfwidth", 0xff74,
    "ekonkargurmukhi", 0x0a74,
    "ekorean", 0x3154,
    "elcyrillic", 0x043b,
    "element", 0x2208,
    "elevencircle", 0x246a,
    "elevenparen", 0x247e,
    "elevenperiod", 0x2492,
    "elevenroman", 0x217a,
    "ellipsis", 0x2026,
    "ellipsisvertical", 0x22ee,
    "emacron", 0x0113,
    "emacronacute", 0x1e17,
    "emacrongrave", 0x1e15,
    "emcyrillic", 0x043c,
    "emdash", 0x2014,
    "emdashvertical", 0xfe31,
    "emonospace", 0xff45,
    "emphasismarkarmenian", 0x055b,
    "emptyset", 0x2205,
    "enbopomofo", 0x3123,
    "encyrillic", 0x043d,
    "endash", 0x2013,
    "endashvertical", 0xfe32,
    "endescendercyrillic", 0x04a3,
    "eng", 0x014b,
    "engbopomofo", 0x3125,
    "enghecyrillic", 0x04a5,
    "enhookcyrillic", 0x04c8,
    "enspace", 0x2002,
    "eogonek", 0x0119,
    "eokorean", 0x3153,
    "eopen", 0x025b,
    "eopenclosed", 0x029a,
    "eopenreversed", 0x025c,
    "eopenreversedclosed", 0x025e,
    "eopenreversedhook", 0x025d,
    "eparen", 0x24a0,
    "epsilon", 0x03b5,
    "epsilontonos", 0x03ad,
    "equal", 0x003d,
    "equalmonospace", 0xff1d,
    "equalsmall", 0xfe66,
    "equalsuperior", 0x207c,
    "equivalence", 0x2261,
    "erbopomofo", 0x3126,
    "ercyrillic", 0x0440,
    "ereversed", 0x0258,
    "ereversedcyrillic", 0x044d,
    "escyrillic", 0x0441,
    "esdescendercyrillic", 0x04ab,
    "esh", 0x0283,
    "eshcurl", 0x0286,
    "eshortdeva", 0x090e,
    "eshortvowelsigndeva", 0x0946,
    "eshreversedloop", 0x01aa,
    "eshsquatreversed", 0x0285,
    "esmallhiragana", 0x3047,
    "esmallkatakana", 0x30a7,
    "esmallkatakanahalfwidth", 0xff6a,
    "estimated", 0x212e,
    "esuperior", 0xf6ec,
    "eta", 0x03b7,
    "etarmenian", 0x0568,
    "etatonos", 0x03ae,
    "eth", 0x00f0,
    "etilde", 0x1ebd,
    "etildebelow", 0x1e1b,
    "etnahtafoukhhebrew", 0x0591,
    "etnahtafoukhlefthebrew", 0x0591,
    "etnahtahebrew", 0x0591,
    "etnahtalefthebrew", 0x0591,
    "eturned", 0x01dd,
    "eukorean", 0x3161,
    "euro", 0x20ac,
    "evowelsignbengali", 0x09c7,
    "evowelsigndeva", 0x0947,
    "evowelsigngujarati", 0x0ac7,
    "exclam", 0x0021,
    "exclamarmenian", 0x055c,
    "exclamdbl", 0x203c,
    "exclamdown", 0x00a1,
    "exclamdownsmall", 0xf7a1,
    "exclammonospace", 0xff01,
    "exclamsmall", 0xf721,
    "existential", 0x2203,
    "ezh", 0x0292,
    "ezhcaron", 0x01ef,
    "ezhcurl", 0x0293,
    "ezhreversed", 0x01b9,
    "ezhtail", 0x01ba,
    "f", 0x0066,
    "fadeva", 0x095e,
    "fagurmukhi", 0x0a5e,
    "fahrenheit", 0x2109,
    "fathaarabic", 0x064e,
    "fathalowarabic", 0x064e,
    "fathatanarabic", 0x064b,
    "fbopomofo", 0x3108,
    "fcircle", 0x24d5,
    "fdotaccent", 0x1e1f,
    "feharabic", 0x0641,
    "feharmenian", 0x0586,
    "fehfinalarabic", 0xfed2,
    "fehinitialarabic", 0xfed3,
    "fehmedialarabic", 0xfed4,
    "feicoptic", 0x03e5,
    "female", 0x2640,
    "ff", 0xfb00,
    "f_f", 0xfb00, // Fixes issue 11016.
    "ffi", 0xfb03,
    "ffl", 0xfb04,
    "fi", 0xfb01,
    "fifteencircle", 0x246e,
    "fifteenparen", 0x2482,
    "fifteenperiod", 0x2496,
    "figuredash", 0x2012,
    "filledbox", 0x25a0,
    "filledrect", 0x25ac,
    "finalkaf", 0x05da,
    "finalkafdagesh", 0xfb3a,
    "finalkafdageshhebrew", 0xfb3a,
    "finalkafhebrew", 0x05da,
    "finalmem", 0x05dd,
    "finalmemhebrew", 0x05dd,
    "finalnun", 0x05df,
    "finalnunhebrew", 0x05df,
    "finalpe", 0x05e3,
    "finalpehebrew", 0x05e3,
    "finaltsadi", 0x05e5,
    "finaltsadihebrew", 0x05e5,
    "firsttonechinese", 0x02c9,
    "fisheye", 0x25c9,
    "fitacyrillic", 0x0473,
    "five", 0x0035,
    "fivearabic", 0x0665,
    "fivebengali", 0x09eb,
    "fivecircle", 0x2464,
    "fivecircleinversesansserif", 0x278e,
    "fivedeva", 0x096b,
    "fiveeighths", 0x215d,
    "fivegujarati", 0x0aeb,
    "fivegurmukhi", 0x0a6b,
    "fivehackarabic", 0x0665,
    "fivehangzhou", 0x3025,
    "fiveideographicparen", 0x3224,
    "fiveinferior", 0x2085,
    "fivemonospace", 0xff15,
    "fiveoldstyle", 0xf735,
    "fiveparen", 0x2478,
    "fiveperiod", 0x248c,
    "fivepersian", 0x06f5,
    "fiveroman", 0x2174,
    "fivesuperior", 0x2075,
    "fivethai", 0x0e55,
    "fl", 0xfb02,
    "florin", 0x0192,
    "fmonospace", 0xff46,
    "fmsquare", 0x3399,
    "fofanthai", 0x0e1f,
    "fofathai", 0x0e1d,
    "fongmanthai", 0x0e4f,
    "forall", 0x2200,
    "four", 0x0034,
    "fourarabic", 0x0664,
    "fourbengali", 0x09ea,
    "fourcircle", 0x2463,
    "fourcircleinversesansserif", 0x278d,
    "fourdeva", 0x096a,
    "fourgujarati", 0x0aea,
    "fourgurmukhi", 0x0a6a,
    "fourhackarabic", 0x0664,
    "fourhangzhou", 0x3024,
    "fourideographicparen", 0x3223,
    "fourinferior", 0x2084,
    "fourmonospace", 0xff14,
    "fournumeratorbengali", 0x09f7,
    "fouroldstyle", 0xf734,
    "fourparen", 0x2477,
    "fourperiod", 0x248b,
    "fourpersian", 0x06f4,
    "fourroman", 0x2173,
    "foursuperior", 0x2074,
    "fourteencircle", 0x246d,
    "fourteenparen", 0x2481,
    "fourteenperiod", 0x2495,
    "fourthai", 0x0e54,
    "fourthtonechinese", 0x02cb,
    "fparen", 0x24a1,
    "fraction", 0x2044,
    "franc", 0x20a3,
    "g", 0x0067,
    "gabengali", 0x0997,
    "gacute", 0x01f5,
    "gadeva", 0x0917,
    "gafarabic", 0x06af,
    "gaffinalarabic", 0xfb93,
    "gafinitialarabic", 0xfb94,
    "gafmedialarabic", 0xfb95,
    "gagujarati", 0x0a97,
    "gagurmukhi", 0x0a17,
    "gahiragana", 0x304c,
    "gakatakana", 0x30ac,
    "gamma", 0x03b3,
    "gammalatinsmall", 0x0263,
    "gammasuperior", 0x02e0,
    "gangiacoptic", 0x03eb,
    "gbopomofo", 0x310d,
    "gbreve", 0x011f,
    "gcaron", 0x01e7,
    "gcedilla", 0x0123,
    "gcircle", 0x24d6,
    "gcircumflex", 0x011d,
    "gcommaaccent", 0x0123,
    "gdot", 0x0121,
    "gdotaccent", 0x0121,
    "gecyrillic", 0x0433,
    "gehiragana", 0x3052,
    "gekatakana", 0x30b2,
    "geometricallyequal", 0x2251,
    "gereshaccenthebrew", 0x059c,
    "gereshhebrew", 0x05f3,
    "gereshmuqdamhebrew", 0x059d,
    "germandbls", 0x00df,
    "gershayimaccenthebrew", 0x059e,
    "gershayimhebrew", 0x05f4,
    "getamark", 0x3013,
    "ghabengali", 0x0998,
    "ghadarmenian", 0x0572,
    "ghadeva", 0x0918,
    "ghagujarati", 0x0a98,
    "ghagurmukhi", 0x0a18,
    "ghainarabic", 0x063a,
    "ghainfinalarabic", 0xfece,
    "ghaininitialarabic", 0xfecf,
    "ghainmedialarabic", 0xfed0,
    "ghemiddlehookcyrillic", 0x0495,
    "ghestrokecyrillic", 0x0493,
    "gheupturncyrillic", 0x0491,
    "ghhadeva", 0x095a,
    "ghhagurmukhi", 0x0a5a,
    "ghook", 0x0260,
    "ghzsquare", 0x3393,
    "gihiragana", 0x304e,
    "gikatakana", 0x30ae,
    "gimarmenian", 0x0563,
    "gimel", 0x05d2,
    "gimeldagesh", 0xfb32,
    "gimeldageshhebrew", 0xfb32,
    "gimelhebrew", 0x05d2,
    "gjecyrillic", 0x0453,
    "glottalinvertedstroke", 0x01be,
    "glottalstop", 0x0294,
    "glottalstopinverted", 0x0296,
    "glottalstopmod", 0x02c0,
    "glottalstopreversed", 0x0295,
    "glottalstopreversedmod", 0x02c1,
    "glottalstopreversedsuperior", 0x02e4,
    "glottalstopstroke", 0x02a1,
    "glottalstopstrokereversed", 0x02a2,
    "gmacron", 0x1e21,
    "gmonospace", 0xff47,
    "gohiragana", 0x3054,
    "gokatakana", 0x30b4,
    "gparen", 0x24a2,
    "gpasquare", 0x33ac,
    "gradient", 0x2207,
    "grave", 0x0060,
    "gravebelowcmb", 0x0316,
    "gravecmb", 0x0300,
    "gravecomb", 0x0300,
    "gravedeva", 0x0953,
    "gravelowmod", 0x02ce,
    "gravemonospace", 0xff40,
    "gravetonecmb", 0x0340,
    "greater", 0x003e,
    "greaterequal", 0x2265,
    "greaterequalorless", 0x22db,
    "greatermonospace", 0xff1e,
    "greaterorequivalent", 0x2273,
    "greaterorless", 0x2277,
    "greateroverequal", 0x2267,
    "greatersmall", 0xfe65,
    "gscript", 0x0261,
    "gstroke", 0x01e5,
    "guhiragana", 0x3050,
    "guillemotleft", 0x00ab,
    "guillemotright", 0x00bb,
    "guilsinglleft", 0x2039,
    "guilsinglright", 0x203a,
    "gukatakana", 0x30b0,
    "guramusquare", 0x3318,
    "gysquare", 0x33c9,
    "h", 0x0068,
    "haabkhasiancyrillic", 0x04a9,
    "haaltonearabic", 0x06c1,
    "habengali", 0x09b9,
    "hadescendercyrillic", 0x04b3,
    "hadeva", 0x0939,
    "hagujarati", 0x0ab9,
    "hagurmukhi", 0x0a39,
    "haharabic", 0x062d,
    "hahfinalarabic", 0xfea2,
    "hahinitialarabic", 0xfea3,
    "hahiragana", 0x306f,
    "hahmedialarabic", 0xfea4,
    "haitusquare", 0x332a,
    "hakatakana", 0x30cf,
    "hakatakanahalfwidth", 0xff8a,
    "halantgurmukhi", 0x0a4d,
    "hamzaarabic", 0x0621,
    "hamzalowarabic", 0x0621,
    "hangulfiller", 0x3164,
    "hardsigncyrillic", 0x044a,
    "harpoonleftbarbup", 0x21bc,
    "harpoonrightbarbup", 0x21c0,
    "hasquare", 0x33ca,
    "hatafpatah", 0x05b2,
    "hatafpatah16", 0x05b2,
    "hatafpatah23", 0x05b2,
    "hatafpatah2f", 0x05b2,
    "hatafpatahhebrew", 0x05b2,
    "hatafpatahnarrowhebrew", 0x05b2,
    "hatafpatahquarterhebrew", 0x05b2,
    "hatafpatahwidehebrew", 0x05b2,
    "hatafqamats", 0x05b3,
    "hatafqamats1b", 0x05b3,
    "hatafqamats28", 0x05b3,
    "hatafqamats34", 0x05b3,
    "hatafqamatshebrew", 0x05b3,
    "hatafqamatsnarrowhebrew", 0x05b3,
    "hatafqamatsquarterhebrew", 0x05b3,
    "hatafqamatswidehebrew", 0x05b3,
    "hatafsegol", 0x05b1,
    "hatafsegol17", 0x05b1,
    "hatafsegol24", 0x05b1,
    "hatafsegol30", 0x05b1,
    "hatafsegolhebrew", 0x05b1,
    "hatafsegolnarrowhebrew", 0x05b1,
    "hatafsegolquarterhebrew", 0x05b1,
    "hatafsegolwidehebrew", 0x05b1,
    "hbar", 0x0127,
    "hbopomofo", 0x310f,
    "hbrevebelow", 0x1e2b,
    "hcedilla", 0x1e29,
    "hcircle", 0x24d7,
    "hcircumflex", 0x0125,
    "hdieresis", 0x1e27,
    "hdotaccent", 0x1e23,
    "hdotbelow", 0x1e25,
    "he", 0x05d4,
    "heart", 0x2665,
    "heartsuitblack", 0x2665,
    "heartsuitwhite", 0x2661,
    "hedagesh", 0xfb34,
    "hedageshhebrew", 0xfb34,
    "hehaltonearabic", 0x06c1,
    "heharabic", 0x0647,
    "hehebrew", 0x05d4,
    "hehfinalaltonearabic", 0xfba7,
    "hehfinalalttwoarabic", 0xfeea,
    "hehfinalarabic", 0xfeea,
    "hehhamzaabovefinalarabic", 0xfba5,
    "hehhamzaaboveisolatedarabic", 0xfba4,
    "hehinitialaltonearabic", 0xfba8,
    "hehinitialarabic", 0xfeeb,
    "hehiragana", 0x3078,
    "hehmedialaltonearabic", 0xfba9,
    "hehmedialarabic", 0xfeec,
    "heiseierasquare", 0x337b,
    "hekatakana", 0x30d8,
    "hekatakanahalfwidth", 0xff8d,
    "hekutaarusquare", 0x3336,
    "henghook", 0x0267,
    "herutusquare", 0x3339,
    "het", 0x05d7,
    "hethebrew", 0x05d7,
    "hhook", 0x0266,
    "hhooksuperior", 0x02b1,
    "hieuhacirclekorean", 0x327b,
    "hieuhaparenkorean", 0x321b,
    "hieuhcirclekorean", 0x326d,
    "hieuhkorean", 0x314e,
    "hieuhparenkorean", 0x320d,
    "hihiragana", 0x3072,
    "hikatakana", 0x30d2,
    "hikatakanahalfwidth", 0xff8b,
    "hiriq", 0x05b4,
    "hiriq14", 0x05b4,
    "hiriq21", 0x05b4,
    "hiriq2d", 0x05b4,
    "hiriqhebrew", 0x05b4,
    "hiriqnarrowhebrew", 0x05b4,
    "hiriqquarterhebrew", 0x05b4,
    "hiriqwidehebrew", 0x05b4,
    "hlinebelow", 0x1e96,
    "hmonospace", 0xff48,
    "hoarmenian", 0x0570,
    "hohipthai", 0x0e2b,
    "hohiragana", 0x307b,
    "hokatakana", 0x30db,
    "hokatakanahalfwidth", 0xff8e,
    "holam", 0x05b9,
    "holam19", 0x05b9,
    "holam26", 0x05b9,
    "holam32", 0x05b9,
    "holamhebrew", 0x05b9,
    "holamnarrowhebrew", 0x05b9,
    "holamquarterhebrew", 0x05b9,
    "holamwidehebrew", 0x05b9,
    "honokhukthai", 0x0e2e,
    "hookabovecomb", 0x0309,
    "hookcmb", 0x0309,
    "hookpalatalizedbelowcmb", 0x0321,
    "hookretroflexbelowcmb", 0x0322,
    "hoonsquare", 0x3342,
    "horicoptic", 0x03e9,
    "horizontalbar", 0x2015,
    "horncmb", 0x031b,
    "hotsprings", 0x2668,
    "house", 0x2302,
    "hparen", 0x24a3,
    "hsuperior", 0x02b0,
    "hturned", 0x0265,
    "huhiragana", 0x3075,
    "huiitosquare", 0x3333,
    "hukatakana", 0x30d5,
    "hukatakanahalfwidth", 0xff8c,
    "hungarumlaut", 0x02dd,
    "hungarumlautcmb", 0x030b,
    "hv", 0x0195,
    "hyphen", 0x002d,
    "hypheninferior", 0xf6e5,
    "hyphenmonospace", 0xff0d,
    "hyphensmall", 0xfe63,
    "hyphensuperior", 0xf6e6,
    "hyphentwo", 0x2010,
    "i", 0x0069,
    "iacute", 0x00ed,
    "iacyrillic", 0x044f,
    "ibengali", 0x0987,
    "ibopomofo", 0x3127,
    "ibreve", 0x012d,
    "icaron", 0x01d0,
    "icircle", 0x24d8,
    "icircumflex", 0x00ee,
    "icyrillic", 0x0456,
    "idblgrave", 0x0209,
    "ideographearthcircle", 0x328f,
    "ideographfirecircle", 0x328b,
    "ideographicallianceparen", 0x323f,
    "ideographiccallparen", 0x323a,
    "ideographiccentrecircle", 0x32a5,
    "ideographicclose", 0x3006,
    "ideographiccomma", 0x3001,
    "ideographiccommaleft", 0xff64,
    "ideographiccongratulationparen", 0x3237,
    "ideographiccorrectcircle", 0x32a3,
    "ideographicearthparen", 0x322f,
    "ideographicenterpriseparen", 0x323d,
    "ideographicexcellentcircle", 0x329d,
    "ideographicfestivalparen", 0x3240,
    "ideographicfinancialcircle", 0x3296,
    "ideographicfinancialparen", 0x3236,
    "ideographicfireparen", 0x322b,
    "ideographichaveparen", 0x3232,
    "ideographichighcircle", 0x32a4,
    "ideographiciterationmark", 0x3005,
    "ideographiclaborcircle", 0x3298,
    "ideographiclaborparen", 0x3238,
    "ideographicleftcircle", 0x32a7,
    "ideographiclowcircle", 0x32a6,
    "ideographicmedicinecircle", 0x32a9,
    "ideographicmetalparen", 0x322e,
    "ideographicmoonparen", 0x322a,
    "ideographicnameparen", 0x3234,
    "ideographicperiod", 0x3002,
    "ideographicprintcircle", 0x329e,
    "ideographicreachparen", 0x3243,
    "ideographicrepresentparen", 0x3239,
    "ideographicresourceparen", 0x323e,
    "ideographicrightcircle", 0x32a8,
    "ideographicsecretcircle", 0x3299,
    "ideographicselfparen", 0x3242,
    "ideographicsocietyparen", 0x3233,
    "ideographicspace", 0x3000,
    "ideographicspecialparen", 0x3235,
    "ideographicstockparen", 0x3231,
    "ideographicstudyparen", 0x323b,
    "ideographicsunparen", 0x3230,
    "ideographicsuperviseparen", 0x323c,
    "ideographicwaterparen", 0x322c,
    "ideographicwoodparen", 0x322d,
    "ideographiczero", 0x3007,
    "ideographmetalcircle", 0x328e,
    "ideographmooncircle", 0x328a,
    "ideographnamecircle", 0x3294,
    "ideographsuncircle", 0x3290,
    "ideographwatercircle", 0x328c,
    "ideographwoodcircle", 0x328d,
    "ideva", 0x0907,
    "idieresis", 0x00ef,
    "idieresisacute", 0x1e2f,
    "idieresiscyrillic", 0x04e5,
    "idotbelow", 0x1ecb,
    "iebrevecyrillic", 0x04d7,
    "iecyrillic", 0x0435,
    "ieungacirclekorean", 0x3275,
    "ieungaparenkorean", 0x3215,
    "ieungcirclekorean", 0x3267,
    "ieungkorean", 0x3147,
    "ieungparenkorean", 0x3207,
    "igrave", 0x00ec,
    "igujarati", 0x0a87,
    "igurmukhi", 0x0a07,
    "ihiragana", 0x3044,
    "ihookabove", 0x1ec9,
    "iibengali", 0x0988,
    "iicyrillic", 0x0438,
    "iideva", 0x0908,
    "iigujarati", 0x0a88,
    "iigurmukhi", 0x0a08,
    "iimatragurmukhi", 0x0a40,
    "iinvertedbreve", 0x020b,
    "iishortcyrillic", 0x0439,
    "iivowelsignbengali", 0x09c0,
    "iivowelsigndeva", 0x0940,
    "iivowelsigngujarati", 0x0ac0,
    "ij", 0x0133,
    "ikatakana", 0x30a4,
    "ikatakanahalfwidth", 0xff72,
    "ikorean", 0x3163,
    "ilde", 0x02dc,
    "iluyhebrew", 0x05ac,
    "imacron", 0x012b,
    "imacroncyrillic", 0x04e3,
    "imageorapproximatelyequal", 0x2253,
    "imatragurmukhi", 0x0a3f,
    "imonospace", 0xff49,
    "increment", 0x2206,
    "infinity", 0x221e,
    "iniarmenian", 0x056b,
    "integral", 0x222b,
    "integralbottom", 0x2321,
    "integralbt", 0x2321,
    "integralex", 0xf8f5,
    "integraltop", 0x2320,
    "integraltp", 0x2320,
    "intersection", 0x2229,
    "intisquare", 0x3305,
    "invbullet", 0x25d8,
    "invcircle", 0x25d9,
    "invsmileface", 0x263b,
    "iocyrillic", 0x0451,
    "iogonek", 0x012f,
    "iota", 0x03b9,
    "iotadieresis", 0x03ca,
    "iotadieresistonos", 0x0390,
    "iotalatin", 0x0269,
    "iotatonos", 0x03af,
    "iparen", 0x24a4,
    "irigurmukhi", 0x0a72,
    "ismallhiragana", 0x3043,
    "ismallkatakana", 0x30a3,
    "ismallkatakanahalfwidth", 0xff68,
    "issharbengali", 0x09fa,
    "istroke", 0x0268,
    "isuperior", 0xf6ed,
    "iterationhiragana", 0x309d,
    "iterationkatakana", 0x30fd,
    "itilde", 0x0129,
    "itildebelow", 0x1e2d,
    "iubopomofo", 0x3129,
    "iucyrillic", 0x044e,
    "ivowelsignbengali", 0x09bf,
    "ivowelsigndeva", 0x093f,
    "ivowelsigngujarati", 0x0abf,
    "izhitsacyrillic", 0x0475,
    "izhitsadblgravecyrillic", 0x0477,
    "j", 0x006a,
    "jaarmenian", 0x0571,
    "jabengali", 0x099c,
    "jadeva", 0x091c,
    "jagujarati", 0x0a9c,
    "jagurmukhi", 0x0a1c,
    "jbopomofo", 0x3110,
    "jcaron", 0x01f0,
    "jcircle", 0x24d9,
    "jcircumflex", 0x0135,
    "jcrossedtail", 0x029d,
    "jdotlessstroke", 0x025f,
    "jecyrillic", 0x0458,
    "jeemarabic", 0x062c,
    "jeemfinalarabic", 0xfe9e,
    "jeeminitialarabic", 0xfe9f,
    "jeemmedialarabic", 0xfea0,
    "jeharabic", 0x0698,
    "jehfinalarabic", 0xfb8b,
    "jhabengali", 0x099d,
    "jhadeva", 0x091d,
    "jhagujarati", 0x0a9d,
    "jhagurmukhi", 0x0a1d,
    "jheharmenian", 0x057b,
    "jis", 0x3004,
    "jmonospace", 0xff4a,
    "jparen", 0x24a5,
    "jsuperior", 0x02b2,
    "k", 0x006b,
    "kabashkircyrillic", 0x04a1,
    "kabengali", 0x0995,
    "kacute", 0x1e31,
    "kacyrillic", 0x043a,
    "kadescendercyrillic", 0x049b,
    "kadeva", 0x0915,
    "kaf", 0x05db,
    "kafarabic", 0x0643,
    "kafdagesh", 0xfb3b,
    "kafdageshhebrew", 0xfb3b,
    "kaffinalarabic", 0xfeda,
    "kafhebrew", 0x05db,
    "kafinitialarabic", 0xfedb,
    "kafmedialarabic", 0xfedc,
    "kafrafehebrew", 0xfb4d,
    "kagujarati", 0x0a95,
    "kagurmukhi", 0x0a15,
    "kahiragana", 0x304b,
    "kahookcyrillic", 0x04c4,
    "kakatakana", 0x30ab,
    "kakatakanahalfwidth", 0xff76,
    "kappa", 0x03ba,
    "kappasymbolgreek", 0x03f0,
    "kapyeounmieumkorean", 0x3171,
    "kapyeounphieuphkorean", 0x3184,
    "kapyeounpieupkorean", 0x3178,
    "kapyeounssangpieupkorean", 0x3179,
    "karoriisquare", 0x330d,
    "kashidaautoarabic", 0x0640,
    "kashidaautonosidebearingarabic", 0x0640,
    "kasmallkatakana", 0x30f5,
    "kasquare", 0x3384,
    "kasraarabic", 0x0650,
    "kasratanarabic", 0x064d,
    "kastrokecyrillic", 0x049f,
    "katahiraprolongmarkhalfwidth", 0xff70,
    "kaverticalstrokecyrillic", 0x049d,
    "kbopomofo", 0x310e,
    "kcalsquare", 0x3389,
    "kcaron", 0x01e9,
    "kcedilla", 0x0137,
    "kcircle", 0x24da,
    "kcommaaccent", 0x0137,
    "kdotbelow", 0x1e33,
    "keharmenian", 0x0584,
    "kehiragana", 0x3051,
    "kekatakana", 0x30b1,
    "kekatakanahalfwidth", 0xff79,
    "kenarmenian", 0x056f,
    "kesmallkatakana", 0x30f6,
    "kgreenlandic", 0x0138,
    "khabengali", 0x0996,
    "khacyrillic", 0x0445,
    "khadeva", 0x0916,
    "khagujarati", 0x0a96,
    "khagurmukhi", 0x0a16,
    "khaharabic", 0x062e,
    "khahfinalarabic", 0xfea6,
    "khahinitialarabic", 0xfea7,
    "khahmedialarabic", 0xfea8,
    "kheicoptic", 0x03e7,
    "khhadeva", 0x0959,
    "khhagurmukhi", 0x0a59,
    "khieukhacirclekorean", 0x3278,
    "khieukhaparenkorean", 0x3218,
    "khieukhcirclekorean", 0x326a,
    "khieukhkorean", 0x314b,
    "khieukhparenkorean", 0x320a,
    "khokhaithai", 0x0e02,
    "khokhonthai", 0x0e05,
    "khokhuatthai", 0x0e03,
    "khokhwaithai", 0x0e04,
    "khomutthai", 0x0e5b,
    "khook", 0x0199,
    "khorakhangthai", 0x0e06,
    "khzsquare", 0x3391,
    "kihiragana", 0x304d,
    "kikatakana", 0x30ad,
    "kikatakanahalfwidth", 0xff77,
    "kiroguramusquare", 0x3315,
    "kiromeetorusquare", 0x3316,
    "kirosquare", 0x3314,
    "kiyeokacirclekorean", 0x326e,
    "kiyeokaparenkorean", 0x320e,
    "kiyeokcirclekorean", 0x3260,
    "kiyeokkorean", 0x3131,
    "kiyeokparenkorean", 0x3200,
    "kiyeoksioskorean", 0x3133,
    "kjecyrillic", 0x045c,
    "klinebelow", 0x1e35,
    "klsquare", 0x3398,
    "kmcubedsquare", 0x33a6,
    "kmonospace", 0xff4b,
    "kmsquaredsquare", 0x33a2,
    "kohiragana", 0x3053,
    "kohmsquare", 0x33c0,
    "kokaithai", 0x0e01,
    "kokatakana", 0x30b3,
    "kokatakanahalfwidth", 0xff7a,
    "kooposquare", 0x331e,
    "koppacyrillic", 0x0481,
    "koreanstandardsymbol", 0x327f,
    "koroniscmb", 0x0343,
    "kparen", 0x24a6,
    "kpasquare", 0x33aa,
    "ksicyrillic", 0x046f,
    "ktsquare", 0x33cf,
    "kturned", 0x029e,
    "kuhiragana", 0x304f,
    "kukatakana", 0x30af,
    "kukatakanahalfwidth", 0xff78,
    "kvsquare", 0x33b8,
    "kwsquare", 0x33be,
    "l", 0x006c,
    "labengali", 0x09b2,
    "lacute", 0x013a,
    "ladeva", 0x0932,
    "lagujarati", 0x0ab2,
    "lagurmukhi", 0x0a32,
    "lakkhangyaothai", 0x0e45,
    "lamaleffinalarabic", 0xfefc,
    "lamalefhamzaabovefinalarabic", 0xfef8,
    "lamalefhamzaaboveisolatedarabic", 0xfef7,
    "lamalefhamzabelowfinalarabic", 0xfefa,
    "lamalefhamzabelowisolatedarabic", 0xfef9,
    "lamalefisolatedarabic", 0xfefb,
    "lamalefmaddaabovefinalarabic", 0xfef6,
    "lamalefmaddaaboveisolatedarabic", 0xfef5,
    "lamarabic", 0x0644,
    "lambda", 0x03bb,
    "lambdastroke", 0x019b,
    "lamed", 0x05dc,
    "lameddagesh", 0xfb3c,
    "lameddageshhebrew", 0xfb3c,
    "lamedhebrew", 0x05dc,
    "lamfinalarabic", 0xfede,
    "lamhahinitialarabic", 0xfcca,
    "laminitialarabic", 0xfedf,
    "lamjeeminitialarabic", 0xfcc9,
    "lamkhahinitialarabic", 0xfccb,
    "lamlamhehisolatedarabic", 0xfdf2,
    "lammedialarabic", 0xfee0,
    "lammeemhahinitialarabic", 0xfd88,
    "lammeeminitialarabic", 0xfccc,
    "largecircle", 0x25ef,
    "lbar", 0x019a,
    "lbelt", 0x026c,
    "lbopomofo", 0x310c,
    "lcaron", 0x013e,
    "lcedilla", 0x013c,
    "lcircle", 0x24db,
    "lcircumflexbelow", 0x1e3d,
    "lcommaaccent", 0x013c,
    "ldot", 0x0140,
    "ldotaccent", 0x0140,
    "ldotbelow", 0x1e37,
    "ldotbelowmacron", 0x1e39,
    "leftangleabovecmb", 0x031a,
    "lefttackbelowcmb", 0x0318,
    "less", 0x003c,
    "lessequal", 0x2264,
    "lessequalorgreater", 0x22da,
    "lessmonospace", 0xff1c,
    "lessorequivalent", 0x2272,
    "lessorgreater", 0x2276,
    "lessoverequal", 0x2266,
    "lesssmall", 0xfe64,
    "lezh", 0x026e,
    "lfblock", 0x258c,
    "lhookretroflex", 0x026d,
    "lira", 0x20a4,
    "liwnarmenian", 0x056c,
    "lj", 0x01c9,
    "ljecyrillic", 0x0459,
    "ll", 0xf6c0,
    "lladeva", 0x0933,
    "llagujarati", 0x0ab3,
    "llinebelow", 0x1e3b,
    "llladeva", 0x0934,
    "llvocalicbengali", 0x09e1,
    "llvocalicdeva", 0x0961,
    "llvocalicvowelsignbengali", 0x09e3,
    "llvocalicvowelsigndeva", 0x0963,
    "lmiddletilde", 0x026b,
    "lmonospace", 0xff4c,
    "lmsquare", 0x33d0,
    "lochulathai", 0x0e2c,
    "logicaland", 0x2227,
    "logicalnot", 0x00ac,
    "logicalnotreversed", 0x2310,
    "logicalor", 0x2228,
    "lolingthai", 0x0e25,
    "longs", 0x017f,
    "lowlinecenterline", 0xfe4e,
    "lowlinecmb", 0x0332,
    "lowlinedashed", 0xfe4d,
    "lozenge", 0x25ca,
    "lparen", 0x24a7,
    "lslash", 0x0142,
    "lsquare", 0x2113,
    "lsuperior", 0xf6ee,
    "ltshade", 0x2591,
    "luthai", 0x0e26,
    "lvocalicbengali", 0x098c,
    "lvocalicdeva", 0x090c,
    "lvocalicvowelsignbengali", 0x09e2,
    "lvocalicvowelsigndeva", 0x0962,
    "lxsquare", 0x33d3,
    "m", 0x006d,
    "mabengali", 0x09ae,
    "macron", 0x00af,
    "macronbelowcmb", 0x0331,
    "macroncmb", 0x0304,
    "macronlowmod", 0x02cd,
    "macronmonospace", 0xffe3,
    "macute", 0x1e3f,
    "madeva", 0x092e,
    "magujarati", 0x0aae,
    "magurmukhi", 0x0a2e,
    "mahapakhhebrew", 0x05a4,
    "mahapakhlefthebrew", 0x05a4,
    "mahiragana", 0x307e,
    "maichattawalowleftthai", 0xf895,
    "maichattawalowrightthai", 0xf894,
    "maichattawathai", 0x0e4b,
    "maichattawaupperleftthai", 0xf893,
    "maieklowleftthai", 0xf88c,
    "maieklowrightthai", 0xf88b,
    "maiekthai", 0x0e48,
    "maiekupperleftthai", 0xf88a,
    "maihanakatleftthai", 0xf884,
    "maihanakatthai", 0x0e31,
    "maitaikhuleftthai", 0xf889,
    "maitaikhuthai", 0x0e47,
    "maitholowleftthai", 0xf88f,
    "maitholowrightthai", 0xf88e,
    "maithothai", 0x0e49,
    "maithoupperleftthai", 0xf88d,
    "maitrilowleftthai", 0xf892,
    "maitrilowrightthai", 0xf891,
    "maitrithai", 0x0e4a,
    "maitriupperleftthai", 0xf890,
    "maiyamokthai", 0x0e46,
    "makatakana", 0x30de,
    "makatakanahalfwidth", 0xff8f,
    "male", 0x2642,
    "mansyonsquare", 0x3347,
    "maqafhebrew", 0x05be,
    "mars", 0x2642,
    "masoracirclehebrew", 0x05af,
    "masquare", 0x3383,
    "mbopomofo", 0x3107,
    "mbsquare", 0x33d4,
    "mcircle", 0x24dc,
    "mcubedsquare", 0x33a5,
    "mdotaccent", 0x1e41,
    "mdotbelow", 0x1e43,
    "meemarabic", 0x0645,
    "meemfinalarabic", 0xfee2,
    "meeminitialarabic", 0xfee3,
    "meemmedialarabic", 0xfee4,
    "meemmeeminitialarabic", 0xfcd1,
    "meemmeemisolatedarabic", 0xfc48,
    "meetorusquare", 0x334d,
    "mehiragana", 0x3081,
    "meizierasquare", 0x337e,
    "mekatakana", 0x30e1,
    "mekatakanahalfwidth", 0xff92,
    "mem", 0x05de,
    "memdagesh", 0xfb3e,
    "memdageshhebrew", 0xfb3e,
    "memhebrew", 0x05de,
    "menarmenian", 0x0574,
    "merkhahebrew", 0x05a5,
    "merkhakefulahebrew", 0x05a6,
    "merkhakefulalefthebrew", 0x05a6,
    "merkhalefthebrew", 0x05a5,
    "mhook", 0x0271,
    "mhzsquare", 0x3392,
    "middledotkatakanahalfwidth", 0xff65,
    "middot", 0x00b7,
    "mieumacirclekorean", 0x3272,
    "mieumaparenkorean", 0x3212,
    "mieumcirclekorean", 0x3264,
    "mieumkorean", 0x3141,
    "mieumpansioskorean", 0x3170,
    "mieumparenkorean", 0x3204,
    "mieumpieupkorean", 0x316e,
    "mieumsioskorean", 0x316f,
    "mihiragana", 0x307f,
    "mikatakana", 0x30df,
    "mikatakanahalfwidth", 0xff90,
    "minus", 0x2212,
    "minusbelowcmb", 0x0320,
    "minuscircle", 0x2296,
    "minusmod", 0x02d7,
    "minusplus", 0x2213,
    "minute", 0x2032,
    "miribaarusquare", 0x334a,
    "mirisquare", 0x3349,
    "mlonglegturned", 0x0270,
    "mlsquare", 0x3396,
    "mmcubedsquare", 0x33a3,
    "mmonospace", 0xff4d,
    "mmsquaredsquare", 0x339f,
    "mohiragana", 0x3082,
    "mohmsquare", 0x33c1,
    "mokatakana", 0x30e2,
    "mokatakanahalfwidth", 0xff93,
    "molsquare", 0x33d6,
    "momathai", 0x0e21,
    "moverssquare", 0x33a7,
    "moverssquaredsquare", 0x33a8,
    "mparen", 0x24a8,
    "mpasquare", 0x33ab,
    "mssquare", 0x33b3,
    "msuperior", 0xf6ef,
    "mturned", 0x026f,
    "mu", 0x00b5,
    "mu1", 0x00b5,
    "muasquare", 0x3382,
    "muchgreater", 0x226b,
    "muchless", 0x226a,
    "mufsquare", 0x338c,
    "mugreek", 0x03bc,
    "mugsquare", 0x338d,
    "muhiragana", 0x3080,
    "mukatakana", 0x30e0,
    "mukatakanahalfwidth", 0xff91,
    "mulsquare", 0x3395,
    "multiply", 0x00d7,
    "mumsquare", 0x339b,
    "munahhebrew", 0x05a3,
    "munahlefthebrew", 0x05a3,
    "musicalnote", 0x266a,
    "musicalnotedbl", 0x266b,
    "musicflatsign", 0x266d,
    "musicsharpsign", 0x266f,
    "mussquare", 0x33b2,
    "muvsquare", 0x33b6,
    "muwsquare", 0x33bc,
    "mvmegasquare", 0x33b9,
    "mvsquare", 0x33b7,
    "mwmegasquare", 0x33bf,
    "mwsquare", 0x33bd,
    "n", 0x006e,
    "nabengali", 0x09a8,
    "nabla", 0x2207,
    "nacute", 0x0144,
    "nadeva", 0x0928,
    "nagujarati", 0x0aa8,
    "nagurmukhi", 0x0a28,
    "nahiragana", 0x306a,
    "nakatakana", 0x30ca,
    "nakatakanahalfwidth", 0xff85,
    "napostrophe", 0x0149,
    "nasquare", 0x3381,
    "nbopomofo", 0x310b,
    "nbspace", 0x00a0,
    "ncaron", 0x0148,
    "ncedilla", 0x0146,
    "ncircle", 0x24dd,
    "ncircumflexbelow", 0x1e4b,
    "ncommaaccent", 0x0146,
    "ndotaccent", 0x1e45,
    "ndotbelow", 0x1e47,
    "nehiragana", 0x306d,
    "nekatakana", 0x30cd,
    "nekatakanahalfwidth", 0xff88,
    "newsheqelsign", 0x20aa,
    "nfsquare", 0x338b,
    "ngabengali", 0x0999,
    "ngadeva", 0x0919,
    "ngagujarati", 0x0a99,
    "ngagurmukhi", 0x0a19,
    "ngonguthai", 0x0e07,
    "nhiragana", 0x3093,
    "nhookleft", 0x0272,
    "nhookretroflex", 0x0273,
    "nieunacirclekorean", 0x326f,
    "nieunaparenkorean", 0x320f,
    "nieuncieuckorean", 0x3135,
    "nieuncirclekorean", 0x3261,
    "nieunhieuhkorean", 0x3136,
    "nieunkorean", 0x3134,
    "nieunpansioskorean", 0x3168,
    "nieunparenkorean", 0x3201,
    "nieunsioskorean", 0x3167,
    "nieuntikeutkorean", 0x3166,
    "nihiragana", 0x306b,
    "nikatakana", 0x30cb,
    "nikatakanahalfwidth", 0xff86,
    "nikhahitleftthai", 0xf899,
    "nikhahitthai", 0x0e4d,
    "nine", 0x0039,
    "ninearabic", 0x0669,
    "ninebengali", 0x09ef,
    "ninecircle", 0x2468,
    "ninecircleinversesansserif", 0x2792,
    "ninedeva", 0x096f,
    "ninegujarati", 0x0aef,
    "ninegurmukhi", 0x0a6f,
    "ninehackarabic", 0x0669,
    "ninehangzhou", 0x3029,
    "nineideographicparen", 0x3228,
    "nineinferior", 0x2089,
    "ninemonospace", 0xff19,
    "nineoldstyle", 0xf739,
    "nineparen", 0x247c,
    "nineperiod", 0x2490,
    "ninepersian", 0x06f9,
    "nineroman", 0x2178,
    "ninesuperior", 0x2079,
    "nineteencircle", 0x2472,
    "nineteenparen", 0x2486,
    "nineteenperiod", 0x249a,
    "ninethai", 0x0e59,
    "nj", 0x01cc,
    "njecyrillic", 0x045a,
    "nkatakana", 0x30f3,
    "nkatakanahalfwidth", 0xff9d,
    "nlegrightlong", 0x019e,
    "nlinebelow", 0x1e49,
    "nmonospace", 0xff4e,
    "nmsquare", 0x339a,
    "nnabengali", 0x09a3,
    "nnadeva", 0x0923,
    "nnagujarati", 0x0aa3,
    "nnagurmukhi", 0x0a23,
    "nnnadeva", 0x0929,
    "nohiragana", 0x306e,
    "nokatakana", 0x30ce,
    "nokatakanahalfwidth", 0xff89,
    "nonbreakingspace", 0x00a0,
    "nonenthai", 0x0e13,
    "nonuthai", 0x0e19,
    "noonarabic", 0x0646,
    "noonfinalarabic", 0xfee6,
    "noonghunnaarabic", 0x06ba,
    "noonghunnafinalarabic", 0xfb9f,
    "nooninitialarabic", 0xfee7,
    "noonjeeminitialarabic", 0xfcd2,
    "noonjeemisolatedarabic", 0xfc4b,
    "noonmedialarabic", 0xfee8,
    "noonmeeminitialarabic", 0xfcd5,
    "noonmeemisolatedarabic", 0xfc4e,
    "noonnoonfinalarabic", 0xfc8d,
    "notcontains", 0x220c,
    "notelement", 0x2209,
    "notelementof", 0x2209,
    "notequal", 0x2260,
    "notgreater", 0x226f,
    "notgreaternorequal", 0x2271,
    "notgreaternorless", 0x2279,
    "notidentical", 0x2262,
    "notless", 0x226e,
    "notlessnorequal", 0x2270,
    "notparallel", 0x2226,
    "notprecedes", 0x2280,
    "notsubset", 0x2284,
    "notsucceeds", 0x2281,
    "notsuperset", 0x2285,
    "nowarmenian", 0x0576,
    "nparen", 0x24a9,
    "nssquare", 0x33b1,
    "nsuperior", 0x207f,
    "ntilde", 0x00f1,
    "nu", 0x03bd,
    "nuhiragana", 0x306c,
    "nukatakana", 0x30cc,
    "nukatakanahalfwidth", 0xff87,
    "nuktabengali", 0x09bc,
    "nuktadeva", 0x093c,
    "nuktagujarati", 0x0abc,
    "nuktagurmukhi", 0x0a3c,
    "numbersign", 0x0023,
    "numbersignmonospace", 0xff03,
    "numbersignsmall", 0xfe5f,
    "numeralsigngreek", 0x0374,
    "numeralsignlowergreek", 0x0375,
    "numero", 0x2116,
    "nun", 0x05e0,
    "nundagesh", 0xfb40,
    "nundageshhebrew", 0xfb40,
    "nunhebrew", 0x05e0,
    "nvsquare", 0x33b5,
    "nwsquare", 0x33bb,
    "nyabengali", 0x099e,
    "nyadeva", 0x091e,
    "nyagujarati", 0x0a9e,
    "nyagurmukhi", 0x0a1e,
    "o", 0x006f,
    "oacute", 0x00f3,
    "oangthai", 0x0e2d,
    "obarred", 0x0275,
    "obarredcyrillic", 0x04e9,
    "obarreddieresiscyrillic", 0x04eb,
    "obengali", 0x0993,
    "obopomofo", 0x311b,
    "obreve", 0x014f,
    "ocandradeva", 0x0911,
    "ocandragujarati", 0x0a91,
    "ocandravowelsigndeva", 0x0949,
    "ocandravowelsigngujarati", 0x0ac9,
    "ocaron", 0x01d2,
    "ocircle", 0x24de,
    "ocircumflex", 0x00f4,
    "ocircumflexacute", 0x1ed1,
    "ocircumflexdotbelow", 0x1ed9,
    "ocircumflexgrave", 0x1ed3,
    "ocircumflexhookabove", 0x1ed5,
    "ocircumflextilde", 0x1ed7,
    "ocyrillic", 0x043e,
    "odblacute", 0x0151,
    "odblgrave", 0x020d,
    "odeva", 0x0913,
    "odieresis", 0x00f6,
    "odieresiscyrillic", 0x04e7,
    "odotbelow", 0x1ecd,
    "oe", 0x0153,
    "oekorean", 0x315a,
    "ogonek", 0x02db,
    "ogonekcmb", 0x0328,
    "ograve", 0x00f2,
    "ogujarati", 0x0a93,
    "oharmenian", 0x0585,
    "ohiragana", 0x304a,
    "ohookabove", 0x1ecf,
    "ohorn", 0x01a1,
    "ohornacute", 0x1edb,
    "ohorndotbelow", 0x1ee3,
    "ohorngrave", 0x1edd,
    "ohornhookabove", 0x1edf,
    "ohorntilde", 0x1ee1,
    "ohungarumlaut", 0x0151,
    "oi", 0x01a3,
    "oinvertedbreve", 0x020f,
    "okatakana", 0x30aa,
    "okatakanahalfwidth", 0xff75,
    "okorean", 0x3157,
    "olehebrew", 0x05ab,
    "omacron", 0x014d,
    "omacronacute", 0x1e53,
    "omacrongrave", 0x1e51,
    "omdeva", 0x0950,
    "omega", 0x03c9,
    "omega1", 0x03d6,
    "omegacyrillic", 0x0461,
    "omegalatinclosed", 0x0277,
    "omegaroundcyrillic", 0x047b,
    "omegatitlocyrillic", 0x047d,
    "omegatonos", 0x03ce,
    "omgujarati", 0x0ad0,
    "omicron", 0x03bf,
    "omicrontonos", 0x03cc,
    "omonospace", 0xff4f,
    "one", 0x0031,
    "onearabic", 0x0661,
    "onebengali", 0x09e7,
    "onecircle", 0x2460,
    "onecircleinversesansserif", 0x278a,
    "onedeva", 0x0967,
    "onedotenleader", 0x2024,
    "oneeighth", 0x215b,
    "onefitted", 0xf6dc,
    "onegujarati", 0x0ae7,
    "onegurmukhi", 0x0a67,
    "onehackarabic", 0x0661,
    "onehalf", 0x00bd,
    "onehangzhou", 0x3021,
    "oneideographicparen", 0x3220,
    "oneinferior", 0x2081,
    "onemonospace", 0xff11,
    "onenumeratorbengali", 0x09f4,
    "oneoldstyle", 0xf731,
    "oneparen", 0x2474,
    "oneperiod", 0x2488,
    "onepersian", 0x06f1,
    "onequarter", 0x00bc,
    "oneroman", 0x2170,
    "onesuperior", 0x00b9,
    "onethai", 0x0e51,
    "onethird", 0x2153,
    "oogonek", 0x01eb,
    "oogonekmacron", 0x01ed,
    "oogurmukhi", 0x0a13,
    "oomatragurmukhi", 0x0a4b,
    "oopen", 0x0254,
    "oparen", 0x24aa,
    "openbullet", 0x25e6,
    "option", 0x2325,
    "ordfeminine", 0x00aa,
    "ordmasculine", 0x00ba,
    "orthogonal", 0x221f,
    "oshortdeva", 0x0912,
    "oshortvowelsigndeva", 0x094a,
    "oslash", 0x00f8,
    "oslashacute", 0x01ff,
    "osmallhiragana", 0x3049,
    "osmallkatakana", 0x30a9,
    "osmallkatakanahalfwidth", 0xff6b,
    "ostrokeacute", 0x01ff,
    "osuperior", 0xf6f0,
    "otcyrillic", 0x047f,
    "otilde", 0x00f5,
    "otildeacute", 0x1e4d,
    "otildedieresis", 0x1e4f,
    "oubopomofo", 0x3121,
    "overline", 0x203e,
    "overlinecenterline", 0xfe4a,
    "overlinecmb", 0x0305,
    "overlinedashed", 0xfe49,
    "overlinedblwavy", 0xfe4c,
    "overlinewavy", 0xfe4b,
    "overscore", 0x00af,
    "ovowelsignbengali", 0x09cb,
    "ovowelsigndeva", 0x094b,
    "ovowelsigngujarati", 0x0acb,
    "p", 0x0070,
    "paampssquare", 0x3380,
    "paasentosquare", 0x332b,
    "pabengali", 0x09aa,
    "pacute", 0x1e55,
    "padeva", 0x092a,
    "pagedown", 0x21df,
    "pageup", 0x21de,
    "pagujarati", 0x0aaa,
    "pagurmukhi", 0x0a2a,
    "pahiragana", 0x3071,
    "paiyannoithai", 0x0e2f,
    "pakatakana", 0x30d1,
    "palatalizationcyrilliccmb", 0x0484,
    "palochkacyrillic", 0x04c0,
    "pansioskorean", 0x317f,
    "paragraph", 0x00b6,
    "parallel", 0x2225,
    "parenleft", 0x0028,
    "parenleftaltonearabic", 0xfd3e,
    "parenleftbt", 0xf8ed,
    "parenleftex", 0xf8ec,
    "parenleftinferior", 0x208d,
    "parenleftmonospace", 0xff08,
    "parenleftsmall", 0xfe59,
    "parenleftsuperior", 0x207d,
    "parenlefttp", 0xf8eb,
    "parenleftvertical", 0xfe35,
    "parenright", 0x0029,
    "parenrightaltonearabic", 0xfd3f,
    "parenrightbt", 0xf8f8,
    "parenrightex", 0xf8f7,
    "parenrightinferior", 0x208e,
    "parenrightmonospace", 0xff09,
    "parenrightsmall", 0xfe5a,
    "parenrightsuperior", 0x207e,
    "parenrighttp", 0xf8f6,
    "parenrightvertical", 0xfe36,
    "partialdiff", 0x2202,
    "paseqhebrew", 0x05c0,
    "pashtahebrew", 0x0599,
    "pasquare", 0x33a9,
    "patah", 0x05b7,
    "patah11", 0x05b7,
    "patah1d", 0x05b7,
    "patah2a", 0x05b7,
    "patahhebrew", 0x05b7,
    "patahnarrowhebrew", 0x05b7,
    "patahquarterhebrew", 0x05b7,
    "patahwidehebrew", 0x05b7,
    "pazerhebrew", 0x05a1,
    "pbopomofo", 0x3106,
    "pcircle", 0x24df,
    "pdotaccent", 0x1e57,
    "pe", 0x05e4,
    "pecyrillic", 0x043f,
    "pedagesh", 0xfb44,
    "pedageshhebrew", 0xfb44,
    "peezisquare", 0x333b,
    "pefinaldageshhebrew", 0xfb43,
    "peharabic", 0x067e,
    "peharmenian", 0x057a,
    "pehebrew", 0x05e4,
    "pehfinalarabic", 0xfb57,
    "pehinitialarabic", 0xfb58,
    "pehiragana", 0x307a,
    "pehmedialarabic", 0xfb59,
    "pekatakana", 0x30da,
    "pemiddlehookcyrillic", 0x04a7,
    "perafehebrew", 0xfb4e,
    "percent", 0x0025,
    "percentarabic", 0x066a,
    "percentmonospace", 0xff05,
    "percentsmall", 0xfe6a,
    "period", 0x002e,
    "periodarmenian", 0x0589,
    "periodcentered", 0x00b7,
    "periodhalfwidth", 0xff61,
    "periodinferior", 0xf6e7,
    "periodmonospace", 0xff0e,
    "periodsmall", 0xfe52,
    "periodsuperior", 0xf6e8,
    "perispomenigreekcmb", 0x0342,
    "perpendicular", 0x22a5,
    "perthousand", 0x2030,
    "peseta", 0x20a7,
    "pfsquare", 0x338a,
    "phabengali", 0x09ab,
    "phadeva", 0x092b,
    "phagujarati", 0x0aab,
    "phagurmukhi", 0x0a2b,
    "phi", 0x03c6,
    "phi1", 0x03d5,
    "phieuphacirclekorean", 0x327a,
    "phieuphaparenkorean", 0x321a,
    "phieuphcirclekorean", 0x326c,
    "phieuphkorean", 0x314d,
    "phieuphparenkorean", 0x320c,
    "philatin", 0x0278,
    "phinthuthai", 0x0e3a,
    "phisymbolgreek", 0x03d5,
    "phook", 0x01a5,
    "phophanthai", 0x0e1e,
    "phophungthai", 0x0e1c,
    "phosamphaothai", 0x0e20,
    "pi", 0x03c0,
    "pieupacirclekorean", 0x3273,
    "pieupaparenkorean", 0x3213,
    "pieupcieuckorean", 0x3176,
    "pieupcirclekorean", 0x3265,
    "pieupkiyeokkorean", 0x3172,
    "pieupkorean", 0x3142,
    "pieupparenkorean", 0x3205,
    "pieupsioskiyeokkorean", 0x3174,
    "pieupsioskorean", 0x3144,
    "pieupsiostikeutkorean", 0x3175,
    "pieupthieuthkorean", 0x3177,
    "pieuptikeutkorean", 0x3173,
    "pihiragana", 0x3074,
    "pikatakana", 0x30d4,
    "pisymbolgreek", 0x03d6,
    "piwrarmenian", 0x0583,
    "plus", 0x002b,
    "plusbelowcmb", 0x031f,
    "pluscircle", 0x2295,
    "plusminus", 0x00b1,
    "plusmod", 0x02d6,
    "plusmonospace", 0xff0b,
    "plussmall", 0xfe62,
    "plussuperior", 0x207a,
    "pmonospace", 0xff50,
    "pmsquare", 0x33d8,
    "pohiragana", 0x307d,
    "pointingindexdownwhite", 0x261f,
    "pointingindexleftwhite", 0x261c,
    "pointingindexrightwhite", 0x261e,
    "pointingindexupwhite", 0x261d,
    "pokatakana", 0x30dd,
    "poplathai", 0x0e1b,
    "postalmark", 0x3012,
    "postalmarkface", 0x3020,
    "pparen", 0x24ab,
    "precedes", 0x227a,
    "prescription", 0x211e,
    "primemod", 0x02b9,
    "primereversed", 0x2035,
    "product", 0x220f,
    "projective", 0x2305,
    "prolongedkana", 0x30fc,
    "propellor", 0x2318,
    "propersubset", 0x2282,
    "propersuperset", 0x2283,
    "proportion", 0x2237,
    "proportional", 0x221d,
    "psi", 0x03c8,
    "psicyrillic", 0x0471,
    "psilipneumatacyrilliccmb", 0x0486,
    "pssquare", 0x33b0,
    "puhiragana", 0x3077,
    "pukatakana", 0x30d7,
    "pvsquare", 0x33b4,
    "pwsquare", 0x33ba,
    "q", 0x0071,
    "qadeva", 0x0958,
    "qadmahebrew", 0x05a8,
    "qafarabic", 0x0642,
    "qaffinalarabic", 0xfed6,
    "qafinitialarabic", 0xfed7,
    "qafmedialarabic", 0xfed8,
    "qamats", 0x05b8,
    "qamats10", 0x05b8,
    "qamats1a", 0x05b8,
    "qamats1c", 0x05b8,
    "qamats27", 0x05b8,
    "qamats29", 0x05b8,
    "qamats33", 0x05b8,
    "qamatsde", 0x05b8,
    "qamatshebrew", 0x05b8,
    "qamatsnarrowhebrew", 0x05b8,
    "qamatsqatanhebrew", 0x05b8,
    "qamatsqatannarrowhebrew", 0x05b8,
    "qamatsqatanquarterhebrew", 0x05b8,
    "qamatsqatanwidehebrew", 0x05b8,
    "qamatsquarterhebrew", 0x05b8,
    "qamatswidehebrew", 0x05b8,
    "qarneyparahebrew", 0x059f,
    "qbopomofo", 0x3111,
    "qcircle", 0x24e0,
    "qhook", 0x02a0,
    "qmonospace", 0xff51,
    "qof", 0x05e7,
    "qofdagesh", 0xfb47,
    "qofdageshhebrew", 0xfb47,
    "qofhebrew", 0x05e7,
    "qparen", 0x24ac,
    "quarternote", 0x2669,
    "qubuts", 0x05bb,
    "qubuts18", 0x05bb,
    "qubuts25", 0x05bb,
    "qubuts31", 0x05bb,
    "qubutshebrew", 0x05bb,
    "qubutsnarrowhebrew", 0x05bb,
    "qubutsquarterhebrew", 0x05bb,
    "qubutswidehebrew", 0x05bb,
    "question", 0x003f,
    "questionarabic", 0x061f,
    "questionarmenian", 0x055e,
    "questiondown", 0x00bf,
    "questiondownsmall", 0xf7bf,
    "questiongreek", 0x037e,
    "questionmonospace", 0xff1f,
    "questionsmall", 0xf73f,
    "quotedbl", 0x0022,
    "quotedblbase", 0x201e,
    "quotedblleft", 0x201c,
    "quotedblmonospace", 0xff02,
    "quotedblprime", 0x301e,
    "quotedblprimereversed", 0x301d,
    "quotedblright", 0x201d,
    "quoteleft", 0x2018,
    "quoteleftreversed", 0x201b,
    "quotereversed", 0x201b,
    "quoteright", 0x2019,
    "quoterightn", 0x0149,
    "quotesinglbase", 0x201a,
    "quotesingle", 0x0027,
    "quotesinglemonospace", 0xff07,
    "r", 0x0072,
    "raarmenian", 0x057c,
    "rabengali", 0x09b0,
    "racute", 0x0155,
    "radeva", 0x0930,
    "radical", 0x221a,
    "radicalex", 0xf8e5,
    "radoverssquare", 0x33ae,
    "radoverssquaredsquare", 0x33af,
    "radsquare", 0x33ad,
    "rafe", 0x05bf,
    "rafehebrew", 0x05bf,
    "ragujarati", 0x0ab0,
    "ragurmukhi", 0x0a30,
    "rahiragana", 0x3089,
    "rakatakana", 0x30e9,
    "rakatakanahalfwidth", 0xff97,
    "ralowerdiagonalbengali", 0x09f1,
    "ramiddlediagonalbengali", 0x09f0,
    "ramshorn", 0x0264,
    "ratio", 0x2236,
    "rbopomofo", 0x3116,
    "rcaron", 0x0159,
    "rcedilla", 0x0157,
    "rcircle", 0x24e1,
    "rcommaaccent", 0x0157,
    "rdblgrave", 0x0211,
    "rdotaccent", 0x1e59,
    "rdotbelow", 0x1e5b,
    "rdotbelowmacron", 0x1e5d,
    "referencemark", 0x203b,
    "reflexsubset", 0x2286,
    "reflexsuperset", 0x2287,
    "registered", 0x00ae,
    "registersans", 0xf8e8,
    "registerserif", 0xf6da,
    "reharabic", 0x0631,
    "reharmenian", 0x0580,
    "rehfinalarabic", 0xfeae,
    "rehiragana", 0x308c,
    "rekatakana", 0x30ec,
    "rekatakanahalfwidth", 0xff9a,
    "resh", 0x05e8,
    "reshdageshhebrew", 0xfb48,
    "reshhebrew", 0x05e8,
    "reversedtilde", 0x223d,
    "reviahebrew", 0x0597,
    "reviamugrashhebrew", 0x0597,
    "revlogicalnot", 0x2310,
    "rfishhook", 0x027e,
    "rfishhookreversed", 0x027f,
    "rhabengali", 0x09dd,
    "rhadeva", 0x095d,
    "rho", 0x03c1,
    "rhook", 0x027d,
    "rhookturned", 0x027b,
    "rhookturnedsuperior", 0x02b5,
    "rhosymbolgreek", 0x03f1,
    "rhotichookmod", 0x02de,
    "rieulacirclekorean", 0x3271,
    "rieulaparenkorean", 0x3211,
    "rieulcirclekorean", 0x3263,
    "rieulhieuhkorean", 0x3140,
    "rieulkiyeokkorean", 0x313a,
    "rieulkiyeoksioskorean", 0x3169,
    "rieulkorean", 0x3139,
    "rieulmieumkorean", 0x313b,
    "rieulpansioskorean", 0x316c,
    "rieulparenkorean", 0x3203,
    "rieulphieuphkorean", 0x313f,
    "rieulpieupkorean", 0x313c,
    "rieulpieupsioskorean", 0x316b,
    "rieulsioskorean", 0x313d,
    "rieulthieuthkorean", 0x313e,
    "rieultikeutkorean", 0x316a,
    "rieulyeorinhieuhkorean", 0x316d,
    "rightangle", 0x221f,
    "righttackbelowcmb", 0x0319,
    "righttriangle", 0x22bf,
    "rihiragana", 0x308a,
    "rikatakana", 0x30ea,
    "rikatakanahalfwidth", 0xff98,
    "ring", 0x02da,
    "ringbelowcmb", 0x0325,
    "ringcmb", 0x030a,
    "ringhalfleft", 0x02bf,
    "ringhalfleftarmenian", 0x0559,
    "ringhalfleftbelowcmb", 0x031c,
    "ringhalfleftcentered", 0x02d3,
    "ringhalfright", 0x02be,
    "ringhalfrightbelowcmb", 0x0339,
    "ringhalfrightcentered", 0x02d2,
    "rinvertedbreve", 0x0213,
    "rittorusquare", 0x3351,
    "rlinebelow", 0x1e5f,
    "rlongleg", 0x027c,
    "rlonglegturned", 0x027a,
    "rmonospace", 0xff52,
    "rohiragana", 0x308d,
    "rokatakana", 0x30ed,
    "rokatakanahalfwidth", 0xff9b,
    "roruathai", 0x0e23,
    "rparen", 0x24ad,
    "rrabengali", 0x09dc,
    "rradeva", 0x0931,
    "rragurmukhi", 0x0a5c,
    "rreharabic", 0x0691,
    "rrehfinalarabic", 0xfb8d,
    "rrvocalicbengali", 0x09e0,
    "rrvocalicdeva", 0x0960,
    "rrvocalicgujarati", 0x0ae0,
    "rrvocalicvowelsignbengali", 0x09c4,
    "rrvocalicvowelsigndeva", 0x0944,
    "rrvocalicvowelsigngujarati", 0x0ac4,
    "rsuperior", 0xf6f1,
    "rtblock", 0x2590,
    "rturned", 0x0279,
    "rturnedsuperior", 0x02b4,
    "ruhiragana", 0x308b,
    "rukatakana", 0x30eb,
    "rukatakanahalfwidth", 0xff99,
    "rupeemarkbengali", 0x09f2,
    "rupeesignbengali", 0x09f3,
    "rupiah", 0xf6dd,
    "ruthai", 0x0e24,
    "rvocalicbengali", 0x098b,
    "rvocalicdeva", 0x090b,
    "rvocalicgujarati", 0x0a8b,
    "rvocalicvowelsignbengali", 0x09c3,
    "rvocalicvowelsigndeva", 0x0943,
    "rvocalicvowelsigngujarati", 0x0ac3,
    "s", 0x0073,
    "sabengali", 0x09b8,
    "sacute", 0x015b,
    "sacutedotaccent", 0x1e65,
    "sadarabic", 0x0635,
    "sadeva", 0x0938,
    "sadfinalarabic", 0xfeba,
    "sadinitialarabic", 0xfebb,
    "sadmedialarabic", 0xfebc,
    "sagujarati", 0x0ab8,
    "sagurmukhi", 0x0a38,
    "sahiragana", 0x3055,
    "sakatakana", 0x30b5,
    "sakatakanahalfwidth", 0xff7b,
    "sallallahoualayhewasallamarabic", 0xfdfa,
    "samekh", 0x05e1,
    "samekhdagesh", 0xfb41,
    "samekhdageshhebrew", 0xfb41,
    "samekhhebrew", 0x05e1,
    "saraaathai", 0x0e32,
    "saraaethai", 0x0e41,
    "saraaimaimalaithai", 0x0e44,
    "saraaimaimuanthai", 0x0e43,
    "saraamthai", 0x0e33,
    "saraathai", 0x0e30,
    "saraethai", 0x0e40,
    "saraiileftthai", 0xf886,
    "saraiithai", 0x0e35,
    "saraileftthai", 0xf885,
    "saraithai", 0x0e34,
    "saraothai", 0x0e42,
    "saraueeleftthai", 0xf888,
    "saraueethai", 0x0e37,
    "saraueleftthai", 0xf887,
    "sarauethai", 0x0e36,
    "sarauthai", 0x0e38,
    "sarauuthai", 0x0e39,
    "sbopomofo", 0x3119,
    "scaron", 0x0161,
    "scarondotaccent", 0x1e67,
    "scedilla", 0x015f,
    "schwa", 0x0259,
    "schwacyrillic", 0x04d9,
    "schwadieresiscyrillic", 0x04db,
    "schwahook", 0x025a,
    "scircle", 0x24e2,
    "scircumflex", 0x015d,
    "scommaaccent", 0x0219,
    "sdotaccent", 0x1e61,
    "sdotbelow", 0x1e63,
    "sdotbelowdotaccent", 0x1e69,
    "seagullbelowcmb", 0x033c,
    "second", 0x2033,
    "secondtonechinese", 0x02ca,
    "section", 0x00a7,
    "seenarabic", 0x0633,
    "seenfinalarabic", 0xfeb2,
    "seeninitialarabic", 0xfeb3,
    "seenmedialarabic", 0xfeb4,
    "segol", 0x05b6,
    "segol13", 0x05b6,
    "segol1f", 0x05b6,
    "segol2c", 0x05b6,
    "segolhebrew", 0x05b6,
    "segolnarrowhebrew", 0x05b6,
    "segolquarterhebrew", 0x05b6,
    "segoltahebrew", 0x0592,
    "segolwidehebrew", 0x05b6,
    "seharmenian", 0x057d,
    "sehiragana", 0x305b,
    "sekatakana", 0x30bb,
    "sekatakanahalfwidth", 0xff7e,
    "semicolon", 0x003b,
    "semicolonarabic", 0x061b,
    "semicolonmonospace", 0xff1b,
    "semicolonsmall", 0xfe54,
    "semivoicedmarkkana", 0x309c,
    "semivoicedmarkkanahalfwidth", 0xff9f,
    "sentisquare", 0x3322,
    "sentosquare", 0x3323,
    "seven", 0x0037,
    "sevenarabic", 0x0667,
    "sevenbengali", 0x09ed,
    "sevencircle", 0x2466,
    "sevencircleinversesansserif", 0x2790,
    "sevendeva", 0x096d,
    "seveneighths", 0x215e,
    "sevengujarati", 0x0aed,
    "sevengurmukhi", 0x0a6d,
    "sevenhackarabic", 0x0667,
    "sevenhangzhou", 0x3027,
    "sevenideographicparen", 0x3226,
    "seveninferior", 0x2087,
    "sevenmonospace", 0xff17,
    "sevenoldstyle", 0xf737,
    "sevenparen", 0x247a,
    "sevenperiod", 0x248e,
    "sevenpersian", 0x06f7,
    "sevenroman", 0x2176,
    "sevensuperior", 0x2077,
    "seventeencircle", 0x2470,
    "seventeenparen", 0x2484,
    "seventeenperiod", 0x2498,
    "seventhai", 0x0e57,
    "sfthyphen", 0x00ad,
    "shaarmenian", 0x0577,
    "shabengali", 0x09b6,
    "shacyrillic", 0x0448,
    "shaddaarabic", 0x0651,
    "shaddadammaarabic", 0xfc61,
    "shaddadammatanarabic", 0xfc5e,
    "shaddafathaarabic", 0xfc60,
    "shaddakasraarabic", 0xfc62,
    "shaddakasratanarabic", 0xfc5f,
    "shade", 0x2592,
    "shadedark", 0x2593,
    "shadelight", 0x2591,
    "shademedium", 0x2592,
    "shadeva", 0x0936,
    "shagujarati", 0x0ab6,
    "shagurmukhi", 0x0a36,
    "shalshelethebrew", 0x0593,
    "shbopomofo", 0x3115,
    "shchacyrillic", 0x0449,
    "sheenarabic", 0x0634,
    "sheenfinalarabic", 0xfeb6,
    "sheeninitialarabic", 0xfeb7,
    "sheenmedialarabic", 0xfeb8,
    "sheicoptic", 0x03e3,
    "sheqel", 0x20aa,
    "sheqelhebrew", 0x20aa,
    "sheva", 0x05b0,
    "sheva115", 0x05b0,
    "sheva15", 0x05b0,
    "sheva22", 0x05b0,
    "sheva2e", 0x05b0,
    "shevahebrew", 0x05b0,
    "shevanarrowhebrew", 0x05b0,
    "shevaquarterhebrew", 0x05b0,
    "shevawidehebrew", 0x05b0,
    "shhacyrillic", 0x04bb,
    "shimacoptic", 0x03ed,
    "shin", 0x05e9,
    "shindagesh", 0xfb49,
    "shindageshhebrew", 0xfb49,
    "shindageshshindot", 0xfb2c,
    "shindageshshindothebrew", 0xfb2c,
    "shindageshsindot", 0xfb2d,
    "shindageshsindothebrew", 0xfb2d,
    "shindothebrew", 0x05c1,
    "shinhebrew", 0x05e9,
    "shinshindot", 0xfb2a,
    "shinshindothebrew", 0xfb2a,
    "shinsindot", 0xfb2b,
    "shinsindothebrew", 0xfb2b,
    "shook", 0x0282,
    "sigma", 0x03c3,
    "sigma1", 0x03c2,
    "sigmafinal", 0x03c2,
    "sigmalunatesymbolgreek", 0x03f2,
    "sihiragana", 0x3057,
    "sikatakana", 0x30b7,
    "sikatakanahalfwidth", 0xff7c,
    "siluqhebrew", 0x05bd,
    "siluqlefthebrew", 0x05bd,
    "similar", 0x223c,
    "sindothebrew", 0x05c2,
    "siosacirclekorean", 0x3274,
    "siosaparenkorean", 0x3214,
    "sioscieuckorean", 0x317e,
    "sioscirclekorean", 0x3266,
    "sioskiyeokkorean", 0x317a,
    "sioskorean", 0x3145,
    "siosnieunkorean", 0x317b,
    "siosparenkorean", 0x3206,
    "siospieupkorean", 0x317d,
    "siostikeutkorean", 0x317c,
    "six", 0x0036,
    "sixarabic", 0x0666,
    "sixbengali", 0x09ec,
    "sixcircle", 0x2465,
    "sixcircleinversesansserif", 0x278f,
    "sixdeva", 0x096c,
    "sixgujarati", 0x0aec,
    "sixgurmukhi", 0x0a6c,
    "sixhackarabic", 0x0666,
    "sixhangzhou", 0x3026,
    "sixideographicparen", 0x3225,
    "sixinferior", 0x2086,
    "sixmonospace", 0xff16,
    "sixoldstyle", 0xf736,
    "sixparen", 0x2479,
    "sixperiod", 0x248d,
    "sixpersian", 0x06f6,
    "sixroman", 0x2175,
    "sixsuperior", 0x2076,
    "sixteencircle", 0x246f,
    "sixteencurrencydenominatorbengali", 0x09f9,
    "sixteenparen", 0x2483,
    "sixteenperiod", 0x2497,
    "sixthai", 0x0e56,
    "slash", 0x002f,
    "slashmonospace", 0xff0f,
    "slong", 0x017f,
    "slongdotaccent", 0x1e9b,
    "smileface", 0x263a,
    "smonospace", 0xff53,
    "sofpasuqhebrew", 0x05c3,
    "softhyphen", 0x00ad,
    "softsigncyrillic", 0x044c,
    "sohiragana", 0x305d,
    "sokatakana", 0x30bd,
    "sokatakanahalfwidth", 0xff7f,
    "soliduslongoverlaycmb", 0x0338,
    "solidusshortoverlaycmb", 0x0337,
    "sorusithai", 0x0e29,
    "sosalathai", 0x0e28,
    "sosothai", 0x0e0b,
    "sosuathai", 0x0e2a,
    "space", 0x0020,
    "spacehackarabic", 0x0020,
    "spade", 0x2660,
    "spadesuitblack", 0x2660,
    "spadesuitwhite", 0x2664,
    "sparen", 0x24ae,
    "squarebelowcmb", 0x033b,
    "squarecc", 0x33c4,
    "squarecm", 0x339d,
    "squarediagonalcrosshatchfill", 0x25a9,
    "squarehorizontalfill", 0x25a4,
    "squarekg", 0x338f,
    "squarekm", 0x339e,
    "squarekmcapital", 0x33ce,
    "squareln", 0x33d1,
    "squarelog", 0x33d2,
    "squaremg", 0x338e,
    "squaremil", 0x33d5,
    "squaremm", 0x339c,
    "squaremsquared", 0x33a1,
    "squareorthogonalcrosshatchfill", 0x25a6,
    "squareupperlefttolowerrightfill", 0x25a7,
    "squareupperrighttolowerleftfill", 0x25a8,
    "squareverticalfill", 0x25a5,
    "squarewhitewithsmallblack", 0x25a3,
    "srsquare", 0x33db,
    "ssabengali", 0x09b7,
    "ssadeva", 0x0937,
    "ssagujarati", 0x0ab7,
    "ssangcieuckorean", 0x3149,
    "ssanghieuhkorean", 0x3185,
    "ssangieungkorean", 0x3180,
    "ssangkiyeokkorean", 0x3132,
    "ssangnieunkorean", 0x3165,
    "ssangpieupkorean", 0x3143,
    "ssangsioskorean", 0x3146,
    "ssangtikeutkorean", 0x3138,
    "ssuperior", 0xf6f2,
    "sterling", 0x00a3,
    "sterlingmonospace", 0xffe1,
    "strokelongoverlaycmb", 0x0336,
    "strokeshortoverlaycmb", 0x0335,
    "subset", 0x2282,
    "subsetnotequal", 0x228a,
    "subsetorequal", 0x2286,
    "succeeds", 0x227b,
    "suchthat", 0x220b,
    "suhiragana", 0x3059,
    "sukatakana", 0x30b9,
    "sukatakanahalfwidth", 0xff7d,
    "sukunarabic", 0x0652,
    "summation", 0x2211,
    "sun", 0x263c,
    "superset", 0x2283,
    "supersetnotequal", 0x228b,
    "supersetorequal", 0x2287,
    "svsquare", 0x33dc,
    "syouwaerasquare", 0x337c,
    "t", 0x0074,
    "tabengali", 0x09a4,
    "tackdown", 0x22a4,
    "tackleft", 0x22a3,
    "tadeva", 0x0924,
    "tagujarati", 0x0aa4,
    "tagurmukhi", 0x0a24,
    "taharabic", 0x0637,
    "tahfinalarabic", 0xfec2,
    "tahinitialarabic", 0xfec3,
    "tahiragana", 0x305f,
    "tahmedialarabic", 0xfec4,
    "taisyouerasquare", 0x337d,
    "takatakana", 0x30bf,
    "takatakanahalfwidth", 0xff80,
    "tatweelarabic", 0x0640,
    "tau", 0x03c4,
    "tav", 0x05ea,
    "tavdages", 0xfb4a,
    "tavdagesh", 0xfb4a,
    "tavdageshhebrew", 0xfb4a,
    "tavhebrew", 0x05ea,
    "tbar", 0x0167,
    "tbopomofo", 0x310a,
    "tcaron", 0x0165,
    "tccurl", 0x02a8,
    "tcedilla", 0x0163,
    "tcheharabic", 0x0686,
    "tchehfinalarabic", 0xfb7b,
    "tchehinitialarabic", 0xfb7c,
    "tchehmedialarabic", 0xfb7d,
    "tcircle", 0x24e3,
    "tcircumflexbelow", 0x1e71,
    "tcommaaccent", 0x0163,
    "tdieresis", 0x1e97,
    "tdotaccent", 0x1e6b,
    "tdotbelow", 0x1e6d,
    "tecyrillic", 0x0442,
    "tedescendercyrillic", 0x04ad,
    "teharabic", 0x062a,
    "tehfinalarabic", 0xfe96,
    "tehhahinitialarabic", 0xfca2,
    "tehhahisolatedarabic", 0xfc0c,
    "tehinitialarabic", 0xfe97,
    "tehiragana", 0x3066,
    "tehjeeminitialarabic", 0xfca1,
    "tehjeemisolatedarabic", 0xfc0b,
    "tehmarbutaarabic", 0x0629,
    "tehmarbutafinalarabic", 0xfe94,
    "tehmedialarabic", 0xfe98,
    "tehmeeminitialarabic", 0xfca4,
    "tehmeemisolatedarabic", 0xfc0e,
    "tehnoonfinalarabic", 0xfc73,
    "tekatakana", 0x30c6,
    "tekatakanahalfwidth", 0xff83,
    "telephone", 0x2121,
    "telephoneblack", 0x260e,
    "telishagedolahebrew", 0x05a0,
    "telishaqetanahebrew", 0x05a9,
    "tencircle", 0x2469,
    "tenideographicparen", 0x3229,
    "tenparen", 0x247d,
    "tenperiod", 0x2491,
    "tenroman", 0x2179,
    "tesh", 0x02a7,
    "tet", 0x05d8,
    "tetdagesh", 0xfb38,
    "tetdageshhebrew", 0xfb38,
    "tethebrew", 0x05d8,
    "tetsecyrillic", 0x04b5,
    "tevirhebrew", 0x059b,
    "tevirlefthebrew", 0x059b,
    "thabengali", 0x09a5,
    "thadeva", 0x0925,
    "thagujarati", 0x0aa5,
    "thagurmukhi", 0x0a25,
    "thalarabic", 0x0630,
    "thalfinalarabic", 0xfeac,
    "thanthakhatlowleftthai", 0xf898,
    "thanthakhatlowrightthai", 0xf897,
    "thanthakhatthai", 0x0e4c,
    "thanthakhatupperleftthai", 0xf896,
    "theharabic", 0x062b,
    "thehfinalarabic", 0xfe9a,
    "thehinitialarabic", 0xfe9b,
    "thehmedialarabic", 0xfe9c,
    "thereexists", 0x2203,
    "therefore", 0x2234,
    "theta", 0x03b8,
    "theta1", 0x03d1,
    "thetasymbolgreek", 0x03d1,
    "thieuthacirclekorean", 0x3279,
    "thieuthaparenkorean", 0x3219,
    "thieuthcirclekorean", 0x326b,
    "thieuthkorean", 0x314c,
    "thieuthparenkorean", 0x320b,
    "thirteencircle", 0x246c,
    "thirteenparen", 0x2480,
    "thirteenperiod", 0x2494,
    "thonangmonthothai", 0x0e11,
    "thook", 0x01ad,
    "thophuthaothai", 0x0e12,
    "thorn", 0x00fe,
    "thothahanthai", 0x0e17,
    "thothanthai", 0x0e10,
    "thothongthai", 0x0e18,
    "thothungthai", 0x0e16,
    "thousandcyrillic", 0x0482,
    "thousandsseparatorarabic", 0x066c,
    "thousandsseparatorpersian", 0x066c,
    "three", 0x0033,
    "threearabic", 0x0663,
    "threebengali", 0x09e9,
    "threecircle", 0x2462,
    "threecircleinversesansserif", 0x278c,
    "threedeva", 0x0969,
    "threeeighths", 0x215c,
    "threegujarati", 0x0ae9,
    "threegurmukhi", 0x0a69,
    "threehackarabic", 0x0663,
    "threehangzhou", 0x3023,
    "threeideographicparen", 0x3222,
    "threeinferior", 0x2083,
    "threemonospace", 0xff13,
    "threenumeratorbengali", 0x09f6,
    "threeoldstyle", 0xf733,
    "threeparen", 0x2476,
    "threeperiod", 0x248a,
    "threepersian", 0x06f3,
    "threequarters", 0x00be,
    "threequartersemdash", 0xf6de,
    "threeroman", 0x2172,
    "threesuperior", 0x00b3,
    "threethai", 0x0e53,
    "thzsquare", 0x3394,
    "tihiragana", 0x3061,
    "tikatakana", 0x30c1,
    "tikatakanahalfwidth", 0xff81,
    "tikeutacirclekorean", 0x3270,
    "tikeutaparenkorean", 0x3210,
    "tikeutcirclekorean", 0x3262,
    "tikeutkorean", 0x3137,
    "tikeutparenkorean", 0x3202,
    "tilde", 0x02dc,
    "tildebelowcmb", 0x0330,
    "tildecmb", 0x0303,
    "tildecomb", 0x0303,
    "tildedoublecmb", 0x0360,
    "tildeoperator", 0x223c,
    "tildeoverlaycmb", 0x0334,
    "tildeverticalcmb", 0x033e,
    "timescircle", 0x2297,
    "tipehahebrew", 0x0596,
    "tipehalefthebrew", 0x0596,
    "tippigurmukhi", 0x0a70,
    "titlocyrilliccmb", 0x0483,
    "tiwnarmenian", 0x057f,
    "tlinebelow", 0x1e6f,
    "tmonospace", 0xff54,
    "toarmenian", 0x0569,
    "tohiragana", 0x3068,
    "tokatakana", 0x30c8,
    "tokatakanahalfwidth", 0xff84,
    "tonebarextrahighmod", 0x02e5,
    "tonebarextralowmod", 0x02e9,
    "tonebarhighmod", 0x02e6,
    "tonebarlowmod", 0x02e8,
    "tonebarmidmod", 0x02e7,
    "tonefive", 0x01bd,
    "tonesix", 0x0185,
    "tonetwo", 0x01a8,
    "tonos", 0x0384,
    "tonsquare", 0x3327,
    "topatakthai", 0x0e0f,
    "tortoiseshellbracketleft", 0x3014,
    "tortoiseshellbracketleftsmall", 0xfe5d,
    "tortoiseshellbracketleftvertical", 0xfe39,
    "tortoiseshellbracketright", 0x3015,
    "tortoiseshellbracketrightsmall", 0xfe5e,
    "tortoiseshellbracketrightvertical", 0xfe3a,
    "totaothai", 0x0e15,
    "tpalatalhook", 0x01ab,
    "tparen", 0x24af,
    "trademark", 0x2122,
    "trademarksans", 0xf8ea,
    "trademarkserif", 0xf6db,
    "tretroflexhook", 0x0288,
    "triagdn", 0x25bc,
    "triaglf", 0x25c4,
    "triagrt", 0x25ba,
    "triagup", 0x25b2,
    "ts", 0x02a6,
    "tsadi", 0x05e6,
    "tsadidagesh", 0xfb46,
    "tsadidageshhebrew", 0xfb46,
    "tsadihebrew", 0x05e6,
    "tsecyrillic", 0x0446,
    "tsere", 0x05b5,
    "tsere12", 0x05b5,
    "tsere1e", 0x05b5,
    "tsere2b", 0x05b5,
    "tserehebrew", 0x05b5,
    "tserenarrowhebrew", 0x05b5,
    "tserequarterhebrew", 0x05b5,
    "tserewidehebrew", 0x05b5,
    "tshecyrillic", 0x045b,
    "tsuperior", 0xf6f3,
    "ttabengali", 0x099f,
    "ttadeva", 0x091f,
    "ttagujarati", 0x0a9f,
    "ttagurmukhi", 0x0a1f,
    "tteharabic", 0x0679,
    "ttehfinalarabic", 0xfb67,
    "ttehinitialarabic", 0xfb68,
    "ttehmedialarabic", 0xfb69,
    "tthabengali", 0x09a0,
    "tthadeva", 0x0920,
    "tthagujarati", 0x0aa0,
    "tthagurmukhi", 0x0a20,
    "tturned", 0x0287,
    "tuhiragana", 0x3064,
    "tukatakana", 0x30c4,
    "tukatakanahalfwidth", 0xff82,
    "tusmallhiragana", 0x3063,
    "tusmallkatakana", 0x30c3,
    "tusmallkatakanahalfwidth", 0xff6f,
    "twelvecircle", 0x246b,
    "twelveparen", 0x247f,
    "twelveperiod", 0x2493,
    "twelveroman", 0x217b,
    "twentycircle", 0x2473,
    "twentyhangzhou", 0x5344,
    "twentyparen", 0x2487,
    "twentyperiod", 0x249b,
    "two", 0x0032,
    "twoarabic", 0x0662,
    "twobengali", 0x09e8,
    "twocircle", 0x2461,
    "twocircleinversesansserif", 0x278b,
    "twodeva", 0x0968,
    "twodotenleader", 0x2025,
    "twodotleader", 0x2025,
    "twodotleadervertical", 0xfe30,
    "twogujarati", 0x0ae8,
    "twogurmukhi", 0x0a68,
    "twohackarabic", 0x0662,
    "twohangzhou", 0x3022,
    "twoideographicparen", 0x3221,
    "twoinferior", 0x2082,
    "twomonospace", 0xff12,
    "twonumeratorbengali", 0x09f5,
    "twooldstyle", 0xf732,
    "twoparen", 0x2475,
    "twoperiod", 0x2489,
    "twopersian", 0x06f2,
    "tworoman", 0x2171,
    "twostroke", 0x01bb,
    "twosuperior", 0x00b2,
    "twothai", 0x0e52,
    "twothirds", 0x2154,
    "u", 0x0075,
    "uacute", 0x00fa,
    "ubar", 0x0289,
    "ubengali", 0x0989,
    "ubopomofo", 0x3128,
    "ubreve", 0x016d,
    "ucaron", 0x01d4,
    "ucircle", 0x24e4,
    "ucircumflex", 0x00fb,
    "ucircumflexbelow", 0x1e77,
    "ucyrillic", 0x0443,
    "udattadeva", 0x0951,
    "udblacute", 0x0171,
    "udblgrave", 0x0215,
    "udeva", 0x0909,
    "udieresis", 0x00fc,
    "udieresisacute", 0x01d8,
    "udieresisbelow", 0x1e73,
    "udieresiscaron", 0x01da,
    "udieresiscyrillic", 0x04f1,
    "udieresisgrave", 0x01dc,
    "udieresismacron", 0x01d6,
    "udotbelow", 0x1ee5,
    "ugrave", 0x00f9,
    "ugujarati", 0x0a89,
    "ugurmukhi", 0x0a09,
    "uhiragana", 0x3046,
    "uhookabove", 0x1ee7,
    "uhorn", 0x01b0,
    "uhornacute", 0x1ee9,
    "uhorndotbelow", 0x1ef1,
    "uhorngrave", 0x1eeb,
    "uhornhookabove", 0x1eed,
    "uhorntilde", 0x1eef,
    "uhungarumlaut", 0x0171,
    "uhungarumlautcyrillic", 0x04f3,
    "uinvertedbreve", 0x0217,
    "ukatakana", 0x30a6,
    "ukatakanahalfwidth", 0xff73,
    "ukcyrillic", 0x0479,
    "ukorean", 0x315c,
    "umacron", 0x016b,
    "umacroncyrillic", 0x04ef,
    "umacrondieresis", 0x1e7b,
    "umatragurmukhi", 0x0a41,
    "umonospace", 0xff55,
    "underscore", 0x005f,
    "underscoredbl", 0x2017,
    "underscoremonospace", 0xff3f,
    "underscorevertical", 0xfe33,
    "underscorewavy", 0xfe4f,
    "union", 0x222a,
    "universal", 0x2200,
    "uogonek", 0x0173,
    "uparen", 0x24b0,
    "upblock", 0x2580,
    "upperdothebrew", 0x05c4,
    "upsilon", 0x03c5,
    "upsilondieresis", 0x03cb,
    "upsilondieresistonos", 0x03b0,
    "upsilonlatin", 0x028a,
    "upsilontonos", 0x03cd,
    "uptackbelowcmb", 0x031d,
    "uptackmod", 0x02d4,
    "uragurmukhi", 0x0a73,
    "uring", 0x016f,
    "ushortcyrillic", 0x045e,
    "usmallhiragana", 0x3045,
    "usmallkatakana", 0x30a5,
    "usmallkatakanahalfwidth", 0xff69,
    "ustraightcyrillic", 0x04af,
    "ustraightstrokecyrillic", 0x04b1,
    "utilde", 0x0169,
    "utildeacute", 0x1e79,
    "utildebelow", 0x1e75,
    "uubengali", 0x098a,
    "uudeva", 0x090a,
    "uugujarati", 0x0a8a,
    "uugurmukhi", 0x0a0a,
    "uumatragurmukhi", 0x0a42,
    "uuvowelsignbengali", 0x09c2,
    "uuvowelsigndeva", 0x0942,
    "uuvowelsigngujarati", 0x0ac2,
    "uvowelsignbengali", 0x09c1,
    "uvowelsigndeva", 0x0941,
    "uvowelsigngujarati", 0x0ac1,
    "v", 0x0076,
    "vadeva", 0x0935,
    "vagujarati", 0x0ab5,
    "vagurmukhi", 0x0a35,
    "vakatakana", 0x30f7,
    "vav", 0x05d5,
    "vavdagesh", 0xfb35,
    "vavdagesh65", 0xfb35,
    "vavdageshhebrew", 0xfb35,
    "vavhebrew", 0x05d5,
    "vavholam", 0xfb4b,
    "vavholamhebrew", 0xfb4b,
    "vavvavhebrew", 0x05f0,
    "vavyodhebrew", 0x05f1,
    "vcircle", 0x24e5,
    "vdotbelow", 0x1e7f,
    "vecyrillic", 0x0432,
    "veharabic", 0x06a4,
    "vehfinalarabic", 0xfb6b,
    "vehinitialarabic", 0xfb6c,
    "vehmedialarabic", 0xfb6d,
    "vekatakana", 0x30f9,
    "venus", 0x2640,
    "verticalbar", 0x007c,
    "verticallineabovecmb", 0x030d,
    "verticallinebelowcmb", 0x0329,
    "verticallinelowmod", 0x02cc,
    "verticallinemod", 0x02c8,
    "vewarmenian", 0x057e,
    "vhook", 0x028b,
    "vikatakana", 0x30f8,
    "viramabengali", 0x09cd,
    "viramadeva", 0x094d,
    "viramagujarati", 0x0acd,
    "visargabengali", 0x0983,
    "visargadeva", 0x0903,
    "visargagujarati", 0x0a83,
    "vmonospace", 0xff56,
    "voarmenian", 0x0578,
    "voicediterationhiragana", 0x309e,
    "voicediterationkatakana", 0x30fe,
    "voicedmarkkana", 0x309b,
    "voicedmarkkanahalfwidth", 0xff9e,
    "vokatakana", 0x30fa,
    "vparen", 0x24b1,
    "vtilde", 0x1e7d,
    "vturned", 0x028c,
    "vuhiragana", 0x3094,
    "vukatakana", 0x30f4,
    "w", 0x0077,
    "wacute", 0x1e83,
    "waekorean", 0x3159,
    "wahiragana", 0x308f,
    "wakatakana", 0x30ef,
    "wakatakanahalfwidth", 0xff9c,
    "wakorean", 0x3158,
    "wasmallhiragana", 0x308e,
    "wasmallkatakana", 0x30ee,
    "wattosquare", 0x3357,
    "wavedash", 0x301c,
    "wavyunderscorevertical", 0xfe34,
    "wawarabic", 0x0648,
    "wawfinalarabic", 0xfeee,
    "wawhamzaabovearabic", 0x0624,
    "wawhamzaabovefinalarabic", 0xfe86,
    "wbsquare", 0x33dd,
    "wcircle", 0x24e6,
    "wcircumflex", 0x0175,
    "wdieresis", 0x1e85,
    "wdotaccent", 0x1e87,
    "wdotbelow", 0x1e89,
    "wehiragana", 0x3091,
    "weierstrass", 0x2118,
    "wekatakana", 0x30f1,
    "wekorean", 0x315e,
    "weokorean", 0x315d,
    "wgrave", 0x1e81,
    "whitebullet", 0x25e6,
    "whitecircle", 0x25cb,
    "whitecircleinverse", 0x25d9,
    "whitecornerbracketleft", 0x300e,
    "whitecornerbracketleftvertical", 0xfe43,
    "whitecornerbracketright", 0x300f,
    "whitecornerbracketrightvertical", 0xfe44,
    "whitediamond", 0x25c7,
    "whitediamondcontainingblacksmalldiamond", 0x25c8,
    "whitedownpointingsmalltriangle", 0x25bf,
    "whitedownpointingtriangle", 0x25bd,
    "whiteleftpointingsmalltriangle", 0x25c3,
    "whiteleftpointingtriangle", 0x25c1,
    "whitelenticularbracketleft", 0x3016,
    "whitelenticularbracketright", 0x3017,
    "whiterightpointingsmalltriangle", 0x25b9,
    "whiterightpointingtriangle", 0x25b7,
    "whitesmallsquare", 0x25ab,
    "whitesmilingface", 0x263a,
    "whitesquare", 0x25a1,
    "whitestar", 0x2606,
    "whitetelephone", 0x260f,
    "whitetortoiseshellbracketleft", 0x3018,
    "whitetortoiseshellbracketright", 0x3019,
    "whiteuppointingsmalltriangle", 0x25b5,
    "whiteuppointingtriangle", 0x25b3,
    "wihiragana", 0x3090,
    "wikatakana", 0x30f0,
    "wikorean", 0x315f,
    "wmonospace", 0xff57,
    "wohiragana", 0x3092,
    "wokatakana", 0x30f2,
    "wokatakanahalfwidth", 0xff66,
    "won", 0x20a9,
    "wonmonospace", 0xffe6,
    "wowaenthai", 0x0e27,
    "wparen", 0x24b2,
    "wring", 0x1e98,
    "wsuperior", 0x02b7,
    "wturned", 0x028d,
    "wynn", 0x01bf,
    "x", 0x0078,
    "xabovecmb", 0x033d,
    "xbopomofo", 0x3112,
    "xcircle", 0x24e7,
    "xdieresis", 0x1e8d,
    "xdotaccent", 0x1e8b,
    "xeharmenian", 0x056d,
    "xi", 0x03be,
    "xmonospace", 0xff58,
    "xparen", 0x24b3,
    "xsuperior", 0x02e3,
    "y", 0x0079,
    "yaadosquare", 0x334e,
    "yabengali", 0x09af,
    "yacute", 0x00fd,
    "yadeva", 0x092f,
    "yaekorean", 0x3152,
    "yagujarati", 0x0aaf,
    "yagurmukhi", 0x0a2f,
    "yahiragana", 0x3084,
    "yakatakana", 0x30e4,
    "yakatakanahalfwidth", 0xff94,
    "yakorean", 0x3151,
    "yamakkanthai", 0x0e4e,
    "yasmallhiragana", 0x3083,
    "yasmallkatakana", 0x30e3,
    "yasmallkatakanahalfwidth", 0xff6c,
    "yatcyrillic", 0x0463,
    "ycircle", 0x24e8,
    "ycircumflex", 0x0177,
    "ydieresis", 0x00ff,
    "ydotaccent", 0x1e8f,
    "ydotbelow", 0x1ef5,
    "yeharabic", 0x064a,
    "yehbarreearabic", 0x06d2,
    "yehbarreefinalarabic", 0xfbaf,
    "yehfinalarabic", 0xfef2,
    "yehhamzaabovearabic", 0x0626,
    "yehhamzaabovefinalarabic", 0xfe8a,
    "yehhamzaaboveinitialarabic", 0xfe8b,
    "yehhamzaabovemedialarabic", 0xfe8c,
    "yehinitialarabic", 0xfef3,
    "yehmedialarabic", 0xfef4,
    "yehmeeminitialarabic", 0xfcdd,
    "yehmeemisolatedarabic", 0xfc58,
    "yehnoonfinalarabic", 0xfc94,
    "yehthreedotsbelowarabic", 0x06d1,
    "yekorean", 0x3156,
    "yen", 0x00a5,
    "yenmonospace", 0xffe5,
    "yeokorean", 0x3155,
    "yeorinhieuhkorean", 0x3186,
    "yerahbenyomohebrew", 0x05aa,
    "yerahbenyomolefthebrew", 0x05aa,
    "yericyrillic", 0x044b,
    "yerudieresiscyrillic", 0x04f9,
    "yesieungkorean", 0x3181,
    "yesieungpansioskorean", 0x3183,
    "yesieungsioskorean", 0x3182,
    "yetivhebrew", 0x059a,
    "ygrave", 0x1ef3,
    "yhook", 0x01b4,
    "yhookabove", 0x1ef7,
    "yiarmenian", 0x0575,
    "yicyrillic", 0x0457,
    "yikorean", 0x3162,
    "yinyang", 0x262f,
    "yiwnarmenian", 0x0582,
    "ymonospace", 0xff59,
    "yod", 0x05d9,
    "yoddagesh", 0xfb39,
    "yoddageshhebrew", 0xfb39,
    "yodhebrew", 0x05d9,
    "yodyodhebrew", 0x05f2,
    "yodyodpatahhebrew", 0xfb1f,
    "yohiragana", 0x3088,
    "yoikorean", 0x3189,
    "yokatakana", 0x30e8,
    "yokatakanahalfwidth", 0xff96,
    "yokorean", 0x315b,
    "yosmallhiragana", 0x3087,
    "yosmallkatakana", 0x30e7,
    "yosmallkatakanahalfwidth", 0xff6e,
    "yotgreek", 0x03f3,
    "yoyaekorean", 0x3188,
    "yoyakorean", 0x3187,
    "yoyakthai", 0x0e22,
    "yoyingthai", 0x0e0d,
    "yparen", 0x24b4,
    "ypogegrammeni", 0x037a,
    "ypogegrammenigreekcmb", 0x0345,
    "yr", 0x01a6,
    "yring", 0x1e99,
    "ysuperior", 0x02b8,
    "ytilde", 0x1ef9,
    "yturned", 0x028e,
    "yuhiragana", 0x3086,
    "yuikorean", 0x318c,
    "yukatakana", 0x30e6,
    "yukatakanahalfwidth", 0xff95,
    "yukorean", 0x3160,
    "yusbigcyrillic", 0x046b,
    "yusbigiotifiedcyrillic", 0x046d,
    "yuslittlecyrillic", 0x0467,
    "yuslittleiotifiedcyrillic", 0x0469,
    "yusmallhiragana", 0x3085,
    "yusmallkatakana", 0x30e5,
    "yusmallkatakanahalfwidth", 0xff6d,
    "yuyekorean", 0x318b,
    "yuyeokorean", 0x318a,
    "yyabengali", 0x09df,
    "yyadeva", 0x095f,
    "z", 0x007a,
    "zaarmenian", 0x0566,
    "zacute", 0x017a,
    "zadeva", 0x095b,
    "zagurmukhi", 0x0a5b,
    "zaharabic", 0x0638,
    "zahfinalarabic", 0xfec6,
    "zahinitialarabic", 0xfec7,
    "zahiragana", 0x3056,
    "zahmedialarabic", 0xfec8,
    "zainarabic", 0x0632,
    "zainfinalarabic", 0xfeb0,
    "zakatakana", 0x30b6,
    "zaqefgadolhebrew", 0x0595,
    "zaqefqatanhebrew", 0x0594,
    "zarqahebrew", 0x0598,
    "zayin", 0x05d6,
    "zayindagesh", 0xfb36,
    "zayindageshhebrew", 0xfb36,
    "zayinhebrew", 0x05d6,
    "zbopomofo", 0x3117,
    "zcaron", 0x017e,
    "zcircle", 0x24e9,
    "zcircumflex", 0x1e91,
    "zcurl", 0x0291,
    "zdot", 0x017c,
    "zdotaccent", 0x017c,
    "zdotbelow", 0x1e93,
    "zecyrillic", 0x0437,
    "zedescendercyrillic", 0x0499,
    "zedieresiscyrillic", 0x04df,
    "zehiragana", 0x305c,
    "zekatakana", 0x30bc,
    "zero", 0x0030,
    "zeroarabic", 0x0660,
    "zerobengali", 0x09e6,
    "zerodeva", 0x0966,
    "zerogujarati", 0x0ae6,
    "zerogurmukhi", 0x0a66,
    "zerohackarabic", 0x0660,
    "zeroinferior", 0x2080,
    "zeromonospace", 0xff10,
    "zerooldstyle", 0xf730,
    "zeropersian", 0x06f0,
    "zerosuperior", 0x2070,
    "zerothai", 0x0e50,
    "zerowidthjoiner", 0xfeff,
    "zerowidthnonjoiner", 0x200c,
    "zerowidthspace", 0x200b,
    "zeta", 0x03b6,
    "zhbopomofo", 0x3113,
    "zhearmenian", 0x056a,
    "zhebrevecyrillic", 0x04c2,
    "zhecyrillic", 0x0436,
    "zhedescendercyrillic", 0x0497,
    "zhedieresiscyrillic", 0x04dd,
    "zihiragana", 0x3058,
    "zikatakana", 0x30b8,
    "zinorhebrew", 0x05ae,
    "zlinebelow", 0x1e95,
    "zmonospace", 0xff5a,
    "zohiragana", 0x305e,
    "zokatakana", 0x30be,
    "zparen", 0x24b5,
    "zretroflexhook", 0x0290,
    "zstroke", 0x01b6,
    "zuhiragana", 0x305a,
    "zukatakana", 0x30ba,
    ".notdef", 0x0000,

    // TeX-specific glyph names.
    "angbracketleftbig", 0x2329,
    "angbracketleftBig", 0x2329,
    "angbracketleftbigg", 0x2329,
    "angbracketleftBigg", 0x2329,
    "angbracketrightBig", 0x232a,
    "angbracketrightbig", 0x232a,
    "angbracketrightBigg", 0x232a,
    "angbracketrightbigg", 0x232a,
    "arrowhookleft", 0x21aa,
    "arrowhookright", 0x21a9,
    "arrowlefttophalf", 0x21bc,
    "arrowleftbothalf", 0x21bd,
    "arrownortheast", 0x2197,
    "arrownorthwest", 0x2196,
    "arrowrighttophalf", 0x21c0,
    "arrowrightbothalf", 0x21c1,
    "arrowsoutheast", 0x2198,
    "arrowsouthwest", 0x2199,
    "backslashbig", 0x2216,
    "backslashBig", 0x2216,
    "backslashBigg", 0x2216,
    "backslashbigg", 0x2216,
    "bardbl", 0x2016,
    "bracehtipdownleft", 0xfe37,
    "bracehtipdownright", 0xfe37,
    "bracehtipupleft", 0xfe38,
    "bracehtipupright", 0xfe38,
    "braceleftBig", 0x007b,
    "braceleftbig", 0x007b,
    "braceleftbigg", 0x007b,
    "braceleftBigg", 0x007b,
    "bracerightBig", 0x007d,
    "bracerightbig", 0x007d,
    "bracerightbigg", 0x007d,
    "bracerightBigg", 0x007d,
    "bracketleftbig", 0x005b,
    "bracketleftBig", 0x005b,
    "bracketleftbigg", 0x005b,
    "bracketleftBigg", 0x005b,
    "bracketrightBig", 0x005d,
    "bracketrightbig", 0x005d,
    "bracketrightbigg", 0x005d,
    "bracketrightBigg", 0x005d,
    "ceilingleftbig", 0x2308,
    "ceilingleftBig", 0x2308,
    "ceilingleftBigg", 0x2308,
    "ceilingleftbigg", 0x2308,
    "ceilingrightbig", 0x2309,
    "ceilingrightBig", 0x2309,
    "ceilingrightbigg", 0x2309,
    "ceilingrightBigg", 0x2309,
    "circledotdisplay", 0x2299,
    "circledottext", 0x2299,
    "circlemultiplydisplay", 0x2297,
    "circlemultiplytext", 0x2297,
    "circleplusdisplay", 0x2295,
    "circleplustext", 0x2295,
    "contintegraldisplay", 0x222e,
    "contintegraltext", 0x222e,
    "coproductdisplay", 0x2210,
    "coproducttext", 0x2210,
    "floorleftBig", 0x230a,
    "floorleftbig", 0x230a,
    "floorleftbigg", 0x230a,
    "floorleftBigg", 0x230a,
    "floorrightbig", 0x230b,
    "floorrightBig", 0x230b,
    "floorrightBigg", 0x230b,
    "floorrightbigg", 0x230b,
    "hatwide", 0x0302,
    "hatwider", 0x0302,
    "hatwidest", 0x0302,
    "intercal", 0x1d40,
    "integraldisplay", 0x222b,
    "integraltext", 0x222b,
    "intersectiondisplay", 0x22c2,
    "intersectiontext", 0x22c2,
    "logicalanddisplay", 0x2227,
    "logicalandtext", 0x2227,
    "logicalordisplay", 0x2228,
    "logicalortext", 0x2228,
    "parenleftBig", 0x0028,
    "parenleftbig", 0x0028,
    "parenleftBigg", 0x0028,
    "parenleftbigg", 0x0028,
    "parenrightBig", 0x0029,
    "parenrightbig", 0x0029,
    "parenrightBigg", 0x0029,
    "parenrightbigg", 0x0029,
    "prime", 0x2032,
    "productdisplay", 0x220f,
    "producttext", 0x220f,
    "radicalbig", 0x221a,
    "radicalBig", 0x221a,
    "radicalBigg", 0x221a,
    "radicalbigg", 0x221a,
    "radicalbt", 0x221a,
    "radicaltp", 0x221a,
    "radicalvertex", 0x221a,
    "slashbig", 0x002f,
    "slashBig", 0x002f,
    "slashBigg", 0x002f,
    "slashbigg", 0x002f,
    "summationdisplay", 0x2211,
    "summationtext", 0x2211,
    "tildewide", 0x02dc,
    "tildewider", 0x02dc,
    "tildewidest", 0x02dc,
    "uniondisplay", 0x22c3,
    "unionmultidisplay", 0x228e,
    "unionmultitext", 0x228e,
    "unionsqdisplay", 0x2294,
    "unionsqtext", 0x2294,
    "uniontext", 0x22c3,
    "vextenddouble", 0x2225,
    "vextendsingle", 0x2223,
  ];
});

var getDingbatsGlyphsUnicode = getArrayLookupTableFactory(function () {
  // prettier-ignore
  return [
    "space", 0x0020,
    "a1", 0x2701,
    "a2", 0x2702,
    "a202", 0x2703,
    "a3", 0x2704,
    "a4", 0x260e,
    "a5", 0x2706,
    "a119", 0x2707,
    "a118", 0x2708,
    "a117", 0x2709,
    "a11", 0x261b,
    "a12", 0x261e,
    "a13", 0x270c,
    "a14", 0x270d,
    "a15", 0x270e,
    "a16", 0x270f,
    "a105", 0x2710,
    "a17", 0x2711,
    "a18", 0x2712,
    "a19", 0x2713,
    "a20", 0x2714,
    "a21", 0x2715,
    "a22", 0x2716,
    "a23", 0x2717,
    "a24", 0x2718,
    "a25", 0x2719,
    "a26", 0x271a,
    "a27", 0x271b,
    "a28", 0x271c,
    "a6", 0x271d,
    "a7", 0x271e,
    "a8", 0x271f,
    "a9", 0x2720,
    "a10", 0x2721,
    "a29", 0x2722,
    "a30", 0x2723,
    "a31", 0x2724,
    "a32", 0x2725,
    "a33", 0x2726,
    "a34", 0x2727,
    "a35", 0x2605,
    "a36", 0x2729,
    "a37", 0x272a,
    "a38", 0x272b,
    "a39", 0x272c,
    "a40", 0x272d,
    "a41", 0x272e,
    "a42", 0x272f,
    "a43", 0x2730,
    "a44", 0x2731,
    "a45", 0x2732,
    "a46", 0x2733,
    "a47", 0x2734,
    "a48", 0x2735,
    "a49", 0x2736,
    "a50", 0x2737,
    "a51", 0x2738,
    "a52", 0x2739,
    "a53", 0x273a,
    "a54", 0x273b,
    "a55", 0x273c,
    "a56", 0x273d,
    "a57", 0x273e,
    "a58", 0x273f,
    "a59", 0x2740,
    "a60", 0x2741,
    "a61", 0x2742,
    "a62", 0x2743,
    "a63", 0x2744,
    "a64", 0x2745,
    "a65", 0x2746,
    "a66", 0x2747,
    "a67", 0x2748,
    "a68", 0x2749,
    "a69", 0x274a,
    "a70", 0x274b,
    "a71", 0x25cf,
    "a72", 0x274d,
    "a73", 0x25a0,
    "a74", 0x274f,
    "a203", 0x2750,
    "a75", 0x2751,
    "a204", 0x2752,
    "a76", 0x25b2,
    "a77", 0x25bc,
    "a78", 0x25c6,
    "a79", 0x2756,
    "a81", 0x25d7,
    "a82", 0x2758,
    "a83", 0x2759,
    "a84", 0x275a,
    "a97", 0x275b,
    "a98", 0x275c,
    "a99", 0x275d,
    "a100", 0x275e,
    "a101", 0x2761,
    "a102", 0x2762,
    "a103", 0x2763,
    "a104", 0x2764,
    "a106", 0x2765,
    "a107", 0x2766,
    "a108", 0x2767,
    "a112", 0x2663,
    "a111", 0x2666,
    "a110", 0x2665,
    "a109", 0x2660,
    "a120", 0x2460,
    "a121", 0x2461,
    "a122", 0x2462,
    "a123", 0x2463,
    "a124", 0x2464,
    "a125", 0x2465,
    "a126", 0x2466,
    "a127", 0x2467,
    "a128", 0x2468,
    "a129", 0x2469,
    "a130", 0x2776,
    "a131", 0x2777,
    "a132", 0x2778,
    "a133", 0x2779,
    "a134", 0x277a,
    "a135", 0x277b,
    "a136", 0x277c,
    "a137", 0x277d,
    "a138", 0x277e,
    "a139", 0x277f,
    "a140", 0x2780,
    "a141", 0x2781,
    "a142", 0x2782,
    "a143", 0x2783,
    "a144", 0x2784,
    "a145", 0x2785,
    "a146", 0x2786,
    "a147", 0x2787,
    "a148", 0x2788,
    "a149", 0x2789,
    "a150", 0x278a,
    "a151", 0x278b,
    "a152", 0x278c,
    "a153", 0x278d,
    "a154", 0x278e,
    "a155", 0x278f,
    "a156", 0x2790,
    "a157", 0x2791,
    "a158", 0x2792,
    "a159", 0x2793,
    "a160", 0x2794,
    "a161", 0x2192,
    "a163", 0x2194,
    "a164", 0x2195,
    "a196", 0x2798,
    "a165", 0x2799,
    "a192", 0x279a,
    "a166", 0x279b,
    "a167", 0x279c,
    "a168", 0x279d,
    "a169", 0x279e,
    "a170", 0x279f,
    "a171", 0x27a0,
    "a172", 0x27a1,
    "a173", 0x27a2,
    "a162", 0x27a3,
    "a174", 0x27a4,
    "a175", 0x27a5,
    "a176", 0x27a6,
    "a177", 0x27a7,
    "a178", 0x27a8,
    "a179", 0x27a9,
    "a193", 0x27aa,
    "a180", 0x27ab,
    "a199", 0x27ac,
    "a181", 0x27ad,
    "a200", 0x27ae,
    "a182", 0x27af,
    "a201", 0x27b1,
    "a183", 0x27b2,
    "a184", 0x27b3,
    "a197", 0x27b4,
    "a185", 0x27b5,
    "a194", 0x27b6,
    "a198", 0x27b7,
    "a186", 0x27b8,
    "a195", 0x27b9,
    "a187", 0x27ba,
    "a188", 0x27bb,
    "a189", 0x27bc,
    "a190", 0x27bd,
    "a191", 0x27be,
    "a89", 0x2768, // 0xF8D7
    "a90", 0x2769, // 0xF8D8
    "a93", 0x276a, // 0xF8D9
    "a94", 0x276b, // 0xF8DA
    "a91", 0x276c, // 0xF8DB
    "a92", 0x276d, // 0xF8DC
    "a205", 0x276e, // 0xF8DD
    "a85", 0x276f, // 0xF8DE
    "a206", 0x2770, // 0xF8DF
    "a86", 0x2771, // 0xF8E0
    "a87", 0x2772, // 0xF8E1
    "a88", 0x2773, // 0xF8E2
    "a95", 0x2774, // 0xF8E3
    "a96", 0x2775, // 0xF8E4
    ".notdef", 0x0000,
  ];
});

export { getDingbatsGlyphsUnicode, getGlyphsUnicode };
