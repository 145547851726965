import React from 'react';
import { v4 as uuidv4 } from 'uuid';

export interface RadioGroupOption
{
    label: string,
    value: string,
    isSelected?: boolean,
}

export interface RadioGroupProps
{
    groupOptions: Array<RadioGroupOption>,
    group: string,
    label: string,
    name?: string,
    onActionClick?: any,
    onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void,
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void,
    properties?: { [key: string]: string },
}

export const RadioGroup: React.FC<RadioGroupProps> = ({
    groupOptions,
    group,
    label,
    name,
    onActionClick,
    onKeyUp,
    onFocus,
    properties
}) => 
{
    return (
        <div className="radio-group">
            {groupOptions.map((item: RadioGroupOption) => {

                const id = uuidv4();
                return (
                <div className="form-check" key={id}>
                    <input className="form-check-input" type="radio" defaultChecked={item.isSelected || false} radioGroup={group} id={name} name={name} value={item.value} onClick={onActionClick} onKeyUp={onKeyUp} onFocus={onFocus} {...properties} />
                    <label className="form-check-label"><b>{item.label}</b></label><br/>
                </div>
                 )}
            )}     
        </div>
    );
}