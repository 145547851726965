import * as React from 'react';
import { Button, ButtonType } from '../../Button';
import { defaults, MobileTableProps } from './types';
import MobileTableFilter from '../MobileTableFilter'
import { MobileTableCard } from './MobileTableCard'

const MobileTable = (props: React.PropsWithChildren<MobileTableProps>) => {
  const finalProps = { ...defaults, ...props };
  const { component, classes, tableData, columnConfig, filterConfig } = finalProps;
  const CustomTag = `${component}` as keyof JSX.IntrinsicElements;
  // const mainProps = React.useContext(MobileTableContext);
  let finalClasses = 'HuiMobileTable';
  if (classes) finalClasses += ` ${classes}`;
  return (
    <div>
      {
        filterConfig !== undefined && <div style={{ marginBottom: '0.25em' }}><MobileTableFilter filterConfig={filterConfig} /></div>
      }
      <CustomTag style={props.style} className={finalClasses}>
        {tableData.map((row: any, index: any) => {
          return (<MobileTableCard columnConfig={columnConfig} row={row} key={row.id} />)
        })}
      </CustomTag>
    </div>

  );
};

export default MobileTable;
