import React, { Component } from "react";
import { TableColumn } from "@ui5/webcomponents-react";

export interface IAdvancedTableColumnProps {
	demandPopin?: boolean;
	minWidth?: number;
	popinText?: string;
	className?: string;
	sortable?: boolean;
}

export class AdvancedTableColumn extends Component<IAdvancedTableColumnProps> {
	render() {
		return (
			<TableColumn {...this.props} slot="columns" className={this.props.className}>
				{this.props.children}
			</TableColumn>
		);
	}
}
