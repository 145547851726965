import * as React from 'react'
import {inject, observer} from "mobx-react";
import {ContactPerson, PeopleStoreInterface} from "../../model/PeopleStore";
import {FC, useState} from "react";
import {formatPhoneNumber, usDateTime, displayDate, displayDateTimeLong} from "../../common/Formatters";
import {CovidNav} from "../../model/CovidNav";
import { AdvancedTable } from '@mit/hui';
import { IAdvancedTableColumn } from '@mit/hui/build/components/AdvancedTable';
import ViewPerson from '../common/ViewPerson';
import PersonDetailsDrawerComponent from '../common/PersonDetailsDrawer'

interface PeopleListProps {
    covidNav?: CovidNav;
    peopleStore?: PeopleStoreInterface;
    isLoading?: boolean;
}


const PeopleListCompletedComponent: FC<PeopleListProps> = ({peopleStore, covidNav, isLoading}) => {
    const [showPerson, setShowPerson]  = useState(false);
    const [person, setPerson]  = useState<ContactPerson>();

    const viewPersonClose = () => {
        setPerson(undefined);
        setShowPerson(false);
    }

    const showPersonInfo = (item: any) => {
        setShowPerson(true);
        setPerson(item);
    }

    const toTitleCase = (str: string) => {
        return str.replace(
          /\w\S*/g,
          function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          }
        );
    }

    const columns: Array<IAdvancedTableColumn> = [
        {
            id: "name",
            name: "Name",
            sortable: true,
            formatter: (item:any) => { return (<div className="clickable" onClick={() => { showPersonInfo(item)}}><span>{item.name}</span></div>) },
            options: {
                demandPopin: true,
                minWidth: 600,
                popinText:  "Name"
            },
            filterable: true,
            filterOptions: {
				type: "textbox",
				onFilter: (item: any, value: any) => {
					if (item.display_name.toLowerCase().indexOf(value.toLowerCase()) != -1)
						return true;

					return false;
				},
			}
        },
        {
            id: "mitId",
            name: "MIT ID",
            sortable: false,
            options: {
                demandPopin: true,
                minWidth: 1000,
                popinText:  "MIT ID"
            },
            filterable: false
        },
        {
            id: "gender",
            name: "Gender",
            formatter: (item: any) => { return toTitleCase(item.gender) },
            sortable: false,
            options: {
                demandPopin: true,
                minWidth: 1000,
                popinText:  "Gender"
            },
            filterable: false
        },
        {
            id: "phoneNumber",
            name: "Phone",
            sortable: false,
            formatter: (item: any) => {return ( <a href={`tel:${item.phoneNumber}`}>{formatPhoneNumber(item.phoneNumber)}</a> ) },
            options: {
                demandPopin: true,
                minWidth: 600,
                popinText: "Phone"
            },
            filterable: false
        },
        {
            id: "email",
            name: "Email",
            sortable: false,
            formatter: (item: any) => { return ( <a className="table-email" href={`mailto:${item.email}`}>{item.email}</a> ) },
            options: {
                demandPopin: true,
                minWidth: 600,
                popinText: "Email"
            },
            filterable: false
        },
        {
            id: "dateOfBirth",
            name: "Date of Birth",
            sortable: false,
            options: {
                demandPopin: true,
                minWidth: 1000,
                popinText: "Date of Birth"
            },
            filterable: false
        },
        {
            id: "testDate",
            name: "Collected",
            sortable: true,
            formatter: (item: any) => { return displayDateTimeLong(item.testDate); },
            options: {
                demandPopin: true,
                minWidth: 1000,
                popinText: "Collected"
            }
        },
        {
            id: "sampleId",
            name: "Sample ID",
            sortable: false,
            options: {
                demandPopin: true,
                minWidth: 600,
                popinText: "Sample ID"
            },
            filterable: false
        },
        {
            id: "exported",
            name: "Broad",
            sortable: true,
            options: {
                demandPopin: true,
                minWidth: 1000,
                popinText: "Broad"
            },
            filterable: false
        },
        {
            id: "result",
            name: "Result",
            sortable: true,
            options: {
                demandPopin: true,
                minWidth: 1000,
                popinText: "Result"
            }
        }
    ];

    const data = peopleStore!.completedList?.map((person: ContactPerson, index: number) => {
        return {
            ...person,
            gender: person.gender ? person.gender : '-',
            phoneNumber: person.phoneNumber ? formatPhoneNumber(person.phoneNumber) : '-',
            email: person.email ? person.email : '-',
            dateOfBirth: person.dateOfBirth ? displayDate(person.dateOfBirth) : '-'
        }
    })

    return ( 
        <>
            <AdvancedTable 
                columns={columns} 
                items={data ? data : []} 
                showExportButton={false} 
                showFilterButton={true} 
                isLoading={isLoading || covidNav!.globalTableIsLoading}
                sortStateOptions={{
                    enabled:true,
                    key: 'covid-medical-people-list-complete'
                }}
                theme={"medical"} />
            {/* <ViewPerson show={showPerson} person={person} onClose={viewPersonClose} /> */}
            <PersonDetailsDrawerComponent id={person?.mitId} show={showPerson} onClose={viewPersonClose} />
        </>
    )
}

export const PeopleListCompleted = inject('peopleStore', 'covidNav')(observer(PeopleListCompletedComponent))