import authProvider from "./AuthProvider";
import BaseController from "./BaseController";

export interface CaseResponse {
	case_guid: string;
	case_number: string;
	case_type: string;
	case_type_id: number;
	contact_count: number;
	contact_date: string;
	created_by: string;
	created_on: string;
	medical_test_guid: string;
	person: PersonModel;
	status: string;
	status_id: number;
	updated_by: string;
	updated_on: string;
	comment_count: number;
}

export interface AuditResponse {
	audit_guid: string;
	audit_severity: number;
	audit_type: string;
	created_by: string;
	datetime: string;
	description_line1: string;
	description_line2: string;
	title: string;
	updated_by: string;
}

export interface StatusResponse {
	status_id: number;
	status_name: string;
}

export interface ContactsResponse {
	case_guid: string;
	contact_date: string;
	contact_guid: string;
	contact_number: string;
	department: string;
	title: string;
	display_name: string;
	email_address: string;
	ext_guid: string;
	is_external: boolean;
	resident?: boolean;
	vaccine_status?: string;
	resident_food_delivery?: boolean;
	mit_id: string;
	medical_status_name: string;
	medical_status_id: number;
	medical_hold_guid: string;
	profilePicture?: string;
}

export interface PersonModel {
	date_of_birth: string;
	display_name: string;
	email: string;
	gender: string;
	kerberos: string;
	mit_id: string;
	vaccine_status: string;
	resident: boolean;
	resident_food_delivery: boolean;
	phone_number: string;
	title: string;
	medical_status_name: string;
	medical_status_id: number;
	medical_hold_guid: string;
	medical_last_test: string;
}

export interface FetchCasesResponse {
	items?: CaseResponse[];
	error?: any;
}

export interface FetchContactResponse {
	items?: ContactsResponse[];
	error?: any;
}

export interface FetchStatusResponse {
	items?: StatusResponse[];
	error?: any;
}

export interface FetchAuditResponse {
	items?: AuditResponse[];
	error?: any;
	unauth?: any;
}

//TODO: RIAAN fix items model
export interface FetchContactQuestions {
	items?: any[];
	error?: any;
	unauth?: any;
}

export interface ClassesInterface {
	all: AllClassInterface,
	classes: FetchClasses[]
}

export interface AllClassInterface {
	vaccinated: number;
	unvaccinated: number;
	total: number
}

export interface FetchClasses {
	identifier: string
	code: string;
	number: string
	name: string;
	term: string
	unvaccinated: number;
	total: number;
}

export interface FetchClassMember {
	mitId: string
	firstName: string
	lastName: string
	email: string
	phoneNumber: string
	vaccinationStatus: string
	isCaseContact: boolean
	caseContact?: ContactsResponse
}

class ContactTracingApi extends BaseController {
	readonly url_contact_tracing: string;
	readonly url_audit: string;
	readonly url_contact_tracing_status: string;
	readonly url_contact_tracing_questions: string;
	readonly url_buildings: string;
	readonly url_locations: string;
	readonly url_classes: string;

	constructor() {
		super();
		this.url_contact_tracing = `${this.apiHost}/${this.apiPathMedical}/contact_tracing`;
		this.url_contact_tracing_status = `${this.apiHost}/${this.apiPathMedical}/contact_tracing/status`;
		this.url_contact_tracing_questions = `${this.apiHost}/${this.apiPathMedical}/contact_tracing/question_medical`;
		this.url_audit = `${this.apiHost}/${this.apiPathMedical}/audit`;
		this.url_buildings = `${this.apiHost}/${this.apiPathMedical}/buildings`;
		this.url_locations = `${this.apiHost}/${this.apiPathMedical}/checkin/locations`;
		this.url_classes = `${this.apiHost}/${this.apiPathMedical}/classes`;
	}

	fetchRoles = async (): Promise<any> => {
		let response = await fetch(`${this.url_contact_tracing}/roles`, await this.requestHeaderGet());

		if (response.status == 200) {
			let data = await response.json();
			return { items: data };
		} else {
			return {
				error: true,
			};
		}
	};

	fetchCases = async (dsl?: boolean, status_id?: number): Promise<FetchCasesResponse> => {
		let response: any;
		if (dsl) {
			response = await fetch(`${this.url_contact_tracing}?dsl=${dsl}${status_id ? `&status_id=${status_id}` : ""}`, await this.requestHeaderGet());
		} else {
			response = await fetch(`${this.url_contact_tracing}${status_id ? `?status_id=${status_id}` : ""}`, await this.requestHeaderGet());
		}

		if (response.status == 200) {
			let data = await response.json();
			return { items: data };
		} else {
			return {
				error: true,
			};
		}
	};
	

	fetchClasses = async (mitId: string): Promise<ClassesInterface> => {
		let response = await fetch(`${this.url_classes}?mitId=${mitId}`, await this.requestHeaderGet());

		if (this.checkStatusCode(response.status)) {
			let data = await response.json();
			return data;
		} else {
			const text = await response.json();
			return await Promise.reject(text.message);
		}
	};

	
	fetchClassMembers = async (classIdentifier: string, mitId: string, caseGuid: string, code: string, number: string, term : string, unvaccinatedOnly?: boolean): Promise<FetchClassMember[]> => {
		let response = await fetch(`${this.url_classes}/${encodeURI(classIdentifier)}/members?mitId=${mitId}&caseGuid=${caseGuid}&code=${code}&number=${number}&term=${term}&unvaccinatedOnly=${unvaccinatedOnly ?? false}`, await this.requestHeaderGet());

		if (this.checkStatusCode(response.status)) {
			let data = await response.json();
			return data;
		} else {
			const text = await response.json();
			return await Promise.reject(text.message);
		}
	};

	fetchStatus = async (): Promise<FetchStatusResponse> => {
		let response = await fetch(`${this.url_contact_tracing_status}`, await this.requestHeaderGet());

		if (response.status == 200) {
			let data = await response.json();
			return { items: data };
		} else {
			return {
				error: true,
			};
		}
	};

	fetchAudit = async (mit_id: string): Promise<FetchAuditResponse> => {
		let response = await fetch(`${this.url_audit}?mit_id=${mit_id}`, await this.requestHeaderGet());

		if (response.status == 200) {
			let data = await response.json();
			return { items: data };
		} else if (response.status === 507 || response.status === 403) {
			return {
				unauth: true,
			};
		} else {
			return {
				error: true,
			};
		}
	};

	fetchContact = async (case_guid: string): Promise<FetchContactResponse> => {
		let response = await fetch(`${this.url_contact_tracing}/${case_guid}/contact`, await this.requestHeaderGet());

		if (response.status === 200) {
			let data = await response.json();
			return { items: data };
		} else {
			return {
				error: true,
			};
		}
	};

	fetchComments = async (case_guid: string): Promise<any> => {
		let response = await fetch(`${this.url_contact_tracing}/${case_guid}/comments`, await this.requestHeaderGet());

		if (response.status === 200) {
			let data = await response.json();
			return { items: data };
		} else {
			return {
				error: true,
			};
		}
	};

	fetchContactComments = async (case_guid: string, contact_guid: string): Promise<any> => {
		let response = await fetch(`${this.url_contact_tracing}/${case_guid}/${contact_guid}/comments`, await this.requestHeaderGet());

		if (response.status === 200) {
			let data = await response.json();
			return { items: data };
		} else {
			return {
				error: true,
			};
		}
	};

	fetchBuildings = async (): Promise<any> => {
		let response = await fetch(this.url_buildings, await this.requestHeaderGet());

		if (response.status === 200) {
			let data = await response.json();
			return { items: data };
		} else {
			return {
				error: true,
			};
		}
	};

	fetchLocations = async (): Promise<any> => {
		let response = await fetch(this.url_locations, await this.requestHeaderGet());

		if (response.status === 200) {
			let data = await response.json();
			return { items: data };
		} else {
			return {
				error: true,
			};
		}
	};

	async updateContactQuestions(case_guid: string, answers: object, contact_guid?: string): Promise<any> {
		let token = await authProvider.getToken();
		const postOptions: RequestInit = {
			method: "PUT",
			headers: new Headers({
				Authorization: "Bearer " + token,
				"Content-Type": "application/json",
			}),
			body: JSON.stringify(answers),
		};

		let url = `${this.url_contact_tracing_questions}/${case_guid}`;
		url = url += !!contact_guid ? `?ct_contact_guid=${contact_guid}` : "";

		let response = await fetch(url, postOptions);

		if (response.status === 200 || response.status === 201) {
			let data = await response.json();
			return { items: data };
		} else if (response.status === 507 || response.status === 403) {
			return {
				unauth: true,
			};
		} else {
			return {
				error: true,
			};
		}
	}

	fetchContactQuestions = async (mit_id: string, case_guid?: string, contact_guid?: string): Promise<FetchContactQuestions> => {
		let url = `${this.url_contact_tracing_questions}/${case_guid}`;
		url = url += !!contact_guid ? `?ct_contact_guid=${contact_guid}` : "";

		let response = await fetch(url, await this.requestHeaderGet());

		if (response.status == 200) {
			let data = await response.json();
			return { items: data };
		} else if (response.status === 507 || response.status === 403) {
			return {
				unauth: true,
			};
		} else {
			return {
				error: true,
			};
		}
	};

	fetchContactQuestionPersonTypes = async (): Promise<any> => {
		let response = await fetch(`${this.url_contact_tracing_questions}/person_type`, await this.requestHeaderGet());

		if (response.status == 200) {
			let data = await response.json();
			return { items: data };
		} else if (response.status === 507 || response.status === 403) {
			return {
				unauth: true,
			};
		} else {
			return {
				error: true,
			};
		}
	};

	fetchContactQuestionExposureTypes = async (): Promise<any> => {
		let response = await fetch(`${this.url_contact_tracing_questions}/exposure_type`, await this.requestHeaderGet());

		if (response.status == 200) {
			let data = await response.json();
			return { items: data };
		} else if (response.status === 507 || response.status === 403) {
			return {
				unauth: true,
			};
		} else {
			return {
				error: true,
			};
		}
	};

	fetchContactQuestionTestStreamTypes = async (): Promise<any> => {
		let response = await fetch(`${this.url_contact_tracing_questions}/testing_stream_type`, await this.requestHeaderGet());

		if (response.status == 200) {
			let data = await response.json();
			return { items: data };
		} else if (response.status === 507 || response.status === 403) {
			return {
				unauth: true,
			};
		} else {
			return {
				error: true,
			};
		}
	};

	async deleteContactQuestions(case_guid: string, body: ContactsResponse): Promise<any> {
		let token = await authProvider.getToken();
		const deleteOptions: RequestInit = {
			method: "DELETE",
			headers: new Headers({
				Authorization: "Bearer " + token,
				"Content-Type": "application/json",
			}),
			body: JSON.stringify(body),
		};

		let response = await fetch(`${this.url_contact_tracing}/${case_guid}`, deleteOptions);

		if (response.status === 200) {
			let data = await response.json();
			return { items: data };
		} else {
			return { error: true };
		}
	}

	async postCase(body: any): Promise<any> {
		let token = await authProvider.getToken();
		const postOptions: RequestInit = {
			method: "POST",
			headers: new Headers({
				Authorization: "Bearer " + token,
				"Content-Type": "application/json",
			}),
			body: JSON.stringify(body),
		};

		let response = await fetch(this.url_contact_tracing, postOptions);

		if (response.status === 200 || response.status === 201) {
			let data = await response.json();
			return { items: data };
		} else {
			return { error: true };
		}
	}

	async postContact(case_guid: string, body: ContactsResponse): Promise<any> {
		let token = await authProvider.getToken();
		const postOptions: RequestInit = {
			method: "POST",
			headers: new Headers({
				Authorization: "Bearer " + token,
				"Content-Type": "application/json",
			}),
			body: JSON.stringify(body),
		};

		let response = await fetch(this.url_contact_tracing + `/${case_guid}/contact`, postOptions);

		if (response.status === 200 || response.status === 201) {
			let data = await response.json();
			return { items: data };
		} else {
			return { error: true };
		}
	}

	async postComment(case_guid: string, message: string): Promise<any> {
		let body = {
			comment: message,
		};

		let token = await authProvider.getToken();
		const postOptions: RequestInit = {
			method: "POST",
			headers: new Headers({
				Authorization: "Bearer " + token,
				"Content-Type": "application/json",
			}),
			body: JSON.stringify(body),
		};

		let response = await fetch(this.url_contact_tracing + `/${case_guid}/comments`, postOptions);

		if (response.status === 200 || response.status === 201) {
			let data = await response.json();
			return { items: data };
		} else if (response.status === 507 || response.status === 403) {
			return {
				unauth: true,
			};
		} else {
			return { error: true };
		}
	}

	async postContactComment(case_guid: string, contact_guid: string, message: string): Promise<any> {
		let body = {
			comment: message,
		};

		let token = await authProvider.getToken();
		const postOptions: RequestInit = {
			method: "POST",
			headers: new Headers({
				Authorization: "Bearer " + token,
				"Content-Type": "application/json",
			}),
			body: JSON.stringify(body),
		};

		let response = await fetch(this.url_contact_tracing + `/${case_guid}/${contact_guid}/comments`, postOptions);

		if (response.status === 200 || response.status === 201) {
			let data = await response.json();
			return { items: data };
		} else if (response.status === 507 || response.status === 403) {
			return {
				unauth: true,
			};
		} else {
			return { error: true };
		}
	}

	async putCaseStatus(case_guid: string, status_id: number, status_name: string): Promise<any> {
		let body = {
			status_id: status_id,
			status_name: status_name,
		};

		let token = await authProvider.getToken();
		const putOptions: RequestInit = {
			method: "PUT",
			headers: new Headers({
				Authorization: "Bearer " + token,
				"Content-Type": "application/json",
			}),
			body: JSON.stringify(body),
		};

		let response = await fetch(this.url_contact_tracing + `/${case_guid}/status`, putOptions);

		if (response.status === 200) {
			let data = await response.json();
			return { items: data };
		} else {
			return { error: true };
		}
	}

	async putContact(case_guid: string, body: ContactsResponse): Promise<any> {
		let token = await authProvider.getToken();
		const putOptions: RequestInit = {
			method: "PUT",
			headers: new Headers({
				Authorization: "Bearer " + token,
				"Content-Type": "application/json",
			}),
			body: JSON.stringify(body),
		};

		let response = await fetch(this.url_contact_tracing + `/${case_guid}/contact`, putOptions);

		if (response.status === 200) {
			let data = await response.json();
			return { items: data };
		} else {
			return { error: true };
		}
	}

	async deleteContact(case_guid: string, body: ContactsResponse): Promise<any> {
		let token = await authProvider.getToken();
		const deleteOptions: RequestInit = {
			method: "DELETE",
			headers: new Headers({
				Authorization: "Bearer " + token,
				"Content-Type": "application/json",
			}),
			body: JSON.stringify(body),
		};

		let response = await fetch(this.url_contact_tracing + `/${case_guid}/contact`, deleteOptions);

		if (response.status === 200) {
			let data = await response.json();
			return { items: data };
		} else {
			return { error: true };
		}
	}
}

const contactTracingApi = new ContactTracingApi();
export default contactTracingApi;
