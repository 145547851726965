import React from 'react';

export interface MITFooterProps
{
    text: string,
    maximized: boolean
}
 
export default class MITFooter extends React.Component<MITFooterProps>
{
    render(): React.ReactNode 
    {
        if (this.props.maximized)
        {
            return (

                <div>
                    <footer>
                        <div className="row">
                            <div className="col-md-6 col-lg-6">
                                <a className="btn btn-link" href=""><img className="footer-logo" src="/images/MIT-red.svg" alt="MIT Logo" /> Massachusetts Institute of Technology</a>
                            </div>
                            <div className="col-md-6 col-lg-6 text-right"><a type="button" className="btn btn-link" href="#" data-toggle="modal" data-target="#accessibilityModal">Accessibility</a></div>
                        </div>



                    </footer>


                    {/*Need to replace this with the refactored modal */}
                    <div className="modal fade" id="accessibilityModal" role="dialog" aria-labelledby="accessibilityModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="accessibilityModalLabel">Accessibility</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    MIT IST is committed to providing an environment that is accessible to individuals with disabilities.
                                    If you cannot access content or use features on our website due to a disability, please let us know. Use the feeback form to contact the help team.
                    </div>

                            </div>
                        </div>
                    </div>
                </div>


            );
        }
        else
        {
            return (
                <div>
                    <footer>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 col-lg-6">
                                    <a className="btn btn-link" href=""><img className="footer-logo" src="/images/MIT-red.svg" alt="MIT Logo" /> Massachusetts Institute of Technology</a>
                                </div>
                                <div className="col-md-6 col-lg-6 text-right"><a type="button" className="btn btn-link" href="#" data-toggle="modal" data-target="#accessibilityModal">Accessibility</a></div>
                            </div>
                        </div>
                    </footer>
                    {/*Need to replace this with the refactored modal */}
                    <div className="modal fade" id="accessibilityModal" role="dialog" aria-labelledby="accessibilityModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="accessibilityModalLabel">Accessibility</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    MIT IST is committed to providing an environment that is accessible to individuals with disabilities.
                                    If you cannot access content or use features on our website due to a disability, please let us know. Use the feeback form to contact the help team.
                </div>

                            </div>
                        </div>
                    </div>
                </div>
            );

        }

    }
}