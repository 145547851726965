import * as React from "react";
import { inject, observer, Provider } from "mobx-react";
import { FC, useEffect, useState } from "react";
import { SearchUserInterface } from "../../model/SearchUser";
import { toDate, usDateTime } from "../../common/Formatters";
import medicalApi from "../../api/MedicalApi";
import { CovidNavInterface } from "../../model/CovidNav";
import AddHold from "./AddHold";
import HoldStatusButton from "./HoldStatusButton";
import ChangeHoldStatus from "./ChangeHoldStatus";
import { HoldsModelInterface } from "../../model/Holds";
import { AdvancedTable, ButtonType, IAdvancedTableColumn, Error, ErrorType, ErrorContext } from "@mit/hui";
import { Fragment } from "react";
import { RolesInterface } from "../../model/Roles";

interface PeopleListProps {
	searchUser?: SearchUserInterface;
	covidNav?: CovidNavInterface;
	holds?: HoldsModelInterface;
	mitId?: string;
	roles?: RolesInterface;
}

const executeSortDesc = (a: any, b: any) => {
	const fieldName = "date";
	let dateTimeA: moment.Moment = toDate(a[fieldName]!);
	let dateTimeB: moment.Moment = toDate(b[fieldName]!);
	return dateTimeB.diff(dateTimeA);
};

const PersonHoldsComponent: FC<PeopleListProps> = ({ searchUser, covidNav, holds, mitId, roles }) => {
	const [isLoading, setIsLoading] = useState(true);
	const [unAuth, setUnAuth] = useState(false);
	const [showHolds, setShowHolds] = useState(false);
	const [showEdit, setShowEdit] = useState(false);
	const [editRecord, setEditRecord] = useState();
	const editHoldStatusClose = () => {
		setEditRecord(undefined);
		setShowEdit(false);
	};

	const closeHolds = () => {
		setShowHolds(false);
	};

	useEffect(() => {
		if (mitId || (searchUser && searchUser.person)) {
			reloadPersonSymptoms();
		}
	}, [searchUser!.person, mitId]);

	const reloadPersonSymptoms = () => {
		setIsLoading(true);
		medicalApi.fetchPersonMedicalHolds(mitId ? mitId : searchUser!.person!.mitId).then((resp) => {
			if (resp.unauth) {
				setUnAuth(true);
			} else {
				holds!.personHolds = resp.items ? resp.items : [];
				setIsLoading(false);
			}
		});
		setIsLoading(false);
	};

  const getClassName = (status: string) => {
    if (status.toLowerCase() === 'open') {
      return 'open-status'
    } else if (status.toLowerCase() === 'new') {
      return 'new-status'
    }
    return ''
  }

	const columns: Array<IAdvancedTableColumn> = [
		{
			id: "date",
			name: "Last Updated",
			sortable: true,
			formatter: (item: any) => {
				return usDateTime(item.date);
			},
			options: {
				demandPopin: true,
				minWidth: 500,
				popinText: "Last Updated",
			},
			filterable: false,
		},
		{
			id: "updated_by",
			name: "Updated By",
			sortable: true,
			options: {
				demandPopin: true,
				minWidth: 300,
				popinText: "Updated By",
			},
			filterable: false,
		},
		{
			id: "reason",
			name: "Reason",
			sortable: false,
			options: {
				demandPopin: true,
				minWidth: 300,
				popinText: "Reason",
			},
			filterable: false,
		},
		{
			id: "comments",
			name: "Comment",
			sortable: false,
			options: {
				demandPopin: true,
				minWidth: 500,
				popinText: "Comment",
			},
			filterable: false,
		},
		{
			id: "status",
			name: "Status",
			sortable: true,
      formatter: (item: any) => (
        <div className={getClassName(item.status)}>
          {item.status}
        </div>
      ),
			options: {
				demandPopin: true,
				minWidth: 500,
				popinText: "Status",
			},
			filterable: true,
			filterOptions: {
				type: "dropdown",
				createOptionsOffItems: true,
				onFilter: (item: any, value: any) => {
					if (value !== null && value !== undefined && value !== "") {
						return item.Action === value;
					}
					return true;
				},
			},
		},
	];

	const actionButtons = [
		{
			icon: "edit",
			type: ButtonType.Ghost,
			onClick: (e: any) => {
				setEditRecord(e);
				setShowEdit(true);
			},
			text: "",
			state: 0,
			padded: false,
		},
	];

	const data = !holds!.personHolds
		? []
		: holds!.personHolds.sort(executeSortDesc).map((hold: any, index: number) => {
				return {
					...hold,
					updated_by: hold.updated_by ? hold.updated_by : hold.created_by,
					date: hold.updated_on ? hold.updated_on : hold.created_on,
					Action: (
						<Provider holds={holds}>
							<HoldStatusButton currentStatus={hold.status} person={hold} holdGuid={hold.hold_guid} />
						</Provider>
					),
				};
		  });

	if (unAuth)
		return (
			<Fragment>
				<h4 className="medical-holds profile-table-headers">
					Medical Holds
				</h4>
				<Error context={ErrorContext.Component} message="Not Authorized" type={ErrorType.AccessDenied} />;
			</Fragment>
		);

	return (
		<>
			<h4 className="medical-holds profile-table-headers">
				Medical Holds
				{roles?.roles.includes('medical') && covidNav && covidNav!.features && covidNav!.features["covid19.medical.put_on_hold"] && (
					<AddHold
						person={{ mitId: mitId ? mitId : searchUser!.person!.mitId, name: mitId ? "" : searchUser!.person!.displayName, kerberosId: mitId ? "" : searchUser!.person!.kerberosId }}
					/>
				)}
			</h4>
			<div className={"person-detail-container"}>
				<AdvancedTable 
					columns={columns} 
					items={data} 
					isLoading={isLoading} 
					actionButtons={actionButtons} 
					sortStateOptions={{
						enabled:true,
						key: 'covid-medical-person-holds'
					}}
				/>
			</div>
			<Provider holds={holds}>
				<ChangeHoldStatus person={searchUser!.person} show={showHolds} onClose={closeHolds} />
			</Provider>
			<ChangeHoldStatus show={showEdit} person={editRecord} onClose={editHoldStatusClose} />
		</>
	);
};

export const PersonHolds = inject("searchUser", "covidNav", "holds", "roles")(observer(PersonHoldsComponent));
