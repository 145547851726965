import * as React from 'react';
import { defaults, TableSortLabelProps } from './types';

const TableSortLabel: React.FC<React.PropsWithChildren<TableSortLabelProps>> = (
  props: React.PropsWithChildren<TableSortLabelProps>
) => {
  const finalProps = { ...defaults, ...props };
  let {
    children,
    classes,
    IconComponent,
    onClick,
    direction,
    active,
  } = finalProps;
  if (direction === 'asc') {
    IconComponent = 'arrow-down';
  } else if (direction === 'desc') {
    IconComponent = 'arrow-up';
  }
  const style = { display: 'flex', alignItems: 'center' };
  const finalStyle = { ...style, ...props.style };
  let finalClasses = 'HuiTableSortLabel';
  if (classes) finalClasses += ` ${classes}`;
  return (
    <div
      onClick={onClick ? onClick : undefined}
      style={finalStyle}
      className={finalClasses}
    >
      <div>{children}</div>
      {active && (
        <span>
          <i className={`fal fa-${IconComponent}`}></i>
        </span>
      )}
    </div>
  );
};

export default TableSortLabel;
