import * as React from "react";
import { FC, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { SearchUserInterface } from "../../model/SearchUser";
import { Form, Col, Row } from "react-bootstrap";
import { useState } from "react";
import { PersonTests } from "./PersonTests";
import userController, { FetchProfileResponse } from "../../api/UserController";
import peopleController, { FetchPeopleDetailResponse } from "../../api/PeopleController";
import { useLocation } from "react-router";
import { CovidNavInterface } from "../../model/CovidNav";
import { PersonHolds } from "../holds/PersonHolds";
import covidPersonApi, { ExtendedPerson, FetchResponseCovidPerson } from "../../api/CovidPersonApi";
import medicalApi, { ExemptFlagModel, PostStatusResponse } from "../../api/MedicalApi";
import ValidationError from "../../common/form/ValidationError";
import { Button, ButtonState, ButtonType, CheckboxInputGroup, FormContainer, Icon, InputBoxOption, SkeletonLoader } from "@mit/hui";
import { formatPhoneNumber } from "../../common/Formatters";
import moment = require("moment");
import { PersonVaccineProofs } from "./PersonVaccineProofs";
import VaccineExemption from "../vaccineExemptions/VaccineExemption";
import { toast } from "react-toastify";

interface PersonProfileComponentProps {
	searchUser?: SearchUserInterface;
	covidNav?: CovidNavInterface;
}

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const PersonProfileComponent: FC<PersonProfileComponentProps> = ({ searchUser }) => {
	let query = useQuery();
	const [imageUrl, setImageUrl] = useState("");
	const [searchPersonExtended, setSearchPersonExtended] = useState<ExtendedPerson | null>(null);
	const [vaccineCourseInfo, setVaccineCourseInfo] = useState<any | null>(null);
	const [vaccineStatusInfo, setVaccineStatusInfo] = useState<any | null>(null);
	const [hasUploadedVaccines, setHasUploadedVaccines] = useState<boolean>(true);
	const [medicalScheduleIrregularSubmitting, setMedicalScheduleIrregularSubmitting] = useState<boolean>(false);
	const [medicalScheduleIrregular, setMedicalScheduleIrregular] = useState<boolean>(false);
	const [eligible, setEligible] = useState<boolean>(false);
	const [eligibleSubmitting, setEligibleSubmitting] = useState<boolean>(false);
	const [errorText, setErrorText] = useState<any>(undefined);
	const [isLoading, setIsLoading] = useState(false);
	const [isBusy, setIsBusy] = useState(false);

	useEffect(() => {
		fecthUserInfo();
	}, [query.get("krb_name")]);

	const fecthUserInfo = () => {
		if (query.get("krb_name") != null) {
			setIsLoading(true);
			searchUser!.person = undefined;
			userController.fetchProfile(query.get("krb_name")!).then((profileResp: FetchProfileResponse) => {
				if (profileResp.person) {
					setIsLoading(false);
					covidPersonApi.fetchPerson(query.get("krb_name")!).then((peopleDetail: FetchResponseCovidPerson) => {
						searchUser!.person = profileResp.person;
						if (searchUser !== undefined && searchUser.person !== undefined) searchUser.person.mobile = peopleDetail.item?.phone || profileResp.person?.mobile || "";
						if (peopleDetail.item) {
							setSearchPersonExtended(peopleDetail.item);
						}
					});
				}
			});
		}
	};

	/*
    when the component mounts, check if there's a query param present, and if there is, look the person up
     */
	useEffect(() => {
		if (query.get("krb_name") != null) {
			setIsLoading(true);
			searchUser!.person = undefined;
			userController.fetchProfile(query.get("krb_name")!).then((profileResp: FetchProfileResponse) => {
				if (profileResp.person) {
					setIsLoading(false);
					covidPersonApi.fetchPerson(query.get("krb_name")!).then((peopleDetail: FetchResponseCovidPerson) => {
						searchUser!.person = profileResp.person;
						if (searchUser !== undefined && searchUser.person !== undefined) searchUser.person.mobile = peopleDetail.item?.phone || profileResp.person?.mobile || "";

						if (peopleDetail.item) {
							setSearchPersonExtended(peopleDetail.item);
						}
					});
				}
			});
		}
	}, []);

	useEffect(() => {
		getPersonStatusData();
	}, [searchUser!.person]);

	const getPersonStatusData = () => {
		if (searchUser!.person) {
			userController.fetchPicture(searchUser!.person.mitId).then((url: any) => {
				setImageUrl(url.value);
			});

			setSearchPersonExtended(null);
			medicalApi.fetchVaccineCourse(searchUser!.person.mitId).then((response) => {
				if (response.items) {
					setVaccineCourseInfo(response.items);
				}
			});

			medicalApi.fetchVaccineStatus(searchUser!.person.mitId).then((response: any) => {
				if (response.items) {
					setVaccineStatusInfo(response.items);
					setEligible(response.items.status === "none" ? false : true);
				}
			});

			covidPersonApi
				.fetchPerson(searchUser!.person.kerberosId)
				.then((profileResp: FetchResponseCovidPerson) => {
					if (profileResp.item) {
						setSearchPersonExtended(profileResp.item);
						setMedicalScheduleIrregular(profileResp.item.medical_schedule_irregular);
						return profileResp.item.mit_id;
					}
				})
				.then((mitId) => {
					medicalApi.fetchPersonVaccineProofs(mitId!).then((res) => {
						setHasUploadedVaccines(res.items && res.items.length !== 0);
					});
				});
		}
	};

	const cancelSearch = () => {
		searchUser!.person = undefined;
	};

	const clickCheckBox = (event: any) => {
		setMedicalScheduleIrregular(event.target.checked);
	};

	const clickEligibleCheckBox = (event: any) => {
		setEligible(event.target.checked);
	};

	const saveMedicalScheduleIrregular = () => {
		setErrorText("");
		setMedicalScheduleIrregularSubmitting(true);
		medicalApi.putMedicalScheduleIrregular(searchUser!.person!.mitId, medicalScheduleIrregular).then((resp: PostStatusResponse) => {
			setMedicalScheduleIrregularSubmitting(false);
			if (resp.error) setErrorText("Error saving Irregular Schedule");

			covidPersonApi.fetchPerson(searchUser!.person!.kerberosId).then((profileResp: FetchResponseCovidPerson) => {
				if (profileResp.item) {
					setSearchPersonExtended(profileResp.item);
					setMedicalScheduleIrregular(profileResp.item.medical_schedule_irregular);
				}
			});
		});
	};

	const saveEligible = () => {
		setErrorText("");

		let body = {
			mit_id: searchPersonExtended?.mit_id,
			start_date: moment().utc().format("YYYY-MM-DD HH:mm:ss.SSS"),
			vaccine_course_guid: vaccineCourseInfo && vaccineCourseInfo.length > 0 && vaccineCourseInfo[0].vaccine_course_guid ? vaccineCourseInfo[0].vaccine_course_guid : "",
			override: false,
		};

		setEligibleSubmitting(true);

		if (eligible) {
			medicalApi.postVaccineCourse(body).then((response) => {
				setEligibleSubmitting(false);
				setEligible(!eligible);
				fecthUserInfo();
			});
		} else {
			//DELETE COURSE
			medicalApi.deleteVaccineCourse(body).then((response) => {
				setEligibleSubmitting(false);
				setEligible(!eligible);
				fecthUserInfo();
			});
		}
	};

	const getVaccineIcon = () => {
		if (searchPersonExtended?.vaccine_status && searchPersonExtended?.vaccine_status.toLowerCase() === "none") return <></>;

		if (searchPersonExtended?.vaccine_status && searchPersonExtended?.vaccine_status.toLowerCase() === "eligible") return <></>;

		if (searchPersonExtended?.vaccine_status && searchPersonExtended?.vaccine_status.toLowerCase() === "in_progress")
			return (
				<>
					<Icon type={"regular"} icon="shield" color="green" />
				</>
			);

		if (searchPersonExtended?.vaccine_status && (searchPersonExtended?.vaccine_status.toLowerCase() === "completed" || searchPersonExtended?.vaccine_status.toLowerCase() === "full"))
			return (
				<>
					<Icon type={"solid"} icon="shield-check" color="green" />
				</>
			);

		return <></>;
	};

	const checkEligible = () => {
		if (vaccineStatusInfo) {
			if (vaccineStatusInfo?.status === "none") return false;

			return true;
		} else {
			return false;
		}
	};

	const clearVaccine = () => {
		setIsBusy(true);
		medicalApi
			.clearVaccine(searchUser!.person!.mitId)
			.then((response) => {
				toast.success("Successfully cleared", {
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
				getPersonStatusData();
			})
			.catch(() => {
				toast.error("Something went wrong", {
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			})
			.finally(() => {
				setIsBusy(false);
			});
	};

	return (
		<div>
			<div className={"page-content"}>
				{!searchUser!.person && !isLoading && <div>Search for a person above to see their record.</div>}
				{isLoading && <SkeletonLoader type="default" />}
				{searchUser!.person && !isLoading && (
					<div className={"profile-container card-entity"}>
						<div className="container-fluid">
							<Row>
								<div className="card card-entity-container p-3 mb-4 col-xl-6 col-md-12">
									<div className="main-profile-header mb-4">
										{searchUser!.person && (
											<span className={"user-image"}>
												{!imageUrl && <div className={"loading-image"}></div>}
												{imageUrl && <img src={imageUrl} className={"img-object-fit"} />}
											</span>
										)}
										<div className="user-name">
											<h3>
												{searchUser!.person.displayName}
												{getVaccineIcon()}
											</h3>
										</div>
									</div>

									<div className={"person-data"}>
										<Row>
											<Col xs={6}>
												<ValidationError text={errorText} />

												<Row>
													<label className="col-sm-4">MIT ID</label>
													<div className="col-sm-8 mb-3 bold">{searchUser!.person.mitId}</div>
												</Row>
												{searchUser!.person.email && (
													<Row>
														<label className="col-sm-4">Email</label>{" "}
														<a className="col-sm-8 mb-3 bold" href={`mailto:${searchUser!.person.email}`}>
															{" "}
															{searchUser!.person.email}
														</a>
													</Row>
												)}
												{((searchPersonExtended && searchPersonExtended!.phone) || searchUser!.person.mobile) && (
													<Row>
														<label className="col-sm-4">Phone</label>{" "}
														<div className="col-sm-8 mb-3 bold">
															{formatPhoneNumber((searchPersonExtended && searchPersonExtended!.phone) || searchUser!.person.mobile)}
														</div>
													</Row>
												)}
												{searchPersonExtended && (
													<>
														{searchPersonExtended.date_of_birth && (
															<Row>
																<label className="col-sm-4">Date of Birth</label> <div className="col-sm-8 mb-3 bold">{searchPersonExtended.date_of_birth}</div>
															</Row>
														)}
														{searchPersonExtended.gender && (
															<Row>
																<label className="col-sm-4">Gender</label> <div className="col-sm-8 mb-3 bold">{searchPersonExtended.gender}</div>
															</Row>
														)}
														{searchPersonExtended.type && (
															<Row>
																<label className="col-sm-4">Type</label> <div className="col-sm-8 bold">{searchPersonExtended.type}</div>
															</Row>
														)}
														{searchPersonExtended.job_title && (
															<Row>
																<label className="col-sm-4">Title</label> <div className="col-sm-8 bold">{searchPersonExtended.job_title}</div>
															</Row>
														)}
														{searchPersonExtended.department && (
															<Row>
																<label className="col-sm-4">Department</label> <div className="col-sm-8 bold">{searchPersonExtended.department}</div>
															</Row>
														)}
														{searchPersonExtended.student_year && (
															<Row>
																<label className="col-sm-4">Student Year</label> <div className="col-sm-8 bold">{searchPersonExtended.student_year}</div>
															</Row>
														)}
													</>
												)}
											</Col>
											<Col xs={6}>
												{searchPersonExtended && (
													<>
														<Row>
															<label className="col-sm-4">Address</label>
															<div className="col-sm-8 mb-3 bold">
																{!!searchPersonExtended.address.address1 && (
																	<>
																		{searchPersonExtended.address.address1} <br />
																	</>
																)}
																{!!searchPersonExtended.address.address2 && (
																	<>
																		{searchPersonExtended.address.address2} <br />{" "}
																	</>
																)}
																{!!searchPersonExtended.address.city && <>{searchPersonExtended.address.city}, </>}
																{!!searchPersonExtended.address.state && <>{searchPersonExtended.address.state} &nbsp;</>}
																{!!searchPersonExtended.address.postal_code && <>{searchPersonExtended.address.postal_code} </>}
															</div>
														</Row>
													</>
												)}
											</Col>
										</Row>
									</div>
								</div>
							</Row>
							<Row className="mt-2">
								<Col xs={12} md={12} lg={3}>
									<div className="d-flex">
										<Button outlined text="Clear vaccines" icon="sync" onClick={clearVaccine} isBusy={isBusy} state={isBusy ? ButtonState.Disabled : ButtonState.Enabled} />
									</div>
								</Col>
								<Col xs={12} md={12} lg={9}>
									<div className="person-data">
										{searchPersonExtended && (
											<Row className="person-schedule-irregular">
												<Col xs={6}>
													<Row>
														<label className="col-sm-4">Vaccine Hold</label> <div className="col-sm-8 mb-3 bold">{searchPersonExtended.vaccine_hold_status}</div>
													</Row>
												</Col>
												<Col xs={9} className="py-2">
													<Form.Check
														custom
														inline
														label="Irregular Schedule"
														type="checkbox"
														id="checkScheduleIrregular"
														checked={medicalScheduleIrregular}
														onChange={clickCheckBox}
													/>
												</Col>
												{searchPersonExtended.medical_schedule_irregular !== medicalScheduleIrregular && (
													<Col xs={3} className="justify-content-end">
														<Button
															type={ButtonType.Primary}
															padded={false}
															block={true}
															text="Save"
															onClick={saveMedicalScheduleIrregular}
															isBusy={medicalScheduleIrregularSubmitting}
															state={medicalScheduleIrregularSubmitting ? ButtonState.Disabled : ButtonState.Enabled}
														/>
													</Col>
												)}
											</Row>
										)}
										{!hasUploadedVaccines && searchPersonExtended && vaccineCourseInfo && (
											<Row className="person-schedule-irregular">
												<Col xs={9} className="py-2">
													<Form.Check custom label="Eligible for vaccine" type="checkbox" id="checkEligible" checked={eligible} onChange={clickEligibleCheckBox} />
												</Col>
												{checkEligible() !== eligible && (
													<Col xs={3} className="justify-content-end">
														<Button
															type={ButtonType.Primary}
															padded={false}
															block={true}
															text={eligible ? "Save" : "Remove"}
															onClick={saveEligible}
															isBusy={eligibleSubmitting}
															state={eligibleSubmitting ? ButtonState.Disabled : ButtonState.Enabled}
														/>
													</Col>
												)}
											</Row>
										)}
									</div>
								</Col>
							</Row>
							<Row>
								<Col xs={12} md={12} lg={4}>
									<div className={"tests-container"}>
										<PersonTests mitId={searchUser!.person.mitId} />
									</div>
								</Col>

								<Col xs={12} md={12} lg={8}>
									<div className={"tests-container"}>
										<PersonHolds />
									</div>
									<div className="py-2">
										{/* <h4 className="medical-holds profile-table-headers">Exemptions
                    </h4> */}
										<VaccineExemption
											mitId={searchUser!.person.mitId}
											vaccine_status={searchPersonExtended?.vaccine_status}
											vaccine_hold_status={searchPersonExtended?.vaccine_hold_status}
										/>
									</div>
									{/* <h4 className="medical-holds profile-table-headers">Vaccine Exemptions</h4>
									<FormContainer action={submit} id="testForm" formValidationTrigger="onSubmit">
										<CheckboxInputGroup name="vaccine-exemption" group="exemptionSelect" groupOptions={items} label="Vaccine Exemption" unselected={true} />
									</FormContainer> */}
								</Col>
							</Row>
							{/* <Row>
								<Col xs={12} md={12} lg={12}>
									<h4 className="covid-19-tests profile-table-headers">Pending Medical Tests</h4>
									<PendingMedicalTests selectedPerson={searchUser} />
								</Col>
							</Row> */}
							<Row>
								{hasUploadedVaccines && (
									<Col xs={12} md={12} lg={12}>
										<div className="tests-container">
											<PersonVaccineProofs mitId={searchUser!.person.mitId} />
										</div>
									</Col>
								)}
							</Row>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

const PersonProfile = inject("searchUser")(observer(PersonProfileComponent));

export default PersonProfile;
