import { BasicColors, Alignment, Spacing, Elevation } from "./common/GlobalTypes";
import { AccordionItem, AccordionItemState, AccordionItemHeader, AccordionItemHeaderState, AccordionList } from "./components/Accordion";
import { GridContainer, ColumnSize, LayoutColumn, BasicLayout, TemplateFourColumns, TemplateThreeColumns, TemplateTwoColumns, HeroshotLayout } from "./components/Layout";
import {
	TemplateMasterDetail,
	MobileView,
	TemplateModal,
	TemplateModalAlignment,
	TemplateModalSize,
	TemplateSection,
	AtlasPage,
	AtlasTheme,
	AtlasPageHero,
	AtlasPageIntro,
	AtlasFeedbackProps,
	TemplateBranding,
	ELOPage,
	ELOFeedbackProps,
	ELOTheme,
	PageLayout,
} from "./components/Template";
// import { Table, TableRow } from "./components/Table";
import { Dropdown, DropdownItem, DropDownState, DropdownItemProps } from "./components/Dropdown";
import { Button, ButtonType, ButtonState, ButtonProps, ButtonBar, ButtonBarType, ButtonBarProps } from "./components/Button";
import { NavBar, NavBarTypes, ItemAlignment, ResponsiveTypes, NavItem, NavItemState, NavItemType, NavItemProps, NavItemDropdown, NavLogo, MenuBar } from "./components/Nav";
import { MetricCard, MetricCardProps, ContactCard, MetricGroup, CategoryCardList, CategoryCard, ICategoryCardListProps, ICategoryCardProps, GroupColorOptions } from "./components/Cards";
import { CheckboxInputGroup, CheckboxInputGroupProps, InputBoxOption } from "./components/Form";
import ActionList, { ActionListType, ActionListTypes } from "./components/ActionList";
import { AutoComplete, AutoCompleteFilterOn, AutoCompleteType } from "./components/AutoComplete";
import DarkMode from "./components/DarkMode";
import { FileUpload, CFileError } from "./components/FileUpload/FileUpload";
import { FileIcon } from "./components/FileUpload/FileIcon";
import { ImageUpload } from "./components/ImageUpload";
import FilteredList from "./components/FilteredList";
import Label from "./components/Label";
import ListItem, { ListItemState, ListItemProps } from "./components/ListItem";
import MITDiploma, { DiplomaTypes, DiplomaDisplayStyle } from "./components/MITDiploma";
import MITFooter from "./components/MITFooter";
import MITLoggedOut from "./components/MITLoggedOut";
import Text, { TextType } from "./components/Text";
import Container from "./components/Container";
import Spacer from "./components/Spacer";
import { Textbox, TextboxType, TextboxProps, StartIconType } from "./components/Textbox";
import { TextArea } from "./components/TextArea";
import HelpIcon, { HelpIconProps } from "./components/HelpIcon";
import Icon from "./components/Icon";
import Profile from "./components/Profile";
import Loader from "./components/Loader";
import Error, { ErrorType, ErrorContext } from "./components/Error";
import Toast, { ToastProps } from "./components/Toast";
import { Validation, ValidationProps, ValidationType } from "./components/Form";
import Checkbox from "./components/Checkbox";
import Feedback from "./components/Feedback";
import StarRating from "./components/StarRating";
import ProgressBar, { ProgressBarProps } from "./components/ProgressBar";
import ReleaseNotes from "./components/ReleaseNotes";
import ReleaseNoteItem, { ReleaseNoteItemProps } from "./components/ReleaseNoteItem";
import ReleaseNoteFeature from "./components/ReleaseNoteFeature";
import ToastContainer, { ToastContainerProps, ToastPosition } from "./components/ToastContainer";
import MobilePromo from "./components/MobilePromo";
import Drawer, { DrawerType, DrawerProps, DrawerLayout, DrawerSize } from "./components/Drawer";
import PagingContainer, { PagingContainerProps, PagingDotProps } from "./components/PagingContainer";
import { AvailabilityRange } from "./components/AvailabilityControl/AvailabilityRange";
// import PagingDot, { PagingDotProps } from './components/PagingDot';
import { Stepper, Step } from "./components/WizardStepper";
import Barcode, { BarcodeProps } from "./components/Barcode";
import Toggle, { ToggleChoice } from "./components/Toggle";
import { RadioButton } from "./components/RadioButton";
import { FormContainer } from "./components/Form/FormContainer";
import { FormField } from "./components/Form/FormField";
import { ValidationUtility } from "./components/Form/FormValidation";
import Alert, { AlertProps } from "./components/Alert";
import Tooltip from "./components/Tooltip";
import { AdvancedTable, IAdvancedTableColumn, IAdvancedTableFilterOptions, IAdvancedTableExportCsvColumn, TableGrowingMode } from "./components/AdvancedTable";
import SearchList from "./components/SearchList";
import { CalendarInput } from "./components/Calendar/CalendarInput";
import { Form } from "./components/Form/Form";
import Attestation, { AttestationProps, AttestationResponse, AttestationQuestion, AttestationAnswer } from "./components/Covid/Attestation";
import BulkUpload, { BulkUploadField, BulkUploadProps } from "./components/BulkUpload";
import { TimePicker } from "./components/TimePicker";
import SkeletonLoader from "./components/SkeletonLoader";
import Badge from "./components/Badge/Badge";
import { LocationSearch } from "./components/Search";
import { Map } from "./components/Map";
import { AddressSearch } from "./components/Search";
import { MultiFillBar } from "./components/MultiFillBar";
import ContainerThreeColumns from "./components/Container/ContainerThreeColumns";
import { AvailabilityControl, CustomDay, DefaultSelectedType, DefaultSelectedTypeSlot, legendLabelProps, SelectionType, weekDays } from "./components/AvailabilityControl/AvailabilityControl";
import { CircularProgress } from "./components/CircularProgress";
import { huiLog } from "./common/huiConsoleLogs";
import { Chart } from "./components/Chart";

import { TableVirtualized, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TableSortLabel, TableFilter } from "./components/AdvancedDataTable";
import { TableDataProps, TableFilterConfig, TableProps } from "./components/AdvancedDataTable/Table";
import { TableHeadCell, TableHeadCellVirtualized, TableHeadProps } from "./components/AdvancedDataTable/TableHead";
import { TableContainerProps } from "./components/AdvancedDataTable/TableContainer";
import { TableBodyProps } from "./components/AdvancedDataTable/TableBody";
import { TableRowProps } from "./components/AdvancedDataTable/TableRow";
import { TableCellProps } from "./components/AdvancedDataTable/TableCell";
import { TableFooterProps } from "./components/AdvancedDataTable/TableFooter";
import { TablePaginationProps } from "./components/AdvancedDataTable/TablePagination";
import { TableSortLabelProps } from "./components/AdvancedDataTable/TableSortLabel";
import { TableVirtualizedProps } from "./components/AdvancedDataTable/TableVirtualized";
import { Order } from "./stories/DataTable/types";
//import FontAwesomeIcon from "./components/Icon/FontAwesomeIcon";

huiLog.updateInfo(
	"\n-------------------------------------- PLEASE NOTE----------------------------------\n",
	"--------HUI HAS NEW CONSOLE HELPERS FOR VERSIONING AND OTHER MUST KNOW ISSUES-------\n",
	"import {huiLog} from './common/huiConsoleLogs' \n\n",
	"huiLog.updateInfo('THIS IS USEFUL INFO ABOUT AN UPDATE.'); \n",
	"huiLog.updateWarning('THIS IS ABOUT TO BE DEPRICATED, PLEASE FIX ASAP.'); \n",
	"huiLog.updateError('THIS HAS BEEN DEPRICATED, PLEASE FIX '); \n\n",
	"EXAMPLES: \n"
);
huiLog.updateInfo("Example of huiLog.updateInfo() log");
huiLog.updateWarning("Example of huiLog.updateWarning() log");
huiLog.updateError("Example of huiLog.updateError() log");
//EXPORTS
export type { BasicColors, Alignment, Spacing, Elevation };
export { AccordionItem, AccordionItemState, AccordionItemHeader, AccordionItemHeaderState, AccordionList };
export { ActionList, ActionListType };
export type { ActionListTypes };
export { AtlasPage };
export { AtlasPageHero };
export { AtlasPageIntro };
export type { AtlasFeedbackProps, AtlasTheme };
export { ELOPage };
export type { ELOFeedbackProps, ELOTheme };
export { PageLayout };
export { Attestation };
export type { AttestationProps, AttestationResponse, AttestationQuestion, AttestationAnswer };
export { BulkUpload };
export type { BulkUploadField, BulkUploadProps };
export { AutoComplete, AutoCompleteFilterOn };
export type { AutoCompleteType };
export { BasicLayout };
export { Button, ButtonType, ButtonState };
export type { ButtonProps };
export { ButtonBar, ButtonBarType };
export type { ButtonBarProps };
export { Barcode };
export type { BarcodeProps };
export { ContactCard };
export { Checkbox };
export { DarkMode };
export { Dropdown };
export { DropdownItem, DropDownState };
export type { DropdownItemProps };
export { Error, ErrorType, ErrorContext };
export { FileUpload };
export type { CFileError };
export { FileIcon };
export { ImageUpload };
export { FilteredList };
export { FormContainer };
export { Form };
export { FormField };
export { ValidationUtility };
export { GridContainer };
export { LayoutColumn };
export type { ColumnSize };
export { Label };
export { Loader };
export { ListItem, ListItemState };
export type { ListItemProps };
export { MenuBar };
export { MetricCard };
export { MobilePromo };
export { HeroshotLayout };
export type { MetricCardProps };
export { MetricGroup };
export { MITDiploma, DiplomaTypes, DiplomaDisplayStyle };
export { MITFooter };
export { NavBar, NavBarTypes, ItemAlignment, ResponsiveTypes };
export { NavItem, NavItemState, NavItemType };
export type { NavItemProps };
export { NavItemDropdown };
export { NavLogo };
export { Text, TextType };
export { Profile };
export { RadioButton };
export { TemplateBranding };
export { Container };
export { TemplateFourColumns };
export { TemplateMasterDetail, MobileView };
export { TemplateModal, TemplateModalAlignment, TemplateModalSize };
export { TemplateSection };
export { TemplateThreeColumns };
export { TemplateTwoColumns };
export { Textbox, TextboxType };
export type { StartIconType };
export { Toggle };
export type { ToggleChoice };
export type { TextboxProps };
export { Spacer };
export { HelpIcon };
export type { HelpIconProps };
export { Icon };
export { Toast };
export { ToastContainer, ToastPosition };
export type { ToastContainerProps };
export type { ToastProps };
export { MITLoggedOut };
export { Validation };
export type { ValidationProps };
export { ValidationType };
export { TextArea };
export { Feedback };
export { StarRating };
export { ProgressBar };
export type { ProgressBarProps };
export { ReleaseNotes };
export { ReleaseNoteItem };
export type { ReleaseNoteItemProps };
export { ReleaseNoteFeature };
export { Drawer, DrawerType, DrawerLayout, DrawerSize };
// export { PagingDot };
export { Alert };
export type { AlertProps };
export { PagingContainer };
export type { DrawerProps };
export type { PagingDotProps };
export type { PagingContainerProps };

export { Stepper };
export { Step };
export { Tooltip };
export { AdvancedTable, TableGrowingMode };
export type { IAdvancedTableColumn, IAdvancedTableExportCsvColumn, IAdvancedTableFilterOptions };

export { SearchList };
export { CalendarInput };

export { TimePicker };
export { SkeletonLoader };
export { Badge };

export { CategoryCardList, CategoryCard };
export type { ICategoryCardListProps, ICategoryCardProps, GroupColorOptions };

export { CheckboxInputGroup };
export type { CheckboxInputGroupProps, InputBoxOption };

export { LocationSearch };

export { Map };
export { AddressSearch };
export { MultiFillBar };
export { ContainerThreeColumns };
export { AvailabilityControl, weekDays };
export type { SelectionType, DefaultSelectedTypeSlot, DefaultSelectedType, legendLabelProps, CustomDay };
export { AvailabilityRange };
export { CircularProgress };
export { Chart };

export { TableVirtualized };
export { Table };
export { TableHead };
export { TableContainer };
export { TableBody };
export { TableRow };
export { TableCell };
export { TableFooter };
export { TablePagination };
export { TableSortLabel };
export { TableFilter };

export type {
	TableDataProps,
	TableFilterConfig,
	TableProps,
	TableHeadCell,
	TableHeadCellVirtualized,
	TableHeadProps,
	TableContainerProps,
	TableBodyProps,
	TableRowProps,
	TableCellProps,
	TableFooterProps,
	TablePaginationProps,
	TableSortLabelProps,
	TableVirtualizedProps,
	Order,
};
