import React, {FC, useEffect } from "react";
import { BasicColors, Alignment } from "../common/GlobalTypes";
import HelpIcon, { HelpIconProps } from "../components/HelpIcon";
import { huiLog } from "../common/huiConsoleLogs";

export enum TextType
{
    Body,
    Heading1,
    Heading2,
    Heading3,
    Heading4,
    Heading5,
    Small,
    Marked,
    Subscript,
    Supscript
}

type TextTypes = "body" | "h1" | "h2" | "h3" | "h4" | "h5" | "small" | "marked" | "subscript" | "supscript"

export interface TextProps
{
    content: any;
    type?: TextTypes | TextType;
    bold?: boolean;
    italic?: boolean;
    padded?: boolean;
    underlined?: boolean;
    align?: Alignment;
    color?: BasicColors;
    icon?: string;
    help?: HelpIconProps;
    ariaLabel?: string;
    ariaHidden?: boolean;
    properties?: object;
    id?: string;
}

const Text: FC<TextProps> = ({ content, bold, italic, padded, color, type, icon, help, underlined, align, properties, id, ariaHidden, ariaLabel }) => {
    
    useEffect(() => {
        if (
            type === TextType.Body ||
            type === TextType.Subscript ||
            type === TextType.Supscript ||
            type === TextType.Small ||
            type === TextType.Marked ||
            type === TextType.Heading1 ||
            type === TextType.Heading2 ||
            type === TextType.Heading3 ||
            type === TextType.Heading4 ||
            type === TextType.Heading5
        ){
            huiLog.updateWarning(
                `[Deprecation WARNING] \nSwitch all [Text] component types from enum [TextType] to string, with the v2.0.0 release the enums will be depricated.
                \nUse type literal [body | h1 | h2 | h3 | h4 | h5 | small | marked | subscript | supscript] instead of enum. 
                \nType [TextTypes] is also exported from cmponent`
            );
        }

		// eslint-disable-next-line
	}, [])

    const getStyles = () =>  {
        let styles:any = {}
        if(italic) styles.fontStyle = 'italic'
        if(bold) styles.fontWeight = 'bolder'
        if(underlined) styles.textDecoration = 'underline'

        return Object.keys(styles).length === 0? false : styles ;
    }
    const getClasses = () => {
        var classes: string[] = [];

        // I strongly doubt wether these IFs need to be here
        if (color === "blue") classes.push("text-secondary");
        else if (color === "grey") classes.push("text-muted");
        else if (color === "red") classes.push("text-primary");
        else if (color === "green") classes.push("text-success");
        else if (color === "orange") classes.push("text-warning");
        else if (color === "purple") classes.push("text-info");
        else if (color === "white") classes.push("text-white");

        if (padded) classes.push("my-3");
        if (align) classes.push(`text-${align}`)

        return classes.length === 0? false : classes.join(" ");
    }

    const getAttributes = () => {
        let attributes:any = {}

        if(ariaLabel) attributes['aria-label'] = ariaLabel;
        if(id) attributes.id = id;
        if(ariaHidden) attributes['aria-hidden'] = true;
        if(getClasses()) attributes.className = getClasses();
        if(getStyles()) attributes.style = getStyles();

        attributes = properties? {...attributes, ...properties} : attributes 
        return attributes;
    }
    
    return (<>
        {(type === "body" || type === TextType.Body) &&
            <p {...getAttributes()}>
                {!!icon && (
                    <i className={`mr-2 fal fa-${icon}`}></i>
                )}
                {content}
                {help && <HelpIcon {...help} />}
            </p>
        }
        {(type === "subscript" || type === TextType.Subscript) &&
            <span {...getAttributes()}>
                <sub> 
                    {!!icon && (
                        <i className={`mr-2 fal fa-${icon}`}></i>
                    )}
                    {content} 
                </sub>
            </span>
        }
        {(type === "supscript" || type === TextType.Supscript) &&
            <span {...getAttributes()}>
                <sup> 
                    {!!icon && (
                        <i className={`mr-2 fal fa-${icon}`}></i>
                    )}
                    {content} 
                </sup>
            </span>
        }
        { (type === "small" || type === TextType.Small) &&
            <span {...getAttributes()}>
                <small> 
                    {!!icon && (
                        <i className={`mr-2 fal fa-${icon}`}></i>
                    )}
                {content} 
                </small>
            </span>
        }
        { (type === "marked" || type === TextType.Marked) &&
            <span {...getAttributes()}>
                <mark> 
                    {!!icon && (
                        <i className={`mr-2 fal fa-${icon}`}></i>
                    )}
                    {content} 
                </mark>
            </span>
        }
        { (type === "h1" || type === TextType.Heading1) && 
            <h1 {...getAttributes()}>
                {!!icon && (
                    <i className={`mr-2 fal fa-${icon}`}></i>
                )}
                {content}
                {help && <HelpIcon {...help} />}
            </h1>
        }
        { (type === "h2"  || type === TextType.Heading2)&& 
            <h2 {...getAttributes()}>
                {!!icon && (
                    <i className={`mr-2 fal fa-${icon}`}></i>
                )}
                {content}
                {help && <HelpIcon {...help} />}
            </h2>
        }
        { (type === "h3" || type === TextType.Heading3) &&
            <h3 {...getAttributes()}>
                {!!icon && (
                    <i className={`mr-2 fal fa-${icon}`}></i>
                )}
                {content}
                {help && <HelpIcon {...help} />}
            </h3>
        }
        { (type === "h4" || type === TextType.Heading4) &&
            <h4 {...getAttributes()}>
                {!!icon && (
                    <i className={`mr-2 fal fa-${icon}`}></i>
                )}
                {content}
                {help && <HelpIcon {...help} />}
            </h4>
        }
        { (type === "h5" || type === TextType.Heading5) && 
            <h5 {...getAttributes()}>
                {!!icon && (
                    <i className={`mr-2 fal fa-${icon}`}></i>
                )}
                {content}
                {help && <HelpIcon {...help} />}
            </h5>
        }
    </>)
}

Text.defaultProps = {
    content: "",
    bold: false,
    italic: false,
    padded: false,
    underlined: false,
    align: undefined,
    color: undefined,
    type: "body",
    icon: undefined,
    help: undefined,
    ariaLabel: undefined,
    ariaHidden: undefined,
    properties: undefined,
    id: undefined
};

export default Text;