import React, { Fragment } from "react";

import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface Props {
  children: any;
  text: string;
  placement?: "top" | "auto" | "bottom" | "left" | "right";
  className?: string
}

const Component: React.FC<Props> = ({ children, text, placement, className }) => {
  const renderTooltip = (props: any, text: string) => (
    <Tooltip {...props}>{text}</Tooltip>
  );

  if (!text) return <Fragment>{children}</Fragment>;

  return (
    <OverlayTrigger
      placement={placement}
      overlay={(props: any) => renderTooltip(props, text)}
    >
      {children}
    </OverlayTrigger>
  );
};

export default Component;
