import * as React from 'react';
import {inject, observer} from "mobx-react";
import {FC, useEffect, useState, Fragment} from "react";
import userController from "../api/UserController";
import {UserProfileStoreInterface} from "../model/UserProfileStore";
import authProvider from "../api/AuthProvider";
import { Profile, DropdownItemProps, DropDownState } from '@mit/hui';
import AuthProvider from '../api/AuthProvider';


export interface CurrentUserProps {
    userProfileStore?: UserProfileStoreInterface;
}

const ProfileViewComponent: FC<CurrentUserProps> = ({userProfileStore}) => {

    const [imageUrl, setImageUrl] = useState('')

    useEffect(() => {
        userController.fetchPicture().then((url: any) => {
            setImageUrl(url.value)
        })
    }, [])

    const handleLogout = () => {
        let ignore = authProvider.logout()
    }
    const subMenuData: DropdownItemProps[] = [
        {
            icon: 'sign-out', text: 'Logout', state: DropDownState.None, onClick: () =>
            {
                AuthProvider.logout().then((data) => { });
            }
        },
    ];

    return <Fragment>
        <Profile darkmode name={userProfileStore?.userProfile.displayName ? " " + userProfileStore.userProfile.displayName : ""} subtitle={"IS&T"} imageUrl={imageUrl ? imageUrl : "/MIT_logo.png"} submenu={subMenuData} />
    </Fragment>
}

const ProfileView = inject('userProfileStore')(observer(ProfileViewComponent))

export default ProfileView