import React from 'react';

export enum AccordionItemHeaderState
{
    Contracted,
    Expanded
}
 
export interface AccordionItemHeaderProps
{
    name: string,
    icon: string,
    label: string,
    badge: number,
    state: AccordionItemHeaderState,
    compact: boolean,
    onClick?: (item: any) => void
}

//need an action list object passed through as well
//need more logic to ensure we can combine many of these
export default class AccordionItemHeader extends React.Component<AccordionItemHeaderProps>
{
    public static defaultProps =
        {
            name: 'Header',
            icon: 'building',
            label: 'Header',
            badge: 0,
            state: AccordionItemHeaderState.Contracted,
            compact: false
        };

    getClasses(): string
    {
        var classes: string[] = [];

        if (this.props.state === AccordionItemHeaderState.Expanded)
            classes.push('show');
        else
            classes.push('collapsed');

        if (this.props.compact)
            classes.push('compact-header');

        return classes.join(' ');
    }

    getAriaState(): boolean
    {
        if (this.props.state === AccordionItemHeaderState.Expanded)
            return true;
        else
            return false;
    }

    render(): React.ReactNode
    {
        //needs that chevrons to indicate expanded or not, also a class related to it
        return (
            <a onClick={() => this.props.onClick && this.props.onClick(this.props)} className={`card-header ${this.getClasses()}`} role="button" data-toggle="collapse" href={`#${this.props.name}-content`} aria-expanded={this.getAriaState()} aria-controls={`${this.props.name}-content`}>
                <h6><i className={`far fa-${this.props.icon}`}></i>{this.props.label}
                <span className="float-right">
                    {
                        this.props.badge > 0
                        &&
                        <span className="badge badge-primary badge-pill">{this.props.badge}</span>
                    }
                    <i className="far fa-chevron-down"></i>
                </span>
                </h6>
            </a>
        );
    }
}