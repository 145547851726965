import React, { Fragment, useEffect, useRef } from "react";
import { useState } from "react";
import { FC } from "react";
import ReactDOM from "react-dom";
import { v4 as uuidv4 } from "uuid";

export interface TimePickerProps {
	onChange: any;
	disabled?: boolean;
	value?: string;
	name?: string;
	minInterval?: MinuteIntervals;
}

export type MinuteIntervals = "1min" | "15min" | "30min" | "1hr";


export const TimePicker: FC<TimePickerProps> = (props) => {

	const currentHour: any = new Date().getHours();
	const currentMinute: any = new Date().getMinutes();
	const inputRef = useRef<HTMLInputElement | null>(null);

	let [id] = useState<string>(uuidv4());
	let [showTimePicker, setShowTimePicker] = useState<boolean>(false);
	let [hour, setHour] = useState<any>("");
	let [minute, setMinute] = useState<any>("");
	let [selectedHour, setSelectedHour] = useState<number>(0);
	let [selectedMinute, setSelectedMinute] = useState<number>(0);
	let [hourDetails, setHourDetails] = useState<any>();
	let [minuteDetails, setMinuteDetails] = useState<any>();
	let [mid, setMid] = useState<string>("");
	let [dateString, setDateString] = useState<string>("");

	const  useOutsideAlerter = (ref: any) => {
		useEffect(() => {
			function handleClickOutside(event: any) {
				if (ref.current && !ref.current.contains(event.target)) {
					setShowTimePicker(false)
				}
			}
	
			// Bind the event listener
			document.addEventListener("mousedown", handleClickOutside);
			return () => {
				// Unbind the event listener on clean up
				document.removeEventListener("mousedown", handleClickOutside);
			};
		}, [ref]);
	}
	const containerRef = useRef(null);

	useOutsideAlerter(containerRef)

	useEffect(() => {
		let date = new Date();
		let hour = date.getHours();
		let minute = date.getMinutes();

		let customHour = props.value?.split(":")[0];
		let customMin = props.value?.split(":")[1];

		if (customHour) hour = parseInt(customHour);

		let mid = "AM";

		if (hour >= 12) mid = "PM";

		hour = hour > 12 ? hour - 12 : hour;

		setHour(hour);
		setMinute(minute);
		setSelectedHour(hour);
		setSelectedMinute(customMin ? parseInt(customMin) : minute);
		setHourDetails(getHoursDetails());
		setMinuteDetails(getMinutesDetails());
		setMid(mid);
	}, [props.value]);

	useEffect(() => {
		setDateToInput()
	},[selectedHour, selectedMinute, mid])

	const timeToString = (time: number, hour: boolean) => {
		let newTime = time.toString();

		if (time < 10) newTime = "0" + time;

		if (hour && newTime === "00") newTime = "12";

		return newTime;
	};

	const getNumberOfDays = (year: any, month: any) => {
		return 40 - new Date(year, month, 40).getDate();
	};

	const getHoursDetails = () => {
		var arr = [],
			i;
		for (i = 1; i <= 12; i++) {
			arr.push(i);
		}

		return arr;
	};

	const getMinutesDetails = () => {
		var arr = [],
			i;

		if (props.minInterval && props.minInterval === "15min") {
			for (i = 0; i < 60; i) {
				arr.push(i);
				i = i + 15;
			}

			return arr;
		}

		if (props.minInterval && props.minInterval === "30min") {
			for (i = 0; i < 60; i) {
				arr.push(i);
				i = i + 30;
			}
		}

		if (props.minInterval && props.minInterval === "1hr") {
			for (i = 0; i < 60; i) {
				arr.push(i);
				i = i + 60;
			}
		}

		if (props.minInterval && props.minInterval === "1min") {
			for (i = 0; i < 60; i++) {
				arr.push(i);
			}
		}

		if (!props.minInterval) {
			for (i = 0; i < 60; i++) {
				arr.push(i);
			}
		}

		return arr;
	};

	const getTimeFromTimeString = (timeValue: any) => {
		let timeData = timeValue.split(":");

		let hour = timeData[0];
		let minute = timeData[1];
		return { hour, minute };
	};

	const getTimeStringFromTimestamp = () => {
		return timeToString(selectedHour, true) + ":" + timeToString(selectedMinute, false);
	};

	const updateTimeFromInput = () => {
		let timeValue: any = inputRef.current?.value;

		if (timeValue) {
			console.log(timeValue);
			let timeData = getTimeFromTimeString(timeValue);
			if (timeData !== null) {
				let intHour = parseInt(timeData.hour);
				let intMinute = parseInt(timeData.minute);
				let mid = timeValue.split(" ")[1];

				if (/(([0-1][1-9]):([0-5][0-9])) ?([AaPp][Mm])/.test(timeValue)) {
					if (props.onChange) {
						let hour = intHour;
						let min = intMinute;

						if (hour === 12) {
							if (mid === "PM") {
								hour = 12;
							} else {
								hour = 0;
							}
						} else {
							if (mid === "PM") hour = hour + 12;
						}

						let time = (hour < 10 ? "0" + hour : hour.toString()) + ":" + (min < 10 ? "0" + min : min.toString());
						props.onChange(time);
					}
					setSelectedHour(intHour);
					setSelectedMinute(intMinute);
					setMid(mid);
				}

				setHour(timeData.hour);
				setMinute(timeData.minute);
			}
		}
	};

	const setDateToInput = () => {
		let dateString = getTimeStringFromTimestamp();
		
		setDateString(dateString + " " + mid)
	};

	const onHourClick = (hour: any) => {
		let num = parseInt(hour);

		setSelectedHour(num);

		if (num === 12) {
			if (mid === "PM") {
				num = 12;
			} else {
				num = 0;
			}
		} else {
			if (mid === "PM") num = num + 12;
		}

		let time = (num < 10 ? "0" + num : num.toString()) + ":" + (selectedMinute < 10 ? "0" + selectedMinute : selectedMinute.toString());

		if (props.onChange) {
			props.onChange(time);
		}
	};

	const onMinuteClick = (min: any) => {
		let num = parseInt(min);
		let hour = selectedHour;

		setSelectedMinute(min);

		if (hour === 12) {
			if (mid === "PM") {
				hour = 12;
			} else {
				hour = 0;
			}
		} else {
			if (mid === "PM") hour = hour + 12;
		}

		let time = (hour < 10 ? "0" + hour : hour.toString()) + ":" + (num < 10 ? "0" + num : num.toString());

		if (props.onChange) {
			props.onChange(time);
		}
	};

	const onMidClick = (mid: any) => {
		setMid(mid);

		let hour = selectedHour;
		let min = selectedMinute;

		if (hour === 12) {
			if (mid === "PM") {
				hour = 12;
			} else {
				hour = 0;
			}
		} else {
			if (mid === "PM") hour = hour + 12;
		}

		let time = (hour < 10 ? "0" + hour : hour.toString()) + ":" + (min < 10 ? "0" + min : min.toString());

		if (props.onChange) {
			props.onChange(time);
		}
	};

	const isSelectedHour = (hour: any) => {
		return hour === selectedHour;
	};

	const isSelectedMinute = (min: any) => {
		return min === selectedMinute;
	};

	const isCurrentHour = (hour: any) => {
		return hour === currentHour;
	};

	const isCurrentMinute = (min: any) => {
		return min === currentMinute;
	};

	const renderCalendar = () => {
		let hours = hourDetails.map((hour: any, index: any) => {
			return (
				<div className={(isCurrentHour(hour) ? " highlight" : "") + (isSelectedHour(hour) ? " highlight-primary" : "")} key={index}>
					<div className="hours" onClick={() => onHourClick(hour)}>
						{hour}
					</div>
				</div>
			);
		});

		let minutes = minuteDetails.map((min: any, index: any) => {
			return (
				<div className={"" + (isCurrentMinute(min) ? " highlight" : "") + (isSelectedMinute(min) ? " highlight-primary" : "")} key={index}>
					<div className="minutes" onClick={() => onMinuteClick(min)}>
						{min}
					</div>
				</div>
			);
		});

		let midData = ["AM", "PM"];

		let midContext = midData.map((itm: string, index: number) => {
			return (
				<div className={mid === itm ? "highlight-primary" : ""} key={index}>
					<div className="minutes" onClick={() => onMidClick(itm)}>
						{itm}
					</div>
				</div>
			);
		});

		return (
			<Fragment>
				<div id={`timePickerContent-${id}`} className="c-container">
					<div className="row no-gutters">
						<div className="col">{hours}</div>
						<div className="col">{minutes}</div>
						<div className="col">{midContext}</div>
					</div>
				</div>
			</Fragment>
		);
	};

	return (
		<div ref={containerRef} className={`time-textbox text compact ${props.disabled && "disabled"}`}>
			<div onClick={() => setShowTimePicker(true)}>{<input type="text" id={props.name} name={props.name} className="form-control" onChange={updateTimeFromInput} value={dateString} ref={inputRef} />}</div>
			{showTimePicker ? (
				<div className="time-popup swing-in-top-fwd">
					<div className="content">{renderCalendar()}</div>
				</div>
			) : (
				""
			)}
		</div>
	);
};

// export default class TimePicker extends Component<TimePickerProps, TimePickerState> {
// 	private inputRef = React.createRef();
// 	private currentHour: any = new Date().getHours();
// 	private currentMinute: any = new Date().getMinutes();
// 	private id = uuidv4();

// 	constructor(props: TimePickerProps) {
// 		super(props);

// 		let date = new Date();
// 		let hour = date.getHours();
// 		let minute = date.getMinutes();

// 		let customHour = this.props.value?.split(":")[0];
// 		let customMin = this.props.value?.split(":")[1];

// 		if (customHour) hour = parseInt(customHour);

// 		let mid = "AM";

// 		if (hour >= 12) mid = "PM";

// 		hour = hour > 12 ? hour - 12 : hour;

// 		this.state = {
// 			hour: hour,
// 			minute: minute,
// 			selectedHour: hour,
// 			selectedMinute: customMin ? parseInt(customMin) : minute,
// 			hourDetails: this.getHoursDetails(),
// 			minuteDetails: this.getMinutesDetails(),
// 			showTimePicker: false,
// 			mid: mid,
// 			dateString: ""
// 		};
// 	}

// 	componentDidMount() {
// 		this.setDateToInput();
// 		window.addEventListener("click", this.addBackDrop);
// 		document.getElementById(`timePickerContent-${this.id}`)?.removeEventListener("click", this.addBackDrop);
// 	}

// 	componentWillUnmount() {
// 		window.removeEventListener("click", this.addBackDrop);
// 	}

// 	addBackDrop = (e: any) => {
// 		if (this.state.showTimePicker && !ReactDOM.findDOMNode(this)?.contains(e.target)) {
// 			this.showTimePicker(false);
// 		}
// 	};

// 	timeToString(time: number, hour: boolean) {
// 		let newTime = time.toString();

// 		if (time < 10) newTime = "0" + time;

// 		if (hour && newTime === "00") newTime = "12";

// 		return newTime;
// 	}

// 	showTimePicker = (showTimePicker = true) => {
// 		this.setState({ showTimePicker: showTimePicker });
// 	};

// 	getNumberOfDays = (year: any, month: any) => {
// 		return 40 - new Date(year, month, 40).getDate();
// 	};

// 	getHoursDetails = () => {
// 		var arr = [],
// 			i;
// 		for (i = 1; i <= 12; i++) {
// 			arr.push(i);
// 		}

// 		return arr;
// 	};

// 	getMinutesDetails = () => {
// 		var arr = [],
// 			i;

// 		if (this.props.minInterval && this.props.minInterval === "15min") {
// 			for (i = 0; i < 60; i) {
// 				arr.push(i);
// 				i = i + 15;
// 			}

// 			return arr;
// 		}

// 		if (this.props.minInterval && this.props.minInterval === "30min") {
// 			for (i = 0; i < 60; i) {
// 				arr.push(i);
// 				i = i + 30;
// 			}
// 		}

// 		if (this.props.minInterval && this.props.minInterval === "1hr") {
// 			for (i = 0; i < 60; i) {
// 				arr.push(i);
// 				i = i + 60;
// 			}
// 		}

// 		if (this.props.minInterval && this.props.minInterval === "1min") {
// 			for (i = 0; i < 60; i++) {
// 				arr.push(i);
// 			}
// 		}

// 		if (!this.props.minInterval) {
// 			for (i = 0; i < 60; i++) {
// 				arr.push(i);
// 			}
// 		}

// 		return arr;
// 	};

// 	getTimeFromTimeString = (timeValue: any) => {
// 		let timeData = timeValue.split(":");

// 		let hour = timeData[0];
// 		let minute = timeData[1];
// 		return { hour, minute };
// 	};

// 	getTimeStringFromTimestamp = () => {
// 		return this.timeToString(this.state.selectedHour, true) + ":" + this.timeToString(this.state.selectedMinute, false);
// 	};

// 	updateTimeFromInput = () => {
// 		//@ts-ignore
// 		let timeValue = this.inputRef.current.value;
// 		let timeData = this.getTimeFromTimeString(timeValue);
// 		if (timeData !== null) {
// 			let intHour = parseInt(timeData.hour);
// 			let intMinute = parseInt(timeData.minute);
// 			let mid = timeValue.split(" ")[1];

// 			if (/(([0-1][1-9]):([0-5][0-9])) ?([AaPp][Mm])/.test(timeValue)) {
// 				if (this.props.onChange) {
// 					let hour = intHour;
// 					let min = intMinute;

// 					if (hour === 12) {
// 						if (this.state.mid === "PM") {
// 							hour = 12;
// 						} else {
// 							hour = 0;
// 						}
// 					} else {
// 						if (this.state.mid === "PM") hour = hour + 12;
// 					}

// 					let time = (hour < 10 ? "0" + hour : hour.toString()) + ":" + (min < 10 ? "0" + min : min.toString());
// 					this.props.onChange(time);
// 				}
// 				this.setState({ selectedHour: intHour, selectedMinute: intMinute, mid: mid }, () => this.setDateToInput());
// 			}

// 			this.setState({
// 				hour: timeData.hour,
// 				minute: timeData.minute,
// 			});
// 		}
// 	};

// 	setDateToInput = () => {
// 		let dateString = this.getTimeStringFromTimestamp();
// 		//@ts-ignore
// 		this.inputRef.current.value = dateString + " " + this.state.mid;
// 	};

// 	onHourClick = (hour: any) => {
// 		let num = parseInt(hour);

// 		this.setState({ selectedHour: num }, () => this.setDateToInput());

// 		if (num === 12) {
// 			if (this.state.mid === "PM") {
// 				num = 12;
// 			} else {
// 				num = 0;
// 			}
// 		} else {
// 			if (this.state.mid === "PM") num = num + 12;
// 		}

// 		let time = (num < 10 ? "0" + num : num.toString()) + ":" + (this.state.selectedMinute < 10 ? "0" + this.state.selectedMinute : this.state.selectedMinute.toString());

// 		if (this.props.onChange) {
// 			this.props.onChange(time);
// 		}
// 	};

// 	onMinuteClick = (min: any) => {
// 		let num = parseInt(min);
// 		let hour = this.state.selectedHour;

// 		this.setState({ selectedMinute: min }, () => this.setDateToInput());

// 		if (hour === 12) {
// 			if (this.state.mid === "PM") {
// 				hour = 12;
// 			} else {
// 				hour = 0;
// 			}
// 		} else {
// 			if (this.state.mid === "PM") hour = hour + 12;
// 		}

// 		let time = (hour < 10 ? "0" + hour : hour.toString()) + ":" + (num < 10 ? "0" + num : num.toString());

// 		if (this.props.onChange) {
// 			this.props.onChange(time);
// 		}
// 	};

// 	onMidClick = (mid: any) => {
// 		this.setState({ mid: mid }, () => this.setDateToInput());

// 		let hour = this.state.selectedHour;
// 		let min = this.state.selectedMinute;

// 		if (hour === 12) {
// 			if (mid === "PM") {
// 				hour = 12;
// 			} else {
// 				hour = 0;
// 			}
// 		} else {
// 			if (mid === "PM") hour = hour + 12;
// 		}

// 		let time = (hour < 10 ? "0" + hour : hour.toString()) + ":" + (min < 10 ? "0" + min : min.toString());

// 		if (this.props.onChange) {
// 			this.props.onChange(time);
// 		}
// 	};

// 	isSelectedHour = (hour: any) => {
// 		let selectedHour = this.state.selectedHour;

// 		return hour === selectedHour;
// 	};

// 	isSelectedMinute = (min: any) => {
// 		let selectedMin = this.state.selectedMinute;

// 		return min === selectedMin;
// 	};

// 	isCurrentHour = (hour: any) => {
// 		let currentHour = this.currentHour;

// 		return hour === currentHour;
// 	};

// 	isCurrentMinute = (min: any) => {
// 		let currentMin = this.currentMinute;

// 		return min === currentMin;
// 	};

// 	renderCalendar() {
// 		let hours = this.state.hourDetails.map((hour: any, index: any) => {
// 			return (
// 				<div className={(this.isCurrentHour(hour) ? " highlight" : "") + (this.isSelectedHour(hour) ? " highlight-primary" : "")} key={index}>
// 					<div className="hours" onClick={() => this.onHourClick(hour)}>
// 						{hour}
// 					</div>
// 				</div>
// 			);
// 		});

// 		let minutes = this.state.minuteDetails.map((min: any, index: any) => {
// 			return (
// 				<div className={"" + (this.isCurrentMinute(min) ? " highlight" : "") + (this.isSelectedMinute(min) ? " highlight-primary" : "")} key={index}>
// 					<div className="minutes" onClick={() => this.onMinuteClick(min)}>
// 						{min}
// 					</div>
// 				</div>
// 			);
// 		});

// 		let midData = ["AM", "PM"];

// 		let mid = midData.map((itm: string, index: number) => {
// 			return (
// 				<div className={this.state.mid === itm ? "highlight-primary" : ""} key={index}>
// 					<div className="minutes" onClick={() => this.onMidClick(itm)}>
// 						{itm}
// 					</div>
// 				</div>
// 			);
// 		});

// 		return (
// 			<Fragment>
// 				<div id={`timePickerContent-${this.id}`} className="c-container">
// 					<div className="row no-gutters">
// 						<div className="col">{hours}</div>
// 						<div className="col">{minutes}</div>
// 						<div className="col">{mid}</div>
// 					</div>
// 				</div>
// 			</Fragment>
// 		);
// 	}

// 	render() {
// 		return (
// 			<Fragment>
// 				<div className={`time-textbox text compact ${this.props.disabled && "disabled"}`}>
// 					<div onClick={() => this.showTimePicker(true)}>
// 						{
// 							//@ts-ignore
// 							<input type="text" id={this.props.name} name={this.props.name} className="form-control" onChange={this.updateTimeFromInput} ref={this.inputRef} />
// 						}
// 					</div>
// 					{this.state.showTimePicker ? (
// 						<div className="time-popup swing-in-top-fwd">
// 							<div className="content">{this.renderCalendar()}</div>
// 						</div>
// 					) : (
// 						""
// 					)}
// 				</div>
// 			</Fragment>
// 		);
// 	}
// }
