import * as React from 'react';
import { TableVirtualizedProps } from './types';
import TableCell from '../TableCell'
import { AutoSizer, Column, Table, TableCellRenderer, TableHeaderProps, InfiniteLoader } from 'react-virtualized';
import TableVirtualizedMobile from './TableVirtualizedMobile'

class TableVirtualized extends React.PureComponent<TableVirtualizedProps> {
  static defaultProps = {
    headerHeight: 48,
    rowHeight: 48,
  };

  cellRenderer: TableCellRenderer = ({ cellData, columnIndex, rowIndex }) => {
    const { columns, rowHeight, onRowClick } = this.props;
    const isSelect = columns[columnIndex].id === undefined
    let style = {
      height: rowHeight, 
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start'
    }

    if (columnIndex != null && columns[columnIndex].align) {
      if (columns[columnIndex].align === 'right') style.justifyContent = 'flex-end'
      if (columns[columnIndex].align === 'left') style.justifyContent = 'flex-start'
      if (columns[columnIndex].align === 'center') style.justifyContent = 'center'
    }

    return (
      <TableCell
        component="div"
        variant="body"
        style={style}
        // align={(columnIndex != null && columns[columnIndex].numeric) || false ? 'right' : 'left'}
        align={(columnIndex != null && columns[columnIndex].align) ? columns[columnIndex].align : 'left'}
      >
        {
          isSelect ? columns[columnIndex].virtualizedRenderData(onRowClick, rowIndex) : columns[columnIndex].virtualizedRenderData !== undefined ? columns[columnIndex].virtualizedRenderData(cellData) : cellData

        }
      </TableCell>
    );
  };

  headerRenderer = ({ label, columnIndex, sortBy, dataKey, sortDirection }: TableHeaderProps & { columnIndex: number }) => {
    const { headerHeight, columns } = this.props;
    const showSortIndicator = sortBy === dataKey;
    let style = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start'
    }

    if (columnIndex != null && columns[columnIndex].align) {
      if (columns[columnIndex].align === 'right') style.justifyContent = 'flex-end'
      if (columns[columnIndex].align === 'left') style.justifyContent = 'flex-start'
      if (columns[columnIndex].align === 'center') style.justifyContent = 'center'
    }

    const children = [
      <TableCell
        component="div"
        variant="head"
        style={{height: headerHeight}}
        // align={columns[columnIndex].numeric || false ? 'right' : 'left'}
        align={(columnIndex != null && columns[columnIndex].align) ? columns[columnIndex].align : 'left'}
      >
        <span>{label}</span>
      </TableCell>
    ]
    if (showSortIndicator && sortDirection === "ASC") {
      children.push(
        <i className="fal fa-arrow-down"></i>
      );
    }
    else if (showSortIndicator && sortDirection === "DESC") {
      children.push(
        <i className="fal fa-arrow-up"></i>
      );
    }
    return (
      <div style={style}>
        {children}
      </div>

    );
  };

  render() {
    const { onSort, columns, rowHeight, headerHeight, selectable, sort, onRowClick, lazyLoading, isRowLoaded, loadMoreRows, remoteRowCount, ...tableProps } = this.props;
    if (lazyLoading !== undefined && lazyLoading) {
      return (
        <>
          <TableVirtualizedMobile
            {...this.props}
          />
          <InfiniteLoader

            isRowLoaded={isRowLoaded}
            loadMoreRows={loadMoreRows}
            rowCount={remoteRowCount}
          >
            {({ onRowsRendered, registerChild }) => (
              <AutoSizer>
                {({ height, width }) => (
                  <Table
                    ref={registerChild}
                    sort={sort}
                    className="HuiTableVirtualized"
                    height={height}
                    onRowsRendered={onRowsRendered}
                    width={width}
                    rowHeight={rowHeight!}
                    gridStyle={{
                      direction: 'inherit',
                    }}
                    headerHeight={headerHeight!}
                    {...tableProps}
                  >
                    {columns.map(({ id, ...other }, index) => {
                      return (
                        <Column
                          key={id}
                          headerRenderer={(headerProps) =>
                            this.headerRenderer({
                              ...headerProps,
                              columnIndex: index,
                            })
                          }
                          cellRenderer={this.cellRenderer}
                          dataKey={id}
                          {...other}
                        />
                      );
                    })}
                  </Table>
                )}
              </AutoSizer>
            )}

          </InfiniteLoader>
        </>
      )
    }
    return (
      <>
        <TableVirtualizedMobile
          {...this.props} />
        <AutoSizer className={"HuiTableVirtualized"}>
          {({ height, width }) => (
            <Table
              sort={sort}
              height={height}
              width={width}
              rowHeight={rowHeight!}
              gridStyle={{
                direction: 'inherit',
              }}
              headerHeight={headerHeight!}
              {...tableProps}
            >
              {columns.map(({ id, ...other }, index) => {
                return (
                  <Column
                    key={id}
                    headerRenderer={(headerProps) =>
                      this.headerRenderer({
                        ...headerProps,
                        columnIndex: index,
                      })
                    }
                    cellRenderer={this.cellRenderer}
                    dataKey={id}
                    {...other}
                  />
                );}
              )}
            </Table>
          )}
        </AutoSizer>
      </>
    );
  }
}

export default TableVirtualized;
