import React from 'react';

import { DropdownItem, DropdownItemProps, DropDownState } from '../Dropdown';
import { NavItemType } from "../Nav";
import {huiLog} from '../../common/huiConsoleLogs'
import { IconType } from '../Icon';

export interface NavItemDropDownProps
{
    icon: string,
    text: string,
    state: NavItemDropDownState,
    dropdownItems?: DropdownItemProps[],
    dropdownHeader?: string,
    dropdownSubheader?: string,
    id?: string,
    iconStyle?: IconType,
    type?: NavItemType;
}
 
export enum NavItemDropDownState
{
    None,
    Disabled,
    Active
}

export default class NavItemDropDown extends React.Component<NavItemDropDownProps>
{
    public static defaultProps = {
        icon: "",
        text: "Item",
        state: NavItemDropDownState.None
    };

    getClasses(): string
    {
        var classes: string[] = [];

        if (this.props.type === NavItemType.GridDropdown) {
            classes.push('grid-dropdown')
        }

        if (this.props.state === NavItemDropDownState.Active)
            classes.push('active');
        else if (this.props.state === NavItemDropDownState.Disabled)
            classes.push('disabled')

        return classes.join(' ');
    }

    getDropDownState(): DropDownState
    {
        if (this.props.state === NavItemDropDownState.Active)
            return DropDownState.Active;
        else if (this.props.state === NavItemDropDownState.Disabled)
            return DropDownState.Disabled;

        return DropDownState.None;
    }

    getAriaDisabledState(): boolean | undefined
    {
        if (this.props.state === NavItemDropDownState.Disabled)
            return true;

        return undefined;
    }

    renderGridDropdown(): React.ReactNode {
        return (
            <>
                <div className="grid-dropdown-container">
                    <div className="grid-dropdown-header">
                        {this.props.dropdownHeader && 
                            <svg width="49px" height="54px" viewBox="0 0 49 54" version="1.1">
                                <title>Atlas Logo</title>
                                <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <g id="Primary-Menu" transform="translate(-22.000000, -21.000000)">
                                        <g id="Logo" transform="translate(22.000000, 21.000000)">
                                            <path
                                                d="M28.3506045,0 L49,54 L32.1588946,54 L29.3238342,46.6401869 L15.275475,46.6401869 L12.3557858,54 L8.50518135,54 L0,40.5 L15.275475,0 L28.3506045,0 Z M22.1727116,23.4672897 L18.5759931,33.3084112 L25.8963731,33.3084112 L22.1727116,23.4672897 Z"
                                                id="Combined-Shape"
                                                fill="#000000"></path>
                                            <polygon id="Path-4" fill="#DE4141" points="0 40.5 11 40 8.5 54"></polygon>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        }

                        <div className="header-titles">
                            <h3 role="heading" className="grid-dropdown-title">{this.props.dropdownHeader}</h3>
                            <div className="grid-dropdown-subtitle">{this.props.dropdownSubheader}</div>
                        </div>
                    </div>
                    <div className="dropdown-menu-grid">
                        {
                            this.props.dropdownItems && this.props.dropdownItems.map((dropdownItem: DropdownItemProps, number: number) => (
                                <DropdownItem {...dropdownItem} tabindex={0} key={number} state={this.getDropDownState()} iconStyle={this.props.iconStyle} />
                            ))
                        }
                    </div>
                </div>
            </>
        )
    }

    render(): React.ReactNode
    {
        if(!this.props.id){
            huiLog.updateWarning(
                `\nA UNIQUE [id] attributes will soon become required for all NavItemDropDown components. 
                \nPlease update all NavItemDropDown components with this atrribute ASAP.`
            );
        }

        console.log(this.props)
        
        return (
            // MENU BAR wants the <LI> to be active 
            //NAVBARS want the <A> to be active, we need conditional thing here (it's a bootstrap issue)
            <li className={`nav-item dropdown ${this.getClasses()}`} aria-disabled={this.getAriaDisabledState()}>
                <a className={`nav-link dropdown-toggle ${this.getClasses()}`} href="/#" id={this.props.id || "navbarDropdownMenuLink"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className={`fal fa-${this.props.icon}`}></i>
                    {this.props.text}
                </a>
                <div  
                    className={`dropdown-menu ${this.props.type === NavItemType.GridDropdown && 'dropdown-menu-right'}`} 
                    tabIndex={-1} 
                    aria-labelledby={this.props.id || "navbarDropdownMenuLink"} 
                >
                    {this.props.type === NavItemType.GridDropdown ? 
                        this.renderGridDropdown() :
                        this.props.dropdownItems && this.props.dropdownItems.map((dropdownItem: DropdownItemProps, number: number) => (
                            <DropdownItem {...dropdownItem} tabindex={number} key={number} state={this.getDropDownState()} />
                        ))
                    }
                </div>
            </li>
        );
    }
}




