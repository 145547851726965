import React from 'react';
import Container from '../Container';
import { GridContainer, LayoutColumn, ColumnSize } from '../Layout';
import { BasicColors, Alignment} from '../../common/GlobalTypes';

interface ContainerThreeColumnsProps {
    customClassname?: string
    raised?:boolean,
    color?: BasicColors,
    alignment?: Alignment
    showGutters?: boolean;
    leftArea: any
    leftColAlignment?: Alignment
    leftColSize: ColumnSize
    centerArea: any
    centerColAlignment?: Alignment
    centerColSize: ColumnSize
    rightArea: any
    rightColAlignment?: Alignment
    rightColSize: ColumnSize
}

const ContainerThreeColumns: React.FC<ContainerThreeColumnsProps> = (props) => {
    return (
        <Container 
          className={props.customClassname}
          contents={
            <>
              <GridContainer showGutters={props.showGutters}>
                <LayoutColumn alignment={props.leftColAlignment} colSize={props.leftColSize}>
                    {props.leftArea}
                </LayoutColumn>
                <LayoutColumn alignment={props.centerColAlignment} colSize={props.centerColSize}>
                    {props.centerArea}
                </LayoutColumn>
                <LayoutColumn alignment={props.rightColAlignment} colSize={props.rightColSize}>
                    {props.rightArea}
                </LayoutColumn>
              </GridContainer>
            </>
          }
          alignment={props.alignment}
        />
    );
}

export default ContainerThreeColumns;

ContainerThreeColumns.defaultProps = {
    raised: false, 
    alignment: "left",
    showGutters: false,
    leftColAlignment: "left",
    centerColAlignment: "left",
    rightColAlignment: "left"
}