// https://react-carbon-datepicker.rinas.in/
// https://medium.com/swlh/build-a-date-picker-in-15mins-using-javascript-react-from-scratch-f6932c77db09
// https://www.npmjs.com/package/react-carbon-datepicker

import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';

/** @deprecated This component has been deprecated. Use the CalendarInput located in Inputs instead. **/
export enum DatePickerFormat
{
    "MM/DD/YY",
    "DD/MM/YY",
    "YY/MM/DD"
}

/** @deprecated This component has been deprecated. Use the CalendarInput located in Inputs instead. **/
export interface DatePickerProps
{
    onChange: any;
    onStringChange?: any;
    format?: DatePickerFormat;
    name?: string;
    value?: Date;
    disabled?:boolean
}

/** @deprecated This component has been deprecated. Use the CalendarInput located in Inputs instead. **/
export interface DatePickerState
{
    year: any;
    month: any;
    selectedDay: any;
    monthDetails: any;
    showDatePicker: boolean;
}

/** @deprecated This component has been deprecated. Use the CalendarInput located in Inputs instead. **/
export default class DatePicker extends Component<DatePickerProps, DatePickerState>
{
    private oneDay = 60 * 60 * 24 * 1000;
    private todayTimestamp = Date.now() - (Date.now() % this.oneDay) + (new Date().getTimezoneOffset() * 1000 * 60);
    private inputRef = React.createRef();
    private name = this.props.name;

    constructor(props: DatePickerProps)
    {
        super(props)

        const customDate = this.props.value?.getTime();
        let date = new Date();

        let year = date.getFullYear();
        let month = date.getMonth();

        this.state = {
            year: year,
            month: month,
            selectedDay: customDate ? customDate : this.todayTimestamp,
            monthDetails: this.getMonthDetails(year, month),
            showDatePicker: false
        }
    }

    componentDidMount()
    {
        this.setDateToInput(this.state.selectedDay);
        window.addEventListener('click', this.addBackDrop);
    }

    componentWillUnmount()
    {
        window.removeEventListener('click', this.addBackDrop);
    }

    addBackDrop = (e: any) =>
    {
        if (this.state.showDatePicker && !ReactDOM.findDOMNode(this)?.contains(e.target))
        {
            this.showDatePicker(false);
        }
    }

    showDatePicker = (showDatePicker = true) =>
    {
        this.setState({ showDatePicker: showDatePicker })
    }

    daysMap = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    monthMap = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    getDayDetails = (args: any) =>
    {
        let date = args.index - args.firstDay;
        let day = args.index % 7;
        let prevMonth = args.month - 1;
        let prevYear = args.year;
        if (prevMonth < 0)
        {
            prevMonth = 11;
            prevYear--;
        }
        let prevMonthNumberOfDays = this.getNumberOfDays(prevYear, prevMonth);
        let _date = (date < 0 ? prevMonthNumberOfDays + date : date % args.numberOfDays) + 1;
        let month = date < 0 ? -1 : date >= args.numberOfDays ? 1 : 0;
        let timestamp = new Date(args.year, args.month, _date).getTime();
        return {
            date: _date,
            day,
            month,
            timestamp,
            dayString: this.daysMap[day],
            dateString: `${args.year}-${args.month + 1}-${_date}`
        }
    }

    getNumberOfDays = (year: any, month: any) =>
    {
        return 40 - new Date(year, month, 40).getDate();
    }

    getMonthDetails = (year: any, month: any) =>
    {
        let firstDay = (new Date(year, month)).getDay();
        let numberOfDays = this.getNumberOfDays(year, month);
        let monthArray = [];
        let rows = 6;
        let currentDay = null;
        let index = 0;
        let cols = 7;

        for (let row = 0; row < rows; row++)
        {
            for (let col = 0; col < cols; col++)
            {
                currentDay = this.getDayDetails({
                    index,
                    numberOfDays,
                    firstDay,
                    year,
                    month
                });
                monthArray.push(currentDay);
                index++;
            }
        }
        return monthArray;
    }

    isCurrentDay = (day: any) =>
    {
        return day.timestamp === this.todayTimestamp;
    }

    isSelectedDay = (day: any) =>
    {
        return day.timestamp === this.state.selectedDay;
    }

    getDateFromDateString = (dateValue: any) =>
    {
        let dateData = dateValue.split('-').map((d: any) => parseInt(d, 10));
        if (dateData.length < 3)
            return null;

        let year = dateData[0];
        let month = dateData[1];
        let date = dateData[2];
        return { year, month, date };
    }

    getMonthStr = (month: any) => this.monthMap[Math.max(Math.min(11, month), 0)] || 'Month';

    getDateStringFromTimestamp = (timestamp: any) =>
    {
        let dateObject = new Date(timestamp);
        let month = dateObject.getMonth() + 1;
        let date = dateObject.getDate();

        if (this.props.format === DatePickerFormat["YY/MM/DD"])
            return dateObject.getFullYear() + '/' + (month < 10 ? '0' + month : month) + '/' + (date < 10 ? '0' + date : date);


        if (this.props.format === DatePickerFormat["DD/MM/YY"])
            return (date < 10 ? '0' + date : date) + '/' + (month < 10 ? '0' + month : month) + "/" + dateObject.getFullYear();


        if (this.props.format === DatePickerFormat["MM/DD/YY"])
            return (month < 10 ? '0' + month : month) + "/" + (date < 10 ? '0' + date : date) + "/" + dateObject.getFullYear();

        return dateObject.getFullYear() + '/' + (month < 10 ? '0' + month : month) + '/' + (date < 10 ? '0' + date : date);
    }

    setDate = (dateData: any) =>
    {
        let selectedDay = new Date(dateData.year, dateData.month - 1, dateData.date).getTime();
        this.setState({ selectedDay })
        if (this.props.onChange)
        {
            this.props.onChange(selectedDay);
        }
    }

    updateDateFromInput = () =>
    {
        //@ts-ignore
        let dateValue = this.inputRef.current.value;
        let dateData = this.getDateFromDateString(dateValue);
        if (dateData !== null)
        {
            this.setDate(dateData);
            this.setState({
                year: dateData.year,
                month: dateData.month - 1,
                monthDetails: this.getMonthDetails(dateData.year, dateData.month - 1)
            })
        }
    }

    setDateToInput = (timestamp: any) =>
    {
        let dateString = this.getDateStringFromTimestamp(timestamp);
        //@ts-ignore
        this.inputRef.current.value = dateString;
    }

    onDateClick = (day: any) =>
    {
        this.setState({ selectedDay: day.timestamp }, () => this.setDateToInput(day.timestamp));


        if (this.props.onStringChange)
            this.props.onStringChange(day.dateString);

        if (this.props.onChange)
        {
            this.props.onChange(day.timestamp);
        }
    }

    setYear = (offset: any) =>
    {
        let year = this.state.year + offset;
        let month = this.state.month;
        this.setState({
            year,
            monthDetails: this.getMonthDetails(year, month)
        })
    }

    setMonth = (offset: any) =>
    {
        let year = this.state.year;
        let month = this.state.month + offset;
        if (month === -1)
        {
            month = 11;
            year--;
        } else if (month === 12)
        {
            month = 0;
            year++;
        }
        this.setState({
            year,
            month,
            monthDetails: this.getMonthDetails(year, month)
        })
    }

    renderCalendar()
    {
        let days = this.state.monthDetails.map((day: any, index: any) =>
        {
            return (
                <div className={'day-container ' + (day.month !== 0 ? ' disabled' : '') +
                    (this.isCurrentDay(day) ? ' highlight' : '') + (this.isSelectedDay(day) ? ' highlight-primary' : '')} key={index}>
                    <div className='day'>
                        <span onClick={() => this.onDateClick(day)}>
                            {day.date}
                        </span>
                    </div>
                </div>
            )
        })

        return (
            <div className='c-container'>
                <div className='cc-head'>
                    {['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'].map((d, i) => <div key={i} className='day-headings'>{d}</div>)}
                </div>
                <div className='cc-body'>
                    {days}
                </div>
            </div>
        )
    }

    render()
    {
        return (
            <Fragment>
                <div className={`datetime-textbox ${this.props.disabled ? "disabled": ""}`}>
                    <div onClick={() => this.showDatePicker(true)}>
                        {
                            //@ts-ignore
                            <input className="form-control" id={this.name} name={this.name} onChange={this.updateDateFromInput} ref={this.inputRef} />
                        }
                    </div>
                    {this.state.showDatePicker ? (
                        <div className='datetime-popup swing-in-top-fwd'>
                            <div className='header'>

                                <div className='date-button'>
                                    <div className='inner' onClick={() => this.setYear(-1)}>
                                        <span className='mdpchbi-left-arrows'></span>
                                    </div>
                                </div>

                                <div className='date-button'>
                                    <div className='inner' onClick={() => this.setMonth(-1)}>
                                        <span className='mdpchbi-left-arrow'></span>
                                    </div>
                                </div>

                                <div className='mdpch-container'>
                                    <div className='year'>{this.state.year}</div>
                                    <div className='month'>{this.getMonthStr(this.state.month)}</div>
                                </div>

                                <div className='date-button'>
                                    <div className='inner' onClick={() => this.setMonth(1)}>
                                        <span className='mdpchbi-right-arrow'></span>
                                    </div>
                                </div>

                                <div className='date-button' onClick={() => this.setYear(1)}>
                                    <div className='inner'>
                                        <span className='mdpchbi-right-arrows'></span>
                                    </div>
                                </div>

                            </div>
                            <div className='content'>
                                {this.renderCalendar()}
                            </div>
                        </div>
                    ) : ''}
                </div>
            </Fragment>
        )
    }

}