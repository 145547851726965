import authProvider from "./AuthProvider";

export default class BaseController {
    readonly apiHost: string
    readonly apiPathCovid: string
    readonly apiPathDigitalId: string
    readonly apiPathMedical: string
    readonly apiPathPass: string
    readonly apiPathVaccine: string
    readonly apiPathFeature: string
    readonly apiPathVaccineV2: string;

    constructor() {
        this.apiHost = process.env.API_HOST ? process.env.API_HOST : ''
        this.apiPathCovid = process.env.API_PATH_COVID ? process.env.API_PATH_COVID : ''
        this.apiPathDigitalId = process.env.API_PATH_DIGITAL_ID ? process.env.API_PATH_DIGITAL_ID : ''
        this.apiPathMedical = process.env.API_PATH_MEDICAL ? process.env.API_PATH_MEDICAL : ''
        this.apiPathPass = process.env.API_PATH_PASS ? process.env.API_PATH_PASS : ''
        this.apiPathVaccine = process.env.API_PATH_VACCINE ? process.env.API_PATH_VACCINE : ''
        this.apiPathFeature = process.env.API_PATH_FEATURE ? process.env.API_PATH_FEATURE : ''
        this.apiPathVaccineV2 = process.env.API_PATH_VACCINE_V2 ? process.env.API_PATH_VACCINE_V2 : ''
    }

    checkStatusCode(status: number): boolean {
        return status >= 200 && status < 300
    }

    requestHeaderGet = async (moreHeaders?: any): Promise<RequestInit> => {
        let token = await authProvider.getToken();
        const options: RequestInit = {
            method: "GET",
            headers: new Headers({
                'Authorization': 'Bearer ' + token,
                ...moreHeaders
            })
        }
        return options
    }

    requestHeaderPost = async (requestBody: any, moreHeaders?: any): Promise<RequestInit> => {
        let token = await authProvider.getToken();
        const options: RequestInit = {
            method: "POST",
            headers: new Headers({
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                ...moreHeaders
            }),
            body: moreHeaders && moreHeaders['Content-Type'] && moreHeaders['Content-Type'] != 'application/json' ? requestBody : JSON.stringify(requestBody)
        };
        return options
    }

    requestHeaderPut = async (requestBody: any, moreHeaders?: any): Promise<RequestInit> => {
        let token = await authProvider.getToken();
        const options: RequestInit = {
            method: "PUT",
            headers: new Headers({
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                ...moreHeaders
            }),
            body: moreHeaders && moreHeaders['Content-Type'] && moreHeaders['Content-Type'] != 'application/json' ? requestBody : JSON.stringify(requestBody)
        };
        return options
    }

    requestHeaderPatch = async (requestBody: any, moreHeaders?: any): Promise<RequestInit> => {
        let token = await authProvider.getToken();
        const options: RequestInit = {
            method: "PATCH",
            headers: new Headers({
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                ...moreHeaders
            }),
            body: moreHeaders && moreHeaders['Content-Type'] && moreHeaders['Content-Type'] != 'application/json' ? requestBody : JSON.stringify(requestBody)
        };
        return options
    }

    async genericResponse(response: any) {
        if (this.checkStatusCode(response.status)) {
            // let data = await response.json()
            return {
                success: true
            }
        } else {
            return {
                error: true
            }
        }
    }
}