import * as React from 'react';

interface Props {
    text?: string
}

const ValidationError: React.FC<Props> = ({ text }) => {

    if (!text) {
        return <span></span>;
    } else {
        return (
            <div  className={`validation-${text === 'Success' ? 'success' : 'error'}`}>
                {text}
            </div>
        )
    }
}

export default ValidationError;
