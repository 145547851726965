import * as React from 'react'
import  {useState, useEffect, FC} from 'react'
import {AvailabilitySpot} from "./AvailabilitySpot";

import moment from '../../common/MomentExtended'

export interface SelectionType {
  day: string | CustomDay;
  id: string;
  fullDate?: string,
  time: string;
  selected?: boolean;
  displayTime?: string;
  timeFrom?: string;
  timeTo?: string
  excluded?: boolean
}

export interface DefaultSelectedTypeSlot  {
  timeFrom: string
  timeTo?: string
  text?: string[]
  excluded?: boolean
}

export interface DefaultSelectedType {
  dayIndex: number;
  fullDate?: string;
  times:DefaultSelectedTypeSlot[]
}

export type legendLabelProps = {
  [key in 'Available' | 'Unavailable' | 'Inclusion' | 'Exclusion']?: string;
};

let legendLabelDefaults:legendLabelProps = {
  Available: 'Available',
  Unavailable: 'Unavailable',
  Inclusion: 'Selected',
  Exclusion: 'Exclusion'
}

export interface AvailabilityProps {
  type?: 'week' | 'range' | 'incl_excl';
  slotsPerHour: 1 | 2 | 4 | 6 | 12;
  selectionType?: 'incl' | 'excl'
  hourFormat?: "12" | "24"
  startTime: string; // something like 08:00
  endTime: string; // something like 17:00
  selections?: DefaultSelectedType[];
  disabledSelections?: DefaultSelectedType[];
  handleSelection?: (selections: SelectionType[], DefaultSelected: DefaultSelectedType[], dayNumber?: number, time?: string) => void;
  containerClass?: string;
  shouldPaint?: boolean;
  customDays?: CustomDay[]
  highLightDays?: string[]
  customDaysDateFormatString?: string,
  multiClick?: {
    firstClick: (dateAndTime: CustomDay) => void
    secondClick: () => void
    thirdClick?: () => void
  }
  readonly?: boolean
  handleInspect?: (selection: SelectionType) => void
  hourSlots?: boolean;
  hoverIcon?: string;
  legendLabels?: legendLabelProps
}

export interface CustomDay {
  day: string;
  date: string;
  fullDate: string;
}

export interface RenderDay {
  day: string;
  dayIndex: number
  date: string;
  fullDate: string;
  id: string;
}

export const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

export const AvailabilityControl: React.FC<AvailabilityProps> = (
  {
    type,
    selectionType = 'incl',
    customDaysDateFormatString = 'MM-DD-YYYY',
    slotsPerHour,
    legendLabels,
    startTime,
    endTime,
    hourFormat = "24",
    selections,
    handleSelection,
    containerClass,
    shouldPaint,
    customDays,
    highLightDays,
    multiClick,
    readonly,
    handleInspect,
    hoverIcon,
    hourSlots,
    disabledSelections
  }
) => {
  const [selectedSlots, setSelectedSlots] = useState<SelectionType[]>([])
  const [disabledSlots, setDisabledSlots] = useState<SelectionType[]>([])
  const [renderTimes, setRenderTimes] = useState<string[]>([])
  const [renderDays, setRenderDays] = useState<RenderDay[]>([])
  const [isCustom, setIsCustom] = useState<boolean>(false)
  const [isPainting, setIsPainting] = useState<boolean>(false)
  const [paintValue, setPaintValue] = useState<"positive" | "negative" | undefined>()
  const [legendLabelsSet, setLegendLabelsSet] = useState<legendLabelProps>()

  //INIT GRID
  useEffect(() => {
    buildTimes()
    // eslint-disable-next-line
  }, [selections])

  //WAIT FOR DISPLAY DAYS TO MAP SELCTION
  useEffect(()=> {
    mapSelectedSlots()
    if(disabledSelections && renderDays.length > 0) mapDisabledSlots()
    // eslint-disable-next-line
  }, [renderDays, disabledSelections])

  // SET DAYS TO DISPLAY
  useEffect(()=> {
    let isCustomDays = customDays ? true : false
    let dayCfg = customDays? customDays.map(o=> ({...o, id: o.fullDate, dayIndex: moment(o.fullDate).weekday()})) : weekDays.map((o, i)=> ({
      day: o,
      dayIndex: i,
      date: o,
      fullDate: o,
      id: o
    }))

    setRenderDays(dayCfg)
    setIsCustom(isCustomDays)

    // eslint-disable-next-line
  }, [customDays])

  useEffect(()=> {
    //SET LEGEND LABELS
    let labels = {...legendLabelDefaults};
    if(legendLabels){
      labels = type === 'incl_excl' ? {...legendLabelDefaults, ...legendLabels} : {
        Available: legendLabels.Available || legendLabelDefaults.Available,
        Unavailable: legendLabels.Unavailable || legendLabelDefaults.Unavailable,
        Inclusion: legendLabels.Inclusion || legendLabelDefaults.Inclusion,
      }
    }

    if(type !== 'incl_excl') delete labels.Exclusion;

    setLegendLabelsSet(labels)
    // eslint-disable-next-line
  }, [legendLabels, type])
  
  // BUILD THE GRID CONFIG FOR SLOTS
  const buildTimes = () => {
    const range = moment.range(moment(startTime, "HH:mm"), moment(endTime, "HH:mm").add(60, 'm')).by('minutes', { step: 60 / slotsPerHour, excludeEnd: true });
    const times = Array.from(range).map(m => moment(m, "HH:mm").format("HH:mm"))
    setRenderTimes(times);
  };

  //MAKE SINGLE ITEM ARRAY STRUCTRE FROM INPUT STRUCTURE
  const mapSelectedSlots = () => {
    if (
      !selections ||
      !selections.length ||
      !renderDays ||
      !renderDays.length
    ) {
      return;
    }
    
    const preSelectedSlots: SelectionType[] = [];

    selections.forEach((sel) => {
      
      const { dayIndex, times } = sel;
      let selectedRenderDay = renderDays.find(o=> o.dayIndex === dayIndex)
      if(!sel || !selectedRenderDay ) return;
      times.forEach((time) => {
        if (customDays) {
          if (selectedRenderDay!.fullDate === sel.fullDate) {    
            preSelectedSlots.push({
              id: selectedRenderDay!.id,
              day: `${selectedRenderDay!.day} - ${selectedRenderDay!.date}`,
              fullDate: selectedRenderDay!.fullDate,
              time: time.timeFrom,
              timeTo: time.timeTo,
              excluded: time.excluded || false
            })
          }
        } else {

          let standardDay = {
            id: selectedRenderDay!.id,
            day: selectedRenderDay!.day,
            time: time.timeFrom,
            timeTo: time.timeTo,
            excluded: time.excluded || false
          }

          preSelectedSlots.push(standardDay)
        }
      })
    });

    setSelectedSlots(preSelectedSlots);
  };

  //MAKE SINGLE ITEM ARRAY STRUCTRE FROM DISABLED INPUT STRUCTURE
  const mapDisabledSlots = () => {

    if (
      !disabledSelections ||
      !disabledSelections.length
    ) {
      return;
    }
  
    const preDisabledSlots: SelectionType[] = [];
    disabledSelections.forEach((sel) => {
      const { dayIndex, times } = sel;
      let selectedRenderDay = renderDays.find(o=> o.dayIndex === dayIndex)
      if(!sel || !selectedRenderDay ) return;

      times.forEach((time) => {
        if (customDays) {
          let customDay = {
            id: selectedRenderDay!.id,
            day: `${selectedRenderDay!.day} - ${selectedRenderDay!.date}`,
            fullDate: selectedRenderDay!.fullDate,
            time: time.timeFrom,
            timeTo: time.timeTo,
            excluded: time.excluded || false,
          };

          if (selectedRenderDay!.fullDate === sel.fullDate) {
            preDisabledSlots.push(customDay);
          }
        } else {
          
          let standardDay = {
            id: selectedRenderDay!.id,
            day: selectedRenderDay!.day,
            time: time.timeFrom,
            timeTo: time.timeTo,
            excluded: time.excluded || false,
          };

          preDisabledSlots.push(standardDay);
        }
      });
    });

    setDisabledSlots(preDisabledSlots);
  };

  // MAPS SINGLE SLOTS BACK TO INPUT STRUCTURE
  const mapDefaultSelectedType = (slotArray:SelectionType[] ):DefaultSelectedType[] => {

    let newArr:DefaultSelectedType[] = [];
    [...slotArray].forEach(slot=> {
      
      let dayIndex:number = renderDays.findIndex(day=> day.id === slot.id)

      if(dayIndex > -1){
        if(newArr.findIndex( nSlot => customDays? (slot.fullDate === nSlot.fullDate) : (dayIndex === nSlot.dayIndex) ) === -1){

          let times:DefaultSelectedTypeSlot[] = [...slotArray].filter(s=> s.id === slot.id).map(s => ({
            timeFrom: s.time as string,
            timeTo: s.timeTo,
            excluded: s.excluded
          }))
  
          newArr.push({
            dayIndex: isCustom? moment(slot.fullDate).weekday() : dayIndex,
            fullDate: isCustom ? slot.fullDate : undefined,
            times,
          });
        }
      }
    })
    return newArr.sort((a,b) => a.dayIndex - b.dayIndex );
  }

  const triggerChange = (slotsArray: SelectionType[]) => {
    if (handleSelection) {
      handleSelection(slotsArray, mapDefaultSelectedType(slotsArray))
    }
  }

  //MAPPING FUNCTION(s) EACH TIME A SLOT IS SELECTED -> Triggers parent on change
  const handleSelect = (selection: SelectionType) => {
    const today = moment().format(customDaysDateFormatString)

    if (handleInspect) {
      if (!selections) {
        handleInspect(selection)
        return;
      }

      const newSelection = {...selection}
      const selectedDay = selections.findIndex(sel => sel.fullDate === newSelection.day)
      if (selectedDay < 0 || !selections[selectedDay] ) {
        handleInspect(selection)
        return;
      }

      const selectedSlot = selections[selectedDay].times.find(time => {
        // return (moment(`${today} ${selection.time}`).isSameOrAfter(moment(`${today} ${time.timeFrom}`))) && moment(`${today} ${selection.time}`).isBefore(moment(`${today} ${time.timeTo}`))
        // return (moment(`${today} ${selection.time}`).isBetween(`${today} ${time.timeFrom}`, `${today} ${time.timeTo}`))
        return moment(`${today} ${selection.time}`).isBetween(`${today} ${time.timeFrom}`, `${today} ${time.timeTo}`, 'm', '[]')
      })

      if (selectedSlot) {
        newSelection.timeFrom = selectedSlot.timeFrom
        newSelection.timeTo = selectedSlot.timeTo
      }
      
      handleInspect(newSelection)
    }
    
    let slotsArray = [...selectedSlots];
    const selectedDay = renderDays.find(day => day.id === selection.id);
    const selectionIndex = slotsArray.findIndex(slot => (slot.id === selection.id && slot.time === selection.time))

    if(selectionIndex === -1) {
      let excluded = selectionType === 'excl'
      let newSlot = isCustom
      ? {
          ...selection,
          day: isCustom && selectedDay ? `${selectedDay.day} - ${selectedDay.date}` : selection.day,
          timeTo: moment(selection.time, "HH:mm").add(60/slotsPerHour, 'm').format("HH:mm"),
          time: selection.time,
          excluded
        }
      : (selection = {
          ...selection,
          day: selection.day,
          timeTo: moment(selection.time, "HH:mm").add(60/slotsPerHour, 'm').format("HH:mm"),
          time: selection.time,
          excluded
        })
      slotsArray.push(
        newSlot
      );
    } else {

      slotsArray.splice(selectionIndex, 1)
    }

    if (!readonly) {
      setSelectedSlots(slotsArray)
    }

    triggerChange(slotsArray)
  }

  const handlePaint = (painting: boolean) => {
    if (!shouldPaint) {
      return
    }
    setIsPainting(painting)
  }

  const handleSetPaint = (value: boolean) => {
    setPaintValue(value ? 'positive' : "negative")
  }

  //GETS SELECTED STATE AS ST RENDERS 
  const checkSelected = (day: RenderDay, time: string) => {

    if (!day) {
      return false;
    }

    const allSlots = [...selectedSlots]
    let isSelected = false;
    let selectedDayIndex = -1;
    let selectedTimeIndex = -1;

    if (!isCustom) {
      const today = moment().format(customDaysDateFormatString);

      selectedTimeIndex = allSlots.findIndex((slot) => {
        let timeMatch = slot.time === time;
        let dayMatch = slot.day === day.day;
        let timeIsBetween = moment(`${today} ${time}`).isBetween(`${today} ${slot.time}`, `${today} ${slot.timeTo}`, 'm', '[)')

        return (timeMatch || timeIsBetween) && dayMatch;

      });

      isSelected = selectedTimeIndex > -1;

    } else {
      
      selectedDayIndex = renderDays.findIndex((d) => d.id === day.id);

      
      selectedTimeIndex = allSlots.findIndex((slot) => {
        
        let timeIsBetween = moment(`${day.fullDate} ${time}`).isBetween(`${slot.fullDate} ${slot.time}`, `${slot.fullDate} ${slot.timeTo}`, 'm', '[)')
        let slotDateIsSelected = slot.id === renderDays[selectedDayIndex].id;
        let timeMatch = slot.time === time;
        return (timeMatch || timeIsBetween) && slotDateIsSelected;

      });

      isSelected = selectedDayIndex > -1 && selectedTimeIndex > -1;
    }
    return isSelected;
  };

  //GETS DISABLED STATE AS ST RENDERS 
  const checkDisabled = (day: RenderDay, time: string) => {

    if (!day) {
      return false;
    }

    const allSlots = [...disabledSlots]

    let isSelected = false;
    let selectedDayIndex = -1;
    let selectedTimeIndex = -1;

    if (!isCustom) {
      const today = moment().format(customDaysDateFormatString);

      selectedTimeIndex = allSlots.findIndex((slot) => {
        let timeMatch = slot.time === time;
        let dayMatch = slot.day === day.day;
        let timeIsBetween = moment(`${today} ${time}`).isBetween(`${today} ${slot.time}`, `${today} ${slot.timeTo}`, 'm', '[)')

        return (timeMatch || timeIsBetween) && dayMatch;

      });

      isSelected = selectedTimeIndex > -1;

    } else {

      selectedDayIndex = renderDays.findIndex((d) => d.id === day.id);

      selectedTimeIndex = allSlots.findIndex((slot) => {
        
        let timeIsBetween = moment(`${day.fullDate} ${time}`).isBetween(`${slot.fullDate} ${slot.time}`, `${slot.fullDate} ${slot.timeTo}`, 'm', '[)')
        let slotDateIsSelected = slot.id === renderDays[selectedDayIndex].id;
        let timeMatch = slot.time === time;

        return (timeMatch || timeIsBetween) && slotDateIsSelected;

      });

      isSelected = selectedDayIndex > -1 && selectedTimeIndex > -1;
    }
    return isSelected;
  };

  //GETS EXCLUDED STATE AS ST RENDERS 
  const checkExcluded = (day: RenderDay, time: string) => {

    if (!day) {
      return false;
    }
    const allSlots = [...selectedSlots]

    let isExcluded = false;
    let selectedDayIndex = -1;
    let selectedTimeIndex = -1;

    if (!isCustom) {
      const today = moment().format(customDaysDateFormatString);

      selectedTimeIndex = allSlots.findIndex((slot) => {
        let timeMatch = slot.time === time;
        let dayMatch = slot.day === day.day;
        let timeIsBetween = moment(`${today} ${time}`).isBetween(`${today} ${slot.time}`, `${today} ${slot.timeTo}`, 'm', '[)')
        return (timeMatch || timeIsBetween) && dayMatch;

      });

      isExcluded = selectedTimeIndex > -1 ? allSlots[selectedTimeIndex].excluded? true : false : false;

    } else {
      selectedDayIndex = renderDays.findIndex((d) => d.id === day.id);

      selectedTimeIndex = selectedSlots.findIndex((slot) => {
        let timeIsBetween = moment(`${day.fullDate} ${time}`).isBetween(`${slot.fullDate} ${slot.time}`, `${slot.fullDate} ${slot.timeTo}`, 'm', '[)')
        let slotDateIsSelected = slot.id === renderDays[selectedDayIndex].id;
        let timeMatch = slot.time === time;

        return (timeMatch || timeIsBetween) && slotDateIsSelected;

      });
      isExcluded = selectedDayIndex > -1 && selectedTimeIndex > -1? allSlots[selectedTimeIndex].excluded? true : false : false;
    }

    return isExcluded;
  };
  
  //JSX GETS GRID DAY/DATE headings
  const getDayLabels = () => {

    const selectUnselectAll = (day:RenderDay)=> {
      if(!day) {
        return;
      }
      
      let slots = [...selectedSlots];
      let daySlots = slots.filter(o=> o.id === day.id);

      if(daySlots.length === renderTimes.length ){
        ///unselect all
        slots = slots.filter(o=> o.id !== day.id);
      }else{
        //select all
        slots = slots.filter(o=> o.id !== day.id);
        
        let allDay = renderTimes.map(o=> ({
          ...day,
          time: o,
          timeTo: moment(o, "HH:mm").add(60/slotsPerHour, 'm').format("HH:mm"),
          excluded: selectionType === 'excl'
        }))
        .filter(o=> !checkDisabled( day , o.time))

        slots = [...slots, ...allDay]
      }
      
      setSelectedSlots(slots);
      triggerChange(slots)

    }
    
    return renderDays.map((day, index) => (
        <div role="button" className={`Availability-day-thin`} key={`Availability-day-thin-${day.date}-${index}`} onDoubleClick={()=> selectUnselectAll(day) } title="Double-click to select or unselect entire day" aria-label={`Double-click to select or unselect entire ${day.day} ${day.date}`}>
          <p >{day.day}</p>
          {isCustom && <p>{day.date}</p>}
          <div className={`Availability-day-line${highLightDays?.includes(isCustom ? day.fullDate : day.day)? ' highlighted': ''} `}></div>
        </div>
      ) 
    )
  }

  //gets time text and suffix 
  const formatTime = (time: string):{ time:string, suffix:string } => {
    let suffix = moment(time, "HH:mm").format("A").toLocaleLowerCase();
    time =  moment(time, "HH:mm").format(hourFormat === "12" ? "hh:mm" : "HH:mm");
    return {time, suffix}
  }

  //JSX builds Time row labels
  const getTimeLables = () => {
    return renderTimes.map((t, index) => {
      let timeObj = formatTime(t);
      // return <p key={`render-time-label-${index}`} >{t.split(":")[1] === '00' && <>{ timeObj.time } <span>{timeObj.suffix}</span></>}</p>
      return t.split(":")[1] === '00' ? <p key={`render-time-label-${index}`} >{ timeObj.time } <span>{timeObj.suffix}</span></p> : <></>
    })
  }

  //JSX builds the slot grid
  const renderGrid = () => {
    return (renderTimes.map((time, key) => (
      <div className={`availability-row${time.split(":")[1] === '00' ? ' start' : ''}`} key={`availability_row_${time}_${key}`} >
        {renderDays.map((day, index) => {
          
          let timeTo = moment(time, "HH:mm").add(60/slotsPerHour, 'minute').format("HH:mm")
          let args = {
            selected: checkSelected(day, time),
            disabled: checkDisabled(day, time),
            excluded: checkExcluded(day, time),
            tooltip: `${formatTime(time).time}${formatTime(time).suffix} - ${formatTime(timeTo).time}${formatTime(timeTo).suffix}`
          }

          return (
            <AvailabilitySpot
              {...args}
              onSelect={() => handleSelect({...day, time })}
              text={[]}
              selectionType={selectionType}
              painting={isPainting}
              paintValue={paintValue}
              key={`slot_${day.id}-${time}-${index}`}
              setPaint={
                shouldPaint ? (value) => handleSetPaint(value) : undefined
              }
              readonly={readonly}
              hoverIcon={hoverIcon}
            />
          )}
        )}
      </div>
    )))
  };

  //JSX FOR LEGEND LABELS UNDERNEATH GRID
  const getLegendLabels = () => {
    return legendLabelsSet && 
      <div className="availability-legend-row">
        {Object.keys(legendLabelsSet).map((labelType) => <>
          <div key={`availability-legend-${labelType}-dot`} className={`availability-legend ${labelType.toLocaleLowerCase()}`}/>
          <p key={`availability-legend-${labelType}-label`}>{legendLabelsSet[labelType as keyof typeof legendLabels]}</p>
        </>)}
      </div>
  }

  return (
    <div
      className={`availability-control-container${containerClass ? ` ${containerClass}` : ''}`}
      onMouseDown={() => handlePaint(true)}
      onMouseUp={() => handlePaint(false)}
      onMouseLeave={() => handlePaint(false)}
    >
      <div className="availability-row-days">
        <div className={'availability-row-days-spacer'} />
        {getDayLabels()}
      </div>
      
      <div className="availability-control-body">
        <div className="availability-control-times">
          {getTimeLables()}
        </div>
        <div className="availability-control-grid">
          {selectedSlots && renderGrid()}
        </div>
      </div>
      {getLegendLabels()}
    </div>
  )
}