import * as React from "react";
import { Alert, Col, Modal, Row } from "react-bootstrap";
import { TimePicker } from "@mit/hui";
import { FunctionComponent, useEffect, useState } from "react";
import { PeopleStoreInterface } from "../../model/PeopleStore";
import medicalApi, { FetchFollowUpsResponse, PostStatusResponse } from "../../api/MedicalApi";
import { inject, observer } from "mobx-react";
import moment = require("moment");
import * as Loader from "react-loader";
import { CONTACT_SUPPORT_LINK, mediumLoaderOptions } from "../../common/Defaults";
import { PageError } from "../../types/PageErrors";
import { ButtonBar, ButtonBarType, Button, ButtonType, Drawer, TextType, Text, DrawerType, DrawerSize, ButtonState, GridContainer, LayoutColumn, CalendarInput, Dropdown } from "@mit/hui";

export interface TestResultPerson {
	name: string;
	kerberosId: string;
}

interface RecordTestResultProps {
	person: TestResultPerson;
	showCalendar?: boolean;
	peopleStore?: PeopleStoreInterface;
	actionText: string;
	callbackFn?: any;
}

interface ValidationType {
	valid: boolean;
	validationText: string;
}

interface FieldValidations {
	[key: string]: ValidationType;
}

export const validationDefault = {
	valid: true,
	validationText: "",
};
const RecordTestResultComponent: FunctionComponent<RecordTestResultProps> = ({ person, showCalendar, peopleStore, actionText, callbackFn }) => {
	const now = new Date();
	const [isLoading, setIsLoading] = useState(false);
	const [show, setShow] = useState(false);
	const [result, setResult] = useState<string>("");
	const [completedDate, setCompletedDate] = useState<moment.Moment>(moment());
	const [pageError, setPageError] = useState<PageError>({ error: false, errorText: "" });
	const [testTime, setTestTime] = useState<moment.Moment>(moment());

	useEffect(() => {
		setResult("");
		setPageError({ error: false, errorText: "" });
		setCompletedDate(moment());
		setTestTime(moment());
		setIsLoading(false);
	}, [show]);

	const handleShow = () => {
		setShow(true);
	};

	const handleClose = () => {
		setShow(false);
	};

	const submitAndClose = () => {
		if (result != "") {
			setPageError({ error: false, errorText: "" });
			setIsLoading(true);
			let testDateTime = `${completedDate.format("YYYY-MM-DD")}T${testTime.format("HH:mm:ssZ")}`;

			medicalApi
				.postSchedule(person.kerberosId, testDateTime, result)
				.then((response: PostStatusResponse) => {
					medicalApi
						.fetchPersonTests()
						.then((response: FetchFollowUpsResponse) => {
							if (response.items) {
								peopleStore!.setScheduleList(response.items);
								peopleStore!.setTestResultList(response.items);
							}

							// if (callbackFn) {
							// 	callbackFn();
							// }
						})
						.catch((e) => {
							setPageError({ error: true, errorText: e });
						});

					if (callbackFn) {
						callbackFn();
					}
					
					handleClose();
				})
				.catch((e) => {
					setPageError({ error: true, errorText: e });
				})
				.finally(() => {
					setIsLoading(false);
				});
		} else {
			setPageError({ error: true, errorText: "Please complete all the fields and try again." });
		}
	};

	const updateCallback = (newValue: any) => {
		setCompletedDate(moment(newValue));
	};

	const handleChangeStartTime = (value: any): void => {
		let valArr = value.split(":");
		setTestTime(moment({ hour: valArr[0], minute: valArr[1] }));
	};

	const handleChangeResult = (event: any) => {
		setResult(event.text);
	};

	return (
		<>
			<Button type={ButtonType.TextNaked} icon="calendar-plus" onClick={handleShow} text={actionText} />
			<Drawer
				contentAriaLabel="Record Test Results"
				header={<Text content={`Record Test Results`} type={TextType.Heading3} icon="" />}
				type={DrawerType.Right}
				themedColor={"medical"}
				show={show}
				onClose={handleClose}
				size={DrawerSize.Small}
				footer={[]}
				contents={
					<>
						{pageError.error && (
							<Alert
								onClose={() => {
									setPageError({ error: false, errorText: "" });
								}}
								variant={"danger"}
								dismissible>
								{pageError.errorText}
							</Alert>
						)}
						<h4 className="mb-4">{person.name}</h4>
						<GridContainer>
							<LayoutColumn colSize={6}>
								<label htmlFor={"testResults"} className={"displayBlock"}>
									Test Result
								</label>
								<Dropdown
									id={`record_test_result`}
									onChange={handleChangeResult}
									value={result}
									text="Select Test Result"
									name="testResults"
									dropdownItems={[
										{ icon: "", text: "Positive" },
										{ icon: "", text: "Negative" },
										{ icon: "", text: "Invalid" },
									]}
								/>

								<div className="mb-3"></div>

								<label htmlFor={"start-date"} className={"displayBlock"}>
									Date Tested
								</label>
								<CalendarInput placeholder="MM/DD/YYYY" value={completedDate.format("YYYY-MM-DD")} onChange={updateCallback} id={"start-date"} />

								<div className="mb-3"></div>

								<label htmlFor={"test-time"} className={"displayBlock"}>
									Time Tested
								</label>
								<br />
								<TimePicker onChange={handleChangeStartTime} name={"testTime"} />

								<div className="mb-5"></div>
							</LayoutColumn>
						</GridContainer>
						<ButtonBar
							type={ButtonBarType.Padded}
							buttons={[
								{
									icon: "",
									text: "Submit",
									isBusy: isLoading,
									state: isLoading ? ButtonState.Disabled : ButtonState.Enabled,
									type: ButtonType.Primary,
									onClick: submitAndClose,
								},
								{
									icon: "",
									text: "Cancel",
									state: isLoading ? ButtonState.Disabled : ButtonState.Enabled,
									type: ButtonType.Secondary | ButtonType.Ghost,
									onClick: handleClose,
								},
							]}
						/>
						{/* <Button type={ButtonType.Primary} text="Submit" onClick={submitAndClose} state={isLoading ? ButtonState.Disabled : ButtonState.Enabled} />
                        <Button type={ButtonType.Secondary | ButtonType.Ghost} text="Cancel" onClick={handleClose} state={isLoading ? ButtonState.Disabled : ButtonState.Enabled} /> */}
					</>
				}
			/>
		</>
	);
};

const RecordTestResult = inject("peopleStore")(observer(RecordTestResultComponent));
export default RecordTestResult;
