import * as React from "react";
import { FC, useState, useEffect } from "react";
import { Error, ErrorContext, ErrorType, Icon, Button, SkeletonLoader } from "@mit/hui";
import userController from "../../api/UserController";

interface PersonProfilePictureProps {
	id: string;
	person?:string;
	highResolution?: boolean;
	type?: "rounded" | "large" | "round-large";
}

const PersonProfilePicture: FC<PersonProfilePictureProps> = ({ id, highResolution, type, person }) => {
	const [profilePicture, setProfilePicture] = useState<string | null>("");
	const [isLoading, setLoading] = useState(false);

	useEffect(() => {
		setProfilePicture(null)
		if (id) {
			setLoading(true);
			userController.fetchPicture(id, highResolution).then((response) => {
				if (response.value) {
					setProfilePicture(response.value);
					setLoading(false);
				}
			});
		}
	}, [id, highResolution]);

	return (
		<React.Fragment>
			{type === "rounded" && <div className="profile-pic">{profilePicture ? <img src={profilePicture} alt={"profile"} /> : <Icon type="regular" icon="user" />}</div>}
			{type === "round-large" && <div className="profile-pic-large">{profilePicture ? <img src={profilePicture} alt={"profile"} /> : <Icon type="regular" icon="user" />}</div>}
			{type === "large" && (
				<div className="main-profile-header m-4">
					<span className={"user-image"}>
						{isLoading && <SkeletonLoader type={`large-image`}/>}
						{!isLoading && profilePicture && <img src={profilePicture} alt={`${person || ''} Profile Picture`} className={"img-object-large-fit"} />}
					</span>
				</div>
			)}
		</React.Fragment>
	);
};
export default PersonProfilePicture;

PersonProfilePicture.defaultProps = {
	type: "rounded",
};
