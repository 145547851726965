import * as React from "react";
import { Row, Col } from "react-bootstrap";
import { FunctionComponent, useRef, useState } from "react";
import medicalApi, { FetchFollowUpsResponse, FluShotDetailResponse } from "../../api/MedicalApi";
import * as Loader from "react-loader";
import moment = require("moment");
import { CONTACT_SUPPORT_LINK, mediumLoaderOptions } from "../../common/Defaults";
import { Drawer, TextType, Text, DrawerType, Button, ButtonType, ButtonState, Alert, DrawerSize, BulkUploadField, BulkUpload } from "@mit/hui";
import { peopleStore } from "../../model/PeopleStore";
import { covidNav } from "../../model/CovidNav";

const VaccineUpload: FunctionComponent<any> = ({ hideModalCallback, show, onClose }) => {
	const [uploadSuccess, setUploadSuccess] = useState<boolean | undefined>(undefined);
	const [errorMessage, setErrorMessage] = useState<string>("");
	const [isLoading, setIsLoading] = useState(false);
	const [uploadFile, setUploadFile]: any = useState({ name: "", file: null });

	const fileUploadedInput: any = useRef(null);

	React.useEffect(() => {
		setUploadSuccess(undefined)
		setErrorMessage("")
	}, [show])

	const handleClose = () => {
		setUploadFile({ name: "", file: null });
		if (fileUploadedInput.current) {
			fileUploadedInput.current.value = "";
		}
		onClose();
	};

	const submitAndClose = async () => {
		if (uploadFile.name) {
			setIsLoading(true);
			let uploadResponse = await medicalApi.uploadFile(uploadFile);
			setIsLoading(false);
			if (!uploadResponse.error) {
				setUploadSuccess(true);
			} else {
				setUploadSuccess(false);
			}
		} else {
		}
	};

	const handleFileUpload = async (data: any) => {
		let csvContent = "MIT_ID,SHOT_DATE_1,BODY_REGION_1,LOT_NUMBER_1,LOT_COMPANY_1,TEST_BY_1,LOCATION_1,SHOT_DATE_2,BODY_REGION_2,LOT_NUMBER_2,LOT_COMPANY_2,TEST_BY_2,LOCATION_2,SOURCE\r\n";

		data.forEach((rowArray: any) => {
			let row = rowArray.join(",");
			csvContent += row + "\r\n";
		});

		var blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

		let uploadResponse = await medicalApi.uploadVaccineResults("upload.csv", blob);
		setIsLoading(false);

		if (!uploadResponse.error) {
			setUploadSuccess(true);
			reloadDataVaccine();
		} else {
			setUploadSuccess(false);
			setErrorMessage(uploadResponse.errorMessage.join(", "))
		}
	};

	const reloadDataVaccine = async () => {
		covidNav!.globalTableIsLoading = true;
		const vaccineDetailResponse: FluShotDetailResponse = await medicalApi.fetchVaccineResults(covidNav.currentDateString);
		if (vaccineDetailResponse.error) {
			//todo: handle error
		} else {
			if (vaccineDetailResponse.items) {
				peopleStore.setVaccineList(vaccineDetailResponse.items);
				covidNav!.globalTableIsLoading = false;
			}
		}
	};

	const fieldData: Record<string, BulkUploadField> = {
		MIT_ID: { required: true, pattern: { value: /^\d{9,12}$/, message: "MIT ID format" } },
		SHOT_DATE_1: {},
		BODY_REGION_1: {},
		LOT_NUMBER_1: {},
		LOT_COMPANY_1: {},
		TEST_BY_1: {},
		LOCATION_1: {},
		SHOT_DATE_2: {},
		BODY_REGION_2: {},
		LOT_NUMBER_2: {},
		LOT_COMPANY_2: {},
		TEST_BY_2: {},
		LOCATION_2: {},
		SOURCE: {},
	};

	return (
		<div >
			<Drawer
				contentAriaLabel={`Upload External vaccine shots`} 
				header={<Text content="Upload External vaccine shots." type={TextType.Heading3} icon="" />}
				type={DrawerType.Right}
				themedColor={"medical"}
				show={show}
				onClose={onClose}
				size={DrawerSize.Medium}
				footer={[]}
				contents={
					<div id="external-vaccine-upload-drawer">
						<Loader loaded={!isLoading} options={{ ...mediumLoaderOptions, color: "#000000" }}>
							<Row>
								<BulkUpload themedColor={"medical"} drawerSize={DrawerSize.Large} autoSave={false} onImport={handleFileUpload} formFields={fieldData} showTemplate={true} />
								<Col xs={12}>
									{uploadSuccess === false && <Alert type="error" text={`${errorMessage}`} />}
									{uploadSuccess === true && <Alert type="success" text="The file has been uploaded successfully." />}
								</Col>
							</Row>
						</Loader>

						<div className="text-center">
							{uploadSuccess && (
								<div className="p-3">
									<Button type={ButtonType.Primary | ButtonType.Ghost} onClick={handleClose} state={isLoading ? ButtonState.Disabled : ButtonState.Enabled} text="Done" />
								</div>
							)}
							{uploadSuccess !== true && (
								<div className="p-3">
									<Button type={ButtonType.Primary | ButtonType.Ghost} onClick={handleClose} state={isLoading ? ButtonState.Disabled : ButtonState.Enabled} text="Cancel" />
								</div>
							)}
						</div>
					</div>
				}
			/>
		</div>
	);
};

export default VaccineUpload;
