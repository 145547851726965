import * as React from "react";
import { Drawer, DrawerSize, DrawerType, ReleaseNoteItemProps, ReleaseNotes, Text, TextType } from "@mit/hui";
import { FC } from "react";

export const defaultListData: ReleaseNoteItemProps[] = [
	{
		date: "11/30/2020",
		text: "",
		items: [{ heading: "Added Contact Tracing", description: "An active case allows for the addition of individuals the patient have been in contact with." }],
	},
	{
		date: "11/10/2020",
		text: "",
		items: [
			{ heading: "Upgraded User Interface", description: "Improved search and navigation. Added additional info per user and the ability to exempt an individual from testing." },
			{ heading: "Improved Bulk Upload", description: "Improved bulk upload validation by allowing realtime fixes before final submission." },
		],
	},
  {
    date: '02/13/2021',
    text: '',
    items: [
      { heading: "Improved Travel Interface", description: "Improved the experience of Travel app to be more rich and interactive, making use of maps and adding more options. Also added passport and visas to profile." },
    ]
  },
  {
    date: '02/21/2021',
    text: '',
    items: [
      { heading: "Improved Travel Process", description: "Added High Risk travel workflow requirements that goes through an approval process." },
    ]
  },

];

interface IReleaseNotesProps {
	onClose: any;
	show: boolean;
}

const ReleaseNotesComponent: FC<IReleaseNotesProps> = ({ onClose, show }) => {
	return (
		<Drawer
			contentAriaLabel={`What's New Release Notes`} 
			type={DrawerType.Right}
			contents={<ReleaseNotes items={defaultListData} />}
			onClose={onClose}
			show={show}
			size={DrawerSize.Medium}
			footer={[]}
			themedColor={"medical"}
			header={
				<>
					<Text content="What's New" type={TextType.Heading3} />
				</>
			}
		/>
	);
};

export default ReleaseNotesComponent;
