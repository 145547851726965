import * as React from 'react'
import {inject, observer} from "mobx-react";
import {FC, useEffect, useState} from "react";
import {SearchUserInterface} from "../../model/SearchUser";
import medicalApi from "../../api/MedicalApi";
import userController, { PersonProfileInterface } from "../../api/UserController";
import {displayDateTimeLong, toDate, usDateTime} from "../../common/Formatters";
import RecordTestResult from "../actions/RecordTestResult";
import { AdvancedTable, IAdvancedTableColumn, TemplateModal, TemplateModalAlignment, Alert, Button, ButtonState } from '@mit/hui';
import { RolesInterface } from '../../model/Roles';

interface PeopleListProps {
    searchUser?: SearchUserInterface;
    mitId?: string;
    roles?: RolesInterface;
}

const executeSortDesc = (a: any, b: any) => {
    const fieldName = 'date'
    let dateTimeA: moment.Moment = toDate(a[fieldName]!)
    let dateTimeB: moment.Moment = toDate(b[fieldName]!)
    return dateTimeB.diff(dateTimeA)
}

const PersonTestsComponent: FC<PeopleListProps> = ({searchUser, mitId, roles}) => {
    const [personTests, setPersonTests] = useState<any[]>([])
    const [isLoading, setIsLoading] = useState(true)
    const [currentUser, setCurrentUser] = useState({
        name: '',
        kerberosId: '',
    })

    const [deleting, setDeleting] = React.useState<boolean>(false)
    const [showWarning, setShowWarning] = React.useState<boolean>(false)
    const [notification, setNotification] = React.useState<any>(null)
    const [itemToRemove, setItemToRemove] = React.useState<any>(null)

    useEffect(() => {
        if(mitId || searchUser && searchUser.person) {
            reloadPersonTests()
            getUserDetails(mitId)
        }
    }, [searchUser!.person, mitId]);

    const reloadPersonTests = () => {
        setIsLoading(true)
        medicalApi.fetchTestResults(mitId ? mitId : searchUser!.person!.mitId).then((resp) => {
            setPersonTests(resp.items ? resp.items.sort(executeSortDesc) : [])
            setIsLoading(false)
        })
    }

    const removePendingTest = async () => {
        setDeleting(true);
        await medicalApi.cancelPendingTests( mitId!, itemToRemove.accession_number )
        .then((res)=>{
            setShowWarning(false)
            setNotification(null)
            reloadPersonTests()
        }).catch((error)=>{
            setNotification({
                text: error.message
            })
        })
        setDeleting(false)
    }

    const closeModal = () => {
        setShowWarning(false);
        setItemToRemove(null);
        setNotification(null);
        setDeleting(false)
    };

    const getUserDetails = async (mitId: any) => {
        await userController.fetchProfile(mitId).then((user: any) => {
            setCurrentUser({
                name: user.person.displayName,
                kerberosId: user.person.kerberosId
            })
        })
    }
    
    const columns: Array<IAdvancedTableColumn> = [
        {
            id: "date",
            name: "Date",
            sortable: true,
            formatter: (item: any) => { return displayDateTimeLong(item.date); },
            options: {
                demandPopin: true,
                minWidth: 500,
                popinText:  "Date"
            },
            filterable: false
        },
        {
            id: "accession_number",
            name: "Accession Number",
            sortable: true,
            options: {
                demandPopin: true,
                minWidth: 500,
                popinText:  "Accession Number"
            },
            filterable: false
        },
        {
            id: "result",
            name: "Result",
            sortable: true,
            options: {
                demandPopin: true,
                minWidth: 500,
                popinText:  "Result"
            },
            filterable: false
        },
    ];

    return <>
        {/* <h4 className="covid-19-tests profile-table-headers">Medical Checkins 
            {roles?.roles.includes('medical') && 
                <RecordTestResult 
                    callbackFn={reloadPersonTests} 
                    actionText={'Add Test Result'} 
                    person={{
                        name: searchUser && searchUser.person && searchUser!.person!.displayName ? searchUser!.person!.displayName : "",
                        kerberosId: searchUser && searchUser.person && searchUser!.person!.kerberosId ? searchUser!.person!.kerberosId  : ""
                    }}/>
            }
        </h4> */}
        <h4 className="covid-19-tests profile-table-headers">Medical Checkins 
            {roles?.roles.includes('medical') && 
                <RecordTestResult 
                    callbackFn={reloadPersonTests} 
                    actionText={'Add Test Result'} 
                    person={currentUser}/>
            }
        </h4>
        <div className={'person-detail-container'}>
           <AdvancedTable 
            columns={columns}
            actionButtons={[
                {
                    icon: 'trash',
                    onClick:( item:any )=> {setItemToRemove(item); setShowWarning(true)},
                    padded: false,
                    shouldDisplay: (item:any) => { return item.result === "Pending" },
                    state: 0,
                    text: '',
                    type: 64
                },
            ]}
            maxRows={1000}
            items={personTests}
            isLoading={isLoading}
            sortStateOptions={{
                enabled:true,
                key: 'covid-medical-person-tests'
            }}
        />
        <TemplateModal
            name=""
            show={showWarning}
            onClose={ ()=> closeModal()}
            noExternalClose
            bodyAlignment={TemplateModalAlignment.Center}
            header={<h2>Cancelling Pending Medical Test</h2>}
            body={<>
                <p className="mt-3">Are you sure you want to cancel {itemToRemove ? itemToRemove.accession_number : ""} ? </p>
                {notification &&
                    <Alert 
                        text={notification.text}
                        type="error"
                        icon={'exclamation-triangle'}
                    />
                }
            </>}
            footer={ <Button state={ deleting? ButtonState.Disabled : ButtonState.Enabled } isBusy={deleting} text="Delete" onClick={() => removePendingTest() } /> }
            />
        </div>
    </>
}

export const PersonTests = inject('searchUser', 'roles')(observer(PersonTestsComponent))
