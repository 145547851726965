import getLocaleDetails from './LocaleLanguages';

const utils = () => {
  const localDetails: any = getLocaleDetails();
  const getMonthLength: any = localDetails.getMonthLength;
  const getLanguageDigits: any = localDetails.getLanguageDigits;

  const getToday = () => {
    const todayDate = new Date();
    const year = todayDate.getFullYear();
    const month = todayDate.getMonth() + 1;
    const day = todayDate.getDate();
    return localDetails.getToday({ year, month, day });
  };

  const getMonthName = (month: number) => localDetails.months[month - 1];

  const getMonthNumber = (monthName: string) => localDetails.months.indexOf(monthName) + 1;

  const getMonthFirstWeekday = (date: any) => {
    const gregorianDate = localDetails.toNativeDate({ ...date, day: 1 });
    const weekday = gregorianDate.getDay();
    const dayIndex = weekday + localDetails.weekStartingIndex;
    return dayIndex % 7;
  };

  const isBeforeDate = (day1: any, day2: any) => {
    if (!day1 || !day2) return false;
    return localDetails.toNativeDate(day1) < localDetails.toNativeDate(day2);
  };

  const checkDayInDayRange = ({day, from, to}: any) => {
    if (!day || !from || !to) return false;
    const nativeDay = localDetails.toNativeDate(day);
    const nativeFrom = localDetails.toNativeDate(from);
    const nativeTo = localDetails.toNativeDate(to);
    console.log(nativeDay > nativeFrom && nativeDay < nativeTo);
    return nativeDay > nativeFrom && nativeDay < nativeTo;
  };

  return {
    getToday,
    getMonthName,
    getMonthNumber,
    getMonthLength,
    getMonthFirstWeekday,
    isBeforeDate,
    checkDayInDayRange,
    getLanguageDigits,
  };
};

export default utils;