export interface IValidationPattern {
  value: any;
  message: string;
}


export class ValidationUtility {
    static emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    static urlPattern = /^[A-Za-z][A-Za-z\d.+-]*:\/*(?:\w+(?::\w+)?@)?[^\s/]+(?::\d+)?(?:\/[\w#!:.?+=&%@\-/]*)?$/;
    static namePattern = /[^a-zA-Z -]/;
    static phonePattern = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
    static mitIdPattern = /^[9][0-9]{8}$/;
  
    static ageValidation = (fieldName: string, fieldValue: string) => {
        let age = parseInt(fieldValue);
        if (!age) {
            return `${fieldName} is required`;
        }
        if (age < 18) {
            return `${fieldName} must be at least 18`;
        }
        if (age > 99) {
            return `${fieldName} must be under 99`;
        }
        return null;
    };
}