import React, { FC } from "react";

export interface PagingDotProps
{
    url?:string,
    selected?:boolean,
    onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, index: number) => void
}

export interface PagingContainerProps
{
    items: PagingDotProps[];
    tabindex?: number;
}

const PagingContainer: FC<PagingContainerProps> = (props) => {
    const getClasses = (item:PagingDotProps): string =>
    {
        var classes: string[] = [];

        if (item.selected)
            classes.push('selected');

        return classes.join(' ');
    }

    return (
        <div className="paging-container" tabIndex={-1} role="navigation">
            {
                props.items.map((item: PagingDotProps, index: number) => (
                    <a 
                        key={`pagingdot_${index}`}
                        className={`paging-dot ${(getClasses(item))}`}
                        role="button"
                        tabIndex={props.tabindex}
                        onClick={(e) => item.onClick && item.onClick(e, index ?? 0)}
                        aria-label={`Pagingdot ${index+1}${item.selected? ', Selected.' : ''}` }
                    />
                ))
            }
        </div>
    );
}

export default PagingContainer;