import { elementType } from '../Table/types';
import { TableFieldFilter } from '../TableFilter';

export interface TableHeadProps {
  classes?: string;
  component?: elementType;
  style?: any;
}

export const defaults: TableHeadProps = {
  classes: '',
  component: 'thead',
};

export interface TableHeadCell {
  id: any,
  pinned: boolean;
  attribute: boolean;
  mobileRender?: any;
  virtualizedRender?: any;
  disablePadding: boolean;
  label: string;
  numeric: boolean;
  filterData?: TableFieldFilter;
}

export interface TableHeadCellVirtualized {
  id: any,
  pinned: boolean;
  attribute: boolean;
  mobileRender?: any;
  virtualizedRenderData?: any;
  disablePadding: boolean;
  label: string;
  numeric: boolean;
  filterData?: TableFieldFilter;
  width: number
  disableSort?: boolean
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify'
}