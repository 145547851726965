import * as React from "react";
import { FC, useEffect, useState, Fragment } from "react";
import moment from "moment";
import Toggle from "../Toggle";
import Spacer from "../Spacer";
import Alert from "../Alert";
import { ButtonType, Button } from "../Button";
import {
  TemplateModalAlignment,
  TemplateModalSize,
  TemplateModal,
} from "../Template";
import Text, { TextType } from "../Text";

export interface AttestationResponse {
  items?: AttestationQuestion[];
  error?: any;
}

export interface AttestationQuestion {
  id: string;
  text: string;
  more_text?: string;
  short_text?: string;
  style: string;
  page: number;
  disqualify: boolean;
  required: boolean;
  follow_up_questions?: AttestationQuestion[];
}

export interface AttestationAnswer {
  id: string;
  checked: boolean;
}

export interface AttestationProps {
  questionList: AttestationResponse;
  forceSinglePageDisplay?: boolean;
  lastSubmission?: string;
  onFinalSubmission?: (answers: AttestationAnswer[]) => void;
  onPageSubmission?: (pageNumber: number) => void;
}

const Attestation: FC<AttestationProps> = ({
  questionList,
  forceSinglePageDisplay,
  lastSubmission,
  onFinalSubmission,
  onPageSubmission,
}) => {
  /* const mediumLoaderOptions = {
    lines: 11,
    length: 34,
    width: 14,
    radius: 31,
    scale: 0.3,
    corners: 1,
    speed: 1,
    position: "absolute",
    color: "white",
  }; */

  interface PageError {
    error: boolean;
    errorText: string;
    showRetry: boolean;
  }

  interface Answers {
    [id: string]: boolean;
  }

  const [isShowingConfirmationPopup, setIsShowingConfirmationPopup] = useState(
    false
  );
  const [page1Error, setPage1Error] = useState<boolean>(false);
  const [page2ErrorText, setPage2ErrorText] = useState<string>("");
  const [page2Error, setPage2Error] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [answers, setAnswers] = useState<Answers>({});
  const [questions, setQuestions] = useState<AttestationQuestion[]>([]);
  const [page1Questions, setPage1Questions] = useState<AttestationQuestion[]>(
    []
  );
  const [pageError, setPageError] = useState<PageError>({
    error: false,
    errorText: "",
    showRetry: false,
  });
  const [showStep1, setShowStep1] = useState<boolean>(true);
  const [showStep2, setShowStep2] = useState<boolean>(false);

  const [CONTACT_KEY, setContactKey] = useState<string>("");
  const [TESTED_KEY, setTestedKey] = useState<string>("");
  const [IS_MEDICAL_KEY, setMedicalKey] = useState<string>("");

  const onAnswerClick = (id: any, evt: any) => {
    if (evt.SWITCH_STATE.choice !== "Positive") {
      updateAnswer(id, true);
    } else {
      updateAnswer(id, false);
    }
  };

  //initialize answers only once
  useEffect(() => {
    let response = questionList;

    if (response && response.items) {
      //mainAppState!.stepperSelectedIndex = 0;
      //mainAppState!.stepperItemCount = 2;

      setQuestions(response.items);

      var p1Questions = response.items.filter((question: AttestationQuestion) =>
        !forceSinglePageDisplay ? question.page === 1 : questions
      );
      setPage1Questions(p1Questions);

      setContactKey(
        response.items.filter(
          (q: AttestationQuestion) => q.short_text === "Contact"
        )[0].id
      );
      setTestedKey(
        response.items.filter(
          (q: AttestationQuestion) => q.short_text === "Tested positive"
        )[0].id
      );

      let contactQ = getQuestionByKey(CONTACT_KEY, response.items);

      setMedicalKey(
        contactQ.follow_up_questions!.filter(
          (q: AttestationQuestion) => q.short_text === "Health Care Worker"
        )[0].id
      );

      setIsLoading(false);
      setPageError({ error: false, errorText: "", showRetry: false });
    } else {
      setPageError({ error: true, errorText: "Loading...", showRetry: false });
    }
  }, [questionList]);

  //Resetting the page and toggle control requires us to reset the original questions.
  useEffect(() => {
    //This will only execute when the p1Questions have been set to [] by the reset method.
    if (!isLoading && page1Questions.length === 0) {
      var p1Questions = questions.filter((question: AttestationQuestion) =>
        !forceSinglePageDisplay ? question.page === 1 : questions
      );
      setPage1Questions(p1Questions);
    }
  }, [page1Questions]);

  const withComponent = (WrappedComponent: any): any => {
    return class WithComponent extends React.Component<any> {
      render(): React.ReactNode {
        return <WrappedComponent {...this.props} />;
      }
    };
  };

  const displayDateTime = (date: moment.Moment | string): string => {
    if (typeof date === "string") {
      date = moment(date);
    }

    let displayDate = moment(date).format("LLL");
    return `${displayDate}`;
  };

  const getQuestionByKey = (
    key: string,
    arr: AttestationQuestion[]
  ): AttestationQuestion => {
    return arr.filter((q: AttestationQuestion) => q.id === key)[0];
  };

  const isDisqualifyingQuestion = (key: string) => {
    let question = questions.filter((q: AttestationQuestion) => q.id === key)[0];
    return question && question.style === "bad";
  };

  const followUpCountersNegativeAnswer = (key: string) => {
    let question = questions.filter((q: AttestationQuestion) => q.id === key)[0];
    //if no follow ups, pass through
    if (
      question.follow_up_questions &&
      question.follow_up_questions.length > 0
    ) {
      return (
        question.follow_up_questions &&
        question.follow_up_questions.filter(
          (f: AttestationQuestion) => !answers[f.id]
        ).length > 0
      );
    } else {
      return true;
    }
  };

  const getHasSymptom = (testAnswers: Answers): boolean => {
    //filter any answers that are true, are disqualifying, but are not countered by the follow up
    return (
      Object.keys(testAnswers).filter(
        (key: string) =>
          isDisqualifyingQuestion(key) &&
          testAnswers[key] === true &&
          followUpCountersNegativeAnswer(key)
      ).length > 0
    );
  };

  const isInvertedQuestion = (question: AttestationQuestion) => {
    if (question.id === "11" || question.id === "12" || question.id === "13") {
      return false;
    }

    return true;
  };

  const updateAnswer = (id: number, newValue: boolean) => {
    let newAnswers = {
      ...answers,
      [id]: newValue,
    };

    setAnswers(newAnswers);
  };

  const renderQuestion = (
    question: AttestationQuestion,
    value: boolean,
    invertColors: boolean = false
  ): JSX.Element => {
    const { id, text } = question;

    return (
      <Fragment key={`render-q-${question.id}-${question.page}`}>
        <div className={"single-question"}>
          <div className={"question-text"}>{text}</div>

          {isInvertedQuestion(question) ? (
            <Toggle
              showBothOptions
              positiveLabel="no"
              negativeLabel="yes"
              choice={"NotSelected"}
              onClick={(evt: any) => onAnswerClick(id, evt)}
              onStateChanged={() => null}
            />
          ) : (
            <Toggle
              inverse
              showBothOptions
              positiveLabel="no"
              negativeLabel="yes"
              choice={"NotSelected"}
              onClick={(evt: any) => onAnswerClick(id, evt)}
              onStateChanged={() => null}
            />
          )}
        </div>
        {answers[id] &&
          question.follow_up_questions &&
          question.follow_up_questions.map((followUpQ: AttestationQuestion) => {
            return renderQuestion(followUpQ, answers[followUpQ.id], true);
          })}
      </Fragment>
    );
  };

  const validateAttestations = (): boolean => {
    // if you are on step 1, all questions must be answered
    let valid = true;
    if (showStep1) {
      valid = Object.keys(answers).length === page1Questions.length;
    }
    return valid;
  };

  const hideModal = () => {
    setIsShowingConfirmationPopup(false);
  };

  const submitQuestions = () => {
    if (!forceSinglePageDisplay) {
      showModal();
    } else {
      setPage1Error(false);
      submitAdditionalQuestions();
    }
  };

  const showModal = () => {
    if (!validateAttestations()) {
      setPage1Error(true);
      return;
    }

    //@ts-ignore
    setIsShowingConfirmationPopup(true);
  };

  const submitAttestations = (callbackFn?: () => void) => {
    hideModal();
    setIsLoading(true);

    //convert web attestations to server format
    const answersServer: AttestationAnswer[] = Object.keys(answers).map(
      (questionKey: string) => {
        return {
          id: questionKey,
          checked: answers[questionKey],
        };
      }
    );

    onFinalSubmission && onFinalSubmission(answersServer);
  };

  const handleResetSymptoms = () => {
    setAnswers({});

    setPage1Questions([]);
  };

  const getModalText = () => {
    if (getHasSymptom(answers)) {
      if (answers[TESTED_KEY] && answers[CONTACT_KEY]) {
        return "You will be submitting that you have been tested for COVID-19 and had a positive result, or have been told by a healthcare provider that you are likely positive for COVID-19, and that within the last 14 days, you have been in close contact with someone that you know had been diagnosed with COVID-19 or had experienced COVID-19 related symptoms.";
      }
      if (answers[TESTED_KEY]) {
        return `You will be submitting that you have been tested for COVID-19 and had a positive result, or have been told by a healthcare provider that you are likely positive for COVID-19.`;
      }
      if (answers[CONTACT_KEY]) {
        return `You${
          answers[IS_MEDICAL_KEY] ? " are a healthcare worker that" : ""
        } will be submitting that within the last 14 days, you have been in close contact with someone that you know had been diagnosed with COVID-19 or had experienced COVID-19 related symptoms.`;
      }
      return "You will be submitting that you do exhibit symptoms related to COVID-19.";
    } else {
      return "You will be submitting that you do not exhibit symptoms related to COVID-19.";
    }
  };

  const showSubmitButton = (): boolean => {
    return forceSinglePageDisplay || (showStep1 && getHasSymptom(answers));
  };

  const showContinueButton = (): boolean => {
    //continue button is visible if:
    //there is no symptom, and answer that no symptom has been given
    return !forceSinglePageDisplay && showStep1 && !getHasSymptom(answers);
  };

  const continueToQuestion2 = () => {
    //mainAppState!.stepperSelectedIndex = 1;
    onPageSubmission && onPageSubmission(1);

    if (!validateAttestations()) {
      setPage1Error(true);
      return;
    } else {
      setPage1Error(false);
      setShowStep1(false);
      setShowStep2(true);
    }
  };

  const countVisibleQuestions = () => {
    let count = 0;
    //count each question
    questions.forEach((q: AttestationQuestion) => {
      count++;
      //count all follow up questions
      if (
        answers[q.id] &&
        q.follow_up_questions &&
        q.follow_up_questions.length > 0
      ) {
        count = count + q.follow_up_questions.length;
      }
    });
    return count;
  };

  const submitAdditionalQuestions = () => {
    let allRequiredQuestionsAnswered = true;
    let page2ErrorTextTmp = "";

    const answersLength = Object.keys(answers).length;
    const visibleQ = countVisibleQuestions();
    if (visibleQ > answersLength) {
      allRequiredQuestionsAnswered = false;
      page2ErrorTextTmp = "Please answer all the questions and submit again.";
    }

    //make sure the ppe and contact question is answered YES
    questions
      .filter((q: AttestationQuestion) => q.page === 2)
      .forEach((question: AttestationQuestion) => {
        if (
          question.required &&
          question.style === "good" &&
          !answers[question.id]
        ) {
          allRequiredQuestionsAnswered = false;
        }
      });

    if (!allRequiredQuestionsAnswered) {
      if (page2ErrorTextTmp === "")
        page2ErrorTextTmp =
          "In order to obtain campus access, you must agree to wear Personal Protective Equipment (PPE) and adhere to all rules and protocols of social distancing. Please review your answers.";
      setPage2ErrorText(page2ErrorTextTmp);
      setPage2Error(true);
    } else {
      setPage2Error(false);
      setPage2ErrorText("");
      submitAttestations();
    }
  };

  //Because our (HUI) Modal gets appended to the documentElement root, we need to rerender and update the class component in order to let our Modal re-append to the documentElement root with the latest info
  const ModalPopup = withComponent(TemplateModal);

  return (
    <>
      {pageError.error && <div>{pageError.errorText}</div>}
      {/* <Loader loaded={!isLoading} options={mediumLoaderOptions}> */}
      {!pageError.error && (
        <div className={"question-container"}>
          {showStep1 && (
            <>
              {lastSubmission && (
                <div className={"last-submission-banner"}>
                  Your last submission was {displayDateTime(lastSubmission)}
                </div>
              )}
              <div className={"symptoms-header"}>
                <p className={"symptoms-header-question"}>
                  Do you experience any of the following symptoms?
                </p>
              </div>

              {page1Questions.map(
                (question: AttestationQuestion, index: number) => {
                  return (
                    <Fragment key={`main-q-${question.id}-${index}`}>
                      {renderQuestion(question, answers[question.id])}
                    </Fragment>
                  );
                }
              )}
              <Spacer />
            </>
          )}
          {!forceSinglePageDisplay && showStep2 && (
            <div>
              {questions
                .filter((q: AttestationQuestion) => q.page === 2)
                .map((question: AttestationQuestion, index: number) => {
                  return (
                    <Fragment key={"follow-up-" + question.id + "-" + index}>
                      {renderQuestion(
                        question,
                        answers[question.id],
                        question.style === "good"
                      )}
                    </Fragment>
                  );
                })}

              <div className="text-center w-100">
                <Spacer />
                {page2Error && <Alert text={page2ErrorText} type={"error"} />}
                <Spacer />
                {
                  <Button
                    text={"Submit"}
                    type={ButtonType.Secondary}
                    onClick={submitQuestions}
                  />
                }
              </div>
            </div>
          )}

          {page1Error && (
            <Alert
              text={"Please review your answers and try again."}
              type={"error"}
            />
          )}

          <div className="text-center w-100 mt-3">
            {(forceSinglePageDisplay || showStep1) && (
              <Button
                text={"Reset"}
                type={ButtonType.Secondary | ButtonType.Ghost}
                onClick={handleResetSymptoms}
              />
            )}{" "}
            &nbsp;&nbsp;&nbsp;
            {showSubmitButton() && (
              <Button
                text={"Submit"}
                type={ButtonType.Secondary}
                onClick={showModal}
              />
            )}
            {showContinueButton() && (
              <Button
                text={"Continue"}
                type={ButtonType.Secondary}
                onClick={continueToQuestion2}
              />
            )}
          </div>
          <Spacer size="5" />
        </div>
      )}
      {/* </Loader> */}
      <ModalPopup
        body={
          //   <Loader
          //     loaded={true}
          //     options={{ ...mediumLoaderOptions, color: "black" }}
          //   >
          <Fragment>{getModalText()}</Fragment>
          //   </Loader>
        }
        bodyAlignment={TemplateModalAlignment.Left}
        show={isShowingConfirmationPopup}
        header={<Text content={"Confirmation"} type={TextType.Heading3} />}
        size={TemplateModalSize.Small}
        theme="medical-dark"
        imageUrl=""
        footer={
          <div>
            <Button
              type={ButtonType.Secondary | ButtonType.Ghost}
              onClick={hideModal}
              text={"I made a mistake"}
            />
            &nbsp;
            <Button
              type={ButtonType.Secondary}
              onClick={submitAttestations}
              text={"Yes, I'm sure"}
            />
          </div>
        }
        name="confirmationDialog"
      />
    </>
  );
};

export default Attestation;
