import * as React from 'react';
import MobileTable from '../MobileTable';
import { defaults, TableContextProvider } from './TableContextProvider';
import { TableProps } from './types';
import TableFilter from '../TableFilter'

const Table: React.FC<React.PropsWithChildren<TableProps>> = (props: React.PropsWithChildren<TableProps>) => {
  const finalProps = { ...defaults, ...props };
  const {
    component,
    padding,
    size,
    stickyHeader,
    children,
    tableData,
    columnConfig,
    filterConfig
  } = finalProps;
  let { classes } = finalProps;
  const CustomTag = `${component}` as keyof JSX.IntrinsicElements;
  let finalClasses = 'HuiTable';
  if (stickyHeader) {
    classes += ' HuiTableStickyTableHeader';
  }
  if (classes) finalClasses += ` ${classes}`;
  return (
    <>
      {
        filterConfig !== undefined && <TableFilter filterConfig={filterConfig} />
      }
      <CustomTag
        style={{
          /* Styles applied to the root element. */
          display: 'table',
          width: '100%',
          maxWidth:
            size === 'small' ? '25vw' : size === 'medium' ? '50vw' : '100vw',
          borderCollapse: 'collapse',
          borderSpacing: 0,
          padding:
            padding === 'default'
              ? '1em'
              : padding === 'checkbox'
                ? '2em'
                : 'none',
          /* Styles applied to the root element if `stickyHeader={true}`. */
        }}
        className={finalClasses}
      >

        <TableContextProvider {...finalProps}>

          {children}
        </TableContextProvider>
      </CustomTag>
      <MobileTable columnConfig={columnConfig} tableData={tableData} filterConfig={filterConfig} />
    </>
  );
};

export default Table;
