import * as React from 'react';
import { DropdownItemProps } from '../../Dropdown';
import DropDown from '../../Dropdown/Dropdown';
import TablePaginationActions from './TablePaginationActions/TablePaginationActions';
import { TablePaginationProps } from './types';

const TablePagination: React.FC<React.PropsWithChildren<TablePaginationProps>> = (
  props: React.PropsWithChildren<TablePaginationProps>
) => {
  let {
    component,
    rowsPerPageOptions,
    rowsPerPage,
    onChangeRowsPerPage,
    classes,
  } = props;

  const tablePaginationActionsProps = {
    count: props.count,
    onChangePage: props.onChangePage,
    page: props.page,
    rowsPerPage: props.rowsPerPage,
  };
  const CustomTag = `${component}` as keyof JSX.IntrinsicElements;
  let dropDownData: DropdownItemProps[];
  if (rowsPerPageOptions) {
    dropDownData = rowsPerPageOptions.map(
      (row: number | { value: number; label: string }) => {
        const newData: DropdownItemProps = {
          icon: '',
          text: row.toString(),
          badge: 0,
        };
        return newData;
      }
    );
  } else {
    dropDownData = [];
  }

  const style = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '1em',
    paddingRight: '0em',
  };

  const finalStyle = { ...style, ...props.style };
  let finalClasses = 'HuiTablePagination';
  if (classes) finalClasses += ` ${classes}`;
  return (
    <CustomTag style={finalStyle} className={finalClasses}>
      <div>Rows Per Page:</div>
      <div style={{ marginLeft: '1vw', width: '65px', marginRight: '2em' }}>
        <DropDown
          id={'dropdown_Normal'}
          name="normal"
          text={rowsPerPage.toString()}
          dropdownItems={dropDownData}
          onChange={(item: any) => onChangeRowsPerPage(item)}
        />
      </div>
      <div>
        <TablePaginationActions {...tablePaginationActionsProps} />
      </div>
    </CustomTag>
  );
};

export default TablePagination;
