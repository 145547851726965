import React, { FC, Fragment } from 'react';
import FormRow, { FormRowProps, FormRowType } from './FormRow';
import Text, { TextType } from '../Text';
import { useForm } from 'react-hook-form';
import { Button, ButtonType, ButtonState } from '../Button';
import { FormType } from './Form';
import $ from 'jquery';
import { v4 as uuidv4  } from 'uuid'

/** @deprecated This component has been deprecated. Use the FormContainer located in Forms instead. **/
export interface FormContainerProps
{
    rowType: FormRowType // needs to become an enum
    formRows: FormRowProps[];
    formType: FormType; // make the default be input
    title?: string;
    disabled?: boolean;
    actionText?: string;
    onSubmit?: any;
    register?: any, // https://react-hook-form.com/api#register
    errors?: any, // https://react-hook-form.com/api#errors 
    setValue?: any // https://react-hook-form.com/api#setValue
}
/** @deprecated This component has been deprecated. Use the FormContainer located in Forms instead. **/
export const FormContainer: FC<FormContainerProps> = (props) =>
{
    let id = uuidv4();

    let { register, errors, setValue, trigger } = useForm({
        mode: "onChange"
    });

    if (props.register)
        register = props.register;

    if (props.errors)
        errors = props.errors

    if (props.setValue)
        setValue = props.setValue

    return (
        <Fragment>
            <form id={"form-" + id}>
                {(props.title) && <Text type={TextType.Heading4} content={props.title} />}
                {props.formRows.map((item: FormRowProps, number: number) => (
                    <FormRow register={register} errors={errors} setValue={setValue} formType={props.formType} {...item} rowType={props.rowType} key={number} />
                ))}
            </form>
            {
                props.onSubmit
                &&
                <div>
                    <Button state={props.disabled ? ButtonState.Disabled : ButtonState.Enabled} trigger={trigger} type={ButtonType.Secondary} onClick={(e: any) =>
                    {
                        $("#form-" + id).serializeArray();
                        props.onSubmit(e);
                    }} text={props.actionText ? props.actionText : "Submit"} />
                </div>
            }
        </Fragment>

    );
}