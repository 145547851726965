import React, {ReactElement, FC, Fragment, useEffect} from "react";
import { ButtonBar, ButtonBarType } from "../components/Button";
import { NavBar } from "../components/Nav";
import { ButtonProps } from "./Button/Button";
import ReactDOM from "react-dom";
import { AtlasTheme } from "../components/Template";
import FocusTrap from "focus-trap-react";
import {huiLog} from '../common/huiConsoleLogs'

export interface DrawerProps {
	contents: any;
	header: ReactElement<Text>;
	show: boolean;
	type: DrawerType;
	size?: DrawerSize;
	className?: string;
	footer: ButtonProps[];
	onClose?: any;
	contentAriaLabel?: string,
	layout?: DrawerLayout;
	headerContent?: any;
	headerNavBar?: React.ReactElement<NavBar>; // Only works
	themedColor?: AtlasTheme;
	noExternalClose?: boolean
	noBackground?: boolean
}

export enum DrawerType {
	// Make these be described for use, not position, otherwise people choose wrong.
	Top,
	Bottom,
	Left,
	Right,
}

export enum DrawerLayout {
	Clean,
	Hero,
	TwoCol,
}

export enum DrawerSize {
	Large,
	Medium,
	Small,
}

const Backdrop: FC<any> = (props) => {
	useEffect(() => {
		document.getElementById("root")?.classList.add("modal-open");
		return document.getElementById("root")?.classList.remove("modal-open");
		//eslint-disable-next-line
	}, [])

	return props.show ? (
		<div
			role={props.onClose ? "button": undefined}
			tabIndex={!props.onClose ? -1 : 0}
			data-dismiss="modal"
			aria-label={props.onClose ? "Backdrop close": undefined}
			onKeyDown={(e: any) => {
				if (e.keyCode === 13) {
					if (props.onClose) {
						props.clicked();
						e.preventDefault();
					}
				}
			}}
			onClick={(e: any) => {
			  	if (props.noExternalClose) {
			    	return
        		}
				if (props.onClose) {
					document.getElementById("root")?.classList.remove("modal-open");
					props.clicked();
					e.preventDefault();
				}
			}}>
			{!props.noBackground && <div className={"Backdrop"} />}
		</div>
	) : null;
};

export default class Drawer extends React.Component<DrawerProps> {
	public static defaultProps = {
		size: DrawerSize.Large,
		layout: DrawerLayout.Clean,
		noBackground: false
	};

	private _container!: HTMLDivElement;
	private _child: any;
    mutationObserver: MutationObserver = new MutationObserver(this.onContentsChange);

	getTypeText(type:number){
		const typeTextMap = [
			'Top',
			'Bottom',
			'Left',
			'Right',
		];
		return typeTextMap[type]
	}
	componentDidMount() {
	  document.documentElement.setAttribute('lang', 'eng')
		if(!this.props.contentAriaLabel){
			huiLog.updateWarning(
				`\nA UNIQUE [contentAriaLabel] attributes will soon become required for all [Drawer] components. 
				\nPlease update all [Drawer] components with this atrribute ASAP.`
			);
		}

		this._container = document.createElement("div");
        this._container.classList.add("modal-div");
        this.mutationObserver.observe(this._container, {childList: true, subtree: true});
        
        const rootElement: HTMLElement | null = document.getElementById("root");
        rootElement?.appendChild(this._container);

		this._child = ReactDOM.render(this.renderComponent(), this._container);

	}

	componentDidUpdate() {
		ReactDOM.hydrate(this.renderComponent(), this._container);
		if (!this._child) return;

		this._child.setState({});
	}

	componentWillUnmount() {
		ReactDOM.unmountComponentAtNode(this._container);
        const rootElement: HTMLElement | null = document.getElementById("root");
		rootElement?.removeChild(this._container);
	}

	getType(): string {
		var classes: string[] = [];

		if (this.props.type === DrawerType.Top) classes.push("top slide-in-top");

		if (this.props.type === DrawerType.Bottom) classes.push("bottom slide-in-bottom");

		if (this.props.type === DrawerType.Left) classes.push("left slide-in-left");

		if (this.props.type === DrawerType.Right) classes.push("right slide-in-right");

		if (this.props.size === DrawerSize.Large) classes.push("drawer-large");

		if (this.props.size === DrawerSize.Medium) classes.push("drawer-medium");

		if (this.props.size === DrawerSize.Small) classes.push("drawer-small");

		if (this.props.layout === DrawerLayout.Hero) classes.push("hero-drawer");

		if (this.props.themedColor === "medical-dark") classes.push("medical-dark themed");
		if (this.props.themedColor === "medical") classes.push("medical themed");

		return classes.join(" ");
	}

    onContentsChange(mutations: MutationRecord[]) {
        const isActiveElementTheBody: boolean = document.activeElement?.tagName === "BODY";
        if (isActiveElementTheBody) {
            document.getElementById('drawer-popup')?.focus();                    
        }
    }

	renderComponent(): React.ReactElement {
		if (!this.props.show) document.getElementById("root")?.classList.remove("modal-open");

		//Close on 'esc' key
		if (this.props.show && !this.props.noExternalClose) {
			document.addEventListener("keydown", (e: any) => {
				if (e.keyCode === 27) {
					this.props.onClose && this.props.onClose();
				}
			});
		}

		return (
			<>
				{this.props.show && (
					<Fragment>
						<FocusTrap focusTrapOptions={{ allowOutsideClick: true, fallbackFocus: "div" }}>
							<div /* tabIndex={0} */ role="dialog" aria-modal="true" id={`drawer-popup`} className={`${this.props.className ? `${this.props.className}-top` : 'drawer-top-level'}`} aria-label={ !this.props.contentAriaLabel? `` : `${this.props.contentAriaLabel}`}>
								<div>
									<Backdrop
										show={this.props.show}
										clicked={() => {
											this.props.onClose && this.props.onClose();
										}}
										onClose={this.props.onClose}
                    noExternalClose={this.props.noExternalClose}
										noBackground={this.props.noBackground}
									/>
									<div className={`drawer ${this.getType()} ${this.props.className}`} >
										<div className={`drawer-header pl-4 pt-4 pr-4`}>
											{this.props.header}
											{this.props.onClose && (
												<button
													type="button"
													className={`close`}
													onClick={() => {
														document.getElementById("root")?.classList.remove("modal-open");
														this.props.onClose();
													}}
													data-dismiss="modal"
													aria-label="Close">
													<i className={`far fa-times`}></i>
												</button>
											)}
											<div className={this.props.headerNavBar ? "pt-4" : "py-4"}>{this.props.headerContent}</div>
											<div>{this.props.headerNavBar}</div>
										</div>
										<div className="drawer-body  px-4" aria-label={ !this.props.contentAriaLabel? `` : `${this.props.contentAriaLabel} content.`}>{this.props.contents}</div>
										<div className="footer p-4">
											<ButtonBar type={ButtonBarType.Padded} buttons={this.props.footer} />
										</div>
									</div>
								</div>
							</div>
						</FocusTrap>
					</Fragment>
				)}
			</>
		);
	}

	render(): React.ReactNode {
		return null;
	}
}
