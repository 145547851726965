import { elementType } from '../Table/types';

export interface TableBodyProps {
  classes?: string;
  component?: elementType;
  style?: any;
  virtualized?: boolean;
  virtualizedData?: any;
  lazyLoad?: boolean;
  tableData?: any,
  headCells?: any
}

export const defaults: TableBodyProps = {
  classes: '',
  component: 'tbody',
};
