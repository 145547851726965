import React from "react";

export interface LoggedOutProps {}

export default class MITLoggedOut extends React.Component<LoggedOutProps> {
  render(): React.ReactNode {
    return (
      <div className="logout">
        <div className="container-sm">
          <div className="row">
            <div className="col-6 mx-auto slide-in-bck-center">
              <div className="card">
                <div className="p-4"><h1>Logging out securely</h1>
                <p>
                  <b>You are authenticated through <a href="http://ist.mit.edu/touchstone-detail" rel="noopener noreferrer" target="_blank">MIT Touchstone</a>.</b><br/><br/>
                  
                  MIT Touchstone is a <a href="http://en.wikipedia.org/wiki/Single_sign-on" rel="noopener noreferrer" target="_blank">single sign-on</a> service that allows members of the MIT community to log in to participating MIT websites. Once you are authenticated via Touchstone, <b>the only way to log out of Touchstone is to close the browser.</b>
                </p>
                </div>
                <p className="p-4 mb-0">
                    Please contact the Help Desk at <a href="mailto:computing-help@mit.edu">computing-help@mit.edu</a> or 3-1101 for assistance.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
