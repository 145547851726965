import React from 'react';
import QRCode from "qrcode.react"
 
export enum DiplomaTypes
{
    // changed all "-" characters to "_" characters
    ARCH_DEGREE_RPT,
    ARCH_RE_ISSUE_RPT,
    BACHELOR_DEGREE_QRY,
    BACHELOR_DEGREE_RPT,
    BS_MINOR_RE_IS_RPT,
    CITY_PLAN_RE_IS_RPT,
    CITY_PLAN_RPT,
    DBL_MAJ_RE_IS_RPT,
    DBL_MAJ_DIPLOMA_FRM1,
    DBL_MAJ_DIPLOMA_FRM2,
    DBL_MAJ_DIPLOMA_FRM3,
    DBL_MAJ_DIPLOMA_FRMT,
    DBL_MAJ_DIPLOMA_RPT,
    DR_SCI_RE_IS_RPT,
    ENGINEER_DEGREE_RPT,
    ENG_DEG_RE_ISSUE_RPT,
    MA_SCI_WH_RE_IS_RPT,
    MASTER_ENG_RPT,
    MASTER_FIN_RPT,
    MASTER_SCI_RPT,
    MA_APPLSCI_RE_IS_RPT,
    MA_ENG_RE_IS_RPT,
    MA_FIN_RE_IS_RPT,
    MA_SCI_RE_IS_RPT,
    MBA_RE_ISSUE_RPT,
    MBA_RPT,
    MBN_RE_ISSUE_RPT,
    MBN_RPT,
    PHD_RE_IS_RPT,
    PHD_RPT,
    SCD_RPT,
    WH_DR_SCI_RE_IS_RPT,
    WH_ENG_RE_IS_RPT,
    WH_PHD_RE_IS_RPT,
    WH_PHD_RPT,
    WH_SCD_RPT,
    WH_EN_RPT,
    WH_MENG_RPT,
    WH_SM_RPT,
    Other
}

export enum DiplomaDisplayStyle
{
    Full = "Full",  //For on the QR code link
    Compact = "Compact",   // For on diploma site
    Minimal = "Minimal" // For processing
}
export interface DiplomaProps
{
    type: DiplomaTypes,
    displayStyle: DiplomaDisplayStyle,
    name: string,
    degree: string,
    date: string,
    thesis: string,

    //those additional fields
    detailPublicKey: string,
    detailDigitalSignature: string,
    detailStatus: string,
    detailCourse: string,
    detailDegreeCode: string,
    publicUrl: string
}

export default class MITDiploma extends React.Component<DiplomaProps>
{
    public static defaultProps =
        {
            displayStyle: DiplomaDisplayStyle.Compact
        };

    /* constructor(props: DiplomaProps)
    {
        super(props);
    } */

    render(): React.ReactNode 
    {
        // Master of Business & bachelor
        if (this.props.type === DiplomaTypes.MBA_RPT
            || this.props.type === DiplomaTypes.ARCH_DEGREE_RPT
            || this.props.type === DiplomaTypes.ARCH_RE_ISSUE_RPT
            || this.props.type === DiplomaTypes.MBA_RE_ISSUE_RPT
            || this.props.type === DiplomaTypes.CITY_PLAN_RPT
            || this.props.type === DiplomaTypes.CITY_PLAN_RE_IS_RPT
            || this.props.type === DiplomaTypes.ENGINEER_DEGREE_RPT
            || this.props.type === DiplomaTypes.ENG_DEG_RE_ISSUE_RPT
            || this.props.type === DiplomaTypes.MASTER_FIN_RPT
            || this.props.type === DiplomaTypes.MA_FIN_RE_IS_RPT
            || this.props.type === DiplomaTypes.BACHELOR_DEGREE_RPT
            || this.props.type === DiplomaTypes.BACHELOR_DEGREE_QRY
            || this.props.type === DiplomaTypes.MBN_RE_ISSUE_RPT
            || this.props.type === DiplomaTypes.MBN_RPT
            || this.props.type === DiplomaTypes.MASTER_ENG_RPT
            || this.props.type === DiplomaTypes.BS_MINOR_RE_IS_RPT


        )
        {
            return (
                <div className={`row no-gutters diploma${this.props.displayStyle}`}>
                    <div className={`${this.props.displayStyle === DiplomaDisplayStyle.Full ? 'col-lg-9' : 'col-lg-12'} `}>


                        <div className={`diploma ${this.props.displayStyle === DiplomaDisplayStyle.Minimal ? 'minimal' : ''}`}>
                            <img className="img-fluid heading mt-3" src="/images/MIT-diploma-header.png" alt="MIT Diploma header" />
                            <div className="diploma-text mt-3">UPON THE RECOMMENDATION OF THE FACULTY HEREBY CONFERS ON</div>
                            <div className="mt-3 filled-name"> {this.props.name}</div>

                            <div className="diploma-text mt-3">THE DEGREE OF</div>
                            <div className="filled-text">{this.props.degree}</div>
                            <div className="diploma-text mt-3">IN RECOGNITION OF PROFICIENCY IN THE GENERAL AND
                            THE SPECIAL STUDIES AND EXERCISES PRESCRIBED BY SAID
                            INSTITUTE FOR SUCH DEGREE GIVEN THIS DAY UNDER THE SEAL
                    OF THE INSTITUTE AT CAMBRIDGE IN THE COMMONWEALTH OF MASSACHUSETTS</div>
                            <div className="mt-3 diploma-text">{this.props.date}</div>
                            <div className="mt-3"><img className="signature-secretary" src="/images/MIT-diploma-secretary.jpg" alt="Secretary signature" /></div>
                            <div className="diploma-text">SECRETARY</div>
                            <div className="mt-3"><img className="signature-president" src="/images/MIT-diploma-president.png" alt="President signature" /></div>
                            <div className="diploma-text"> PRESIDENT</div>
                            <div className="mt-3 row">
                                <div className="col-4 ml-auto text-right">
                                <QRCode value={this.props.publicUrl} /></div>
                                <div className="col-4 mr-auto text-left">
                                <img className="seal" src="/images/MIT-seal.jpg" alt="MIT Seal" />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className={` ${this.props.displayStyle === DiplomaDisplayStyle.Full ? 'col-lg-3' : 'd-none'} p-3 degreeProperties`}>
                        <div>Public Key</div>
                        <b>{this.props.detailPublicKey}</b>
                        <div>Digital Signature</div>
                        <b>{this.props.detailDigitalSignature}</b>
                        <div>Full Name</div>
                        <b>{this.props.name}</b>
                        <div>Degree Title</div>
                        <b>{this.props.degree}</b>
                        <div>Status</div>
                        <b>{this.props.detailStatus}</b>
                        <div>Course</div>
                        <b>{this.props.detailCourse}</b>
                        <div>Degree</div>
                        <b>{this.props.detailDegreeCode}</b>
                        <div>Award Date</div>
                        <b>{this.props.date}</b>
                    </div>
                </div>

            )
        }


        // double majors
        else if (this.props.type === DiplomaTypes.DBL_MAJ_RE_IS_RPT
            || this.props.type === DiplomaTypes.DBL_MAJ_DIPLOMA_FRM1
            || this.props.type === DiplomaTypes.DBL_MAJ_DIPLOMA_FRM2
            || this.props.type === DiplomaTypes.DBL_MAJ_DIPLOMA_FRM3
            || this.props.type === DiplomaTypes.DBL_MAJ_DIPLOMA_FRMT
            || this.props.type === DiplomaTypes.DBL_MAJ_DIPLOMA_RPT
  


        )
        {
            return (
                <div className={`row no-gutters diploma${this.props.displayStyle}`}>
                    <div className={`${this.props.displayStyle === DiplomaDisplayStyle.Full ? 'col-lg-9' : 'col-lg-12'} `}>


                        <div className={`diploma ${this.props.displayStyle === DiplomaDisplayStyle.Minimal ? 'minimal' : ''}`}>
                            <img className="img-fluid heading mt-3" src="/images/MIT-diploma-header.png" alt="MIT Diploma header" />
                            <div className="diploma-text mt-3">UPON THE RECOMMENDATION OF THE FACULTY HEREBY CONFERS ON</div>
                            <div className="mt-3 filled-name"> {this.props.name}</div>

                            <div className="diploma-text mt-3">THE DEGREE OF</div>
                            <div className="filled-text mt-3">BACHELOR OF SCIENCE</div>
                            <div className="diploma-text">IN</div>
                            <div className="filled-text">{this.props.degree}</div>
                            <div className="diploma-text mt-3">IN RECOGNITION OF PROFICIENCY IN THE GENERAL AND
                            THE SPECIAL STUDIES AND EXERCISES PRESCRIBED BY SAID
                            INSTITUTE FOR SUCH DEGREE GIVEN THIS DAY UNDER THE SEAL
                    OF THE INSTITUTE AT CAMBRIDGE IN THE COMMONWEALTH OF MASSACHUSETTS</div>
                            <div className="mt-3 diploma-text">{this.props.date}</div>
                            <div className="mt-3"><img className="signature-secretary" src="/images/MIT-diploma-secretary.jpg" alt="Secretary signature" /></div>
                            <div className="diploma-text">SECRETARY</div>
                            <div className="mt-3"><img className="signature-president" src="/images/MIT-diploma-president.png" alt="President signature" /></div>
                            <div className="diploma-text"> PRESIDENT</div>
                            <div className="mt-3"><QRCode value={this.props.publicUrl} /><img className="seal" src="/images/MIT-seal.jpg" alt="MIT Seal" /></div>
                        </div>

                    </div>
                    <div className={` ${this.props.displayStyle === DiplomaDisplayStyle.Full ? 'col-lg-3' : 'd-none'} p-3 degreeProperties`}>
                        <div>Public Key</div>
                        <b>{this.props.detailPublicKey}</b>
                        <div>Digital Signature</div>
                        <b>{this.props.detailDigitalSignature}</b>
                        <div>Full Name</div>
                        <b>{this.props.name}</b>
                        <div>Degree Title</div>
                        <b>{this.props.degree}</b>
                        <div>Status</div>
                        <b>{this.props.detailStatus}</b>
                        <div>Course</div>
                        <b>{this.props.detailCourse}</b>
                        <div>Degree</div>
                        <b>{this.props.detailDegreeCode}</b>
                        <div>Award Date</div>
                        <b>{this.props.date}</b>
                    </div>
                    
                </div>

            )
        }




        // Woods Hole
        if (

            this.props.type === DiplomaTypes.WH_ENG_RE_IS_RPT
            || this.props.type === DiplomaTypes.WH_EN_RPT



        )
        {
            return (
                <div className={`row no-gutters diploma${this.props.displayStyle}`}>
                    <div className={`${this.props.displayStyle === DiplomaDisplayStyle.Full ? 'col-lg-9' : 'col-lg-12'} `}>


                        <div className={`diploma ${this.props.displayStyle === DiplomaDisplayStyle.Minimal ? 'minimal' : ''}`}>
                            <img className="img-fluid heading mt-3" src="/images/MIT-diploma-header.png" alt="MIT Diploma header" />
                            <div className="diploma-text">AND</div>
                            <img className="img-fluid heading mt-3" src="/images/MIT-diploma-header-woods-hole.png" alt="Woods Hole Oceanographic Header" />
                            <div className="diploma-text mt-3">UPON THE RECOMMENDATION OF THEIR RESPECTIVE FACULTIES AND STAFFS DO HEREBY JOINTLY CONFER ON</div>
                            <div className="mt-3 filled-name"> {this.props.name}</div>

                            <div className="diploma-text mt-3">THE DEGREE OF</div>
                            <div className="filled-text">{this.props.degree}</div>
                            <div className="diploma-text mt-3">IN RECOGNITION OF PROFICIENCY IN THE GENERAL AND THE SPECIAL STUDIES AND EXERCISES PRESCRIBED FOR SUCH DEGREE GIVEN THIS DAY UNDER THE SEALS OF THE INSTITUTIONS IN THE COMMONWEALTH OF MASSACHUSETTS</div>
                            <div className="mt-3 diploma-text">{this.props.date}</div>
                            <div className="mt-3"><img className="signature-secretary" src="/images/MIT-diploma-secretary.jpg" alt="Secretary signature" /></div>
                            <div className="diploma-text">SECRETARY</div>
                            <div className="mt-3"><img className="signature-president" src="/images/MIT-diploma-president.png" alt="President signature" /></div>
                            <div className="diploma-text"> PRESIDENT</div>
                            <div className="mt-3"><img className="signature-director" src="/images/MIT-signature-president-director.png" alt="President and Director Signature" /></div>
                            <div className="diploma-text">PRESIDENT AND DIRECTOR</div>
                            <div className="mt-3"><img className="signature-board" src="/images/MIT-signature-secretary-board.png" alt="Secretary of the Board Signature" /></div>
                            <div className="diploma-text">SECRETARY OF THE BOARD</div>
                            <div className="mt-3"><QRCode value={this.props.publicUrl} /><img className="seal-wh" src="/images/MIT-woods-hole-seal.png" alt="MIT Seal" /></div>
                        </div>

                    </div>
                    <div className={` ${this.props.displayStyle === DiplomaDisplayStyle.Full ? 'col-lg-3' : 'd-none'} p-3 degreeProperties`}>
                        <div>Public Key</div>
                        <b>{this.props.detailPublicKey}</b>
                        <div>Digital Signature</div>
                        <b>{this.props.detailDigitalSignature}</b>
                        <div>Full Name</div>
                        <b>{this.props.name}</b>
                        <div>Degree Title</div>
                        <b>{this.props.degree}</b>
                        <div>Status</div>
                        <b>{this.props.detailStatus}</b>
                        <div>Course</div>
                        <b>{this.props.detailCourse}</b>
                        <div>Degree</div>
                        <b>{this.props.detailDegreeCode}</b>
                        <div>Award Date</div>
                        <b>{this.props.date}</b>
                    </div>
                    
                </div>

            )
        }


        //MASTER OF SCIENCE
        else if (this.props.type === DiplomaTypes.MASTER_SCI_RPT ||
            this.props.type === DiplomaTypes.MA_SCI_RE_IS_RPT
            || this.props.type === DiplomaTypes.MA_APPLSCI_RE_IS_RPT)
        {
            return (
                <div className={`row no-gutters diploma${this.props.displayStyle}`}>
                    <div className={`${this.props.displayStyle === DiplomaDisplayStyle.Full ? 'col-lg-9' : 'col-lg-12'} `}>
                        <div className="diploma">
                            <img className="img-fluid heading mt-3" src="/images/MIT-diploma-header.png" alt="MIT Diploma header" />
                            <div className="diploma-text mt-3">UPON THE RECOMMENDATION OF THE FACULTY HEREBY CONFERS ON</div>
                            <div className="mt-3 filled-name"> {this.props.name}</div>

                            <div className="diploma-text mt-3">THE DEGREE OF</div>
                            <div className="filled-text"><div>MASTER OF SCIENCE</div><div>IN</div> {this.props.degree}</div>
                            <div className="diploma-text mt-3">IN RECOGNITION OF PROFICIENCY IN THE GENERAL AND
                            THE SPECIAL STUDIES AND EXERCISES PRESCRIBED BY SAID
                            INSTITUTE FOR SUCH DEGREE GIVEN THIS DAY UNDER THE SEAL
               OF THE INSTITUTE AT CAMBRIDGE IN THE COMMONWEALTH OF MASSACHUSETTS</div>
                            <div className="mt-3 diploma-text">{this.props.date}</div>
                            <div className="mt-3"><img className="signature-secretary" src="/images/MIT-diploma-secretary.jpg" alt="Secretary signature" /></div>
                            <div className="diploma-text">SECRETARY</div>
                            <div className="mt-3"><img className="signature-president" src="/images/MIT-diploma-president.png" alt="President signature" /></div>
                            <div className="diploma-text"> PRESIDENT</div>
                            <div className="mt-3"><QRCode value={this.props.publicUrl} /><img className="seal" src="/images/MIT-seal.jpg" alt="MIT Seal" /></div>
                        </div>

                    </div>
                    <div className={` ${this.props.displayStyle === DiplomaDisplayStyle.Full ? 'col-lg-3' : 'd-none'} p-3 degreeProperties`}>
                        <div>Public Key</div>
                        <b>{this.props.detailPublicKey}</b>
                        <div>Digital Signature</div>
                        <b>{this.props.detailDigitalSignature}</b>
                        <div>Full Name</div>
                        <b>{this.props.name}</b>
                        <div>Degree Title</div>
                        <b>{this.props.degree}</b>
                        <div>Status</div>
                        <b>{this.props.detailStatus}</b>
                        <div>Course</div>
                        <b>{this.props.detailCourse}</b>
                        <div>Degree</div>
                        <b>{this.props.detailDegreeCode}</b>
                        <div>Award Date</div>
                        <b>{this.props.date}</b>
                    </div>
                    
                </div>
            )
        }


        //MASTER OF ENGINEERING
        else if (this.props.type === DiplomaTypes.MA_ENG_RE_IS_RPT)
        {
            return (
                <div className={`row no-gutters diploma${this.props.displayStyle}`}>
                    <div className={`${this.props.displayStyle === DiplomaDisplayStyle.Full ? 'col-lg-9' : 'col-lg-12'} `}>
                        <div className="diploma">
                            <img className="img-fluid heading mt-3" src="/images/MIT-diploma-header.png" alt="MIT Diploma header" />
                            <div className="diploma-text mt-3">UPON THE RECOMMENDATION OF THE FACULTY HEREBY CONFERS ON</div>
                            <div className="mt-3 filled-name"> {this.props.name}</div>

                            <div className="diploma-text mt-3">THE DEGREE OF</div>
                            <div className="filled-text"><div>MASTER OF ENGINEERING</div><div>IN</div> {this.props.degree}</div>
                            <div className="diploma-text mt-3">IN RECOGNITION OF PROFICIENCY IN THE GENERAL AND
                            THE SPECIAL STUDIES AND EXERCISES PRESCRIBED BY SAID
                            INSTITUTE FOR SUCH DEGREE GIVEN THIS DAY UNDER THE SEAL
           OF THE INSTITUTE AT CAMBRIDGE IN THE COMMONWEALTH OF MASSACHUSETTS</div>
                            <div className="mt-3 diploma-text">{this.props.date}</div>
                            <div className="mt-3"><img className="signature-secretary" src="/images/MIT-diploma-secretary.jpg" alt="Secretary signature" /></div>
                            <div className="diploma-text">SECRETARY</div>
                            <div className="mt-3"><img className="signature-president" src="/images/MIT-diploma-president.png" alt="President signature" /></div>
                            <div className="diploma-text"> PRESIDENT</div>
                            <div className="mt-3"><QRCode value={this.props.publicUrl} /><img className="seal" src="/images/MIT-seal.jpg" alt="MIT Seal" /></div>
                        </div>

                    </div>
                    <div className={` ${this.props.displayStyle === DiplomaDisplayStyle.Full ? 'col-lg-3' : 'd-none'} p-3 degreeProperties`}>
                        <div>Public Key</div>
                        <b>{this.props.detailPublicKey}</b>
                        <div>Digital Signature</div>
                        <b>{this.props.detailDigitalSignature}</b>
                        <div>Full Name</div>
                        <b>{this.props.name}</b>
                        <div>Degree Title</div>
                        <b>{this.props.degree}</b>
                        <div>Status</div>
                        <b>{this.props.detailStatus}</b>
                        <div>Course</div>
                        <b>{this.props.detailCourse}</b>
                        <div>Degree</div>
                        <b>{this.props.detailDegreeCode}</b>
                        <div>Award Date</div>
                        <b>{this.props.date}</b>
                    </div>
                    
                </div>
            )
        }

        //DOCTOR OF SCIENCE

        else if (this.props.type === DiplomaTypes.DR_SCI_RE_IS_RPT || this.props.type === DiplomaTypes.SCD_RPT
        )
        {
            return (
                <div className={`row no-gutters diploma${this.props.displayStyle}`}>
                    <div className={`${this.props.displayStyle === DiplomaDisplayStyle.Full ? 'col-lg-9' : 'col-lg-12'} `}>
                        <div className="diploma">
                            <img className="img-fluid heading mt-3" src="/images/MIT-diploma-header.png" alt="MIT Diploma header" />
                            <div className="diploma-text mt-3">UPON THE RECOMMENDATION OF THE FACULTY HEREBY CONFERS ON</div>
                            <div className="mt-3 filled-name"> {this.props.name}</div>

                            <div className="diploma-text mt-3">THE DEGREE OF</div>
                            <div className="filled-text">DOCTOR OF SCIENCE</div>
                            <div className="diploma-text mt-3">IN RECOGNITION OF SCIENTIFIC ATTAINMENTS AND THE ABILITY TO CARRY ON ORIGINAL RESEARCH AS DEMONSTRATED BY A THESIS IN THE FIELD OF</div>
                            <div className="mt-3 filled-text">{this.props.thesis}</div>
                            <div className="diploma-text mt-3">GIVEN THIS DAY UNDER THE SEAL OF THE INSTITUTE AT CAMBRIDGE IN THE COMMONWEALTH OF MASSACHUSETTS</div>
                            <div className="diploma-text mt-3">AWARD DATE</div>
                            <div className="mt-3 diploma-text">{this.props.date}</div>
                            <div className="mt-3"><img className="signature-secretary" src="/images/MIT-diploma-secretary.jpg" alt="Secretary signature" /></div>
                            <div className="diploma-text">SECRETARY</div>
                            <div className="mt-3"><img className="signature-president" src="/images/MIT-diploma-president.png" alt="President signature" /></div>
                            <div className="diploma-text"> PRESIDENT</div>
                            <div className="mt-3"><QRCode value={this.props.publicUrl} /><img className="seal" src="/images/MIT-seal.jpg" alt="MIT Seal" /></div>
                        </div>

                    </div>
                    <div className={` ${this.props.displayStyle === DiplomaDisplayStyle.Full ? 'col-lg-3' : 'd-none'} p-3 degreeProperties`}>
                        <div>Public Key</div>
                        <b>{this.props.detailPublicKey}</b>
                        <div>Digital Signature</div>
                        <b>{this.props.detailDigitalSignature}</b>
                        <div>Full Name</div>
                        <b>{this.props.name}</b>
                        <div>Degree Title</div>
                        <b>{this.props.degree}</b>
                        <div>Status</div>
                        <b>{this.props.detailStatus}</b>
                        <div>Course</div>
                        <b>{this.props.detailCourse}</b>
                        <div>Degree</div>
                        <b>{this.props.detailDegreeCode}</b>
                        <div>Award Date</div>
                        <b>{this.props.date}</b>
                    </div>
                    
                </div>
            )
        }
        //PHD
        else if (this.props.type === DiplomaTypes.PHD_RPT
            || this.props.type === DiplomaTypes.PHD_RE_IS_RPT
        )
        {
            return (
                <div className={`row no-gutters diploma${this.props.displayStyle}`}>
                    <div className={`${this.props.displayStyle === DiplomaDisplayStyle.Full ? 'col-lg-9' : 'col-lg-12'} `}>
                        <div className="diploma">
                            <img className="img-fluid heading mt-3" src="/images/MIT-diploma-header.png" alt="MIT Diploma header" />
                            <div className="diploma-text mt-3">UPON THE RECOMMENDATION OF THE FACULTY HEREBY CONFERS ON</div>
                            <div className="mt-3 filled-name"> {this.props.name}</div>

                            <div className="diploma-text mt-3">THE DEGREE OF</div>
                            <div className="filled-text">DOCTOR OF PHILOSOPHY</div>
                            <div className="diploma-text mt-3">IN RECOGNITION OF SCIENTIFIC ATTAINMENTS AND THE ABILITY TO CARRY ON ORIGINAL RESEARCH AS DEMONSTRATED BY A THESIS IN THE FIELD OF</div>
                            <div className="mt-3 filled-text">{this.props.thesis}</div>
                            <div className="diploma-text mt-3">GIVEN THIS DAY UNDER THE SEAL OF THE INSTITUTE AT CAMBRIDGE IN THE COMMONWEALTH OF MASSACHUSETTS</div>
                            <div className="diploma-text mt-3">AWARD DATE</div>
                            <div className="mt-3 diploma-text">{this.props.date}</div>
                            <div className="mt-3"><img className="signature-secretary" src="/images/MIT-diploma-secretary.jpg" alt="Secretary signature" /></div>
                            <div className="diploma-text">SECRETARY</div>
                            <div className="mt-3"><img className="signature-president" src="/images/MIT-diploma-president.png" alt="President signature" /></div>
                            <div className="diploma-text"> PRESIDENT</div>
                            <div className="mt-3"><QRCode value={this.props.publicUrl} /><img className="seal" src="/images/MIT-seal.jpg" alt="MIT Seal" /></div>
                        </div>

                    </div>
                    <div className={` ${this.props.displayStyle === DiplomaDisplayStyle.Full ? 'col-lg-3' : 'd-none'} p-3 degreeProperties`}>
                        <div>Public Key</div>
                        <b>{this.props.detailPublicKey}</b>
                        <div>Digital Signature</div>
                        <b>{this.props.detailDigitalSignature}</b>
                        <div>Full Name</div>
                        <b>{this.props.name}</b>
                        <div>Degree Title</div>
                        <b>{this.props.degree}</b>
                        <div>Status</div>
                        <b>{this.props.detailStatus}</b>
                        <div>Course</div>
                        <b>{this.props.detailCourse}</b>
                        <div>Degree</div>
                        <b>{this.props.detailDegreeCode}</b>
                        <div>Award Date</div>
                        <b>{this.props.date}</b>
                    </div>
                    
                </div>
            )
        }



        //MASTER OF SCIENCE WOODS HOLE
        else if (this.props.type === DiplomaTypes.WH_SM_RPT
            || this.props.type === DiplomaTypes.MA_SCI_WH_RE_IS_RPT
            || this.props.type === DiplomaTypes.WH_MENG_RPT
        )
        {
            return (
                <div className={`row no-gutters diploma${this.props.displayStyle}`}>
                    <div className={`${this.props.displayStyle === DiplomaDisplayStyle.Full ? 'col-lg-9' : 'col-lg-12'} `}>
                        <div className="diploma">
                            <img className="img-fluid heading mt-3" src="/images/MIT-diploma-header.png" alt="MIT Diploma header" />
                            <div className="diploma-text">AND</div>
                            <img className="img-fluid heading mt-3" src="/images/MIT-diploma-header-woods-hole.png" alt="Woods Hole Oceanographic Header" />
                            <div className="diploma-text mt-3">UPON THE RECOMMENDATION OF THEIR RESPECTIVE FACULTIES DO HEREBY JOINTLY CONFER ON</div>
                            <div className="mt-3 filled-name"> {this.props.name}</div>

                            <div className="diploma-text mt-3">THE DEGREE OF</div>
                            <div className={`filled-text`}>{(this.props.type === DiplomaTypes.WH_SM_RPT) && 'MASTER OF SCIENCE'}</div>
                            <div className={`filled-text`}>{(this.props.type === DiplomaTypes.MA_SCI_WH_RE_IS_RPT) && 'MASTER OF SCIENCE'}</div>
                            <div className={`filled-text`}>{(this.props.type === DiplomaTypes.WH_MENG_RPT) && 'MASTER OF ENGINEERING'}</div>
                            <div>IN</div>
                            <div>{this.props.degree}</div>
                            <div className="diploma-text mt-3">IN RECOGNITION OF PROFICIENCY IN THE GENERAL AND THE SPECIAL STUDIES AND EXERCISES PRESCRIBED FOR SUCH DEGREE GIVEN THIS DAY UNDER THE SEALS OF THE INSTITUTIONS IN THE COMMONWEALTH OF MASSACHUSETTS</div>
                            <div className="mt-3 diploma-text">{this.props.date}</div>
                            <div className="mt-3"><img className="signature-secretary" src="/images/MIT-diploma-secretary.jpg" alt="Secretary signature" /></div>
                            <div className="diploma-text">SECRETARY</div>
                            <div className="mt-3"><img className="signature-president" src="/images/MIT-diploma-president.png" alt="President signature" /></div>
                            <div className="diploma-text"> PRESIDENT</div>


                            <div className="mt-3"><img className="signature-director" src="/images/MIT-signature-president-director.png" alt="President and Director Signature" /></div>
                            <div className="diploma-text">PRESIDENT AND DIRECTOR</div>
                            <div className="mt-3"><img className="signature-board" src="/images/MIT-signature-secretary-board.png" alt="Secretary of the Board Signature" /></div>
                            <div className="diploma-text">SECRETARY OF THE BOARD</div>
                            <div className="mt-3"><QRCode value={this.props.publicUrl} /><img className="seal-wh" src="/images/MIT-woods-hole-seal.png" alt="MIT Seal" /></div>
                        </div>
                    </div>
                    <div className={` ${this.props.displayStyle === DiplomaDisplayStyle.Full ? 'col-lg-3' : 'd-none'} p-3 degreeProperties`}>
                        <div>Public Key</div>
                        <b>{this.props.detailPublicKey}</b>
                        <div>Digital Signature</div>
                        <b>{this.props.detailDigitalSignature}</b>
                        <div>Full Name</div>
                        <b>{this.props.name}</b>
                        <div>Degree Title</div>
                        <b>{this.props.degree}</b>
                        <div>Status</div>
                        <b>{this.props.detailStatus}</b>
                        <div>Course</div>
                        <b>{this.props.detailCourse}</b>
                        <div>Degree</div>
                        <b>{this.props.detailDegreeCode}</b>
                        <div>Award Date</div>
                        <b>{this.props.date}</b>
                    </div>
                    
                </div>

            )
        }



    //WOODS HOLE Doctor of
    else if (this.props.type === DiplomaTypes.WH_PHD_RPT
        || this.props.type === DiplomaTypes.WH_PHD_RE_IS_RPT
        || this.props.type === DiplomaTypes.WH_SCD_RPT
        || this.props.type === DiplomaTypes.WH_DR_SCI_RE_IS_RPT
        
        
        ){
        return(
<div className={`row no-gutters diploma${this.props.displayStyle}`}>

                    <div className={`${this.props.displayStyle === DiplomaDisplayStyle.Full ? 'col-lg-9' : 'col-lg-12'} `}>
                        <div className="diploma">
                            <img className="img-fluid heading mt-3" src="/images/MIT-diploma-header.png" alt="MIT Diploma header" />
                            <div className="diploma-text">AND</div>
                            <img className="img-fluid heading mt-3" src="/images/MIT-diploma-header-woods-hole.png" alt="Woods Hole Oceanographic Header" />
                            <div className="diploma-text mt-3">UPON THE RECOMMENDATION OF THEIR RESPECTIVE FACULTIES DO HEREBY JOINTLY CONFER ON</div>
                            <div className="mt-3 filled-name"> {this.props.name}</div>

                            <div className="diploma-text mt-3">THE DEGREE OF</div>
                            <div className={`filled-text`}>{(this.props.type === DiplomaTypes.WH_PHD_RPT) && 'DOCTOR OF PHILOSOPHY'}</div>
                            <div className={`filled-text`}>{(this.props.type === DiplomaTypes.WH_PHD_RE_IS_RPT) && 'DOCTOR OF PHILOSOPHY'}</div>
                            <div className={`filled-text`}>{(this.props.type === DiplomaTypes.WH_SCD_RPT) && 'DOCTOR OF SCIENCE'}</div>
                            <div className={`filled-text`}>{(this.props.type === DiplomaTypes.WH_DR_SCI_RE_IS_RPT) && 'DOCTOR OF SCIENCE'}</div>
                            <div className="diploma-text mt-3">IN RECOGNITION OF SCIENTIFIC ATTAINMENTS AND THE ABILITY TO CARRY ON ORIGINAL RESEARCH AS DEMONSTRATED BY A THESIS IN THE FIELD OF</div>
                            <div className="mt-3 filled-text">{this.props.thesis}</div>
                            <div className="diploma-text mt-3">GIVEN THIS DAY UNDER THE SEALS OF THE INSTITUTIONS IN THE COMMONWEALTH OF MASSACHUSETTS</div>
                            <div className="diploma-text mt-3">AWARD DATE</div>
                            <div className="mt-3 diploma-text">{this.props.date}</div>
                            <div className="mt-3"><img className="signature-secretary" src="/images/MIT-diploma-secretary.jpg" alt="Secretary signature" /></div>
                            <div className="diploma-text">SECRETARY</div>
                            <div className="mt-3"><img className="signature-president" src="/images/MIT-diploma-president.png" alt="President signature" /></div>
                            <div className="diploma-text"> PRESIDENT</div>


                            <div className="mt-3"><img className="signature-director" src="/images/MIT-signature-president-director.png" alt="President and Director Signature" /></div>
                            <div className="diploma-text">PRESIDENT AND DIRECTOR</div>
                            <div className="mt-3"><img className="signature-board" src="/images/MIT-signature-secretary-board.png" alt="Secretary of the Board Signature" /></div>
                            <div className="diploma-text">SECRETARY OF THE BOARD</div>
                            <div className="mt-3"><QRCode value={this.props.publicUrl} /><img className="seal-wh" src="/images/MIT-woods-hole-seal.png" alt="MIT Seal" /></div>
                        </div>

                    </div>
                    <div className={` ${this.props.displayStyle === DiplomaDisplayStyle.Full ? 'col-lg-3' : 'd-none'} p-3 degreeProperties`}>
                        <div>Public Key</div>
                        <b>{this.props.detailPublicKey}</b>
                        <div>Digital Signature</div>
                        <b>{this.props.detailDigitalSignature}</b>
                        <div>Full Name</div>
                        <b>{this.props.name}</b>
                        <div>Degree Title</div>
                        <b>{this.props.degree}</b>
                        <div>Status</div>
                        <b>{this.props.detailStatus}</b>
                        <div>Course</div>
                        <b>{this.props.detailCourse}</b>
                        <div>Degree</div>
                        <b>{this.props.detailDegreeCode}</b>
                        <div>Award Date</div>
                        <b>{this.props.date}</b>
                    </div>
                    
                </div>
            )
        }
        if (this.props.type === DiplomaTypes.Other)
        {
            return (<div>Unknown Degree Type</div>)

        }
        else return (<div>Unknown Degree Type</div>)






    }
} 