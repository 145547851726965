import BaseController from "./BaseController";

export interface FeaturesResponse {
	data?: Features
	error?: any
}

export interface Features {
	features: string[]
}


class FeatureController extends BaseController {
	readonly url_features: string

	constructor() {
		super()

		this.url_features = `${this.apiHost}/${this.apiPathFeature}/features/vaccine`
	}

  fetchFeatures = async (): Promise<FeaturesResponse> => {
    let response = await fetch(this.url_features, await this.requestHeaderGet())
    if (response.status >= 200 && response.status < 300) {
        let data: string[] = await response.json()
        return {
          data: {features: data}
        }
    } else {
        return {
            error: true
        }
    }
  }
}

const featureController = new FeatureController()
export default featureController
