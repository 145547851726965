import React from 'react';
import { FormType } from './Form';

/** @deprecated This component has been deprecated. Use the FormContainer located in Forms instead. **/
export interface FormRowProps
{
    //required
    label: any,
    control: any,

    //optional
    comment?: string,
    rowType?: FormRowType,
    formType?: FormType, // need to make it by default be input form
    register?: any,
    errors?: any,
    setValue?: any
}

/** @deprecated This component has been deprecated. Use the FormContainer located in Forms instead. **/
export enum FormRowType
{
    OnTop,
    NextTo
}

/** @deprecated This component has been deprecated. Use the form located in Forms instead. **/
export default class FormRow extends React.Component<FormRowProps>
{
    public static defaultProps =
        {
            formType: FormType.Input
        };

    getClasses(): string
    {
        var classes: string[] = [];

        if (this.props.rowType === FormRowType.OnTop)
            classes.push('cluster');

        if (this.props.rowType === FormRowType.NextTo)
            classes.push('row');

        return classes.join(' ');
    }

    renderControl()
    {
        if (this.props.control)
        {
            let element = this.props.control[0];

            try
            {
                let control = React.cloneElement(element, { register: this.props.register, errors: this.props.errors, setValue: this.props.setValue });

                return control;
            } catch (e)
            {
                return this.props.control;
            }
        }

        return this.props.control;
    }

    render(): React.ReactNode 
    {
        if (this.props.rowType === FormRowType.OnTop)
        {
            return (
                <div className={`form-group ${this.getClasses()}`}>
                    <div >{this.props.label}</div>
                    <div >{this.renderControl()}</div>
                </div>
            );
        }

        else if (this.props.rowType === FormRowType.NextTo)
        {
            return (
                <div className={`form-group ${this.getClasses()}`}>
                    <span className="col-sm-4 col-form-label text-right">{this.props.label}<br /><small>{this.props.comment}</small></span>
                    <span className={`col-sm-8 ml-auto ${(this.props.formType === FormType.Output) && "col-form-label"}`}>{this.renderControl()}</span>
                </div>
            );
        }


    }
}