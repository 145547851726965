import React, { FC } from 'react';
import { ButtonBarProps, ButtonBarType, ButtonBar } from '../Button';
import { FormContainerProps, FormContainer } from './FormContainer';
import { useForm } from 'react-hook-form';

/** @deprecated This component has been deprecated. Use the FormContainer located in Forms instead. **/
export interface FormProps
{
    actionBar: ButtonBarProps;
    formItems: FormContainerProps[];
    formValidationType?: FormValidationType;
}

/** @deprecated This component has been deprecated. Use the FormContainer located in Forms instead. **/
export enum FormValidationType
{
    onChange,
    onSubmit,
    onBlur
}


/** @deprecated This component has been deprecated. Use the FormContainer located in Forms instead. **/
export enum FormType
{
    Input, //this form takes inputs
    Output // this form prints out the values of an input form
}

/** @deprecated This component has been deprecated. Use the FormContainer located in Forms instead. **/
export const Form: FC<FormProps> =
    (
        props = {
            formValidationType: FormValidationType.onSubmit,
            actionBar: { buttons: [], type: ButtonBarType.None },
            formItems: [],
        }
    ) => 
    {
        const getValidationType = () =>
        {
            if (props.formValidationType === FormValidationType.onChange)
                return "onChange";

            if (props.formValidationType === FormValidationType.onBlur)
                return "onBlur";

            return "onSubmit";
        };

        const { register, errors, setValue, trigger } = useForm({
            mode: getValidationType(),
        });

        return (
            <div className="form">
                <div className="row">
                    {props.formItems.length === 1 &&
                        props.formItems.map((form: FormContainerProps, number: number) =>
                        {
                            return <FormContainer key={number} {...form}
                                register={register}
                                errors={errors}
                                setValue={setValue} />;
                        })}

                    {props.formItems.length > 1 &&
                        props.formItems.map((form: FormContainerProps, number: number) =>
                        {
                            return (
                                <div className="col-lg-6" key={number}>
                                    <FormContainer
                                        {...form}
                                        register={register}
                                        errors={errors}
                                        setValue={setValue}
                                    />
                                </div>
                            );
                        })}
                </div>
                <ButtonBar trigger={trigger} {...props.actionBar} />
            </div>
        );
    };