import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AutoComplete } from "../AutoComplete";
import { TextboxProps } from "../Textbox";

// types?: 'country' | 'region' | 'postcode' | 'district' | 'place' | 'locality' | 'neighborhood' | 'address' | 'poi'

interface Props {
	accessToken: string;
	name: string;
	icon?: string;
	value?: any;
	required?: boolean;
	limit?: number;
	country?: string;
	category?: string;
	types?: String;
	showPlainText?: boolean;
	fuzzyMatch?: boolean;
	useCurrentLocation?: boolean;
	proximity?: Coordinates;
	boxed?: BBOX;
	onChange?: (v: any) => void;
	placeholderText?: string;
	register?: any; // https://react-hook-form.com/api#register
	errors?: any; // https://react-hook-form.com/api#errors
	setValue?: any; // https://react-hook-form.com/api#setValue
}

interface Coordinates {
	lng: string;
	lat: string;
}

interface BBOX {
	first_bbox: Coordinates;
	second_bbox: Coordinates;
}

export const LocationSearch: React.FC<Props> = (props) => {
	const [isLoading, setIsLoading] = useState(false);
	const [items, setItems] = useState([]);
	const [poiOptions, setPoiOptions] = useState<any>();
	let [prox, setProximity] = useState<string>("");
	let navigator = window.navigator;

	let { register, errors, setValue } = useForm({
		mode: "onChange",
	});

	if (props.register) register = props.register;

	if (props.errors) errors = props.errors;

	if (props.setValue) setValue = props.setValue;

	useEffect(() => {
		if (props.value) onSearch(props.value.text);

		if ("geolocation" in navigator && props.useCurrentLocation) {
			console.log("Available", navigator);
			getProximity();
		} else {
			console.log("Not Available");
		}
		// eslint-disable-next-line
	}, []);

	const onSearch = (q: any) => {
		if (q && q.length > 0) {
			let search_string = encodeURI(q);
			let url = "";
			const mapboxPlaces = `https://api.mapbox.com/geocoding/v5/mapbox.places/${search_string}.json?limit=${props.limit}&proximity=${prox}&access_token=${props.accessToken}&language=en&fuzzyMatch=${
				props.fuzzyMatch ?? false
			}`;
			//should prob move this to a controller
			//better way to store this public access token?

			let paramaters = "";
			const paramatersArray: Array<string> = [];
			if (props.country) {
				paramatersArray.push(`country=${props.country}`);
			}
			if (props.types) {
				paramatersArray.push(`types=${props.types}`);
			}
			if (props.proximity) {
				setProximity(() => `proximity=${props.proximity?.lng},${props.proximity?.lat}`);
				// paramatersArray.push(`proximity=${proximity.lng},${proximity.lat}`)
			}
			if (props.boxed) {
				paramatersArray.push(`bbox=${props.boxed.first_bbox.lng},${props.boxed.first_bbox.lat},${props.boxed.second_bbox.lng},${props.boxed.second_bbox.lat}`);
			}

			paramaters = paramatersArray.map((param: string) => param).join("&");

			if (paramatersArray.length >= 1) {
				url = `${mapboxPlaces}&${paramaters}`;
			} else {
				url = mapboxPlaces;
			}

			setIsLoading(true);
			fetch(url)
				.then((response) => response.json())
				.then((data) => {
					setIsLoading(false);
					// console.log('onSearch REPSONSE', data);
					let items = data.features;
					if (items)
						items = items
							.filter((x: any) => {
								if (props.category) return x.properties.category === props.category.toLowerCase();
								else return true;
							})
							.map((row: any) => {
								let place_name = props.showPlainText ? row.text : row.place_name;
								return { ...row, id: place_name, text: place_name };
							});
					let options = data.features
						?.filter((place: any) => place.place_type.includes("poi"))
						.map((poi: any) => ({
							id: poi.id,
							center: poi.center,
							name: poi.text,
						}));
					setItems(items);
					setPoiOptions(options);
				});
		}
	};

	const formatLocation = (value: any) => {
		// console.log('VALUE ', value);
		let location: any = {
			address: null,
			city: null,
			state: null,
			country: null,
			postal_code: null,
			detailText: null,
			text: null,
			coordinates: null,
			poi: null,
			category: null,
		};

		location.text = value.text;
		location.detailText = value.place_name;

		location.poi = poiOptions || [];

		location.coordinates = {
			lng: value.center[0],
			lat: value.center[1],
		};

		getLocationTypeObject(value, location);
		if (value.context) {
			for (let row of value.context) {
				getLocationTypeObject(row, location);
			}
		}

		location.json = value;

		console.log(location);
		return location;
	};

	const getLocationTypeObject = (value: any, location: any) => {
		let type = value.id.indexOf(".") !== -1 ? value.id.split(".")[0] : value.place_type[0];
		let newText = value.text ? value.text?.split(",")[0] : null;
		// if (value.text) {
		//     value.text = value.text?.split(',')[0];
		// }

		if (value.properties?.short_code) {
			value.short_code = value.properties?.short_code;
		}

		if (value.properties?.category) {
			location.category = value.properties?.category;
		}

		if (type === "address") {
			location.address = `${value.address} ${value.original_text}`;
		}

		if (type === "place") {
			location.city = value.original_text || newText;
		}

		if (type === "postcode") {
			location.postal_code = value.original_text || newText;
		}

		if (type === "region") {
			location.state = {
				id: value.short_code,
				text: value.original_text || newText,
			};
		}
		if (type === "country") {
			location.country = {
				id: value.short_code,
				text: value.original_text || newText,
			};
		}
	};

	const getProximity = () => {
		navigator.geolocation.getCurrentPosition(
			function (position) {
				if (position.coords.longitude) {
					setProximity(`${position.coords.longitude},${position.coords.latitude}`);
				}
			},
			(err: any) => {
				console.log(err);
			},
			{ timeout: 2000 }
		);
	};

	return (
		<>
			<AutoComplete
				name={props.name}
				icon={props.icon}
				items={items || []}
				searchOptions={{
					name: "",
					placeholderText: props.placeholderText ?? "Search for a location",
				}}
				isBusy={isLoading}
				value={props.value}
				required={props.required}
				onSearch={onSearch}
				limit={20}
				errors={errors}
				onChange={(v: any) => props.onChange && props.onChange(formatLocation(v))}
				clearOnBlur
				register={register}
				setValue={setValue}

				// showItemsOnFocus
			/>
		</>
	);
};

LocationSearch.defaultProps = {
	required: false,
	limit: 10,
	icon: "search",
	useCurrentLocation: false,
};
