import * as React from 'react';
import { Context, createContext } from 'react';
import { TableLvl2Props } from './types';

export const defaults: TableLvl2Props = {
  variant: 'body',
};
const TableLvl2Context: Context<TableLvl2Props> = createContext(defaults);

const TableLvl2ContextProvider = (
  props: React.PropsWithChildren<TableLvl2Props>
) => {
  const finalProps = { ...defaults, ...props };
  const { children } = finalProps;
  return (
    <TableLvl2Context.Provider value={finalProps}>
      {children}
    </TableLvl2Context.Provider>
  );
};

export { TableLvl2Context, TableLvl2ContextProvider };
