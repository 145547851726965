import React, { Fragment, useState, useEffect, FC } from "react";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import Button, { ButtonType, ButtonProps, ButtonState } from "./Button/Button";
import { ButtonBar, ButtonBarType } from "./Button";
import { Text } from "..";
import { ValidationProps } from "./Form"
import { TemplateModal, TemplateModalAlignment } from "./Template";
import { TextType } from "./Text";
import { useForm } from "react-hook-form";
import { ValidationContainer } from "./Form/ValidationContainer";

interface IImageUploadProps {
	name?: string;
	isLoading?: boolean;
	label?: string;
	onChange: (file: any) => void;
	onDelete?: () => void;
	onRejected?: (fileRejections: FileRejection[]) => void;
	onAccepted?: <T extends File>(files: T[], event: DropEvent) => void;
	maxSize?: number;
	maxHeight?: number;
	maxWidth?: number;
	minHeight?: number;
	minWidth?: number;
	maxSizeErrorText?: string;
	text?: string;
	multiple?: boolean;
	imageUrl?: string;
	displayButtons?: boolean;
	confirmOnClear?: boolean;
	noClear?: boolean;
	editButtonAriaLabel?: string;
	value?: string;
	disabled?:boolean;
	validation?: ValidationProps;
	required?: boolean; //A Boolean which, if true, indicates that the input must have a value before the form can be submitted.
	register?: any; // https://react-hook-form.com/api#register
	errors?: any; // https://react-hook-form.com/api#errors
	setValue?: any; // https://react-hook-form.com/api#setValue
}

export const ImageUpload: FC<IImageUploadProps> = (props) => {
	const [myFiles, setMyFiles] = useState(props.imageUrl ? [{ preview: props.imageUrl }] : []);
	const [loading, setLoading] = useState(false);
	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const [selectedFile, setSelectedFile] = useState<any>();
	const [loaded, setLoaded] = useState<boolean>(false);

	let { register, errors, setValue } = useForm({
		mode: "onChange",
	});

	if (props.register) register = props.register;

	if (props.errors) errors = props.errors;

	if (props.setValue) setValue = props.setValue;
	
	useEffect(() => {
		if (props.isLoading !== undefined) setLoading(props.isLoading);
	}, [props.isLoading]);

	useEffect(() => {
		if (props.imageUrl && props.imageUrl.length > 0) {
			setMyFiles([{ preview: props.imageUrl }]);
		}
	}, [props.imageUrl]);

	const onDrop = (acceptedFiles: any) => {
		setMyFiles(
			acceptedFiles.map((file: any) =>
				Object.assign(file, {
					preview: URL.createObjectURL(file),
				})
			)
		);
		props.onChange && props.onChange(acceptedFiles);
	};
	
	const onDropRejected = (fileRejections: FileRejection[]) => {
		
		props.onRejected && props.onRejected(fileRejections);
	};
	
	const onDropAccepted = <T extends File>(files: T[], event: DropEvent) => {
		props.onAccepted && props.onAccepted(files, event);
	};

	const { getRootProps, getInputProps, fileRejections, open } = useDropzone({
		accept: "image/*, application/pdf",
		maxSize: props.maxSize,
		multiple: false,
		onDrop,
		onDropRejected,
		onDropAccepted,
		disabled: props.disabled,
		getFilesFromEvent: async (event: any):Promise<(File | DataTransferItem)[]> => {
			console.log('getFilesFromEvent event', event)
			if(event === null) return event.target.files;

			const files = event.target.files || event.dataTransfer.files
			const promises = []
			for (let index = 0; index < files.length; index++) {
			  const file = files[index]
			  const promise = new Promise((resolve, reject) => {
				const image = new Image()
				let url: string
				image.onload = function () {
				  file.width = image.width
				  file.height = image.height
				  resolve(file)
				}
				url = URL.createObjectURL(file)
				image.src = url
			  })
			  promises.push(promise)
			}

			let res:Promise<(File | DataTransferItem)[]> = await Promise.all(promises).then(res=> res).catch(err => {
				console.error('Error validating image dimentions', err)
				return event.target.files 
			});

			return res;
		  },
		  validator: (file:any) => {
			let err = null
			//check max dimentions
			if((props.maxWidth && file.width > props.maxWidth) && (props.maxHeight && file.height > props.maxHeight)) {
				err = {
					code: "large-width-height",
					message: `Image dimentions must be lower than W:${props.maxWidth}px X H:${props.maxHeight}px`,
				}
			}else if(props.maxWidth && file.width > props.maxWidth) {
				err = {
					code: "large-width",
					message: `Image width must be lower than ${props.maxWidth}px`,
				}
			} else if(props.maxHeight && file.height > props.maxHeight) {
				err = {
					code: "large-height",
					message: `Image height must be lower than ${props.maxHeight}px`,
				}
			}

			//check min Dimetions 
			if((props.minWidth && file.width < props.minWidth) && (props.minHeight && file.height < props.minHeight)) {
				err = {
					code: "small-width-height",
					message: `Image dimentions must be greater than W:${props.minWidth}px X H:${props.minHeight}px`,
				}
			}else if(props.minWidth && file.width < props.minWidth) {
				err = {
					code: "small-width",
					message: `Image width must be greater than ${props.minWidth}px`,
				}
			}else if(props.minHeight && file.height < props.minHeight) {
				err = {
					code: "small-height",
					message: `Image height must be greater than ${props.minHeight}px`,
				}
			}
			
			return err
		  }
	});

	const thumbs = myFiles.map((file: any, index) => (
		<div key={`loader-div-${index}`}>
			{!loaded && (
				<div className="skeleton-loader"></div>
			)}
			<img src={file.preview} style={!loaded ? { display: "none" } : { width: "100%", height: "100%" }} onLoad={() => setLoaded(true)} alt={file.name} />
		</div>
	));

	const removeFile = (file: File) => {
		const newFiles = [...myFiles];
		//@ts-ignore
		newFiles.splice(newFiles.indexOf(file), 1);
		setMyFiles(newFiles);
		props.onChange && props.onChange(newFiles);
	};

	// We need this upload control to show an icon per mime type, for upload needs great ux because it can be real stupid.
	const files = myFiles.map((file: any, i:number) => {
		const defaultButtonData: ButtonProps[] = [
			{
				icon: "edit",
				text: "Edit",
				type: ButtonType.Secondary | ButtonType.Small,
				onClick: open,
				state: ButtonState.Enabled,
        		customAriaLabel: props.editButtonAriaLabel,
			},
		];

		if (!props.noClear)
			defaultButtonData.unshift({
				icon: "times",
				text: "",
				type: ButtonType.Secondary | ButtonType.Small | ButtonType.Ghost,
				onClick: () => {
					if (props.confirmOnClear) {
						setSelectedFile(file);
						setShowConfirmModal(true);
					} else {
						removeFile(file);
					}
				},
				state: ButtonState.Enabled,
			});

		return (
			<Fragment>
				<div className="dropzone image confirm-area" key={i}>
					{props.onDelete && 
						<Button
							id="deleteButton"
							icon={"trash"}
							type={ButtonType.Icon}
							onClick={() => {
								removeFile(file)
								props.onDelete && props.onDelete()
								
							}}
							state={ButtonState.Enabled}
						/>
					}
					<div key={file.path} className="thumbnail-area">
						{thumbs}
					</div>

					<input {...getInputProps()}/>
					{loaded && (
						<div className="controls image-upload">
							<div className="title-bar text-left">{props.label}</div>
							{(props.displayButtons === undefined || props.displayButtons) && <ButtonBar type={ButtonBarType.Padded} alignment={"right"} buttons={defaultButtonData} />}
						</div>
					)}
				</div>
			</Fragment>
		);
	});

	const fileRejectionItems = fileRejections.map(({ file, errors }, i:number) => (
		<Fragment>
			{errors.map((e) => (
				<Text content={props.maxSizeErrorText ? props.maxSizeErrorText : e.message} color={"red"} key={`error-message-${e.code}-${i}`} padded={false} />
			))}
		</Fragment>
	));

	return (
		<React.Fragment>
			{!loading ? (
				files.length === 0 ? (
					<div {...getRootProps({ className: `dropzone upload-area${props.disabled ? ' disabled' : ''}` })} role='button' >
						<input type={'hidden'} name={props.name ?? 'upload'} ref={register({required: props.required})} />
						<input {...getInputProps()} />
						<div className="content">
							<i className="fal fa-paperclip"></i>
							{/* Needs to be our icon component */}
							<div>{props.text}</div>
						</div>
						<aside>{fileRejectionItems}</aside>
						<ValidationContainer name={props.name ?? ''} validation={props.validation} errors={errors} />
					</div>
				) : (
					files
				)
			) : (
				<div className="dropzone loading">
					<div className="content">
						<div className="icon">
							<i className="fas fa-cloud-upload-alt"></i>
							{/* Needs to be our icon component */}
						</div>
						<div className="text">Processing...</div>
					</div>
				</div>
			)}
			<TemplateModal
				body={
					<>
						<Text content="Are you sure you want to remove this image?" />
					</>
				}
				show={showConfirmModal}
				onClose={() => setShowConfirmModal(false)}
				bodyAlignment={TemplateModalAlignment.Center}
				header={<Text content="Are you sure?" type={TextType.Heading4} />}
				name="confirmModal"
				footer={
					<div>
						<Button type={ButtonType.Secondary | ButtonType.Ghost} onClick={() => setShowConfirmModal(false)} text={"Go back"} />
						&nbsp;
						<Button
							type={ButtonType.Secondary}
							onClick={() => {
								removeFile(selectedFile);
								setShowConfirmModal(false);
							}}
							text={"Yes, I'm sure"}
						/>
					</div>
				}
			/>
		</React.Fragment>
	);
};

ImageUpload.defaultProps = {
	text: "Drop your file(s) here",
	isLoading: false,
	maxSize: 200000000,
	multiple: false,
	confirmOnClear: false,
	noClear: false
};
