import React, {useEffect, useState} from 'react';
import {
  AreaChart,
  BarChart,
  LineChart,
  ComposedChart,
  FunnelChart,
  RadialBarChart,
  RadarChart,
  XAxis,
  YAxis,
  Area,
  Bar,
  Line,
  Funnel,
  RadialBar,
  Radar,
  Tooltip,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
  Legend
} from 'recharts'
import { BasicColors } from '../common/GlobalTypes';
import { colors } from '../styles/hui-theme'

interface ChartProps {
  type: 'area' | 'bar' | 'line' | 'composed' | 'pie' | 'radar' | 'radialBar' | 'scatter' | 'funnel'
  data: any[]
  graphColor?: BasicColors
  hideAxisY?: boolean
  showLegend?: boolean
  showTooltip?: boolean
}

export const Chart: React.FC<ChartProps> =  (
  {
    type,
    data,
    graphColor,
    hideAxisY,
    showLegend,
    showTooltip,
  }) => {
  const [color, setColor] = useState<string>('#007bff');

  useEffect(() => {
    if (!graphColor) {
      return
    }

    getColor(graphColor)
  }, [graphColor])

  const getColor = (color: string) => {
		let colorData: any = { //TODO - change when theme updates are done
			'red': () => setColor(colors['warning']),
			'green': () => setColor(colors['success']),
			'blue': () => setColor(colors['blue']),
			'grey': () => setColor(colors['gray']),
			'white': () => setColor(colors['light']),
			'orange': () => setColor(colors['danger']),
			'purple': () => setColor(colors['purple']),
			'yellow': () => setColor(colors['yellow']),
		}
		colorData[color.toLowerCase()]();
	}

  const getMaxVal = () => {
    let maxNumber = 0;
    data.forEach(item => {
      Object.values(item).forEach(val => {
        if (typeof val === "number" && val > maxNumber) {
          maxNumber = val
        }
      })
    })
    return maxNumber
  }

  const colorCalc = (value: number) => {
    let R = parseInt(color.substring(1,3),16);
    let G = parseInt(color.substring(3,5),16);
    let B = parseInt(color.substring(5,7),16);

    R = parseInt(`${R * (100 + value) / 100}`);
    G = parseInt(`${G * (100 + value) / 100}`);
    B = parseInt(`${B * (100 + value) / 100}`);

    R = (R<255)?R:255;
    G = (G<255)?G:255;
    B = (B<255)?B:255;

    const RR = ((R.toString(16).length==1)?"0"+R.toString(16):R.toString(16));
    const GG = ((G.toString(16).length==1)?"0"+G.toString(16):G.toString(16));
    const BB = ((B.toString(16).length==1)?"0"+B.toString(16):B.toString(16));

    return "#"+RR+GG+BB;
  }

  const chartType = () => {
    const keys = Object.keys(data[0]).slice(1)
    switch (type) {
      case "area":
        return (
          <AreaChart data={data}>
            <XAxis dataKey={Object.keys(data[0])[0]} />
            {!hideAxisY && <YAxis />}
            {keys ? keys.map((k: any, index) => (
              <Area key={`area-${index}`} dataKey={k} fill={`${colorCalc(index * -20)}`} />
            )) : null}
            {showLegend && <Legend />}
            {showTooltip && <Tooltip />}
          </AreaChart>
        )
      case "bar":
        return (
          <BarChart data={data}>
            <XAxis dataKey={Object.keys(data[0])[0]} />
            {!hideAxisY && <YAxis />}
            {keys ? keys.map((k: any, index) => (
              <Bar key={`bar-${index}`} dataKey={k} fill={`${colorCalc(index * -20)}`} />
            )) : null}
            {showLegend && <Legend />}
            {showTooltip && <Tooltip />}
          </BarChart>
        )
      case "line":
        return (
          <LineChart data={data} >
            <XAxis dataKey={Object.keys(data[0])[0]} />
            {!hideAxisY && <YAxis />}
            {keys ? keys.map((k: any, index) => (
              <Line key={`line-${index}`} dataKey={k} fill={`${colorCalc(index * -20)}`} />
            )) : null}
            {showLegend && <Legend />}
            {showTooltip && <Tooltip />}
          </LineChart>
        )
      case "funnel":
        return (
          <FunnelChart>

          </FunnelChart>
        )
      case "radialBar":
        return (
          <RadialBarChart innerRadius="10%" outerRadius="80%" data={data} startAngle={180} endAngle={0}>
            <RadialBar dataKey={keys[0]} fill={`${colorCalc(0)}`} background={true} />
            {showLegend && <Legend width={120} layout='vertical' verticalAlign='middle' align="right" />}
            {showTooltip && <Tooltip />}
          </RadialBarChart>
        )
      case "composed":
        return (
          <ComposedChart data={data} >
            <XAxis dataKey={Object.keys(data[0])[0]} />
            <YAxis />
            {showLegend && <Legend width={120} layout='vertical' verticalAlign='middle' align="right" />}
            {showTooltip && <Tooltip />}
            <Area dataKey={keys[0]} fill={colorCalc(0)} stroke="#00000000"/>
            <Bar dataKey={keys[1]} fill={colorCalc(-20)} barSize={20}/>
            <Line dataKey={keys[2]} fill={colorCalc(-40)} />
          </ComposedChart>
        )
      case "radar":
        return (
          <RadarChart data={data}>
            <PolarGrid />
            <PolarAngleAxis dataKey={Object.keys(data[0])[0]} />
            <PolarRadiusAxis angle={180/data.length} domain={[0, getMaxVal()]} />
            {keys ? keys.map((k: any, index) => (
              <Radar key={`radar-${index}`} name={Object.keys(data[0])[index + 1]} dataKey={k} fill={`${colorCalc(index * -20)}`} fillOpacity={0.6} />
            )) : null}
            {showLegend && <Legend width={120} layout='vertical' verticalAlign='middle' align="right" />}
            {showTooltip && <Tooltip />}
          </RadarChart>
        )
      default:
        return <div>This chart type does not exist, or is coming soon</div>
    }
  }

  return (
    <ResponsiveContainer>
      {chartType()}
    </ResponsiveContainer>
  )
}
