//
//
// Should this item be depricated in favour of the Accordion Item just used within a ActionList?
//
import React from 'react';

import AccordionItem, { AccordionItemProps } from './AccordionItem';
 
export interface AccordionListProps
{
    name: string,
    type: string,
    items: AccordionItemProps[],
    isLoading?: boolean
}


export default class AccordionList extends React.Component<AccordionListProps>
{
    render(): React.ReactNode
    {
        return (
            <div >
                
                {this.props.items.map((item: AccordionItemProps, number: number) => (
                    <AccordionItem {...item} key={number} />
                ))}

            </div>
        );
    }
}