import * as React from 'react'
import {FC, Fragment} from "react";
import {inject, observer, Provider} from "mobx-react";
import {CovidNavInterface} from "../../model/CovidNav";
import {PeopleStoreInterface} from "../../model/PeopleStore";
import {FollowUpList} from "./FollowUpList";
import {HoldsModelInterface} from "../../model/Holds";

interface FollowUpComponentProps {
    covidNav?: CovidNavInterface;
    holds?: HoldsModelInterface;
    peopleStore? : PeopleStoreInterface;
    history?: any;
}

const FollowUpComponent: FC<FollowUpComponentProps> = ({covidNav, holds, peopleStore, history}) => {

    return <Fragment>
            <Provider peopleStore={peopleStore}><FollowUpList /></Provider>
        </Fragment>
}

const FollowUp = inject('peopleStore', 'holds', 'covidNav')(observer(FollowUpComponent))
export default FollowUp