import React from 'react';

export interface ValidationProps
{
    message: string,
    type: ValidationType,
    active: boolean,

}

export enum ValidationType
{
    Required,
    Format,
    System
}


export default class Validation extends React.Component<ValidationProps>
{
    getClasses(): string
    {
        var classes: string[] = [];
        if (this.props.type === ValidationType.Required)
            classes.push('required-validation');

        return classes.join(' ');
    }

    render(): React.ReactNode 
    {
        if (this.props.active && this.props.message)
        {
            return (
                <div className={`shake-horizontal ${this.getClasses()}`}>{this.props.message}</div>
            );
        }
        else
        {
            return (
                <React.Fragment></React.Fragment>
            );
        }

    }
}