import * as React from 'react';
import { TableDataProps } from '../Table';
import { TableHeadCell } from '../TableHead';

function capitalize(str: string) {
  if (str !== undefined) {
    const lower = str.toLowerCase();
    return str.charAt(0).toUpperCase() + lower.slice(1);
  }

}
export const getPinnedCols = (row: any, columnConfig: any) => {

  const finalConfig: any = []
  columnConfig.forEach((config: TableHeadCell) => {
    if (config.pinned) {
      if (config.mobileRender !== undefined) {
        finalConfig.push(<div>{config.mobileRender(row[config.id])}</div>);
      }
      else {
        return finalConfig.push(<><div>{capitalize(config.id)}</div><strong>{row[config.id]}</strong></>);
      }
    }
  })
  return finalConfig
};

export const getPinnedColsHeaders = (row: TableDataProps, columnConfig: TableHeadCell[]) => {
  const finalConfig: any = []
  columnConfig.forEach((config: TableHeadCell) => {
    if (config.pinned) {
      finalConfig.push((<strong>{capitalize(config.id)}</strong>));
    }
  })
  return finalConfig

  // const final: any = Object.keys(row)
  //   .filter((rowKey) => {
  //     let exists = false;
  //     columnConfig.forEach((col: TableHeadCell) => {
  //       if (col.id === rowKey) {
  //         exists = true;
  //       }
  //     });
  //     return exists;
  //   }) // Now I have the keys that have a config attached to it.
  //   .filter((key, index) => {
  //     // This is the config for this key
  //     if (columnConfig[index].pinned) {
  //       console.log(columnConfig[index])
  //     }
  //     return columnConfig[index].pinned === true;
  //   })
  //   .map((key, index) => {
  //     console.log(key)
  //     return <strong>{capitalize(key)}</strong>;
  //   });
  // return final;
};

export const getAttributeCols = (row: any, columnConfig: any) => {
  const finalConfig: any = []
  columnConfig.forEach((config: TableHeadCell) => {
    if (config.attribute) {
      if (config.mobileRender !== undefined) {
        finalConfig.push(<><div>{capitalize(config.id)}</div><strong>{config.mobileRender(row[config.id])}</strong></>);
      }
      else {
        return finalConfig.push((<><div>{capitalize(config.id)}</div><strong>{row[config.id]}</strong></>));
      }
    }
  })
  return finalConfig
};

export const getRemainingCols = (row: any, columnConfig: any) => {
  const finalConfig: any = []
  columnConfig.forEach((config: TableHeadCell) => {
    if (!config.pinned && !config.attribute) {
      if (config.mobileRender !== undefined) {
        finalConfig.push(<><div>{capitalize(config.id)}</div><strong>{config.mobileRender(row[config.id])}</strong></>);
      }
      else {
        return finalConfig.push((<><div>{capitalize(config.id)}</div><strong>{row[config.id]}</strong></>));
      }
    }
  })
  return finalConfig
};
