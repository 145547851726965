import React, { Component } from 'react';
import {
TableCell
} from '@ui5/webcomponents-react';

export interface IAdvancedTableCellProps {
    className?: string;
    properties?:any;
}

export class AdvancedTableCell extends Component<IAdvancedTableCellProps> {
    render() {
        return <TableCell className={this.props.className} {...this.props.properties}>
            {this.props.children}
        </TableCell>
    }
}