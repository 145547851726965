import { elementType } from '../Table/types';
import { TableHeadCell } from '../TableHead/types'
import { TableFilterConfig } from '../Table'
export interface MobileTableProps {
  classes?: string;
  component?: elementType;
  style?: any;
  tableData: any;
  columnConfig: TableHeadCell[];
  filterConfig?: TableFilterConfig;
}

export const defaults: MobileTableProps = {
  classes: '',
  component: 'div',
  tableData: [],
  columnConfig: [],
};
