import React, { Fragment, FC, ReactNode, useEffect } from "react";
import { Button, ButtonType } from "../Button";
import { useForm, UseFormMethods } from "react-hook-form";
import { ButtonState } from "../..";

type ValidationTrigger = "onChange" | "onBlur" | "onSubmit" | "onTouched" | "all";
export type FormContainerReset = () => void;

export interface IFormProps {
	id: string;
	submitText?: string;
	onFormSubmit?: (e: any) => void
	action?: (e: any, valid: boolean) => void;
	actionDisabled?: boolean;
	actionIsBusy?: boolean;
	actionIcon?: string;
	secondaryAction?: any;
	secondaryActionText?: string;
	secondaryActionDisabled?: boolean;
	secondaryActionIsBusy?: boolean;
	tertiaryAction?: any;
	tertiaryActionText?: string;
	tertiaryActionDisabled?: boolean;
	tertiaryActionIsBusy?: boolean;
	isValid?: any;
	formValidationTrigger: ValidationTrigger;
	initialValues?: any;
	methods?: UseFormMethods;
	children: ReactNode;
	isPristine?: (e: boolean) => void;
}

let initialFormValeus: any = {};

const hasError = () => {
	let form: any = document.forms[0]?.elements;
	if (form === undefined) {
		return false;
	}
	const data = {};
	const inputArray = [...form];
	inputArray.map((input: any) => {
		let { name, value } = input;
		if (input.type === "checkbox") {
			value = input.checked;
		}
		if (input.type === "radio" && !input.checked) {
			return {};
		}
		const splitName = name.split(".");
		return generateObj(data, splitName, value, input, true);
	});
	/* let errored: boolean = false;
	Object.entries(data).forEach((key, value) => {
		let item = { value };
		if (item.hasOwnProperty("error")) errored = true;
	}); */
};

const getErrorString = () => {
	let form: any = document.forms[0]?.elements;
	if (form === undefined) {
		return false;
	}
	const data = {};
	const inputArray = [...form];
	inputArray.map((input: any) => {
		let { name, value } = input;
		if (input.type === "checkbox") {
			value = input.checked;
		}
		if (input.type === "radio" && !input.checked) {
			return {};
		}
		const splitName = name.split(".");
		return generateObj(data, splitName, value, input, true);
	});
	let errorString: string = "";
	Object.entries(data).forEach(([key, value]) => {
		let item = { value };
		if (item.hasOwnProperty("error")) {
			errorString = "The form cannot be submitted.";
		}
	});
	return errorString;
};

const displayError = () => {
	if (hasError()) {
		return true;
	}
	return false;
};

const generateObj = (obj: any, arr: any, val: any, input: any, includeErrors: boolean) => {
	val = val === undefined || val === "" ? input.value : val;
	if (arr.length === 1) {
		if (includeErrors) {
			if (input.required && (val === undefined || val === "")) {
				obj[arr[0]] = {
					value: val,
					error: `Please enter a value for ${input.label}`,
				};
			} else if (input.validation) {
				let error = input.validation(input.name, val);
				obj[arr[0]] = { value: val, error: error };
			} else {
				obj[arr[0]] = { value: val };
			}
		} else {
			obj[arr[0]] = val;
		}
		return;
	}
	if (!obj[arr[0]]) {
		obj[arr[0]] = {};
	}
	const restArr = arr.splice(1);
	generateObj(obj[arr[0]], restArr, val, input, includeErrors);
};

const mapChildren = (children: any, props: any, initialValues: any) => {
	let clonedChildren: any = React.Children.map(children, (child: any) => {
		let parent: any;
		if (initialValues && child?.props?.value === undefined && child?.props?.id !== undefined && initialValues[child?.props?.id] !== undefined) {
			let tempProps = props;
			tempProps.value = initialValues[child?.props?.id];
			parent = React.cloneElement(child, tempProps, child.props.children);
			tempProps.value = undefined;
		} else {
			if (child && child.props) parent = React.cloneElement(child, props, child.props.children);
			else parent = child;
		}

		if (parent?.props?.children !== undefined) {
			let parentalChildren = mapChildren(parent.props.children, props, initialValues);

			parent = React.cloneElement(parent, props, parentalChildren);
		}
		return parent;
	});
	return clonedChildren;
};

const haveChanged = (oldArray: any, newArray: any) => {
	if (JSON.stringify(oldArray) === JSON.stringify(newArray)) {
		return true;
	} else {
		return false;
	}
};

export const FormContainer: React.FC<IFormProps> = (props) => {
	let methods = useForm({
		mode: props.formValidationTrigger
	});

	useEffect(() => {
		initialFormValeus = methods.getValues();
	}, []);

	useEffect(() => {
		if (props.isPristine) {
			let isPristine = haveChanged(initialFormValeus, methods.watch());
			props.isPristine(isPristine);
		}
	});

	if (props.methods) {
		methods = props.methods;
	}

	const handleSubmit = (action: any, isValid: boolean) => {
		let form: any = document.getElementById(props.id);
		const data = {};
		const inputArray = [...form];
		inputArray.map((input: any) => {
			let { name, value } = input;
			if (input.type === "checkbox") {
				value = input.checked;
			}
			if (input.type === "radio" && !input.checked) {
				return {};
			}
			if (name !== "") {
				const splitName = name.split(".");
				return generateObj(data, splitName, value, input, false);
			}
			return {};
		});
		var valid: boolean = !hasError();
		if (valid) {
			action(data, isValid);
		}
	};

	const onSubmit = (data: any) => {
		let values = methods.getValues();
		props.isValid && props.isValid(data);
		handleSubmit(props.action, data);
	};

	const childrenWithProps = mapChildren(
		props.children,
		{
			register: methods.register,
			errors: methods.errors,
			setValue: methods.setValue,
			control: methods.control,
		},
		props.initialValues
	);

	return (
		<Fragment>
			<form id={props.id} onSubmit={props.onFormSubmit}>
				<div className="container">{childrenWithProps}</div>
				{displayError() && (
					<div className={`alert alert-danger alert-dismissible fade ${displayError() ? "show" : "hide"}`}>
						<strong>Error!</strong> {getErrorString()}
						<button type="button" className="close" data-dismiss="alert">
							&times;
						</button>
					</div>
				)}
				<div className="form-btn-actions">
					<div className="btn-padded">
						{props.action && (
							<Button
								icon={props.actionIcon}
								state={props.actionDisabled ? ButtonState.Disabled : ButtonState.Enabled}
								trigger={methods.trigger}
								type={ButtonType.Primary}
								onClick={(e: any) => {
									onSubmit(e);
								}}
								isBusy={props.actionIsBusy}
								text={props.submitText || "Submit"}
							/>
						)}
						{props.secondaryAction && (
							<Button
								state={props.secondaryActionDisabled ? ButtonState.Disabled : ButtonState.Enabled}
								isBusy={props.secondaryActionIsBusy}
								type={ButtonType.Secondary | ButtonType.Ghost}
								onClick={(e: any) => {
									props.secondaryAction && props.secondaryAction(e);
								}}
								text={props.secondaryActionText || "Cancel"}
							/>
						)}
					</div>
					{props.tertiaryAction && (
						<div className="form-tertiary-action">
							<Button
								state={props.tertiaryActionDisabled ? ButtonState.Disabled : ButtonState.Enabled}
								type={ButtonType.TextNaked}
								isBusy={props.tertiaryActionIsBusy}
								onClick={(e: any) => {
									props.tertiaryAction && props.tertiaryAction(e);
								}}
								text={props.tertiaryActionText || "Delete"}
							/>
						</div>
					)}
				</div>
			</form>
		</Fragment>
	);
};
