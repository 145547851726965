import React, { Component } from 'react';
import {
TableRow
} from '@ui5/webcomponents-react';

export class AdvancedTableRow extends Component {
    render() {
        return <TableRow>
            {this.props.children}
        </TableRow>
    }
}