import * as React from "react";
import { observable } from "mobx";
import { StatusResponse } from "../api/ContactTracingApi";

export interface CovidNavInterface {
	activePage: string;
	defaultPage: string;
	pages: NavItem[];
	getPage: (url: string) => NavItem;
	features: any;
	texts: any;
	contactTracingStatus: StatusResponse[]
}

export interface NavItem {
	url: string;
	title: string;
	icon: string;
	visible: boolean;
	showReasonCol: boolean;
	renderAction: boolean;
	showManifestButtons: boolean;
	showUploadButton: boolean;
	listName: "followUpList" | "vaccineHoldList" | "scheduleList" | "completedList" | "fluShotList" | "contact-tracing" ;
	showDatePicker?: boolean;
}

export class CovidNav {
	defaultPage: string = "follow-up";
	@observable activePage: string = this.defaultPage;
	@observable features: any;
	@observable texts: any;
	@observable contactTracingStatus: StatusResponse[] = [];
	@observable globalTableIsLoading: boolean;
	@observable currentDateString: string;

	pages: NavItem[] = [
		{
			url: "contact-tracing",
			title: "Active Cases",
			icon: "glyphicons-basic-28-search-white.svg",
			showReasonCol: false,
			listName: "contact-tracing",
			showManifestButtons: false,
			showUploadButton: false,
			renderAction: false,
			visible: true,
		},
		{
			url: "search",
			title: "Search",
			icon: "glyphicons-basic-28-search-white.svg",
			showReasonCol: false,
			listName: "followUpList",
			showManifestButtons: false,
			showUploadButton: false,
			renderAction: false,
			visible: true,
		},
		{
			url: "follow-up",
			title: "Follow-up Required",
			icon: "glyphicons-basic-44-user-group.svg",
			showReasonCol: true,
			listName: "followUpList",
			showManifestButtons: false,
			showUploadButton: false,
			renderAction: true,
			visible: true,
		},
		{
			url: "vaccine-hold",
			title: "Vaccine Hold",
			icon: "glyphicons-basic-44-user-group.svg",
			showReasonCol: true,
			listName: "vaccineHoldList",
			showManifestButtons: false,
			showUploadButton: false,
			renderAction: true,
			visible: true,
		},
		{
			url: "pending-tests",
			title: "Pending Tests",
			icon: "glyphicons-basic-487-exchange.svg",
			showReasonCol: false,
			listName: "scheduleList",
			showManifestButtons: false,
			showUploadButton: false,
			renderAction: true,
			visible: false,
		},
		{
			url: "completed-tests",
			title: "Completed Tests",
			icon: "glyphicons-basic-634-circle-check.svg",
			showReasonCol: false,
			listName: "completedList",
			showManifestButtons: true,
			showUploadButton: false,
			showDatePicker: true,
			renderAction: false,
			visible: true,
		},
		{
			url: "flushots",
			title: "Flu Shots",
			icon: "glyphicons-basic-634-circle-check.svg",
			showReasonCol: false,
			listName: "fluShotList",
			showManifestButtons: false,
			showUploadButton: true,
			showDatePicker: true,
			renderAction: false,
			visible: true,
		},
	];

	getPage(url: string): NavItem {
		return this.pages.filter((p: NavItem) => p.url === url)[0];
	}
}

export const covidNav = new CovidNav();
export default covidNav;
