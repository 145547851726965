import * as React from "react";
import { FC, useState, useEffect } from "react";
import { Alert } from "react-bootstrap";
import { ActionList, CheckboxInputGroup, Error, ErrorContext, ErrorType, FormContainer, Text, InputBoxOption, SkeletonLoader, Button, ButtonType, ButtonState, Textbox, TextArea, AdvancedTable, IAdvancedTableColumn } from "@mit/hui";
import medicalApi, { ApiPersonDetail, ExemptFlagModel, PostStatusResponse, VaccineExemptionDTO } from "../../api/MedicalApi";
import { PageError } from "../../types/PageErrors";
import { CONTACT_SUPPORT_LINK } from "../../common/Defaults";
import moment from "moment";
import peopleController from "../../api/PeopleController";
import { inject, observer } from "mobx-react";
import { HoldsModelInterface } from "../../model/Holds";
import { VaccineExemptionRequestDrawer } from "./VaccineExemptionRequestDrawer";
import { ExtendedPerson } from "../../api/CovidPersonApi";
import { PersonProfileInterface } from "../../api/UserController";
import { SearchUserInterface } from "../../model/SearchUser";
import { data } from "jquery";

interface VaccineExemptionComponentProps {
	mitId: string;
	vaccine_status?: string;
	vaccine_hold_status?: string;
	holds?: HoldsModelInterface;
}

const VaccineExemptionComponent: FC<VaccineExemptionComponentProps> = ({ mitId, vaccine_status, vaccine_hold_status, holds }) => {
	const [isLoading, setLoading] = useState(false);
	const [isBusy, setIsBusy] = useState(false);
	const [isBusyMoreInfo, setIsBusyMoreInfo] = useState(false);
	const [isBusyDownload, setIsBusyDownload] = useState(false);
	const [isLoadingPersonDetails, setIsLoadingPersonDetails] = useState(false);
	const [unAuth, setUnAuth] = useState(false);
	const [isError, setError] = useState(false);
	const [showExemptionRequest, setShowExemptionRequest] = useState(false);
	// const [exemptionFlags, setExemptionFlags] = useState<ExemptFlagModel>();
	const [pageError, setPageError] = useState<PageError>({ error: false, errorText: "" });
	const [pdfDownloadError, setPDFDownloadError] = useState<string>("");

	const [hrFlag, setHrFlags] = useState<boolean>(false);
	const [medicalFlag, setMedicalFlags] = useState<boolean>(false);
	const [internationalFlag, setInternationalFlags] = useState<boolean>(false);
	const [tempFlag, setTempFlags] = useState<boolean>(false);
	const [remoteFlag, setRemoteFlags] = useState<boolean>(false);

	const [exemptions, setExemptions] = useState<VaccineExemptionDTO[]>();
	const [exemptReason, setExemptReason] = useState("");
	const [exemptionId, setExemptionId] = useState("");
	const [exemptionUrl, setExemptionURL] = useState("");
	const [exemptionData, setExemptionData] = useState<VaccineExemptionDTO>();

	const [busy, setBusy] = useState(false);
	const [personDetails, setPersonDetails] = useState<ApiPersonDetail>();

	const [requestText, setRequestText] = useState<string>("");

  const [reFetchPersonExemption, setReFetchPersonExemption] = useState(false)

	useEffect(() => {
		fetchPersonExemption();
		fetchPersonVaccineExemptions();
		getPersonalDetails();
	}, [mitId]);

  // Added to refetch the data after an obo exemption was created
  useEffect(()=> {
    if (reFetchPersonExemption) {
      console.log('Executing fetchPersonExemption()')
      fetchPersonExemption()
      setReFetchPersonExemption(false)
    }
  }, [reFetchPersonExemption])

	const fetchPersonExemption = async () => {
		setBusy(true);
		setIsBusyDownload(true);
		medicalApi
			.fetchVaccineExemption(mitId)
			.then((data) => {
				if (data.items && data.items?.length > 0) {
					setExemptReason(data.items[0]?.reason ?? "");
					setExemptionId(data.items[0]?.id);
					setExemptionURL(data.items[0]?.imageUrl ?? "");
					setExemptionData(data.items[0]);
          setExemptions(data.items.sort((a, b) => (new Date(b.submissionDate).getTime()) - (new Date(a.submissionDate)).getTime()))
				} else {
					setExemptReason("");
				}
				setBusy(false);
			})
			.catch((err) => {
				//TODO
			})
			.finally(() => {
				setIsBusyDownload(false);
				setBusy(false);
			});
	};

	const fetchPersonVaccineExemptions = (silent = false) => {
		if (!silent) setLoading(true);

		medicalApi.fetchPersonVaccineExemption(mitId).then((response) => {
			// endpoint -> /vaccine/hold/exemptions/flags
			if (response.error) {
			} else if (response.unauth) {
				setUnAuth(true);
			} else {
				if (response) {
					setLoading(false);
					setMedicalFlags(response.flags?.medical ?? false);
					setHrFlags(response.flags?.hr ?? false);
					setInternationalFlags(response.flags?.international ?? false);
					setTempFlags(response.flags?.temp ?? false);
					setRemoteFlags(response.flags?.remote ?? false);
				}
			}
			setLoading(false);
		});
	};

	const getPersonalDetails = () => {
		if (mitId) {
			setIsLoadingPersonDetails(true);
			peopleController
				.fetchPersonAws(mitId)
				.then((response) => {
					if (response.detail) {
						setPersonDetails(response.detail);
					}
				})
				.finally(() => {
					setIsLoadingPersonDetails(false);
				});
		}
	};

	//Remove request more info from this page

	// const submitRequestMoreInfo = () => {
	// 	if (exemptionId) {
	// 		setIsBusyMoreInfo(true);
	// 		medicalApi
	// 			.putExemptionApproval(exemptionId, "M", requestText)
	// 			.then((response) => {
	// 				fetchPersonVaccineExemptions(true);
	// 				getPersonalDetails();
	// 			})
	// 			.catch(() => {
	// 				//TODO
	// 			})
	// 			.finally(() => {
	// 				setIsBusyMoreInfo(false);
	// 			});
	// 	}
	// };

	// const submit = () => {
	// 	const body: ExemptFlagModel = {
	// 		hr: hrFlag,
	// 		international: internationalFlag,
	// 		medical: medicalFlag,
	// 		temp: tempFlag,
	// 		remote: remoteFlag,
	// 	};

	// 	setIsBusy(true);
	// 	medicalApi
	// 		.putPersonVaccineExemption(mitId, body)
	// 		.then((response: PostStatusResponse) => {
	// 			if (response.success) {
	// 				medicalApi.fetchPersonVaccineExemption(mitId).then((response) => {
	// 					if (response) {
	// 						fetchPersonVaccineExemptions(true);
	// 						getPersonalDetails();
	// 					}
	// 				});
	// 			} else {
	// 				setPageError({ error: true, errorText: `An error occurred and the person could not be processed. If the problem persists, please contact ${CONTACT_SUPPORT_LINK}.` });
	// 			}
	// 		})
	// 		.catch(() => {
	// 			setPageError({ error: true, errorText: `An error occurred and the person could not be processed. If the problem persists, please contact ${CONTACT_SUPPORT_LINK}.` });
	// 		})
	// 		.finally(() => {
	// 			setIsBusy(false);
	// 		});
	// };

	const selectExemption = (data: any) => {
		setPageError({ error: false, errorText: "" });
		setMedicalFlags(data === "medical_exemption" ? true : false);
		setHrFlags(data === "hr_exemption" ? true : false);
		setInternationalFlags(data === "international_exemption" ? true : false);
		setTempFlags(data === "temp_exemption" ? true : false);
		setRemoteFlags(data === "remote_exemption" ? true : false);
	};

	const downloadFile = (data: any, filename: any, mime: any) => {
		// It is necessary to create a new blob object with mime-type explicitly set
		// otherwise only Chrome works like it should
		const blob = new Blob([data], { type: mime || "application/octet-stream" });
		//@ts-ignore
		if (typeof window.navigator.msSaveBlob !== "undefined") {
			// IE doesn't allow using a blob object directly as link href.
			// Workaround for "HTML7007: One or more blob URLs were
			// revoked by closing the blob for which they were created.
			// These URLs will no longer resolve as the data backing
			// the URL has been freed."
			//@ts-ignore
			window.navigator.msSaveBlob(blob, filename);
			return;
		}
		// Other browsers
		// Create a link pointing to the ObjectURL containing the blob
		const blobURL = window.URL.createObjectURL(blob);
		const tempLink = document.createElement("a");
		tempLink.style.display = "none";
		tempLink.href = blobURL;
		tempLink.setAttribute("download", filename);
		// Safari thinks _blank anchor are pop ups. We only want to set _blank
		// target if the browser does not support the HTML5 download attribute.
		// This allows you to download files in desktop safari if pop up blocking
		// is enabled.
		if (typeof tempLink.download === "undefined") {
			tempLink.setAttribute("target", "_blank");
		}
		document.body.appendChild(tempLink);
		tempLink.click();
		document.body.removeChild(tempLink);
		setTimeout(() => {
			// For Firefox it is necessary to delay revoking the ObjectURL
			window.URL.revokeObjectURL(blobURL);
		}, 100);
	};

	const downloadPDF = () => {
		if (exemptionId) {
			setIsBusyDownload(true);
			medicalApi
				.fetchVaccineExemptionPDF(exemptionId)
				.then((resp) => {
					downloadFile(resp, `exemption_${moment().format("YYYY-MM-DD")}.pdf`, "application/pdf");
				})
				.catch((err) => {
					setPDFDownloadError(err);
				})
				.finally(() => setIsBusyDownload(false));
		}
	};

	const getVaccineStatusText = () => {
		switch (personDetails?.vaccine_status ?? vaccine_status) {
			case "full":
				return "Fully Vaccinated";
			case "partial":
				return "Partially Vaccinated";
			case "exempt":
				return "Exempt";
			case "none":
				return "Not Vaccinated";
			case "No Status":
				return "Unknown Vaccination Status";
		}
	};

	if (isError) return <Error context={ErrorContext.Component} message="Oops.. something went wrong" type={ErrorType.Generic} />;

	if (unAuth) return <Error context={ErrorContext.Component} message="Not Authorized" type={ErrorType.AccessDenied} />;

	const items: InputBoxOption[] = [
		{
			title: "Temporary Exemption",
			selected: tempFlag,
			value: tempFlag,
			name: "temp_exemption",
			required: false,
			showInput: false,
		},
		{
			title: "Medical Exemption",
			selected: medicalFlag,
			value: medicalFlag,
			name: "medical_exemption",
			required: false,
			showInput: false,
		},
		{
			title: "HR Exemption",
			selected: hrFlag,
			value: hrFlag,
			name: "hr_exemption",
			required: false,
			showInput: false,
		},
		{
			title: "International Exemption",
			selected: internationalFlag,
			value: internationalFlag,
			name: "international_exemption",
			required: false,
			showInput: false,
		},
	];



	const columns: Array<IAdvancedTableColumn> = [
		{
			id: "typeName",
			name: "Type",
			sortable: false,
			options: {
				demandPopin: true,
				// minWidth: 300,
				popinText: "Type",
			},
			filterable: false,
		},
		{
			id: "exemptFrom",
			name: "From",
			sortable: false,
			options: {
				demandPopin: true,
				// minWidth: 200,
				popinText: "From",
			},
			filterable: false,
		},
		// {
		// 	id: "reason",
		// 	name: "Reason",
		// 	sortable: false,
		// 	options: {
		// 		demandPopin: true,
		// 		// minWidth: 500,
		// 		popinText: "Reason",
		// 	},
		// 	filterable: false,
		// },
		// {
		// 	id: "feedback",
		// 	name: "Feedback",
		// 	sortable: false,
		// 	options: {
		// 		demandPopin: true,
		// 		// minWidth: 500,
		// 		popinText: "Feedback",
		// 	},
		// 	filterable: false,
		// },
		{
			id: "expiryDate",
			name: "Expiry Date",
			sortable: false,
			options: {
				demandPopin: true,
				// minWidth: 500,
				popinText: "Expiry Date",
			},
			filterable: false,
		},
		{
			id: "statusText",
			name: "Status",
			sortable: false,
			options: {
				demandPopin: true,
				// minWidth: 300,
				popinText: "Status",
			},
			filterable: false,
		},
	];

	return (
		<>
      <h4 className="medical-holds profile-table-headers">
        Exemptions&nbsp;&nbsp;&nbsp;
        <Button type={ButtonType.TextNaked} text="Create Exemption" icon="file-excel" onClick={() => setShowExemptionRequest(true)} />
      </h4>
      {exemptions &&
			<div className="row">
        <div className="col-12">
          <AdvancedTable 
            columns={columns} 
            items={exemptions!.map(exemption => {
              return {
                typeName: exemption.typeName,
                exemptFrom: exemption.exemptFrom,
                // reason: exemption.reason,
                // feedback: exemption.feedback,
                expiryDate: exemption.expiryDate ? exemption.expiryDate.substring(0,10) : '',
                statusText: exemption.statusText,
                imageUrl: exemption.imageUrl
              }
            })} 
            isLoading={isLoading} 
            actionButtons={[
                {
                    icon: 'file-alt',
                    onClick:(exemption: VaccineExemptionDTO) => window.open(exemption.imageUrl),
                    padded: false,
                    shouldDisplay: (exemption: VaccineExemptionDTO) => {return exemption.imageUrl ? true : false},
                    state: 0,
                    text: '',
                    type: 64
                },
            ]}
            sortStateOptions={{
              enabled:true,
              key: 'covid-medical-person-holds'
            }}
          />
        </div>
      </div>
      }
          {/* <div className="row p-3">
            <Button type={ButtonType.Ghost | ButtonType.Secondary} text="Create Exemption" icon="file-excel" onClick={() => setShowExemptionRequest(true)} />
          </div> */}
			<div className="row py-3">
				<div className="col-6">
					<span className="py-1" style={{ fontWeight: 600 }}>
						Vaccine Status
					</span>
					<div>{isLoadingPersonDetails ? <Textbox isLoading name="loading" /> : <Text content={getVaccineStatusText()} bold type="h4" />}</div>
					<span className="py-1" style={{ fontWeight: 600 }}>
						Vaccine Hold Status
					</span>
					<div>{isLoadingPersonDetails ? <Textbox isLoading name="loading" /> : <Text content={personDetails?.vaccine_hold_status ?? vaccine_hold_status} bold type="h5" />}</div>
					{/* <span className="py-1" style={{ fontWeight: 600 }}>
						Vaccine Exemption
					</span> */}
					<div>
						{isLoading ? (
							<ActionList isLoading items={[]} />
						) : (
							<>
								{pageError.error && <Alert variant={"danger"}>{pageError.errorText}</Alert>}
								{/* <CheckboxInputGroup onChange={(e) => selectExemption(e)} name="vaccine-exemption" group="exemptionSelect" groupOptions={items} label="Vaccine Exemption" unselected={true} /> */}
								<div className="radio-group">
									{/* <Button type={ButtonType.Ghost | ButtonType.Secondary} text="Create Exemption" icon="file-excel" onClick={() => setShowExemptionRequest(true)} /> */}
									{/* {items.map((item: InputBoxOption) => {
										return (
											<div className="form-check">
												<input
													className="form-check-input"
													type="radio"
													defaultChecked={item.selected ?? false}
													checked={item.selected ?? false}
													radioGroup={"vaccine"}
													id={item.name}
													name={"vaccine-exemption"}
													onClick={() => selectExemption(item.name)}
													value={item.value}
												/>
												<label htmlFor={item.name} className="form-check-label">
													<b>{item.title}</b>
												</label>
												<br />
											</div>
										);
									})} */}
									{/* <div className="form-check">
										<input
											className="form-check-input"
											type="radio"
											defaultChecked={!hrFlag && !medicalFlag && !internationalFlag && !remoteFlag && !tempFlag}
											checked={!hrFlag && !medicalFlag && !internationalFlag && !remoteFlag && !tempFlag}
											radioGroup={"vaccine"}
											id={"none_exemption"}
											name={"vaccine-exemption"}
											onClick={() => selectExemption("")}
										/>
										<label htmlFor={"none_exemption"} className="form-check-label">
											<b>None</b>
										</label>
										<br />
									</div> */}
								</div>
							</>
						)}
					</div>
				</div>
				<div className="col-6">
					{/* <div>
						{busy ? (
							<SkeletonLoader type="list" />
						) : (
							<>
								{exemptionData?.feedback && (
									<div className="py-1">
										<span style={{ fontWeight: 600 }}>Feedback</span>
										<div>
											<Text content={exemptionData.feedback} />
										</div>
									</div>
								)}
								<span className="py-1" style={{ fontWeight: 600 }}>
									Exemption Submission
								</span>
								{exemptionData?.status === "M" && <Alert variant={"warning"}>Additional info requested</Alert>}
								<div>{exemptReason ? <Text content={exemptReason} /> : <Text content="None" type="small" />}</div>
							</>
						)}
					</div> */}
					{/* {medicalFlag && exemptionUrl && (
						<div>
							<span className="py-1" style={{ fontWeight: 600 }}>
								Supporting Document
							</span>
							<div className="py-1">
								<Button
									icon="file-alt"
									type={ButtonType.Ghost | ButtonType.Secondary}
									text="Download"
									isBusy={isBusyDownload}
									state={isBusyDownload ? ButtonState.Disabled : ButtonState.Enabled}
									onClick={() => window.open(exemptionUrl)}
								/>
								<div>{pdfDownloadError && <Text type="small" content={pdfDownloadError} color="red" />}</div>
							</div>
						</div>
					)} */}
				</div>
			</div>
			{/* Remove request more info from this page */}
			{/* <div className="px-3">
				<TextArea name="moreInfo" placeholderText="Type your request here" rows={3} onChange={(e) => setRequestText(e.currentTarget.value)} />
			</div> */}
			<div className={"d-flex justify-content-center pt-2"}>
				<div className="btn-padded">
					{/* <Button isBusy={isBusy} state={isBusy ? ButtonState.Disabled : ButtonState.Enabled} text="Submit" onClick={submit} /> */}
					{/* Remove request more info from this page */}
					{/* <Button
						isBusy={isBusyMoreInfo}
						state={requestText.length > 0 ? ButtonState.Enabled : ButtonState.Disabled}
						text="Request more info"
						type={ButtonType.Ghost | ButtonType.Secondary}
						outlined
						onClick={() => submitRequestMoreInfo()}
					/> */}
				</div>
			</div>
      {showExemptionRequest &&
        <VaccineExemptionRequestDrawer
          setReFetchPersonExemption={setReFetchPersonExemption}
          show={showExemptionRequest}
          onClose={() => setShowExemptionRequest(false)}
          done={() => {
            setShowExemptionRequest(false);
          }}
          personDetails={personDetails}
        />
      }
		</>
	);
};
const VaccineExemption = inject("searchUser", "covidNav", "holds", "roles")(observer(VaccineExemptionComponent));
export default VaccineExemption;
