import React from 'react';

export type ColorScheme = "Blue" | "Green" | "Pink";

export interface StepperProps
{
    children: React.ReactNode;
    colorScheme: ColorScheme
}

export const getStepsScheme = (color: ColorScheme) => {
    switch(color) {
        case "Blue":
            return "theme-blue";
        case "Green": 
            return "theme-green";
        case "Pink":
        default:
            return "theme-pink";
    }
}

export default class Stepper extends React.Component<StepperProps> {
    render(): React.ReactNode
    {
        return (
            <div className={`stepwizard ${getStepsScheme(this.props.colorScheme)}`}>
                <div className="stepwizard-row setup-panel">
                    {this.props.children}
                </div>
            </div>
        );
    }
}