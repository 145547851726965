import React from 'react';

export interface FourColumnsProps
{
    oneArea: any,
    oneAlignment: string,
    twoArea: any,
    twoAlignment: string,
    threeArea: any,
    threeAlignment: string,
    fourArea: any,
    fourAlignment: string,
    showDivider: boolean,
    showGutters: boolean,
    padded: boolean
}
 
export default class TemplateFourColumns extends React.Component<FourColumnsProps>
{
    render(): React.ReactNode
    {
        return (
            <React.Fragment>

                <div className={`row ${(!this.props.showGutters) && 'no-gutters'}`}>
                    <div className={`col-lg-3 text-${this.props.oneAlignment} ${(this.props.showDivider) && 'divider'} ${(this.props.padded) && 'p-3'}`}>
                        {
                            this.props.oneArea
                        }
                    </div>
                    <div className={`col-lg-3 text-${this.props.twoAlignment} ${(this.props.showDivider) && 'divider'} ${(this.props.padded) && 'p-3'}`}>
                        {
                            this.props.twoArea
                        }
                    </div>
                    <div className={`col-lg-3 text-${this.props.threeAlignment} ${(this.props.showDivider) && 'divider'} ${(this.props.padded) && 'p-3'}`}>
                        {
                            this.props.threeArea
                        }
                    </div>
                    <div className={`col-lg-3 text-${this.props.fourAlignment} ${(this.props.padded) && 'p-3'}`}>
                        {
                            this.props.fourArea
                        }
                    </div>
                </div>

            </React.Fragment>
        );
    }
}