import * as React from 'react'
import {FunctionComponent, useState} from "react";
import * as Loader from 'react-loader'
import {mediumLoaderOptions} from "../../common/Defaults";
import * as moment from 'moment'
import { ButtonBar, ButtonBarType, Drawer, TextType, Text, DrawerType, ButtonType, ButtonState, DrawerSize, CalendarInput } from '@mit/hui';
import medicalApi, { DownloadFileResp } from '../../api/MedicalApi';


const DownloadBetweenDates: FunctionComponent<any> = ({hideModalCallback, show, onClose}) => {

    const [isLoading, setIsLoading] = useState(false)
    const [startDate, setStartDate] = useState<moment.Moment>(moment())
    const [endDate, setEndDate] = useState<moment.Moment>(moment())

    const handleClose = () => {
        if (hideModalCallback) 
            hideModalCallback();
        if (onClose) 
            onClose();
    }

    const submitAndClose = async () => {
        setIsLoading(true)
        let resp = await handleFileDownload(startDate, endDate)
        setIsLoading(false)
        handleClose()
    }

    const changeStartDate = (newValue: any) => {
        setStartDate(moment(newValue.startDate))
    }

    const changeEndDate = (newValue: any) => {
        setEndDate(moment(newValue.endDate))
    }

    const changeDateRange = (newValue: any) => {
        if (newValue.from !== undefined) {
            let fromDate = new Date(newValue.from.year, newValue.from.month - 1, newValue.from.day);
            setStartDate(moment(fromDate));
        }

        if (newValue.to !== undefined) {
            let toDate = new Date(newValue.to.year, newValue.to.month - 1, newValue.to.day);
            setEndDate(moment(toDate));
        }
    }

    const handleFileDownload = async (from?: moment.Moment, to?: moment.Moment) => {
        const fileDataResp: DownloadFileResp = await medicalApi.downloadManifest(from, to)
        setIsLoading(true)
    
        if(!fileDataResp.error) {
            let stringData: string = fileDataResp.data ? fileDataResp.data : ''
            const element = document.createElement("a");
            const file = new Blob([stringData], {type: 'text/csv'})
            element.href = URL.createObjectURL(file);
            element.download = `completed-tests.csv`;
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
            setIsLoading(false)
            handleClose()
        } else {
            alert('An error occurred and the file could not be downloaded.')
            setIsLoading(false)
        }
    }

    const toDate = new Date();
    let fromDate = new Date();
    fromDate.setDate(fromDate.getDate() -7);

    return (
        <>
            <Drawer
                contentAriaLabel={`Download Between Dates`} 
                footer={[]}
                show={show}
                onClose={onClose}
                header={<Text content="Download Between Dates" type={TextType.Heading3} icon="" />}
                size={DrawerSize.Medium}
                type={DrawerType.Right}
                themedColor={"medical"}
                contents={
                    <>
                        <Loader loaded={!isLoading} options={{...mediumLoaderOptions, color: '#000000'}}>
                            <div className="text-center">
                                <CalendarInput
                                    id="downloadRange"
                                    isRangeSelected={true}
                                    onChange={changeDateRange}
                                    placeholder="Select a date range"
                                    value={
                                        {
                                            from: {day: fromDate.getDate(), month: fromDate.getMonth() + 1, year: fromDate.getFullYear()}, 
                                            to: {day: toDate.getDate(), month: toDate.getMonth() + 1, year: toDate.getFullYear()}
                                        }
                                    }
                                />
                            </div>
                        </Loader>
                        <br/>
                        <ButtonBar type={ButtonBarType.Padded}  buttons={[
                            {
                                icon: '',
                                text: "Download",
                                state: isLoading? ButtonState.Disabled : ButtonState.Enabled,
                                type: ButtonType.Primary,
                                onClick: submitAndClose,
                            },
                            // {
                            //     icon: '',
                            //     text: "Download All",
                            //     state: isLoading? ButtonState.Disabled : ButtonState.Enabled,
                            //     type: ButtonType.Primary,
                            //     onClick: handleFileDownload,
                            // },
                            {
                                icon: '',
                                text: "Cancel",
                                state: isLoading? ButtonState.Disabled : ButtonState.Enabled,
                                type: ButtonType.Secondary | ButtonType.Ghost,
                                onClick: handleClose,
                            }
                        ]} />
                    </>
                }
            />
        </>
    )
}

export default DownloadBetweenDates