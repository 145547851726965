import * as React from 'react'
import {Dropdown} from "react-bootstrap";
import { DropdownItemProps} from '@mit/hui';
import {FC} from "react";
import {inject, observer} from "mobx-react";
import {HoldsModelInterface} from "../../model/Holds";
import {HoldModel} from "../../api/MedicalApi";

interface HoldStatusButtonProps {
    person: HoldModel;
    holds?: HoldsModelInterface;
    holdGuid: string;
    currentStatus?: string;
}

const HoldStatusButtonComponent: FC<HoldStatusButtonProps> = ({person, holds, holdGuid, currentStatus}) => {
    const handleStatusChange = (newStatus: any) => {
        holds!.showUpdateStatusModal = true
        holds!.newStatus = newStatus
        holds!.guidToUpdate = holdGuid
        holds!.personName = person.display_name
        holds!.reason = person.reason
    }

    const getDropDownItems = () => {
        let dropdownData: DropdownItemProps[] = [];

        if (holds && holds.statuses) {
            holds.statuses.map((status: any) => {
                dropdownData.push({text: status.text, icon: "", id: status.text});
            })
        }

        return dropdownData;
    }

    //return <Dropdown dropdownItems={getDropDownItems()} name="dropdownStatus" text={currentStatus} value={currentStatus} onChange={(value: string) => handleStatusChange(value)} />

    return <Dropdown>
        <Dropdown.Toggle variant={'outline-dark'} id="dropdown-basic">
            {currentStatus}
        </Dropdown.Toggle>

        <Dropdown.Menu>
            {holds && holds.statuses && holds.statuses.map((status: any) => (<Dropdown.Item key={status.id} className={'table-action-button'} href="#" onClick={() => (handleStatusChange(status))} >
                {status.text}
            </Dropdown.Item>))}
        </Dropdown.Menu>
    </Dropdown>
}

const HoldStatusButton = inject('holds')(observer(HoldStatusButtonComponent))
export default HoldStatusButton
