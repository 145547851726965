import { CategoryCard, ICategoryCardProps } from "./CategoryCard";
import React, { Fragment } from "react";

export interface ICategoryCardListProps {
	items: ICategoryCardProps[];
	addWrapper?: boolean;
	isLoading?: boolean;
}

export const CategoryCardList: React.FC<ICategoryCardListProps> = ({ items, addWrapper, isLoading }) => {
	if (isLoading) {
		return (
			<div aria-busy={isLoading ?? false} className="category-cards skeleton-loader">
				<div className="category-card card"></div>
				<div className="category-card card"></div>
				<div className="category-card card"></div>
				<div className="category-card card"></div>
			</div>
		);
	}

	return (
		<Fragment>
			{(addWrapper || addWrapper === undefined) && (
				<div aria-busy={isLoading ?? false} className="category-cards">{items !== undefined && items!.map((item: ICategoryCardProps, number: number) => <CategoryCard key={number} {...item} />)}</div>
			)}
			{!addWrapper && addWrapper !== undefined && items !== undefined && items!.map((item: ICategoryCardProps, number: number) => <CategoryCard key={number} {...item} />)}
		</Fragment>
	);
};
