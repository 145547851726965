import * as React from "react";
import { FC, useState, useEffect } from "react";
import { peopleStore } from "../../model/PeopleStore";
import medicalApi, { FluShotDetailResponse } from "../../api/MedicalApi";
import { inject, observer, Provider } from "mobx-react";
import { CovidNav } from "../../model/CovidNav";
import { Button, ButtonType, CalendarInput, Error, ErrorContext, ErrorType, ItemAlignment, NavBar, NavBarTypes, NavItemProps, NavItemState, Textbox, Toggle } from "@mit/hui";
import { VaccineAdministered } from "./VaccineAdministered";
import { RolesInterface } from "../../model/Roles";
import EligibleUpload from "./EligibleUpload";
import VaccineUpload from "./VaccineUpload";
import { Fragment } from "react";
import VaccinationReview from "./VaccinationReviews"
import VaccineHold from "../vaccineHold/VaccineHold"
import VaccineExempt from "../vaccineExemptions/VaccineExempt"
import VaccineRequests from "../vaccineRequests/VaccineRequests";

interface CompletedTestsComponentProps {
	covidNav?: CovidNav;
	roles?: RolesInterface;
}

const VaccineComponent: FC<CompletedTestsComponentProps> = ({ covidNav, roles }) => {
	const [isLoading, setLoading] = useState(false);
	const [unAuth, setUnAuth] = useState(false);
	const [error, setError] = useState(false);
	const [fullyVaccinated, setFullyVaccinated] = useState(false);
	const [tabs, setTabs] = useState<"vaccinations" | "vaccinationreview" | "vaccinehold" | "vaccineexempt" | "requests">("vaccinations");
	const [showEligibleUpload, setShowEligibleUpload] = useState(false);
	const [showVaccineUpload, setShowVaccineUpload] = useState(false);

	const handleEligibleShowUpload = () => {
		setShowEligibleUpload(true);
	};

	const handleEligibleCloseUpload = () => {
		setShowEligibleUpload(false);
	};

	const handleVaccineShowUpload = () => {
		setShowVaccineUpload(true);
	};

	const handleVaccineCloseUpload = () => {
		setShowVaccineUpload(false);
	};

	useEffect(() => {
		setLoading(false);
		if (peopleStore?.vaccineList === undefined && !isLoading) {
			fetchVaccineResults()
		}
	}, []);

	const fetchVaccineResults = () => {
		setLoading(true);
		// let searchDate = new Date();
		// let dateString = `${searchDate.getFullYear()}-${searchDate.getMonth() + 1 < 10 ? "0" + (searchDate.getMonth() + 1) : searchDate.getMonth() + 1}-${
		// 	searchDate.getDate() < 10 ? "0" + searchDate.getDate() : searchDate.getDate()
		// }`;
		let dateString = ''
		setLoading(true);
		covidNav!.currentDateString = dateString;
		medicalApi.fetchVaccineResults().then((vaccineDetailResponse: FluShotDetailResponse) => {
			if (vaccineDetailResponse.error) {
				setLoading(false);
				setError(true);
				//todo: handle error
			} else if (vaccineDetailResponse.unauth) {
				setUnAuth(true);
			} else {
				if (vaccineDetailResponse.items) {
					setLoading(false);
					peopleStore.setVaccineList(vaccineDetailResponse.items);
				}
			}
		});
	}

	const reloadDataCompleted = async (date?: string, only_complete?: boolean) => {
		setLoading(true);
		
		if(!date)
		{
			date = covidNav!.currentDateString;
		}
		else{
			covidNav!.currentDateString = date!;
		}

		const vaccineDetailResponse: FluShotDetailResponse = await medicalApi.fetchVaccineResults(date!, only_complete);
		if (vaccineDetailResponse.error) {
			setLoading(false);
			//todo: handle error
		} else if (vaccineDetailResponse.unauth) {
			setUnAuth(true);
		} else {
			if (vaccineDetailResponse.items) {
				setLoading(false);
				peopleStore.setVaccineList(vaccineDetailResponse.items);
			}
		}
	};

	const getInitialCalendarValue = () => {

		if(covidNav!.currentDateString)
		return covidNav!.currentDateString;

		// let searchDate = new Date();
		// let dateString = `${searchDate.getFullYear()}-${searchDate.getMonth() + 1 < 10 ? "0" + (searchDate.getMonth() + 1) : searchDate.getMonth() + 1}-${
		// 	searchDate.getDate() < 10 ? "0" + searchDate.getDate() : searchDate.getDate()
		// }`;
		return null;
	};

	const navItems: NavItemProps[] = [
		{
			icon: "",
			iconOnly: false,
			text: "Vaccinations",
			state: tabs === "vaccinations" ? NavItemState.Active : NavItemState.None,
			onClick: () => { setTabs("vaccinations") },
		},
		{
			icon: "",
			iconOnly: false,
			text: "Review Vaccine Uploads",
			state: tabs === "vaccinationreview" ? NavItemState.Active : NavItemState.None,
			onClick: () => { setTabs("vaccinationreview") },
		},
		{
			icon: "",
			iconOnly: false,
			text: "Holds",
			state: tabs === "vaccinehold" ? NavItemState.Active : NavItemState.None,
			onClick: () => setTabs("vaccinehold"),
		},
		{
			icon: "",
			iconOnly: false,
			text: "Requests",
			state: tabs === "requests" ? NavItemState.Active : NavItemState.None,
			onClick: () => setTabs("requests"),
		}
	];

	//if (error) return <Error context={ErrorContext.Component} message="Failed to fecth Vaccine results" type={ErrorType.Generic} />;
	if (unAuth) return <Error context={ErrorContext.Component} message="Not Authorized" type={ErrorType.AccessDenied} />;

	return (
		<React.Fragment>
			<div className="contacttracingtable">
				<NavBar tabs={navItems} type={NavBarTypes.Text} itemAlignment={ItemAlignment.Left} />
			</div>

			{tabs === "vaccinations" && (
				<Fragment>
					<div className="calendar-picker">
						<div className="row">
							<div className="col-xl-4">
								<div className="row">
									<div className="col-12 text-left">
										<span className="toggle-vacc">
											<Toggle
												choice="Negative"
												onStateChanged={(e: any) => {
													e.choice === "Positive" ? setFullyVaccinated(true) : setFullyVaccinated(false);
													covidNav!.currentDateString = '';
													reloadDataCompleted("", e.choice === "Positive" ? true : false);
												}}
											/>
										</span>
										<span className="toggle-vacc2">Show fully vaccinated people.</span>									
									</div>
								</div>
							</div>
							<div className="col-xl-4">
								{covidNav!.globalTableIsLoading 
									? <div style={{display: 'inline-block', width: '40%'}}>
										<Textbox name="CalendarInput" isLoading />
									</div>
									: !fullyVaccinated && <CalendarInput onChange={(e: string) => reloadDataCompleted(e)} value={getInitialCalendarValue()} id="vaccineDateSelector" />
								}
							</div>
							<div className="col-xl-4">
								<div className="controls">
									{roles?.roles.includes("medical") && (
										<Button type={ButtonType.Secondary | ButtonType.Ghost} text="Import Elected Individuals" onClick={() => handleEligibleShowUpload()} />
									)}
									{roles?.roles.includes("medical") && <Button type={ButtonType.Primary} text="Import Results" onClick={() => handleVaccineShowUpload()} />}
								</div>
							</div>
						</div>
					</div>
					<Provider peopleStore={peopleStore}>
						<VaccineAdministered isLoading={isLoading} />
					</Provider>
					<EligibleUpload show={showEligibleUpload} onClose={handleEligibleCloseUpload} />
					<VaccineUpload show={showVaccineUpload} onClose={handleVaccineCloseUpload} />
				</Fragment>
			)}
			{/* {tabs === "vaccinationreview" && <VaccinationReview />}
			{tabs === "vaccinehold" && <VaccineHold />}
			{tabs === "requests" && <VaccineRequests />} */}
		</React.Fragment>
	);
};

const Vaccine = inject("covidNav", "roles", "peopleStore")(observer(VaccineComponent));
export default Vaccine;
