import React from 'react';

export interface StarRatingProps
{
    onActionClick?: any,
}

export interface StarRatingState
{
    selectedStar: number
}

export default class StarRating extends React.Component<StarRatingProps, StarRatingState>
{
    constructor(props: any)
    {
        super(props);

        this.state = {
            selectedStar: 0
        }
    }

    public static defaultProps =
        {

        };

    getClasses(): string
    {
        var classes: string[] = [];

        classes.push("stars-" + this.state.selectedStar)

        return classes.join(' ');
    }

    setStar(rating: number)
    {
        this.props.onActionClick(rating);
        this.setState({ selectedStar: rating })
    }

    render(): React.ReactNode 
    {
        return (
            <div className={`star-rating ${this.getClasses()}`}>

                <a className='star-1'>
                    <i tabIndex={0} onClick={() => this.setStar(1)} className="fas fa-star"></i>
                    <a className='star-2'>
                        <i tabIndex={0} onClick={() => this.setStar(2)} className="fas fa-star"></i>
                        <a className='star-3'>
                            <i tabIndex={0} onClick={() => this.setStar(3)} className="fas fa-star"></i>
                            <a className='star-4'>
                                <i tabIndex={0} onClick={() => this.setStar(4)} className="fas fa-star"></i>
                                <a className='star-5'>
                                    <i tabIndex={0} onClick={() => this.setStar(5)} className="fas fa-star"></i>
                                </a>
                            </a>
                        </a>
                    </a>
                </a>
            </div>
        );
    }
}