import {action, computed, observable} from "mobx";

export interface UserProfile {
    mitId?: string;
    kerberosId?: string;
    firstName?: string;
    lastName?: string;
    displayName?: string;
}

export interface UserProfileStoreInterface {
    userProfile: UserProfile;
}

class UserProfileStore {
    @observable userProfile: UserProfile

    constructor(userProfile: UserProfile) {
        this.userProfile = {
            ...userProfile
        }
    }

    @action setCurrentUser(mitId: string, kerberosId: string, firstName: string, lastName: string, displayName: string): void {
        this.userProfile = {
            mitId, kerberosId, firstName, lastName, displayName
        }
    }

    @computed get currentUser(): UserProfile {
        return this.userProfile
    }
}

export default UserProfileStore