import React from "react";

// This component needs to be used for all icons inside components even
export interface BadgeProps {
	type: BadgeType;
	content: any;
	pill?: boolean;
}
export type BadgeType = "primary" | "secondary" | "success" | "danger" | "warning" | "info" | "light" | "dark";

export default class Badge extends React.Component<BadgeProps> {
	getClasses(): string {
		var classes: string[] = [];

		if (this.props.pill) classes.push("badge-pill");

		return classes.join(" ");
	}

	render(): React.ReactNode {
		return <span className={`badge badge-${this.props.type} ${this.getClasses()}`} style={{alignSelf: 'center'}}>{this.props.content}</span>;
	}
}
