import { elementType } from '../Table/types';

export type TableRowBaseProps = React.ThHTMLAttributes<HTMLTableRowElement> &
  React.TdHTMLAttributes<HTMLTableRowElement>;
export type SortDirection = 'asc' | 'desc' | false;

export const defaults: TableRowProps = {
  component: 'tr',
  selected: false,
  hover: false,
};
export interface TableRowProps {
  id?: any;
  style?: any;
  children?: React.ReactNode;
  component?: elementType;
  hover?: boolean;
  selected?: boolean;
  classes?: any;
  onClick?: React.MouseEventHandler<any>;
}
