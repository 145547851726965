import React from 'react';

import Button, { ButtonProps, ButtonType } from './Button';

export enum ButtonBarType
{
    None,
    Padded,
    Grouped,
    Underlined
} 

export interface ButtonBarProps
{
    type: ButtonBarType,
    buttons: ButtonProps[],
    alignment?: string,
    trigger?: any;
}

export default class ButtonBar extends React.Component<ButtonBarProps>
{
    public static defaultProps =
        {
            type: ButtonBarType.None,
            buttons: [{ text: 'Action', type: ButtonType.Primary } ],
        };

    getClasses(): string
    {
        var classes: string[] = [];

        if (this.props.type === ButtonBarType.Padded)
            classes.push('btn-padded');
        else if (this.props.type === ButtonBarType.Grouped)
            classes.push('btn-group');
        else if (this.props.type === ButtonBarType.Underlined)
            classes.push('btn-padded btn-underlined');

        else if (this.props.alignment === "right")
            classes.push('text-right');

        return classes.join(' ');
    }

    render(): React.ReactNode
    {
        return (
            <div className={`${this.getClasses()}`}>
                {this.props.buttons.map((button, num) => (
                    <Button {...button} trigger={this.props.trigger} key={num} />
                ))}

            </div>
        );
    }
}