import React from "react";
import { IconType } from "../Icon";

export interface DropdownItemProps {
	icon: string;
	text: string;
	state?: DropDownState;
	badge?: number;
	onClick?: any;
	onChange?: any;
	onKeyDown?: (e: React.KeyboardEvent<HTMLAnchorElement>) => void;
	id?: string;
	tabindex?: number;
	class?:string;
	iconStyle?: IconType;
}

export enum DropDownState {
	None,
	Disabled,
	Active,
}

export default class DropdownItem extends React.Component<DropdownItemProps> {
	public static defaultProps = {
		icon: "",
		text: "Item",
		state: DropDownState.None,
		badge: 0,
	};

	getClasses(): string {
		var classes: string[] = [];

		if (this.props.class) classes.push(this.props.class);

		if (this.props.state === DropDownState.Active) classes.push("active");
		else if (this.props.state === DropDownState.Disabled) classes.push("disabled");

		return classes.join(" ");
	}

	getIconClasses(): string {
		var classes: string[] = [];

		if (this.props.state === DropDownState.Active) classes.push("fas");
		else if (this.props.iconStyle === "duotone") classes.push("fad");
		else if (this.props.iconStyle === "regular") classes.push("far");
		else if (this.props.iconStyle === "solid") classes.push("fas");
		else {
			classes.push("fal");
		}

		return classes.join(" ");
	}

	getAriaDisabledState(): boolean | undefined {
		if (this.props.state === DropDownState.Disabled) return true;

		return undefined;
	}

	render(): React.ReactNode {
		let idItem = {};

		if (this.props.id) idItem = { id: this.props.id };
		if (this.props.id) idItem = { id: this.props.id };

		return (
			// MENU BAR wants the <LI> to be active
			//NAVBARS want the <A> to be active, we need conditional thing here (it's a bootstrap issue)
			<a
				// href="javascript:void(0)"
				tabIndex={this.props.tabindex}
				aria-label={this.props.text}
				className={`dropdown-item  ${this.getClasses()}`}
				{...idItem}
				aria-disabled={this.getAriaDisabledState()}
				onKeyDown={(e: React.KeyboardEvent<HTMLAnchorElement>) => {this.props.onKeyDown && this.props.onKeyDown(e)}}
				onClick={() => {
					this.props.onClick && this.props.onClick();
					this.props.onChange && this.props.onChange();
				}}>
				<i className={`${this.getIconClasses()} fa-${this.props.icon} mr-2`}></i> {this.props.text}
				{this.props.badge !== undefined && this.props.badge > 0 && <span className="badge float-right badge-pill">{this.props.badge}</span>}
			</a>
		);
	}
}
