import React from 'react';
import Icon from './Icon';

interface DarkModeState
{
    darkMode: boolean,
}

export interface DarkModeProps
{
    allowDarkMode: boolean,
}

//Moved to Profile component, are we going to keep this as a stand alone component? - from JL
export default class DarkMode extends React.Component<DarkModeProps, DarkModeState>
{
    public static defaultProps =
        {
            darkMode: false,
            allowDarkMode: false
        };

    constructor(props: DarkModeProps)
    {
        super(props);

        const darkMode = localStorage.getItem("hui.darkMode") === "true";

        if (darkMode)
        {
            document.body.classList.add("dark");
        }

        this.state =
        {
            darkMode: darkMode
        };
    }

    switchMode(): void
    {
        const updatedState = { darkMode: !this.state.darkMode };

        this.setState(updatedState);

        if (updatedState.darkMode)
        {
            document.body.classList.add("dark");
            localStorage.setItem('hui.darkMode', "true");
        }
        else
        {
            document.body.classList.remove("dark");
            localStorage.setItem('hui.darkMode', "false");
        }
    }

    render(): React.ReactNode 
    {
        return (
            <React.Fragment>
                <p className={this.props.allowDarkMode ? "darkmode-switch p-2" : "d-none"}>
                    <input type="checkbox" id="darkmode" onClick={() => this.switchMode()} defaultChecked={this.state.darkMode} />
                    <label className="moon" htmlFor="darkmode"><Icon icon="moon" type={"solid"} padded={true} />Go Dark</label>
                    <label className="sun" htmlFor="darkmode"><Icon icon="sun" type={"solid"} padded={true} />Go Light</label>
                </p>
            </React.Fragment>
        );
    }
}