import React, { FC } from 'react';
import {BasicColors, Alignment, Spacing} from '../common/GlobalTypes';

export interface ContainerProps
{
    contents: any,
    alignment?: Alignment,
    raised?:boolean,
    color?: BasicColors,
    className?: string,
    margin?: Spacing,
    padding?: Spacing
}

const Container: FC<ContainerProps> = (props) => {
    const {contents, alignment, raised, color, className, margin, padding} = props;

    //TODO: Add any colour styling because the defaults dont work so well
    /* const getStyles = () => {
        let styles:any = {};
        if(color) styles.backgroundColor = color
        return styles;
    } */
    const getClasses = () => {
    
        var classes: string[] = [];
        
        if(className) classes.push(className);
        if(alignment) classes.push(`text-${alignment}`);
        if(margin) classes.push(`m-${margin}`)
        if(padding) classes.push(`p-${padding}`)
        if(raised) classes.push('raised');
        if(color) classes.push(color)

        return classes.join(' ');
    }

    return (
        // This item will have to be a style element. It's not wrong, it's a flexibility we afford
        <div className={`card ${(getClasses())}`}/*  style={getStyles()} */>
            { contents }
        </div>
    );
}

Container.defaultProps = {
    alignment: "left",
    raised: true
};

export default Container;