import React, { FC, useState, useEffect } from "react";
import ListItem, { ListItemProps, ListItemState } from "./ListItem";
import { huiLog } from "../common/huiConsoleLogs";

// Reconsider the name of this module
interface ActionListState {
    /**
	 * @deprecated Use ActionListType type literal instead [normal or flush].
	 */
	selectedIndex: number;
}

export enum ActionListType {
	Normal,
	Flush,
}

export type ActionListTypes = "normal" | "flush"

interface ActionListProps {
	type?: ActionListTypes | ActionListType;
	items: ListItemProps[];
	compact?: boolean;
	selectable?: boolean;
	isLoading?: boolean;
	isEmpty?: boolean;
	clearSelected?: boolean;
}

const ActionList: FC<ActionListProps> = ({ type, items, compact, selectable, isLoading, isEmpty, clearSelected }) => {

	useEffect(() => {
		huiLog.updateWarning("[Deprecation WARNING] \nSwitch all [ActionList] component types from enum [ActionListType] to string, with the v2.0.0 release the enums will be depricated. \nUse type literal [normal or flush] instead of enum. \nType [ActionListTypes] is also exported from cmponent");
		// eslint-disable-next-line
	}, [])

	const [selectedIndex, setSelectedIndex ] = useState<number>(-1)
	
	useEffect(() => {
		if (clearSelected) setSelectedIndex(-1)
	}, [clearSelected])

	const getClasses = ():string => {
		var classes: string[] = [];

		if (type === ActionListType.Flush || type === "flush" ) classes.push("list-group-flush");
		if (compact) classes.push("list-group-compact");
		if (!selectable) classes.push("disabled");
		if (isLoading) classes.push("loading disabled");
		if (!isLoading && isEmpty) classes.push("d-none");
		
		return classes.join(" ");
	}
	const getState = (item: ListItemProps, number: number): ListItemState | undefined  => {
		if (!selectable) return ListItemState.None;
		return selectedIndex === number ? ListItemState.Active : item.state;
	}

	const setSelected = (index: number): boolean => {
		if (selectable) {
			setSelectedIndex( index );
		}
		return true;
	}

	return (<>
		
		{!!isLoading? (
			<div aria-busy={true} role="list" className={`list-group ${getClasses()}`}>
				<div className="skeleton list-group-item">&nbsp;</div>
				<div className="skeleton list-group-item">&nbsp;</div>
				<div className="skeleton list-group-item">&nbsp;</div>
				<div className="skeleton list-group-item">&nbsp;</div>
			</div>
		) : (
			<>
				<div aria-busy={false} role="list" className={`list-group ${getClasses()}`}>
					{items.map((item: ListItemProps, number: number) => (
						<ListItem {...item} key={number} state={getState(item, number)} onClick={(event: React.MouseEvent) => setSelected(number) && item.onClick && item.onClick(event, item)} />
					))}
				</div>

				<div className={isEmpty ? "empty-list" : "d-none"}>
					<i className="fal fa-folder-open"></i>
					No Items found
				</div>
			</>
		)}
	</>)
}

ActionList.defaultProps = {
	type: 'normal',
	items: [{ text: "empty" }],
	compact: false,
	selectable: true,
	isEmpty: false,
	isLoading: false,
}

export default ActionList;
