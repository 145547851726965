import React, { Fragment } from "react";

export interface BarcodeProps {
	label: string;
	imageUrl: string;
	isLoading?: boolean;
	barcodeError?: string;
	description?: string;
}

export default class Barcode extends React.Component<BarcodeProps> {
	render() {
		return (
			<div aria-busy={this.props.isLoading ?? false} className="barcode-container mx-auto">
				{this.props.isLoading && <div>Loading barcode...</div>}
				{!this.props.isLoading && this.props.barcodeError && <div>{this.props.barcodeError}</div>}
				<Fragment>
					{!this.props.isLoading && !this.props.barcodeError && <div className={"barcode-name text-center"}>{this.props.label}</div>}
					{this.props.imageUrl && (
						<div className={"text-center"}>
							<img src={this.props.imageUrl} className={"img-barcode"} alt={this.props.description} />
						</div>
					)}
					{!this.props.isLoading && !this.props.barcodeError && <div className={"barcode-description text-center"}>{this.props.description}</div>}
				</Fragment>
			</div>
		);
	}
}
