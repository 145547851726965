import BaseController from "./BaseController";
import { PersonAutocomplete } from "./PeopleController";

export interface FetchStringResponse {
	value?: string;
	error?: any;
}

export interface PersonProfileInterface {
	mitId: string;
	kerberosId: string;
	firstName: string;
	lastName: string;
	displayName: string;
	email: string;
	phone: string;
	mobile: string;
}

export interface FetchProfileResponse {
	person?: PersonProfileInterface;
	error?: any;
}

class UserController extends BaseController {
	readonly url_picture: string;
	readonly url_profile: string;
	readonly url_feature: string;
	constructor() {
		super();
		this.url_picture = `${this.apiHost}/${this.apiPathDigitalId}/picture`;
		this.url_profile = `${this.apiHost}/${this.apiPathDigitalId}/profile`;
		this.url_feature = `${this.apiHost}/${this.apiPathPass}/pass/features`;
	}

	fetchFeatures = async (): Promise<any> => {
		let response = await fetch(`${this.url_feature}`, await this.requestHeaderGet());
		if (response.status == 200) {
			let data = await response.json();
			return data;
		} else {
			return {
				error: true,
			};
		}
	};

	fetchProfile = async (kerbId?: string): Promise<FetchProfileResponse> => {
		let response = await fetch(`${this.url_profile}${kerbId && kerbId !== "" ? `/${kerbId}` : ``}`, await this.requestHeaderGet());
		if (response.status == 200) {
			try {
				let data = await response.json();
				return {
					person: {
						kerberosId: data.kerberos,
						mitId: data.mitid,
						firstName: data.first_name,
						lastName: data.last_name,
						displayName: data.display_name,
						email: data.email,
						phone: data.phone_number,
						mobile: data.phone_number,
					},
				};
			} catch (error) {
				return {
					person: {
						kerberosId: "",
						mitId: "",
						firstName: "",
						lastName: "",
						displayName: "",
						email: "",
						phone: "",
						mobile: "",
					},
				};
			}
		} else {
			return {
				error: true,
			};
		}
	};

	fetchPicture = async (mitId?: string, highResolution?: boolean): Promise<FetchStringResponse> => {
		let response = await fetch(`${this.url_picture}${mitId ? `/${mitId}${highResolution ? "?resolution=high" : ""}` : ""}`, await this.requestHeaderGet());
		if (response.status == 200) {
			let data = await response.blob();
			return {
				value: URL.createObjectURL(data),
			};
		} else {
			return {
				error: true,
			};
		}
	};
}

const userController = new UserController();
export default userController;
