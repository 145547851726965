import React from 'react';

export interface TwoColumnsProps
{
    leftArea: any,
    leftAlignment: string,
    rightArea: any,
    rightAlignment: string,
    showDivider: boolean,
    showGutters: boolean,
    primarySize: number,  //this is to be used for determining the column size of the left column,
    //ideally this needs to rather be an enumeration
    padded: boolean
}
 
export default class TemplateTwoColumns extends React.Component<TwoColumnsProps>
{
    render(): React.ReactNode
    {
        return (
            <React.Fragment>

                <div className={`row ${(!this.props.showGutters) && 'no-gutters'}`}>
                    <div className={`col-lg-${this.props.primarySize} col-md-${this.props.primarySize} text-${this.props.leftAlignment} ${(this.props.showDivider) && 'divider'} `}>
                        <div className={`${(this.props.padded) && 'p-4'}`}>{
                            this.props.leftArea
                        }</div>
                    </div>
                    <div className={`col-lg-${12 - this.props.primarySize} col-md-${12 - this.props.primarySize} text-${this.props.rightAlignment}`}>
                        <div className={`${(this.props.padded) && 'p-4'}`}>{
                            this.props.rightArea
                        }</div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}