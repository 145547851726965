import React, { FC } from "react";

type AlertType = "success" | "info" | "warning" | "error";
type IconType = "regular" | "solid" | "light" | "duotone" | "brands";
type FAIconTypes = "far" | "fas" | "fal" | "fad" | "fab";

export interface AlertProps {
  text: any;
  type?: AlertType;
  onClose?: () => void;
  icon?: string;
  iconType?: IconType;
}

const iconTypeMap: {fa:FAIconTypes, type: IconType}[] = [
  {
    fa: "far",
    type: "regular",
  },
  {
    fa: "fas",
    type: "solid",
  },
  {
    fa: "fal",
    type: "light",
  },
  {
    fa: "fad",
    type: "duotone",
  },
  {
    fa: "fab",
    type: "brands",
  }
];

const Alert: FC<AlertProps> = (props) => {
  const { text, type, onClose, icon, iconType } = props;

  const getColCount = (): number => {
    let colCount = 12;
    if (!!onClose) colCount--;

    if (!!icon) colCount--;
    return colCount;
  };

  const getIconType = (type?: IconType): FAIconTypes => {
      let T = iconTypeMap.find((iconType: any) => iconType.type === type);
      if(!T) return 'fas';
      else return T.fa || 'fas';
  };

  const getClasses = (): string => {
    var classes: string[] = [];
    if (type === "success") classes.push("alert-success");
    else if (type === "info") classes.push("alert-info");
    else if (type === "warning") classes.push("alert-warning");
    else if (type === "error") classes.push("alert-danger");

    return classes.join(" ");
  };

  return (
    <div
      className={`d-flex flex-nowrap align-items-stretch alert ${getClasses()}`}
      role="alert"
    >
      {!!icon && (
        <div className="alert-icon align-self-center">
          <i
            aria-hidden={true}
            aria-label={`icon`}
            className={`${getIconType(iconType)} fa-${icon} m-1'}`}
            role={"img"}
          />
        </div>
      )}
      <div className={`flex-grow-1 mx-2 align-self-center`}>{text}</div>
      {!!onClose && (
        <div className="alert-close-icon align-self-center">
          <i
            aria-hidden={false}
            aria-label={`close alert`}
            className={`close-button fa fa-times m-1'}`}
            role={"button"}
            onClick={() => {
              onClose && onClose();
            }}
          />
        </div>
      )}
    </div>
  );
};

Alert.defaultProps = {
  type: "info",
};

export default Alert;
