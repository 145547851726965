import React, {FC} from 'react';
import {BasicColors} from '../common/GlobalTypes';

// This component needs to be used for all icons inside components even
export interface IconProps
{
  type: IconType,
  icon: string,
  padded?: boolean
  color?: string;
  onClick?: () => void;
  tabIndex?: number;
  hideAriaLabel?: boolean;
}
export type IconType = "light" | "regular" | "solid" | "brand" | "duotone";

const Icon: FC<IconProps> = ({type, icon, padded, color, onClick, tabIndex, hideAriaLabel}) => 
{
  const getClasses = () =>
  {
    var classes: string[] = [];

    if (type === "light") classes.push('fal');
    if (type === "regular") classes.push('far');
    if (type === "solid") classes.push('fas');
    if (type === "brand") classes.push('fab');
    if (type === "duotone") classes.push('fad');
    if (color) classes.push(`${color}-text`);

    return classes.join(' ');
  }

  const getStyles = () =>
  {
    var styles:any = {};
    if (color) styles.color = color;
    return styles;
  }

  return (
    <i 
      aria-hidden={!onClick}
      aria-label={hideAriaLabel ? '' : `${icon} icon`}
      className={`${getClasses()} fa-${(icon)} ${(padded) && 'm-1'}`}
      role={onClick ? "button" : "img"}
      onClick={onClick}
      tabIndex={onClick ? tabIndex || 0 : undefined}
      style={getStyles()}
    />
  );
}

export default Icon;