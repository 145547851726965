import * as React from 'react'
import {Alert, Col, Row} from "react-bootstrap";
import {FunctionComponent, useEffect, useState} from "react";
import {ContactPerson, PeopleStoreInterface} from "../../model/PeopleStore";
import medicalApi, {PostStatusResponse, TextItem} from "../../api/MedicalApi";
import {inject, observer} from "mobx-react";
import * as Loader from 'react-loader'
import {CONTACT_SUPPORT_LINK, mediumLoaderOptions} from "../../common/Defaults";
import {PageError} from "../../types/PageErrors";
import {HoldsModelInterface} from "../../model/Holds";
import { Drawer, DrawerSize, DrawerType, TextType, Text, Button, ButtonType, ButtonState} from '@mit/hui';

export interface AddHold {
    name: string;
    kerberosId: string;
    mitId: string;
}

interface AddSymptomProps {
    person: AddHold;
    showCalendar?: boolean;
    peopleStore?: PeopleStoreInterface;
    holds?: HoldsModelInterface;
}

const AddHoldComponent: FunctionComponent<AddSymptomProps> = ({person, showCalendar, peopleStore, holds}) => {

    const [isLoading, setIsLoading] = useState(false)
    const [show, setShow] = useState(false)
    const [reason, setReason] = useState<string>(' ')
    const [description, setDescription] = useState<string>('')
    const [pageError, setPageError] = useState<PageError>({error: false, errorText: ''})
    const [reasons, setReasons] = useState<TextItem[]>([{id: ' ', text: 'Select one...'}])

    const handleShow = () => {
        setShow(true)
    }

    const handleClose = () => {
        setShow(false)
        setReason(' ')
        setDescription('')
        setPageError({error: false, errorText: ''})
    }

    const submitAndClose = () => {
        if (isValid()) {
            setPageError({error: false, errorText: ''})
            setIsLoading(true)
            medicalApi.postOnHold(person.mitId, reason, description).then((response: PostStatusResponse) => {
                if (response.success) {
                    medicalApi.fetchPersonMedicalHolds(person.mitId).then((resp) => {
                        holds!.personHolds = resp.items ? resp.items : []
                    })
                    setIsLoading(false)
                    handleClose()
                } else {
                    setPageError({error: true, errorText: `An error occurred and the person could not be processed. If the problem persists, please contact ${CONTACT_SUPPORT_LINK}.`})
                }
                setIsLoading(false)
            }).catch(() => {
                setIsLoading(false)
                setPageError({error: true, errorText: `An error occurred and the person could not be processed. If the problem persists, please contact ${CONTACT_SUPPORT_LINK}.`})
            })

            // medicalApi.postExemptions(person.mitId, exemptionFlags, description).then((response: PostStatusResponse) => {
            //     if (response.success) {
            //         medicalApi.fetchPersonMedicalHolds(person.mitId).then((resp) => {
            //             holds!.personHolds = resp.items ? resp.items : []
            //         })
            //         setIsLoading(false)
            //         handleClose()
            //     } else {
            //         setPageError({error: true, errorText: `An error occurred and the person could not be processed. If the problem persists, please contact ${CONTACT_SUPPORT_LINK}.`})
            //     }
            //     setIsLoading(false)
            // }).catch(() => {
            //     setIsLoading(false)
            //     setPageError({error: true, errorText: `An error occurred and the person could not be processed. If the problem persists, please contact ${CONTACT_SUPPORT_LINK}.`})
            // })
        }
    }

    const isValid = () => {
        return reason !== ' ' && description.trim() !== ''
    }

    const changeReason = (event: any) => {
        setReason(event.target.options[event.target.selectedIndex].id)
    }

    const changeDescr = (event: any) => {
        setDescription(event.target.value)
    }
    const defaultButtonData: any[] = [
        { icon: '', text: 'Submit', type: ButtonType.Primary, onClick: () => { submitAndClose() }, state: isValid() ? ButtonState.Enabled : ButtonState.Disabled },
        { icon: '', text: 'Cancel', type: ButtonType.Secondary | ButtonType.Ghost, onClick: () => { handleClose() }, state: ButtonState.Enabled },
    ];

    return (
        <>
            <Button type={ButtonType.TextNaked} icon="pause-circle" text="Put on Hold" onClick={handleShow} />
            <Drawer
                contentAriaLabel={`Add Medical Hold for ${person.name}`}
                show={show} 
                onClose={handleClose} 
                size={DrawerSize.Medium} 
                type={DrawerType.Right} 
                themedColor={"medical"}
                header={
                    <>
                        <Text content={`Put ${person.name} On Hold`} type={TextType.Heading3} />
                    </>
                }
                contents={
                    <>
                    <Loader loaded={!isLoading} options={{...mediumLoaderOptions, color: '#000000'}}>
                        {pageError.error && <Alert variant={'danger'}>{pageError.errorText}</Alert>}
                        <Row>
                            <Col xs={6}>
                                <span className={'symptoms-question-heading'}>Reason</span>
                                <select className={'form-control'} onChange={changeReason}>
                                    {reasons.map((item: TextItem) => (<option key={item.id} id={item.id}>{item.text}</option>))}
                                </select>

                                {reason !== ' ' && <div>
                                    <span className={'symptoms-question-heading smallMarginTop'}>Comment</span>
                                    <textarea rows={3} className={'form-control'} value={description} onChange={changeDescr}/>
                                </div>}
                            </Col>
                        </Row>
                    </Loader>
                    </>
                } 
                footer={defaultButtonData}
            />
        </>
    )
}

const AddHold = inject('peopleStore', 'holds')(observer(AddHoldComponent))
export default AddHold