import React, { FC } from 'react';
import { useForm, UseFormMethods } from "react-hook-form";

type ValidationTrigger = "onChange" | "onBlur" | "onSubmit" | "onTouched" | "all";

type FormConnectProps = {
    onRender?: (fieldsRef: any) => void;
    children: (methods: UseFormMethods) => React.ReactNode;
    formValidationTrigger?: ValidationTrigger;
};

export const Form: FC<FormConnectProps> = (props) =>
{
    const methods = useForm({mode: props.formValidationTrigger });
    props.onRender && props.onRender(methods.control.fieldsRef.current);

    return <div>
        {
            props.children(methods)
        }
    </div>
};

Form.defaultProps= {
    formValidationTrigger: 'onChange'
}