import React from "react";

export interface HeroshotLayoutProps {
    content: any;
    header: any;
    startState: boolean; // true = component will transition in, false = component will not transition in
    className?: string;
    navigation?: any;
    headerHeight?: number;
}

export default class HeroshotLayout extends React.Component<HeroshotLayoutProps>
{


    public static defaultProps =
        {

        };


    getClasses(): string {
        var classes: string[] = [];

        if(this.props.className) classes.push(this.props.className)

        return classes.join(' ');
    }

    render(): React.ReactNode {

        /* const searchProps: TextboxProps =
        {
            startIcon: 'search',
            startIconAccessibilityText: 'search',
            placeholderText: 'Search your list',
            type: TextboxType.IconStart,
            name: "search"
        } */

        return (
            <div className={`heroshot-layout row no-gutters ${(this.getClasses())} ${(this.props.startState) && 'slide-in-bottom-whole'}`}>

                <div className={`layout-wrapper col-lg-12 overflow-auto align-self-stretch  `}>
                    <div className="header" role="navigation" aria-label="tertiary" style={{ height: this.props.headerHeight }}>
                        <div className="row">
                            <div className="col-12">
                                {this.props.header}
                            </div>
                        </div>
                    </div>

                    <div className={`content-area ${(this.props.startState) && 'slide-in-bottom aa'}`}
                        role="region"
                        aria-label="detail-content"
                        style={this.props.headerHeight ? {minHeight: `calc(100% - ${this.props.headerHeight}px)`, height: `calc(100% - ${this.props.headerHeight}px)`} : {}}>
                    <div className="container navbar-lift">
                        {this.props.navigation}
                    </div>
                    <div className="container">
                        <div className={`col-12 py-4`}>{this.props.content}</div>
                    </div>

                </div>
            </div>
            </div >

        );
    }
}
