import {observable} from "mobx";

export interface HoldsModelInterface extends Holds {
    
}

export interface HoldStatus {
    id: number;
    text: string;
    clear_for_access: boolean;
}

export interface ExemptionTypes {
    description: string
    icon: {
      color: string,
      icon: string,
      style: string
    }
    id: number
    name: string
    roleApprove: string
    seq: number
    roleCreate: string
}

class Holds {
    @observable showUpdateStatusModal: boolean
    @observable newStatus?: HoldStatus
    @observable guidToUpdate?: string
    @observable personName?: string
    @observable personHolds?: any
    @observable reason?: string
    @observable statuses?: HoldStatus[]
    @observable exemptionTypes?: ExemptionTypes[]
    @observable appText?: {id: string, text: string}[];
    @observable features?: string[]

    constructor() {
        this.showUpdateStatusModal = false
    }
}

const holds = new Holds()
export default holds