import * as React from "react";
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap/dist/css/bootstrap.min.css';

import Tooltip from '../Tooltip'

import { useState, useEffect } from "react";
import Icon from "../Icon";

interface Props {
  onSelect: () => void;
  text: string[];
  tooltip: string,
  selectionType?: "incl" | "excl";
  selected?: boolean;
  excluded: boolean;
  disabled?: boolean;
  paintValue?: "positive" | "negative";
  painting?: boolean;
  setPaint?: (value: boolean) => void;
  multiClick?: {
    firstClick: () => void;
    secondClick: () => void;
    thirdClick?: () => void;
  };
  readonly?: boolean;
  hoverIcon?: string;
}

export const AvailabilitySpot: React.FC<Props> = ({
  onSelect,
  selectionType = "incl",
  tooltip,
  text,
  painting,
  paintValue,
  setPaint,
  selected,
  excluded,
  disabled,
  multiClick,
  readonly,
  hoverIcon,
}) => {
  const [isSelected, setSelected] = useState<boolean>(selected || false);
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const [isPainting, setIsPainting] = useState<
    "positive" | "negative" | undefined
  >();

  useEffect(() => {
    if (!painting && !isPainting) {
      return;
    }
    if (!painting && !!isPainting) {
      setIsPainting(undefined);
      return;
    } else {
      setIsPainting(paintValue);
    }
  }, [painting, paintValue]);

  useEffect(() => {
    setSelected(selected || false);
  }, [selected]);

  const handleClick = () => {
    if (setPaint) {
      return;
    }

    if (readonly) {
      onSelect();
      return;
    }

    setSelected(!isSelected);
    onSelect();
  };

  const handleMouse = (value: boolean) => {
    if (isPainting === "positive" && value && !isSelected) {
      setSelected(true);
      onSelect();
    } else if (isPainting === "negative" && value && isSelected) {
      setSelected(false);
      onSelect();
    }
    setIsHovering(value);
  };

  const handlePaint = (value: boolean) => {
    if (!setPaint) {
      return;
    }
    setSelected(!selected);
    onSelect();
    if (setPaint) {
      setPaint(value);
    }
  };

  return (
    <Tooltip text={tooltip} placement="top" className="availability-tooltip">
      <div
        role="button"
        onClick={handleClick}
        className={`arrow availability-spot-${
          disabled ? "disabled" : isSelected ? "selected" : "unselected"
        }${excluded ? " exclusion" : ""} ${selectionType}`}
        onMouseEnter={() => handleMouse(true)}
        onMouseOut={() => handleMouse(false)}
        onMouseDown={() => handlePaint(!selected)}
      />
    </Tooltip>
  );
};
