import React from 'react';

export interface MultiFillBarProps {
  maxValue: number;
  primaryFillAmount: number;
  secondaryFillAmount?: number;
}

export const MultiFillBar: React.FC<MultiFillBarProps> = (
  {
    maxValue,
    primaryFillAmount,
    secondaryFillAmount,
  }
) => {

  return (
    <div className="multi-fill-body">
      <div
        className="multi-fill-primary"
        style={{width: `${(primaryFillAmount/maxValue*100)}%`}}
      />
      {!!secondaryFillAmount &&
        <div
          className="multi-fill-secondary"
          style={{width: `${((primaryFillAmount + secondaryFillAmount)/maxValue*100)}%`}}
        />
      }
    </div>
  )
}