import * as React from "react";
import { useEffect, useState } from "react";
import { Day } from "@mit/hui/build/components/Calendar/CalendarDisplay";
import { DateTime } from 'luxon'
import {
	Text,
	Drawer,
	DrawerSize,
	DrawerLayout,
	DrawerType,
	Button,
	ButtonType,
	Icon,
	ActionList,
	ButtonState,
	TextArea,
	TextType,
	Label,
	Spacer,
	Checkbox,
	ImageUpload,
	TemplateModal,
	TemplateModalAlignment,
	TemplateModalSize,
	ButtonBar,
	Alert,
	FormField,
	DropDownState,
	Dropdown,
  CalendarInput
} from "@mit/hui";

import Resizer from "react-image-file-resizer";
import * as pdfjsLib from "pdfjs-dist/es5/build/pdf";
import pdfjsWorker from "pdfjs-dist/es5/build/pdf.worker.entry";
import { ImageCrop } from "./../../common/ImageCrop";
// import DatePicker from "./../../common/DatePicker";
// import { PersonAutocomplete } from "../../api/PeopleController";
import { HoldsModelInterface } from "../../model/Holds";
import MedicalApi, { ApiPersonDetail, PersonInfo } from "../../api/MedicalApi";
import { inject, observer, Provider } from "mobx-react";
import { DaysList } from "@mit/hui/build/components/Calendar/CalendarDisplay";
// import { ExtendedPerson } from "../../api/CovidPersonApi";

interface Props {
	show: boolean;
	vaccine_status?: string;
	vaccine_hold_status?: string;
	holds?: HoldsModelInterface;
	onClose: () => void;
	done: () => void;
  personDetails?: ApiPersonDetail;
  setReFetchPersonExemption: React.Dispatch<React.SetStateAction<boolean>>
}

export const VaccineExemptionRequestDrawerComponent: React.FC<Props> = (props) => {
	// const { auth } = useStoreState((state) => state.VaccineExemptionModel)
	// const { setReload, setAlertMessage, setNewDocUploaded } = useStoreActions((action) => action.VaccineExemptionModel)

	// const [personDetails, setPersonDetails] = useState<ApiPersonDetail | null>()
	const [expiryDate, setExpiryDate] = useState<string | undefined>(undefined);
	// const [expiryDate, setExpiryDate] = useState<moment.Moment | undefined>(undefined);
	const [expiryFrom, setExpiryFrom] = useState<string | undefined>("ALL");

	const [isLoading, setLoading] = useState(false);
	const [fileContentType, setFileContentType] = useState<string>("image/jpeg");
	const [showCropModal, setShowCropModal] = useState<boolean>(false);
	const [showPreview, setShowPreview] = useState<boolean>(false);
	const [displayData, setDisplayData] = useState<any>({});
	const [hasImage, setHasImage] = useState<boolean>(false);
	const [exemptionDocUrl, setExemptionDocUrl] = useState<any>({ image_key: "", signed_url: "" });
	const [file, setFile] = useState<any>();
	const [fileChanged, setFileChanged] = useState<boolean>(false);
	const [fileName, setFileName] = useState("");
	const [isUploading, setUploading] = useState(false);
	// const [agreementTicked, setAgreementTicked] = useState(false)
	const [extContractorTicked, setExtContractorTicked] = useState(false);
	// const [religiousTicked, setReligiousTicked] = useState(false)
	const [exemptionType, setExemptionType] = useState<number>();
	const [exemptionReason, setExemptionReason] = useState<string>("");
	const [personInfo, setPersonInfo] = useState<{type: string, personType: string} | undefined>();
	const [errorAlerts, setErrorAlerts] = useState<string[]>([]);

	const exemptionFroms = [
		{
			text: "COVID-19",
			enum: "COVID",
		},
		{
			text: "Flu Shot",
			enum: "FLU",
		},
		{
			text: "All",
			enum: "ALL",
		},
	];



	const tomorrowDate = DateTime.local().plus({ days: 1 }).toJSDate()
	const tomorrow = {
	  year: tomorrowDate.getFullYear(),
	  month: tomorrowDate.getMonth() + 1,
	  day: tomorrowDate.getDate()
	}

	const handleExpiryFromChange = (newValue: any) => {
		setExpiryFrom(newValue);
	};

	// const religiousExemptText =
	// 	'To be eligible for a religious exemption you must demonstrate that your refusal to be vaccinated is based on a sincere belief that is religious in nature. A refusal to be vaccinated does not qualify for a religious exemption if it is based on personal preference, concerns about the possible effects of the vaccine, or political opinions. Any intentional misrepresentation to MIT may result in denial of this request and further disciplinary action.'
	// const religiousExemptLabel = 'This vaccination is inconsistent with my sincerely held religious beliefs'
	const medicalExemptionText =
		"You may upload medical documentation from your health care provider here or your provider can securely send documentation directly to Human Resources. For directions on submitting information to HR, employees or their providers should contact: hr-dsmlo@mit.edu for Campus employees, or covidvaxexemption@ll.mit.edu for Lincoln Laboratory employees.";

	useEffect(() => {
		setLoading(false);
		// vaccineController.fetchExemptionTypes().then(res => {
		// 	if (res.error) {
		// 		console.log(res)
		// 	} else {
		// 		setExemptionTypes(res.data)
		// 		setLoading(false)
		// 	}
		// })

	}, []);

	useEffect(() => {
		// setAgreementTicked(false)
		setExtContractorTicked(false);
		// setReligiousTicked(false)
		setFile(undefined);
		setHasImage(false);
		setExemptionReason("");
		setExemptionType(undefined);
		setPersonInfo(undefined);
		setFileName("");
		setDisplayData({});
		setShowPreview(false);
		setErrorAlerts([]);
	}, [props.show]);

  useEffect(() => {
    loadPerson()
	}, [props.personDetails]);

	const resizeFile = (file: any) => {
		return new Promise((resolve) => {
			Resizer.imageFileResizer(
				file, // Is the file of the image which will resized
				800, // Is the maxWidth of the resized new image.
				1000, // Is the maxHeight of the resized new image.
				"JPEG", // Is the compressFormat of the resized new image.
				100, // Is the quality of the resized new image.
				0, // Is the degree of clockwise rotation to apply to uploaded image.
				(uri) => {
					// Is the callBack function of the resized new image URI.
					resolve(uri);
				},
				"base64" // Is the output type of the resized new image.
			);
		});
	};

	const onSaveImage = (): void => {
		const blob = b64toBlob(displayData.image_url);
		setFile(blob);
		setShowPreview(false);
		setUploading(false);
		saveImage(blob);
	};

	const closeImageCropped = (): void => {
		setShowCropModal(false);
		setUploading(false);
		setFileChanged(false);
		setFile("");
		setDisplayData({
			image_url: "",
		});
	};

	const handleImageCropped = (blob: Blob, previewUrl: string): void => {
		console.log(blob);
		setFile(blob);
		setDisplayData({
			image_url: previewUrl,
		});
		setShowCropModal(false);
		setUploading(false);
		saveImage(blob);
	};

	function b64toBlob(dataURI: any) {
		const byteString = atob(dataURI.split(",")[1]);
		const ab = new ArrayBuffer(byteString.length);
		const ia = new Uint8Array(ab);

		for (let i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}
		return new Blob([ab], { type: "image/jpeg" });
	}

	const saveImage = (blob: Blob): void => {
		MedicalApi.getExemptionDocumentUrl(exemptionDocUrl.image_key !== "" ? exemptionDocUrl.image_key : undefined, fileName, blob.type)
			.then((response) => {
				setExemptionDocUrl({
					image_key: response.data?.docKey,
					signed_url: response.data?.signedUrl,
				});
			})
			.catch((err) => console.log(err));
	};

	const imageUpload = async (fileData: any) => {
		if (fileData.length >= 1 && fileData[0].type !== "application/pdf") {
			setUploading(true);
			const file = fileData[0];
			const image = await resizeFile(file);
			setFileChanged(true);
			setHasImage(true);
			setFileName(fileData[0].name);
			setDisplayData({
				image_url: image,
			});
			setShowPreview(true);
		} else {
			setUploading(true);
			const file = fileData[0];
			var fileReader = new FileReader();
			pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

			fileReader.onload = async function (e) {
				var typedarray = new Uint8Array(e.target!.result as ArrayBuffer);

				const loadingTask = pdfjsLib.getDocument(typedarray);
				const pdfDoc = await loadingTask.promise;

				const page = await pdfDoc.getPage(1);
				const viewport = page.getViewport({ scale: 1.0 });

				var canvas = document.createElement("canvas"),
					ctx = canvas.getContext("2d");
				var renderContext = {
					canvasContext: ctx as object,
					viewport: viewport,
				};

				canvas.height = viewport.height;
				canvas.width = viewport.width;

				const renderTask = page.render(renderContext);
				await renderTask.promise;

				const image = canvas.toDataURL("image/jpeg");
				setFileChanged(true);
				setHasImage(true);
				// setFileName(`${fileData[0].name.slice(0, fileData[0].name.indexOf('.'))}.jpg`)
				if (fileData[0].type === "image/jpeg") {
					setFileName(`${fileData[0].name.slice(0, fileData[0].name.indexOf("."))}.jpg`);
				} else if (fileData[0].type === "image/png") {
					setFileName(`${fileData[0].name.slice(0, fileData[0].name.indexOf("."))}.png`);
				}
				setDisplayData({
					image_url: image,
				});
				setShowPreview(true);
			};

			fileReader.readAsArrayBuffer(file);
		}
	};

	const submitExemption = async () => {
		const errorsArr: string[] = [];
		if (exemptionType === 3 && personInfo && personInfo.type !== "EMPLOYEE" && !hasImage) {
			// medical exemption, requires supporting docs if not an employee
			let drawerBody = document.getElementsByClassName("drawer")[0];
			drawerBody.scroll(0, 0);
			errorsArr.push("Please select a valid image");
			setErrorAlerts(errorsArr);
			return;
		}
		setLoading(true);
		let reqBody = {};
		if (exemptionDocUrl.image_key) {
			// if supporting docs
			reqBody = {
				type: exemptionType,
				reason: exemptionReason,
				imageKey: exemptionDocUrl.image_key,
				isExternalContractor: extContractorTicked,
				fileName: fileName,
				fileContentType: file.type,
				onBehalfMitId: props.personDetails?.mit_id,
				expiryDate: (exemptionType === 1 || exemptionType === 4)? expiryDate: undefined,
				exemptFrom: expiryFrom,
			};
		} else {
			// no supporting docs
			reqBody = {
				type: exemptionType,
				reason: exemptionReason,
				isExternalContractor: extContractorTicked,
				onBehalfMitId: props.personDetails?.mit_id,
				expiryDate: (exemptionType === 1 || exemptionType === 4)? expiryDate: undefined,
				exemptFrom: expiryFrom,
			};
		}
		// console.log('reqBody', reqBody)

		const haveUploadedDoc = async () => {
			// let fileNameInternal = fileName
			// if(file.type === 'image/jpeg' && fileName.substring(fileName.length-4) !== '.jpg'){
			//   fileNameInternal = `${fileName.substring(0,fileName.length-4)}.jpg`
			//   setFileName(fileNameInternal)
			// }
			// else if(file.type === 'image/png' && fileName.substring(fileName.length-4) !== '.png'){
			//   fileNameInternal = `${fileName.substring(0,fileName.length-4)}.png`
			//   setFileName(fileNameInternal)
			// }

			// saveImage()
			// console.log(exemptionDocUrl)
			// console.log(file)
			// console.log(fileNameInternal)
			if (file && fileChanged) {
				return await MedicalApi.uploadExemptionProof(exemptionDocUrl.signed_url, file, fileName);
			}
			return true;
		};
		// New exemption request POST
		// Submit a new exemption request
		const postExemptionReq = async () => await MedicalApi.postExemptionRequest(reqBody);
		// if (exemptionType === 3 /* Medical */) {
		if (hasImage) {
			// has supporting documentation
			haveUploadedDoc()
				.then((result) => postExemptionReq())
				.then((resp) => {
					if (resp.error) {
						errorsArr.push("An error occured while uploading the request.");
						setLoading(false);
						setErrorAlerts(errorsArr);
						return;
					} else {
						// setReloadVaccineData(true)
						// setIsExemptionReqSubmitted(true)
						// setActiveDrawer(ActiveDrawer.None)
						setLoading(false);
            props.setReFetchPersonExemption(true)
						props.done();
					}
				})
				.catch((err) => {
					errorsArr.push("An error occured while uploading the request.");
					setLoading(false);
					setErrorAlerts(errorsArr);
					return;
				});
		} else {
			// dont have supporting documentation
			postExemptionReq()
				.then((resp) => {
					if (resp.error) {
						errorsArr.push("An error occured while uploading the request.");
						setLoading(false);
						setErrorAlerts(errorsArr);
						return;
					} else {
						// setReloadVaccineData(true)
						// setIsExemptionReqSubmitted(true)
						// setActiveDrawer(ActiveDrawer.None)
						setLoading(false);
            props.setReFetchPersonExemption(true)
						props.done();
					}
				})
				.catch((err) => {
					errorsArr.push("An error occured while uploading the request.");
					setLoading(false);
					setErrorAlerts(errorsArr);
					return;
				});
		}
	};

	const exemptionsBtnsData = props.holds?.exemptionTypes?.map((exemption, index) => {
		const isActive = exemptionType === exemption.id;
		const enabled = props.holds?.features?.includes(exemption.roleCreate) || props.holds?.features?.some((r) => exemption.roleApprove.includes(r));
		return (
			<span key={`exemptBtn-${exemption.id}`}>
				<button
					className="btn btn-primary elevation-0"
					id={isActive ? "exemption-btn-active" : "exemption-btn"}
					aria-label={exemption.name}
					onClick={() => {
						if (enabled) setExemptionType(exemption.id);
            // console.log(exemption.id)
					}}
					disabled={!enabled}>
					<Icon
						type="light"
						icon={exemption.icon.icon}
						// padded?= boolean
						// color?= string
						// onClick?= () => void
						// tabIndex?= number
						// hideAriaLabel?= boolean
					/>
					<span style={{ fontWeight: "bold", fontSize: "1em" }}>{exemption.name}</span>
					<br />
					<span style={{ fontSize: "0.8em" }}>{exemption.description}</span>
				</button>
			</span>
		);
	});

	const loadPerson = () => {
		if (props.personDetails) {
			MedicalApi.fetchPerson(props.personDetails.krb_name).then((resp) => {
				setPersonInfo(resp.data);
			});
		}
	};

	const ImageUploadComponents = () => {
		return (
			<>
				<ImageUpload
					// onChange={file => null}
					onChange={(file) => {
						if (file.length > 0) {
							imageUpload(file);
						} else {
							setUploading(false);
						}
					}}
					text={props.holds?.appText?.find((text) => text.id === "vaccine.exemption.support-upload.title")?.text || ""}
					label={"Exemption supporting documentation"}
					noClear={false}
					imageUrl={displayData.image_url}
					// imageUrl={exemptionDocUrl.signed_url}
					isLoading={isUploading}
					editButtonAriaLabel={"Edit Uploaded image."}
					displayButtons={true}
				/>
				<TemplateModal
					name="imagePreview"
					show={showPreview}
					autoHeight
					// // onClose={() => onCloseCropModal()}
					bodyAlignment={TemplateModalAlignment.Center}
					size={TemplateModalSize.Large}
					header={<Text content={"Image preview"} type={TextType.Heading2} />}
					body={
						<>
							<img src={displayData.image_url} style={{ maxWidth: "100%", maxHeight: "65vh" }} alt="" />
						</>
					}
					footer={
						<>
							<ButtonBar
								buttons={[
									{
										padded: true,
										icon: "crop",
										type: ButtonType.Ghost | ButtonType.Secondary,
										text: "Crop Image",
										onClick: () => {
											setShowCropModal(true);
											setShowPreview(false);
										},
										customAriaLabel: "Crop Image.",
									},
									{
										padded: true,
										icon: "save",
										text: "Save Image",
										onClick: () => onSaveImage(),
										customAriaLabel: "Save Image.",
									},
								]}
							/>
							{/* <div  className="mobile-only" style={{paddingBottom: '3rem', width: '100%'}} /> */}
							{/* {mobile && <div style={{ paddingBottom: '3rem', width: '100%' }} />} */}
						</>
					}
				/>
				<ImageCrop showCropModal={showCropModal} uploadImage={displayData.image_url} onCloseCropModal={closeImageCropped} handleCropImage={handleImageCropped} />
			</>
		);
	};

	return (
		<>
			{props.show && (
				<Drawer
					show={props.show}
					onClose={() => {
						// setShowFileUpload(false)
						props.onClose();
					}}
					className="vaccine-request-drawer"
					contents={
						<div>
							{isLoading ? (
								<ActionList isLoading items={[]} />
							) : (
								<>
									{errorAlerts &&
										errorAlerts.length > 0 &&
										errorAlerts.map((er, index) => (
											<div key={`er-${index}`}>
												<Alert text={er} type="error" />
											</div>
										))}
									{props.personDetails && (
										<>
											<Spacer />
											<strong>Person: </strong>
											{`${props.personDetails?.first_name} ${props.personDetails?.last_name} (${props.personDetails?.krb_name})`}
										</>
									)}
									<hr className="regular" />
									<div className="btn-padded">{exemptionsBtnsData}</div>
									<hr className="regular" />
									<Label target="exemptionReason" text="Explain The Request" />
									<TextArea
										name="exemptionReason"
										placeholderText={exemptionReason}
										properties={{
											"aria-label": "Explain your request",
											value: exemptionReason,
											id: "exemptionReason",
										}}
										rows={5}
										onChange={(e: any) => setExemptionReason(e.target.value)}
									/>
									<Spacer />
									{(exemptionType === 1 || exemptionType === 4)?
                  <div className="form-group row">
                    <label htmlFor='form-date' className="col-sm-4 text-right col-form-label">Expiry Date</label>
                    <div className="col-sm-8">
                      <CalendarInput minDate={tomorrow} onChange={(e:string) => setExpiryDate(e)} id={"from-date"}/>
                    </div>
                  </div>:<></>}
                  
                  <div className="form-group row">
                    <label className="col-sm-4 text-right col-form-label">Exempted From</label>
                    <div className="col-sm-8">
                      <Dropdown
                        width={50}
                        text={""}
                        value={exemptionFroms.find((exemptionFrom) => exemptionFrom.enum === "COVID")?.text}
                        name="exemptedFrom"
                        dropdownItems={exemptionFroms.map((exemptionFroms) => {
                          return {
                            id: exemptionFroms.enum,
                            icon: "",
                            text: exemptionFroms.text,
                            state: DropDownState.None,
                          };
                        })}
                        onChange={(itm: any) => {
                          handleExpiryFromChange(itm.id);
                        }}
                      />
                    </div>
                  </div>

									{exemptionType && props.personDetails && personInfo && (
										<>
											<hr className="regular" />
											<Spacer />
											<Text type="h5" content="Supporting Documentation" align="center" />
											<Spacer size="2" />
											{personInfo.type === "EMPLOYEE" && exemptionType === 3 && (
												<Text
													align="left"
													content={props.holds?.appText?.find((text) => text.id === "vaccine.exemption.medical-support-upload.title")?.text || medicalExemptionText}
													italic
													padded
												/>
											)}
											{["image/jpeg", ""].includes(fileContentType) && <ImageUploadComponents />}
											{fileName && fileContentType && displayData && (
												<div className="text-center">
													{console.log(fileContentType)}
													{fileContentType !== "image/jpeg" && (
														<Button
															type={ButtonType.Primary}
															text={`Upload new file`}
															icon={"cloud-upload"}
															onClick={() => {
																setFileName("");
																setFileContentType("");
																setDisplayData({});
															}}
														/>
													)}
												</div>
											)}
											<div style={{ maxWidth: 750, margin: "20px auto 0px" }}>
												<Spacer />
												<hr className="regular" />
												{!["Current Employee", "Current Student"].includes(personInfo.personType) && (
													<>
														<Spacer />
														<Checkbox
															label={props.holds?.appText?.find((text) => text.id === "vaccine.exemption.extcontractor")?.text || ""}
															name="Exemption Agreement"
															onClick={() => setExtContractorTicked((extContractorTicked) => !extContractorTicked)}
															checked={extContractorTicked}
															properties={{
																"aria-label": "Exemption Agreement",
															}}
														/>
														<hr className="regular" />
													</>
												)}
												{/* {exemptionType === 2 && (
                          <>
                            <Spacer />
                            <Text align="left" content={appText[COVID_TEXTS["vaccine.exemption.religious.disclaimer"]] || religiousExemptText} italic padded />
                            <Checkbox
                              label={appText[COVID_TEXTS['vaccine.exemption.religious.agreement']] || religiousExemptLabel}
                              name="Religious Agreement"
                              onClick={() => setReligiousTicked(religiousTicked => !religiousTicked)}
                              checked={religiousTicked}
                              properties={{
                                'aria-label': 'Religious Agreement'
                              }}
                            />
                            <Spacer />
                            <hr className="regular" />
                          </>
                        )}
                        <Spacer /> */}
												{/* <Checkbox
                          label={appText[COVID_TEXTS['vaccine.exemption.agreement']]}
                          name="Exemption Agreement"
                          onClick={() => setAgreementTicked(agreementTicked => !agreementTicked)}
                          checked={agreementTicked}
                          properties={{
                            'aria-label': 'Exemption Agreement'
                          }}
                        /> */}
												<Button type={ButtonType.Primary} text="Submit" onClick={submitExemption} state={expiryFrom ? ButtonState.Enabled : ButtonState.Disabled} />
											</div>
										</>
									)}
								</>
							)}
						</div>
					}
					size={DrawerSize.Medium}
					footer={[]}
					header={<Text type={TextType.Heading3} content={"Vaccine Exemption"} />}
					layout={DrawerLayout.Hero}
					type={DrawerType.Right}
				/>
			)}
		</>
	);
};

export const VaccineExemptionRequestDrawer = inject("searchUser", "covidNav", "holds", "roles")(observer(VaccineExemptionRequestDrawerComponent));
