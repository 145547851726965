import React from "react";
import $ from 'jquery';

import { Button, ButtonType } from "..";

export interface HelpIconProps
{
    title: string;
    description: string;
    embedded: boolean;
}

export default class HelpIcon extends React.Component<HelpIconProps>
{   
    componentDidMount() {
        const button = $('#help-icon-button');
        if(button){
            //@ts-ignore
            $(button).tooltip('dispose');

            //@ts-ignore
            $(button).popover({
                content: this.props.description || 'unconfigured',
                title: this.props.title || 'unconfigured',
                trigger: 'focus'
            });
        }
    }

    render(): React.ReactNode
    {
        return (
            <span className="help-icon">
                <Button id="help-icon-button" type={ButtonType.IconNaked} text="Help" icon={"question-circle"} />
            </span>
        );
    }
}