import * as React from 'react';
import { Row, Col } from "react-bootstrap";
import {FunctionComponent, useRef, useState} from "react";
import medicalApi, { FetchFollowUpsResponse, FluShotDetailResponse } from "../../api/MedicalApi";
import * as Loader from 'react-loader';
import moment = require("moment");
import {CONTACT_SUPPORT_LINK, mediumLoaderOptions} from "../../common/Defaults";
import { Drawer, TextType, Text, DrawerType, Button, ButtonType, ButtonState, Alert, DrawerSize, BulkUploadField, BulkUpload } from '@mit/hui';
import {peopleStore} from "../../model/PeopleStore";
import {covidNav} from "../../model/CovidNav";

const UploadModalTests: FunctionComponent<any> = ({hideModalCallback, show, onClose}) => {

    const [uploadSuccess, setUploadSuccess] = useState<boolean | undefined>(undefined)
    const [isLoading, setIsLoading] = useState(false)

    const [uploadFile, setUploadFile]: any = useState({name: '', file: null});

    const fileUploadedInput: any = useRef(null);

    const handleClose = () => {
        setUploadFile({name: '', file: null});
        if (fileUploadedInput.current) {
            fileUploadedInput.current.value = '';
        }
        onClose();
    }

    const submitAndClose = async () => {
        if (uploadFile.name) {
            setIsLoading(true)
            let uploadResponse = await medicalApi.uploadFile(uploadFile)
            setIsLoading(false)
            if (!uploadResponse.error) {
                setUploadSuccess(true)
            } else {
                setUploadSuccess(false)
            }
        } else {

        }
    }

    const handleFileUpload = async (data: any) => {

        let csvContent = 'MIT_ID,TEST_DATE,TEST_RESULT,IS_FLU_SHOT,BODY_REGION (OPTIONAL),LOT_NUMBER (OPTIONAL),TEST_BY (OPTIONAL),SOURCE\r\n';

        data.forEach((rowArray: any) => {

            if (!rowArray[2] || rowArray[2] == null)
                rowArray[2] = '12:00';
            else {
                let time = rowArray[2];
                let momentTime = moment(time, ['h:m a', 'hh:mm a', 'H:m', 'HH:mm']);
                //I apologise to whomever sees this. The backend sometimes converts to UTC. Sometimes it doesn't. 
                //Here is an example of that. On completed tests it converts to UTC, on flushots it doesn't. But the same modal handles both uploads.
                //Again. I'm truly sorry you have to deal with this - I was not given the time to fix it, yet another emergent feature was dreamt up
                if(!window.location.pathname.includes("/completed-tests"))
                    rowArray[2] = momentTime.utc().format('HH:mm');
                else 
                    rowArray[2] = momentTime.format('HH:mm')
            }
            rowArray[1] = rowArray[1] + ' ' + rowArray[2];
            rowArray.splice(2, 1);
            
            if (!window.location.pathname.includes("/completed-tests")) {
                rowArray[2] = 0;
            }

            let row = rowArray.join(",");
            csvContent += row + "\r\n";
        });

        var blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

        let uploadResponse = await medicalApi.uploadFileResults("upload.csv", blob)
        setIsLoading(false)

        if (!uploadResponse.error) {
            setUploadSuccess(true);

            if (covidNav!.activePage === 'completed-tests') {
                reloadDataCompleted();
            }
            if (covidNav!.activePage === 'flushots') {
                reloadDataFluShots();
            }
        } else {
            setUploadSuccess(false)
        }
    }

    const reloadDataCompleted = async () => {
        covidNav!.globalTableIsLoading = true;
        const resultsResponse: FetchFollowUpsResponse = await medicalApi.fetchPersonResults(covidNav.currentDateString)
        if (resultsResponse.error) {
            //todo: handle error
        } else {
            if (resultsResponse.items) {
                peopleStore.setCompletedList(resultsResponse.items)
                covidNav!.globalTableIsLoading = false;
            }
        }
      }
  
    const reloadDataFluShots = async () => {
      covidNav!.globalTableIsLoading = true;
      const fluShotDetailResponse: FluShotDetailResponse = await medicalApi.fetchFluShotResults(covidNav.currentDateString)
      if (fluShotDetailResponse.error) {
          //todo: handle error
      } else {
          if (fluShotDetailResponse.items) {
              peopleStore.setFluShotList(fluShotDetailResponse.items)
              covidNav!.globalTableIsLoading = false;
          }
      }
    }

    const fieldData: Record<string, BulkUploadField> = {
        'MIT_ID': { required: true, pattern: { value: /^\d{9,12}$/, message: "Time format" } },
        'TEST_DATE': { required: true, type: "calendar", pattern: { value: /^(\d{4})[\-](\d{2})[\-]\d{2}$/, message: "Date format" } },
        'TEST_TIME': { type: "time", pattern: { value: /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]\s?([AaPp][Mm])?$/, message: "Time format" } },
        'TEST_RESULT': { required: window.location.pathname.includes("/completed-tests"), pattern: { value: /^(1|(\-1)|0)$/, message: "Boolean format" } },
        'IS_FLU_SHOT': { required: true, pattern: { value: /^(1|0)$/, message: "Boolean format" } },
        'BODY_REGION': {},
        'LOT_NUMBER': {},
        'TEST_BY': {},
        'SOURCE': {}
        }

    return (
        <>
            <Drawer
                contentAriaLabel="Upload Tests"
                header={<Text content="Upload Tests File" type={TextType.Heading3} icon="" />}
                type={DrawerType.Right}
                themedColor={"medical"}
                show={show}
                onClose={onClose}
                size={DrawerSize.Medium}
                footer={[]}
                contents={
                    <>
                        <Loader loaded={!isLoading} options={{ ...mediumLoaderOptions, color: '#000000' }}>
                            <Row>
                            <BulkUpload themedColor={"medical"} autoSave={false} onImport={handleFileUpload} formFields={fieldData} showTemplate={true} />
                                <Col xs={12}>
                                   
                                    {uploadSuccess === false &&
                                        <Alert type="warning" text={`An error occurred uploading the file. If the problem persists please contact ${CONTACT_SUPPORT_LINK}.`} />}
                                    {uploadSuccess === true && <Alert type="success" text="The file has been uploaded successfully." />}
                                </Col>
                            </Row>
                        </Loader>

                        <div className="text-center">
                            {uploadSuccess &&
                                <div className="p-3"><Button type={ButtonType.Primary | ButtonType.Ghost} onClick={handleClose} state={isLoading ? ButtonState.Disabled : ButtonState.Enabled} text="Done" /></div>}
                            {uploadSuccess !== true &&
                                <div className="p-3"><Button type={ButtonType.Primary | ButtonType.Ghost} onClick={handleClose} state={isLoading ? ButtonState.Disabled : ButtonState.Enabled} text="Cancel" /></div>}
                        </div>
                    </>
                }
            />
        </>
    )

    
}

export default UploadModalTests
