import React, { useCallback, Fragment, useState, useEffect, FC } from 'react';
import { FileError, useDropzone } from 'react-dropzone';
import { ButtonType, ButtonProps, ButtonState } from '../Button/Button';
import { ButtonBar, ButtonBarType } from '../Button';
import { Text } from '../..';
import { FileIcon } from './FileIcon';

interface FileValue {
  name: string;
  type: string;
}

type MimeTypes = 'audio' | 'application' | 'video' | 'image' | 'text' | 'font'
type CodeTypes = "file-too-large" | "file-too-small" | "too-many-files" | "file-invalid-type";

export interface CFileError {
  type: MimeTypes;
  code: CodeTypes;
  message: string;
}
interface IFileUploadProps {
  acceptedMimeTypes: string[];
  isLoading?: boolean;
  onConfirm: (file: any) => void;
  onChange?: (files: File[]) => void;
  maxSize?: number;
  maxSizeErrorText?: string;
  customErrorText?: CFileError[]
  text?: string;
  multiple?: boolean;
  displayButtons?: boolean;
  clearFilesAfterConfirm?: boolean;
  disabled?: boolean;
  initialValue?: FileValue[];
}

export const FileUpload: FC<IFileUploadProps> = (props) => {
  const [myFiles, setMyFiles] = useState<File[]>([]);
  const [myConfirmedFiles, setMyConfirmedFiles] = useState<File[]>((props.initialValue as File[]) || []);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    //@ts-ignore
    if (props.isLoading !== undefined) setLoading(props.isLoading);
  }, [props.isLoading]);

  useEffect(() => {
    //@ts-ignore
    props.onChange && props.onChange(myConfirmedFiles);
  }, [myConfirmedFiles]);

  useEffect(() => {
    //@ts-ignore
    if (props.initialValue) {
      setMyConfirmedFiles(props.initialValue as File[]);
    }
  }, [props.initialValue]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      //@ts-ignore
      if (props.displayButtons === false) {
        // setMyConfirmedFiles([...myFiles, ...acceptedFiles]);
        acceptedFiles.map((file: File) => {
          onSubmit(file);
        });

        if (props.clearFilesAfterConfirm) {
          props.onChange && props.onChange(acceptedFiles);
        }
      } else {
        setMyFiles([...myFiles, ...acceptedFiles]);
      }
    },
    [myFiles]
  );

  const acceptedMimeTypesString = props.acceptedMimeTypes.join(',');
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: acceptedMimeTypesString,
    maxSize: props.maxSize,
    multiple: props.multiple,
    onDrop,
    disabled: props.disabled
  });

  const removeFile = (file: File) => () => {
    const newFiles = [...myFiles];
    //@ts-ignore
    newFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(newFiles);
  };

  const removeConfirmedFile = (index: number) => {
    const newFiles = [...myConfirmedFiles];

    newFiles.splice(index, 1);
    setMyConfirmedFiles(newFiles);
  };

  const removeAll = () => {
    setMyFiles([]);
    setMyConfirmedFiles([]);
  };

  const onSubmit = (file: File) => {
    props.onConfirm(file);
    if (props.clearFilesAfterConfirm) {
      removeAll();
    } else {
      setMyConfirmedFiles((prev) => [...prev, file]);
      setMyFiles((prev) => {
        const newFiles = [...prev];
        //@ts-ignore
        newFiles.splice(newFiles.indexOf(file), 1);
        return newFiles;
      });
    }
  };

  // We need this upload control to show an icon per mime type, for upload needs great ux because it can be real stupid.
  //NB We need this one to be for SINGLE file uploads only, not multiple!!!!
  const files = myFiles.map((file: any) => {
    const defaultButtonData: ButtonProps[] = [
      {
        icon: 'times',
        text: '',
        type: ButtonType.Secondary | ButtonType.Ghost,
        onClick: removeFile(file),
        state: ButtonState.Enabled,
      },
      {
        icon: 'check',
        text: 'Confirm',
        type: ButtonType.Secondary,
        onClick: () => onSubmit(file),
        state: ButtonState.Enabled,
      },
    ];

    return (
      <Fragment>
        <div className='dropzone confirm-area'>
          <div key={file.path}>
            <i className='fal fa-file-spreadsheet'></i>
            <div className='py-2'>
              <b>{file.path}</b>
              <br />
              {file.size}
            </div>
          </div>
          {(props.displayButtons === undefined || props.displayButtons) && (
            <div className='controls'>
              <ButtonBar type={ButtonBarType.Padded} buttons={defaultButtonData} />
            </div>
          )}
        </div>
      </Fragment>
    );
  });

  const customErrorMessages = (file: File, error: FileError): React.ReactNode => {
    if (props.customErrorText !== undefined) {
      for (let i = 0; i < props.customErrorText.length; i++) {
        const err = props.customErrorText[i]
        if (error.code === err.code && file.type.includes(err.type)) error.message = err.message
      }
    }
    return <Text content={props.maxSizeErrorText ? props.maxSizeErrorText : error.message} color={'red'} />
  }

  const fileRejectionItems = fileRejections.map(({ file, errors }) => {
    const invalidType = errors.find(item => item.code === 'file-invalid-type')
    if (invalidType !== undefined) {
      return <>{customErrorMessages(file, invalidType)}</>
    } else {
      return (
        <>{errors.map((e) => customErrorMessages(file, e))}</>
      )
    }
  });

  return (
    <React.Fragment>
      {!loading ? (
        files.length === 0 ? (
          <>
            <div {...getRootProps({ className: `dropzone upload-area ${props.disabled ? ' disabled' : ''}` })}>
              <input {...getInputProps()} />
              <div className='content'>
                <i className='fal fa-paperclip'></i>
                {/* Needs to be our icon component */}
                <div>{props.text}</div>
              </div>
              <aside>{fileRejectionItems}</aside>
            </div>
          </>
        ) : (
          files
        )
      ) : (
        <div className='dropzone loading'>
          <div className='content'>
            <div className='icon'>
              <i className='fas fa-cloud-upload-alt'></i>
              {/* Needs to be our icon component */}
            </div>
            <div className='text'>Processing...</div>
          </div>
        </div>
      )}
      {!!myConfirmedFiles.length && (
        <div className='d-flex flex-wrap mt-3 uploaded-files'>
          {myConfirmedFiles.map((file: File, index: number) => (
            <FileIcon
              text={file.name}
              type={file.type}
              onRemove={() => {
                // alert(`Remove ${file.name} ${index}`);
                removeConfirmedFile(index);
              }}
              showRemoveIcon
            />
          ))}
        </div>
      )}
    </React.Fragment>
  );
};

FileUpload.defaultProps = {
  text: 'Drop your file(s) here',
  isLoading: false,
  maxSize: 200000000,
  multiple: false,
  clearFilesAfterConfirm: true,
  initialValue: [],
};
