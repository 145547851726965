import React, {FC, Fragment, useEffect} from 'react';
import ReactDOM from 'react-dom'
import { FormContainer } from './OldForm/FormContainer';
import { FormRowProps, FormRowType } from './OldForm/FormRow';
import Label from './Label';
import { Textbox } from '../components/Textbox';
import Text, { TextType } from '../components/Text';
import { TextArea } from '../components/TextArea';
import StarRating from '../components/StarRating';
import { FormType } from './OldForm/Form';

export interface FeedbackProps
{
    name: string,
    onSubmit?: any,
    actionText?: string;
    formName?: string,
    formEmail?: string,
    formComment?: string,
    show: boolean,
    onClose: any
}

export interface FeedbackState
{
    name: string,
    email: string,
    stars: string,
    comment: string;
    submitting: boolean;
}

export const textAreaData = {
    placeholderText: 'Type something here'
};

const Backdrop: FC<any> = (props) =>
{
    useEffect(() => {
        document.getElementById("root")?.classList.add("modal-open");
        return document.getElementById("root")?.classList.remove("modal-open");
        //eslint-disable-next-line
    }, [])

    return props.show ? <div className={"Backdrop"} onClick={() =>
    {
        if (props.onClose)
        {
            document.getElementById("root")?.classList.remove("modal-open");
            props.clicked();
        }
    }}></div> : null
}

export default class Feedback extends React.Component<FeedbackProps, FeedbackState>
{
    public static defaultProps =
        {

        };

    private _container: any;
    private _child: any;

    constructor(props: FeedbackProps)
    {
        super(props);

        this.state = {
            name: this.props.formName ? this.props.formName : "",
            email: this.props.formEmail ? this.props.formEmail : "",
            stars: "0",
            comment: this.props.formComment ? this.props.formComment : "",
            submitting: false
        }
    }

    handleEmailChange = (e: any) =>
    {
        this.setState({ email: e.target.value });
    }

    handleNameChange = (e: any) =>
    {
        this.setState({ name: e.target.value });
    }

    handleCommentChange = (e: any) =>
    {
        this.setState({ comment: e.target.value });
    }

    handleStarsChange = (e: any) =>
    {
        this.setState({ stars: e });
    }

    componentDidMount() 
    {
        this._container = document.createElement("div");
        document.documentElement.appendChild(this._container);

        this._child = ReactDOM.render(this.renderComponent(), this._container);
    }

    componentDidUpdate()
    {
        ReactDOM.hydrate(this.renderComponent(), this._container)
        if (!this._child) return;

        this._child.setState({});
    }

    componentWillUnmount()
    {
        ReactDOM.unmountComponentAtNode(this._container);
        document.documentElement.removeChild(this._container);
    }

    getClasses(): string
    {
        var classes: string[] = [];

        if (this.props.show)
            classes.push('show');

        return classes.join(' ');
    }

    render(): React.ReactNode
    {
        return null;
    }

    defaultFormData: FormRowProps[] = [
        {
            label: <Label text='Name' />,
            control: [<Textbox onChange={this.handleNameChange} name="name" value={this.props.formName} placeholderText="Name" required={true} />]
        }
        ,
        {
            label: <Label text='Email' />,
            control: [<Textbox onChange={this.handleEmailChange} pattern={{ "value": /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, "message": "Email format" }} name="email" value={this.props.formEmail} placeholderText="Email" required={true} />]
        }
        ,
        {
            label: <Label text='How do you rate this app?' />,
            control: [<StarRating onActionClick={this.handleStarsChange} />]
        }
        ,
        {
            label: <Label text='Comments, Suggestion or Feedback' />,
            control: [<TextArea onChange={this.handleCommentChange} name="comments" value={this.props.formComment} rows={5} {...textAreaData} properties={{ "aria-label": textAreaData.placeholderText }} />]
        }
    ];


    renderComponent(): React.ReactElement
    {
        if (this.props.show)
        {
            this._container.className = "modal-wrapper modal-show"
        }
        else
        {
            document.getElementById("root")?.classList.remove("modal-open")
            this._container.className = "modal-wrapper"
        }

        return (
            <React.Fragment>
                {
                    this.props.show
                    &&
                    <Fragment>
                        <Backdrop show={this.props.show} clicked={() => { this.props.onClose && this.props.onClose() }} onClose={this.props.onClose} />
                        <div className={`modal fade ${this.getClasses()}`} tabIndex={-1} role="dialog" id={`${this.props.name}`} aria-labelledby={`${this.props.name}-label`} aria-hidden="true">
                            <div className={`modal-dialog`} role="document">
                                <div className={`modal-content`}>
                                    <div className={`modal-header`}>
                                        <Text content="We'd love to hear from you!" type={TextType.Heading4} icon="" />
                                        {
                                            this.props.onClose
                                            &&
                                            <button type="button" className={`close`} data-dismiss="modal" aria-label="Close" onClick={() => { document.getElementById("root")?.classList.remove("modal-open"); this.props.onClose() }}>
                                                <i className={`far fa-times`}></i>
                                            </button>
                                        }
                                    </div>
                                    <div className={`modal-body `}>
                                        <FormContainer
                                            disabled={this.state.submitting}
                                            actionText={this.state.submitting ? this.props.actionText : ""}
                                            onSubmit={(e: any) =>
                                            {
                                                var feedbackResponse = {
                                                    valid: e,
                                                    name: this.state.name,
                                                    email: this.state.email,
                                                    stars: this.state.stars,
                                                    comment: this.state.comment
                                                }

                                                if (e)
                                                {
                                                    this.setState({ submitting: true })
                                                }

                                                this.props.onSubmit(feedbackResponse);
                                            }} formRows={this.defaultFormData} rowType={FormRowType.OnTop} formType={FormType.Input} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                }

            </React.Fragment>
        );
    }
}