import React, { Fragment } from 'react';
import 'bootstrap/dist/js/bootstrap.js';
import '../../styles/css/boston-bounce-2.min.css';
import { NavBar } from '../Nav';
import Text, { TextType } from '../Text';
import GoogleAnalytics from '../GoogleAnalytics';
import Div100vh from 'react-div-100vh';
import { Button, ButtonProps } from '../Button';
import { getColorClass, GroupColorOptions } from '../Cards/CategoryCard';

export interface ELOPageProps {
    name: string;
    navigation?: NavBar | any;
    content: any;
    environment?: ProductionEnvironment;
    theme?: ELOTheme;
    profile?: any;
    customLogo?: any;
    onLogoClick?: (event: React.MouseEvent) => void;
    hero?: ELOHeroProps;
    gaId: string;
    utilMaxWidth?: number;
    utilMinWidth?: number;
    wrapperClass?: string;
}

export type ELOTheme = 'default' | 'mit' | 'medical' | 'medical-dark' | 'max-contrast' | 'ruby';

export type ProductionEnvironment = 'production' | 'staging' | 'dev' | 'admin' | 'superuser' | 'helpdesk' | 'online' | 'offline';

export interface ELOFeedbackProps {
    name: string;
    icon: string;
    onSubmit: any;
    formName?: string;
    formEmail?: string;
    formComment?: string;
}

export interface ELOHeroProps {
    title: string;
    subTitle: string;
    groupColorClass: GroupColorOptions;
    showPrimaryButton?: boolean;
    primaryButtonProps?: ButtonProps;
    primaryButtonSubText?: string;
    showBackButton?: boolean;
    backButtonProps?: ButtonProps;
    index?: number;
}

export default class ELOPage extends React.Component<ELOPageProps> {
    /* constructor(props: ELOPageProps) {
        super(props);
    } */

    componentDidMount() {
        if (this.props.navigation && this.props.navigation.props && this.props.navigation.props.tabs && this.props.navigation.props.tabs.length === 1)
            document.getElementsByClassName('elo-theme')[0].classList.add('single-item');
    }

    public static defaultProps = {
        name: 'New Application',
        theme: 'default',
    };

    getClasses(): string {
        var classes: string[] = [];

        if (this.props.theme === 'default') {
            classes.push('elo-bounce');
        }
        if (this.props.theme === 'medical') {
            classes.push('medical');
        }
        if (this.props.theme === 'medical-dark') {
            classes.push('medical-dark');
        }

        if (this.props.theme === 'max-contrast') {
            classes.push('max-contrast');
        }

        if (this.props.wrapperClass) classes.push(this.props.wrapperClass);

        return classes.join(' ');
    }

    getTopContainerStyle(): any {
        if (!!this.props.hero) return { height: '20rem', display: 'flex', 'flex-direction': 'column' };

        return {};
    }

    getHeroStyle(): any {
        return {
            'margin-top': 'auto',
            display: 'flex',
            'justify-content': 'space-between',
            padding: '1rem 2rem',
        };
    }

    render(): React.ReactNode {
        return (
            <Fragment>
                <GoogleAnalytics gaId={this.props.gaId} />

                <Div100vh>
                    <div className={`${this.getClasses()}`}>
                        <span className='mobile-handle'>
                            <i className='fas fa-chevron-down'></i>
                        </span>
                        <div className={`${!!this.props.hero ? `elo-top-container ${getColorClass(this.props.hero.groupColorClass, this.props.hero.index)}` : ''}`}>
                            <div className={`elo-navbar navbar-default`}>
                                <div className='navbar-brand logo'>
                                    <div>
                                        {this.props.customLogo ? (
                                            this.props.customLogo
                                        ) : (
                                            <a onClick={(e: React.MouseEvent) => this.props.onLogoClick && this.props.onLogoClick(e)} className='siteheader'>
                                                <svg
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    width='160'
                                                    height='83'
                                                    viewBox='0 0 160 83'
                                                    aria-labelledby='mit_elo_logo_title'
                                                    style={{
                                                        display: 'inline-block',
                                                        marginRight: '32px',
                                                        width: '80px',
                                                        height: 'auto',
                                                    }}
                                                >
                                                    <title id='mit_elo_logo_title'>MIT</title>
                                                    <path
                                                        fill='#000'
                                                        d='M113.837993,16.5384335 L113.837993,0 L159.902669,0 L159.902669,16.5384335 L113.837993,16.5384335 Z M28.4984305,56.5296737 L28.4984305,0 L45.940093,0 L45.940093,56.5296737 L28.4984305,56.5296737 Z M56.9812882,82.7233131 L56.9812882,0 L74.4385235,0 L74.4385235,82.7077402 L56.9968611,82.7077402 L56.9812882,82.7233131 Z M0,82.7077402 L0,0 L17.4572353,0 L17.4572353,82.7077402 L0,82.7077402 Z M0,82.7077402 L0,0 L17.4572353,0 L17.4572353,82.7077402 L0,82.7077402 Z M113.962576,82.7077402 L113.962576,26.1936394 L131.419812,26.1936394 L131.419812,82.7233131 L113.962576,82.7233131 L113.962576,82.7077402 Z M85.3395625,16.5384335 L85.3395625,0 L102.936954,0 L102.936954,16.5384335 L85.3395625,16.5384335 Z'
                                                    ></path>
                                                    <rect id='mit_elo_logo_color' width='17.46' height='56.51' x='85.5' y='26.12' fill='#e00'></rect>
                                                </svg>
                                            </a>
                                        )}
                                    </div>

                                    <div className='appName'>{this.props.name}</div>

                                    <div className='flex-line-break'></div>

                                    {this.props.environment === 'dev' && <div className='badge badge-info'>Dev</div>}
                                    {this.props.environment === 'staging' && <div className='badge badge-info'>Staging</div>}
                                    {this.props.environment === 'admin' && <div className='badge badge-info'>Admin</div>}
                                    {this.props.environment === 'superuser' && <div className='badge badge-info'>Super User</div>}
                                    {this.props.environment === 'helpdesk' && <div className='badge badge-info'>Help Desk</div>}
                                    {this.props.environment === 'online' && <div className='badge badge-success'>Online</div>}
                                    {this.props.environment === 'offline' && <div className='badge badge-danger'>Online</div>}
                                </div>
                                {this.props.navigation && (
                                    <div role='navigation' aria-label='primary' className='navbar-nav mx-auto mb-2 mb-lg-0'>
                                        {this.props.navigation}
                                    </div>
                                )}
                                <div className={`elo-theme ${this.getClasses()}`}>
                                    <div className='profile-hover'>{this.props.profile}</div>
                                </div>
                            </div>

                            {!!this.props.hero && (
                                <div className='elo-hero'>
                                    <div className='elo-hero-info'>
                                        {this.props.hero.showBackButton && <Button {...this.props.hero.backButtonProps} />}
                                        <Text type={TextType.Heading1} content={this.props.hero.title} />
                                        <Text type={TextType.Heading4} content={this.props.hero.subTitle} />
                                    </div>
                                    {this.props.hero.showPrimaryButton && (
                                        <div className='elo-hero-cta'>
                                            <Button {...this.props.hero.primaryButtonProps} />
                                            {!!this.props.hero?.primaryButtonSubText && <Text type={TextType.Heading5} content={this.props.hero?.primaryButtonSubText} />}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>

                        <section className='mainbar' role='main' aria-label='mainpagearea'>
                            {this.props.content}
                        </section>
                    </div>
                </Div100vh>
            </Fragment>
        );
    }
}
