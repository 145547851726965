import * as React from "react";
import { FC, useState, useEffect } from "react";
import { peopleStore } from "../../model/PeopleStore";
import medicalApi, { FluShotDetailResponse } from "../../api/MedicalApi";
import { inject, observer, Provider } from "mobx-react";
import { CovidNav } from "../../model/CovidNav";
import { FluShotsAdministered } from "./FluShotsAdministered";
import { Button, ButtonType, CalendarInput, Error, ErrorContext, ErrorType } from "@mit/hui";
import UploadModalTests from "../completedTests/UploadModalTests";
import { RolesInterface } from "../../model/Roles";

interface CompletedTestsComponentProps {
	covidNav?: CovidNav;
	roles?: RolesInterface;
}

const FluShotsComponent: FC<CompletedTestsComponentProps> = ({ covidNav, roles }) => {
	const [isLoading, setLoading] = useState(false);
	const [unAuth, setUnAuth] = useState(false);
	const [show, setShow] = useState(false);

	const handleShowUpload = () => {
		setShow(true);
	};

	const handleCloseUpload = () => {
		setShow(false);
	};

	useEffect(() => {
		if (peopleStore?.fluShotsList === undefined && isLoading === false) {
			setLoading(true);
			let searchDate = new Date();
			let dateString = `${searchDate.getFullYear()}-${searchDate.getMonth() + 1 < 10 ? "0" + (searchDate.getMonth() + 1) : searchDate.getMonth() + 1}-${
				searchDate.getDate() < 10 ? "0" + searchDate.getDate() : searchDate.getDate()
			}`;
			setLoading(true);
			covidNav!.currentDateString = dateString;
			medicalApi.fetchFluShotResults(dateString).then((fluShotDetailResponse: FluShotDetailResponse) => {
				if (fluShotDetailResponse.error) {
					setLoading(false);

					//todo: handle error
				} else if (fluShotDetailResponse.unauth) {
					setUnAuth(true);
				} else {
					if (fluShotDetailResponse.items) {
						setLoading(false);
						peopleStore.setFluShotList(fluShotDetailResponse.items);
					}
				}
			});
		}
	});

	const reloadDataCompleted = async (date?: string) => {
		setLoading(true);
		covidNav!.currentDateString = date!;
		const fluShotDetailResponse: FluShotDetailResponse = await medicalApi.fetchFluShotResults(date!);
		if (fluShotDetailResponse.error) {
			setLoading(false);
			//todo: handle error
		} else if (fluShotDetailResponse.unauth) {
			setUnAuth(true);
		} else {
			if (fluShotDetailResponse.items) {
				setLoading(false);
				peopleStore.setFluShotList(fluShotDetailResponse.items);
			}
		}
	};

	const getInitialCalendarValue = () => {
		let searchDate = new Date();
		let dateString = `${searchDate.getFullYear()}-${searchDate.getMonth() + 1 < 10 ? "0" + (searchDate.getMonth() + 1) : searchDate.getMonth() + 1}-${
			searchDate.getDate() < 10 ? "0" + searchDate.getDate() : searchDate.getDate()
		}`;
		return dateString;
	};

	if (unAuth) return <Error context={ErrorContext.Component} message="Not Authorized" type={ErrorType.AccessDenied} />;

	return (
		<React.Fragment>
			<div className="calendar-picker">
				<div className="row">
					<div className="col-xl-4"></div>
					<div className="col-xl-4">
						<CalendarInput onChange={(e: string) => reloadDataCompleted(e)} value={getInitialCalendarValue()} id="flushotDateSelector" />
					</div>
					<div className="col-xl-4">
						<div className="controls">{roles?.roles.includes("medical") && <Button type={ButtonType.Primary} text="Import Results" onClick={() => handleShowUpload()} />}</div>
					</div>
				</div>
			</div>
			<Provider peopleStore={peopleStore}>
				<FluShotsAdministered isLoading={isLoading} />
			</Provider>
			<UploadModalTests show={show} onClose={handleCloseUpload} />
		</React.Fragment>
	);
};

const FluShots = inject("covidNav", "roles")(observer(FluShotsComponent));
export default FluShots;
