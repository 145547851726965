import { elementType } from '../Table/types';

export interface TableFooterProps {
  classes?: string;
  component?: elementType;
  style?: any;
}

export const defaults: TableFooterProps = {
  classes: '',
  component: 'tfoot',
};
