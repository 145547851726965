import BaseController from "./BaseController";
import { ContactPerson, Note } from "../model/PeopleStore";
import moment = require("moment");
import { getResultText, toLocalTimeFromUct } from "../common/Formatters";
import authProvider from "./AuthProvider";

export const API_UPDATE_DATE_FORMAT = "YYYY-MM-DD HH:mm";

export interface GlobalResponse {
	items?: any;
	error?: any;
	unauth?: any;
}

export interface VaccineExemptionResponse {
	items?: VaccineExemptionDTO[];
	error?: any;
	unauth?: any;
}

export interface TextItem {
	id: string;
	text: string;
}

export interface DownloadFileResp {
	data?: string;
	error?: any;
}

export interface PostAttestationsResponse {
	success?: boolean;
	error?: any;
}

export interface FetchFollowUpsResponse {
	items?: ContactPerson[];
	error?: any;
	unauth?: any;
}

export interface FetchHoldsResponse {
	items?: HoldModel[];
	error?: any;
	unauth?: any;
}

export interface FetchExemptFlagsResponse {
	flags?: ExemptFlagModel;
	error?: any;
	unauth?: any;
}

export interface PostStatusResponse {
	success?: boolean;
	error?: any;
}

export interface ApiTests {
	cleared: boolean;
	created_by: string;
	symptoms_reported_on: string;
	phone_number: string;
	date_of_birth: string;
	gender: string;
	email: string;
	job_title?: string;
	department?: string;
	student_year?: string;
	display_name: string;
	test_date: string;
	has_symptoms: boolean;
	initial_test_scheduled: boolean;
	kerberos: string;
	mit_id: string;
	waiver_signed_on: string;
	test_guid: string | null;
}

export interface ApiPersonDetail {
	krb_name: string;
	mit_id: string;
	first_name: string;
	last_name: string;
	job_title?: string;
	student_year?: string;
	department?: string;
	email: string;
	phone: string;
	gender: string;
	date_of_birth: string;
	type: string;
	medical_schedule_irregular: boolean;
	initial_test_scheduled: boolean;
	address: ApiPersonAddress;
	resident_food_delivery: boolean;
	vaccine_status: string;
	vaccine_hold_status: string;
	resident: boolean;
}
export interface ApiPersonAddress {
	address1?: string;
	address2?: string;
	city?: string;
	state?: string;
	postal_code?: string;
}

export interface ApiResults {
	cleared: boolean;
	created_by: string;
	symptoms_reported_on: string;
	phone_number: string;
	date_of_birth: string;
	gender: string;
	email: string;
	job_title: string;
	department: string;
	student_year: string;
	display_name: string;
	test_date: string;
	has_symptoms: boolean;
	initial_test_scheduled: boolean;
	kerberos: string;
	mit_id: string;
	waiver_signed_on: string;
	test_guid: string | null;
	accession_number?: string;
	result: number;
	result_date: string;
	exported: number;
}

export interface FluShotDetailResponse {
	items?: FluShotDetail[];
	error?: any;
	unauth?: any;
}

export interface FluShotDetail {
	flu_shot_guid: string;
	mit_id: string;
	flu_shot_date?: string;
	body_region_name?: string;
	full_name?: string;
	test_by_mit_id?: string;
	test_by_full_name?: string;
	location_name?: string;
	lot_number?: string;
	gender?: string;
	date_of_birth?: string;
	phone_number?: string;
	email?: string;
}

export interface VaccineScheduleConfigResponse {
	items?: VaccineScheduleConfigList;
	error?: any;
	unauth?: any;
}

export interface VaccineScheduleConfigList {
	config_list: VaccineScheduleConfigItem[];
	end_date: string;
}

export interface VaccineScheduleConfigItem {
	active: boolean;
	is_open: boolean;
	capacity: number;
	close_time: string;
	day_of_week_name: string;
	day_of_week_number: number;
	open_time: string;
	schedule_config_guid: string;
	slot_duration_minutes: number;
}

export interface VaccineStandbyListResponse {
	items?: VaccineStandbyPerson[];
	error?: any;
	unauth?: any;
}

export interface VaccineStandbyPerson {
	vaccine_standby_guid: string;
	vaccine_course_guid: string;
	mit_id: string;
	standby_date: string;
	schedule_guid: string;
	accepted: boolean;
	active: boolean;
	vaccine_shot_guid: string;
	last_shot_number: number;
	shot_date: string;
	days_since: number;
	full_name: string;
	lot_number: string;
	lot_company: string;
	administered: boolean;
	completed: boolean;
}

export interface VaccineSummaryResponse {
	items?: VaccineSummaryList[];
	error?: any;
	unauth?: any;
}

export interface VaccineSummaryList {
	appointments: VaccineSummaryAppointment[];
	schedule: VaccineSummarySchedule;
}

export interface VaccineSummaryAppointment {
	full_name: string;
	mit_id: string;
	status: string;
}

export interface VaccineSummarySchedule {
	active: boolean;
	available_capacity: number;
	capacity: number;
	end_datetime: string;
	schedule_guid: string;
	start_datetime: string;
}

export interface ExemptionUploadUrlResponse {
	data?: ExemptionUploadUrl;
	error?: any;
}

export interface ExemptionUploadUrl {
	docKey: string;
	signedUrl: string;
}

export interface PersonInfo {
	dlc: any;
	dlcName: string;
	email: string;
	firstName: string;
	krbName: string;
	lastName: string;
	mitId: string;
	personType: string;
	phone: string;
	primaryAssignmentType: any;
	resident: boolean;
	type: string;
}

const convertApiPersonTestsToWebPerson = (apiItems: ApiTests[]): ContactPerson[] => {
	return apiItems.map((p: ApiTests) => {
		const cp: ContactPerson = {
			mitId: p.mit_id,
			name: p.display_name,
			kerberosId: p.kerberos,
			phoneNumber: p.phone_number,
			dateOfBirth: p.date_of_birth,
			jobTitle: p.job_title,
			department: p.department,
			studentYear: p.student_year,
			gender: p.gender ? p.gender.toLowerCase() : "",
			email: p.email,
			isCleared: p.cleared,
			initialTestScheduled: p.initial_test_scheduled,
			createdOn: toLocalTimeFromUct(p.symptoms_reported_on),
			createdBy: p.created_by,
			notes: [],
			nextTestDate: p.test_date,
			waiverSignedOn: p.waiver_signed_on ? toLocalTimeFromUct(p.waiver_signed_on) : undefined,
			testGuid: p.test_guid ? p.test_guid : undefined,
		};
		return cp;
	});
};

const getResult = (res: number): string => {
	switch (res) {
		case 1:
			return "Positive";
		case -1:
			return "Negative";
		case 0:
			return "Invalid";
	}
	return "";
};

const convertApiPersonResultsToWebPerson = (apiItems: ApiResults[]): ContactPerson[] => {
	return apiItems.map((p: ApiResults) => {
		const cp: ContactPerson = {
			mitId: p.mit_id,
			name: p.display_name,
			kerberosId: p.kerberos,
			phoneNumber: p.phone_number,
			dateOfBirth: p.date_of_birth,
			gender: p.gender ? p.gender.toLowerCase() : "",
			email: p.email,
			isCleared: p.cleared,
			initialTestScheduled: p.initial_test_scheduled,
			createdOn: toLocalTimeFromUct(p.symptoms_reported_on),
			createdBy: p.created_by,
			notes: [],
			nextTestDate: p.test_date,
			waiverSignedOn: p.waiver_signed_on ? toLocalTimeFromUct(p.waiver_signed_on) : undefined,
			testGuid: p.test_guid ? p.test_guid : undefined,
			result: getResult(p.result),
			resultDate: p.result_date ? toLocalTimeFromUct(p.result_date) : undefined,
			sampleId: p.accession_number,
			exported: p.exported && p.exported == 1 ? "Yes" : "No",
			testDate: p.test_date,
		};
		return cp;
	});
};

export interface FetchArrayResponse {
	items?: TextItem[];
	error?: any;
}

export interface FetchHoldReasonsResponse {
	items?: TextItem[];
	error?: any;
}

export interface FetchHoldStatusResponse {
	items?: TextItem[];
	error?: any;
}

export interface HoldModel {
	hold_guid: string;
	mit_id: string;
	kerberos: string;
	display_name: string;
	gender?: string;
	phone_number?: string;
	email?: string;
	job_title?: string;
	student_year?: string;
	department?: string;
	date_of_birth?: string;
	reason?: string;
	comments?: string;
	status?: string;
	created_on?: string;
	created_by?: string;
	updated_on?: string;
	updated_by?: string;
}

export interface ExemptFlagModel {
	medical: boolean;
	hr: boolean;
	international: boolean;
	temp: boolean;
	remote: boolean;
}

export interface FetchArrayResponse {
	items?: TextItem[];
	error?: any;
}

export interface FetchVaccineScheduleResponse {
	items?: VaccineSchedules[];
	unauth?: any;
	error?: any;
}

export interface VaccineSchedules {
	active: boolean;
	available_capacity: boolean;
	capacity: boolean;
	end_datetime: string;
	schedule_guid: string;
	selected: boolean;
	start_datetime: string;
}

export interface VaccineTypes {
	type_id?: number;
	name?: string;
	error?: any;
}

export interface VaccineCompany {
	company_name?: string;
	medical_vaccine_company_guid?: string;
	number_of_shots?: number;
	shot_days_interval?: number;
	error?: any;
}

export interface rejectionType {
	status: string;
	id: number;
	text: string;
	shortText: string;
}

export interface VaccineExemptionDTO {
	id: string;
	krbName: string;
	displayName: string;
	gender: string;
	phoneNumber: string;
	email: string;
	feedback: string;
	dateOfBirth: string;
	reason: string;
	type: number;
	typeName: string;
	imageKey: string;
	imageUrl: string;
	status: string;
	statusText: string;
	mitId: string;
	submissionDate: string;
	isExternalContractor: boolean;
	personAff: string;
	personType: string;
	exemptFrom?: string;
	expiryDate?: string;
}

export interface PersonInfoResponse {
	data?: PersonInfo;
	error?: any;
}

export interface PersonInfo {
	dlc: any;
	dlcName: string;
	email: string;
	firstName: string;
	krbName: string;
	lastName: string;
	mitId: string;
	personType: string;
	phone: string;
	primaryAssignmentType: any;
	resident: boolean;
	type: string;
}

class MedicalApi extends BaseController {
	readonly url_texts: string;
	readonly url_tests: string;
	readonly url_clear: string;
	readonly url_complete_tests: string;
	readonly url_results: string;
	readonly url_results_download: string;
	readonly url_results_upload: string;
	readonly url_eligible_upload: string;
	readonly url_vaccine_upload: string;
	readonly url_fetch_results: string;
	readonly url_pending_tests: string;
	readonly url_all_holds: string;
	readonly url_all_vaccine_holds: string;
	readonly url_post_hold: string;
	readonly url_hold_reasons: string;
	readonly url_person_holds: string;
	readonly url_hold_statuses: string;
	readonly url_patch_hold: string;
	readonly url_person_vaccine_holds: string;
	readonly url_vaccine_hold_statuses: string;
	readonly url_patch_vaccine_hold: string;
	readonly url_flu_shot_detail: string;
	readonly url_vaccine_detail: string;
	readonly url_vaccine_type: string;
	readonly url_rejection_type: string;
	readonly url_vaccine_company: string;
	readonly url_schedule_irregular: string;
	readonly url_vaccine_proof: string;
	readonly url_person_vaccine_proof: string;
	readonly url_vaccine_proof_admin: string;
	readonly url_all_vaccine_exemptions: string;
	readonly url_person_exemptions: string;
	readonly url_vaccine_exemptions: string;
	readonly url_vaccine_exemptions_pdf: string;
	readonly url_exemption_status: string;
	readonly url_exemption_auth: string;
	readonly url_exemption_documentation_upload: string;
	readonly url_exemption_request: string;
	readonly url_vaccine_documents: string;
	readonly url_get_person: string;
	readonly url_vaccine_clear: string;

	constructor() {
		super();
		this.url_clear = `${this.apiHost}/${this.apiPathCovid}/clear`;
		this.url_fetch_results = `${this.apiHost}/${this.apiPathMedical}/tests/results`;
		this.url_pending_tests = `${this.apiHost}/${this.apiPathMedical}/tests/pending`;
		this.url_tests = `${this.apiHost}/${this.apiPathMedical}/tests`;
		this.url_flu_shot_detail = `${this.apiHost}/${this.apiPathMedical}/tests/flushot`;
		this.url_complete_tests = `${this.apiHost}/${this.apiPathMedical}/tests/manual_complete`;
		this.url_results = `${this.apiHost}/${this.apiPathMedical}/results`;
		this.url_results_download = `${this.apiHost}/${this.apiPathMedical}/results/download`;
		this.url_results_upload = `${this.apiHost}/${this.apiPathMedical}/results/upload`;
		this.url_eligible_upload = `${this.apiHost}/${this.apiPathMedical}/results/upload_vaccine_eligible`;
		this.url_vaccine_upload = `${this.apiHost}/${this.apiPathMedical}/results/upload_vaccine`;
		this.url_all_holds = `${this.apiHost}/${this.apiPathMedical}/medical/hold`;
		this.url_texts = `${this.apiHost}/${this.apiPathMedical}/medical/texts`;
		this.url_post_hold = `${this.apiHost}/${this.apiPathMedical}/medical/hold`;
		this.url_hold_reasons = `${this.apiHost}/${this.apiPathMedical}/medical/hold/reasons`;
		this.url_person_holds = `${this.apiHost}/${this.apiPathMedical}/medical/hold`;
		this.url_hold_statuses = `${this.apiHost}/${this.apiPathMedical}/medical/hold/statuses`;
		this.url_patch_hold = `${this.apiHost}/${this.apiPathMedical}/medical/hold/`;
		this.url_schedule_irregular = `${this.apiHost}/${this.apiPathMedical}/medical/schedule_irregular`;
		this.url_all_vaccine_holds = `${this.apiHost}/${this.apiPathVaccine}/vaccine/hold`;
		this.url_person_vaccine_holds = `${this.apiHost}/${this.apiPathVaccine}/vaccine/hold`;
		this.url_vaccine_hold_statuses = `${this.apiHost}/${this.apiPathVaccine}/vaccine/hold/statuses`;
		this.url_patch_vaccine_hold = `${this.apiHost}/${this.apiPathVaccine}/vaccine/hold/`;
		this.url_vaccine_detail = `${this.apiHost}/${this.apiPathVaccine}/vaccine`;
		this.url_vaccine_type = `${this.apiHost}/${this.apiPathVaccine}/vaccine/type`;
		this.url_rejection_type = `${this.apiHost}/${this.apiPathVaccineV2}/vaccinations/reasons`;
		this.url_vaccine_company = `${this.apiHost}/${this.apiPathVaccineV2}/company`;
		this.url_vaccine_proof = `${this.apiHost}/${this.apiPathVaccine}/vaccine/proof/upload`;
		this.url_vaccine_proof_admin = `${this.apiHost}/${this.apiPathVaccine}/vaccine/proof/admin`;
		this.url_person_vaccine_proof = `${this.apiHost}/${this.apiPathVaccine}/vaccine/proof/upload`;
		this.url_all_vaccine_exemptions = `${this.apiHost}/${this.apiPathVaccine}/vaccine/hold/exemptions`;
		this.url_person_exemptions = `${this.apiHost}/${this.apiPathVaccine}/vaccine/hold/exemptions/flags`;
		this.url_vaccine_exemptions = `${this.apiHost}/${this.apiPathVaccineV2}/exemptions`;
		this.url_vaccine_exemptions_pdf = `${this.apiHost}/${this.apiPathVaccine}/exemptions/proof`;
		this.url_exemption_status = `${this.apiHost}/${this.apiPathVaccineV2}/exemptions/status`;
		this.url_exemption_auth = `${this.apiHost}/${this.apiPathVaccineV2}/exemptions/auth`;
		this.url_exemption_documentation_upload = `${this.apiHost}/${this.apiPathVaccineV2}/documents/url`;
		this.url_exemption_request = `${this.apiHost}/${this.apiPathVaccineV2}/exemptions/on-behalf`;
		this.url_vaccine_documents = `${this.apiHost}/${this.apiPathVaccineV2}/documents`;
		this.url_get_person = `${this.apiHost}/${this.apiPathVaccine}/person`;
		this.url_vaccine_clear = `${this.apiHost}/${this.apiPathVaccineV2}/vaccinations/clear`;
	}

	async fetchHoldReasons(): Promise<FetchHoldReasonsResponse> {
		let response = await fetch(this.url_hold_reasons, await this.requestHeaderGet());
		if (this.checkStatusCode(response.status)) {
			let data = await response.json();
			return { items: data };
		} else {
			return {
				error: true,
			};
		}
	}

	async fetchHoldStatuses(): Promise<FetchHoldReasonsResponse> {
		let response = await fetch(this.url_hold_statuses, await this.requestHeaderGet());
		if (this.checkStatusCode(response.status)) {
			let data = await response.json();
			return { items: data };
		} else {
			return {
				error: true,
			};
		}
	}

	async fetchVaccineHoldStatuses(): Promise<FetchHoldReasonsResponse> {
		let response = await fetch(this.url_vaccine_hold_statuses, await this.requestHeaderGet());
		if (this.checkStatusCode(response.status)) {
			let data = await response.json();
			return { items: data };
		} else {
			return {
				error: true,
			};
		}
	}

	fetchAllHolds = async (): Promise<FetchHoldsResponse> => {
		let response = await fetch(this.url_all_holds, await this.requestHeaderGet());
		if (this.checkStatusCode(response.status)) {
			let data = await response.json();
			return { items: data };
		} else if (response.status === 507 || response.status === 403) {
			return {
				unauth: true,
			};
		} else {
			return {
				error: true,
			};
		}
	};

	fetchAllVaccineHolds = async (from: number | undefined = 1, to: number | undefined = undefined): Promise<FetchHoldsResponse> => {
		let query = "";
		if (from !== undefined && to !== undefined && from > 0) {
			query = `?from=${from}&to=${to}`;
		}

		let response = await fetch(this.url_all_vaccine_holds + query, await this.requestHeaderGet());
		if (this.checkStatusCode(response.status)) {
			let data = await response.json();
			return { items: data };
		} else if (response.status === 507 || response.status === 403) {
			return {
				unauth: true,
			};
		} else {
			return {
				error: true,
			};
		}
	};

	fetchAllVaccineExemptions = async (): Promise<FetchHoldsResponse> => {
		let response = await fetch(this.url_all_vaccine_exemptions, await this.requestHeaderGet());
		if (this.checkStatusCode(response.status)) {
			let data = await response.json();
			return { items: data };
		} else if (response.status === 507 || response.status === 403) {
			return {
				unauth: true,
			};
		} else {
			return {
				error: true,
			};
		}
	};

	fetchPersonTests = async (): Promise<FetchFollowUpsResponse> => {
		let response = await fetch(this.url_tests, await this.requestHeaderGet());
		if (this.checkStatusCode(response.status)) {
			let data = await response.json();
			return { items: convertApiPersonTestsToWebPerson(data) };
		} else {
			return {
				error: true,
			};
		}
	};

	fetchTexts = async (): Promise<FetchArrayResponse> => {
		let response = await fetch(this.url_texts, await this.requestHeaderGet());
		if (this.checkStatusCode(response.status)) {
			let data = await response.json();
			return { items: data };
		} else {
			return {
				error: true,
			};
		}
	};

	postSchedule = async (kerbId: string, date: string, result: string): Promise<PostAttestationsResponse> => {
		let response = await fetch(
			this.url_complete_tests,
			await this.requestHeaderPost({
				kerberos: kerbId,
				date: date,
				result: result.toLowerCase(),
			})
		);

		if (this.checkStatusCode(response.status)) {
			// let data = await response.json()
			return {
				success: true,
			};
		} else {
			try {
				let text = await response.json();
				return Promise.reject(text.message);
			} catch (e) {
				let text = await response.text();
				return Promise.reject(text);
			}
		}
	};

	postClear = async (kerbId: string): Promise<PostStatusResponse> => {
		let response = await fetch(this.url_clear, await this.requestHeaderPost({ kerberos: kerbId }));

		return await this.genericResponse(response);
	};

	postOnHold = async (mitId: string, reasonId: string, description: string): Promise<PostStatusResponse> => {
		let response = await fetch(
			this.url_post_hold,
			await this.requestHeaderPost({
				mit_id: mitId,
				reason_id: reasonId,
				comment: description,
			})
		);

		return await this.genericResponse(response);
	};

	postExemptions = async (mitId: string, exemptionFlags: ExemptFlagModel, description: string): Promise<PostStatusResponse> => {
		let response = await fetch(
			this.url_post_hold,
			await this.requestHeaderPost({
				mit_id: mitId,
				medical: exemptionFlags.medical,
				hr: exemptionFlags.hr,
				international: exemptionFlags.international,
			})
		);

		return await this.genericResponse(response);
	};

	patchStatus = async (statusGuid: string, newId: number, exemptFromTesting: boolean, clear_schedule_time?: string, clear_schedule?: boolean): Promise<PostStatusResponse> => {
		let response = await fetch(
			`${this.url_patch_hold}${statusGuid}`,
			await this.requestHeaderPatch({
				status_id: newId,
				exempt_retest_extended_period: exemptFromTesting,
				clear_schedule_time: clear_schedule_time || null,
				clear_schedule: clear_schedule || false,
			})
		);

		return await this.genericResponse(response);
	};

	patchVaccineHoldStatus = async (statusGuid: string, newId: number): Promise<PostStatusResponse> => {
		let response = await fetch(
			`${this.url_patch_vaccine_hold}${statusGuid}`,
			await this.requestHeaderPatch({
				status_id: newId,
			})
		);

		return await this.genericResponse(response);
	};

	downloadManifest = async (from?: moment.Moment, to?: moment.Moment): Promise<DownloadFileResp> => {
		let queryParams = ``;
		if (from && to) {
			queryParams = `?from=${from.format("YYYY-MM-DD [00:00]")}&to=${to.format("YYYY-MM-DD [24:00]")}`;
		}
		let response = await fetch(this.url_results_download + queryParams, await this.requestHeaderGet());
		if (this.checkStatusCode(response.status)) {
			let data = await response.text();
			return {
				data: data,
			};
		} else {
			return {
				error: true,
			};
		}
	};

	uploadFile = async (file: any) => {
		return await this.uploadFileResults(file.name, file.file);
	};

	uploadFileResults = async (name: string, data: any) => {
		let token = await authProvider.getToken();

		const options: RequestInit = {
			method: "POST",
			headers: new Headers({
				"Content-Type": "binary/octet-stream",
				Authorization: "Bearer " + token,
				"Content-Filename": name,
			}),
			// @ts-ignore
			body: data,
		};

		let response = await fetch(`${this.url_results_upload}?file_name=${name}`, options);

		if (this.checkStatusCode(response.status)) {
			return {
				error: false,
			};
		} else {
			return {
				error: true,
			};
		}
	};

	uploadVaccineResults = async (name: string, data: any) => {
		let token = await authProvider.getToken();

		const options: RequestInit = {
			method: "POST",
			headers: new Headers({
				"Content-Type": "binary/octet-stream",
				Authorization: "Bearer " + token,
				"Content-Filename": name,
			}),
			// @ts-ignore
			body: data,
		};

		let response = await fetch(`${this.url_vaccine_upload}?file_name=${name}`, options);

		if (this.checkStatusCode(response.status)) {
			return {
				error: false,
			};
		} else {
			let data = await response.json();
			return {
				error: true,
				errorMessage: data,
			};
		}
	};

	uploadVaccineEligibleResults = async (name: string, data: any) => {
		let token = await authProvider.getToken();

		const options: RequestInit = {
			method: "POST",
			headers: new Headers({
				"Content-Type": "binary/octet-stream",
				Authorization: "Bearer " + token,
				"Content-Filename": name,
			}),
			// @ts-ignore
			body: data,
		};

		let response = await fetch(`${this.url_eligible_upload}?file_name=${name}`, options);

		if (this.checkStatusCode(response.status)) {
			return {
				error: false,
			};
		} else {
			let data = await response.json();
			return {
				error: true,
				errorMessage: data,
			};
		}
	};

	async fetchTestResults(mitId?: string): Promise<FetchArrayResponse> {
		let response = await fetch(`${this.url_fetch_results}${mitId ? `?mit_id=${mitId}&optional=medical,flu_shots,vaccine` : ``}`, await this.requestHeaderGet());
		if (this.checkStatusCode(response.status)) {
			let data = await response.json();

			return {
				items: data
					? data.slice(0, 20).map((item: any) => ({
							date: item.test_date,
							accession_number: item.accession_number,
							result: getResultText(item.result, item),
					  }))
					: undefined,
			};
		} else {
			return {
				error: true,
			};
		}
	}

	async fetchPendingTests(mitId?: string): Promise<any> {
		let response = await fetch(`${this.url_pending_tests}${mitId ? `?mitId=${mitId}` : ``}`, await this.requestHeaderGet());
		if (this.checkStatusCode(response.status)) {
			let data = await response.json();
			return data;
		} else {
			return {
				error: true,
			};
		}
	}

	async cancelPendingTests(mitId: string, accessionNumber: any): Promise<any> {
		let token = await authProvider.getToken();
		const deleteOptions: RequestInit = {
			method: "DELETE",
			headers: new Headers({
				Authorization: "Bearer " + token,
				"Content-Type": "application/json",
			}),
			body: JSON.stringify({ mitId, accessionNumber }),
		};

		let response = await fetch(`${this.url_pending_tests}`, deleteOptions);
		console.log("cancelPendingTests", response);
		if (response.status === 200 || response.status === 204) {
			return true;
		} else {
			let err = await response.json();
			return Promise.reject(err);
		}
	}

	async fetchPersonMedicalHolds(mitId: string): Promise<FetchHoldsResponse> {
		let response = await fetch(`${this.url_person_holds}?mit_id=${mitId}`, await this.requestHeaderGet());
		if (this.checkStatusCode(response.status)) {
			let data = await response.json();

			if (data.length > 0) {
				data.forEach((d: HoldModel) => {
					(d.created_on = d.created_on ? toLocalTimeFromUct(d.created_on) : d.created_on), (d.updated_on = d.updated_on ? toLocalTimeFromUct(d.updated_on) : d.updated_on);
				});
			}

			return {
				items: data,
			};
		} else if (response.status === 507 || response.status === 403) {
			return {
				unauth: true,
			};
		} else {
			return {
				error: true,
			};
		}
	}

	async fetchPersonVaccineExemption(mitId: string): Promise<FetchExemptFlagsResponse> {
		let response = await fetch(`${this.url_person_exemptions}/${mitId}`, await this.requestHeaderGet());
		if (response.status === 200) {
			let flags: ExemptFlagModel = await response.json();
			if (flags) {
				return { flags };
			} else {
				return { error: true };
			}
		} else {
			return { error: true };
		}
	}

	putPersonVaccineExemption = async (mitId: string, exemptionFlags: ExemptFlagModel): Promise<PostStatusResponse> => {
		let response = await fetch(this.url_person_exemptions + `/${mitId}`, await this.requestHeaderPut(exemptionFlags));

		return await this.genericResponse(response);
	};

	fetchPersonResults = async (date: string): Promise<FetchFollowUpsResponse> => {
		let response = await fetch(this.url_results + `?date=${date}`, await this.requestHeaderGet());
		if (this.checkStatusCode(response.status)) {
			let data = await response.json();
			return { items: convertApiPersonResultsToWebPerson(data) };
		} else if (response.status === 507 || response.status === 403) {
			return {
				unauth: true,
			};
		} else {
			return {
				error: true,
			};
		}
	};

	fetchFluShotResults = async (date: string): Promise<FluShotDetailResponse> => {
		let response = await fetch(this.url_flu_shot_detail + `?date=${date}`, await this.requestHeaderGet());
		if (this.checkStatusCode(response.status)) {
			let data = await response.json();
			return {
				error: false,
				items: data,
			};
		} else if (response.status === 507 || response.status === 403) {
			return {
				unauth: true,
			};
		} else {
			return {
				error: true,
			};
		}
	};

	fetchVaccineResults = async (date?: string, only_completed?: boolean): Promise<FluShotDetailResponse> => {
		let query = "";
		if (date) {
			query = `?date=${date}`;
		}
		if (only_completed) query = `?only_completed=true`;

		let response = await fetch(this.url_vaccine_detail + query, await this.requestHeaderGet());
		if (this.checkStatusCode(response.status)) {
			let data = await response.json();
			return {
				error: false,
				items: data,
			};
		} else if (response.status === 507 || response.status === 403) {
			return {
				unauth: true,
			};
		} else {
			return {
				error: true,
			};
		}
	};

	putMedicalScheduleIrregular = async (mitId: string, medical_schedule_irregular: boolean): Promise<PostStatusResponse> => {
		let response = await fetch(
			this.url_schedule_irregular,
			await this.requestHeaderPut({
				mit_id: mitId,
				medical_schedule_irregular: medical_schedule_irregular,
			})
		);

		return await this.genericResponse(response);
	};

	fetchVaccineCourse = async (id: string): Promise<FetchArrayResponse> => {
		let response = await fetch(`${this.url_vaccine_detail}/course?mit_id=${id}`, await this.requestHeaderGet());

		if (response.status == 200) {
			let data = await response.json();
			return { items: data };
		} else {
			return {
				error: true,
			};
		}
	};

	fetchVaccineStatus = async (id: string): Promise<FetchArrayResponse> => {
		let response = await fetch(`${this.url_vaccine_detail}/status?mit_id=${id}`, await this.requestHeaderGet());

		if (response.status == 200) {
			let data = await response.json();
			return { items: data };
		} else {
			return {
				error: true,
			};
		}
	};

	async postVaccineCourse(body: any): Promise<any> {
		let token = await authProvider.getToken();
		const postOptions: RequestInit = {
			method: "POST",
			headers: new Headers({
				Authorization: "Bearer " + token,
				"Content-Type": "application/json",
			}),
			body: JSON.stringify(body),
		};

		let response = await fetch(this.url_vaccine_detail + `/course`, postOptions);

		if (response.status === 200 || response.status === 201) {
			let data = await response.json();
			return { items: data };
		} else {
			return { error: true };
		}
	}

	async deleteVaccineCourse(body: any): Promise<any> {
		let token = await authProvider.getToken();
		const deleteOptions: RequestInit = {
			method: "DELETE",
			headers: new Headers({
				Authorization: "Bearer " + token,
				"Content-Type": "application/json",
			}),
			body: JSON.stringify(body),
		};

		let response = await fetch(this.url_vaccine_detail + `/course`, deleteOptions);

		if (response.status === 200) {
			let data = await response.json();
			return { items: data };
		} else {
			return { error: true };
		}
	}

	getVaccineProofFiltered = async (filters?: any): Promise<any> => {
		let url = new URL(this.url_vaccine_proof_admin);
		if (filters) {
			Object.keys(filters).forEach((key) => {
				if (filters[key] !== null && typeof filters[key] !== undefined) {
					url.searchParams.append(key, filters[key]);
				}
			});
		}

		let response = await fetch(url.toString(), await this.requestHeaderGet());
		if (this.checkStatusCode(response.status)) {
			let data = await response.json();
			return data;
		} else {
			return {
				error: true,
			};
		}
	};

	getVaccineProof = async (filters?: any): Promise<any> => {
		let response = await fetch(this.url_vaccine_proof, await this.requestHeaderGet());
		if (this.checkStatusCode(response.status)) {
			let data = await response.json();
			return data;
		} else {
			return {
				error: true,
			};
		}
	};

	async fetchPersonVaccineProofs(mitId: string): Promise<any> {
		let response = await fetch(`${this.url_person_vaccine_proof}?mit_id=${mitId}`, await this.requestHeaderGet());
		if (this.checkStatusCode(response.status)) {
			let data = await response.json();

			/* if (data.length > 0) {
				data.forEach((d: HoldModel) => {
					(d.created_on = d.created_on ? toLocalTimeFromUct(d.created_on) : d.created_on), (d.updated_on = d.updated_on ? toLocalTimeFromUct(d.updated_on) : d.updated_on);
				});
			} */

			return {
				items: data,
			};
		} else if (response.status === 507 || response.status === 403) {
			return {
				unauth: true,
			};
		} else {
			return {
				error: true,
			};
		}
	}

	async fetchPersonVaccineDocuments(mitId?: string): Promise<any> {
		let response = await fetch(`${this.url_vaccine_documents}${mitId ? `?mitId=${mitId}` : ""}`, await this.requestHeaderGet());
		if (this.checkStatusCode(response.status)) {
			let data = await response.json();

			/* if (data.length > 0) {
				data.forEach((d: HoldModel) => {
					(d.created_on = d.created_on ? toLocalTimeFromUct(d.created_on) : d.created_on), (d.updated_on = d.updated_on ? toLocalTimeFromUct(d.updated_on) : d.updated_on);
				});
			} */

			return {
				items: data,
			};
		} else if (response.status === 507 || response.status === 403) {
			return {
				unauth: true,
			};
		} else {
			return {
				error: true,
			};
		}
	}

	async fetchPersonVaccineProofsFromGuid(proofGuid: string): Promise<any> {
		let response = await fetch(`${this.url_person_vaccine_proof}/${proofGuid}`, await this.requestHeaderGet());
		if (this.checkStatusCode(response.status)) {
			let data = await response.json();

			/* if (data.length > 0) {
				data.forEach((d: HoldModel) => {
					(d.created_on = d.created_on ? toLocalTimeFromUct(d.created_on) : d.created_on), (d.updated_on = d.updated_on ? toLocalTimeFromUct(d.updated_on) : d.updated_on);
				});
			} */

			return {
				items: data,
			};
		} else if (response.status === 507 || response.status === 403) {
			return {
				unauth: true,
			};
		} else {
			return {
				error: true,
			};
		}
	}

	putVaccineProof = async (body: any): Promise<PostStatusResponse> => {
		let response = await fetch(this.url_vaccine_proof_admin, await this.requestHeaderPut(body));

		return await this.genericResponse(response);
	};

	fetchVaccineTypes = async (): Promise<any> => {
		let response = await fetch(`${this.url_vaccine_type}`, await this.requestHeaderGet());

		if (response.status == 200) {
			let data = await response.json();
			return data;
		} else {
			return {
				error: true,
			};
		}
	};

	fetchRejectionTypes = async (): Promise<any> => {
		let response = await fetch(`${this.url_rejection_type}`, await this.requestHeaderGet());

		if (response.status == 200) {
			let data = await response.json();
			return data;
		} else {
			return {
				error: true,
			};
		}
	};

	fetchVaccineCompanies = async (): Promise<any> => {
		let response = await fetch(`${this.url_vaccine_company}`, await this.requestHeaderGet());

		if (response.status == 200) {
			let data = await response.json();
			return data;
		} else {
			return {
				error: true,
			};
		}
	};

	//EXEMPTION
	fetchVaccineExemption = async (id?: string, includes?: string): Promise<VaccineExemptionResponse> => {
		let response = await fetch(`${this.url_vaccine_exemptions}${id ? `?mitId=${id}` : ""}${includes ? `${id ? `&` : `?`}includes=${includes}` : ""}`, await this.requestHeaderGet());

		if (this.checkStatusCode(response.status)) {
			let data = await response.json();
			return {
				items: data,
			};
		} else if (response.status === 507 || response.status === 403) {
			return {
				unauth: true,
			};
		} else {
			return {
				error: true,
			};
		}
	};

	fetchVaccineExemptionPDF = async (exemptionId: string): Promise<any> => {
		let response = await fetch(`${this.url_vaccine_exemptions_pdf}/${exemptionId}`, await this.requestHeaderGet());

		if (this.checkStatusCode(response.status)) {
			let data = await response.blob();
			return data;
		} else {
			const text = await response.json();
			return await Promise.reject(text?.message);
		}
	};

	fetchExemptionAuth = async (): Promise<any> => {
		let response = await fetch(`${this.url_exemption_auth}`, await this.requestHeaderGet());

		if (this.checkStatusCode(response.status)) {
			let data = await response.json();
			return data;
		} else {
			const text = await response.text();
			return await Promise.reject(text);
		}
	};

	fetchExemptionTypes = async (): Promise<any> => {
		let response = await fetch(`${this.apiHost}/${this.apiPathVaccineV2}/exemptions/types`, await this.requestHeaderGet());

		if (this.checkStatusCode(response.status)) {
			let data = await response.json();
			return data;
		} else {
			const text = await response.text();
			return await Promise.reject(text);
		}
	};

	// putExemptionApproval = async (exemptionId: string, status: "A" | "R" | "M", feedback?: string): Promise<PostStatusResponse> => {
	// 	let response = await fetch(`${this.url_exemption_status}?id=${exemptionId}&status=${status}`, await this.requestHeaderPut({ feedback: feedback }));

	// 	if (response.status === 200) {
	// 		const data = await response.json();
	// 		return data;
	// 	}

	// 	const text = await response.text();
	// 	return await Promise.reject(text);
	// };

	putExemptionApproval = async (body: any): Promise<PostStatusResponse> => {
		let response = await fetch(`${this.url_exemption_status}`, await this.requestHeaderPut(body));

		if (response.status === 200) {
			const data = await response.json();
			return data;
		}

		const text = await response.text();
		return await Promise.reject(text);
	};

	async getExemptionDocumentUrl(image_key?: string, fileName?: string, fileType?: string): Promise<ExemptionUploadUrlResponse> {
		let url: string;
		image_key
			? (url = `${this.url_exemption_documentation_upload}?docKey=${image_key}&contentType=${fileType}&fileName=${fileName}`)
			: (url = `${this.url_exemption_documentation_upload}?contentType=${fileType}&fileName=${fileName}`);

		let response = await fetch(url, await this.requestHeaderGet());

		if (this.checkStatusCode(response.status)) {
			return { data: await response.json() };
		} else {
			const text = await response.text();
			return await Promise.reject(text);
		}
	}

	async uploadExemptionProof(url: string | any, file: any, fileName?: any): Promise<any> {
		const putOptions: RequestInit = {
			method: "PUT",
			headers: new Headers({
				"Content-Type": file.type,
			}),
			body: file,
		};

		let response = await fetch(url, putOptions);

		// let response = await this.useFetch('PUT', 'put/upload', url, file, undefined, true)

		if (response.status >= 200 && response.status < 300) {
			return true;
		} else {
			let text = await response.text();
			return Promise.reject(text);
		}
	}

	postExemptionRequest = async (body: any): Promise<any> => {
		let token = await authProvider.getToken();
		const postOptions: RequestInit = {
			method: "POST",
			headers: new Headers({
				Authorization: "Bearer " + token,
				"Content-Type": "application/json",
			}),
			body: JSON.stringify(body),
		};

		let response = await fetch(this.url_exemption_request, postOptions);

		if (response.status >= 200 && response.status < 300) {
			const data = await response.json();
			return data;
		}

		const text = await response.text();
		return await Promise.reject(text);
	};

	async fetchPerson(krbName: string): Promise<PersonInfoResponse> {
		let response = await fetch(this.url_get_person + "/" + krbName, await this.requestHeaderGet());

		if (response.status >= 200 && response.status < 300) {
			let data: PersonInfo = await response.json();
			return {
				data: data,
			};
		} else {
			const text = await response.text();
			return await Promise.reject(text);
		}
	}

	async clearVaccine(id: string): Promise<any> {
		let token = await authProvider.getToken();
		const deleteOptions: RequestInit = {
			method: "DELETE",
			headers: new Headers({
				Authorization: "Bearer " + token,
				"Content-Type": "application/json",
			})
		};

		let response = await fetch(`${this.url_vaccine_clear}/${id}`, deleteOptions);
		if (response.status === 200 || response.status === 204) {
			return true;
		} else {
			let err = await response.json();
			return Promise.reject(err);
		}
	}
}

const medicalApi = new MedicalApi();
export default medicalApi;
