import React from "react";
import { v4 as uuidv4 } from "uuid";
import ReleaseNoteFeature, { ReleaseNoteFeatureProps } from "./ReleaseNoteFeature";
import Text from "./Text";
import VideoItem, { VideoItemProps } from "./VideoItem";

export interface ReleaseNoteItemProps
{
    items: ReleaseNoteFeatureProps[];

    text?: string;
    image?: ImageData;
    itemIndex?: number;
    secondaryText?: any;
    date?: string;
    videos?: VideoItemProps[];

    isLoading?: boolean;
}

//need an action list object passed through as well
//need more logic to ensure we can combine many of these
export default class ReleaseNoteItem extends React.Component<ReleaseNoteItemProps> {
    private id = uuidv4();

    public static defaultProps = {
        text: "",
        date: "",
        itemIndex: 0,
    };

    getClasses(): string
    {
        var classes: string[] = [];

        return classes.join(" ");
    }

    getAriaDisabledState(): boolean | undefined
    {
        return undefined;
    }

    render(): React.ReactNode
    {
        let day = "";
        let month = "";
        let year = "";

        if (this.props.date)
        {
            var today = new Date(`${parseInt(this.props.date.split("/")[2])}-${parseInt(this.props.date.split("/")[0])}-${parseInt(this.props.date.split("/")[1])}`.replace(/-/g,"/"));

            const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(today)
            const mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(today)
            const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(today)

            day = da;
            month = mo;
            year = ye;
        }

        if (this.props.isLoading) {
            return (
                <React.Fragment>
                    <div className="row releaseNoteItem loading pt-3 pb-3">
                        <div className="col-lg-2 col-md-2 col-sm-12">
                            <div className="date-icon skeleton mx-auto mb-3 mt-3">
                                &nbsp;
                            </div>
                        </div>
                        <div className="col-lg-10 col-md-10 col-sm-12">
                            <div className="row">
                                <div className="col-lg-3 col-md-4 col-sm-6 mt-3">
                                    <div className="skeleton">&nbsp;</div>
                                    <div className="skeleton">&nbsp;</div>
                                    <div className="skeleton">&nbsp;</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </React.Fragment>
            )
        }

        return (
            <div className="row releaseNoteItem pt-3 pb-3">
                <div className="col-lg-2 col-md-2 col-sm-12">
                    {
                        this.props.date
                        &&
                        <div className="date-icon mx-auto mb-3 mt-3">
                            <div>{month}</div>
                            <strong>{day}</strong>
                            <div>{year}</div>
                        </div>
                    }
                </div>
                <div className="col-lg-10 col-md-10 col-sm-12">
                    <div className="row">
                        {
                            this.props.videos
                                ?
                                this.props.videos.map((item: VideoItemProps, number: number) => (
                                    <VideoItem {...item} key={number} />
                                ))
                                :
                                <div></div>
                        }
                    </div>
                    <div className="row">
                        <div className="col mb-4">
                            {
                                this.props.text
                                &&
                                <Text bold={true} content={this.props.text} />
                            }
                        </div>
                    </div>
                    <div className="row">
                        {this.props.items.map(
                            (item: ReleaseNoteFeatureProps, number: number) => (
                                <ReleaseNoteFeature {...item} key={number} />
                            )
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
