import React, { FC, useState, Fragment, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import DropdownItem, { DropdownItemProps, DropDownState } from "./DropdownItem";
import { Validation, ValidationType } from "../Form";
import { useForm } from "react-hook-form";
import { huiLog } from "../../common/huiConsoleLogs";

export interface DropDownProps {
	text: string;
	state?: DropDownState;
	dropdownItems: DropdownItemProps[];
	name: string;
	required?: boolean;
	width?: number;
	tags?: boolean;
	maxTags?: number;
	onChange?: any;
	value?: DropdownItemProps | DropdownItemProps[] | string;
	icon?: React.ReactElement;
	disabled?: boolean;
	readOnly?: boolean;
	compact?: boolean;
	id?: string;
	register?: any; // https://react-hook-form.com/api#register
	errors?: any; // https://react-hook-form.com/api#errors
	setValue?: any; // https://react-hook-form.com/api#setValue
}

export enum NavItemDropDownState {
	None,
	Active,
}

const Dropdown: FC<DropDownProps> = (props = { dropdownItems: [], state: DropDownState.None, text: "", name: "" }) => {
	if (!props.id) {
		huiLog.updateWarning(
			`\nA UNIQUE [id] attributes will soon become required for all dropdown components. 
			\nPlease update all dropdown components with this atrribute ASAP.`
		);
	}

	console.log('sumbit::Dropdown', {req: props.required, ronly: props.readOnly})

	let { register, errors } = useForm();

	if (props.register) register = props.register;

	if (props.errors) errors = props.errors;

	let controlledItems: any = props.dropdownItems.filter((dropdownitem: DropdownItemProps) => {
		return (dropdownitem.id = dropdownitem.id ? dropdownitem.id : uuidv4());
	});

	const [dropdownItems, setDropdownItems] = useState(controlledItems);
	const [tagItems, setTagItems] = useState<DropdownItemProps[]>([]);
	const [tagMax, setTagMax] = useState(false);
	const [selectedItem, setSelectedItem] = useState<DropdownItemProps>({ id: "", text: "", icon: "" });

	useEffect(() => {
		if (props.value) {
			if (typeof props.value === "object" && !Array.isArray(props.value)) {
				setSelectedItem(props.value);
				setTagItems([{ text: props.value.text, icon: props.value.icon, id: props.value.id }]);
			} else if (typeof props.value === "object" && Array.isArray(props.value)) {
				setSelectedItem(props.value[0]);
				setTagItems(props.value);
			} else {
				setSelectedItem({ text: props.value, icon: "", id: "" });
			}
		} else {
			setSelectedItem({ text: "", icon: "", id: "" });
		}

		// setSelectedItem(props.value? props.value : {text: '', icon: ''})
	}, [props.value]);

	const getClasses = (): string => {
		var classes: string[] = [];

		if (props.state === DropDownState.Active) classes.push("active");
		if (props.icon) classes.push("icon-dropdown");
		if (props.disabled) classes.push("disabled");
		if (props.readOnly) classes.push("read-only");
		if (props.compact) classes.push("compact");

		return classes.join(" ");
	};

	const getAriaDisabledState = (): boolean | undefined => {
		if (props.disabled) return true;

		return undefined;
	};

	const selectTag = (itm: DropdownItemProps) => {
		let existingItems: DropdownItemProps[] = tagItems;

		//Max Check
		if (existingItems.length === props.maxTags) {
			setTagMax(true);
			return;
		}

		existingItems.push(itm);

		//Disable item
		const newItemState = dropdownItems.filter((di: DropdownItemProps) => {
			if (di.id === itm.id) return (di.state = DropDownState.Disabled);

			return di;
		});

		setTagItems(existingItems);
		setDropdownItems(newItemState);

		props.onChange && props.onChange(existingItems);
	};

	const onChange = (itm: DropdownItemProps) => {
		setSelectedItem(itm);
		props.onChange && props.onChange(itm);
		return true;
	};

	const removeTag = (itm: DropdownItemProps) => {
		let existingItems = tagItems;

		var filtered = existingItems.filter((value: DropdownItemProps) => {
			return value.id !== itm.id;
		});

		const newItemState = dropdownItems.filter((di: DropdownItemProps) => {
			if (di.id === itm.id) {
				di.state = DropDownState.None;
				return di;
			}

			return di;
		});

		setTagItems(filtered);
		setDropdownItems(newItemState);

		props.onChange && props.onChange(filtered);
	};

	const onKeyDown = (e: React.KeyboardEvent<HTMLAnchorElement>, dropdownItem: DropdownItemProps, tags: boolean) => {
		let key = e.key || e.keyCode
		if (key === 13) {
			if (tags) selectTag(dropdownItem);
			else onChange(dropdownItem);
		}
	};

	//Tags
	if (props.tags)
		return (
			<div className={`dropdown tags ${getClasses()}`} style={{ width: props.width + "%" }}>
				{
					//Need to have this dissappear when an option is chosen
					props.required && tagItems.length === 0 && <span className="text-required-dot"></span>
				}
				{
					//Add validation message for max
					tagMax && <Validation active={true} message={"Max"} type={ValidationType.Required} />
				}
				<ul>
					{
						//Style tags
						tagItems.map((tag: DropdownItemProps) => {
							return (
								<li onClick={() => removeTag(tag)}>
									{tag.text} <i className="fal fa-times"></i>
								</li>
							);
						})
					}
				</ul>
				<a
					className={`form-control-inner ${getClasses()}`}
					href="/#"
					id={props.id || "navbarDropdownMenuLink"}
					data-toggle="dropdown"
					aria-haspopup="true"
					aria-expanded="false"
					aria-disabled={getAriaDisabledState()}>
					Add Item
				</a>

				<div className="dropdown-menu" aria-labelledby={props.id || "navbarDropdownMenuLink"} tabIndex={0}>
					{dropdownItems &&
						dropdownItems.map((dropdownItem: DropdownItemProps, number: number) => (
							<DropdownItem {...dropdownItem} tabindex={number} onKeyDown={(e) => onKeyDown(e, dropdownItem, true)} onChange={() => selectTag(dropdownItem)} key={number} />
						))}
				</div>
			</div>
		);

	return (
		<Fragment>
			<div className={`dropdown ${getClasses()}`} style={{ width: props.width ? props.width + "%" : "100%" }}>
				<div className="text">
					{
						//Default Required
						!selectedItem.text && errors[props.name] && <Validation active={true} message={"Required"} type={ValidationType.Required} />
					}
				</div>
				<input type="hidden" value={selectedItem.text} id={props.name} name={props.name} 
					ref={register({ 
						required: props.required,
						// validate: (value) => value !== "" //Not sure what this was meant for. This caused some validation issues so I commented them out
					})} 
					required={props.required} 
				/>
				<a
					className={`form-control ${getClasses()}`}
					href="/#"
					id={props.id || "navbarDropdownMenuLink"}
					data-toggle="dropdown"
					aria-haspopup="true"
					aria-expanded="false"
					aria-disabled={getAriaDisabledState()}>
					{!props.icon ? (selectedItem.text ? <span className="selected-item-text">{selectedItem.text}</span> : <span className="selected-item-text">{props.text}</span>) : props.icon}

					{
						//Need to have this dissappear when an option is chosen
						!props.icon ? props.required && !selectedItem.text ? <i className="fas fa-chevron-down red" /> : <i className="fas fa-chevron-down" /> : ""
					}
				</a>

				<div className="dropdown-menu" aria-labelledby={props.id || "navbarDropdownMenuLink"} tabIndex={-1}>
					{props.dropdownItems &&
						props.dropdownItems.map((dropdownItem: DropdownItemProps, number: number) => (
							<DropdownItem {...dropdownItem} tabindex={0} onKeyDown={(e) => onKeyDown(e, dropdownItem, false)} onChange={() => onChange(dropdownItem)} key={number} />
						))}
				</div>
			</div>
		</Fragment>
	);
};

export default Dropdown;

Dropdown.defaultProps = {
	compact: true,
};
