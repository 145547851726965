import {action, observable} from "mobx";
import {toDate} from "../common/Formatters";
import {FluShotDetail, HoldModel, VaccineExemptionDTO} from "../api/MedicalApi";
import { CaseResponse } from "../api/ContactTracingApi";

export interface Note {
    dateTime: string;
    text: string;
    submittedByKerbId: string;
}

export interface ContactPerson {
    mitId: string;
    name: string;
    kerberosId: string;
    status?: string;
    phoneNumber: string;
    email: string;
    dateOfBirth: string;
    gender: string;
    jobTitle?: string;
    department?: string;
    studentYear?: string;
    initialTestScheduled: boolean;
    isCleared: boolean;
    nextTestDate?: string;
    waiverSignedOn?: string;
    testGuid?: string;
    createdOn: string;
    createdBy: string;
    symptoms?: string[];
    notes?: Note[];
    sampleId?: string;
    result?: string;
    resultDate?: string;
    testDate?: string;
    exported?: string;
}

export interface PeopleStoreInterface {
    fluShotsList: FluShotDetail[];
    vaccineList: FluShotDetail[];
    followUpList: HoldModel[];
    vaccineHoldList: HoldModel[];
    vaccineExemptList: HoldModel[];
    vaccineRequestExemptList: VaccineExemptionDTO[];
    completedList: ContactPerson[];
    scheduleList: ContactPerson[];
    contactTracingList: CaseResponse[];
    testResultList: ContactPerson[];
    setFollowUpList: (people: HoldModel[]) => void;
    setVaccineHoldList: (people: HoldModel[]) => void;
    setVaccineExemptionList: (people: HoldModel[]) => void;
    setVaccineRequestExemptionList: (people: VaccineExemptionDTO[]) => void;
    setCompletedList: (people: ContactPerson[]) => void;
    setScheduleList: (people: ContactPerson[]) => void;
    setTestResultList: (people: ContactPerson[]) => void;
    setFluShotList: (people: FluShotDetail[]) => void;
    setVaccineList: (people: FluShotDetail[]) => void;
    setContactTracingList: (people: CaseResponse[]) => void;
}

interface DictContactPers {
    [key: string]: ContactPerson
}

interface DictHoldModel {
    [key: string]: HoldModel;
}

export const sortHoldByTime = (a: HoldModel, b: HoldModel) => {
    return executeSortDesc(a, b, 'created_on')
}
export const sortByTime = (a: ContactPerson, b: ContactPerson) => {
    return executeSort(a, b, 'createdOn')
}

export const sortByTimeWaiver = (a: ContactPerson, b: ContactPerson) => {
    return executeSort(a, b, 'waiverSignedOn')
}

export const sortByTimeScheduled = (a: ContactPerson, b: ContactPerson) => {
    return executeSort(a, b, 'nextTestDate')
}

export const sortByTestDate = (a: ContactPerson, b: ContactPerson) => {
    return executeSortDesc(a, b, 'testDate')
}

export const sortByTestDateFluShot = (a: FluShotDetail, b: FluShotDetail) => {
    return executeSortDesc(a, b, 'flu_shot_date')
}

export const executeSort = (a: any, b: any, fieldName: 'nextTestDate' | 'waiverSignedOn' | 'createdOn' | 'testDate' | 'created_on') => {
    let dateTimeA: moment.Moment = toDate(a[fieldName]!)
    let dateTimeB: moment.Moment = toDate(b[fieldName]!)
    return dateTimeA.diff(dateTimeB)
}

export const executeSortDesc = (a: any, b: any, fieldName: 'nextTestDate' | 'waiverSignedOn' | 'createdOn' | 'testDate' | 'created_on' | 'flu_shot_date') => {
    let dateTimeA: moment.Moment = toDate(a[fieldName]!)
    let dateTimeB: moment.Moment = toDate(b[fieldName]!)
    return dateTimeB.diff(dateTimeA)
}

export class PeopleStore {
    public followUpList: HoldModel[];
    @observable vaccineHoldList: HoldModel[];
    @observable vaccineExemptList: HoldModel[];
    @observable vaccineRequestExemptList: VaccineExemptionDTO[];
    @observable scheduleList: ContactPerson[] = []
    @observable testResultList: ContactPerson[] = []
    @observable completedList: ContactPerson[];
    @observable fluShotsList: FluShotDetail[];
    @observable vaccineList: FluShotDetail[];
    @observable contactTracingList: CaseResponse[];

    @action setFollowUpList(people: HoldModel[]) {
       
        this.followUpList = people.sort(sortHoldByTime)
        // console.log(
        //     'settingFollowupList', people, this.followUpList
        // );
    }

    @action setVaccineHoldList(people: HoldModel[]) {
        this.vaccineHoldList = people.sort(sortHoldByTime)
    }

    @action setVaccineExemptionList(people: HoldModel[]) {
        this.vaccineExemptList = people.sort(sortHoldByTime)
    }

    @action setVaccineRequestExemptionList(people: VaccineExemptionDTO[]) {
        this.vaccineRequestExemptList = people
    }

    @action setScheduleList(people: ContactPerson[]) {
        this.scheduleList = people.filter((item: ContactPerson) => !item.nextTestDate).sort(sortByTimeWaiver)
    }

    @action setTestResultList(people: ContactPerson[]) {
        this.testResultList = people.filter((item: ContactPerson) => item.nextTestDate).sort(sortByTimeScheduled)
    }

    @action setCompletedList(people: ContactPerson[]) {
        this.completedList = people.sort(sortByTestDate)
    }

    @action setFluShotList(people: FluShotDetail[]) {
        this.fluShotsList = people.sort(sortByTestDateFluShot)
    }

    @action setVaccineList(people: FluShotDetail[]) {
        this.vaccineList = people.sort(sortByTestDateFluShot)
    }

    @action setContactTracingList(people: CaseResponse[]) {
        this.contactTracingList = people
    }
}

export const peopleStore = new PeopleStore()
