import * as React from 'react';
import { defaults, TableFooterProps } from './types';

const TableFooter: React.FC<React.PropsWithChildren<TableFooterProps>> = (props: React.PropsWithChildren<TableFooterProps>) => {
  const finalProps = { ...defaults, ...props };
  const { component, children, classes } = finalProps;
  const CustomTag = `${component}` as keyof JSX.IntrinsicElements;
  let finalClasses = 'HuiTableFooter';
  if (classes) finalClasses += ` ${classes}`;
  // const mainProps = React.useContext(TableFooterContext);
  return (
    <CustomTag style={props.style} className={finalClasses}>
      {children}
    </CustomTag>
  );
};

export default TableFooter;
