import { Component } from 'react';

interface ErrorBoundaryProps
{
    errorComponent: any;
}

export default class ErrorBoundary extends Component<ErrorBoundaryProps,any>
{
    constructor(props: any)
    {
        super(props);
        this.state = {
            hasError: false
        };
    }

    static getDerivedStateFromError(error: any)
    {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: any, errorInfo: any)
    {
        // You can also log the error to an error reporting service
    }

    render()
    {
        if (this.state.hasError)
        {
            // You can render any custom fallback UI
            return this.props.errorComponent
        }

        return this.props.children;
    }
}