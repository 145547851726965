import {observable} from "mobx";

export interface HoldsModelInterface extends VaccineHolds {
    
}

export interface VaccineHoldStatus {
    id: number;
    text: string;
    clear_for_access: boolean;
}

class VaccineHolds {
    @observable showUpdateStatusModal: boolean
    @observable newStatus?: VaccineHoldStatus
    @observable guidToUpdate?: string
    @observable personName?: string
    @observable personHolds?: any
    @observable reason?: string
    @observable statuses?: VaccineHoldStatus[]

    constructor() {
        this.showUpdateStatusModal = false
    }
}

const vaccineHolds = new VaccineHolds()
export default vaccineHolds